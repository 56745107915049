import React, { useState } from "react";
import IconsModel from "./IconsModel";
import { Button } from "antd";
const IcanUpdate = ({ record, getIcons, icons, updateIcon, getCategory }) => {
  let [catId, setCatId] = useState(record._id);
  let [isModel, setIsModel] = useState(false);
  const editIcon = () => {
    let branchType = localStorage.getItem("branchType");
    console.log("branchType for icons", branchType);
    getIcons({type:branchType});
    setIsModel(true);
  };
  const offMode = () => {
    return (
      <Button
        type="link"
        style={{ color: "#1890ff", fontSize: "20px", padding: "0 7px" }}
        onClick={() => editIcon()}
      >
        <i title="Update Ican" className="fa fa-image"></i>
      </Button>
    );
  };
  return (
    <>
      <IconsModel
        isModel={isModel}
        setIsModel={setIsModel}
        icons={icons}
        catId={catId}
        updateIcon={updateIcon}
        getCategory={getCategory}
      />
      {offMode()}
    </>
  );
};
export default IcanUpdate;
