import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import "./stockDetails.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Table,
  Input,
  InputNumber,
  Popover,
  Form,
  Button,
  notification,
  Select
} from "antd";
import appConfig from "../../helpers/appConfig";
import productsUnits from "../../helpers/productUnits";
const Option = Select.Option;
const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productFile: ""
    };
  }
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class stockDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      category: true,
      visiblePopover: false,
      visibleEditPopover: false,
      editingKey: "",
      popoverId: "",
      deletingKey: "",
      items: [],
      PaginationNo: 1,
      eProductUnit: "",
      eProdLimit: ""
    };

    this.stockCatColumns = [
      {
        title: "Sr. Nr.",
        key: "index",
        render: (text, record, index) =>
          {
            return index + (this.state.PaginationNo - 1) * 10 + 1;
          }
      },
      {
        title: "Produkt Navn",
        key: "name",
        dataIndex: "name",
        editable: true,
        render: (text, record, index) => (
          <a
            onClick={() =>
              this.handleClick(
                this.state.items[index + (this.state.PaginationNo - 1) * 10]._id
              )
            }
          >
            {text}
          </a>
        )
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "25%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() => this.save(form, record._id)}
                        style={{ marginRight: 8 }}
                      >
                        Lagre
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          Avbryt
                        </a>
                      </span>
                    }
                    title="Er du sikker?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      Avbryt
                    </a>
                  </Popover>
                </span>
              ) : (
                <a
                  style={{ color: "#1890ff" }}
                  onClick={() => this.edit(record)}
                >
                  Edit
                </a>
              )}
            </div>
          );
        }
      },
      {
        title: "Slett",
        width: "15%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <Fragment>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        Avbryt
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        Slett
                      </a>
                    </span>
                  }
                  title="Er du sikker?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    Slett
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record._id)}
                  style={{ color: "#F44336" }}
                >
                  Slett
                </a>
              )}
            </Fragment>
          );
        }
      }
    ];

    this.stockProdColumns = [
      {
        title: "Sr. Nr.",
        key: "index",
        render: (text, record, index) => {
          return parseInt(record.minQuantity) > parseInt(record.quantity) ? (
            <div
              style={{
                padding: 1,
                margin: "auto",
                height: 24,
                width: 24,
                background: "red",
                borderRadius: "100%"
              }}
            >
              <p style={{ textAlign: "center", color: "#FFFFFF" }}>
                {index + (this.state.PaginationNo - 1) * 10 + 1}
              </p>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>
              {index + (this.state.PaginationNo - 1) * 10 + 1}
            </p>
          );
        }
      },
      {
        title: "Produkt Navn",
        key: "name",
        dataIndex: "name",
        editable: true,
        render: (text, record, index) => (
          <a
            onClick={() =>
              this.handleClick(
                //? for correct sr no., check antd pagination and table/pagination docs
                //? index + (currentpage - 1)*10
                this.state.items[index + (this.state.PaginationNo - 1) * 10]._id
              )
            }
          >
            {text}
          </a>
        )
      },
      {
        title: "Antall",
        key: "newQty",
        dataIndex: "newQty",
        render: (text, record) => {
          return record.newQty ? record.newQty : record.quantity;
        }
      },
      {
        title: "Min. Antall",
        key: "newMinQty",
        // editable: true,
        dataIndex: "newMinQty",
        width: "20%",
        render: (select, record) => {
          const editable = this.isEditing(record);
          const units = productsUnits[record.base_unit]
            ? productsUnits[record.base_unit]
            : productsUnits["all"];
          const qty = record.min_qty_unit ? record.min_qty_unit : "";
          return editable ? (
            <>
              <Input
                style={{ width: 80, "marginRight": "2px" }}
                compact={true}
                type="number"
                onChange={(e) => this.setState({ eProdLimit: e.target.value })}
                placeholder="Min. Antall"
                value={this.state.eProdLimit}
                required
              />
              <Select
                style={{ width: 80 }}
                showSearch
                defaultValue={this.state.eProductUnit}
              >
                {units.map((product) => (
                  <Option
                    value={product.key}
                    label={product.name}
                    key={product.value}
                    onClick={() => this.handleEProductChange(product.key)}
                  >
                    {product.value}
                  </Option>
                ))}
              </Select>
            </>
          ) : record.newMinQty ? (
            `${record.newMinQty} ${qty}`
          ) : (
            record.minQuantity
          );
        }
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "25%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() => this.save(form, record._id)}
                        style={{ marginRight: 8 }}
                      >
                        Lagre
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          Avbryt
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      Avbryt
                    </a>
                  </Popover>
                </span>
              ) : (
                <a onClick={() => this.edit(record)}>Edit</a>
              )}
            </div>
          );
        }
      },
      {
        title: "Slett",
        width: "15%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <Fragment>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        Avbryt
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        Slett
                      </a>
                    </span>
                  }
                  title="Er du sikker?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    Slett
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record._id)}
                  style={{ color: "#F44336" }}
                >
                  Slett
                </a>
              )}
            </Fragment>
          );
        }
      }
    ];
  }

  pageChange = async (pagination) => {
    if (pagination) {
      // alert(pagination.current);
      await this.setState({ PaginationNo: pagination.current });
      // alert(this.state.PaginationNo);
    }
  };

  edit({ _id, min_qty_unit, newMinQty, minQuantity }) {
    let minQty = newMinQty ? newMinQty : minQuantity;
    this.setState({
      editingKey: _id,
      eProductUnit: min_qty_unit,
      eProdLimit: minQty
    });
  }

  handleVisibleChange = () => {
    this.setState({ visiblePopover: true });
  };

  hideDelete = () => {
    this.setState({ deletingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.items];
      const index = newData.findIndex((item) => key === item._id);
      if (index > -1) {
        const item = newData[index];
        const qu = this.state.eProductUnit;
        const nMq = this.state.eProdLimit;
        if (!qu || !nMq) {
          return;
        }
        item["min_qty_unit"] = qu;
        item["newMinQty"] = nMq;
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState(
          {
            items: newData,
            editingKey: ""
          },
          this.state.category
            ? this.updateCategory(row.name, item._id)
            : this.updateProduct(
                row.name,
                item.newMinQty,
                item._id,
                item.min_qty_unit
              )
        );
      } else {
        newData.push(row);
        this.setState({ items: newData, editingKey: "" });
      }
    });
  }

  delete(e, record) {
    e.preventDefault();
    const newData = [...this.state.items];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1);
      this.setState(
        {
          items: newData,
          editingId: item._id
        },
        this.state.category
          ? this.deleteCategory(item._id)
          : this.deleteProduct(item._id)
      );
    } else {
      newData.push(record);
      this.setState({ items: newData, editingKey: "" });
    }
  }

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  goToCat = () => {
    const { category } = this.state;
    if (!category) {
      this.setState({ category: true, isLoaded: false });
      this.categoryPage();
    } else {
      this.navigate("/stock");
    }
  };

  deleteTemp(key) {
    this.setState({ deletingKey: key });
  }

  openEditPopover = () => {
    this.setState({ visibleEditPopover: true });
  };

  isEditing = (record) => {
    return record._id === this.state.editingKey;
  };

  isDeleting = (record) => {
    return record._id === this.state.deletingKey;
  };

  handleClick = (id) => {
    // alert(id);
    if (this.state.category) {
      this.setState({ isLoaded: false });
      this.productPage(id);
    } else {
      console.log("beep bop");
    }
  };

  productPage = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock/product/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              items: result.data,
              category: false,
              catId: id,
              newItem: ""
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  categoryPage = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              items: result.data && result.data.reverse()
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleAddCategory = (e) => {
    e.preventDefault();
    const { newItem } = this.state;
    var hdr = localStorage.token;
    var data = JSON.stringify({ name: newItem });
    fetch(`${appConfig.endpoint}/stock`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.categoryPage();
            this.openNotification("success", "Category added successfully.");
            window.location.reload();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleDPChange = (e) => {
    const { id } = this.state;
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("profileImage", file);
    this.setState({ productFile: file });
  };
  handleProductChange = (id) => {
    this.setState({ productUnit: id });
  };
  handleEProductChange = (id) => {
    this.setState({ eProductUnit: id });
  };
  handleAddProduct = () => {
    const { newItem, catId, prodLimit, productUnit } = this.state;
    var hdr = localStorage.token;
    if (newItem && prodLimit && productUnit) {
      var data = JSON.stringify({
        name: newItem,
        prodLimit: prodLimit,
        productUnit: productUnit
      });
      fetch(`${appConfig.endpoint}/stock/product/${catId}`, {
        method: "POST",
        body: data,
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status === "failure") {
              this.openNotification("error", result.data);
            } else {
              this.productPage(catId);
              this.openNotification("success", "Product added successfully.");
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    } else {
      this.openNotification("error", "Please fill required fields !...");
    }
  };

  updateCategory = (name, id) => {
    var hdr = localStorage.token;
    var data = JSON.stringify({ name });
    console.log(data);
    fetch(`${appConfig.endpoint}/stock/${id}`, {
      method: "PUT",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      },
      body: data
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", "Category updated successfully.");
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  updateProduct = (name, minQuantity, id, min_qty_unit) => {
    var hdr = localStorage.token;
    var data = JSON.stringify({ name, minQuantity, min_qty_unit });
    console.log(data);
    fetch(`${appConfig.endpoint}/stockProduct/${id}`, {
      method: "PUT",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      },
      body: data
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", "Product updated successfully.");
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  deleteCategory = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          this.openNotification("success", "Category deleted successfully.");
          console.log(result.message);
        }
      });
  };

  deleteProduct = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stockProduct/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          this.openNotification("success", "Product deleted successfully.");
          console.log(result.message);
        }
      });
  };

  componentDidMount() {
    this.categoryPage();
  }

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const stockCatColumns = this.stockCatColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    const stockProdColumns = this.stockProdColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "quantity" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });
    const { error, isLoaded, category } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">Lager</span>
            <span className="item">
              <span id="less-visible">HJEM / </span>
              Lager Liste
            </span>
            <span className="item">
              <span id="less-visible">
                {
                  <div onClick={this.goToCat} className="back-button-border">
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    <span>Back</span>
                  </div>
                }
              </span>
            </span>
          </div>
          {category ? (
            <Fragment>
              <Form onSubmit={this.handleAddCategory} required>
                <Input
                  style={{ width: 200, "marginRight": "10px" }}
                  compact={true}
                  type="text"
                  onChange={(e) => this.setState({ newItem: e.target.value })}
                  placeholder="Produkt Navn"
                  value={this.state.newItem}
                  required
                />

                <Button
                  type="primary"
                  style={{ marginBottom: 16 }}
                  onClick={this.handleAddCategory}
                >
                  Legg Til Produkt
                </Button>
              </Form>
              <Table
                rowKey={(record) => record.name}
                loading={isLoaded ? false : true}
                onChange={(pagination) => this.pageChange(pagination)}
                components={components}
                bordered
                dataSource={this.state.items}
                columns={stockCatColumns}
                rowClassName="editable-row"
              />
            </Fragment>
          ) : (
            <Fragment>
              <Form>
                <Input
                  style={{ width: 200, "marginRight": "10px" }}
                  compact={true}
                  onChange={(e) => this.setState({ newItem: e.target.value })}
                  placeholder="Produkt Navn"
                  value={this.state.newItem}
                />
                <Input
                  style={{ width: 200, "marginRight": "10px" }}
                  compact={true}
                  type="number"
                  onChange={(e) => this.setState({ prodLimit: e.target.value })}
                  placeholder="Min. Antall"
                  value={this.state.prodLimit}
                  required
                />
                <Select
                  style={{ width: 200, "marginRight": "10px" }}
                  showSearch
                  placeholder="Enhet Produkt"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {productsUnits["all"].map((product) => {
                    return (
                      <Option
                        key={product.key}
                        value={product.key}
                        onClick={() => this.handleProductChange(product.key)}
                      >
                        {product.value}
                      </Option>
                    );
                  })}
                </Select>
                {/* <Input type="file"></Input> */}
                <Button
                  onClick={this.handleAddProduct}
                  type="primary"
                  style={{ marginBottom: 16 }}
                >
                  Legg Til Produkt
                </Button>
              </Form>
              <Table
                rowKey={(record) => record.name}
                loading={isLoaded ? false : true}
                onChange={(pagination) => this.pageChange(pagination)}
                components={components}
                bordered
                dataSource={this.state.items}
                columns={stockProdColumns}
                rowClassName="editable-row"
              />
            </Fragment>
          )}
        </Home>
      );
    }
  }

  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};

const StockDetails = connect(mapStateToProps)(stockDetailsComponent);

export default StockDetails;
