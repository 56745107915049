import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Breadcrumb, DatePicker } from "antd";



const Header = (props) => {
  return (
    <>
      <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
          <span id="less-visible">Rapporter / </span>
          Cash Rapport
        </span>
        <span id="less-visible">
          <Button
            onClick={() => props.goBackMainPage()}
            className="ant-btn back-btn"
          >
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </Button>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row className="filter justify-content-center">
            <div className="col-6">
              <DatePicker
                onChange={(date, dateString) => {
                  props.handleDateChange(date);
                }}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </div>
            <div className="col-6 text-right">
              <Button
                className="ant-btn btn-ab" style={{background:"#dcbb68"}}
                onClick={() => props.printReportHandler()}
              >
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                />
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Header;
