import { GET_ICONS } from "../../action/types";
const iconReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ICONS:
      return action.payload.data;
    default:
      return state;
  }
};
export default iconReducer;
