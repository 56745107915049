import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { Input, notification, Progress } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import appConfig from "../../helpers/appConfig";
import { Spin } from "antd";

const { billHtmlGenerate } = require("../../helpers/billHtmlGenerate");

class BillingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: [],
      driveoutCharge: localStorage.getItem("driveoutCharge"),
      loggedid: ""
    };
  }
  async componentDidMount() {
    // console.log("CREATED BY", this.props.createdBy);
    var li = await localStorage.getItem("eid");
    this.setState({ loggedid: li });
    console.log("LOGGED IN USER", localStorage.getItem("eid"));
  }

  componentWillReceiveProps = () => {
    console.log("GETTIND STAT in BILLING FORM", this.props.product);
    console.log("credit check", this.props.creditCheck);
    this.setState({ product: this.props.product });
    this.setState({ createdBy: this.props.createdBy });
  };

  billformat(data) {
    var dd = JSON.parse(JSON.stringify(data));

    dd.data.date = moment(dd.data.createdAt).format("DD-MM-YY");
    dd.data.time = moment(dd.data.createdAt).format("HH:mm:ss");

    var driveoutdata = [];
    if (dd.data.orderType == "Drive Out") {
      dd.data.items.forEach((element) => {
        driveoutdata.push(element);
      });
    }

    var html = billHtmlGenerate(dd.data); // Common HTML Function

    var bimageString = window.btoa(html);
    return bimageString;
  }

  invoiceHandler = async (e) => {
    var taxPrice = this.state.product.reduce(function (acc, x) {
      return acc + x.productPrice * x.quantity;
    }, 0);
    let { type, discount, creditCheck } = this.props;
    let isPaid = creditCheck ? 0 : 1;
    var hdr = localStorage.token;
    var table = localStorage.table;
    var data = JSON.stringify({
      items: this.state.product,
      taxPrice: taxPrice.toFixed(3),
      type,
      isPaid,
      discount
    });
    if (this.state.product && this.state.product.length > 0) {
      fetch(`${appConfig.endpoint}/api/v1/employee/invoice/createinvoice/${table}`, {
        method: "POST",
        body: data,
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "failure") {
            this.openNotification(
              "error",
              "Invoice already generated or something went wrong."
            );
          } else {
            var html = this.billformat(result);
            this.openNotification("success", "Bill printed successfully");
            this.reduceUsedStock(data.items);
            this.props.history.goBack();
            localStorage.setItem("table", null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.openNotification("error", "Please Add Element in cart");
    }
  };
  reduceUsedStock = (items) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/board/reduceUsedStock`, {
      method: "POST",
      body: JSON.stringify({ items: items }),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("failed to reduce the ingredients!");
        } else {
          console.log("Successfully reduced the ingredients!");
        }
      });
  };
  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleQuantityChange = (e, index) => {
    const value = parseInt(e.target.value);
    const quantity = this.state.product[index].quantity;
    const newProduct = this.state.product;
    newProduct[index].quantity = value;
    this.setState({ product: newProduct });
    console.log("Handele qunaityt cange", this.state.product);
  };

  render() {
    const createdOn = new Date(this.props.createdOn);
    // this.setState({ stt: this.props.stat });
    const date = createdOn.toLocaleDateString();
    const time = createdOn.toLocaleTimeString();
    console.log("YOUr PROPS CASH", this.props.cash);
    const { type, discount, cash } = this.props;
    var taxPrice = this.state.product.reduce(function (acc, x) {
      return acc + x.productPrice * x.quantity;
    }, 0);
    if (this.props.orderType && this.props.orderType == "Drive Out") {
      var total = (
        taxPrice - (type === "percent" ? (taxPrice * discount) / 100 : discount)
      ).toFixed(3);
    } else {
      var total = (
        taxPrice - (type === "percent" ? (taxPrice * discount) / 100 : discount)
      ).toFixed(3);
    }

    return (
      <div className="bill">
        <div className="outer">
          <span className="black-line" />
          <span className="white-line" />
          <div className="form-container">
            <div className="form">
              <table className="product-table">
                <tbody id="myBill">
                  {this.props.product.length > 0 &&
                    this.props.product.map((x, index) => (
                      <tr key={index}>
                        <td className="product-name" style={{ width: "33%" }}>
                          {x.productName}
                        </td>
                        <td
                          className="single-quantity"
                          style={{ width: "35%" }}
                        >
                          X
                          <Input
                            min="1"
                            type="number"
                            value={this.props.product[index].quantity}
                            onChange={(e) =>
                              this.handleQuantityChange(e, index)
                            }
                            style={{
                              width: 60,
                              display: "inline-block",
                              marginLeft: 10
                            }}
                            /* onChange={e => this.handleQuantity(e, index)} */
                          />
                        </td>
                        <td className="single-price" style={{ width: "25%" }}>
                          Kr. {x.productPrice * x.quantity}
                        </td>

                        <td className="trash-td" style={{ width: "7%" }}>
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={this.props.delete.bind(this, x.id)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <table className="table2">
                <tbody>
                  <tr>
                    <td className="time">
                      OPPRETTET AV:
                      <span>{this.props.createdBy}</span>
                    </td>

                    <td className="date">
                      OPPRETTET:
                      <br />
                      <span>{`${date} ${time}`}</span>
                    </td>
                  </tr>
                  {/* <tr>
                  <td className="light">Discount</td>
                  <td className="text-right price">0</td>
                </tr> */}
                  <tr>
                    <td className="sub-total name">SUB-TOTALL</td>
                    <td className="text-right sub-total price">
                      Kr.
                      {(
                        total -
                        (
                          total -
                          ((100 * total) / (100 + this.props.tax)).toFixed(3)
                        ).toFixed(3)
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="light tax">
                      Rabatt(
                      {type === "percent" ? `${discount}%` : `Kr.${discount}`})
                    </td>
                    <td className="text-right tax price">
                      - Kr.
                      {type === "percent"
                        ? ((taxPrice * discount) / 100).toFixed(3)
                        : discount.toFixed(3)}
                    </td>
                  </tr>

                  {this.props.orderType && this.props.orderType == "Drive Out" && (
                    <tr>
                      <td className="light tax">Utkjøring Mva(25%)</td>
                      <td className="text-right tax price">
                        {`+ Kr.${(
                          this.state.driveoutCharge -
                          (100 * this.state.driveoutCharge) / 125
                        ).toFixed(3)}`}
                      </td>
                    </tr>
                  )}

                  {this.props.orderType && this.props.orderType == "Drive Out" && (
                    <tr>
                      <td className="light tax">Utkjøring Pris</td>
                      <td className="text-right tax price">
                        {`+ Kr.${(
                          (100 * this.state.driveoutCharge) /
                          125
                        ).toFixed(3)}`}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className="light tax">
                      Mva(
                      {this.props.tax}
                      %)
                    </td>
                    <td className="text-right tax price">
                      Kr.
                      {(
                        (
                          taxPrice -
                          (type === "percent"
                            ? (taxPrice * discount) / 100
                            : discount)
                        ).toFixed(3) -
                        ((100 * total) / (100 + this.props.tax)).toFixed(3)
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="total name">TOTALL</td>
                    <td className="text-right total price">
                      Kr.
                      {this.props.orderType == "Drive Out"
                        ? Number(total) + parseInt(this.state.driveoutCharge)
                        : Number(total)}
                      {/* {Number(total)} */}
                    </td>
                  </tr>
                  {this.props.orderType == "Drive Out" ? (
                    Number(cash) -
                      (Number(total) + Number(this.state.driveoutCharge)) <
                    0 ? (
                      <tr>
                        <td className="total name">Betalt</td>
                        <td className="text-right tax price">
                          Kr.
                          {cash.toFixed(3)}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="light tax">Kontant Retur </td>
                        <td className="text-right total price">
                          Kr.
                          {this.props.orderType == "Drive Out"
                            ? (
                                Number(cash) -
                                (Number(total) +
                                  Number(this.state.driveoutCharge))
                              ).toFixed(3)
                            : (Number(cash) - Number(total)).toFixed(3)}
                        </td>
                      </tr>
                    )
                  ) : (
                    ""
                  )}

                  {this.props.orderType !== "Drive Out" ? (
                    Number(cash) - Number(total) < 0 ? (
                      <tr>
                        <td className="total name">Betalt</td>
                        <td className="text-right tax price">
                          Kr.
                          {cash.toFixed(3)}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="light tax">Kontant Retur</td>
                        <td className="text-right total price">
                          Kr.
                          {(Number(cash) - Number(total)).toFixed(3)}
                        </td>
                      </tr>
                    )
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
            {this.state.product && this.state.product.length ? (
              <p>
                <b>Prosent Betalt : </b>{" "}
                <h3 style={{ float: "right" }}>
                  {this.props.orderType == "Drive Out"
                    ? (
                        Number(cash) /
                        ((Number(total) + Number(this.state.driveoutCharge)) /
                          100)
                      ).toFixed(0) + "%"
                    : (Number(cash) / (Number(total) / 100)).toFixed(0) + "%"}
                </h3>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="bill-button-container">
          {this.props.creditCheck && this.props.creditCheck == true ? (
            <button
              disabled={this.props.creditCheck && !this.props.creditCheck}
              type="button"
              onClick={this.invoiceHandler}
              className="ant-btn leftButton shadow print"
            >
              <b>FAKTURA </b>
            </button>
          ) : (
            ""
          )}

          <button
            onClick={this.props.clicked}
            type="button"
            className="ant-btn leftButton shadow send"
          >
            {this.props.saveItemsLoading ? <Spin></Spin> : <b>LAGRE</b>}
          </button>
        </div>
      </div>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}

const mapStateToProps = (state) => {
  return {};
};
const Billing = connect(mapStateToProps)(BillingForm);

export default withRouter(BillingForm);
