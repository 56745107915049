import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import ReactToPrint from "react-to-print";
// import "./Reports.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import appConfig from "../../helpers/appConfig";
import ReportsButton from "../ReportsButton/ReportsButton";
import {
  DatePicker,
  notification,
  Table,
  Button,
  Select,
  Statistic,
  Card,
  Row,
  Col,
  Divider
} from "antd";
import moment from "moment";

export default class Employeetip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipData: [],
      startDate: "",
      endDate: ""
    };
  }
  componentDidMount() {
    let hdr = localStorage.token;
    let id = localStorage.eid;
    let startDate = moment().date()._d;
    let endDate = moment().date()._d;
    let dat = JSON.stringify({
      startDate: moment(startDate).set({
        hour: 4,
        minute: 0,
        second: 0
      }),
      endDate: moment(endDate).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      })
    });
    fetch(`${appConfig.endpoint}/employeetip/${id}`, {
      method: "POST",
      body: dat,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((res) => this.setState({ tipData: res.data }))
      .catch((err) => console.log(err));
  }

  render() {
    const tiplistColumns = [
      {
        title: "Employee Name",
        width: "30%",
        key: "name",
        dataIndex: "name",
        render: (record, text) => {
          return <p>{text.employeeId.firstName}</p>;
        }
      },
      {
        title: "Employee Email",
        width: "30%",
        //   key: "minQuantity",
        //   dataIndex: "minQuantity",
        render: (record, text) => {
          return <p>{text.employeeId.email}</p>;
        }
      },
      {
        title: "Amount",
        width: "10%",
        key: "amount",
        dataIndex: "amount"
      },
      {
        title: "Tip time",
        width: "30%",
        key: "createdAt",
        dataIndex: "createdAt",
        render: (text, record) => {
          return moment(record.createdAt).format("MM-DD-YYYY h:mm a");
        }
      }
    ];
    return (
      <Home>
        <div className="report-container">
          <span className="item">TIP REPORT</span>

          {/* <span
            className="item"
            style={{ position: "absolute", marginLeft: "85%" }}
          >
            <button className="back-button-border" onClick={this.printbill}>
              <i
                class="fa fa-print"
                style={{ fontSize: 20, marginTop: 1 }}
                aria-hidden="true"
              ></i>
            </button>
          </span> */}
          <span className="item">
            <span id="less-visible">
              <div onClick={this.goBackButton} className="back-button-border">
                <i
                  className="fa fa-arrow-circle-left"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                />
              </div>
            </span>
          </span>
        </div>
        <Table
          dataSource={this.state.tipData}
          columns={tiplistColumns}
          pagination={false}
        />
      </Home>
    );
  }
}
