import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Switch,Select } from "antd";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
const {Option}=Select


class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      address: "",
      phoneNo: "",
      form: false
    };
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
   
    this.state = {
      designation: [],
      // permissions: [
      //   "taccountant",
      //   "managecat",
      //   "crm",
      //   "hrm",
      //   "stock",
      //   "invoice",
      //   "openaccount",
      //   "tableview",
      //   "tdDiscountPermission",
      //   "openaccountWeb",
      // ],
      permissions: props.permissions? props.permissions:[],
      taccountant: 0,
      managecat: 0,
      crm: 0,
      hrm: 0,
      stock: 0,
      invoice: 0,
      openaccount: 0,
      tableview: 0,
      kitchen: 0,
      bar: 0,
      tdDiscountPermission: 0,
      openaccountWeb:0,
      addEmpType:"EMPLOYEE"
    };

    // if (kitchenpermission && kitchenpermission == "true") {
    //   this.setState({ permissions: this.state.permissions.push("kitchen") });
    // }
    // if (barpermission && barpermission == "true") {
    //   this.setState({ permissions: this.state.permissions.push("bar") });
    // }

    this.toggleModal = this.toggleModal.bind(this);
    }
  componentDidMount() {
    // this.setState({ isLoaded: true });
    console.log("-------- componentDidMount PROPS",this.props)
   this.setState({  permissions:this.props.permissions?this.props.permissions:[]})
  //  this.props.getAnsatte().then(() => this.setState({ permissions:this.props.permissions?this.props.permissions:[]}));
    }

  componentDidUpdate(previousProps, previousState) {
    console.log("-------- componentUPDATE PROPS",this.props)
    if (previousProps.permissions!== this.props.permissions) {
      this.setState({  permissions:this.props.permissions?this.props.permissions:[]})
    }
  }
  changed(checked, item) {
    let toggledState = {};
    if (checked) {
      toggledState[item] = 1;
    } else {
      toggledState[item] = 0;
    }
    this.setState(toggledState);
  }
  addEmployee = (e) => {
    e.preventDefault();
    var data = {
      email: this.state.email,
      hrm: this.state.hrm,
      crm: this.state.crm,
      taccountant: this.state.taccountant,
      invoice: this.state.invoice,
      stock: this.state.stock,
      managecat: this.state.managecat,
      branchId: localStorage.bid,
      openaccount: this.state.openaccount,
      openaccountWeb: this.state.openaccountWeb,
      tableview: this.state.tableview,
      kitchen: this.state.kitchen,
      bar: this.state.bar,
      tdDiscountPermission: this.state.tdDiscountPermission,
      empType: this.state.addEmpType
    };
    this.props.addAnsatte(data).then(() => {
      this.props.addToggleModal(this.props.modalAddIsOpen, true);
    });
  };
  addDesignation = (e) => {
    var designation = this.state.designation.slice();
    designation.findIndex((item) => item);
    var newDes = designation.concat(e.target.value);
    this.setState({ designation: newDes });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleModal = () => {
    this.props.addToggleModal(this.props.modalAddIsOpen, false);
  };
  // for add employee form label
  render() {
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    var permissionsAlt = {
      managecat: "Manage",
      taccountant: "Arkiv",
      stock: "Lager",
      crm: "Kunder",
      hrm: "Ansatte",
      invoice: "Faktura",
      openaccount: "Opprett Konto",
      openaccountWeb:"Opprett Konto web",
      tableview: "Bord",
      tdDiscountPermission: "TD Discount",
      kitchen :"Kjøkken",
      bar: "Bar"
    };
    // if (kitchenpermission && kitchenpermission == "true") {
    //   permissionsAlt["kitchen"] = "Kjøkken";
    // }
    // if (barpermission && barpermission == "true") {
    //   permissionsAlt["bar"] = "Bar";
    // }

    const { permissions } = this.state;
    const  loggedInEmpType  = localStorage.getItem("empType");
    const isAdminLogged=localStorage.getItem("sadm")
    return (
      
      <Modal
        isOpen={this.props.modalAddIsOpen}
        onRequestClose={this.toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Legg Til Ansatte"
      >
        <div className="button-container">
          <button onClick={this.toggleModal} className="close-button">
            X
          </button>
        </div>
        <form className="add-employee">
          <li key="8">
            <center>
              <h2>Legg Til Ansatte</h2>
            </center>
          </li>
          <li key="9">
            <input
              type="text"
              onChange={this.handleChange}
              name="email"
              className="input2"
              placeholder="E-mail"
            />
            {console.log("'---------------emppppppp---",{
              isAdminLogged,
              loggedInEmpType
            })}
          
                    {(loggedInEmpType=="MANAGER" || isAdminLogged=="true")? 
                    <Select defaultValue=""
                        style={{ width: "150px", marginLeft:100 }}
                        onChange={(e)=>(this.setState({addEmpType:e}))}
                        // onClick={(e)=>(console.log("click-----------",e))}
                        value={this.state.addEmpType}
                      >
                        <Option value="EMPLOYEE">EMPLOYEE</Option>
                        <Option value="MANAGER">MANAGER</Option>
                      </Select>:<></>}
                   
          </li>
          <li key="10" className="designation-title">Ansatte Tillatelser</li>
          <li key="11" className="switches">
            {permissions.map((item) => {
              return (
                <Switch
                  checkedChildren={permissionsAlt[item]}
                  unCheckedChildren={permissionsAlt[item]}
                  onChange={(checked) => this.changed(checked, item)}
                />
              );
            })}
          </li>
          <li key="12">
            <button
              onClick={this.addEmployee}
              type="submit"
              id="submit-landing2"
            >
              SEND
            </button>
          </li>
        </form>
      </Modal>
    );
  }
}
export default AddForm;
