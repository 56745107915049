import { GET_PRODUCTS } from "../../action/types";

const ProductReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};
export default ProductReducer;
