import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo
} from "react";
import { Modal, Button } from "antd";
import appConfig from "../../helpers/appConfig";
const ImageModel = ({
  record,
  updateProductImage,
  isModelVisible,
  setIsModelVisible
}) => {
  const defaultImge = `${appConfig.s3url}/${record.thumbnail}`;
  let [ProFile, setProFile] = useState("");
  let [lader, setLoader] = useState(false);
  let [FileError, setFileError] = useState("");
  let [ProImage, setProImage] = useState(defaultImge);
  const inputFile = useRef(null);
  const handleProductChange = useCallback((e) => {
    if (e.target.files[0]) {
      let imageFile = e.target.files[0];
      let fileName = imageFile.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|svg)$/)) {
        setFileError("Please select valid image.");
        return false;
      }
      const isLt10M = imageFile.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        setFileError("Image must smaller than 10MB!");
        return false;
      }
      setProFile(imageFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setProImage(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }
  });
  const closeModel = () => {
    setIsModelVisible(false);
    setProFile("");
    setProImage(defaultImge);
  };
  const triggerFile = () => {
    inputFile.current.click();
  };
  const sendUpdate = (id) => {
    let formData = new FormData();
    if (ProFile && ProFile.size > 0) {
      formData.append("id", id);
      formData.append("image", ProFile, ProFile.name);
      setLoader(true);
      updateProductImage(formData).then(() => {
        setIsModelVisible(false);
        setProFile("");
        setLoader(false);
      });
    }
  };
  return (
    <>
      <Modal
        title={record.name}
        onCancel={closeModel}
        onOk={() => sendUpdate(record._id)}
        visible={isModelVisible}
        footer={[
          <Button key="back" onClick={closeModel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!ProFile || lader}
            onClick={() => sendUpdate(record._id)}
          >
            Upload
          </Button>
        ]}
      >
        <div style={{ marginLeft: "11%" }}>
          <img style={{ width: "85%" }} src={ProImage} onClick={triggerFile} />
          <br />
          <p style={{ color: "red", textAlign: "center" }}>{FileError}</p>
        </div>
        <input
          ref={inputFile}
          style={{ display: "none" }}
          id="files"
          accept="image/*"
          onChange={handleProductChange}
          type="file"
        />
      </Modal>
    </>
  );
};
export default ImageModel;
