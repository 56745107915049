import { GET_CASH_REPORT } from "../../action/types";
const initialState = [];

const getCashReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH_REPORT:
      return {
        ...state,
        data: action.payload.data
      };
    default:
      return state;
  }
};

export default getCashReportReducer;
