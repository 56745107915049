import { SEARCH_DRIVER } from "../../action/types";
const initialState = {
  orderId:"",
  driverId:"",
  branchId:""
};
//this is for online table booking
const searchDriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DRIVER:
      return {
        ...state,
        orderId: action.payload.orderId,
        branchId:action.payload.branchId
      };
    default:
      return state;
  }
};

export default searchDriverReducer;