import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import {
  Form,
  Row,
  Col,
  notification,
  Card,
  Modal,
  Input,
  Avatar,
  Table,
  DatePicker
} from "antd";
import TableSum from "../TableSum/TableSum";

import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import moment from "moment";
import "./Setting.css";

import appConfig from "../../helpers/appConfig";

const FormItem = Form.Item;
const { MonthPicker, WeekPicker } = DatePicker;
class SettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      previewVisible: false,
      currentPage: "setting",
      items: [],
      fileList: [],
      email: "",
      firstName: "",
      lastName: "",
      phoneNo: "",
      line1: "",
      line2: "",
      city: "",
      pin: "",
      state: "",
      country: "",
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      previewImage: "",
      tipData: [],
      startDate: "",
      endDate: "",
      tipTotal: 0
    };
  }

  componentDidMount = () => {
    this.loadProfile();
    let date = moment().date()._d;
    this.tipfunc(date);
  };

  loadProfile = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/ownprofile`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              items: result.data,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              email: result.data.email,
              phoneNo: result.data.phoneNo,
              line1: result.data.address.line1,
              line2: result.data.address.line2,
              city: result.data.address.city,
              state: result.data.address.state,
              pin: result.data.address.pin,
              country: result.data.address.country,
              id: result.data._id,
              personalInfo: result.data.personalInfo,
              profileImage: result.data.profileImage
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleEditProfile = () => {
    this.setState({ currentPage: "editProfile" });
  };

  handleViewProfile = () => {
    this.setState({ currentPage: "viewProfile" });
  };
  handleTip = () => {
    this.setState({ currentPage: "tippage" });
    // this.setState({currentPage:})
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  goBack = () => {
    this.setState({ currentPage: "setting" });
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      visible: true
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  editProfile = (e) => {
    e.preventDefault();
    var hdr = localStorage.token;
    const {
      firstName,
      lastName,
      phoneNo,
      line1,
      line2,
      city,
      pin,
      state,
      country,
      email
    } = this.state;
    const data = JSON.stringify({
      firstName,
      lastName,
      phoneNo,
      line1,
      line2,
      city,
      pin,
      state,
      country
    });
    console.log(data);
    fetch(`${appConfig.endpoint}/ownprofile`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            let user = {
              firstName,
              lastName,
              email,
              phoneNo
            };
            user = JSON.stringify(user);
            console.log(user);
            localStorage.setItem("user", user);
            this.openNotification("success", result.message);
            this.setState({ currentPage: "viewProfile" });
            this.loadProfile();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleOk = () => {
    const { newPassword, newPasswordRepeat, oldPassword } = this.state;
    if (newPassword === oldPassword) {
      this.openNotification("error", "You can not set the same password");
    } else if (newPassword !== newPasswordRepeat) {
      this.openNotification("error", "The Passwords do not match");
    } else if (newPassword === newPasswordRepeat) {
      this.changePassword();
    }
  };

  tipfunc = (date, type) => {
    let hdr = localStorage.token;
    let id = localStorage.eid;
    let startDate = moment().date()._d;
    let endDate = moment().date()._d;
    if (type == "month") {
      var dat = JSON.stringify({
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    } else {
      var dat = JSON.stringify({
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    }

    fetch(`${appConfig.endpoint}/employeetip/${id}`, {
      method: "POST",
      body: dat,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        let sum = 0;
        res.data.forEach((item) => {
          sum = Number(sum) + Number(item.amount);
        });

        this.setState({ tipData: res.data, tipTotal: sum });
      })

      .catch((err) => console.log(err));
  };

  changePassword = () => {
    const { oldPassword, newPassword } = this.state;
    console.log("works");
    var hdr = localStorage.token;
    var data = JSON.stringify({
      oPassword: oldPassword,
      nPassword: newPassword
    });
    fetch(`${appConfig.endpoint}/changePassword`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", result.message);
            this.setState({ visible: false });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleInfoChange = (e) => {
    const { id } = this.state;
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("info", file);
    var hdr = localStorage.token;
    console.log(formData);
    fetch(`${appConfig.endpoint}/profile/${id}`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("success", result.data);
          } else {
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  navigate = (route) => {
    // const { dispatch } = this.props;
    this.props.navigation.navigate(route);
    // dispatch(push(route));
  };
  handleDPChange = (e) => {
    const { id } = this.state;
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profileImage", file);
    var hdr = localStorage.token;
    console.log(formData);
    fetch(`${appConfig.endpoint}/ownprofile/${id}`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("success", result.data);
          } else {
            localStorage.setItem("profileImage", result.data);
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  render() {
    const tiplistColumns = [
      {
        title: "Ansatte Navn",
        width: "30%",
        key: "name",
        dataIndex: "name",
        render: (record, text) => {
          return <p>{text.employeeId.firstName}</p>;
        }
      },
      {
        title: "Ansatte Email",
        width: "30%",
        //   key: "minQuantity",
        //   dataIndex: "minQuantity",
        render: (record, text) => {
          return <p>{text.employeeId.email}</p>;
        }
      },
      {
        title: "Beløp",
        width: "10%",
        key: "amount",
        dataIndex: "amount"
      },
      {
        title: "Tip Tid",
        width: "30%",
        key: "createdAt",
        dataIndex: "createdAt",
        render: (text, record) => {
          return moment(record.createdAt).format("MM-DD-YYYY h:mm a");
        }
      }
    ];
    var tipSummaryColumns = [
      {
        title: "Name",
        width: "20%",
        render: () => <strong>Total</strong>
      },
      {
        title: "email",
        width: "25%",
        render: () => <strong> </strong>
      },
      {
        title: "Total",
        width: "30%",
        render: () => `Kr.${this.state.tipTotal.toFixed(2)}`
      }
    ];
    const summaryData = [
      {
        key: "-1",
        // productName: 'Total',
        // productPrice: this.state.unitPriceTotal,
        // // total: this.state.finalTotal,
        // total: 'total',
        // Total: 'total',
        // unitoftax15: this.state.total15Quantity,
        // unitoftax25: this.state.total25Quantity
        render: () => "test"
      }
    ];
    console.log(this.state);
    const regex = /(^(.)|png|jpeg|jpg)$/;
    const {
      error,
      currentPage,
      isLoaded,
      firstName,
      lastName,
      phoneNo,
      email,
      line1,
      line2,
      city,
      state,
      pin,
      country,
      oldPassword,
      newPassword,
      newPasswordRepeat,
      personalInfo
    } = this.state;

    let profileImage = localStorage.getItem("profileImage");

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <Home>
          <Loader />
        </Home>
      );
    } else if (currentPage === "setting") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">Settings</span>
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Settings
            </span>
          </div>
          <div className="different-reports">
            <div id="flex" style={{ minHeight: 40 }}>
              <button
                className="box shadow orange-bg"
                onClick={this.handleViewProfile}
              >
                <p className="text-items">Se Profil</p>
              </button>
            </div>

            <div id="flex" style={{ minHeight: 40 }}>
              <button
                className="box shadow black-bg"
                onClick={this.handleEditProfile}
              >
                <p className="text-items">Edit Profil</p>
              </button>
            </div>
          </div>

          <div className="different-reports">
            <div id="flex" style={{ minHeight: 40 }}>
              <button
                className="box shadow orange-bg"
                onClick={() => {
                  this.handleTip();
                }}
              >
                <p className="text-items">TIP RAPPORT</p>
                <p style={{ fontSize: 10 }}>Liste TIP</p>
              </button>
            </div>
          </div>
          {/* <Card title="TIP" style={{ width: 300 }}>
            <h1>Kr. 350</h1>
          </Card> */}
        </Home>
      );
    } else if (currentPage === "viewProfile") {
      return (
        <Home>
          <div className="report-container">
            <span className="item"> Profil</span>
            <span className="item">
              <span id="less-visible">HJEM / </span>
              Profil
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBack} className="back-button-border">
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <Row>
            <Col span={5} />
            <Col span={14}>
              <Card
                style={{ lineHeight: 2 }}
                title={`${firstName} ${lastName}`}
                extra={
                  <a
                    onClick={this.handleEditProfile}
                    style={{ color: "#1890ff", lineHeight: 2 }}
                  >
                    Edit
                  </a>
                }
              >
                <Avatar
                  shape="square"
                  size={80}
                  icon="user"
                  src={`${appConfig.s3url}/${profileImage}`}
                />

                <p>
                  <b>Email:</b> {email}
                </p>
                <p>
                  <b>Phone:</b> {phoneNo}
                </p>
                <h5 style={{ color: "#000000a6", margin: "5px 0 0 0" }}>
                  <b>Addresse:</b>
                </h5>
                <p>{`${line1} ${line2}`}</p>
                <p>{`${city} ${pin}`}</p>
                <p>{`${state} ${country}`}</p>
                <p style={{ fontWeight: 700 }}>Dokumenter:</p>
                <p>
                  {personalInfo.length > 0
                    ? personalInfo.map((item, index) => {
                        {
                          /* return (
                          <img
                            style={{ maxWidth: '100%' }}
                            src={`${appConfig.endpoint}/${item}`}
                            key={item}
                          />
                        ); */
                        }
                        if (item.match(regex)) {
                          return (
                            <div>
                              Dokumenter {index + 1}:
                              <img
                                style={{ maxWidth: "100%" }}
                                src={`${appConfig.s3url}/${item}`}
                                key={item}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div key={item}>
                              <a
                                href={`${appConfig.s3url}/${item}`}
                                target="_blank"
                              >
                                Dokumenter {index + 1}
                              </a>
                              <br />
                            </div>
                          );
                        }
                      })
                    : "test"}
                </p>
              </Card>
            </Col>
            <Col span={5} />
          </Row>
        </Home>
      );
    } else if (currentPage == "tippage") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">TIP RAPPORT</span>

            {/* <span
            className="item"
            style={{ position: "absolute", marginLeft: "85%" }}
          >
            <button className="back-button-border" onClick={this.printbill}>
              <i
                class="fa fa-print"
                style={{ fontSize: 20, marginTop: 1 }}
                aria-hidden="true"
              ></i>
            </button>
          </span> */}

            <span style={{ position: "absolute", marginLeft: "38%" }}>
              <DatePicker
                onChange={(date, dateString) => this.tipfunc(date)}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>
            <span style={{ position: "absolute", marginLeft: "63%" }}>
              <MonthPicker
                onChange={(date, dateString) => this.tipfunc(date, "month")}
                placeholder="Select month"
              />
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBack} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          {/* <Table
            dataSource={this.state.tipData}
            columns={tiplistColumns}
            pagination={false}
          /> */}
          <TableSum
            // loading={isLoaded ? true : false}
            columns={tiplistColumns}
            summaryColumns={tipSummaryColumns}
            data={this.state.tipData}
            summaryData={summaryData}
            onChange={(pagination) => this.pageChange(pagination)}
            bordered
          />
        </Home>
      );
    } else if (currentPage === "editProfile") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">Edit Profil</span>
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Profile
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBack} className="back-button-border">
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <Row>
            <Col span={5} />
            <Col span={14}>
              <Modal
                title="Change Password"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={400}
                okText="Save"
              >
                <Form onSubmit={this.handleOk}>
                  <FormItem>
                    <label style={{ color: "#333" }}>Old Password</label>
                    <Input
                      style={{ margin: "10px 0" }}
                      placeholder="Old Password"
                      value={oldPassword}
                      name="oldPassword"
                      onChange={this.handleChange}
                      type="password"
                    />
                  </FormItem>
                  <FormItem>
                    <label style={{ color: "#333" }}>New Password</label>
                    <Input
                      style={{ margin: "7px 0" }}
                      placeholder="New Password"
                      value={newPassword}
                      name="newPassword"
                      onChange={this.handleChange}
                      type="password"
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      style={{ margin: "7px 0" }}
                      placeholder="Repeat New Password"
                      value={newPasswordRepeat}
                      name="newPasswordRepeat"
                      onChange={this.handleChange}
                      type="password"
                    />
                  </FormItem>
                </Form>
              </Modal>
              <form
                className="add-employee add-customer edit-profile"
                onSubmit={this.editProfile}
              >
                <li>
                  <center>
                    <h2>Edit Profil</h2>
                  </center>
                </li>

                <li style={{ marginTop: "1em" }}>
                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 2% 0 8%"
                    }}
                    type="text"
                    onChange={this.handleChange}
                    name="firstName"
                    className="input2"
                    pattern="[A-Za-z]+"
                    maxlength="20"
                    placeholder="Fornavn"
                    value={firstName}
                    required
                  />
                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 8% 0 2%"
                    }}
                    type="text"
                    onChange={this.handleChange}
                    name="lastName"
                    className="input2"
                    pattern="[A-Za-z]+"
                    maxlength="20"
                    placeholder="Etternavn"
                    value={lastName}
                    required
                  />
                </li>
                <li>
                  <input
                    type="number"
                    onChange={this.handleChange}
                    name="phoneNo"
                    className="input2"
                    placeholder="Tel. Nr."
                    min="0000000000"
                    max="9999999999"
                    value={phoneNo}
                    required
                  />
                </li>
                <p style={{ color: "#333", margin: "10px 0 5px 0" }}>
                  Addresse
                </p>
                <li>
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="line1"
                    className="input2"
                    placeholder="Addresse Line 1"
                    value={line1}
                  />
                </li>
                <li>
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="line2"
                    className="input2"
                    placeholder="Addresse Line 2"
                    value={line2}
                  />
                </li>
                <li style={{ marginTop: "1em" }}>
                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 2% 0 8%"
                    }}
                    type="text"
                    onChange={this.handleChange}
                    name="city"
                    // placeholder="State"
                    pattern="[A-Za-z]+"
                    maxlength="20"
                    className="input2"
                    placeholder="By"
                    value={city}
                  />

                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 8% 0 2%"
                    }}
                    type="number"
                    onChange={this.handleChange}
                    name="pin"
                    className="input2"
                    placeholder="PIN"
                    value={pin}
                  />
                </li>
                <li>
                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 2% 0 8%"
                    }}
                    type="text"
                    onChange={this.handleChange}
                    name="state"
                    pattern="[A-Za-z]+"
                    maxlength="20"
                    className="input2"
                    value={state}
                  />

                  <input
                    style={{
                      maxWidth: "40%",
                      float: "left",
                      margin: "0 8% 0 2%"
                    }}
                    type="text"
                    onChange={this.handleChange}
                    name="country"
                    pattern="[A-Za-z]+"
                    maxlength="20"
                    className="input2"
                    placeholder="Land"
                    value={country}
                  />
                </li>
                <li style={{ display: "flex", marginTop: "20px" }}>
                  <label
                    style={{
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "50px",
                      fontWeight: 700
                    }}
                  >
                    Velg Profil Bilde
                  </label>
                  <input
                    className="file-input"
                    type="file"
                    name="info"
                    accept="image/*"
                    onChange={this.handleDPChange}
                  />
                </li>
                <li style={{ display: "flex" }}>
                  <label
                    style={{
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "50px",
                      fontWeight: 700
                    }}
                  >
                    Velg Dokumenter
                  </label>
                  <input
                    className="file-input"
                    type="file"
                    name="info"
                    accept="image/*,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={this.handleInfoChange}
                  />
                </li>
                <li
                  style={{
                    marginTop: "20px"
                  }}
                >
                  <a
                    onClick={this.showModal}
                    style={{
                      color: "#1890ff"
                    }}
                  >
                    Change Password
                  </a>
                </li>
                <li>
                  <button type="submit" id="submit-landing2">
                    Submit
                  </button>
                </li>
              </form>
            </Col>
            <Col span={5} />
          </Row>
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => ({});
const Setting = connect(mapStateToProps)(Form.create()(SettingPage));

export default Setting;
