import React from "react";
import { Table, Card, Button, Modal, Radio, Row, Col } from "antd";
import { TimePicker } from "antd";
import moment from "moment";
import Countdown from "react-countdown";
import { Icon as RIcon } from "react-icons-kit";
import { check } from "react-icons-kit/fa/check";
const { Column } = Table;
const SingleOrder = ({
  data,
  changeStatus,
  timemodalvisible,
  cancelModalvisible,
  showcancelModel,
  showtModal,
  // handleOk,
  handleCancel,
  onChangetime,
  timeString,
  onChangeCancelReason,
  cancelReason
}) => {
  console.log("getting data ranch driveout ", data);
  let totalUnitPrice = data.items.reduce(
    (acc, current) => acc + current.price,
    0
  );

  let totalQuantity = data.items.reduce(
    (acc, current) => acc + current.quantity,
    0
  );

  let totalPrice = data.items.reduce(
    (acc, current) => acc + current.totalPrice,
    0
  );

  let driveoutCharge = parseFloat(data.driveoutCharge.toFixed(3));
  const orderType = data.orderType;
  const btnstyle = {
    background: "transparent",
    border: "1px solid black",
    color: "black"
  };

  const dataSource = [
    {
      key: "1",
      totalUnitPrice: `Kr.${data.items.reduce(
        // (acc, current) => acc + current.price, // prev
        (acc, current) => acc + current.totalPrice,
        0
      )}`,
      totalExtraPrice: `Kr.${data.items.reduce(
        (acc, current) => acc + (current.extraPrice ? current.extraPrice : 0),
        0
      )}`,
      totalQuantity: data.items.reduce(
        (acc, current) => acc + current.quantity,
        0
      ),
      total: `Kr.${
        data.items.reduce(
          (acc, current) =>
            acc +
            current.totalPrice +
            (current.extraPrice ? current.extraPrice : 0),
          0
        ) + (orderType == "Drive Out" ? driveoutCharge : 0)
      }`,
      driveoutTax:
        orderType == "Drive Out"
          ? `Kr. ${(driveoutCharge - (driveoutCharge * 100) / 125).toFixed(3)}`
          : 0,
      driveoutCharge:
        orderType == "Drive Out"
          ? `Kr. ${((driveoutCharge * 100) / 125).toFixed(3)}`
          : 0
    }
  ];

  const columns = [
    {
      title: "Drive Out Tax",
      dataIndex: "driveoutTax",
      width: "10%",
      key: "driveoutTax"
    },
    {
      title: "Drive Out Charge",
      dataIndex: "driveoutCharge",
      width: "15%",
      key: "driveoutCharge"
    },
    {
      title: "Total Unit Price",
      dataIndex: "totalUnitPrice",
      width: "13%",
      key: "totalUnitPrice"
    },
    {
      title: "Total Extra Price",
      dataIndex: "totalExtraPrice",
      width: "13%",
      key: "totalExtraPrice"
    },
    { width: "10%" },

    {
      title: "Total quantity",
      dataIndex: "totalQuantity",
      width: "15%",
      key: "totalQuantity"
    },

    {
      title: "Total",
      dataIndex: "total",
      width: "13%",
      key: "total"
    }
  ];
  console.log("gg1o", data);
  const Completionist = () => (
    <span style={{ color: "red", fontWeight: "bold" }}>
      ORDER TIME COMPLETED!
    </span>
  );
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  Date.prototype.addMillisecs = function (s) {
    this.setMilliseconds(this.getMilliseconds() + s);
    return this;
  };
  var deliveryTime = data.deliveryTime;
  var compeltemilisec = new Date(data.updatedAt).addMillisecs(
    Number(deliveryTime)
  );
  var upddatesdattime = new Date(data.updatedAt);
  const productList = ({ items }) => {
    let list = "";
    if (items) {
      for (const [idx, itm] of items.entries()) {
        list += `<ul class="product_ul">`;
        list += `<li>${idx + 1}) For ${itm.quantity} ${itm.name} -</li>`;
        list += `<ul>`;
        for (const [ix, ingredents] of itm.ingredents.entries()) {
          list += `<li>${ingredents.quantity} ${ingredents.qtyUnit} ${ingredents.stockproduct.name}</li>`;
        }
        list += `</ul>`;
        list += `</ul>`;
      }
      return list;
    }
  };
  const proInst = ({ items }) => {
    let list = "";
    if (items) {
      let i = 1;
      for (const [idx, itm] of items.entries()) {
        if (itm.instruction) {
          for (const [ix, inst] of itm.instruction.entries()) {
            let extra =
              itm.extraItems && itm.extraItems[ix] ? itm.extraItems[ix] : [];
            let desInt = inst.instruction
              ? inst.instruction.replace(/(^,)|(,$)/g, "")
              : "";
            let det = [];
            if (!Array.isArray(desInt)) {
              det = desInt.split(",");
            }
            list += `<ul class="product_ul">`;
            list += `<li>${i}) For ${inst.quantity} ${inst.productName} -</li>`;
            if (desInt && det && det.length > 0) {
              // list += `<ul>`;
              list += `<ul>`;
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i> Bruksanvisning</h5>`;
              list += `<ul style="list-style-type:none;font-size:15px;">`;
              if (Array.isArray(det)) {
                for (const [eix, v] of det.entries()) {
                  list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${v}</li>`;
                }
              } else {
                list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${det}</li>`;
              }
              list += `</ul>`;
              list += `</ul>`;
              // list += `</ul>`;
            }
            list += `<ul>`;
            if (extra.length > 0) {
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i>  Ekstra produkt</h5>`;
              let ic = <RIcon size={15} icon={check} />;
              for (const [eix, exta] of extra.entries()) {
                list += `<ul style="list-style-type:none;font-size:15px;">`;
                list += `<li><i class="fa fa-check"></i> ${exta.name}</li>`;
                list += `</ul>`;
              }
            }
            list += `</ul>`;
            list += `</ul>`;
            list += `</ul>`;
            i++;
          }
        }
      }
      return list;
    }
  };
  return (
    <>
      <Row>
        <Card className="od" style={{ width: "100%", wordWrap: "break-word" }}>
          <Col span={12}>
            {data.customerId[0] && (
              <Card.Grid>
                <p>
                  <strong>OTP: {data.otp}</strong>
                </p>
                <Countdown
                  style={{ color: "red" }}
                  date={moment(data.updatedAt) + Number(deliveryTime)}
                  renderer={renderer}
                ></Countdown>
                <p>
                  Name:{" "}
                  {`${data.customerId[0].firstName} ${data.customerId[0].lastName}`}
                </p>
                <p>Phone No.: {data.customerId[0].phoneNo}</p>
                <p>Email: {data.customerId[0].email}</p>

                {data.orderStatus == "CANCEL" ? (
                  <span>
                    <p>
                      Order Status: <b style={{ color: "red" }}>CANCELLED</b>
                    </p>
                    <p style={{ color: "red" }}>
                      <b>Cancel Reason :</b>
                      {data.cancelReason}
                    </p>
                  </span>
                ) : (
                  ""
                )}
              </Card.Grid>
            )}
            {data.customerId[0] &&
              data.customerId[0].address &&
              data.orderType == "Drive Out" && (
                <Card.Grid style={{ wordWrap: "break-word" }}>
                  <p>
                    Address:
                    {data.customerId[0].address.line1}{" "}
                    {data.customerId[0].address.line2}
                  </p>
                  <p>
                    {data.customerId[0].address.city
                      ? data.customerId[0].address.city
                      : ""}{" "}
                    {data.customerId[0].address.state
                      ? "," + data.customerId[0].address.state
                      : ""}
                  </p>
                  <p>{data.customerId[0].address.pin}</p>
                  <p>{data.customerId[0].address.country}</p>
                </Card.Grid>
              )}
            {data.orderStatus !== "DELIVERED" && (
              <Card.Grid>
                {data.orderStatus === "PENDING" && (
                  <Button
                    style={btnstyle}
                    // onClick={() => changeStatus("IN-PROCESS", data._id)}
                    onClick={() => showtModal()}
                  >
                    Confirm
                  </Button>
                )}
                {data.orderStatus === "IN-PROCESS" && (
                  <Button
                    style={btnstyle}
                    onClick={() =>
                      changeStatus(
                        "DELIVERED",
                        data._id,
                        timeString,
                        data.orderType
                      )
                    }
                  >
                    Deliver
                  </Button>
                )}
                {(data.orderStatus === "PENDING" ||
                  data.orderStatus === "IN-PROCESS") && (
                  <Button style={btnstyle} onClick={() => showcancelModel()}>
                    Cancel
                  </Button>
                )}
              </Card.Grid>
            )}
          </Col>
          <Col span={12}>
            <div className="mycontent-right">
              <Card.Grid>
                <h5>Instruksjoner av kunden</h5>
                <div dangerouslySetInnerHTML={{ __html: proInst(data) }} />
              </Card.Grid>
            </div>
          </Col>

          {/* <p>Card content</p>
        <p>Card content</p> */}
        </Card>
      </Row>

      <Table
        dataSource={data.items}
        pagination={false}
        style={{ borderBlockStartColor: "red" }}
      >
        <Column title="Product Name" dataIndex="name" key="name" />
        <Column title="Item No." dataIndex="itemNo" key="itemNo" />
        <Column
          title="Unit Price"
          dataIndex="price"
          render={(text) => `Kr.${text}`}
          key="price"
        />
        <Column title="Quantity" dataIndex="quantity" key="quantity" />
        <Column
          title="Extra Item Price"
          dataIndex="extraPrice"
          render={(text, record) =>
            `Kr.${record.extraPrice ? record.extraPrice : 0}`
          }
          key="extraPrice"
        />

        <Column
          title="Total Price"
          dataIndex="totalPrice"
          render={(text, record) =>
            `Kr.${Number(text + (record.extraPrice ? record.extraPrice : 0))}`
          }
          key="totalPrice"
        />
      </Table>
      <div>
        <Modal
          title="Select Time"
          visible={timemodalvisible}
          onOk={() =>
            changeStatus("IN-PROCESS", data._id, timeString, data.orderType)
          }
          onCancel={() => handleCancel()}
        >
          <div style={{ textAlign: "center", alignSelf: "center" }}>
            <TimePicker
              style={{ width: "80%" }}
              onChange={onChangetime}
              defaultValue={moment("00:00", "HH:mm")}
              // defaultOpenValue={moment("00:00", "HH:mm")}
              format={"HH:mm"}
            />
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          title="Select Reason for cancelling order"
          visible={cancelModalvisible}
          onOk={() =>
            changeStatus("CANCEL", data._id, timeString, data.orderType)
          }
          onCancel={() => handleCancel()}
        >
          <div style={{ textAlign: "center", alignSelf: "center" }}>
            <Radio.Group
              buttonStyle="solid"
              onChange={onChangeCancelReason(cancelReason)}
              defautValue={cancelReason}
            >
              <Radio.Button value="Items Not present">
                Items Not present
              </Radio.Button>
              <Radio.Button value="No One to deliver">
                No One to deliver
              </Radio.Button>
              <Radio.Button value="Restaurant closed">
                Restaurant Closed
              </Radio.Button>
              <Radio.Button value="Not accepting orders">
                Not accepting orders
              </Radio.Button>
            </Radio.Group>
          </div>
        </Modal>
      </div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </>
  );
};

export default SingleOrder;
