import axios from "axios";
import {
  GET_DAILY_REPORT,
  SET_CURRENT_USER,
  GET_DAILY_PRINT_REPORT,
  GET_REPORT,
  VIEW_REPORT,
  ERROR_MASSAGE,
  EMPTY_SUCCESS_MESSAGE,
  EMPTY_ERROR_MASSAGE,
  GET_CASH_REPORT,
  GET_X_REPORT,
  GET_POS_REPORT,
  GET_DAILY_REPORT_DINING,
  SUCCESS_MESSAGE
} from "./types";

// import setAuthorixationToken from "../utils/setAuthorizationToken";
import appConfig from "../helpers/appConfig";
import {isServerLogout} from "../utils/isServerLogout";
import { getOnlineTableBookingCount, getOrderCount } from "./ordersAction";

export const getDailyReport = (data) => async (dispatch) => {
  const res = await axios.post(
    `${appConfig.endpoint}/api/v1/employee/invoice/daily-reports`,
    data
  );
  dispatch({ type: GET_DAILY_REPORT, payload: res.data });
};

export const getDailyReportPrint = (data) => async (dispatch) => {
  const res = await axios.post(
    `${appConfig.endpoint}/api/v1/employee/reports/print`,
    data
  );
  if (res.data.data && res.data.success === 1 && res.data.data.url) {
    window.open(res.data.data.url, "_blank");
  }
  dispatch({ type: GET_DAILY_PRINT_REPORT, payload: res.data });
};

export const getReportView = () => async (dispatch) => {
  dispatch({ type: GET_REPORT, payload: {} });
};

export const viewDailyReport = (data) => async (dispatch) => {
  dispatch({ type: VIEW_REPORT, payload: data });
};

export const getDailyXReport = (data) => async (dispatch) => {
  const res = await axios.post(`${appConfig.endpoint}/api/v1/employee/xreport/report`, data);
  dispatch({ type: GET_X_REPORT, payload: res.data });
};

export const getPosReport = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${appConfig.endpoint}/api/v1/employee/xreport/report`, data);
    dispatch({ type: GET_POS_REPORT, payload: res.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getCashReport = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/invoice/cash-reports`,
      data
    );
    dispatch({ type: GET_CASH_REPORT, payload: res.data });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getDailyReportDining = (data) => async (dispatch) => {
  const res = await axios.post(
    `${appConfig.endpoint}/api/v1/employee/invoice/daily-reports-dining`,
    data
  );
  dispatch({ type: GET_DAILY_REPORT_DINING, payload: res.data });
};

export const changeDeliveryStatusAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/invoice/changedeliverystatus`,
      data
    );
    dispatch({ type: GET_DAILY_REPORT_DINING, payload: res.data });
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "order delivered" }
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
    
  }
 
};
