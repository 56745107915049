import { SET_ONLINE_TABLE_BOOKING_COUNT } from "../../action/types";
const initialState = {
  orders: 0
};
//this is for online table booking
const onlineTableBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE_TABLE_BOOKING_COUNT:
      return {
        ...state,
        orders: action.payload
      };
    default:
      return state;
  }
};



export default onlineTableBookingReducer;
