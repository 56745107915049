import axios from "axios";
import { SAVE_COUPAN, ERROR_MASSAGE, SUCCESS_MESSAGE, GET_COUPAN_INVOICES } from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
export const saveCoupan = (coupanValue, phoneNumber) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/coupan/saverescoupan`,
      {
        coupanValue: coupanValue,
        phoneNumber: phoneNumber
      }
    );

    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { data: res.data, actionType: SAVE_COUPAN }
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getCoupan = (page=1,pageSize=10) => async (dispatch) => {
  try {
  
    let res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/coupan/getallrescoupan`,{},{
        params:{
          page:page,
          pageSize:pageSize
        }
      }
    );
    

    dispatch({
      type: "GET_COUPAN",
      data: res.data.data
    });
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { data: res.data, actionType: SAVE_COUPAN }
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getCoupanInvoices = (id) => async (dispatch) => {
  try {
  
    let res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/coupan/getcouponinvoices`,{
        params:{
          couponId:id
        }
      }
    );
    

    dispatch({
      type: GET_COUPAN_INVOICES,
      data: res.data.data
    });
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { data: res.data, actionType: GET_COUPAN_INVOICES }
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
