import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { getLagerReport } from "../../../action/arkiveActions";
import Home from "./../../Home";
import List from "./List";

const LagerReport = (props) => {
  let [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchLagerReport = async () => {
      await props.getLagerReport();
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchLagerReport();
  }, []);

  const goBackMainPage = () => {
    props.history.push("/arkiv");
  };

  return (
    <>
      <Home>
        <List
          goBackMainPage={() => goBackMainPage()}
          loading={isLoading}
          lagerReport={props.lagerReport}
        />
      </Home>
    </>
  );
};

const mapStateToProps = ({ lagerReport }) => {
  return { lagerReport };
};

export default connect(mapStateToProps, { getLagerReport })(LagerReport);
