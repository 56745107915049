import axios from "axios";
import {
  CHANGE_AVAILABILITY,
  SET_INFORMATION,
  SET_LOGO,
  GET_INFORMATION,
  UPDATE_DAY_AND_TIME,
  SUCCESS_MESSAGE,
  ERROR_MASSAGE,
  UPDATE_WEEK_AVAILABILITY
} from "./types";
import appConfig from "../helpers/appConfig";
import {isServerLogout} from "../utils/isServerLogout";
export const changeAvailability = (data) => async (dispatch) => {
  const res = await axios.post(
    `${appConfig.endpoint}/changeavailability`,
    data
  );
  dispatch({ type: CHANGE_AVAILABILITY, payload: res.data });
};

export const setProfile = (data) => async (dispatch) => {
  const res = await axios.put(`${appConfig.endpoint}/profile`, data);
  dispatch({ type: SET_INFORMATION, payload: res.data });
};

export const setLogo = (data) => async (dispatch) => {
  const res = await axios.post(`${appConfig.endpoint}/profile/logo`);
  dispatch({ type: SET_LOGO, payload: res.data });
};

export const getInformation = (bid) => async (dispatch) => {
  console.log("bid",bid)
  const res = await axios.get(
    `${appConfig.endpoint}/api/v1/employee/information/getInformation?branchId=${bid}`
  );
  dispatch({ type: GET_INFORMATION, payload: res.data });
};

export const updateDayAndTime = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/information/updateDay`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateAvailability" }
    });
  } catch (error) {
    
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateAvailability = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/information/updateAvailability`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateAvailability" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const changeavailability = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/changeavailability`,
      data
    );
    console.log("-----changeavailability",res)
    if (res.data.message =="Errors" || res.data.status=="failure") {
      console.log("------RUNNIng",res)
      
      dispatch({ type: ERROR_MASSAGE, payload:res.data });
      return
      
    } 
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data.data, actionType: "changeavailability" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateQrAvail = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/information/qrAvail`,
      data
    );
    console.log("------Validation fail",res)

    if (res.data.message =="Validation fail") {
      console.log("------RUNNIng",res)
      
      dispatch({ type: ERROR_MASSAGE, payload:res.data });
      return
      
    } 
    
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "qrAvail" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const treeDriveAvailability = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/information/treeDriveAvail`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "treeDriveAvailability" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/information/updateInfo`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateInfo" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateWeekAvailability = (data) => async (dispatch) => {
  const res = await axios.post(
    `${appConfig.endpoint}/updateweekavailability`,
    data
  );
  dispatch({ type: UPDATE_WEEK_AVAILABILITY, payload: res.data });
};
