import React from "react";
import { NavLink } from "react-router-dom";
import { Icon as RIcon } from "react-icons-kit";
import lager from "./lager.png";
import { ic_storage } from "react-icons-kit/md/ic_storage";
import {
  userTie,
  users,
  menu,
  fileText2,
  drawer,
  fileText,
  spoonKnife,
  glass,
  radioChecked
} from "react-icons-kit/icomoon";
import { table } from "react-icons-kit/fa/table";

const branchType=(localStorage.getItem("branchType"))
console.log("-----branchType---------",branchType)
const SideMenu = (props) => {
  const handleClick = () => {};
  return (
    <div className="left-menu">
      <ul className="list-group">
        {Number(localStorage.getItem("taccountant")) === 1 &&  branchType=="rest" &&(
          <li className="list-group-item">
            <NavLink to="/arkiv">
              <RIcon icon={fileText2} size={25} />
              Arkiv
            </NavLink>
          </li>
        )}
        {Number(localStorage.getItem("managecat")) === 1 && (
          <li className="list-group-item">
            <NavLink to="/menu">
              <RIcon icon={menu} size={25} />
              Meny
            </NavLink>
          </li>
        )}
        {Number(localStorage.getItem("crm")) === 1 &&  branchType=="rest" &&(
          <li className="list-group-item">
            <NavLink to="/kunder">
              <RIcon icon={users} size={25} />
              Kunder
            </NavLink>
          </li>
        )}
        {Number(localStorage.getItem("hrm")) === 1 && (
          <li className="list-group-item">
            <NavLink to="/ansatte">
              <RIcon icon={userTie} size={25} />
              Ansatte
            </NavLink>
          </li>
        )}
        {Number(localStorage.getItem("stock")) === 1 && (
          <li className="list-group-item">
            <NavLink to="/lager">
              <RIcon icon={ic_storage} size={25} />
              Lager
            </NavLink>
          </li>
        )}
        {localStorage.getItem("couponPermission") === "true" &&  branchType=="rest" && (
          <li className="list-group-item">
            <NavLink to="/coupons">
              <RIcon icon={fileText2} size={25} />
              Coupons
            </NavLink>
          </li>
        )}

        {branchType=="vendor"? <li className="list-group-item">
            <NavLink to="/vendorCities">
              <RIcon icon={fileText2} size={25} />
              Cities
            </NavLink>
          </li>:""}
       
        
        {Number(localStorage.getItem("invoice")) === 1 &&  branchType=="rest" && (
          <li className="list-group-item">
            <NavLink to="/unpaidinvoices">
              <RIcon icon={fileText} size={25} />
              Faktura
            </NavLink>
          </li>
        )}
        {Number(localStorage.getItem("kitchen")) === 1 &&
          localStorage.getItem("kitchenpermission") === "true" &&  branchType=="rest" &&(
            <li className="list-group-item">
              <NavLink to="/kitchen">
                <RIcon icon={spoonKnife} size={25} />
                Kjøkken
              </NavLink>
            </li>
          )}
        {localStorage.getItem("barpermission") === "true" &&
          Number(localStorage.getItem("bar")) === 1 &&  branchType=="rest" &&(
            <li className="list-group-item">
              <NavLink to="/bar">
                <RIcon icon={glass} size={25} />
                Bar
              </NavLink>
            </li>
          )}
        {localStorage.getItem("tdDiscount") === "true" &&
          Number(localStorage.getItem("tdDiscountPermission")) === 1 && (
            <li className="list-group-item">
              <NavLink to="/tdDiscount">
                <RIcon icon={fileText2} size={25} />
                TD Discount
              </NavLink>
            </li>
          )}
      </ul>
    </div>
  );
};
export default SideMenu;
