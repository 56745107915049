import React, { useEffect, useState } from "react";
import "./style.css";
import {
  getSupperCategory,
  getCategory,
  showWeb,
  showExtra,
  updateCategory,
  getIcons,
  updateIcon,
  deleteCategory
} from "./../../action/menuActions";
import { getProducts } from "./../../action/productsAction";
import { connect } from "react-redux";
import Layout from "../Home";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";
const Menu = (props) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [isShowWebValues, setIsShowWebValues] = useState([]);
  let [isShowExtra, setIsShowExtra] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setIsLoaded(true);
      props.getSupperCategory();
      props.getCategory().then(() => setIsLoaded(false));
    }
    fetchData();
  }, []);
  useEffect(() => {
    getIsShowValues();
    getIsShowExtra();
  }, [props.categories]);
  const getIsShowValues = () => {
    let data = [];
    if (props.categories.length) {
      for (let category of props.categories) {
        data[category._id] = category.isShowWeb;
      }
      setIsShowWebValues(data);
    }
  };
  const getIsShowExtra = () => {
    let data = [];
    if (props.categories.length) {
      for (let category of props.categories) {
        data[category._id] = category.isShowAsExtra;
      }
      setIsShowExtra(data);
    }
  };
  const redirectToProduct = (record) => {
    props.getProducts(record._id);
  };
  const renderPage = () => {
    return (
      <div id="prod-cat">
        <div className="report-container d-flex justify-content-center">
          <span className="item">Meny/ Produkt Kategorier</span>
        </div>
        <CategoryForm
          superCategory={props.superCategory}
          getIcons={props.getIcons}
          updateIcon={props.updateIcon}
          getCategory={props.getCategory}
        />
        <CategoryList
          showWeb={props.showWeb}
          showExtra={props.showExtra}
          categories={props.categories}
          isLoaded={isLoaded}
          isShowWebValues={isShowWebValues}
          setIsShowWebValues={setIsShowWebValues}
          setIsShowExtra={setIsShowExtra}
          isShowExtra={isShowExtra}
          superCategory={props.superCategory}
          updateCategory={props.updateCategory}
          getCategory={props.getCategory}
          getIcons={props.getIcons}
          icons={props.icons}
          updateIcon={props.updateIcon}
          deleteCategory={props.deleteCategory}
          redirectToProduct={redirectToProduct}
        />
      </div>
    );
  };
  return <Layout>{renderPage()}</Layout>;
};

const mapStateToProps = ({ superCategory, categories, icons }) => {
  return {
    superCategory,
    categories,
    icons
  };
};
export default connect(mapStateToProps, {
  getSupperCategory,
  getCategory,
  showWeb,
  showExtra,
  updateCategory,
  getIcons,
  updateIcon,
  deleteCategory,
  getProducts
})(Menu);
