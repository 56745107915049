import KitchenHeading from "./kitchenheading";
import React, { Fragment, useEffect } from "react";
import { Button, Col, Row, notification, Card, Badge } from "antd";
import OnlineOrder from "./onlineorder";
const KitchenTable = (props) => {
  useEffect(() => {
    console.log(props.activeTable);
  }, [props.activeTable]);

  return (
    <Fragment>
      {props.activeTable && props.activeTable.length > 0
        ? props.activeTable.map((item, index) => {
            var cntr = 0;
            console.log("cntr", cntr);
            item.items.length > 0 &&
              item.items.forEach((it, ind) => {
                if (it.sCatType == props.refer && it.status !== "PREPARED") {
                  cntr += 1;
                }
              });
            console.log("cntr after", cntr);
            if (Number(cntr) > 0) {
              return (
                <Fragment>
                  <Col
                    onClick={() => {
                      props.showTable(item);
                    }}
                    key={index}
                    className="items gutter-row"
                  >
                    <div className="button-style">
                      <Button
                        className="drinks button-size-icon"
                        shape="circle"
                      >
                        <div className="menu-options white">
                          {item.orderType === "PLU1" && item.number > 0 ? (
                            <span>
                              Table <b>{item.number}</b>
                            </span>
                          ) : (
                            <span>
                              {item.orderType} <b>{item.number}</b>
                            </span>
                          )}
                        </div>
                        <br />
                        <b>{item.employeeId.firstName}</b>
                      </Button>
                    </div>
                  </Col>
                </Fragment>
              );
            }
          })
        : ""}
    </Fragment>
  );
};
export default KitchenTable;
