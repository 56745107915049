import React from "react";
import moment from "moment";
import { Button } from "antd";
const dailyColumns = (showInvoice) => {
  return [
    {
      title: "Invoice No.",
      dataIndex: "invoiceNumber",
      key: "invoiceNumberrr",
      width: "5%",
      render: (text, record) => {
        return record.isPaid == false ? (
          <p style={{ color: "red" }}>{text}</p>
        ) : (
          text
        );
      }
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDatee@",
      width: "5%"
    },
    {
      title: "Time",
      dataIndex: "createdTime",
      key: "createdTime#",
      width: "12%"
    },
    {
      title: "Order From",
      dataIndex: "orderFrom",
      key: "orderFrom",
      width: "8%"
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType5",
      width: "10%"
    },

    {
      title: "Sub-Total",
      width: "15%",
      render: (text, record) => {
        // return `Kr.${(
        //   record.taxPrice -
        //   (
        //     record.taxPrice -
        //     (100 * record.taxPrice) / (100 + record.tax)
        //   ).toFixed(3)
        // ).toFixed(2)}`;
        return `Kr. ${record.isAppOrder ? ((record.taxPriceInc - (record.tdDiscountAbs?record.tdDiscountAbs:record.tdDiscountPickUpAbs?record.tdDiscountPickUpAbs:0)) / 1.15).toFixed(2) : 
          (record.taxPrice - (record.taxPrice - (100 * record.taxPrice) / (100 + record.tax))
          .toFixed(3)).toFixed(2)}`
      },
      key: "Sub-Total"
    },
    {
      title: "Tax",
      width: "11%",
      render: (text, record) => {
      
        return `Kr.${(
          record.taxPrice -
          (100 * record.taxPrice) / (100 + record.tax)
        ).toFixed(2)}`;
      },
      key: "tax5"
    },
    {
      title: "Drive out Charges",
      key: "DriveOutCharge",
      width: "12%",
      render: (text, record) => {
        if(record.isAppOrder) {
          return (record.tdDriveOutCharge).toFixed(2)
        }else {
          if(record.iDriveMyself){
            return `Kr. ${(record.driveoutCharge).toFixed(2)}`
          }
           if (record.orderType == "Drive Out") {
            var extra = record.extraDriveOut ? record.extraDriveOut : 0;
            return `Kr. ${(record.driveoutCharge + extra).toFixed(2) || 0}`;
          } else {
            return `Kr. 0`;
          }
        }
      }
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPriceaerev",
      width: "15%",
      render: (text, record) => {
          return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
      }
    },
    {
      title: "Action",
      width: "5%",
      key: "Actionnn",
      render: (text, record) => (
        <Button
          onClick={() => showInvoice(record.invoiceNumber)}
          className="mouse-hover"
        >
          <i className="fa fa-eye"></i>
        </Button>
      )
    }
  ];
};

const dailySummaryColumns = (
  totalSubtotal,
  totalTax,
  totalDriveoutCharge,
  totalTaxPrice
) => {
  return [
    {
      title: "emp",
      width: "7%",
      key: "empadsf",
      render: () => ``
    },
    {
      title: "Total",
      width: "9%",
      key: "Totaldes",
      render: () => <strong>Total</strong>
    },
    {
      title: "emp",
      width: "10%",
      key: "emp1adfa"
    },
    {
      title: "emp",
      width: "9.4%",
      key: "empdafd2"
    },
    {
      title: "Sub-Total",
      width: "13%",
      key: "12.7%de%",
      render: () => `Kr.${totalSubtotal.toFixed(2)}`,
      key: "Sub-Total1"
    },
    {
      title: "Tax",
      key: "9.4%yr",
      width: "11%",
      render: () => `Kr.${totalTax.toFixed(2)}`
    },
    {
      title: "total drive out",
      width: "11%",
      key: "totalDriveOutt",
      render: () => {
        return `Kr.${(totalDriveoutCharge).toFixed(2)}`;
      }
    },
    {
      title: "Total",
      key: "Totalll",
      width: "13%",
      render: () => `Kr.${totalTaxPrice.toFixed(2)}`
    },
    {
      key: "emp4#$",
      title: "emp",
      width: "9%"
    }
  ];
};
const dailyColumnsNew = (showInvoice) => {
  return [
    {
      title: "Invoice No.",
      dataIndex: "invoiceNumber",
      key: "invoiceNumberrr",
      width: "5%",
      render: (text, record) => {
        return record.isPaid == false ? (
          <p style={{ color: "red" }}>{text}</p>
        ) : (
          text
        );
      }
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDatee@",
      width: "5%"
    },
    {
      title: "Time",
      dataIndex: "createdTime",
      key: "createdTime#",
      width: "12%"
    },
    {
      title: "Order From",
      dataIndex: "orderFrom",
      key: "orderFrom",
      width: "8%"
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType5",
      width: "10%"
    },

    // {
    //   title: "Sub-Total",
    //   width: "15%",
    //   render: (text, record) => {
        
    //     // return `Kr.${(
    //     //   record.taxPrice -
    //     //   (
    //     //     record.taxPrice -
    //     //     (100 * record.taxPrice) / (100 + record.tax)
    //     //   ).toFixed(3)
    //     // ).toFixed(2)}`;
    //     return `Kr. ${record.isAppOrder ? ((record.taxPriceInc - (record.tdDiscountAbs?record.tdDiscountAbs:record.tdDiscountPickUpAbs?record.tdDiscountPickUpAbs:0)) / 1.15).toFixed(2) : 
    //       (record.taxPrice - (record.taxPrice - (100 * record.taxPrice) / (100 + record.tax))
    //       .toFixed(3)).toFixed(2)}`
    //   },
    //   key: "Sub-Total"
    // },
    // {
    //   title: "Tax",
    //   width: "11%",
    //   render: (text, record) => {
      
    //     return `Kr.${(
    //       record.taxPrice -
    //       (100 * record.taxPrice) / (100 + record.tax)
    //     ).toFixed(2)}`;
    //   },
    //   key: "tax5"
    // },
    {
      title: "Sub-Total",
      width: "15%",
      render: (text, record) => {
        let discounTd = record.tdDiscountAbs ? record.tdDiscountAbs : 
        record.tdDiscountPickUpAbs ? record.tdDiscountPickUpAbs
        : 0;
        return`Kr. ${
          record.isAppOrder 
          && record.orderType == "reserveTable"
            ? ((record.taxPriceInc - discounTd) / 1.25).toFixed(2)
            :
              record.isAppOrder 
            ? ((record.taxPriceInc - discounTd) / 1.15).toFixed(2)
            :
              (
              record.taxPrice -
                (
                  record.taxPrice -
                  (100 * record.taxPrice) / (100 + record.tax)
                ).toFixed(3)
              ).toFixed(2)
        }`
      },
      key: "Sub-Total"
    },
    {
      title: "Tax",
      width: "11%",
      render: (text, record) => {
        let discounTd = record.tdDiscountAbs ? record.tdDiscountAbs : 
        record.tdDiscountPickUpAbs ? record.tdDiscountPickUpAbs
        : 0;
        return `Kr. ${
          record.isAppOrder 
          && record.orderType == "reserveTable"
            ? (
            record.taxPriceInc -
              discounTd -
              ((record.taxPriceInc - discounTd) / 1.25).toFixed(2)
            ).toFixed(2)
            :
        record.isAppOrder
          ? (
            record.taxPriceInc -
              discounTd -
              ((record.taxPriceInc - discounTd) / 1.15).toFixed(2)
            ).toFixed(2)
          : (
            record.taxPrice -
              (100 * record.taxPrice) / (100 + record.tax)
            ).toFixed(2)
      }`
        // return `Kr.${(
        //   record.taxPrice -
        //   (100 *  record.taxPrice) / (100 + record.tax)
        // ).toFixed(2)}`;
      },
      key: "tax5"
    },
    {
      title: "Drive out Charges",
      key: "DriveOutCharge",
      width: "12%",
      render: (text, record) => {
        if(record.isAppOrder) {
          return (record.tdDriveOutCharge).toFixed(2)
        }else {
        if(record.iDriveMyself){
            return `Kr. ${(record.driveoutCharge).toFixed(2)}`
        }
        if (record.orderType == "Drive Out") {
          var extra = record.extraDriveOut ? record.extraDriveOut : 0;
          return `Kr. ${(record.driveoutCharge + extra).toFixed(2) || 0}`;
        } else {
          return `Kr. 0`;
        }
      }
    },
  },
    // {
    //   title: "Service Charges",
    //   key: "tdServiceCharge",
    //   width: "12%",
    //   render: (text, record) => {
    //         return `Kr. ${(record.tdServiceCharge).toFixed(2) || 0}`;
    //     }
    // },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPriceaerev",
      width: "15%",
      render: (text, record) => {
          return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
      }
    },
    {
      title: "Action",
      width: "5%",
      key: "Actionnn",
      render: (text, record) => (
        <Button
          onClick={() => showInvoice(record.invoiceNumber)}
          className="mouse-hover"
        >
          <i className="fa fa-eye"></i>
        </Button>
      )
    }
  ];
};

const dailySummaryColumnsNew = (
  totalSubtotal,
  totalServiceCharge,
  totalTax,
  totalDriveoutCharge,
  totalTaxPrice
) => {
  return [
    {
      title: "emp",
      width: "7%",
      key: "empadsf",
      render: () => ``
    },
    {
      title: "Total",
      width: "9%",
      key: "Totaldes",
      render: () => <strong>Total</strong>
    },
    {
      title: "emp",
      width: "10%",
      key: "emp1adfa"
    },
    {
      title: "emp",
      width: "9.4%",
      key: "empdafd2"
    },
    {
      title: "Sub-Total",
      width: "13%",
      key: "12.7%de%",
     // render: () => `Kr.${totalSubtotal.toFixed(2)}`,
      key: "Sub-Total1"
    },
    {
      title: "Tax",
      key: "9.4%yr",
      width: "11%",
     // render: () => `Kr.${totalTax.toFixed(2)}`
    },
    {
      title: "total drive out",
      width: "11%",
      key: "totalDriveOutt",
      // render: () => {
      //   return `Kr.${(totalDriveoutCharge).toFixed(2)}`;
      // }
    },
    // totalServiceCharge
    {
      title: "total service charge",
      width: "11%",
      key: "totalServiceCharge",
      // render: () => {
      //   return `Kr.${(totalServiceCharge).toFixed(2)}`;
      // }
    },
    {
      title: "Total",
      key: "Totalll",
      width: "13%",
      render: () => `Kr.${totalTaxPrice.toFixed(2)}`
    },
    {
      key: "emp4#$",
      title: "emp",
      width: "9%"
    }
  ];
};
const summaryData = () => {
  return [
    {
      key: "-1",
      render: () => "test"
    }
  ];
};

const xColumns = (items) => {
  return [
    {
      title: "Sr. No.",
      key: "Sr.No.@#",
      editable: false,
      width: "8%",
      render: (text, record, index) => {
        return items.indexOf(record) + 1;
      }
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productNameeee",
      width: "15%"
    },
    {
      title: "Tax",
      key: "14%@#",
      width: "14%",
      children: [
        {
          title: "15%",
          width: "13%",
          key: "25%",
          dataIndex: "taxPrice15",
          render: (text) => {
            return text ? `Kr.${text.toFixed(2)}` : text;
          }
        },
        {
          title: "25%",
          width: "13%",
          key: "25%%%",
          dataIndex: "taxPrice25",
          render: (text) => {
            return `Kr.${text.toFixed(2)}`;
          }
        }
      ]
    },
    {
      title: "Unit Price",
      dataIndex: "productPrice",
      key: "productPriceeefer",
      width: "13%",
      render: (text, record) => {
        return `Kr.${
          text
            ? (
                record.total -
                (record.taxPrice15 && record.taxPrice25
                  ? record.taxPrice15 + record.taxPrice25
                  : record.taxPrice15
                  ? record.taxPrice15
                  : record.taxPrice25) -
                (record.extraPrice ? record.extraPrice : 0)
              ).toFixed(2)
            : ""
        }`;
      }
    },
    {
      title: "Quantity",
      width: "13%",
      key: "Quantityasde@#",
      children: [
        {
          title: "15%",
          dataIndex: "unitoftax15",
          key: "unitoftax15dere"
        },
        {
          title: "25%",
          dataIndex: "unitoftax25",
          key: "unitoftax25afda"
        }
      ]
    },
    {
      title: "Discount",
      key: "discountPriceertew",
      dataIndex: "discountPrice",
      width: "12%",
      render: (text) => {
        return `Kr.${text.toFixed(2)}`;
      }
    },
    {
      title: "Extra Price",
      key: "extraPrice@#$",
      dataIndex: "extraPrice",
      width: "12%",
      render: (text, record) => {
        return record.extraPrice
          ? `Kr.${record.extraPrice.toFixed(2)}`
          : `Kr.0`;
      }
    },
    {
      title: "Total",
      key: "totalllkh",
      dataIndex: "total",
      width: "13%",
      render: (text, record) => {
        var dcount = 0;
        var invoiceNumberArr = [];
        return `Kr.${text ? text.toFixed(2) : ""}`;
      }
    }
  ];
};
const getTaxItem = (tax, taxObj, type = "tx") => {
  // console.log("taxObj==>",taxObj)
  const oj = taxObj && taxObj.find((ob) => ob.tax == tax);
  // console.log("oj==>",oj)
  return type === "tx"
    ? oj
      ? `Kr.${oj.taxPrice.toFixed(2)}`
      : "-"
    : oj
    ? oj.unitOfTax
    : "-";
};
const calTotal = (record) => {
  return (
    record.total -
    record.taxObj.reduce((total, row) => {
      return total + row.taxPrice;
    }, 0)
  ).toFixed(2);
};
const posColumns = (items, allTax) => {
  let txObj = [];
  if (allTax.length)
    for (let tx of allTax) {
      let ob = {
        title: `${tx}%`,
        width: "13%",
        key: `taxPrice${tx}@#fg`,
        dataIndex: `taxObj${tx}`,
        render: (text, record) => {
          return getTaxItem(tx, record.taxObj, "tx");
        }
      };
      txObj.push(ob);
    }
  let txUn = [];
  if (allTax.length)
    for (let tx of allTax) {
      let ob = {
        title: `${tx}%`,
        width: "13%",
        key: `unitoftax${tx}`,
        dataIndex: `unit${tx}`,
        render: (text, record) => {
          return getTaxItem(tx, record.taxObj, "unit");
        }
      };
      txUn.push(ob);
    }
  return [
    {
      title: "Sr. Nr.",
      key: "Sr.Nr...",
      editable: false,
      width: "8%",
      render: (text, record, index) => {
        return items.indexOf(record) + 1;
      }
    },
    {
      title: "Navn",
      dataIndex: "productName",
      key: "productNameade@#",
      width: "15%",
      render: (text) =>{
        return text.length > 20 ? text.substr(0,20) + "..."  : text
      }
    },
    {
      title: "Mva",
      key: "Mvass##",
      width: "14%",
      children: txObj
      // children: [
      //   {
      //     title: "15%",
      //     width: "13%",
      //     key:"taxPrice15@#fg",
      //     dataIndex: "taxPrice15",
      //     render: (text,record) => {
      //       return `Kr.${text && text.toFixed(2)}`;
      //     }
      //   },
      //   {
      //     title: "25%",
      //     width: "13%",
      //     key:"taxPrice25@#gh",
      //     dataIndex: "taxPrice25",
      //     render: (text) => {
      //       return `Kr.${text && text.toFixed(2)}`;
      //     }
      //   }
      // ]
    },
    {
      title: "Enhet Pris",
      dataIndex: "productPrice",
      key: "productpricebfh@df#",
      width: "13%",
      render: (text, record) => {
        return `Kr ${calTotal(record)}`;
        // return `Kr.${
        //   text
        //     ? (
        //         record.total -
        //         (record.taxPrice15 && record.taxPrice25
        //           ? record.taxPrice15 + record.taxPrice25
        //           : record.taxPrice15
        //           ? record.taxPrice15
        //           : record.taxPrice25)
        //       ).toFixed(2)
        //     : ""
        // }`;
      }
    },
    {
      title: "Antall",
      key: "Antallll",
      width: "13%",
      children: txUn
      // children: [
      //   {
      //     title: "15%",
      //     dataIndex: "unitoftax15",
      //     key: "unitoftax15@gfsfdg"
      //   },
      //   {
      //     title: "25%",
      //     dataIndex: "unitoftax25",
      //     key: "unitoftax25@ryrg"
      //   }
      // ]
    },
    {
      title: "Rabatt",
      key: "discountPrice@hght",
      dataIndex: "discountPrice",
      width: "12%",
      render: (text) => {
        return `Kr.${text && text.toFixed(2)}`;
      }
    },
    {
      title: "Ekstra varepris",
      key: "extraPrice",
      dataIndex: "extraPrice",
      width: "12%",
      render: (text,record) => {
        console.log("textttt====>",text)
        if (text) {
          return `Kr.${text && text.toFixed(2)}`;
        }
        return `Kr.0`;
      }
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      width: "13%",
      render: (text, record) => {
        console.log("record in xreport====>",record)
        // let tamt = record.extraPrice ? record.extraPrice + Number(text) : text;
        let tamt = Number(record.total) 
        return `Kr.${tamt ? tamt.toFixed(2) : ""}`;
      }
    }
  ];
};
const posSummaryColumns = (
  totalTax,
  allTax,
  tatalOrdersPrice,
  unitPriceTotal,
  totalDiscount,
  totalExtra,
  finalTotal
) => {
  let txObj = [];
  if (allTax.length)
    for (let tx of allTax) {
      let ob = {
        title: `Total${tx}% Mva`,
        width: "13%",
        key: `Totall${tx}Mva`,
        render: () => {
          return getTaxItem(tx, totalTax, "tx");
        }
      };
      txObj.push(ob);
    }
  let txUn = [];
  if (allTax.length)
    for (let tx of allTax) {
      let ob = {
        title: `Total${tx}% Unit`,
        width: "13%",
        key: `Totall${tx}unit`,
        render: () => {
          return getTaxItem(tx, totalTax, "unit");
        }
      };
      txUn.push(ob);
    }
  return [
    {
      title: "Sr. Nr.",
      key: "Sr.Nr.",
      width: "7%"
    },
    {
      title: "Navn",
      key: "Navn",
      width: "15%",
      render: () => <strong>Total</strong>
    },

    {
      title: "Mva",
      width: "14%",
      children: txObj
      // [
        // {
        //   title: "Totall 15% Mva",
        //   key: "Totall15%Mva",
        //   render: () => `Kr.${total15Tax.toFixed(2)}`
        // },
        // {
        //   title: "Totall 25% Mva",
        //   key: "Totall25%Mva",
        //   render: () =>
        //     `Kr.${(
        //       Number(total25Tax) +
        //       Number(tatalOrdersPrice) -
        //       Number(tatalOrdersPrice * 100) / 125
        //     ).toFixed(2)}`
        // }
      // ]
    },

    {
      title: "Total Antall Pris",
      key: "TotallAntallPris",
      width: "12%",
      render: () =>
        `Kr.${(unitPriceTotal + parseInt(tatalOrdersPrice * 100) / 125).toFixed(
          2
        )}`
    },
    {
      title: "Total Antall",
      key: "TotallAntall",
      width: "17%",
      children:txUn
      //  [
        // {
        //   title: "15%",
        //   dataIndex: "unitoftax15",
        //   key: "unitoftax15",
        //   render: () => total15Quantity
        // },
        // {
        //   title: "25%",
        //   dataIndex: "unitoftax25",
        //   key: "unitoftax25",
        //   render: () => total25Quantity
        // }
      // ]
    },
    {
      title: "Rabatt",
      key: "Rabatt",
      width: "10%",
      render: () => `Kr.${totalDiscount.toFixed(2)}`
    },
    {
      title: "Ekstra varepris",
      key: "EkstraVarepris",
      width: "13%",
      render: () => `Kr.${totalExtra.toFixed(2)}`
    },
    {
      title: "Total",
      key: "Totall",
      width: "14%",
      render: () => `Kr.${(finalTotal + tatalOrdersPrice).toFixed(2)}`
    }
  ];
};
const posDriveoutColumns = [
  {
    title: "Total Ordre",
    dataIndex: "dcount",
    width: "10%",
    key: "dcount"
  },
  {
    title: "Utkjøring Mva",
    dataIndex: "driveoutTax",
    width: "10%",
    key: "driveoutTax"
  },
  {
    title: "Utkjøring Pris",
    dataIndex: "driveoutCharge",
    width: "15%",
    key: "driveoutCharge"
  },

  {
    title: "Total Utkjøring Pris",
    dataIndex: "totalDcharge",
    width: "13%",
    key: "totalDcharge"
  }
];
const driveOutColumns = [
  {
    title: "Total Orders",
    dataIndex: "dcount",
    width: "10%",
    key: "dcount"
  },
  {
    title: "Drive Out Tax",
    dataIndex: "driveoutTax",
    width: "10%",
    key: "driveoutTax"
  },
  {
    title: "Drive Out Charge",
    dataIndex: "driveoutCharge",
    width: "15%",
    key: "driveoutCharge"
  },

  {
    title: "Total Drive Out Charge",
    dataIndex: "totalDcharge",
    width: "13%",
    key: "totalDcharge"
  }
];

const summaryColumns = (
  total15Tax,
  total25Tax,
  tatalOrdersPrice,
  unitPriceTotal,
  dcount,
  total15Quantity,
  total25Quantity,
  totalDiscount,
  totalExtra,
  finalTotal
) => {
  return [
    {
      title: "Name",
      key: "Name",
      width: "23%",
      render: () => <strong>Total + Total Drive Out</strong>
    },
    {
      title: "Tax",
      key: "Tax",
      width: "13%",
      children: [
        {
          title: "Total 15% Tax",
          key: "Total5%Tax",
          width: "13%",
          render: () => `Kr.${total15Tax.toFixed(2)}`
        },
        {
          title: "Total 25% Tax",
          key: "Total25%Tax",
          width: "12%",
          render: () => {
            return `Kr.${(
              Number(total25Tax) +
              Number(tatalOrdersPrice) -
              Number(tatalOrdersPrice * 100) / 125
            ).toFixed(2)}`;
          }
        }
      ]
    },

    {
      title: "Total Unit Price",
      key: "TotalUnitPrice",
      width: "13%",
      render: () => {
        return `Kr.${(
          unitPriceTotal + (dcount ? parseInt(tatalOrdersPrice * 100) / 125 : 0)
        ).toFixed(2)}`;
      }
    },
    {
      title: "Total Quantity",
      width: "8%",
      children: [
        {
          title: "15%",
          dataIndex: "unitoftax15",
          width: "7%",
          key: "unitoftax15",
          render: () => total15Quantity
        },
        {
          title: "25%",
          width: "7%",
          dataIndex: "unitoftax25",
          key: "unitoftax25",
          render: () => total25Quantity
        }
      ]
    },
    {
      title: "Discount",
      key: "Discount",
      width: "12%",
      render: () => `Kr.${totalDiscount.toFixed(2)}`
    },
    {
      key: "EkstraVarepris",
      title: "Ekstra varepris",
      width: "13%",
      render: () => `Kr.${totalExtra.toFixed(2)}`
    },
    {
      key: "FinalTotal",
      title: "Final Total",
      width: "14%",
      render: () => `Kr.${(finalTotal + tatalOrdersPrice).toFixed(2)}`
    }
  ];
};

const columns = [
  {
    title: "Total Orders",
    dataIndex: "dcount",
    width: "10%",
    key: "dcount"
  },
  {
    title: "Drive Out Tax",
    dataIndex: "driveoutTax",
    width: "10%",
    key: "driveoutTax"
  },
  {
    title: "Drive Out Charge",
    dataIndex: "driveoutCharge",
    width: "15%",
    key: "driveoutCharge"
  },

  {
    title: "Total Drive Out Charge",
    dataIndex: "totalDcharge",
    width: "13%",
    key: "totalDcharge"
  }
];

const LagerReportColumn = () => [
  {
    key: "productNameLager",
    title: "Produkt Navn",
    width: "33.33%",
    dataIndex: "name"
  },
  {
    key: "Min. Mengde Lager",
    title: "Min. Mengde",
    width: "33.33%",
    dataIndex: "newMinQty"
  },
  {
    key: "Mengde Lager",
    title: "Mengde",
    width: "33.33%",
    dataIndex: "newQty"
  }
];
const invoiceColumn = [
  {
    title: "Sr Nr.",
    dataIndex: "no",
    key: "noo"
  },
  {
    title: "Navn",
    dataIndex: "name",
    key: "Navninvoice"
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    key: "QtyInvoice"
  },
  {
    title: "Pris",
    dataIndex: "price",
    key: "PrisInvoice"
  },
  {
    title: "Ekstra vare Pris",
    dataIndex: "extraPrice",
    key: "InvoiceEkstraVarePris"
  },
  {
    title: "Beløp",
    dataIndex: "amount",
    key: "BelopInvoice"
  }
];

const cashReportColumn = () => [
  {
    title: "Employee Name",
    width: "33.33%",
    key: "Employee Name",
    render: (text, record) => {
      return (
        <b>{`${record.employeeId.firstName} ${record.employeeId.lastName}`}</b>
      );
    }
  },
  {
    title: "Email",
    key: "Email",
    width: "33.33%",
    render: (text, record) => {
      return <b>{record.employeeId.email}</b>;
    }
  },
  {
    title: "Date And Time",
    key: "Date And Time",
    width: "33.33%",

    render: (text, record) => {
      return moment(record.createdAt).format("MM-DD-YYYY h:mm a");
    }
  }
];
const employeesTipColumn = () => [
  {
    title: "NAVN",
    key: "EmployeeTipNavn",
    width: "20%",
    render: (text, record) => {
      return <b>{record.firstName + " " + record.lastName}</b>;
    }
  },
  {
    title: "EMAIL",
    key: "EmployeeTipEmail",
    width: "20%",
    render: (text, record) => {
      return <b>{`${record.email}`}</b>;
    }
  },
  {
    title: "CASH",
    key: "EmployeeTipCash",
    width: "20%",
    render: (text, record) => {
      return <b>{`Kr.${Number(record.cash).toFixed(2)}`}</b>;
    }
  },
  {
    title: "BANK",
    key: "EmployeeTipBank",
    render: (text, record) => {
      return <b>{`Kr.${Number(record.bank).toFixed(2)}`}</b>;
    }
  },
  {
    title: "TOTAL",
    key: "EmployeeTipTotal",
    width: "20%",
    render: (text, record) => {
      return <b>{`Kr.${Number(record.total).toFixed(2)}`}</b>;
    }
  }
];
const TipReportSummary = (totalEmployeesTip) => [
  {
    title: "TipReportTotalSummary",
    key: "TipReportTotalSummary",
    width: "20%",
    render: () => <b>Total</b>
  },
  {
    title: "TipReportSummarySpace1",
    key: "TipReportSummarySpace1",
    width: "20%",
    render: () => <b>{""}</b>
  },
  {
    title: "TipReportSummarySpace2",
    key: "TipReportSummarySpace2",
    width: "20%",
    render: () => <b>{""}</b>
  },
  {
    title: "TipReportSummarySpace3",
    key: "TipReportSummarySpace3",
    width: "20%",
    render: () => <b>{""}</b>
  },
  {
    title: "TipReportTotal",
    key: "TipReportTotall",
    width: "20%",
    render: () => `kr.${Number(totalEmployeesTip).toFixed(2)}`
  }
];

const unpaidInvoicesTable = (branchInfo, showInvoice) => [
  {
    title: "Faktura Nr.",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber"
  },
  {
    title: "Dato",
    dataIndex: "createdDate",
    key: "createdDate"
  },
  {
    title: "Tid",
    dataIndex: "createdTime",
    key: "createdTime"
  },
  {
    title: "Ordre Type",
    dataIndex: "orderType",
    key: "orderType"
  },
  {
    title: "Total",
    dataIndex: "taxPrice",
    key: "taxPrice",
    render: (text, record) => {
      // return `Kr.${this.branchInfo.driveoutCharge + parseInt(text)}`;
      if (record.orderType === "Drive Out") {
        return `Kr.${(
          parseFloat(branchInfo.driveoutCharge) + parseFloat(text)
        ).toFixed(2)}`;
      } else {
        return `Kr.${parseFloat(text).toFixed(2)}`;
      }
    }
  },
  {
    title: "Faktura",
    key: "_id",
    render: (text, record) => (
      <Button onClick={() => showInvoice(record.invoiceNumber)}>View</Button>
    )
  }
];

const dailyDiningColumns = (showInvoice) => {
  return [
    {
      title: "Invoice No.",
      dataIndex: "invoiceNumber",
      key: "invoiceNumberrr",
      width: "5%",
      render: (text, record) => {
        return record.isPaid == false ? (
          <p style={{ color: "red" }}>{text}</p>
        ) : (
          text
        );
      }
    },
    {
      title:"Table No.",
      dataIndex:"tableNumber",
      key:"tableNumber",
      width: "5%",
      render: (text, record) => {
        return record.isOnlineDelivered == false ? (
          <p style={{ color: "blue" }}>{record.tableNumber}</p>
        ) : (
          <p>{record.tableNumber}</p>
        );
      }
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDatee@",
      width: "5%"
    },
    {
      title: "Time",
      dataIndex: "createdTime",
      key: "createdTime#",
      width: "12%"
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType5",
      width: "10%"
    },

    {
      title: "Sub-Total",
      width: "15%",
      render: (text, record) => {
        return `Kr.${(
          record.taxPrice -
          (
            record.taxPrice -
            (100 * record.taxPrice) / (100 + record.tax)
          ).toFixed(3)
        ).toFixed(2)}`;
      },
      key: "Sub-Totalll"
    },
    {
      title: "Tax",
      width: "11%",
      render: (text, record) => {
        return `Kr.${(
          record.taxPrice -
          (100 * record.taxPrice) / (100 + record.tax)
        ).toFixed(2)}`;
      },
      key: "tax5"
    },
    {
      title: "Drive out Charge",
      key: "DriveOutChargeadfa",
      width: "12%",
      render: (text, record) => {
        if (record.orderType == "Drive Out") {
          var extra = record.extraDriveOut ? record.extraDriveOut : 0;
          return `Kr. ${(record.driveoutCharge + extra).toFixed(2) || 0}`;
        } else {
          return `Kr. 0`;
        }
      }
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPriceaerev",
      width: "15%",
      render: (text, record) => {
        return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
      }
    },
    {
      title: "Action",
      width: "5%",
      key: "Actionnn",
      render: (text, record) => (
        <Button
          onClick={() => showInvoice(record.invoiceNumber)}
          className="mouse-hover"
        >
          <i className="fa fa-eye"></i>
        </Button>
      )
    }
  ];
};

export {
  dailySummaryColumns,
  dailyColumns,
  driveOutColumns,
  summaryColumns,
  summaryData,
  columns,
  xColumns,
  posColumns,
  posSummaryColumns,
  posDriveoutColumns,
  LagerReportColumn,
  invoiceColumn,
  cashReportColumn,
  employeesTipColumn,
  TipReportSummary,
  unpaidInvoicesTable,
  dailyDiningColumns,
  dailyColumnsNew,
  dailySummaryColumnsNew
};
