import React, { Component } from "react";
import Home from "../Home/Home";
import "./stock.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import StockHistory from "../StockHistory/StockHistory";
import { notification } from "antd";

import appConfig from "../../helpers/appConfig";

class StockComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "reports",
      error: false,
      isLoaded: true,
      PaginationNo: 1,
      stockInData: [],
      stockOutData: []
    };
  }

  goBack = () => {
    this.setState({ currentPage: "reports" });
  };
  componentDidMount() {
    const now = new Date();
    this.loadHistory([
      { _d: now.setHours(0, 0, 0) },
      { _d: now.setHours(23, 59, 59) }
    ]);
  }

  loadHistory = (date) => {
    var hdr = localStorage.token;
    const startDate = new Date(date[0]._d).setHours(0, 0, 0);
    let data = JSON.stringify({ startDate, endDate: date[1]._d });
    console.log("date", data);
    this.setState({ isLoaded: false });
    fetch(`${appConfig.endpoint}/stockhistory`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            // // Magic, Do Not Touch
            // let data = result.data.map(item => ({
            //   ...item,
            //   createdDate: new Date(item.created).toLocaleDateString(),
            //   createdTime: new Date(item.created).toLocaleTimeString(),
            //   taxPrice: 'Kr.' + item.taxPrice.toFixed(3)
            // }));
            // var invoiceToLoad = data.reverse();
            // this.setState({
            //   isLoaded: true,
            //   stockInData: result.data.stockIn.reverse(),
            //   stockOutData: result.data.stockOut.reverse()
            // });

            let stockIn = result.data.stockIn.map((item) => ({
              ...item,
              created:
                new Date(item.created).toLocaleDateString() +
                " " +
                new Date(item.created).toLocaleTimeString()
            }));

            let stockOut = result.data.stockOut.map((item) => ({
              ...item,
              created:
                new Date(item.created).toLocaleDateString() +
                " " +
                new Date(item.created).toLocaleTimeString()
            }));

            this.setState({
              isLoaded: true,
              stockInData: stockIn,
              stockOutData: stockOut
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  stockHistory = (currentPage) => {
    this.setState({ currentPage });
    const now = new Date();
    this.loadHistory([
      { _d: now.setHours(0, 0, 0) },
      { _d: now.setHours(23, 59, 59) }
    ]);
  };

  render() {
    const inColumns = [
      {
        title: "Sr. No.",
        editable: false,
        width: "10%",
        render: (text, record, index) =>
          //? for correct sr no., check antd pagination and table/pagination docs
          //? index + (currentpage - 1)*10
          {
            return index + (this.state.PaginationNo - 1) * 10 + 1;
          }
      },
      {
        title: "Tid",
        dataIndex: "created",
        key: "created",
        width: "25%"
      },
      {
        title: "Produkt Navn",
        dataIndex: "productName",
        key: "productName",
        width: "15%"
      },
      {
        title: "Antall",
        dataIndex: "quantity",
        key: "quantity",
        width: "15%"
      },
      {
        title: "Lager Inn Via",
        dataIndex: "employeeName",
        key: "emplyeeName",
        width: "20%"
      }
    ];

    const outColumns = [
      {
        title: "Sr. Nr.",
        editable: false,
        width: "10%",
        render: (text, record, index) =>
          //? for correct sr no., check antd pagination and table/pagination docs
          //? index + (currentpage - 1)*10
          {
            return index + (this.state.PaginationNo - 1) * 10 + 1;
          }
      },
      {
        title: "Tid",
        dataIndex: "created",
        key: "created",
        width: "25%"
      },
      {
        title: "Produkt Navn",
        dataIndex: "productName",
        key: "productName",
        width: "15%"
      },
      {
        title: "Antall",
        dataIndex: "quantity",
        key: "quantity",
        width: "12%"
      },
      {
        title: "Lager Inn Via",
        dataIndex: "employeeName",
        key: "emplyeeName",
        width: "18%"
      },
      {
        title: "Lager Tildelt Til ",
        dataIndex: "employeeNameTo",
        key: "emplyeeNameTo",
        width: "20%"
      }
    ];

    const { currentPage, error, stockInData, stockOutData, isLoaded } =
      this.state;
    if (error) {
      return <div>error</div>;
    } else if (currentPage === "reports") {
      return (
        <Home isAdmin={true}>
          <div className="report-container-flex">
            <button className="box shadow stock-bg">
              <p className="text-items">LAGER</p>
            </button>
          </div>
          <div className="different-reports">
            <div id="flex-stock">
              <button
                onClick={() => {
                  this.navigate("/StockDetails");
                }}
                className="box shadow orange-bg"
              >
                <p className="text-items">Produkt Liste</p>
              </button>
              <button
                onClick={() => {
                  this.stockHistory("in");
                }}
                className="box shadow orange-bg"
              >
                <p className="text-items">Inn-Historie</p>
              </button>
            </div>
            <div id="flex-stock">
              {/* <button className="box shadow black-bg">
              <p className="text-items">MANAGE ORDERS</p>
            </button> */}
              <button
                onClick={() => {
                  this.navigate("/stockChange");
                }}
                className="box shadow black-bg"
              >
                <p className="text-items small-text">+/- Lager</p>
              </button>
              <button
                onClick={() => {
                  this.stockHistory("out");
                }}
                className="box shadow black-bg"
              >
                <p className="text-items">Ut-Historie</p>
              </button>
            </div>
          </div>
        </Home>
      );
    } else if (currentPage === "in") {
      return (
        <StockHistory
          loading={isLoaded}
          data={stockInData}
          columns={inColumns}
          clicked={() => this.goBack()}
          onOk={(date) => this.loadHistory(date)}
        />
      );
    } else if (currentPage === "out") {
      return (
        <StockHistory
          loading={isLoaded}
          data={stockOutData}
          columns={outColumns}
          clicked={() => this.goBack()}
          onOk={(date) => this.loadHistory(date)}
        />
      );
    }
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const Stocks = connect(mapStateToProps)(StockComponent);
export default Stocks;
