import React from "react";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      address: "",
      phoneNo: "",
      form: false
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  addCustomer = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNo: this.state.phoneNo,
      reference: this.state.reference,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      pin: this.state.pin,
      state: this.state.state,
      country: this.state.country
    };
    this.props.addKunder(data).then(() => {
      this.props.addToggleModal(this.props.addModalIsOpen,true);
    });
  };
  toggleModal = () => {
    this.props.addToggleModal(this.props.addModalIsOpen,false);
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.props.addModalIsOpen}
          onRequestClose={this.toggleModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="LEGG TIL KUNDER"
        >
          <div className="button-container">
            <button onClick={this.toggleModal} className="close-button">
              X
            </button>
          </div>
          <form
            className="add-employee add-customer"
            onSubmit={this.addCustomer}
          >
            <li>
              <center>
                <h2>LEGG TIL KUNDER</h2>
              </center>
            </li>
            <li style={{ marginTop: "1em" }}>
              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                required
                onChange={this.handleChange}
                name="firstName"
                className="input2"
                placeholder="Navn"
              />
              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="text"
                onChange={this.handleChange}
                name="lastName"
                className="input2"
                required
                placeholder="Etter Navn"
              />
            </li>
            <li>
              <input
                type="email"
                onChange={this.handleChange}
                name="email"
                required
                className="input2"
                placeholder="E-mail"
              />
            </li>
            <li>
              <input
                type="number"
                onChange={this.handleChange}
                name="phoneNo"
                className="input2"
                maxLength="10"
                min="0000000000"
                pattern="[0-9]"
                max="9999999999"
                placeholder="Tlf. Nr."
              />
            </li>
            <li>
              <textarea
                onChange={this.handleChange}
                name="reference"
                placeholder="Skriv inn referanse"
                className="input2"
              ></textarea>
            </li>
            <p>Adresse</p>
            <li>
              <input
                type="text"
                onChange={this.handleChange}
                name="line1"
                className="input2"
                maxLength="20"
                placeholder="Adresse"
              />
            </li>
            <li>
              <input
                type="text"
                onChange={this.handleChange}
                name="line2"
                maxLength="20"
                className="input2"
                placeholder="Adresse"
              />
            </li>
            <li style={{ marginTop: "1em" }}>
              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                onChange={this.handleChange}
                name="city"
                maxLength="15"
                className="input2"
                required
                placeholder="By"
              />

              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="number"
                onChange={this.handleChange}
                name="pin"
                className="input2"
                required
                pattern="[0-9]"
                // maxLength="10"
                min="0000000000"
                max="1000000000"
                placeholder="PIN KODE"
              />
            </li>
            <li>
              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                onChange={this.handleChange}
                name="state"
                className="input2"
                maxLength="15"
                required
                placeholder="Fylke"
              />

              <input
                style={{
                  maxWidth: "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="text"
                onChange={this.handleChange}
                name="country"
                className="input2"
                maxLength="15"
                placeholder="Land"
                required
              />
            </li>

            <li>
              <button type="submit" id="submit-landing2">
                Send
              </button>
            </li>
          </form>
        </Modal>
      </>
    );
  }
}
export default AddForm;
