import React from "react";
import { Drawer, Menu, Dropdown, Divider, Col, Row } from "antd";
import appConfig from "../../helpers/appConfig";
import { Icon as RIcon } from "react-icons-kit";
import { chevronDown } from "react-icons-kit/fa/chevronDown";
import { user as ProfileIcon } from "react-icons-kit/ikons/user";
const ProfileDrawer = ({
  visible,
  setIsVisibleProfile,
  details,
  switchBranch,
  handleSwitch
}) => {
  const {
    firstName,
    lastName,
    email,
    phoneNo,
    managecat,
    crm,
    hrm,
    invoice,
    stock,
    taccountant,
    branchList,
    branchId,
    personalInfo,
    tdDiscountPermission
  } = details;
  let myRoles = [];
  managecat === 1 && myRoles.push("Manage");
  crm === 1 && myRoles.push("CRM");
  hrm === 1 && myRoles.push("HRM");
  invoice === 1 && myRoles.push("Invoice");
  stock === 1 && myRoles.push("Stock");
  taccountant === 1 && myRoles.push("T Accountant");
  tdDiscountPermission === 1 && myRoles.push("TD Discount");
  //const handleSwitch = (id) => {
  //if (id !== branchId._id) {
  // switchBranch({ id: id });
  //}
  //};
  const DescriptionItem = ({ title, content }) => (
    <div
      style={{
        fontSize: 14,
        marginBottom: 7,
        marginTop: 14,
        color: "rgba(0,0,0,0.65)",
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      <p
        style={{
          // marginRight: 8,
          // display: "inline-block",
          color: "rgba(0,0,0,0.85)"
        }}
      >
        {title}:
      </p>
      {content}
    </div>
  );
  return (
    <Drawer
      className="drawer"
      placement="right"
      closable={true}
      width="400px"
      margin-top="6rem"
      onClose={() => setIsVisibleProfile(false)}
      visible={visible}
    >
      <h4 className="m-b-1">
        <i className="fa fa-user-circle" /> User Profile
      </h4>

      <Divider />
      <h6 className="m-b-p5">Personal Details</h6>

      <Divider />
      <DescriptionItem
        className="mb-0"
        title="Full Name"
        content={
          <span className="profile-full-name">
            {firstName} {lastName}
          </span>
        }
      />
      <DescriptionItem title="Email" content={email} />
      <DescriptionItem title="Phone Number" content={phoneNo} />
      <Dropdown
        className="profile"
        trigger={["click"]}
        overlay={
          <Menu>
              {branchList &&
                branchList.map((branch, index) => {
                  var tt = {};
                  if (branch.branch && branch.branch.name) {
                    tt._id = branch.branch._id;
                    tt.name = branch.branch.name;

                    return (
                      <Menu.Item key={index}>
                        <a data-id = {tt._id} onClick={() => handleSwitch(tt._id)}>{tt.name}</a>
                      </Menu.Item>
                    );
                  } 
                  //console.log("id, name---->", tt._id, tt.name)
                  
                })}
            </Menu>
        }
      >
        <a className="ant-dropdown-link change-branch">
          {branchId ? branchId.name : ""}
          <RIcon size={10} icon={chevronDown} />
        </a>
      </Dropdown>
      <DescriptionItem
        title="Roles"
        content={myRoles.map((item, index) => {
          if (index > 0)
            return (
              <span key={item} className="profile-role">
                {item}
              </span>
            );
          else
            return (
              <span key={item} className="profile-role">
                {item}
              </span>
            );
        })}
      />
      <Divider />
      <Row className="documents">
        <h6 className="m-b-p5">Documents</h6>
        <Col span={24}>
          {personalInfo && personalInfo.length > 0
            ? personalInfo.map((item, index) => {
                if (item)
                  return (
                    <div>
                      Document {index + 1}:
                      <img
                        style={{ maxWidth: "10%" }}
                        src={`${appConfig.s3url}/${item}`}
                        key={item}
                      />
                    </div>
                  );
              })
            : ""}
        </Col>
      </Row>
    </Drawer>
  );
};

export default ProfileDrawer;
