import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import { connect } from "react-redux";
import {
  Table,
  Tag,
  Popover,
  Input,
  InputNumber,
  Form,
  Switch,
  notification,
  Card
} from "antd";
import Modal from "react-modal";

import appConfig from "../../helpers/appConfig";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class employeetable extends Component {
  constructor(props) {
    super(props);
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    var tdDiscountPermission = localStorage.getItem("tdDiscountPermission");
    this.state = {
      error: null,
      isLoaded: false,
      isAuthenticated: false,
      modalIsOpen: false,
      visiblePopover: false,
      deletingKey: "",
      currentPage: "empTable",
      first_name: "",
      last_name: "",
      address: "",
      items: "",
      editingKey: "",
      currentEmp: "",
      permissions: [
        "taccountant",
        "managecat",
        "crm",
        "hrm",
        "stock",
        "invoice",
        "openaccount",
        "tableview",
        "tdDiscountPermission"
      ],
      taccountant: 0,
      managecat: 0,
      crm: 0,
      hrm: 0,
      stock: 0,
      invoice: 0,
      openaccount: 0,
      tableview: 0,
      kitchen: 0,
      bar: 0,
      tdDiscountPermission:0,
      empType: localStorage.getItem("empType")
    };
    if (kitchenpermission) {
      this.setState({ permissions: this.state.permissions.push("kitchen") });
    }
    if (barpermission) {
      this.setState({ permissions: this.state.permissions.push("bar") });
    }
    if (tdDiscountPermission) {
      this.setState({
        permissions: this.state.permissions.push("tdDiscount")
      });
    }
    this.toggleModal = this.toggleModal.bind(this);

    this.empColumns = [
      {
        title: "Navn",
        width: "20%",
        editable: false,
        dataIndex: "name",
        render: (item, record) => {
          if (item !== " ") {
            return (
              <a
                style={{ color: "#1890ff" }}
                onClick={() => this.showEmployeeProfile(record)}
              >
                {item}
              </a>
            );
          } else {
            return <center>--</center>;
          }
        }
      },
      {
        title: "Email",
        dataIndex: "email",
        editable: false,
        width: "26%",
        render: (item, record) => (
          <a
            style={{ color: "#1890ff" }}
            onClick={() => this.showEmployeeProfile(record)}
          >
            {item}{" "}
            {record.empType == "MANAGER" ? (
              <p style={{ color: "green" }}>[ADMIN]</p>
            ) : (
              ""
            )}
          </a>
        )
      },
      {
        title: "Tillatelser",
        editable: false,
        width: "30%",
        render: (text) => {
          let roles = [];
          text.hrm && roles.push(<Tag color="green">ANSATTE</Tag>);
          text.crm && roles.push(<Tag color="blue">KUNDER</Tag>);
          text.taccountant && roles.push(<Tag color="blue">ARKIV</Tag>);
          text.managecat && roles.push(<Tag color="blue">MENY</Tag>);
          text.invoice && roles.push(<Tag color="blue">FAKTURA</Tag>);
          text.stock && roles.push(<Tag color="blue">LAGER</Tag>);
          text.openaccount && roles.push(<Tag color="blue">OPPRETT KONTO</Tag>);
          text.tableview && roles.push(<Tag color="blue">BORD</Tag>);
          text.kitchen && roles.push(<Tag color="blue">KJØKKEN</Tag>);
          text.bar && roles.push(<Tag color="blue">BAR</Tag>);
          text.tdDiscountPermission &&
            roles.push(<Tag color="blue">TD Discount</Tag>);
          return roles;
        }
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "12%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          const empType = localStorage.empType;
          if (record.empType !== "MANAGER") {
            return (
              <a
                style={{
                  color: "#1890ff",
                  borderColor: "blue",
                  borderWidth: 2,
                  padding: 5
                }}
                onClick={() => this.toggleModal(record)}
              >
                Edit
              </a>
            );
          } else {
            return <center>--</center>;
          }
        }
      },
      {
        title: "SLETT",
        width: "12%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          const empType = localStorage.getItem("empType");
          if (record.empType !== "MANAGER") {
            return (
              <Fragment>
                {deletable ? (
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <a
                          style={{ color: "#1890ff" }}
                          onClick={this.hideDelete}
                        >
                          AVBRYT
                        </a>
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.delete(record)}
                        >
                          SLETT
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visiblePopover}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <a
                      onClick={() => this.setState({ popoverId: record.id })}
                      style={{ color: "#F44336" }}
                    >
                      SLETT
                    </a>
                  </Popover>
                ) : (
                  <a
                    onClick={() => this.deleteTemp(record.email)}
                    style={{ color: "#F44336" }}
                  >
                    SLETT
                  </a>
                )}
              </Fragment>
            );
          } else {
            return <center>--</center>;
          }
        }
      }
    ];
  }

  toggleModal(record) {
    console.log("toggle record", record);
    const {
      crm,
      hrm,
      invoice,
      taccountant,
      managecat,
      stock,
      openaccount,
      tableview,
      kitchen,
      bar,
      tdDiscountPermission
    } = record;
    this.setState({
      crm,
      hrm,
      taccountant,
      invoice,
      managecat,
      stock,
      openaccount,
      tableview,
      kitchen,
      bar,
      tdDiscountPermission,
      modalIsOpen: !this.state.modalIsOpen,
      editingKey: record.email
    });
    console.log("open account tableview", this.state.openaccount);
  }

  closeModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleVisibleChange = () => {
    this.setState({ visiblePopover: true });
  };

  isEditing = (record) => {
    return record.id === this.state.editingKey;
  };

  isDeleting = (record) => {
    return record.email === this.state.deletingKey;
  };

  hideDelete = () => {
    this.setState({ deletingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.items];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });

        this.setState(
          {
            items: newData,
            editingKey: ""
          },

          this.updateEmployee(
            row.email,
            row.hrm,
            row.crm,
            row.taccountant,
            row.invoice,
            row.stock,
            row.managecat,
            item._id
          )
        );
      } else {
        newData.push(row);
        this.setState({ items: newData, editingKey: "" });
      }
    });
  }

  delete = (record) => {
    const id = record.id;
    let hdr = localStorage.token;
    const data = JSON.stringify({ id });
    console.log(data);
    fetch(`${appConfig.endpoint}/employee`, {
      method: "DELETE",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("error", result.data);
          this.setState({ visiblePopover: false });
        } else {
          this.openNotification("success", result.message);
          console.log(result.message);
          this.showEmployees();
        }
      });
  };

  deleteTemp(key) {
    this.setState({ deletingKey: key });
  }

  changed = (checked, item) => {
    let toggledState = {};
    if (checked) {
      console.log("checked");
      toggledState[item] = 1;
    } else {
      console.log("unchecked");
      toggledState[item] = 0;
    }
    this.setState(toggledState);
    console.log(item);
  };

  editEmployee = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      email: this.state.editingKey,
      hrm: this.state.hrm,
      crm: this.state.crm,
      taccountant: this.state.taccountant,
      invoice: this.state.invoice,
      stock: this.state.stock,
      managecat: this.state.managecat,
      openaccount: this.state.openaccount,
      tableview: this.state.tableview,
      kitchen: this.state.kitchen,
      bar: this.state.bar,
      tdDiscountPermission: this.state.tdDiscountPermission
    });
    console.log(data);
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/employee`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            console.log(result);
            this.openNotification(
              "error",
              result.data.errors ? result.data.errors[0].msg : result.data
            );
          } else {
            this.openNotification("success", result.message);
            // this.props.history.push('/hrm');
            console.log(result);
            this.closeModal();
            this.showEmployees();
            // window.location.reload();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  goBack = () => {
    this.setState({ currentPage: "empTable" });
  };

  showEmployees = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/employee`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log("EMPLOYEE DATA ", result.data);
            const employee = result.data.map((item) => ({
              firstName: item.firstName,
              lastName: item.lastName,
              name: item.firstName + " " + item.lastName,
              email: item.email,
              address: item.address,
              id: item.id,
              phoneNo: item.phoneNo,
              personalInfo: item.personalInfo,
              // roles: [
              //   item.taccountant,
              //   item.managecat,
              //   item.crm,
              //   item.hrm,
              //   item.stock
              // ]
              hrm: item.hrm,
              crm: item.crm,
              taccountant: item.taccountant,
              stock: item.stock,
              invoice: item.invoice,
              managecat: item.managecat,
              openaccount: item.openaccount,
              tableview: item.tableview,
              kitchen: item.kitchen,
              bar: item.bar,
              tdDiscountPermission: item.tdDiscountPermission,
              empType: item.empType
            }));
            this.setState({
              isLoaded: true,
              items: employee
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  showEmployeeProfile = (employee) => {
    console.log(employee);
    this.setState({ currentPage: "empProfile", currentEmp: employee });
  };

  componentDidMount() {
    this.showEmployees();
  }
  render() {
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    console.log(this.state);
    const regex = /(^(.)|png|jpeg|jpg)$/;
    const permissionsAlt = {
      managecat: "Manage",
      taccountant: "T-Accountant",
      stock: "Stock",
      crm: "CRM",
      hrm: "HRM",
      invoice: "Invoice",
      openaccount: "Open Account",
      tableview: "Table View"
    };
    if (kitchenpermission) {
      permissionsAlt.kitchen = "Kitchen";
    }
    if (barpermission) {
      permissionsAlt.bar = "Bar";
    }
    const { error, items, isLoaded, permissions, currentPage, currentEmp } =
      this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const empColumns = this.empColumns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (currentPage === "empTable") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">ANSATTE</span>
            <span className="item" />
          </div>
          <Table
            loading={isLoaded ? false : true}
            dataSource={items}
            columns={empColumns}
            rowKey={(record) => record.id}
            onChange={(pagination) => this.pageChange(pagination)}
            components={components}
            bordered
            rowClassName="editable-row"
          />

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.toggleModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="LEGG TIL ANSATTE"
          >
            <div className="button-container">
              <button onClick={this.toggleModal} className="close-button">
                X
              </button>
            </div>
            <form
              className="add-employee"
              onSubmit={(e) => this.editEmployee(e)}
            >
              <li>
                <center>
                  <h2>LEGG TIL ANSATTE</h2>
                </center>
              </li>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="email"
                  className="input2"
                  placeholder="E-mail"
                  value={this.state.editingKey}
                  disabled
                />
              </li>
              <li className="designation-title">ANSATEE TILLATELSER</li>
              <li className="switches">
                {permissions.map((item) => {
                  return (
                    <Switch
                      checkedChildren={permissionsAlt[item]}
                      unCheckedChildren={permissionsAlt[item]}
                      checked={this.state[item]}
                      onChange={(checked) => this.changed(checked, item)}
                    />
                  );
                })}
              </li>
              <li>
                <button type="submit" id="submit-landing2">
                  SEND
                </button>
              </li>
            </form>
          </Modal>
        </Home>
      );
    } else if (currentPage === "empProfile") {
      return (
        <Home>
          <div style={{ maxWidth: 600, margin: "0 auto" }}>
            <Card
              title="ANSATTE PROFIL"
              extra={<a onClick={() => this.goBack()}>Back</a>}
            >
              <div
                style={{
                  display: "flex"
                }}
                className="hrm-profile-single"
              >
                <p style={{ fontWeight: 700, minWidth: 150 }}>Navn</p>
                <p>{currentEmp.name}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: 700, minWidth: 150 }}>Email</p>
                <p>{currentEmp.email}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: 700, minWidth: 150 }}>Tlf. Nr.</p>
                <p>{currentEmp.phoneNo}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: 700, minWidth: 150 }}>Adresse</p>
                <p>
                  {currentEmp.address.line1} {currentEmp.address.line2}
                  <br />
                  {currentEmp.address.city} {currentEmp.address.pin}
                  <br />
                  {currentEmp.address.state} {currentEmp.address.country}
                </p>
              </div>
              <div>
                <span>
                  <h3>Dokumenter</h3>
                </span>
                {currentEmp.personalInfo && currentEmp.personalInfo.length > 0
                  ? currentEmp.personalInfo.map((item, index) => {
                      if (item.match(regex)) {
                        return (
                          <div>
                            Dokumenter {index + 1}:
                            <img
                              style={{ maxWidth: "100%" }}
                              src={`${appConfig.endpoint}/${item}`}
                              key={item}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div key={item}>
                            <a href={`${appConfig.endpoint}/${item}`}>
                              Dokumenter {index + 1}
                            </a>
                            <br />
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
            </Card>
          </div>
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const emptab = connect(mapStateToProps)(employeetable);
export default emptab;
