import { UPDATE_DAY_AND_TIME } from "../../action/types";

const initialState = {
  data: {
    monday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    tuesday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    wednesday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    thursday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    friday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    saturday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    },
    sunday: {
      status: false,
      openingTime: "00:00",
      closingTime: "00:00"
    }
  }
};

const dayAndTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DAY_AND_TIME:
      return {
        data: {
          monday: action.payload.data.monday,
          tuesday: action.payload.data.tuesday,
          wednesday: action.payload.data.wednesday,
          thursday: action.payload.data.thursday,
          friday: action.payload.data.friday,
          saturday: action.payload.data.saturday,
          sunday: action.payload.data.sunday
        }
      };
    default:
      return state;
  }
};

export default dayAndTimeReducer;
