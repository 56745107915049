import React from "react";
import Home from "../Home/Home";
import { Table, DatePicker } from "antd";

const { RangePicker } = DatePicker;

const StockHistory = (props) => {
  const { data, columns, loading } = props;
  return (
    <Home isAdmin={true}>
      <div className="report-container">
        <span className="item">
          <span id="less-visible">HJEM / </span>
          Lager Historie
        </span>
        <span className="item">
          <span id="less-visible">
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={["Fra Dato", "Til Dato"]}
              onOk={(date) => props.onOk(date)}
            />
          </span>
        </span>
        <span className="item">
          <div onClick={props.clicked} className="back-button-border">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            <span>Back</span>
          </div>
        </span>
      </div>
      <Table
        loading={loading ? false : true}
        dataSource={data}
        columns={columns}
      />
    </Home>
  );
};

export default StockHistory;
