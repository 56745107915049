import { GET_EMPLOYEES_TIP_REPORT } from "./../../action/types";

let initialState = [];

const employeesTipReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_TIP_REPORT:
      return action.payload.data;

    default:
      return state;
  }
};

export default employeesTipReducer;
