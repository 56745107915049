import React, { useState } from "react";
import { Popover } from "antd";
const DeleteCategory = ({ record, deleteCategory, getCategory }) => {
  let [visible, setVisible] = useState(false);
  const deleteCat = () => {
    deleteCategory(record._id).then(() => getCategory());
  };
  const renderDelete = () => {
    return (
      <Popover
        title="Are you Sure?"
        trigger="click"
        visible={visible}
        content={
          <span
            style={{
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            <a style={{ color: "#1890ff" }} onClick={() => setVisible(false)}>
              AVBRYT
            </a>
            <a style={{ color: "#F44336" }} onClick={(e) => deleteCat()}>
              SLETT
            </a>
          </span>
        }
      >
        <a
          style={{ color: "#1890ff", fontSize: "20px", padding: "0 7px" }}
          onClick={() => setVisible(true)}
          href="#"
        >
          <i title="Detete Category" className="fa fa-trash"></i>
        </a>
      </Popover>
    );
  };
  return renderDelete();
};
export default DeleteCategory;
