import React from "react";
import Home from "../Home";
import { NavLink } from "react-router-dom";
import { Row,Col,Card } from "react-bootstrap";
const Index = () => {
  return (
    <Home>
      <div className="report-container text-center">
        <span className="item ">Lager</span>
      </div>
      <Card>
        <Card.Body>
          <Row className="m-b-1">
            <Col>
              <NavLink
                to="/lager/list"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                Produkt Liste
              </NavLink>
            </Col>
            <Col className="text-right">
              <NavLink
                to="/lager/innut"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                +/- Lager
              </NavLink>
            </Col>
          </Row>
          <Row className="m-b-1">
            <Col>
              <NavLink
                to="/lager/inn"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                Inn-Historie
              </NavLink>
            </Col>
            <Col className=" text-right">
              <NavLink
                to="/lager/ut"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                Ut-Historie
              </NavLink>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Home>
  );
};
export default Index;
