import React, { useEffect, useState } from "react";
import history from "../../history";
import Layout from "../Home";
import { connect } from "react-redux";
import ProductList from "./ProductList";
import ProductForm from "./ProductForm";
import {
  addProduct,
  updateProduct,
  getProducts,
  updateProductImage,
  deleteProduct,
  getProductStock,
  getIngredent,
  saveIngredent,
  showWeb,
  getCatProducts,
} from "./../../action/productsAction";
const Products = (props) => {
  let [isShowWebValues,setIsShowWebValues]= useState([]);
  useEffect(() => {
    if (!Object.keys(props.products).length) {
      history.push("/menu");
    }
  }, [props.products]);
  useEffect(() => {
    props.getProductStock();
  }, []);

  useEffect(() => {
    getIsShowValues();
  }, [props.products]);

  const getIsShowValues = () => {
    let data = [];
    if (Object.keys(props.products).length) {
      for (let product of props.products.data) {
        data[product._id] = product.isShowTable;
      }
      setIsShowWebValues(data);
    }
  };
  const isShowWeb = async (status, record) => {
    if (Object.keys(record).length) {
      const isShowTable = async () => {
        await props
          .showWeb({
            categoryId: record.categoryId._id,
            id: record._id,
            status,
          })
          .then(async () => {
           await  props.getCatProducts(record.categoryId._id);
          });
      };
      isShowTable();
    }
  };

  const goBack = () => {
    history.push("/menu");
  };
  const renderPage = () => {
    return (
      <>
        <div className="report-container">
          <span className="item">MENY: PRODUKT</span>
          <span className="item">
            <span id="less-visible">
              <div onClick={() => goBack()} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </div>
            </span>
          </span>
        </div>
        <ProductForm
          categoryId={props.products.categoryId}
          addProduct={props.addProduct}
          successData={props.successData}
          errorMassage={props.errorMassage}
          getProducts={props.getProducts}
        />
        <ProductList
          products={props.products.data}
          categoryId={props.products.categoryId}
          updateProduct={props.updateProduct}
          updateProductImage={props.updateProductImage}
          successData={props.successData}
          getProducts={props.getProducts}
          deleteProduct={props.deleteProduct}
          stockProduct={props.stockProduct}
          saveIngredent={props.saveIngredent}
          isShowWeb={isShowWeb}
          isShowWebValues={isShowWebValues}
          setIsShowWebValues={setIsShowWebValues}

          offerBackgrounds={props.products.offerBackgrounds}

        />
      </>
    );
  };

  return <Layout>{renderPage()}</Layout>;
};
const mapStateToProps = ({
  products,
  successMassage,
  errorMassage,
  stockProduct,
  ingredent
}) => {
  return {
    products: products,
    successData: successMassage.data,
    errorMassage,
    stockProduct,
    ingredent
  };
};
export default connect(mapStateToProps, {
  getProducts,
  addProduct,
  updateProduct,
  updateProductImage,
  deleteProduct,
  getProductStock,
  getIngredent,
  getCatProducts,
  saveIngredent,
  showWeb
})(Products);
