
const initialState={
    permissions:[],
    allInfo:[]
}
const loginInfoReducer=(state=initialState,action)=>{

    switch(action.type){
        case "LOGIN_INFO":
            console.log('----------LOGIN_INFO----------',action.payload.empPermissions)
        return   { ...state,allInfo: action.payload,permissions:action.payload.empPermissions};


        default:
            return state
    }
   
    
}

export default loginInfoReducer