import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../history";
import Layout from "../Home";
import { getEmployeeTips } from "../../action/profileAction";
import { DatePicker } from "antd";
import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import TableSum from "../Table/TableSumX";
const { MonthPicker } = DatePicker;

const EmployeeTips = (props) => {
  const [tipData, setTipData] = useState([]);
  const [tipTotal, setTipTotal] = useState(0);
  useEffect(() => {
    let date = moment().date()._d;
    tipfunc(date);
  }, []);
  useEffect(() => {
    if (props.empTips.length) {
      let sum = 0;
      props.empTips.forEach((item) => {
        sum = Number(sum) + Number(item.amount);
      });
      setTipTotal(sum)
      setTipData(props.empTips);
    }else{
      setTipData([]);
      setTipTotal(0)
    }
  }, [props.empTips]);
  const goBack = () => {
    history.push("/profil");
  };
  const tipfunc = (date, type) => {
    let startDate = "";
    let endDate = "";
    if (type === "m") {
      startDate = moment(date).startOf("month").set({
        hour: 4,
        minute: 0,
        second: 0
      });
      endDate = moment(date).endOf("month").set({
        hour: 3,
        minute: 59,
        second: 59
      });
    } else {
      startDate = moment(date).set({
        hour: 4,
        minute: 0,
        second: 0
      });
      endDate = moment(date).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      });
    }
    const branchId = localStorage.getItem("bid");
    props.getEmployeeTips({ branchId, startDate, endDate });
  };
  const tiplistColumns = [
    {
      title: "Ansatte Navn",
      width: "30%",
      key: "name",
      dataIndex: "name",
      render: (record, text) => {
        return <p>{text.employeeId.firstName}</p>;
      }
    },
    {
      title: "Ansatte Email",
      width: "30%",
      render: (record, text) => {
        return <p>{text.employeeId.email}</p>;
      }
    },
    {
      title: "Beløp",
      width: "10%",
      key: "amount",
      dataIndex: "amount"
    },
    {
      title: "Tip Tid",
      width: "30%",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text, record) => {
        return moment(record.createdAt).format("MM-DD-YYYY h:mm a");
      }
    }
  ];
  var tipSummaryColumns = [
    {
      title: "Name",
      width: "20%",
      render: () => <strong>Totall</strong>
    },
    {
      title: "email",
      width: "25%",
      render: () => <strong> </strong>
    },
    {
      title: "Total",
      width: "30%",
      render: () => `Kr.${tipTotal.toFixed(2)}`
    }
  ];
  const summaryData = [
    {
      key: "-1",
      render: () => "test"
    }
  ];
  const renderPage = () => {
    return (
      <>
        <div className="report-container">
          <span className="item">Profil: Tip Rapport</span>
          <span className="item">
            <span id="less-visible">
              <div onClick={() => goBack()} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </div>
            </span>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row className="justify-content-end">
              <Col xs lg="2">
                <DatePicker
                  onChange={(date, dateString) => tipfunc(date, "d")}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />
              </Col>
              <Col xs lg="2">
                <MonthPicker
                  onChange={(date, dateString) => tipfunc(date, "m")}
                  placeholder="Select month"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row className="justify-content-md-center">
              <Col>
                <TableSum
                  columns={tiplistColumns}
                  summaryColumns={tipSummaryColumns}
                  data={tipData}
                  summaryData={summaryData}
                  bordered
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };
  return <Layout>{renderPage()}</Layout>;
};
const mapStateToProps = ({ empTips }) => {
  console.log("empTips==>", empTips);
  return {
    empTips
  };
};
export default connect(mapStateToProps, {
  getEmployeeTips
})(EmployeeTips);
