import Item from "antd/lib/list/Item";
import { updateKitchenStatus } from "../../action/kitchenAction";
import { GET_ALL_TABLE, UPDATE_STATUS_kITCHEN } from "../../action/types";
const initialState = {
  activeTables: [],
  driveOut: [],
  noTable: [],
  takeAway: [],
  loading: false
};

const allTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TABLE:
      return {
        ...state,
        activeTables: action.payload.activeTable,
        driveOut: action.payload.driveOut,
        noTable: action.payload.noTable,
        takeAway: action.payload.takeAway
      };

    case UPDATE_STATUS_kITCHEN:
      let table = [];
      let tableId = action.payload.tableId;
      let items = action.payload.items;
      if (action.payload.orderType.toLowerCase() == "table") {
        table = [...state.activeTables];
        let updatedTable = updateKitchenStatusHelper(table, tableId, items);
        return {
          ...state,
          activeTable: updatedTable
        };
      }

      if (action.payload.orderType == "Drive Out") {
        table = [...state.driveOut];

        let updatedTable = updateKitchenStatusHelper(table, tableId, items);

        return {
          ...state,
          driveOut: updatedTable
        };
      }
      if (action.payload.orderType == "No Table") {
        table = [...state.noTable];

        let updatedTable = updateKitchenStatusHelper(table, tableId, items);

        return {
          ...state,
          noTable: updatedTable
        };
      }
      if (action.payload.orderType == "Take away") {
        table = [...state.takeAway];
        let updatedTable = updateKitchenStatusHelper(table, tableId, items);

        return {
          ...state,
          takeAway: updatedTable
        };
      }

    default:
      return state;
  }
};

let updateKitchenStatusHelper = (tableData, tableId, items) => {
  let table = tableData;
  let tableIndex = table.findIndex((sinId) => {
    return sinId._id == tableId;
  });

  let singletable = table[tableIndex];
  console.log("tableINdex----->", items);
  console.log("single table", singletable.items);
  singletable.items = items;
  table[tableIndex] = singletable;
  return table;
};

export default allTableReducer;
