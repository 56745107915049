import React from "react";
import { Card } from "react-bootstrap";
import { Avatar, Badge } from "antd";
import { Button } from "antd/lib/radio";
import "./bord.css";
const BordCard = (props) => {
  return (
    <>
      <div className="button-style-card">
        <Button className="drinks button-size-icon drinks-card-border" shape="circle">
          <div className="card-align">
          <span style={{ marginRight: 5 }}>
            {props.item.items.filter(
              (x) => x.status !== "PREPARED" && x.sCatType == "KITCHEN"
            ).length > 0 ? (
              <Badge dot>
                <Avatar
                  style={{
                    color: "#ff7675",
                    background: "#636e72",
                  }}
                  shape="square"
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    K
                  </p>
                </Avatar>
              </Badge>
            ) : (
              <Badge>
                <Avatar
                  style={{
                    color: "#55efc4",
                    background: "#636e72",
                  }}
                  shape="square"
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    K
                  </p>
                </Avatar>
              </Badge>
            )}
          </span>
          <span>
            {props.item.items.filter(
              (x) => x.status !== "PREPARED" && x.sCatType == "BAR"
            ).length > 0 ? (
              <Badge dot>
                <Avatar
                  style={{
                    color: "#ff7675",
                    background: "#636e72",
                  }}
                  shape="square"
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    B
                  </p>
                </Avatar>
              </Badge>
            ) : (
              <Badge>
                <Avatar
                  style={{
                    color: "#55efc4",
                    background: "#636e72",
                  }}
                  shape="square"
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    B
                  </p>
                </Avatar>
              </Badge>
            )}
          </span>
          </div>
          <div className="menu-options card-bord white">
            {props.item.orderType === "table" && props.item.number > 0 ? (
              <span>
                Table <b>{props.item.number}</b>
              </span>
            ) : (
              <span>
                {props.item.orderType} <b>{props.item.number}</b>
              </span>
            )}
          </div>
          <br />
          <b>{props.item.employeeId.firstName}</b>
        </Button>
      </div>
    </>
  );
};

export default BordCard;
