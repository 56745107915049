import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
const InfoForm = ({ updateInfo, details }) => {
  const [inputs, setInputs] = useState({});
  const [validated, setValidated] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (details) {
      let dValue = {
        name: details.name,
        email: details.email,
        website: details.website,
        taxNo: details.taxNo,
        phoneNo: details.phoneNo,
        description: details.description,
        line1: details.address.line1,
        line2: details.address.line2,
        city: details.address.city,
        state: details.address.state,
        pin: details.address.pin,
        country: details.address.country
      };
      setInputs(dValue);
    }
  }, [details]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      const bid = localStorage.getItem("bid");
      updateInfo({ ...inputs, branchId: bid });
    }
    setValidated(true);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3 p-2">
        <Col>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Restaurant Navn"
                value={inputs.name || ""}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                required
                type="email"
                placeholder="Email"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Nettsted"
                name="website"
                value={inputs.website || ""}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                required
                type="number"
                name="taxNo"
                placeholder="Org Nr."
                value={inputs.taxNo || ""}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="number"
                name="phoneNo"
                min="0000000000"
                max="9999999999"
                placeholder="Tel. Nr."
                value={inputs.phoneNo || ""}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={10}>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Om"
                name="description"
                value={inputs.description || ""}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                name="line1"
                maxLength="20"
                placeholder="Addresse"
                value={inputs.line1 || ""}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                name="line2"
                maxLength="20"
                placeholder="Addresse 2"
                value={inputs.line2 || ""}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                name="city"
                pattern="[A-Za-z]+"
                maxLength="20"
                placeholder="By"
                value={inputs.city || ""}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="number"
                onChange={handleChange}
                name="pin"
                placeholder="PIN"
                value={inputs.pin || ""}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="state"
                pattern="[A-Za-z]+"
                maxLength="20"
                placeholder="Fylke"
                value={inputs.state || ""}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="country"
                pattern="[A-Za-z]+"
                maxLength="20"
                placeholder="Land"
                value={inputs.country || ""}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Col sm={10}>
              <Button className="btn-ab float-right" type="submit">
                Submit
              </Button>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </Form>
  );
};
export default InfoForm;
