import React from "react";
import { Redirect, Route } from "react-router-dom";

const DefaultRoute = ({ component: Component, ...restOfProps }) => {
  let isAuthenticated = false;
  const token = localStorage.getItem("token");
  if (token) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  let isAuthenticated = false;
  const token = localStorage.getItem("token");
  if (token) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export { ProtectedRoute, DefaultRoute };
