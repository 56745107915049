import { GET_UNPAID_INVOICES } from "../../action/types";

const initialState = {
  invoice:[],
  dueDatedInvoice:[],
  branch:{}
};

const getUnpaidInvoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNPAID_INVOICES:
      return {
        ...state,
        invoice:action.payload.data.invoice,
        dueDatedInvoice:action.payload.data.dueDatedInvoice,
        branch:action.payload.data.branch
      };
    default:
      return state;
  }
};

export default getUnpaidInvoicesReducer;
