import React from 'react'
import { GETAllAPI, POSTAPI } from "../AllApi";
import Home from '../../Home';
import { useEffect, useState } from 'react';
import appConfig from '../../../helpers/appConfig';
import { Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import SingleOrder from './SingleOrder';
import { Select, DatePicker } from "antd";
import TableSumX from '../../Table/TableSumX'

import { Table } from "antd";


const Option = Select.Option;

const GetVendorOrders = (props) => {

    // const print = useRef();

    const [orderStatus, setOrderStatus] = useState("all");
    const [onlineDate, setOnlineDate] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState("list");
    const [filterOrdered, setFilteredOrder] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    const [singleOrder, setSingleOrder] = useState(false);
    const [singleOrderData, setSingleOrderData] = useState({})
    const [statusChanged, setStatusChanged] = useState(false)
    const token = localStorage.getItem("token")

    useEffect(() => {
        console.log("statusChanged--------------")
        setLoading(true);
        const date = onlineDate ? onlineDate : moment().date()._d;
        filterDate(date);
    }, [statusChanged])


    useEffect(() => {
        console.log("filter data is-----", filterOrdered)
    }, [filterOrdered])

    useEffect(() => {
        console.log("====single order data===", singleOrderData)
    }, [singleOrderData])

    const goBackMainPage = () => {
        if (singleOrder) {
            props.history.push("/vendor-vendors");
            setSingleOrder(false)
        } else {
            props.history.push("/vendor");
        }
        setPage("list");
    };


    const showOrder = (id) => {
        setSingleOrder(true)
        let filteredData = filterOrdered.filter((item) => {
            return item._id == id
        })
        setSingleOrderData(filteredData[0]);
        //console.log("============data source============", dataSource);
        //console.log("==========filtered data=========", filteredData[0]);
    };

    const headers = {
        "x-auth-token": token
    }


    console.log("==========filtered data=========", filterOrdered)

    const getRecords = async (data) => {
        try {
            let resData = await POSTAPI(`${appConfig.endpoint}/api/v1/vendor/orders/getVendorOrders`,
                data,
                {
                    headers: headers
                }
            )
            //console.log("====getVendor orders===", resData.data)
            setDataSource(resData.data)
            setFilteredOrder(resData.data)
            setLoading(false)

        } catch (error) {
            console.log(error)
        }
    }


    // useEffect(() => {
    //     setLoading(true);
    //     const date = onlineDate ? onlineDate : moment().date()._d;
    //     filterDate(date);
    // }, []);


    const filterDate = (date) => {
        setOnlineDate(date)
        const startDate = moment(date);
        //console.log("date =====", startDate)
        const endDate = moment(date).add(1, "days");
        //console.log("statusChanged in get", date)
        let postData = {
            startDate: startDate,
            orderType: orderStatus,
            endDate: endDate
        }
        //console.log("======data=====", postData)
        getRecords(postData)
    }




    const filteredResult = (e) => {
        console.log("cjkkklk====", e)
        setOrderStatus(e);

        if (e.toLowerCase() != "all") {

            let filterData = dataSource.filter((val) => {
                // if (e.toLowerCase() == 'all') {
                //     //console.log("filterData", dataSource)
                // }
                return val.orderStatus.toLowerCase() == e.toLowerCase();
            })

            setFilteredOrder(filterData)

        } else {

            setFilteredOrder(dataSource)

        }




        // console.log("filterd data", filterData)
        // setDataSource(filterData)

        //setDataSource()
    }

    // console.log("=======single order =======", singleOrder)
    // console.log("========single order data=========", singleOrderData)

    const printbill = () => {
        window.print();
    };
    const columns = () => [
        {
            title: "S.No",
            key: "orderNumber",
            width: "5%",
            render: (text, record, index) => index + 1

        }
        , {
            title: "Order time",
            key: "orderTime",
            width: "18%",
            render: (text, record) =>
                `${moment(record.createdAt).format("DD/MM/YYYY")} - ${new Date(
                    record.createdAt
                ).toLocaleTimeString()}`
        },
        {
            title: "No. of Products",
            key: "noProducts",
            width: "9%",
            render: (text, record) => record.items.length
        }, {
            title: "Status",
            key: "status",
            width: "11%",
            render: (text, record) => {
                if (record.orderStatus == "DELIVERED") {
                    return (
                        <p
                            style={{
                                background: "green",
                                textAlign: "center",
                                borderRadius: 5,
                                color: "white"
                            }}
                        >
                            {record.orderStatus}
                        </p>
                    );
                } else if (record.orderStatus == "PENDING") {
                    return (
                        <p
                            style={{
                                //  background: "orange",
                                textAlign: "center",
                                borderRadius: 5,
                                color: "black",
                                opacity: ".65"
                            }}
                        >
                            {record.orderStatus}
                        </p>
                    );
                } else if (record.orderStatus == "IN-PROCESS") {
                    return (
                        <p
                            style={{
                                background: "orange",
                                textAlign: "center",
                                borderRadius: 5,
                                color: "white"
                            }}
                        >
                            {record.orderStatus}
                        </p>
                    );
                }
                else if (record.orderStatus == "CANCEL") {
                    return (
                        <p
                            style={{
                                background: "red",
                                textAlign: "center",
                                borderRadius: 5,
                                color: "white"
                            }}
                        >
                            {record.orderStatus}
                        </p>
                    );
                } else {
                    return record.orderStatus;
                }
            }
        }, {
            title: "Order type",
            key: "OrderType",
            width: "10%",
            render: (text, record) => record.orderType
        },
        {
            title: "Order From",
            key: "OrderFrom",
            width: "10%",
            render: (text, record) => record.customerType == "tdUser" ? "Treedrive" : "Restaurant"
        },
        {
            title: "Total",
            key: "total",
            width: "9%",
            render: (text, record) => {
                return `Kr.${record.totalPrice.toFixed(2)}`;
            }
        }, {
            title: "View",
            key: "view",
            width: "10%",
            className:"view-title",
            render: (text, record) => (
                <Button className="view-btn" onClick={() => showOrder(record._id)}>
                    <i className="fa fa-eye"></i>
                </Button>
            )
        }
    ]


    let totalPrice = 0;
     dataSource.map((e) =>{
        totalPrice += e.totalPrice
     })




    const VendorSummaryData = [
        {
            key: "-1",
            render: () => "test"
        }
    ];

    const VendorSummary = (totalPrice) => [
        {
            title: "Total",
            key: "orderTime",
            width: "18%",
            render: () => <strong>Total</strong>

        }, {
            title: "TotalPrice",
            key: "orderNumber",
            width: "5%",
            render: () => `Kr.${totalPrice.toFixed(2)}`
        }
        ,
    ];


    return (

        <Home>
            <div className="report-container">
                <span className="item" style={{ width: "60%" }}>
                    <span id="less-visible">Rapport / </span>
                    Vendor Orders
                </span>
                <span id="less-visible">
                    <Button
                        onClick={() => goBackMainPage()}
                        className="ant-btn back-btn"
                    >
                        <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    </Button>
                </span>
            </div>

            <Row>
                <Col>
                    {singleOrder ? <SingleOrder orderData={singleOrderData} setSingleOrder={setSingleOrder} statusChanged={statusChanged} setStatusChanged={setStatusChanged} name="KSHDJKSDKJ" /> :
                        <>
                            <Row>
                                <Col className=" d-flex text-right ml-6" lg="8" >
                                    <div className='ml-6'
                                        id="printbtn">
                                        {/* <Button
                                            onClick={() => printbill()}
                                            type="primary"
                                            size="large"
                                            className="btn-ab  text-color tablePrint"
                                        >
                                            <i
                                                className="fa fa-print"
                                                style={{ fontSize: 20 }}
                                                aria-hidden="true"
                                            />
                                        </Button> */}
                                    </div>
                                </Col>
                                <Col lg="4" className='d-flex'>
                                    <div className='d-flex mr-3'>
                                        <Select
                                            style={{ width: 120 }}
                                            // onChange={(e) => setOrderStatus(e)}
                                            onChange={filteredResult}
                                            value={orderStatus}
                                        >
                                            <Option key="all" value="all">
                                                All
                                            </Option>
                                            <Option key="Pending" value="pending">
                                                Pending
                                            </Option>
                                            <Option key="in-process" value="in-process">
                                                In-Process
                                            </Option>
                                            <Option key="delivered" value="delivered">
                                                Delivered
                                            </Option>
                                            <Option key="cancel" value="cancel">
                                                Cancelled
                                            </Option>
                                        </Select>
                                    </div>
                                    <div>
                                        <DatePicker
                                            onChange={(date, dateString) => {
                                                filterDate(date)
                                            }}
                                            format="YYYY-MM-DD"
                                            placeholder="Select day"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="TableSection" style={{margin:"10px"}}>
                                <TableSumX
                                    id="tablePrint"
                                    loading={loading}
                                    //scroll={{ x: true }}
                                    columns={columns()}
                                    summaryColumns={VendorSummary(totalPrice)}
                                    data={filterOrdered}
                                    summaryData={VendorSummaryData}
                                    striped
                                    bordered
                                />
                            </div>
                        
                          
                        </>
                    }
                </Col>
            </Row>
        </Home>

    )
}

export default GetVendorOrders;
