import React, { Component } from "react";
import "./Home.css";
import { Icon as RIcon } from "react-icons-kit";
import {bellO} from 'react-icons-kit/fa/bellO'
import {search} from 'react-icons-kit/fa/search'
import { ic_home } from "react-icons-kit/md/ic_home";
import { edit } from "react-icons-kit/fa/edit";
import { ic_view_module } from "react-icons-kit/md/ic_view_module";
import { ic_settings } from "react-icons-kit/md/ic_settings";
import { ic_info } from "react-icons-kit/md/ic_info";
import { ic_person } from "react-icons-kit/md/ic_person";
// import { ic_user } from "react-icons-kit/md/ic_user";
import { power } from "react-icons-kit/ionicons/power";
import { push } from "react-router-redux";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  notification
} from "antd";
import FloatingButtons from "../../components/FloatingButtons/FloatingButtons";
import { connect } from "react-redux";
import RightMenuAdmin from "../../components/RightMenuAdmin/RightMenuAdmin";
import PropTypes from "prop-types";
import Profile from "../Profile/Profile";
import moment from "moment";
import Clock from "../Clock/Clock";
import { subscribeToOrder } from "../../helpers/socket";
import { emptySuccessMsg, emptyErrorMsg } from "../../action";
import appConfig from "../../helpers/appConfig";

const { Header, Content } = Layout;

class HomeComponent extends Component {
  static propTypes = {
    isBilling: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isProfile: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      leftsidebar: false,
      website: "",
      rightsidebar: false,
      profile: false,
      time: "",
      date: "",
      data: "",
      currentTime: "",
      orders: 0
      // currentTime: '00:00:00 am'
    };
    console.log("ORDERS before ", this.state.orders);

   // subscribeToOrder(async (err, orders) => await this.setState({orders}));
    //console.log("After ORDERS socket", this.state.orders);
  }

  clicked = (data) => {
    this.navigate(data);
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  logout = (e) => {
    var hdr = localStorage.token;
    var data = JSON.stringify({ name: this.state.name });
    fetch(`${appConfig.endpoint}/logout`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.navigate("/");
          } else {
            localStorage.removeItem("token");
            setAuthorizationToken(false);
            localStorage.clear();
            this.navigate("/");
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  toggleSidebar = (e) => {
    let state = Object.assign(this.state);
    state.leftsidebar = !state.leftsidebar;
    if (state.rightsidebar) {
      state.rightsidebar = false;
    }
    this.setState(state);
  };
  toggleSidebarright = (e) => {
    let state = Object.assign(this.state);
    state.rightsidebar = !state.rightsidebar;
    if (state.leftsidebar) {
      state.leftsidebar = false;
    }
    this.setState(state);
  };

  handleTable = (e) => {
    console.log("something");
  };

  handleProfileClick = () => {
    this.setState({ profile: !this.state.profile });
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/ownprofile`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              data: result.data
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  closeDrawer = () => {
    this.setState({ profile: false });
  };

  componentDidMount() {
    this.confirm();
    this.updateDate();

    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/orders/ordercount`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("HOME GOT ORDER COUNT", res.data);
        this.setState({ orders: res.data });
        localStorage.setItem("currentOrders", res.data);
      })
      .catch((err) => console.log(err));
  }

  confirm = () => {
    const token = localStorage.getItem("token");

    function handleErrors(result) {
      if (!result.ok) {
        localStorage.clear();
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${appConfig.endpoint}/confirm`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          localStorage.clear();
          this.navigate("/dashboard");
        } else {
          const token = result.data.token;
          localStorage.setItem("loggedIn", true);
        }
      })
      .catch((error) => console.log(error));
  };

  updateDate() {
    let event = new Date();

    let dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    let timeOptions = {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };

    this.setState({
      date: event.toLocaleDateString("en-GB", dateOptions),
      time: event.toLocaleTimeString("en-GB", timeOptions)
    });
  }

  onlinorders(page) {
    console.log("THIS IS PAGE", page);
    this.setState({ currentPage: "reports" });
    // this.sendDate(page);
    // this.props.title = "ONLINE ORDERS";
    this.navigate(page);
  }

  sendDate = (title) => {
    let startDate, endDate, date;
    switch (title) {
      case "DAILY REPORTS":
        startDate = moment().date()._d;
        endDate = moment().date()._d;
        return this.getInvoices(title, startDate, endDate);
      case "WEEKLY REPORTS":
        // startDate = moment().startOf('week')._d;
        // endDate = moment().endOf('week')._d;
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        return this.getInvoices(title, startDate, endDate);
      case "MONTHLY REPORTS":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        return this.getInvoices(title, startDate, endDate);
      case "YEARLY REPORTS":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        return this.getInvoices(title, startDate, endDate);
      case "X-REPORTS":
        date = moment().date()._d;
        return this.onOk(date);
      case "ONLINE ORDERS":
        return this.getOnlineOrders();
      default:
        return 0;
    }
  };

  getOnlineOrders = (filter) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/getorders`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          // this.openNotification('error', result.data);
        } else {
          console.log("online", result);

          let items = [];
          let onlineTotal = "";

          switch (filter) {
            case "all":
              items = result.data;
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
            case "active":
              items = result.data.filter(
                (item) => item.orderStatus === "IN-PROCESS"
              );
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
            case "delivered":
              items = result.data.filter(
                (item) => item.orderStatus === "DELIVERED"
              );

              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
            case "cancelled":
              items = result.data.filter(
                (item) => item.orderStatus === "CANCEL"
              );
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
            default:
              items = result.data;
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
          }

          this.setState({
            items,
            onlineTotal,
            isLoaded: true,
            sendProps: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.errorMassage !== prevProps.errorMassage &&
      this.props.errorMassage
    ) {
      this.openNotification("error", this.props.errorMassage);
      this.props.emptyErrorMsg();
    }
    if (
      this.props.successMassage !== prevProps.successMassage &&
      this.props.successMassage
    ) {
      this.openNotification("success", this.props.successMassage);
      this.props.emptySuccessMsg();
    }
  }
  render() {
    const { children } = this.props;
    var orders = localStorage.getItem("currentOrders");
    const { date, currentTime } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    let profileImage = localStorage.getItem("profileImage");
    const logo = localStorage.getItem("logo");
    return (
      <Layout>
        <div id={this.state.leftsidebar ? "left-side-bar" : "something"}>
          <Menu className="menu-background main-menu-style">
            <Menu.Item
              key="1"
              onClick={() => {
                this.navigate("/dashboard");
              }}
              className="menu-button"
            >
              <div className="menu-item">
                <RIcon size={32} icon={ic_home} />
                <span className="menu-text">Hjem</span>
              </div>
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                this.navigate("/reports");
              }}
              className="menu-button"
            >
              <div className="menu-item">
                <RIcon size={32} icon={ic_view_module} />
                <span className="menu-text">Rapporter</span>
                {/* <span className="menu-text">Reports</span> */}
                {orders > 0 && (
                  <span
                    style={{
                      background: "red",
                      borderRadius: 100,
                      width: 25,
                      height: 25,
                      color: "white",
                      position: "absolute",
                      top: 5,
                      right: 2,
                      fontSize: 16,
                      lineHeight: 1.625,
                      textAlign: "center"
                    }}
                  >
                    {orders}
                  </span>
                )}
              </div>
            </Menu.Item>
            <Menu.Item
              key="3"
              className="menu-button"
              onClick={() => {
                this.navigate("/profile");
              }}
            >
              <div className="menu-item">
                <RIcon size={32} icon={ic_info} />
                <span className="menu-text">Info</span>
              </div>
            </Menu.Item>
            <Menu.Item
              key="4"
              className="menu-button"
              onClick={() => {
                this.navigate("/setting");
              }}
            >
              <div className="menu-item">
                <RIcon size={32} icon={ic_person} />
                {/* <span className="menu-text">Settings</span> */}
                <span className="menu-text">Profil</span>
              </div>
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                this.navigate("/ikaccounts");
              }}
              key="6"
              className="menu-button"
            >
              <div className="menu-item">
                <RIcon size={32} icon={edit} />
                <span className="menu-text">IK system</span>
              </div>
            </Menu.Item>
            <Menu.Item onClick={this.logout} key="5" className="menu-button">
              <div className="menu-item">
                <RIcon size={32} icon={power} />
                <span className="menu-text">Logg ut</span>
              </div>
            </Menu.Item>
          </Menu>
        </div>

        <Header className="header-background">
          <div className="header-div-left">
            <div className="inner-flex-company">
              <img
                src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                onClick={() => {
                  this.navigate("/dashboard");
                }}
                className="company-logo-header hide-logo"
              />
              <img
                src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                onClick={() => {
                  this.navigate("/dashboard");
                }}
                className="company-logo-header2 hide-logo2"
              />
            </div>
          </div>
          <div className="header-div-right">
            <Input
              className="item-header-flex header-input ant-input-dark"
              placeholder="Search here"
              suffix={<RIcon className="header-search-icon" Icon={search} />}
            />
            <div className="user">
              <span style={{ position: "relative" }}>
                <RIcon
                  onClick={() => this.onlinorders("reports")}
                  type={bellO}
                 theme="filled"
                  style={{ fontSize: 30, color: "white", margin: "0 10px" }}
                />
                {orders > 0 && (
                  <span
                    style={{
                      background: "red",
                      borderRadius: 100,
                      width: 25,
                      height: 25,
                      padding: 5,
                      color: "white",
                      position: "absolute",
                      left: -4,
                      top: -10,
                      lineHeight: 1,
                      textAlign: "center",
                      fontWeight: "bold"
                    }}
                  >
                    {orders}
                  </span>
                )}
              </span>
              <Avatar
                onClick={this.handleProfileClick}
                size="large"
                shape="square"
                icon="user"
                src={profileImage ? `${appConfig.s3url}/${profileImage}` : ""}
              />
              <span onClick={this.handleProfileClick} className="username">{`${user && user.firstName ? user.firstName : ""
              }`}</span>
            </div>
            <div>
              <span className="item-header-flex avatar-general border-right text-header">
                {date}
              </span>
              <Clock />
            </div>
            <div>
              <div className="item-header-flex avatar-general hide1  text-header" />
            </div>
          </div>

          <div>
            <div
              className="item-header-flex2"
              onClick={this.toggleSidebarright}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </div>
          </div>
        </Header>
        <Layout>
          <Content className="content-style">
            <div>
              <Row>
                <div className={this.props.isBilling ? "hide-f" : "test"}>
                  <Col
                    className={
                      this.props.isProfile
                        ? "hide-f floating-buttons"
                        : "gutter-row floating-buttons"
                    }
                    span={3}
                  >
                    <FloatingButtons
                      history={this.props.history}
                      website={this.state.website}
                    />
                  </Col>
                </div>
                <Col
                  className={
                    this.props.isProfile
                      ? "gutter-row content"
                      : "gutter-row content"
                  }
                  span={this.props.isBilling ? 18 : 13}
                >
                  <div style={{ paddingTop: 10 }}>{children}</div>
                </Col>
                <Col>
                  <div
                    id={this.state.rightsidebar ? "right-menu" : "something2"}
                  >
                    <div className={this.props.isBilling ? "hide" : ""}>
                      <RightMenuAdmin
                        handleClick={(data) => this.clicked(data)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
        <Profile
          visible={this.state.profile}
          close={this.closeDrawer}
          userData={this.state.data}
        />
      </Layout>
    );
  }
  navigate = (route) => {
    // const { dispatch } = this.props;
    // dispatch(push(route));
    this.props.history.push(route);
  };
}
const mapStateToProps = ({ errorMassage, successMassage }) => {
  return {
    errorMassage,
    successMassage: successMassage.message
  };
};
const Home = connect(mapStateToProps, { emptySuccessMsg, emptyErrorMsg })(
  HomeComponent
);
export default withRouter(Home);
