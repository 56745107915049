import React from "react";
import KitchenView from "./../Kitchen";
import DashBoard from "./../Dashboard";
import { Redirect } from 'react-router';
const RedirectRoute = ({Component,history,permission,status}) => {  
  if (localStorage.getItem(permission) =="false") {
    return (
      <>
        <Redirect to="/dashboard" />
      </>
    );
  }


  else if(permission==="couponPermission") {
    return (
      <>


      <Component/>
      </>
    )
  }
  else {


    return (
      <>
        {Number(localStorage.getItem(status)) === 1 ? (
          <Component history={history} />
        ) : (
          <Redirect to="/dashboard" />
        )}
      </>
    );
  }
};

export default RedirectRoute;
