import React, { useEffect, useState } from "react";
import appConfig from "../../helpers/appConfig";
import Home from "../Home";
import history from "../../history";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Card,
  Select,
  Avatar,
  Descriptions,
  Checkbox,
  Radio
} from "antd";
import { GETAllAPI, POSTAPI } from "./AllApi";
import "./BillingOpprettkonto.css";

const { Search } = Input;
const { Meta } = Card;

export default function BillingOpprettkonto(props) {
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState(true);

  const [cartData, setCartData] = useState([]);

  const [responseData, setResponseData] = useState([]);
  const [totalOfCart, setTotalOfCart] = useState(0);

  const [taxId, setTaxId] = useState({ taxId: localStorage.taxID });

  const [searchTerm, setSearchTerm] = useState("");
  const [cashConfirmModal, setCashConfirmModal] = useState(false);
  const [discountConfirmModal, setDiscountConfirmModal] = useState(false);
  const [tflConfirmModal, setTFLConfirmModal] = useState(false);
  const [tflCouponValue, setTFLCouponValue] = useState("");
  const [extraDOchargesModal, setExtraDOchargesModal] = useState(false);
  const [tipModal, setTipModal] = useState(false);
  const [creditConfirmModal, setCreditConfirmModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);

  const [bankPay, setBankPay] = useState(0);
  const [cashPay, setCashPay] = useState(0);
  const [discountPay, setDiscountPay] = useState(0);
  const [extraDO, setExtraDO] = useState(0);
  const [discountType, setDiscountType] = useState("percentageDiscount");
  const [tflCouponId, setTflCouponId] = useState("");
  const [tflCouponPay, setTflCouponPay] = useState(0);
  const [creditPhoneNo, setCreditPhoneNo] = useState(0);
  const [tipPay, setTipPay] = useState(0);
  const [balanceAmt, setBalanceAmt] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [notAddExtras, setNotAddExtras] = useState();

  let table = localStorage.table;

  const propsData = props.location.state.detail; // prop data from opprettkonto

  let orderTotal = 0;
  let discount = 0;
  orderTotal = cartData.length
    ? totalOfCart + propsData.driveOutCharge - discount + Number(extraDO)
    : totalOfCart;

  if (discountType == "percentageDiscount") {
    discount = (orderTotal * discountPay) / 100;
  } else {
    discount = discountPay;
  }

  const remAmount = orderTotal - cashPay - tflCouponValue - discount;

  // formulas for bill summary
  const mvaFormula = totalOfCart - (100 * totalOfCart) / (100 + propsData.tax);
  const subtotalFormula = totalOfCart - mvaFormula;

  // payment buttons
  const paymentBtn = [
    {
      key: "1",
      name: "Bank"
    },
    {
      key: "2",
      name: "Cash"
    },
    {
      key: "3",
      name: "Credit"
    },
    {
      key: "4",
      name: "TFL Gift Card"
    },
    {
      key: "5",
      name: "Discount"
    },
    {
      key: "6",
      name: "Extra Drive Out Charges"
    }
  ];
  const handlechange = (index) => {
    if (index.name === "Bank") {
      bankFxn();
    } else if (index.name === "Cash") {
      cashFxn();
    } else if (index.name === "Credit") {
      creditFxn();
    } else if (index.name === "TFL Gift Card") {
      tflFxn();
    } else if (index.name === "Discount") {
      discountFxn();
    } else if (index.name === "Extra Drive Out Charges") {
      extraDOchargesFxn();
    }
  };

  //common api for payment
  let getInvoices = async (bankAmt = 0, isPaid = 1) => {
    let cashPaid = cashPay;
    if (cashPay > orderTotal) {
      cashPaid = orderTotal;
    }
    let postCalculations = {
      machineType: "FRONT_END",
      masked_pan: "1234xxxxxx",
      cardType: "card",
      cardBrand: "xxxx",
      cardExpiryDate: "xxxx",
      paymentStatus: "0000",
      taxPrice: orderTotal - extraDO - propsData.driveOutCharge,
      transactionId: "0000",
      type: propsData.orderType,
      vendorCode: "0000",
      vendorName: "system",
      coupon: tflCouponValue,
      coupanId: tflCouponId,
      extraDriveOut: extraDO,
      isPaid: isPaid,
      cash: cashPaid,
      discount: discount,
      bank: bankAmt,
      items: cartData
    };
    console.log("in get invoice");
    if (remAmount - bankAmt <= 0) {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/createinvoice/${table}`,
        postCalculations
      );
      console.log("after api call");
      if (resData.status == 200) {
        console.log("after sucess 200");
        let amtForTip = Math.abs(remAmount);
        setTipPay(amtForTip);
        if (remAmount < 0) {
          setCashConfirmModal(false);
          setTipModal(true);
        } else {
          history.push("/OpprettKonto");
          localStorage.setItem("table", "");
        }
      }
    } else if (!isPaid) {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/createinvoice/${table}`,
        postCalculations
      );
      console.log("after api call");
      if (resData.status == 200) {
        console.log("after sucess 200", isPaid);
        history.push("/OpprettKonto");
        localStorage.setItem("table", "");
      }
    }
  };

  const tipVerify = async () => {
    let tipDataEmp = {
      employeeId: localStorage.getItem("eid"),
      amount: tipPay,
      mode: "cash"
    };
    let tipData = await POSTAPI(`${appConfig.endpoint}/addtip`, tipDataEmp);
    if (tipData.status == 200) {
      let amtForTip = Math.abs(remAmount);
      setTipPay(amtForTip);
      if (remAmount >= 0) {
        history.push("/OpprettKonto");
        localStorage.setItem("table", "");
      } else {
        history.push("/OpprettKonto");
        localStorage.setItem("table", "");
      }
    }
  };

  const payTfl = () => {
    getTflCoupon();
  };

  let getTflCoupon = async () => {
    let postCoupon = {
      coupanCode: tflCouponPay // added coupon code from frontend
    };
    let resData = await POSTAPI(
      `${appConfig.endpoint}/api/v1/employee/coupan/getcoupanvalue`,
      postCoupon
    );
    if (resData.status == 200) {
      setTflCouponId(resData.data._id);
      if (resData.data.coupanValue > remAmount) {
        setTFLCouponValue(remAmount);
        getInvoices();
      } else {
        setTFLConfirmModal(false);
        setTFLCouponValue(resData.data.coupanValue);
      }
    } else if (resData.status == 202) {
      alert("Oops! coupon is expired or invalid");
    }
  };

  // calculation starts here
  // ------------------------------------bank
  const bankFxn = () => {
    setBankPay(remAmount);
    let bankAmt = remAmount;
    getInvoices(bankAmt);
  };
  // ------------------------------------cash
  const cashFxn = () => {
    setCashConfirmModal(true);
  };
  const handleCash = (e) => {
    let enteredValue = e.target.value;
    setCashPay(enteredValue);
  };
  const handleOk = () => {
    getInvoices();
    setCashConfirmModal(false);
  };
  // ------------------------------------credit
  const creditFxn = () => {
    setCreditConfirmModal(true);
  };
  const handleCredit = (e) => {
    let enteredValue = e.target.value;
    setCreditPhoneNo(enteredValue);
  };

  const creditOk = async (apiType) => {
    let postData;
    if (apiType == "fetchingData") {
      postData = {
        type: "phoneNo",
        customer: creditPhoneNo
      };
    } else {
      postData = {
        city: "test",
        country: "test",
        customer: "web1@omdata.world",
        firstName: "test",
        lastName: "test",
        line1: "test",
        line2: "test",
        phoneNo: creditPhoneNo,
        pin: 123456,
        state: "test",
        type: "email"
      };
    }
    let resData = await POSTAPI(
      `${appConfig.endpoint}/api/v1/employee/board/customerTable/${table}`,
      postData
    );
    if (resData.status == 200) {
      setCreditConfirmModal(false);
      setCustomerModal(true);
      setCustomerData(resData.data);
      if (apiType == "postingCustomer") {
        setCustomerModal(false);
        setGenerateInvoiceModal(true);
      }
    } else {
      setCustomerModal(false);
      history.push("/OpprettKonto");
      localStorage.setItem("table", "");
    }
  };
  const creditInovice = () => {
    getInvoices(0, 0);
  };
  const customerEdit = (e) => {
    setCustomerData(e.target.value);
  };

  // ------------------------------------tfl gift card
  const tflFxn = () => {
    setTFLConfirmModal(true);
  };

  let remTflCouponVal = orderTotal - tflCouponValue;
  const handleTfl = (e) => {
    let enteredValue = e.target.value;
    setTflCouponPay(enteredValue);
  };
  const handleTip = (e) => {
    let enteredValue = e.target.value;
    let changeAmt = Math.abs(remAmount) - enteredValue;
    setTipPay(enteredValue);
    setBalanceAmt(changeAmt);
  };
  const payTipAmt = () => {
    tipVerify();
  };

  // ------------------------------------Discount
  const discountFxn = () => {
    setDiscountConfirmModal(true);
  };
  const handleDiscount = (e) => {
    let enteredValue = e.target.value;
    setDiscountPay(enteredValue);
  };
  const payDiscount = () => {
    getInvoices();
    setDiscountConfirmModal(false);
  };
  const { Option } = Select;
  function handleChange(value) {
    setDiscountType(value);
  }
  // ------------------------------------Extra Drive Out Charges
  const extraDOchargesFxn = () => {
    setExtraDOchargesModal(true);
  };
  const handleExtraDriveOut = (e) => {
    let enteredValue = e.target.value;
    setExtraDO(enteredValue);
  };
  const applyExtraDriveOut = () => {
    // setExtraDO(extraDO);
    setExtraDOchargesModal(false);
  };
  useEffect(() => {
    totalCartAmt();
  }, [cartData]);

  // product category api call
  useEffect(() => {
    let getCategories = async () => {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/menu/categorybyordertype`,
        taxId
      );
      if (resData.status == 200) {
        setResponseData(resData.data);
        setCategoryData(true);
      }
    };
    getCategories();
  }, []);

  // product api call
  const displayProducts = async (id) => {
    let resData = await GETAllAPI(
      `${appConfig.endpoint}/api/v1/employee/products/getProduct/${id}`,
      "display product"
    );
    if (resData.status == 200) {
      setProductData(resData.data);
      setCategoryData(false);
    } else {
      setCategoryData(true);
    }
  };

  // add product in cart
  let addProduct = (productInfo, productAction) => {
    if (productInfo.quantity === 1 && productAction == "minus") {
      deletePro(productInfo.id);
      return;
    }
    //cart's updating
    let thisItem = cartData.filter((e) => e.id === productInfo.id);
    if (thisItem.length > 0) {
      //now update the item in cart
      let updateItem = cartData.map((e) => {
        if (productInfo.id == e.id) {
          let nQuantity;
          if (productAction == "add") {
            nQuantity = e.quantity + 1;
          } else if (productAction == "minus") {
            nQuantity = e.quantity - 1;
          }

          let nPrice = e.productPrice * nQuantity;
          return {
            ...e,
            quantity: nQuantity,
            totalAmount: nPrice
          };
        }
        return e;
      });
      setCartData(updateItem);
    } else {
      productInfo.totalAmount = productInfo.productPrice; //no item found in cart so insert this item in cart
      setCartData((old) => [...old, productInfo]);
    }
  };

  // add extras
  const extraModal = () => {
    setCustomizeModal(true);
  };

  const onChange = (checkedValues) => {
    setNotAddExtras(checkedValues);
    console.log("checked = ", checkedValues);
  };

  // all product total "SUM"
  let totalCartAmt = () => {
    let totalAmountCart = 0;
    if (cartData.length) {
      cartData.map((e) => {
        if (e) {
          totalAmountCart = e.totalAmount + totalAmountCart;
        }
      });
    }
    setTotalOfCart(totalAmountCart);
  };

  // action in cart
  let backBtn = () => {
    setCategoryData(true);
  };
  const clearCartPro = () => {
    setCartData([]);
  };
  const deletePro = (id) => {
    let a = cartData.filter((e) => e.id !== id);
    if (a.length == 0) {
      setCartData([]);
    } else {
      setCartData(a);
    }
  };
  // modal action
  const handleClose = () => {
    setCashConfirmModal(false);
    setDiscountConfirmModal(false);
    setTFLConfirmModal(false);
    setExtraDOchargesModal(false);
    setTipModal(false);
    setCreditConfirmModal(false);
    setCustomerModal(false);
    setGenerateInvoiceModal(false);
    setCustomizeModal(false);
  };

  return (
    <Home>
      <Row className="d-flex justify-content-between">
        <Col span={9}>
          <div className="d-flex justify-content-between mb-3">
            {/* <p className="mb-0">Table No </p> */}
            {cartData != "" ? (
              <Button onClick={() => clearCartPro()}>Clear Cart</Button>
            ) : (
              ""
            )}
          </div>
          <div className="billDisplay">
            <div className="cartProDisplay">
              {cartData.length > 0 ? (
                <>
                  {cartData.map((e) => {
                    return (
                      <div className="col-md-12 mb-2 pl-0" key={e.productId}>
                        <Card className="d-flex py-0 px-2 justify-content-between align-items-center cartProductCard">
                          <div style={{ width: "100%" }}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="mb-0">
                                  <b>{e.productName}</b>
                                </p>
                                <p className="mb-0">Quantity * {e.quantity}</p>
                                <p className="mb-0">Kr.{e.totalAmount}</p>
                                <Button
                                  type="link"
                                  style={{ color: "red" }}
                                  className="p-0"
                                  onClick={extraModal}
                                >
                                  <i>
                                    <u>customize</u>
                                  </i>
                                </Button>
                              </div>
                              <div className="d-flex flex-column justify-content-between align-items-end">
                                <div className="d-flex justify-content-end">
                                  <Button
                                    type="link"
                                    style={{ color: "red" }}
                                    className="p-0"
                                    onClick={() => deletePro(e.id)}
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>{" "}
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                  <Button
                                    style={{
                                      backgroundColor: "#07182c",
                                      height: "30px",
                                      color: "#fff"
                                    }}
                                    className="minus"
                                    onClick={() => {
                                      addProduct(e, "minus");
                                    }}
                                  >
                                    -
                                  </Button>
                                  <p className="px-2 mb-0">{e.quantity}</p>
                                  <Button
                                    style={{
                                      backgroundColor: "#07182c",
                                      height: "30px",
                                      color: "#fff"
                                    }}
                                    className="plus"
                                    onClick={() => {
                                      addProduct(e, "add");
                                    }}
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        {/* {customizeModal && (
                          <Modal
                            centered
                            visible={customizeModal}
                            title="Add Extras"
                            // onOk={handleOkk}
                            onCancel={handleClose}
                          >
                            <>
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={onChange}
                              >
                                <Row style={{ marginBottom: 16 }}>
                                  {e.ingredents.map((data) => {
                                    console.log("here some content data", data);
                                    return (
                                      <Col span={8}>
                                        <Checkbox value={data._id}>
                                          {data.ingName}
                                        </Checkbox>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Checkbox.Group>
                              <p>Do not include: {notAddExtras}</p>
                              <div style={{ marginTop: 16 }}>
                                <h6>Spicyness</h6>
                                <Radio.Group defaultValue="a">
                                  <Radio.Button value="a">
                                    Mild Spicy
                                  </Radio.Button>
                                  <Radio.Button value="b">Spicy</Radio.Button>
                                  <Radio.Button value="c">
                                    Extra Spicy
                                  </Radio.Button>
                                </Radio.Group>
                              </div>
                            </>
                          </Modal>
                        )} */}
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>Add Product</p>
              )}
            </div>

            <br />

            <div className="totalBillSummary">
              <Descriptions>
                <Descriptions.Item label="Sub-Totall">
                  <p>Kr. {subtotalFormula.toFixed(2)} </p>
                </Descriptions.Item>{" "}
              </Descriptions>{" "}
              <Descriptions>
                <Descriptions.Item label={`Mva (${propsData.tax}%)`}>
                  Kr. {mvaFormula.toFixed(2)}
                </Descriptions.Item>{" "}
              </Descriptions>
              {props.discount > 0 ? (
                <Descriptions>
                  <Descriptions.Item label={`Rabatt (${propsData.discount}%)`}>
                    Kr. {propsData.discount}
                  </Descriptions.Item>
                </Descriptions>
              ) : (
                ""
              )}
              {discount > 0 ? (
                <Descriptions>
                  <Descriptions.Item label="Discount">
                    Kr. {discount}
                  </Descriptions.Item>{" "}
                </Descriptions>
              ) : (
                ""
              )}
              {props.orderType != "Drive Out" ? (
                <Descriptions>
                  <Descriptions.Item label="Drive Out Charges (+)">
                    Kr. {propsData.driveOutCharge}
                  </Descriptions.Item>{" "}
                </Descriptions>
              ) : (
                ""
              )}
              {extraDO > 0 ? (
                <Descriptions>
                  <Descriptions.Item label="Extra Drive Out Charges (+)">
                    Kr. {extraDO}
                  </Descriptions.Item>{" "}
                </Descriptions>
              ) : (
                ""
              )}
              <Descriptions>
                <Descriptions.Item label="Total">
                  Kr. {orderTotal}
                </Descriptions.Item>
              </Descriptions>
              {remAmount > 0 ? (
                <Descriptions>
                  <Descriptions.Item label="Remaining Amount">
                    Kr. {remAmount}
                  </Descriptions.Item>
                </Descriptions>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col span={14}>
          <div className="d-flex justify-content-end">
            {/* <Search
              className="w-100"
              placeholder="input search text"
              onSearch={onSearch}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              style={{
                width: 200
              }}
            /> */}
            {!categoryData ? (
              <>
                <Button className="ml-2 w-100" onClick={() => backBtn()}>
                  Back to Categories
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
          <div
            className="d-flex flex-wrap mt-3"
            style={{
              maxHeight: "430px",
              overflowY: "scroll",
              overflowX: "hidden"
            }}
          >
            {categoryData ? (
              <>
                {responseData.map((e) => {
                  return (
                    <div className="col-md-4 mb-2 pl-0" key={e._id}>
                      <Card
                        className="d-flex p-2 justify-content-between align-items-center productCatCard"
                        hoverable
                        cover={
                          <img
                            alt="example"
                            src={`${appConfig.s3url}/${e.image}`}
                            style={{ maxWidth: "40px", maxHeight: "40px" }}
                          />
                        }
                        onClick={() => displayProducts(e._id)}
                      >
                        <Meta title={e.name} />
                      </Card>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {productData.map((e) => {
                  return (
                    <div className="col-md-6 mb-2 pl-0">
                      <Card
                        key={e._id}
                        className="d-flex py-1 px-2 justify-content-between align-items-center productCard"
                        hoverable
                        onClick={() => {
                          addProduct(
                            {
                              id: e._id,
                              productName: e.name,
                              productPrice: e.price,
                              quantity: 1,
                              category: false,
                              catType: e.categoryId.catType,
                              categoryId: e.categoryId._id,
                              ingredents: e.ingredents,
                              sCatType: e.categoryId.superCategory.sCatType,
                              superCategory: e.categoryId.superCategory._id,
                              status: "IN-PROCESS"
                            },

                            "add"
                          );
                        }}
                      >
                        <Meta
                          avatar={
                            <Avatar
                              className="d-flex"
                              alt="example"
                              src={`${appConfig.s3url}/${e.image}`}
                              style={{ width: "80px", height: "80px" }}
                            />
                          }
                          title={e.name}
                          description={`Kr. ${e.price}`}
                        />
                      </Card>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {cartData.length > 0 ? (
            <div className="d-flex flex-wrap pr-2 py-3 buttonDisplay mt-3">
              {paymentBtn.map((e) => {
                return (
                  <div className="col-md-4 mb-2 pl-0" key={e.key}>
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => {
                        handlechange(e);
                      }}
                    >
                      {e.name}
                    </Button>{" "}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {cashConfirmModal && (
            <Modal
              centered
              visible={cashConfirmModal}
              title="Cash Payment"
              onOk={handleOk}
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={handleOk}>
                  Pay
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>Order Total</p>
                  <p>Kr. {orderTotal}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Amount Paid</p>
                  <Input
                    prefix="Kr. "
                    style={{ width: 150 }}
                    type="number"
                    value={cashPay}
                    onChange={handleCash}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <p>Change</p>
                  <p>Kr. {remAmount}</p>
                </div>
              </div>
            </Modal>
          )}
          {creditConfirmModal && (
            <Modal
              centered
              visible={creditConfirmModal}
              title="Credit Confirm Modal"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={() => creditOk("fetchingData")}>
                  Next
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>Order Total</p>
                  <p>Kr. {orderTotal}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Phone No.</p>
                  <Input
                    style={{ width: 150 }}
                    type="number"
                    onChange={handleCredit}
                  />
                </div>
              </div>
            </Modal>
          )}
          {tipModal && (
            <Modal
              centered
              visible={tipModal}
              title="Tip Payment"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={payTipAmt}>
                  Pay
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>Balance Amount</p>
                  <p>Kr. {remAmount}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Tip(if any)</p>
                  <p>
                    {" "}
                    Kr.
                    <Input type="number" value={tipPay} onChange={handleTip} />
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Change</p>
                  <p>Kr. {balanceAmt}</p>
                </div>
              </div>
            </Modal>
          )}
          {tflConfirmModal && (
            <Modal
              centered
              visible={tflConfirmModal}
              title="TFL Payment"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={payTfl}>
                  Pay
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>Order Total</p>
                  <p>Kr. {orderTotal}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Coupon Value(if any)</p>
                  <div>
                    <Input
                      value={tflCouponPay}
                      onChange={handleTfl}
                      style={{ width: 150 }}
                    />
                    <Button onClick={() => getTflCoupon()}>Apply</Button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Remaining Amount to pay</p>
                  <p>Kr. {remTflCouponVal <= 0 ? 0 : <>{remTflCouponVal}</>}</p>
                </div>
              </div>
            </Modal>
          )}
          {discountConfirmModal && (
            <Modal
              centered
              visible={discountConfirmModal}
              title="Discount Payment"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={payDiscount}>
                  Pay
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>Order Total</p>
                  <p>Kr. {orderTotal}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Discount %</p>
                  <div>
                    {" "}
                    <Input
                      style={{ width: 120 }}
                      type="number"
                      value={discountPay}
                      onChange={handleDiscount}
                    />
                    <Select
                      defaultValue="percentageDiscount"
                      style={{ width: 120 }}
                      onChange={handleChange}
                    >
                      <Option value="flatDiscount">Flat Discount</Option>
                      <Option value="percentageDiscount">Percentage</Option>
                    </Select>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Reaming Amount</p>
                  <p>Kr. {remAmount}</p>
                </div>
              </div>
            </Modal>
          )}
          {extraDOchargesModal && (
            <Modal
              centered
              visible={extraDOchargesModal}
              title="Extra Drive Out Charges"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={applyExtraDriveOut}>
                  Apply
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  {" "}
                  <p>Extra Drive Out Charges</p>
                  <Input
                    type="number"
                    value={extraDO}
                    onChange={handleExtraDriveOut}
                  />
                </div>
              </div>
            </Modal>
          )}
          {customerModal && (
            <Modal
              centered
              visible={customerModal}
              title="Customer Data Modal"
              onCancel={handleClose}
              footer={[
                // <Button key="submit" onClick={sendCustomerData}>
                <Button
                  key="submit"
                  onClick={() => creditOk("postingCustomer")}
                >
                  Add
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <p>First Name</p>
                  <p>{customerData.firstName}</p>
                  {/* <Input
                    type="number"
                    value={customerData.firstName}
                    defaultValue={customerData.firstName}
                    onChange={customerEdit}
                    name="firstName"
                    maxlength="20"
                  /> */}
                </div>
                <div className="d-flex justify-content-between">
                  <p>Last Name</p>
                  <p>{customerData.lastName}</p>
                  {/* <Input
                    type="number"
                    value={customerData.lastName}
                    onChange={customerEdit}
                    name="lastName"
                    maxlength="20"
                  /> */}
                </div>
                <div className="d-flex justify-content-between">
                  <p>Phone Number</p>
                  <p>{customerData.phoneNo}</p>
                </div>
              </div>
            </Modal>
          )}
          {generateInvoiceModal && (
            <Modal
              centered
              visible={generateInvoiceModal}
              title="Generate Invoice Modal"
              onCancel={handleClose}
              footer={[
                <Button key="submit" onClick={() => creditInovice("ispaidVal")}>
                  Generate
                </Button>,
                <Button key="back" onClick={handleClose}>
                  Close
                </Button>
              ]}
            >
              <div>Generate Invoice</div>
            </Modal>
          )}
        </Col>
      </Row>
    </Home>
  );
}
