import React from "react";
import Home from "../Home/index";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const Index = () => {
  return (
    <Home>
      <div className="report-container text-center">
        <span className="item ">IK System</span>
      </div>
      <Card>
        <Card.Body>
          <Row className="m-b-1">
            <Col>
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                IK MAT
              </NavLink>
            </Col>
            <Col className=" text-right">
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                IK EL
              </NavLink>
            </Col>
          </Row>
          <Row className="m-b-1">
            <Col>
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                IK ALCH
              </NavLink>
            </Col>
            <Col className=" text-right">
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                HMS
              </NavLink>
            </Col>
          </Row>
          <Row className="m-b-1">
            <Col>
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                IK BRANN
              </NavLink>
            </Col>
            <Col className=" text-right">
              <NavLink
                to="#"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                FORSIKRING
              </NavLink>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Home>
  );
};

export default Index;
