import React, { useEffect } from "react";
import Home from "../../Home";
import { connect } from "react-redux";
import { getReportView } from "../../../action/ReportAction";
import SingleInvoice from "../../../containers/SingleInvoice/SingleInvoice";
import { Button } from "react-bootstrap";
const ViewReport = (props) => {
  useEffect(() => {
    async function fetchData() {
      await props.getReportView();
    }
    fetchData();
  }, []);
  const goBackButton = () => {
    props.setPage("list");
  };
  const singleInvoice = () => {
    if (
      props.viewReport.billData &&
      Object.keys(props.viewReport.billData).length
    ) {
      return (
        <SingleInvoice
          data={props.viewReport.billData}
          branch={props.viewReport.branch}
        />
      );
    }
  };
  return (
    <Home isAdmin={true}>
      <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
          <span id="less-visible">Hjem / </span>
          Invoice No. :
          {props.viewReport ? props.viewReport.billData.invoiceNumber : ""}
        </span>
        <span id="less-visible">
          <Button onClick={() => goBackButton()} className="ant-btn back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </Button>
        </span>
      </div>
      {console.log("inside the view of the daily report customer details====>")}
      {singleInvoice()}
    </Home>
  );
};
const mapStateToProps = ({ viewReport }) => {
  return {
    viewReport
  };
};
export default connect(mapStateToProps, {
  getReportView
})(ViewReport);
