import { SET_LOGIN_MODAL,DISABLE_LOGIN_MODAL ,SET_LOGIN_VALUE} from "./../../action/types";

const initialState={
    modelState:0,
    modelLogin:0
}
const modelLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_MODAL:
      return { ...state,
      modelState: action.payload,
    };
    case DISABLE_LOGIN_MODAL:
    return { ...state,
    modelState: action.payload};  

    case SET_LOGIN_VALUE:
        return { ...state,
        modelLogin: action.payload};  
default:
     return state;


   
  }
};
export default modelLoginReducer