import React, { Component } from "react";
import { push } from "react-router-redux";
import { Icon as RIcon } from "react-icons-kit";
import { Card, Form, Modal, Button, Input, notification } from "antd";
import "./RightMenu.css";
import returnItem from "../../assets/return.png";
import payment from "../../assets/payment.png";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { connect } from "react-redux";
import { ic_person } from "react-icons-kit/md/ic_person";
import { withRouter } from "react-router-dom";
import { money } from "react-icons-kit/fa/money";
import appConfig from "../../helpers/appConfig";
import { Spin } from "antd";
import CoupanModal from "../Coupans/CoupanModal";
const { billHtmlGenerate } = require("../../helpers/billHtmlGenerate");

const confirm = Modal.confirm;

class RightMenu extends Component {
  constructor(props) {
    super(props);
    var eid = localStorage.getItem("eid");
    var bid = localStorage.getItem("bid");
    this.state = {
      visible: false,
      cashpopupFlag: false,
      tippopupFlag: false,
      value: 0,
      type: "",
      isCredit: false,
      empId: eid,
      brId: bid,
      customer: "",
      gvnCash: 0,
      driveoutCharge: 0,
      invoiceLoaderHunderd: false,
      invoiceLoaderTwoHunderd: false,
      invoiceLoaderFiveHunderd: false,
      invoiceLoaderThousand: false,
      invoiceBank: false,
      saveCashLoading: false,
      discountError: false,
      coupanModal: false,
      coupanCode: "",
      coupanModalPopup: false,
      coupanValue: 0
    };
  }

  transaction = {
    machineType: "FRONT_END",
    transactionId: "0000",
    paymentStatus: "0000",
    vendorCode: "xxxx",
    vendorName: "xxxx",
    masked_pan: "xxxx",
    cardExpiryDate: "xxxx",
    cardBrand: "xxxx",
    cardType: "xxxx"
  };

  componentWillReceiveProps = async () => {
    this.setState({
      product: this.props.product,
      productData: this.state.productData,
      driveoutCharge: await localStorage.getItem("driveoutCharge")
    });
  };
  componentWillUnmount() {
    // console.log("RIGHT MENU UNOUNT");
    // if (this.state.productData && this.state.productData.length !== 0) {
    //   this.saveconfirmDialog();
    // }
  }
  addTip = (amount, key) => {
    var hdr = localStorage.token;
    var employeeId = localStorage.eid;
    var amount = amount;
    var postData = {
      employeeId: employeeId,
      amount: amount
    };

    console.log("saving the tip", postData);
    fetch(`${appConfig.endpoint}/api/v1/employee/board/addtip`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        this.openNotification("success", `Received Kr. ${amount} as TIP`);
        notification.close(key);
      });
  };

  saveconfirmDialog = async () => {
    confirm({
      title: "Do you want to save this table?",
      onOk: () => {
        this.openNotification("success", "Table saved successfully");
        this.props.saveItems();
        // this.setState({ isCredit: true });
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        console.log("Cancel");
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      type: "percent"
    });
  };
  showCashModal = () => {
    this.setState({ cashpopupFlag: true });
  };
  showTipModal = () => {
    this.setState({ tippopupFlag: true });
  };
  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  openTipNotification = (message, amount) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={
          () => this.addTip(amount, key)
          // notification.close(key)
        }
      >
        MARK AS TIP
      </Button>
    );
    notification.open({
      message: "TIP NOTIFICATION",
      description: message,
      btn,
      key,
      duration: 150500
      // onClose: close
    });
  };

  billformat(data) {
    var dd = JSON.parse(JSON.stringify(data));

    dd.data.date = moment(dd.data.createdAt).format("DD-MM-YY");
    dd.data.time = moment(dd.data.createdAt).format("HH:mm:ss");
    var driveoutdata = [];
    if (dd.data.orderType == "Drive Out") {
      dd.data.items.forEach((element) => {
        driveoutdata.push(element);
      });
    }

    var html = billHtmlGenerate(dd.data); // Common HTML Function

    var bimageString = window.btoa(html);
    return bimageString;
  }
  handleChangef = (e) => {
    console.log("getting e", e);
    this.setState({ [e.target.name]: e.target.value });
  };

  cashGvn = (csh) => {
    this.setState({
      gvnCash: this.state.gvnCash + Number(csh),
      saveCashLoading: true
    });
    console.log("ENTERY CSHGVN", this.state.gvnCash);
    console.log("ENTERY CSHGVN", csh);
  };

  //  invoice creation function

  invoiceHandler = async (btntype, val) => {
    console.log("PRODUCT LIST OF STATE", this.state.product);

    var taxPrice =
      this.state.product &&
      this.state.product.reduce(function (acc, x) {
        return acc + x.productPrice * x.quantity;
      }, 0);

    const { type, discount, cash } = this.props;
    var taxPrice = this.state.product
      ? this.state.product.reduce(function (acc, x) {
          return acc + x.productPrice * x.quantity;
        }, 0)
      : 0;
    if (this.props.orderType && this.props.orderType == "Drive Out") {
      var total =
        Number(taxPrice) -
        Number(type === "percent" ? (taxPrice * discount) / 100 : discount) +
        Number(this.state.driveoutCharge);
    } else {
      var total =
        taxPrice -
        (type === "percent" ? (taxPrice * discount) / 100 : discount);
    }
    let { creditCheck } = this.props;
    let isPaid = creditCheck ? 0 : 1;
    var hdr = localStorage.token;
    var table = localStorage.table;
    var acctMoney = 0;
    var data = {
      items: this.state.product,
      taxPrice: taxPrice.toFixed(3),
      type,
      isPaid,
      discount
    };
    if (
      btntype == 100 ||
      btntype == 200 ||
      btntype == 500 ||
      btntype == 1000 ||
      val !== 0
      // btntype == "cash"
    ) {
      if (val !== 0) {
        var enteredCash = val;
      } else {
        var enteredCash = btntype;

        //console.log("enterdedddd", enteredCash);
        // acctMoney = acctMoney + enteredCash;
        await this.cashGvn(enteredCash);
        console.log("ENTE RED CASHSHS", this.state.gvnCash);
      }
    }
    if (this.state.product && this.state.product.length > 0) {
      if (
        btntype == "bank" ||
        (Number(this.state.gvnCash) - Number(total)).toFixed(0) -
          Number(this.state.coupanValue) >=
          0
      ) {
        console.log("I CAME IN", Number(cash));
        console.log("I CAME IN", Number(cash) - Number(total));
        if (btntype == "bank") {
          this.setState({ invoiceBank: true });
          this.transaction.masked_pan = "1234xxxxxx";
          this.transaction.vendorCode = "0000";
          this.transaction.vendorName = "system";
          this.transaction.cardType = "card";
        }
        data = { ...data, ...this.transaction };

        data.cash =
          this.state.gvnCash >= Number(total) ? total : this.state.gvnCash;

        data.coupanValue =
          this.state.coupanValue >= Number(total)
            ? total
            : this.state.coupanValue;
        data.bank =
          this.state.gvnCash < Number(total)
            ? Number(total) - Number(this.state.gvnCash)
            : 0;

        console.log("helooooo", this.state.coupanValue);

        fetch(
          `${appConfig.endpoint}/api/v1/employee/invoice/createinvoice/${table}`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "x-auth-token": hdr,
              "Content-Type": "application/json"
            }
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "failure") {
              this.setState({ invoiceLoader: false });
              this.openNotification(
                "error",
                "Invoice already generated or something went wrong."
              );
            } else {
              this.setState({
                invoiceLoaderHunderd: false,
                invoiceLoaderTwoHunderd: false,
                invoiceLoaderFiveHunderd: false,
                invoiceLoaderThousand: false,
                invoiceBank: false
              });
              // if (window.Print) window.Print.printBill(printResult.url);
              this.openNotification("success", "Bill printed successfully");
              // parseInt(btntype)
              this.reduceUsedStock(data.items);
              if (
                result.data.totalPrice < Number(this.state.gvnCash) ||
                btntype == "cash" ||
                btntype !== "bank"
              ) {
                var leftamt =
                  parseInt(this.state.gvnCash) -
                  parseInt(result.data.totalPrice);
                this.openTipNotification(
                  `Cash To be returned : ${leftamt.toFixed(
                    2
                  )}, If you want to MARK IT AS TIP Press BUTTON BELOW on close to REFUND or CANCEL`,
                  leftamt
                );
              }
              if (btntype >= 100) {
                this.props.cashGiven(btntype);
                if (window.CashBox) window.CashBox.OpenCashbox("A", "B"); // was commented
              }
              if (btntype !== "bank") {
                if (window.CashBox) window.CashBox.OpenCashbox("A", "B");
              }
              this.props.history.goBack();
              localStorage.setItem("table", null);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.props.cashGiven(enteredCash);
        this.setState({ saveCashLoading: false });
      }
    } else {
      this.openNotification("error", "Please Add Element in cart");
    }
  };

  reduceUsedStock = (items) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/reduceUsedStock`, {
      method: "POST",
      body: JSON.stringify({ items: items }),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("failed to reduce the ingredients!");
        } else {
          console.log("Successfully reduced the ingredients!");
        }
      });
  };
  cashboxFunction = () => {
    if (window.CashBox)
      window.CashBox.OpenCashbox(this.state.empId, this.state.brId);
  };
  cashboxFunctionNm = async (amount) => {
    if (amount == 100 || amount == 200 || amount == 500 || amount == 1000) {
      if (this.state.product && this.state.product.length > 0) {
        if (amount == 100) {
          this.setState({ invoiceLoaderHunderd: true });
        } else if (amount == 200) {
          this.setState({ invoiceLoaderTwoHunderd: true });
        } else if (amount == 500) {
          this.setState({ invoiceLoaderFiveHunderd: true });
        } else if (amount == 1000) {
          this.setState({ invoiceLoaderThousand: true });
        }

        this.invoiceHandler(amount, 0);
        //if (window.CashBox) window.CashBox.OpenCashbox("A", "B");
      } else {
        alert("no product in cart ! Please select product");
      }
    } else {
      if (
        amount === "cash" &&
        this.state.product &&
        this.state.product.length > 0
      ) {
        this.invoiceHandler("cash", 0);
        // if (window.CashBox) window.CashBox.OpenCashbox("A", "B");
      } else {
        alert("no product in cart ! Please select product");
      }
    }
  };

  showConfirm = async () => {
    console.log("you are in confirm");
    let result = await this.props.updateCustomerModalIsOpen("Open");

    if (!result) return;

    confirm({
      title: "Do you Want to mark this as credit invoice ?",
      onOk: () => {
        this.openNotification("success", "Credit added successfully");
        this.setState({ isCredit: true });
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {}
    });
  };

  handleChange = (e) => {
    if (e.target.value > 100) {
      this.setState({ discountError: true });
      this.setState({ value: 0 });
    } else {
      this.setState({ discountError: false });
      this.setState({ value: e.target.value });
    }
  };
  cashmodalfun = async (val) => {
    await this.cashGvn(val);
    // this.props.cashGiven(val);
    this.invoiceHandler("cash", val);
  };

  handleChangeCoupan = (e) => {
    this.setState({
      coupanCode: e.target.value
    });
  };
  handleClose = (e) => {
    this.setState({
      visible: false,
      cashpopupFlag: false,
      tippopupFlag: false,
      value: 0
    });
  };

  closeCoupanModal = () => {
    this.setState({
      coupanModal: false
    });
  };

  onChange = (e) => {
    this.setState({
      type: e.target.value
    });
  };

  getCoupanCode = async () => {
    var hdr = localStorage.token;
    let data = await fetch(
      `${appConfig.endpoint}/api/v1/employee/coupan/getcoupanvalue`,
      {
        method: "POST",
        body: JSON.stringify({ coupanCode: this.state.coupanCode }),
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      }
    );
    let coupanData = await data.json();
    this.setState({
      coupanValue: coupanData.data.coupanValue
    });
    this.invoiceHandler("coupan", this.state.coupanValue);

    this.setState({
      coupanModalPopup: false
    });
  };
  handleCoupanClose = () => {
    this.setState({
      coupanModalPopup: false
    });
  };
  tipmodalfun = (value) => {
    var hdr = localStorage.token;
    var employeeId = localStorage.eid;
    var amount = value;
    var postData = {
      employeeId: employeeId,
      amount: amount
    };
    fetch(`${appConfig.endpoint}/api/v1/employee/board/addtip`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        this.openNotification("success", `Received Kr. ${amount} as TIP`);
        this.setState({ tippopupFlag: false });
        // notification.close(key);
      });
  };
  render() {
    const { type, value } = this.state;
    return (
      <div id="right-menu-main-wrapper">
        <Card className="card-style">
          <div className="right-upper-button">
            {localStorage.getItem("couponPermission") === "true" && (
              <></>
              // <button
              //   disabled={this.props.creditCheck && this.props.creditCheck}
              //   className="leftButton shadow"
              //   onClick={() => {
              //     this.setState({
              //       coupanModal: true
              //     });
              //   }}
              // >
              //   <p className="mb-0">NAVN</p>
              // </button>
            )}

            {/* <button
            style={{cursor:"not-allowed"}}
            disabled={true}
              // disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => {
                this.navigate("/reports");
              }}
              className="leftButton shadow"
            >
              <img src={returnItem} className="return-image-right" />
              <p className="mb-0">RAP</p>
            </button> */}

            {/* <button
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => this.showTipModal()}
              className="rightButton shadow"
            >
              <RIcon size={25} icon={money} />
              <p className="mb-0">TIP</p>
            </button> */}

            
            {/* <button
             style={{cursor:"not-allowed"}}
             disabled={true}
              // disabled={this.props.creditCheck && this.props.creditCheck}
              className="rightButton shadow"
            >
              <img src={returnItem} className="return-image-right-black" />
              <p className="mb-0">RETUR</p>


            </button> */}

            
            {/* <Button
              width="100%"
              style={{ color: "white", fontWeight: "bold" }}
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => {
                this.setState({
                  coupanModalPopup: true
                });
              }}
              // onclick={() => "openCashbox.performClick();"}
              className="rightButton shadow "
            >
              <b>COUPAN</b>
            </Button> */}
          </div>

          <div className="right-upper-button">
            {/* <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              className="leftButton shadow"
            >
              <b>APPLE PAY</b>
            </Button> */}


            <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => this.invoiceHandler("bank", 0)}
              className="leftButton shadow"
            >
              {this.state.invoiceBank ? <Spin></Spin> : <b>KORT</b>}
            </Button>


            {/* <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              className="rightButton shadow"
              // onClick={() => this.props.cashGiven(100)}
              onClick={() => this.cashboxFunctionNm(100)}
            >
              {this.state.invoiceLoaderHunderd ? <Spin></Spin> : <b>100</b>}
            </Button>           
            <Button
              className="rightButton shadow"
              disabled={this.props.creditCheck && this.props.creditCheck}
              // onClick={() => this.props.cashGiven(200)}
              onClick={() => this.cashboxFunctionNm(200)}
            >
              {this.state.invoiceLoaderTwoHunderd ? <Spin></Spin> : <b>200</b>}
            </Button> */}


            {/* <Button
              disabled={
                (this.props.creditCheck && this.props.creditCheck) ||
                (this.state.product && this.state.product.length == 0)
              }
              className="rightButton shadow"
              onClick={this.showModal}
            >
              <b>RABATT</b>
            </Button> */}
          </div>

          <div className="right-upper-button">
            {/* <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              // onClick={() => this.cashboxFunctionNm("cash")}
              onClick={() => this.showCashModal()}
              className="leftButton shadow"
            >
              <b>KONTANT</b>
            </Button> */}


            {/* <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => this.cashboxFunction()}
              // onclick={() => "openCashbox.performClick();"}
              className="leftButton shadow"
            >
              <b>SKUFF</b>
            </Button> */}

            
            {/* <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              className="rightButton shadow"
              // onClick={() => this.props.cashGiven(500)}
              onClick={() => this.cashboxFunctionNm(500)}
            >
              {this.state.invoiceLoaderFiveHunderd ? <Spin></Spin> : <b>500</b>}
            </Button> */}
{/*             
            <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => this.cashboxFunctionNm(1000)}
              className="rightButton shadow"
            >
              {this.state.invoiceLoaderThousand ? <Spin></Spin> : <b>1000</b>}
            </Button> */}

{/*             
            <Button
              disabled={this.props.creditCheck && this.props.creditCheck}
              className="rightButton"
              onClick={async () => {
                await this.showConfirm();
              }}
            >
              <b>KREDITT</b>
            </Button> */}
          </div>

          <div className="right-upper-button">
            {/* <Button
              width="100%"
              style={{ color: "white", fontWeight: "bold" }}
              disabled={this.props.creditCheck && this.props.creditCheck}
              onClick={() => {
                this.setState({
                  coupanModalPopup: true
                });
              }}
              onclick={() => "openCashbox.performClick();"}
              className="leftButton shadow "
            >
              <b>COUPAN</b>
            </Button> */}
            {/* <Button
              disabled={
                (this.props.creditCheck && this.props.creditCheck) ||
                (this.state.product && this.state.product.length == 0)
              }
              className="leftButton shadow"
              onClick={this.showModal}
            >
              <b>RABATT</b>
            </Button> */}

            {/* <Button
              style={{ marginLeft: "40px" }}
              disabled={this.props.creditCheck && this.props.creditCheck}
              className="rightButton"
              onClick={async () => {
                await this.showConfirm();
              }}
            >
              <b>KREDITT</b>
            </Button> */}

            <Modal
              width={400}
              title="Rabatt"
              visible={this.state.visible}
              onOk={() => {
                if (this.state.discountError === false) {
                  this.props.handleOk(type, value);
                  this.handleClose();
                } else {
                  this.handleClose();
                }
              }}
              onCancel={this.handleClose}
            >
              <Form>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                ></FormItem>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Input
                    type="number"
                    min="1"
                    max="99"
                    prefix={this.state.type === "percent" ? "%" : "Kr."}
                    style={{ maxWidth: 200 }}
                    value={this.state.value}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  {this.state.discountError ? (
                    <>
                      <div style={{ color: "red" }}>
                        Discount cannot be more than 100
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </FormItem>
              </Form>
            </Modal>

            <Modal
              width={400}
              title="Cash"
              footer={[
                <Button key="back" onClick={this.handleClose}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={this.state.saveCashLoading}
                  onClick={() => {
                    this.cashmodalfun(value);
                    this.handleClose();
                  }}
                >
                  Submit
                </Button>
              ]}
              visible={this.state.cashpopupFlag}
              onOk={() => {
                this.cashmodalfun(value);
                // alert(value);
                this.handleClose();
              }}
              onCancel={this.handleClose}
            >
              <Form>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                ></FormItem>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Input
                    type="number"
                    min="1"
                    prefix={"Kr."}
                    style={{ maxWidth: 200 }}
                    value={this.state.value}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormItem>
              </Form>
            </Modal>

            <Modal
              width={400}
              title="Coupan"
              visible={this.state.coupanModalPopup}
              centered
              onOk={() => {
                this.getCoupanCode();
              }}
              onCancel={this.handleCoupanClose}
            >
              <Form>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                ></FormItem>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Input
                    type="text"
                    min="1"
                    placeholder="enter coupon code"
                    style={{ maxWidth: 200 }}
                    value={this.state.coupanCode}
                    onChange={(e) => this.handleChangeCoupan(e)}
                  />
                </FormItem>
              </Form>
            </Modal>

            <CoupanModal
              visible={this.state.coupanModal}
              closeCoupanModal={this.closeCoupanModal}
            />


            <Modal
              width={400}
              title="TIP"
              visible={this.state.tippopupFlag}
              onOk={() => {
                this.tipmodalfun(value);
                // alert(value);
                this.handleClose();
              }}
              onCancel={this.handleClose}
            >
              <Form>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                ></FormItem>
                <FormItem
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Input
                    type="number"
                    min="1"
                    prefix={"Kr."}
                    style={{ maxWidth: 200 }}
                    value={this.state.value}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormItem>
              </Form>
            </Modal>
          </div> 

          {/* <div
            style={{
              padding: 5,
              borderWidth: 2,
              borderColor: "grey",
              borderStyle: "groove",
              height: "100px",
              textAlign: "center"
            }}
          >
            {this.props.customerunpaidInvoice &&
            this.props.customerunpaidInvoice.length == 0 ? (
              <form>
                <input
                  onChange={this.handleChangef}
                  name="customer"
                  style={{ width: "58%" }}
                  type="text"
                  className="input2"
                  placeholder="Tel. Nr."
                  value={this.state.customer}
                />

                <button
                  disabled={this.state.customer.toString().length == 0}
                  style={{
                    // minWidth: "35%",
                    float: "right",
                    fontSize: 10,
                    padding: 7,
                    fontWeight: "bold"
                  }}
                  onClick={() => (
                    this.props.findCustomer(this.state.customer),
                    this.props.checkCustomerEmail(this.state.customer)
                  )}
                  type="button"
                  id="submit-landing2"
                >
                  {this.props.findcustomerLoader ? (
                    <Spin size="small"></Spin>
                  ) : (
                    <b>LEGG TIL KUNDE</b>
                  )}
                </button>
              </form>
            ) : (
              <p style={{ fontSize: 23, color: "white" }}>
                UBETALT FAKTURA :
                <span style={{ color: "red" }}>
                  {" "}
                  {this.props.customerunpaidInvoice &&
                    this.props.customerunpaidInvoice[0].count}{" "}
                </span>
                <br />
                Total (Kr.):{" "}
                <span style={{ color: "red" }}>
                  {this.props.customerunpaidInvoice &&
                    this.props.customerunpaidInvoice[0].total}
                </span>
              </p>
            )}
          </div> */}
        </Card>
      </div>
    );
  }

  navigate = (route) => {
    const { dispatch } = this.props;
    // dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps)(RightMenu));
