import React, { useState, useEffect } from "react";
import { ic_restaurant_menu } from "react-icons-kit/md/ic_restaurant_menu";
import { Icon as RIcon } from "react-icons-kit";
import productsUnits from "../../helpers/productUnits";
import appConfig from "../../helpers/appConfig";
import { errorFunc, validateFunc } from "./Functions";
import { Input, Button, Modal, Row, Col, Select, Divider } from "antd";
const { TextArea } = Input;

const Option = Select.Option;
const Ingredents = ({ record, stockCats, saveIngredent }) => {
  let [fields, setFields] = useState([
    {
      stock: null,
      quantity: null,
      qtyUnit: null,
      stockproduct: null
    }
  ]);
  let [isVisible, setIsVisible] = useState(false);
  let [ingredents, setIngredents] = useState([[{ base_unit: null }]]);
  let [errors, setErrors] = useState([errorFunc()]);
  let [productsUnit, setProductsUnit] = useState([]);
  let [isCallProduct, setIsCallProduct] = useState(true);
  let [isUpdateList, setIsUpdateList] = useState(true);
  let [ingredentsDesc, setIngredentsDesc] = useState("");
  const handleRemove = (i) => {
    const values = [...fields];
    const errorArr = [...errors];
    const ingredentArr = [...ingredents];
    values.splice(i, 1);
    errorArr.splice(i, 1);
    ingredentArr.splice(i, 1);
    setFieldInputs(values, errorArr, ingredentArr);
  };
  const setFieldInputs = (values, errors, ingredents) => {
    console.log("------------data",{
      values,errors,ingredents
    })
    setFields(values);
    setErrors(errors);
    setIngredents(ingredents);
  };
  const handleAdd = () => {
    const values = [...fields];
    const errorArr = [...errors];
    const ingredentArr = [...ingredents];
    values.push({
      stock: null,
      quantity: null,
      qtyUnit: null,
      stockproduct: null
    });
    errorArr.push(errorFunc());
    ingredentArr.push([{ base_unit: null }]);
    setFieldInputs(values, errorArr, ingredentArr);
  };
  const buttonRender = (idx) => {
    if (idx === 0) {
      return (
        <> 
       <div className="d-flex">
        <Button type="primary" className="btn-ab text-color"  onClick={() => handleAdd()} ghost>
          +
        </Button>
       <Button
       type="danger"
       onClick={() => handleRemove(idx)}
       ghost
     >
       X
     </Button>
       </div>
     </>
      );
    } else {
      return (
        <Button
          type="button"
          type="danger"
          onClick={() => handleRemove(idx)}
          ghost
        >
          X
        </Button>
      );
    }
  };
  useEffect(() => {
    if (
      isVisible &&
      isUpdateList &&
      fields.length < 2 &&
      record.ingredents.length > 0
    ) {
      let errorsArr = [];
      let ingredentsArr = [];
      let ingredentsDesc = record.ingredentsDesc;
      for (let i = 0; i < record.ingredents.length; i++) {
        errorsArr.push(...errors);
        ingredentsArr.push(...ingredents);
      }
      setFields(record.ingredents);
      setErrors(errorsArr);
      setIngredents(ingredentsArr);
      setIngredentsDesc(ingredentsDesc);
      setIsUpdateList(false);
    }
  }, [isVisible]);
  const openModel = () => {
    setIsVisible(true);
  };
  const modelClose = (e) => {
    const fields = [
      { stock: null, quantity: null, qtyUnit: null, stockproduct: null }
    ];
    const ingredents = [[{ base_unit: null }]];
    setIngredentsDesc("");
    setFields(fields);
    setIngredents(ingredents);
    setIsCallProduct(true);
    setIsUpdateList(true);
    setIsVisible(false);
  };

  const updateIngredents = (id) => {
    const res = validateFunc({ fields, errors });
    setErrors(res.allErroes);
    if (res.isError == 0) {
      const data = {
        ingredents: fields,
        ingredentsDesc,
        id
      };
      saveIngredent(data).then(() => modelClose());
    }
    if(res.isError == 4){
      const data = {
        // ingredents: fields?fields:'123',
        ingredentsDesc,
        id
      };
      saveIngredent(data).then(() => modelClose());

    }
  };

  const handleStockChange = (event) => {
    handleChange(event);
    const proUnit = [...productsUnit];
    proUnit[event.idx] =
      event.e.item.props &&
      event.e.item.props.qtyunittype &&
      productsUnits[event.e.item.props.qtyunittype]
        ? productsUnits[event.e.item.props.qtyunittype]
        : productsUnits["all"];
    setProductsUnit(proUnit);
  };
  const handleChange = (event) => {
    const values = [...fields];
    const errorArr = [...errors];
    const ingredentArr = [...ingredents];
    let name = event.e.item ? event.e.item.props.name : event.e.target.name;
    let value = event.e.item ? event.e.item.props.value : event.e.target.value;
    values[event.idx][name] = value;
    setFieldInputs(values, errorArr, ingredentArr);
  };
  const cancel = () => {
    setIsVisible(false);
  };
  const handleProductChange = (event) => {
    let hdr = localStorage.token;
    const id = event.e.key;
    fetch(`${appConfig.endpoint}/api/v1/employee/products/ingredent/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        const ingre = [...ingredents];
        ingre[event.idx] = result.data ? [...result.data] : [];
        const values = [...fields];
        const proUnit = [...productsUnit];
        if (event.e.item) {
          let name = event.e.item
            ? event.e.item.props.name
            : event.e.target.name;
          let value = event.e.item
            ? event.e.item.props.value
            : event.e.target.value;
          values[event.idx][name] = value;
        }
        let unit = result.data
          ? getQtyUnits(values[event.idx], result.data)
          : [];
        proUnit[event.idx] =
          unit && productsUnits[unit.base_unit]
            ? productsUnits[unit.base_unit]
            : productsUnits["all"];
        setFields(values);
        setIngredents(ingre);
        setProductsUnit(proUnit);
      });
  };
  useEffect(() => {
    if (isCallProduct &&fields.length&& fields[0].stock !== null) {
      setIsCallProduct(false);
      for (let [idx, props] of fields.entries()) {
        if (props.stock !== null) {
          let e = { key: props.stock };
          handleProductChange({ e, idx });
        }
      }
    }
  }, [fields]);

  const getQtyUnits = (val, res) => {
    return res.find((data) => data._id === val.stockproduct);
  };
  const IngredentModel = () => {
    let branchType=localStorage.getItem("branchType")
    // branchType=""
    return (
      <>
        <Modal
          width="800px"
          title={ branchType=="rest"? `Enter Ingredents for ${record.name}`:`${record.name}`}
          onCancel={cancel}
          visible={isVisible}
          afterClose={(e) => modelClose(e)}
          footer={[
            <Button key="back" className="btn-ab text-color" onClick={cancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="btn-ab text-color"
              onClick={() => updateIngredents(record._id)}
            >
              Save
            </Button>
          ]}
        >
{branchType=="rest"? <> 
          <Row key="key1">
            <Col span={6} style={{ marginRight: "10px" }}>
              <b>Produkt</b>
            </Col>
            <Col span={6} style={{ marginRight: "10px" }}>
              <b>Ingredienser</b>
            </Col>
            <Col span={4} style={{ marginRight: "10px" }}>
              <b>Antall</b>
            </Col>
            <Col span={4} style={{ marginRight: "10px" }}>
              <b>Enhet</b>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Divider style={{ marginBottom: "10px" }} />

          {fields &&fields.length>0&&
            fields.map((field, idx) => {
              return (
                <Row key={`${field}-${idx}`}>
                  <Col
                    span={6}
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Plukke ut"
                      showSearch
                      value={fields[idx].stock}
                    >
                      {/* {console.log("stockcats",stockCats)} */}
                      {stockCats &&
                        stockCats.length > 0 &&
                        stockCats.map((product) => (
                          <Option
                            value={product._id}
                            name="stock"
                            label={product.name}
                            key={product._id}
                            onClick={(e) => handleProductChange({ e, idx })}
                          >
                            {product.name}
                          </Option>
                        ))}
                    </Select>
                    {errors.length&& errors[idx].stock.isValidationFail ? (
                      <small>{errors[idx].stock.message}</small>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    span={6}
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Plukke ut"
                      showSearch
                      value={fields.length>0?fields[idx].stockproduct:""}
                    >
                      {ingredents.length>0 &&
                        ingredents[idx] &&
                        ingredents[idx].length > 0 &&
                        ingredents[idx].map((product) => (
                          <Option
                            name="stockproduct"
                            value={product._id}
                            qtyunittype={product.base_unit}
                            label={product.name}
                            key={`${product._id}-${fields[idx].stockproduct}`}
                            onClick={(e) => handleStockChange({ e, idx })}
                          >
                            {product.name}
                          </Option>
                        ))}
                    </Select>
                    {errors.length>0 &&errors[idx].stockproduct.isValidationFail ? (
                      <small>{errors[idx].stockproduct.message}</small>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    span={4}
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                  >
                    <Input
                      name="quantity"
                      type="number"
                      onChange={(e) => handleChange({ e, idx })}
                      value={fields[idx].quantity}
                      placeholder="Antall"
                      required
                    />
                    {errors.length>0&&errors[idx].quantity.isValidationFail ? (
                      <small>{errors[idx].quantity.message}</small>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    span={4}
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Plukke ut"
                      value={fields[idx].qtyUnit}
                    >
                      {productsUnit &&
                        productsUnit[idx] &&
                        productsUnit[idx].length > 0 &&
                        productsUnit[idx].map((product) => (
                          <Option
                            value={product.key}
                            name="qtyUnit"
                            label={product.name}
                            key={product.value}
                            onClick={(e) => handleChange({ e, idx })}
                          >
                            {product.value}
                          </Option>
                        ))}
                    </Select>
                    {errors.length>0&& errors[idx].qtyUnit.isValidationFail ? (
                      <small>{errors[idx].qtyUnit.message}</small>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={2}>{buttonRender(idx)}</Col>
                </Row>
              );
            })}
            </>:""}
          <Divider style={{ marginBottom: "10px" }} />
          <Col span={21} style={{ marginRight: "10px", marginBottom: "5px" }}>
            <TextArea
              rows={2}
              value={ingredentsDesc}
              maxLength={300}
              onChange={(e) => setIngredentsDesc(e.target.value)}
              placeholder="Produkt Beskrivelse"
            />
          </Col>
          <div className="clearfix"></div>
        </Modal>
      </>
    );
  };
  return (
    <>
      <Button title="Edit Ingredienser" type="link" onClick={openModel}>
        <RIcon icon={ic_restaurant_menu} size={18} />
      </Button>
      {IngredentModel()}
    </>
  );
};

export default Ingredents;
