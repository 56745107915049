import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCashReport,
  getDailyReportPrint,
} from "./../../../action/ReportAction";

import { setDefaultDates } from "../../../helpers/commonDates";
import Home from "./../../Home";
import List from "./List";
import moment from "moment";

const CashReport = (props) => {
  let [isLoading, setIsLoading] = useState(false);
  let [date, setDate] = useState(moment().date()._d);
  // let[data,setData]= useState([])
  useEffect(() => {
    const fetchCashReport = async (startDate, endDate) => {
      const dayDate = {
        startDate: startDate,
        endDate: endDate,
      };
      setIsLoading(true);
      await props.getCashReport(dayDate).then(() => {
        setIsLoading(false);
      });
    };
    const { tSDay, tEDay } = setDefaultDates(date);
    if (date != null) fetchCashReport(tSDay, tEDay);
  }, [date]);

  const printReportHandler = async () => {
    const { tSDay, tEDay } = await setDefaultDates(date);
    const data = {
      startDate: tSDay,
      endDate: tEDay,
      title: "CASH REPORTS",
      type: "C",
    };
    setIsLoading(true);
    await props.getDailyReportPrint(data).then(() => setIsLoading(false));
  };
  const goBackMainPage = () => {
    props.history.push("/rapporter");
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  return (
    <>
      <Home>
        <List
          printReportHandler={printReportHandler}
          goBackMainPage={goBackMainPage}
          handleDateChange={handleDateChange}
          cashReport={props.cashReport}
          isLoading={isLoading}
        />
      </Home>
    </>
  );
};

const mapStateToProps = ({ cashReport }) => {
  return { cashReport };
};

export default connect(mapStateToProps, { getCashReport, getDailyReportPrint })(
  CashReport
);
