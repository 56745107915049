import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { sendNotification } from "../../helpers/commFuc";
const ChangePassword = ({
  show,
  handleClose,
  setLoading,
  loading,
  changePassword
}) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    setPassword("")
    setNewPassword("")
    setConfirmPassword("")
  },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return true;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      if (!newPassword.localeCompare(confirmPassword)) {
        const bid = localStorage.getItem("bid");
        setLoading(true);
        changePassword({ password: password, newPassword: newPassword }).then(
          () => {
            handleClose();
            setLoading(false);
          }
        );
      } else {
        sendNotification("error", "Bekreft at passord ikke stemmer");
      }
    }
    setValidated(true);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bytt passord</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    placeholder="Passord"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="password"
                    name="newPassword"
                    placeholder="Nytt Passord"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="password"
                    name="newPassword"
                    placeholder="Bekreft passord"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3 text-center ">
                  <Button
                    className="btn-ab"
                    style={{ width: "100%" }}
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ChangePassword;
