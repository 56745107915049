import React, { Component } from "react";
import { Button, Row, Col } from "antd";
import "./FloatingButtons.css";
import { push } from "react-router-redux";
import PropTypes from "prop-types";
import companyLogo from "../../assets/Aryan-Holding-Group-Logo-Square.png";
import appConfig from "../../helpers/appConfig";
import history from "../../history";
export default class FloatingButtons extends Component {
  static propTypes = {
    isBilling: PropTypes.bool
  };

  constructor() {
    super();
    this.state = {
      isActive: false,
      web: "",
      bid: "",
      slug: "",
      openaccount: localStorage.getItem("openaccount"),
      tableview: localStorage.getItem("tableview")
    };
  }
  componentDidMount() {
    var bid = localStorage.getItem("bid");
    var slug = localStorage.getItem("slug");
    this.setState({ bid: bid, slug: slug });
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };
  checkwebsite() {
    const website = localStorage.getItem("website");
    if (website) {
      let str = website;
      let tt = str.split(":");
      if (tt[0] == "http") {
        this.setState({ web: tt[1] });
      } else {
        var ww = "http://" + tt[0];
        this.setState({ web: ww });
      }
    } else {
      this.setState({ web: "" });
    }
  }

  render() {
    const website = localStorage.getItem("website");
    return (
      <Row gutter={12}>
        <div
          className="scrollbar"
          id={this.props.isBilling ? "container-billing" : "container"}
        >
          <Col>
            <div className="button-style1">
              <Button
                onClick={() => {
                  this.navigate("/admin");
                }}
                className="main-button button-size"
                shape="circle"
              >
                <img src={companyLogo} className="company-logo" />
              </Button>
            </div>
          </Col>
          {Number(this.state.openaccount) !== 0 ? (
            <Col>
              <div className="button-style1">
                <Button
                  className="open-ac-button  button-size"
                  onClick={() => {
                    this.navigate("/OpprettKonto");
                  }}
                  shape="circle"
                >
                  <div className="button-text-style">
                    <span>Opprett konto</span>
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            ""
          )}

          {Number(this.state.tableview) !== 0 ? (
            <Col>
              <div className="button-style1">
                <Button
                  onClick={() => {
                    this.navigate("/table");
                  }}
                  className="table-view-button button-size"
                  shape="circle"
                >
                  <div className="button-text-style">
                    {/* <span>Table View </span> */}
                    <span>Bord</span>
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            ""
          )}

          <Col>
            <div className="button-style1">
              <Button className="tfl-button  button-size" shape="circle">
                <div className="button-text-style">
                  <span>TFL</span>
                </div>
              </Button>
            </div>
          </Col>
          <Col>
            <div className="button-style1">
              <a
                href={`${appConfig.publicURL}/${this.state.slug}`}
                target="_blank"
              >
                <Button className="web-button  button-size" shape="circle">
                  <div className="button-text-style">
                    <span style={{ color: "white" }}>Web</span>
                  </div>
                </Button>
              </a>
            </div>
          </Col>
        </div>
      </Row>
    );
  }
  navigate = (route) => {
    history.push(route);
  };
}
