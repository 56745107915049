import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { employeesTipReport } from "./../../../action/arkiveActions";
import Home from "./../../Home";
import List from "./List";
import { setDefaultDates, setMonthDates } from "../../../helpers/commonDates";
import { POSTAPI } from "../../Vendor/AllApi";
import appConfig from "../../../helpers/appConfig";
import { Card } from "react-bootstrap";

const TipReport = (props) => {
  let [date, setDate] = useState(moment().date()._d);
  let [month, setMonth] = useState(moment().date()._d);
  let [isLoading, setIsLoading] = useState(false);
  let [dayType, setDayType] = useState("");
  let [employeeTipData, setEmployeeTipData] = useState([]);


  let [allTipsData, setAllTipsData] = useState([]);


  let data ;

  useEffect(() => {
    const fetchEmployeesTipReport = async (tSDay, tEDay) => {
      data = {
        startDate: tSDay,
        endDate: tEDay
      };

      await props.employeesTipReport(data).then(() => {
        setIsLoading(false);
      });
    };


    
    setIsLoading(true);
    if (dayType === "month") {
      const { tSDay, tEDay } = setMonthDates(month);
      fetchEmployeesTipReport(tSDay, tEDay);
    
      getAllTips({startDate: tSDay,endDate: tEDay})
    
    } else {
      const { tSDay, tEDay } = setDefaultDates(date);
      fetchEmployeesTipReport(tSDay, tEDay);
    
      getAllTips({startDate: tSDay,endDate: tEDay})
    }
  }, [date, month]);


  // get all tips data
  const getAllTips = async(data)=>{
    console.log('---all tips api running----')
    try {
      let resData = await POSTAPI(`${appConfig.endpoint}/api/v1/employee/arkiv/all-tips`,
          data
      )
      // console.log("====TIPS DATA===", resData.data)
      setAllTipsData(resData.data)
      setIsLoading(false);

  } catch (error) {
      console.log(error)
  }

  }


  const handleDateChange = (date, type) => {
    setDate(date);
    setMonth(moment().date()._d);
    setDayType(type);
  };
  const handleMonthChange = (month, type) => {
    setDate(moment().date()._d);
    setMonth(month);
    setDayType(type);
  };

  // console.log("allTipsData======", allTipsData)

  return (
    <>
      <Home>
        <div className="report-container">
          <span className="item ">
            <span>Arkiv/ </span> Tip Rapport
            <NavLink to="/arkiv" className="btn back-btn" style={{marginLeft:"620px", marginTop:"8px"}}>
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </NavLink>
          </span>
        </div>
        <List
          dataSource={props.employeesTip }
          allTipsData={allTipsData}
          isLoading = {isLoading}
          handleDateChange={handleDateChange}
          handleMonthChange={handleMonthChange}
        />
      </Home>
    </>
  );
};

const mapStateToProps = ({ employeesTip }) => {
  return { employeesTip };
};

export default connect(mapStateToProps, { employeesTipReport })(TipReport);
