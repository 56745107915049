import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Home from "../Home";
import { NavLink } from "react-router-dom";
import { getStcokHstory } from "../../action/lagerAction";
import StockHistory from "./StockHistory";
const UtList = (props) => {
  const [isLoaded, setIsLoading] = useState(false);
  const [paginationNo, setPaginationNo] = useState(1);
  useEffect(() => {
    const now = new Date();
    handleStockHistory([
      { _d: now.setHours(0, 0, 0) },
      { _d: now.setHours(23, 59, 59) }
    ]);
  }, []);
  const handleStockHistory = (date) => {
    const startDate = new Date(date[0]._d).setHours(0, 0, 0);
    const endDate = date[1]._d;
    const data = {
      type: "utttt",
      startDate,
      endDate
    };
    setIsLoading(true)
    props.getStcokHstory(data).then(() => setIsLoading(false));
  };
  const outColumns = [
    {
      title: "Sr. Nr.",
      editable: false,
      width: "10%",
      render: (text, record, index) => {
        return index + (paginationNo - 1) * 10 + 1;
      }
    },
    {
      title: "Tid",
      dataIndex: "created",
      key: "created",
      width: "25%"
    },
    {
      title: "Produkt Navn",
      dataIndex: "productName",
      key: "productName",
      width: "15%"
    },
    {
      title: "Antall",
      dataIndex: "quantity",
      key: "quantity",
      width: "12%"
    },
    {
      title: "Lager Inn Via",
      dataIndex: "employeeName",
      key: "emplyeeName",
      width: "18%"
    },
    {
      title: "Lager Tildelt Til ",
      dataIndex: "employeeNameTo",
      key: "emplyeeNameTo",
      width: "20%"
    }
  ];
  return (
    <Home>
      <div className="report-container">
        <span className="item ">Lager/ Ut Historie</span>
        <NavLink to="/lager" className="back-btn">
          <i className="fa fa-arrow-circle-left" aria-hidden="true" />
        </NavLink>
      </div>
      <StockHistory
        isLoaded={isLoaded}
        columns={outColumns}
        data={props.stockHistory}
        handleStockHistory={handleStockHistory}
        setPaginationNo={setPaginationNo}
      />
    </Home>
  );
};
const mapStateToProps = ({ stockHistory }) => {
  return {
    stockHistory
  };
};
export default connect(mapStateToProps, { getStcokHstory })(UtList);
