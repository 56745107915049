import React, { useEffect } from "react";
import Home from "../../../containers/Home/Home";
import { connect } from "react-redux";
import { getReportView } from "../../../action/ReportAction";
import SingleInvoice from "../../../containers/SingleInvoice/SingleInvoice";
const ViewReport = (props) => {
  useEffect(() => {
    async function fetchData() {
      await props.getReportView();
    }
    fetchData();
  }, []);
  const goBackButton = () => {
    props.setPage("list");
  };
  const singleInvoice = () => {
    if (
      props.viewReport.billData &&
      Object.keys(props.viewReport.billData).length
    ) {
      return (
        <SingleInvoice
          data={props.viewReport.billData}
          branch={props.viewReport.branch}
        />
      );
    }
  };
  return (
    <Home isAdmin={true}>
      <div className="report-container">
        <span className="item">
          <span id="less-visible">Hjem/ </span>
          Invoice No. :{" "}
          {props.viewReport ? props.viewReport.billData.invoiceNumber : ""}
        </span>
        <span className="item">
          <span id="less-visible">
            <div onClick={goBackButton} className="back-button-border">
              <i
                className="fa fa-arrow-circle-left"
                style={{ fontSize: 20, marginTop: 1 }}
                aria-hidden="true"
              />
            </div>
          </span>
        </span>
      </div>
      {singleInvoice()}
    </Home>
  );
};
const mapStateToProps = ({ viewReport }) => {
  return {
    viewReport,
  };
};
export default connect(mapStateToProps, {
  getReportView,
})(ViewReport);
