import React from "react";
import "./AdminDashboard.css";
import { Button, Col, Row, notification } from "antd";
import appConfig from "../../helpers/appConfig";
const AdminFunc = ({ data }) => {
  
  return (
    <>
      <div> 
        <Row className="m dashboard-flex" >
          {Object.keys(data).length != 0 && data.data.map((adminIcon, index) => {
            return (
              <Col className="items gutter-row" key={index}>
                <div className="button-style">
                  <Button
                    // style={{
                      // marginBottom: "3.5em",
                    // }}
                    className="drinks button-size-icon"
                    // shape="circle"
                  >
                    <div className="menu-options white d-flex flex-column justify-content-between h-100">
                      <img
                        src={`${appConfig.s3url}/${adminIcon.path}`}
                        className="product-image invert-c"
                        title={adminIcon.name}
                      />
                      <span className="menu-options name">
                        {adminIcon.name}
                      </span>
                    </div>
                    <div className="menu-options details">
                      {/* <span className="menu-options price">
                        {adminIcon.name}
                      </span> */}
                    </div>
                  </Button>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default AdminFunc;
