import { GET_FAKTURA } from "../../action/types";
const fakturaReducer = (state = [], action) => {
  switch (action.type) {
    case GET_FAKTURA:
      return action.payload.data;
    default:
      return state;
  }
};
export default fakturaReducer;
