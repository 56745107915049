import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Home from "../Home/Home";
import { Button, Col, Row, notification } from "antd";
import PropTypes, { element } from "prop-types";
import appConfig from "../../helpers/appConfig";
import Loader from "../Loader/Loader";
import { Avatar, Badge } from "antd";

class TablesComponent extends Component {
  static propTypes = {
    isBilling: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      activeTable: [],
      noTable: [],
      driveOut: [],
      takeAway: []
    };
  }

  showTable = (id) => {
    const empType = localStorage.getItem("empType");
    if (empType === "MANAGER") {
      localStorage.setItem("table", id);
      this.props.history.push("/billing");
    }
  };

  componentWillMount() {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/table`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              activeTable: result.data.activeTable,
              noTable: result.data.noTable,
              driveOut: result.data.driveOut,
              takeAway: result.data.takeAway
            });
            // console.log(this.state);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    const { error, isLoaded, activeTable, driveOut, takeAway, noTable } =
      this.state;

    if (!isLoaded) {
      return (
        <Home>
          <Loader />
        </Home>
      );
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else if (isLoaded) {
      return (
        <Home>
          <div
            className={
              this.props.isBilling
                ? "dashboard-billing"
                : "dashboard-main-wrapper"
            }
          >
            <Row className="dashboard-flex">
              {activeTable.length > 0 ? (
                <h3 style={{ width: "100%" }}>Active Tables</h3>
              ) : (
                ""
              )}
              {activeTable.length > 0
                ? activeTable.map((item, index) => {
                    return (
                      <Fragment>
                        <Col
                          onClick={() => {
                            this.showTable(item._id);
                          }}
                          key={index}
                          className="items gutter-row"
                        >
                          <div className="button-style">
                            <Button
                              className="drinks button-size-icon"
                              shape="circle"
                            >
                              <span style={{ marginRight: 30 }}>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "KITCHEN"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <span>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "BAR"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <div className="menu-options white">
                                {item.orderType === "PLU1" &&
                                item.number > 0 ? (
                                  <span>
                                    Table <b>{item.number}</b>
                                  </span>
                                ) : (
                                  <span>
                                    {item.orderType} <b>{item.number}</b>
                                  </span>
                                )}
                              </div>
                              <br />
                              <b>{item.employeeId.firstName}</b>
                            </Button>
                          </div>
                        </Col>
                      </Fragment>
                    );
                  })
                : ""}
              {takeAway.length > 0 ? (
                <h3 style={{ width: "100%" }}>Take Aways</h3>
              ) : (
                ""
              )}
              {takeAway.length > 0
                ? takeAway.map((item, index) => {
                    return (
                      <Fragment>
                        <Col
                          onClick={() => {
                            this.showTable(item._id);
                          }}
                          key={index}
                          className="items gutter-row"
                        >
                          <div className="button-style">
                            <Button
                              className="drinks button-size-icon"
                              shape="circle"
                            >
                              <span style={{ marginRight: 30 }}>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "KITCHEN"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <span>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "BAR"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <div className="menu-options white">
                                {item.orderType === "PLU1" &&
                                item.number > 0 ? (
                                  <span>
                                    Table <b>{item.number}</b>
                                  </span>
                                ) : (
                                  <span>{item.orderType}</span>
                                )}
                              </div>
                              <br />
                              <b>{item.employeeId.firstName}</b>
                            </Button>
                          </div>
                        </Col>
                      </Fragment>
                    );
                  })
                : ""}

              {driveOut.length > 0 ? (
                <h3 style={{ width: "100%" }}>Drive Outs</h3>
              ) : (
                ""
              )}
              {driveOut.length > 0
                ? driveOut.map((item, index) => {
                    return (
                      <Fragment>
                        <Col
                          onClick={() => {
                            this.showTable(item._id);
                          }}
                          key={index}
                          className="items gutter-row"
                        >
                          <div className="button-style">
                            <Button
                              className="drinks button-size-icon"
                              shape="circle"
                            >
                              <span style={{ marginRight: 30 }}>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "KITCHEN"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <span>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "BAR"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>

                              <div className="menu-options white">
                                {item.orderType === "PLU1" &&
                                item.number > 0 ? (
                                  <span>
                                    Table <b>{item.number}</b>
                                  </span>
                                ) : (
                                  <span>{item.orderType}</span>
                                )}
                              </div>
                              <br />
                              <b>{item.employeeId.firstName}</b>
                            </Button>
                          </div>
                        </Col>
                      </Fragment>
                    );
                  })
                : ""}

              {noTable.length > 0 ? (
                <h3 style={{ width: "100%" }}>No Table</h3>
              ) : (
                ""
              )}
              {noTable.length > 0
                ? noTable.map((item, index) => {
                    return (
                      <Fragment>
                        <Col
                          onClick={() => {
                            this.showTable(item._id);
                          }}
                          key={index}
                          className="items gutter-row"
                        >
                          <div
                            className="button-style"
                          >
                            <Button
                              className="drinks button-size-icon"
                              shape="circle"
                            >
                              <span style={{ marginRight: 30 }}>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "KITCHEN"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge style={{ boxShadow: 10 }}>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        K
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <span>
                                {item.items.filter(
                                  (x) =>
                                    x.status !== "PREPARED" &&
                                    x.sCatType == "BAR"
                                ).length > 0 ? (
                                  <Badge dot>
                                    <Avatar
                                      style={{
                                        color: "#ff7675",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                ) : (
                                  <Badge>
                                    <Avatar
                                      style={{
                                        color: "#55efc4",
                                        background: "#636e72"
                                      }}
                                      shape="square"
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16
                                        }}
                                      >
                                        B
                                      </p>
                                    </Avatar>
                                  </Badge>
                                )}
                              </span>
                              <div className="menu-options white">
                                {item.orderType === "PLU1" &&
                                item.number > 0 ? (
                                  <span>
                                    Table <b>{item.number}</b>
                                  </span>
                                ) : (
                                  <span>{item.orderType}</span>
                                )}
                              </div>
                              <br />
                              <b>{item.employeeId.firstName}</b>
                            </Button>
                          </div>
                        </Col>
                      </Fragment>
                    );
                  })
                : ""}
            </Row>
          </div>
        </Home>
      );
    } else {
      return <Home />;
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const Tables = connect(mapStateToProps)(TablesComponent);
export default Tables;
