import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form
} from "react-bootstrap";
const EditModel = ({ show, handleClose, details, updateInfo ,setLoading,loading}) => {
  const [inputs, setInputs] = useState({});
  const [profile, setProfile] = useState("");
  const [picInfo, setPicInfo] = useState("");
  const [validated, setValidated] = useState(false);
 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if(loading){
      return true
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      const bid = localStorage.getItem("bid");
      const formData = new FormData();
      for (var key in inputs) {
        formData.append(key, inputs[key]);
      }
      if (picInfo) formData.append("info", picInfo, picInfo.name);
      if (profile) formData.append("profileImage", profile, profile.name);
      setLoading(true)
      updateInfo(formData);
    }
    setValidated(true);
  };
  useEffect(() => {
    if (details) {
      let dValue = {
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        phoneNo: details.phoneNo,
        line1: details.address ? details.address.line1 : "",
        line2: details.address ? details.address.line2 : "",
        city: details.address ? details.address.city : "",
        state: details.address ? details.address.state : "",
        pin: details.address ? details.address.pin : "",
        country: details.address ? details.address.country : ""
      };
      setInputs(dValue);
    }
  }, [details]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Rediger profil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    name="firstName"
                    pattern="[A-Za-z]+"
                    maxLength="20"
                    placeholder="Fornavn"
                    value={inputs.firstName || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    name="lastName"
                    pattern="[A-Za-z]+"
                    maxLength="20"
                    placeholder="Etternavn"
                    value={inputs.lastName || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="number"
                    name="phoneNo"
                    placeholder="Tel. Nr."
                    min="0000000000"
                    max="9999999999"
                    value={inputs.phoneNo || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-center h5">Address</Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    name="line1"
                    placeholder="Addresse Line 1"
                    value={inputs.line1 || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="line2"
                    placeholder="Addresse Line 2"
                    value={inputs.line2 || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="By"
                    value={inputs.city || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    name="pin"
                    placeholder="Pin"
                    value={inputs.pin || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="state"
                    value={inputs.state || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Land"
                    value={inputs.country || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label style={{ color: "#000" }}>
                    Velg Profil Bilde
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setProfile(e.target.files[0])}
                    accept="image/*"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label style={{ color: "#000" }}>
                    Velg Dokumenter
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPicInfo(e.target.files[0])}
                    accept="image/*,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3 text-center ">
                  <Button
                    className="btn-ab"
                    style={{ width: "100%" }}
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditModel;
