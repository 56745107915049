import Axios from "axios";

const GETAllAPI = async (url, type = "") => {
  // let fullData = [];
  // console.log(`      api${type}`);
  // Axios.get(uri)
  //   .then((res) => {
  //     console.log("response in allapi", res);
  //     setState(fullData);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     setState([]);
  //   });

  try {
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    console.log("Something Went Wrong on Post Api", error);
  }
};

const POSTAPI = async (url, data = {}) => {
  try {
    let res = await Axios.post(url, data);
    return res.data;
  } catch (error) {
    console.log("Something Went Wrong on Post Api", error);
  }
};

// const GETONEAPI = (uri, setState = () => {}) => {
//   Axios.get(uri, { headers: { token: localStorage.token } })
//     .then((res) => {
//       setState(res.data.data);
//       console.log("userdata order erss", res);
//     })
//     .catch((err) => {
//       console.log(err);
//       setState([]);
//     });
// };

// const UPDATEAPI = (
//   url,
//   data = {},
//   toast = () => {},
//   responseData = () => {}
// ) => {
//   Axios.put(url, data, { headers: { token: localStorage.token } })
//     .then((response) => {
//       responseData(response.data);
//       toast({
//         open: true,
//         severity: response?.data?.success ? "success" : "error",
//         message: (response?.data?.message ?? "No Message")
//           .split(" ")
//           .map((d) => d.charAt(0).toUpperCase() + d.slice(1))
//           .join(" ")
//       });
//     })
//     .catch((error) => {
//       console.log("Something Went Wrong on Post Api", error);
//     });
// };

// const DELETEAPI = (uri, data = {}, recallAPI = () => {}) => {
//   Axios.delete(uri, data, { headers: { token: localStorage.token } })
//     .then((res) => {
//       if (res.data.success) recallAPI();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// const RETURN_POSTAPI = (url, data = {}) => {
//   return Axios.post(url, data, { headers: { token: localStorage.token } });
// };

export { GETAllAPI, POSTAPI };
