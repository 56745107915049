import { PRODUCT_STOCKS } from "../../action/types";

const stockProductReducer = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_STOCKS:
      return action.payload.data;
    default:
      return state;
  }
};
export default stockProductReducer;
