import moment from "moment";
// const setDefaultDates = (date) => {
//   const tSDay = moment(date).set({
//     hour: 4,
//     minute: 0,
//     second: 0
//   })._d;
//   const tEDay = moment(date).add(1, "days").set({
//     hour: 3,
//     minute: 59,
//     second: 59
//   })._d;
//   return { tSDay, tEDay };
// };

// const setMonthDates = (date) => {
//   const tSDay = moment(date).startOf("month").set({
//     hour: 4,
//     minute: 0,
//     second: 0,
//   })._d;
//   const tEDay = moment(date).endOf("month").add(1, "days").set({
//     hour: 3,
//     minute: 59,
//     second: 59,
//   })._d;
//   return { tSDay, tEDay };
// };


const setDefaultDates = (date) => {
  const tSDay = moment(date)
  const tEDay = moment(date).add(1, "days")
  return { tSDay, tEDay };
};

const setMonthDates = (date) => {
  const tSDay = moment(date).startOf("month").add(1, "days")
  const tEDay = moment(date).endOf("month").add(1, "days")
  return { tSDay, tEDay };
};
export { setDefaultDates, setMonthDates };
