import React from "react";
import { connect } from "react-redux";
import "./singleInvoice.css";
import { Card, Table, Button, Avatar, Tag, Popconfirm } from "antd";
import { Row, Col, Container, Image, Badge } from "react-bootstrap";
import appConfig from "../../../helpers/appConfig";
import { markInvoicePaid } from "../../../action/arkiveActions";
import { invoiceColumn } from "./../../Table/TableColumns";
import { bottom } from "react-icons-kit/iconic";

const { Meta } = Card;
const SingleInvoice = (props) => {
  const markPaid = (id) => {
    props.markInvoicePaid(id);
  };
  const printbill = () => {
    window.print();
  };

  const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
  const branchLogo = localStorage.getItem("logo");
  const { data } = props;
  const { customerId, isPaid, _id } = props.data;

  const { name, address, taxNo, phoneNo, bEmail, accountNumber } = branchInfo;
  const table = data.items;
  let tableData = table.map((item, index) => {
    let ext = item.extraPrice ? item.extraPrice : 0;
    return {
      key: index + 1,
      no: index + 1,
      name: item.productName,
      quantity: item.quantity,
      price: `Kr.${item.productPrice}`,
      extraPrice: `Kr.${ext}`,
      amount: `Kr.${Number(item.quantity * item.productPrice + ext)}`
    };
  });
  return (
    <>
      <Container id="singleinvoice" fluid className="justifyContent card">
        <Row className="mb-2 h4 pt-3 ">
          <Col md={3}>
            <Avatar
              src={
                branchLogo === null ? "" : `${appConfig.s3url}/${branchLogo}`
              }
              size="large"
            />
          </Col>
          <Col md={4} align="center">
            <h3>{name}</h3>
          </Col>
          <Col md={5} align="right">
            <div id="printbtn">
              <Button
                onClick={() => printbill()}
                type="primary"
                size="large"
                className="btn-ab text-color"
              >
                Skrive ut
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col align="left" className="row-font">
            {address && (
              <>
                <p style={{marginBottom:4 }}>
                  {address.line1 ? address.line1 : ""}
                  {address.line2 ? " " + address.line2 : ""}
                </p>
                <p style={{marginBottom:4 }}>
                  {address.city ? address.city : ""}
                  {address.pin ? "," + " " + address.pin : ""}
                  {address.state ? "," + " " + address.state : ""}
                </p>
                <p style={{marginBottom:4 }}>
                  <b>Mva Nr.</b>: {taxNo}
                </p>
                {!isPaid ? (
                  <p style={{marginBottom:4 }}>
                    <b>Regnskap Nr. </b>: {accountNumber}
                  </p>
                ) : null}
                <p style={{marginBottom:4 }}>
                  <b>Telefon Nr.</b>: {phoneNo}
                </p>
                <p style={{marginBottom:4 }}>
                  <b>E-post</b>: {bEmail}
                </p>
              </>
            )}
          </Col>
          <Col align="right" className="row-font">
            <p style={{marginBottom:4}}>
              <b>Faktura Nr.</b>: {data.invoiceNumber}
            </p>
            <p style={{marginBottom:4 }}>
              <b>Dato</b>: {data.createdDate}
            </p>
            <p style={{marginBottom:4 }}>
              <b>Tid</b>: {data.createdTime}
            </p>
            <p style={{marginBottom:4 }}>
              <b>Bestillingstype</b>: {data.orderType}
            </p>
          </Col>
        </Row>
        <Row className="mb-2 h4 pt-3">
          <Col>
            {customerId ? (
              <h3>Kundedetaljer </h3>
            ) : (
              <h3>No Customer Details</h3>
            )}
          </Col>
        </Row>
        <Row className="justify-content-evenly">
          <Col>
            {customerId && (
              <>
                {customerId.address.line1 ? (
                  <>
                    <h5>Customer First Name:{customerId.firstName}</h5>
                    <h5>Customer Last Name:{customerId.lastName}</h5>
                    <h5>
                      Customer Address City:
                      {customerId.address.city ? customerId.address.city : ""}
                    </h5>
                    <h5>
                      Customer Address Pin:
                      {customerId.address.pin ? customerId.address.pin : ""}
                    </h5>
                    <h5>
                      Customer Address State:
                      {customerId.address.state ? customerId.address.state : ""}
                    </h5>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Col>
          <Col className="me-5" align="right">
            {customerId && (
              <>
              <h5>Telefon: {customerId.phoneNo}</h5>
              <h5>e-post: {customerId.phoneNo}</h5>
              <h5>Referanse: {customerId.phoneNo}</h5>
              </>
            )}
          </Col>
        </Row>
        <Table
          className="row-font"
          id="producttab"
          dataSource={tableData}
          columns={invoiceColumn}
          pagination={false}
        />
        <Row className="mb-2 row-font">
          <Col>
            <p style={{marginBottom:4 }}>
              <b>
                Sub-Totalt:{" "}
                {`Kr.${(
                  data.taxPrice -
                  (
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                  ).toFixed(3)
                ).toFixed(2)}`}
              </b>
            </p>
            <p style={{marginBottom:4 }}>
              <b>
                Mva({`${data.tax.toFixed(2)}%`}):
                {`Kr.${(
                  data.taxPrice -
                  (100 * data.taxPrice) / (100 + data.tax)
                ).toFixed(2)}`}
              </b>
            </p>
            <p style={{marginBottom:4 }}>
              <b>
                {" "}
                Rabatt (
                {data.discountType === "percent" ? `${data.discount}%` : "0%"})
                :
                {`- Kr.${(
                  (100 * Number(data.taxPrice)) / (100 - data.discount) -
                  data.taxPrice
                ).toFixed(2)}`}
              </b>
            </p>
            {data.flatDiscount && data.flatDiscount !== 0 ? (
              <p style={{marginBottom:4 }}>
                <b>
                  Flat Rabatt (kr.{data.flatDiscount}) :
                  {`-
                    Kr.${Number(data.flatDiscount).toFixed(3)}`}
                </b>
              </p>
            ) : null}
            {data.orderType == "Drive Out" ? (
              <p style={{marginBottom:4 }}>
                <b>
                  Drive Out Tax:
                  {`+ Kr.${(
                    data.driveoutCharge +
                    data.extraDriveOut -
                    (100 * (data.driveoutCharge + data.extraDriveOut)) / 125
                  ).toFixed(2)}`}
                </b>
              </p>
            ) : (
              <p style={{marginBottom:4 }}>
                <b>Totalt:{`Kr.${parseFloat(data.taxPrice).toFixed(2)}`}</b>
              </p>
            )}
            <p style={{marginBottom:4 }}>
              <b>
                CASH:
                {
                  <Tag
                    color={data.cash !== 0 ? "blue" : "grey"}
                  >{`Kr.${parseFloat(data.cash).toFixed(2)}`}</Tag>
                }
              </b>
            </p>
            <p style={{marginBottom:4 }}>
              <b>
                BANK:
                {
                  <Tag
                    color={data.cash !== 0 ? "#87d068" : "grey"}
                  >{`Kr.${parseFloat(data.cash).toFixed(2)}`}</Tag>
                }
              </b>
            </p>
          </Col>
          {data.transaction && data.transaction.card ? (
            <>
              <Col align="right" className="row-font">
                <Row align="text-center">
                  <Col>
                    <h2>Card Details</h2>
                  </Col>
                </Row>
                <p style={{marginBottom:4 }}>
                  <b>Card:{data.transaction.card.masked_pan}</b>
                </p>
                <p style={{marginBottom:4 }}>
                  <b>Card Type:{data.transaction.card.brand}</b>
                </p>
                <p style={{marginBottom:4 }}>
                  <b>Expiry Date:{data.transaction.card.expiry_date}</b>
                </p>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="text-center">
          <Col>
            {isPaid ? (
              ""
            ) : (
              <>
                <Popconfirm
                  title="faktura vil bli merket betalt. Er du sikker på å betale?"
                  onConfirm={() => markPaid({ id: _id })}
                  okText="ja"
                  cancelText="Nei"
                >
                  <Button
                    id="markpaidbtn"
                    type="danger"
                    style={{ background: "red" }}
                  >
                    Ubetalt
                  </Button>
                </Popconfirm>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {
  markInvoicePaid
})(SingleInvoice);
