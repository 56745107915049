import React, { Component } from "react";
import Home from "../Home/Home";
import { Table, Form, Input, InputNumber, notification, Button } from "antd";
import { Modal as AntModal } from "antd";
import SingleInvoice from "../SingleInvoice/SingleInvoice";
import Modal from "react-modal";

import appConfig from "../../helpers/appConfig";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)"
//   }
// };

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class CTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: "",
      error: null,
      isLoaded: false,
      visible: false,
      isAuthenticated: false,
      first_name: "",
      last_name: "",
      address: "",
      currentPage: "ctable",
      invoiceData: [],
      items: [],
      filtered: [],
      branch: "",
      modalIsOpen: false,
      firstName: "",
      lastName: "",
      phoneNo: "",
      reference: "",
      email: "",
      line1: "",
      line2: "",
      pin: "",
      city: "",
      state: "",
      country: "",
      id: "",
      searchQ: ""
    };
  }

  componentDidMount() {
    this.loadCustomers();
  }

  loadCustomers = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/customer`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
            this.setState({ isLoaded: true });
          } else {
            this.setState({
              isLoaded: true,
              items: result.data.reverse()
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  isEditing = (record) => {
    return record.email === this.state.editingKey;
  };

  edit(e, key) {
    e.preventDefault();
    this.setState({ editingKey: key });
  }

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.items];
      const index = newData.findIndex((item) => key === item.email);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });

        this.setState(
          {
            items: newData,
            editingKey: ""
          },
          this.updateCustomer(
            row.firstName,
            row.lastName,
            row.phoneNo,
            item._id
          )
        );
      } else {
        newData.push(row);
        this.setState({ items: newData, editingKey: "" });
      }
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateCustomer = (e) => {
    e.preventDefault();
    let hdr = localStorage.token;
    const {
      id,
      firstName,
      lastName,
      phoneNo,
      email,
      line1,
      line2,
      pin,
      city,
      state,
      country
    } = this.state;

    let data = JSON.stringify({
      firstName,
      lastName,
      phoneNo,
      email,
      line1,
      line2,
      pin,
      city,
      state,
      country
    });
    //console.log(data);
    this.setState({ isLoaded: false });
    fetch(`${appConfig.endpoint}/customer/${id}`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
            this.setState({ isLoaded: true });
          } else {
            this.openNotification("success", "Customer updated successfully.");
            this.setState({ isLoaded: true, modalIsOpen: false });
            this.loadCustomers();
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  showInvoice = (invoiceNumber) => {
    let index = this.state.invoiceData.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    this.setState({
      currentPage: "singleInvoice",
      billData: this.state.invoiceData[index]
    });
  };

  goBackButton = () => {
    if (this.state.currentPage === "singleInvoice") {
      this.setState({ currentPage: "invoices" });
    } else {
      this.setState({ currentPage: "ctable" });
    }
  };

  showCustomerInvoices = (id) => {
    var hdr = localStorage.token;
    this.setState({ isLoaded: false });
    fetch(`${appConfig.endpoint}/customer/${id}/invoice`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
            this.setState({ isLoaded: true });
          } else {
            let data = result.data.invoiceData.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(3),
              isLoaded: true
            }));
            var invoiceToLoad = data.reverse();
            this.setState({
              invoiceData: invoiceToLoad,
              branch: result.data.branch,
              currentPage: "invoices",
              isLoaded: true
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  showModal = (record) => {
    const { firstName, lastName, email, phoneNo, reference } = record;
    const { line1, line2, city, pin, state, country } = record.address;
    this.setState({
      visible: true,
      firstName,
      lastName,
      phoneNo,
      reference,
      email,
      line1,
      line2,
      city,
      state,
      pin,
      country
    });
  };

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      visible: false
    });
  };

  toggleModal(record) {
    //console.log(record);
    const { _id, firstName, lastName, phoneNo, email, address } = record;
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      id: _id,
      firstName,
      lastName,
      phoneNo,
      email,
      line1: address.line1,
      line2: address.line2,
      pin: address.pin,
      city: address.city,
      state: address.state,
      country: address.country
    });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  delete(record) {
    const newData = [...this.state.items];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1);
      this.setState(
        {
          items: newData,
          editingId: item._id
        },
        this.state.category
          ? this.deleteCategory(item._id)
          : this.deleteProduct(item._id)
      );
    } else {
      newData.push(record);
      this.setState({ items: newData, editingKey: "" });
    }
  }

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  showSingleCustomer = (record) => {
    this.setState({ currentPage: "singleCustomer", currentCustomer: record });
  };

  filterUser = (e) => {
    const regex = RegExp(e.target.value.toString());
    const filtered = this.state.items.filter((user) =>
      e.target.value === ""
        ? true
        : (user.email && regex.test(user.email)) ||
          (user.firstName && regex.test(user.firstName)) ||
          (user.lastName && regex.test(user.lastName)) ||
          (user.phoneNo && regex.test(user.phoneNo.toString())) ||
          (user.address && user.address.line1 && regex.test()) ||
          (user.address &&
            user.address.line1 &&
            regex.test(user.address.line1)) ||
          (user.address &&
            user.address.line2 &&
            regex.test(user.address.line2)) ||
          (user.address &&
            user.address.city &&
            regex.test(user.address.city)) ||
          (user.address &&
            user.address.state &&
            regex.test(user.address.state)) ||
          (user.address &&
            user.address.country &&
            regex.test(user.address.country))
    );
    this.setState({ searchQ: e.target.value, filtered });
  };

  render() {
    //console.log(this.state);
    const {
      error,
      items,
      isLoaded,
      currentPage,
      invoiceData,
      filtered,
      firstName,
      lastName,
      reference,
      country,
      city,
      email,
      line1,
      line2,
      phoneNo,
      pin,
      state,
      searchQ
    } = this.state;
    const dailyColumns = [
      {
        title: "Navn",
        dataIndex: "firstName",
        editable: false,
        width: "18%",
        render: (text, record) => {
          return `${record.firstName ? record.firstName : ""} ${
            record.lastName ? record.lastName : ""
          }`;
        }
      },
      {
        title: "Email",
        dataIndex: "email",
        width: "20%"
      },

      {
        title: "Phone",
        dataIndex: "phoneNo",
        editable: false,
        width: "15%",
        render: (text) => (text === 0 ? "" : text)
      },
      {
        title: "Actions",
        dataIndex: "operation",
        width: "30%",
        render: (text, record) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Button onClick={() => this.showModal(record)}>View</Button>{" "}
              <Button onClick={() => this.toggleModal(record)}>Edit</Button>{" "}
              <Button onClick={() => this.showCustomerInvoices(record._id)}>
                Faktura
              </Button>
            </div>
          );
        }
      }
    ];

    const invoiceColumn = [
      {
        title: "Invoice No.",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber"
      },
      {
        title: "Date",
        dataIndex: "createdDate",
        key: "createdDate"
      },
      {
        title: "Time",
        dataIndex: "createdTime",
        key: "createdTime"
      },
      {
        title: "Order Type",
        dataIndex: "orderType",
        key: "orderType"
      },
      {
        title: "Total",
        dataIndex: "taxPrice",
        key: "taxPrice",
        render: (text, record) => {
          return `Kr.${text}`;
        }
      },
      {
        title: "Invoice",
        key: "_id",
        render: (text, record) => (
          <Button onClick={() => this.showInvoice(record.invoiceNumber)}>
            View
          </Button>
        )
      }
    ];

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const customerColumn = dailyColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "phoneNo" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (currentPage === "ctable") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">CRM</span>
            <span className="item" />
          </div>
          <Table
            loading={isLoaded ? false : true}
            dataSource={searchQ ? filtered : items}
            title={() => (
              <Input
                placeholder="Search"
                type="text"
                style={{ marginBottom: 16, width: "100%" }}
                onChange={(e) => this.filterUser(e)}
              />
            )}
            components={components}
            rowKey={(record) => record.email}
            columns={customerColumn}
            bordered
          />
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={() => this.closeModal()}
            // style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Customer"
          >
            <div className="button-container">
              <button
                onClick={() => this.closeModal()}
                className="close-button"
              >
                X
              </button>
            </div>
            <form
              className="add-employee add-customer"
              onSubmit={(e) => this.updateCustomer(e)}
            >
              <li>
                <center>
                  <h2>Edit Customer</h2>
                </center>
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="firstName"
                  className="input2"
                  placeholder="First Name"
                  value={firstName}
                />
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="lastName"
                  className="input2"
                  placeholder="Last Name"
                  value={lastName}
                />
              </li>
              <li>
                <input
                  type="email"
                  onChange={this.handleChange}
                  name="email"
                  className="input2"
                  placeholder="E-mail"
                  value={email}
                />
              </li>
              <li>
                <input
                  type="number"
                  onChange={this.handleChange}
                  name="phoneNo"
                  className="input2"
                  min="0000000000"
                  max="9999999999"
                  required
                  maxlength="10"
                  pattern="[0-9]"
                  placeholder="Phone No."
                  value={phoneNo === 0 ? "" : phoneNo}
                />
              </li>
              <p>Address</p>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line1"
                  className="input2"
                  placeholder="Address Line 1"
                  value={line1}
                />
              </li>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line2"
                  className="input2"
                  placeholder="Address Line 2"
                  value={line2}
                />
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="city"
                  className="input2"
                  placeholder="City"
                  value={city}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="number"
                  onChange={this.handleChange}
                  name="pin"
                  className="input2"
                  placeholder="PIN"
                  value={pin}
                />
              </li>
              <li>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="state"
                  className="input2"
                  placeholder="State"
                  value={state}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="country"
                  className="input2"
                  placeholder="Country"
                  value={country}
                />
              </li>
              <li>
                <button type="submit" id="submit-landing2">
                  Submit
                </button>
              </li>
            </form>
          </Modal>

          <AntModal
            title={`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={this.handleOk}>
                Ok
              </Button>
            ]}
          >
            <p>
              <b>Email:</b> {email ? email : ""}
            </p>
            <p>
              <b>Phone:</b> {phoneNo ? phoneNo : ""}
            </p>
            <p>
              <b>reference:</b> {reference ? reference : ""}{" "}
            </p>
            <h5>Address:</h5>
            <p>{`${line1 ? line1 : ""} ${line2 ? line2 : ""}`}</p>
            <p>{`${city ? city : ""} ${pin ? pin : ""}`}</p>
            <p>{`${state ? state : ""} ${country ? country : ""}`}</p>
          </AntModal>
        </Home>
      );
    } else if (currentPage === "invoices") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">CRM</span>
            <span className="item">/ Invoices</span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <Table
            loading={isLoaded ? false : true}
            dataSource={invoiceData}
            components={components}
            rowKey={(record) => record.email}
            columns={invoiceColumn}
            bordered
          />
        </Home>
      );
    } else if (currentPage === "singleInvoice") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Invoice No. {this.state.billData.invoiceNumber}
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <SingleInvoice
            data={this.state.billData}
            branch={this.state.branch}
          />
        </Home>
      );
    }
  }
}

export default CTable;
