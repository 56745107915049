import React, { Component } from "react";
import Home from "../Home/Home";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Modal from "react-modal";
import "./hrm.css";
import { Switch, notification } from "antd";

import appConfig from "../../helpers/appConfig";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class hrmComponent extends Component {
  constructor(props) {
    super(props);
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    var tdDiscountPermission = localStorage.getItem("tdDiscountPermission");
    this.state = {
      modalIsOpen: false,
      designation: [],
      permissions: [
        "taccountant",
        "managecat",
        "crm",
        "hrm",
        "stock",
        "invoice",
        "openaccount",
        "tableview"
        // "kitchen"
      ],
      taccountant: 0,
      managecat: 0,
      crm: 0,
      hrm: 0,
      stock: 0,
      invoice: 0,
      openaccount: 0,
      tableview: 0,
      kitchen: 0,
      bar: 0,
      tdDiscountPermission: 0
    };

    if (kitchenpermission && kitchenpermission == "true") {
      this.setState({ permissions: this.state.permissions.push("kitchen") });
    }
    if (barpermission && barpermission == "true") {
      this.setState({ permissions: this.state.permissions.push("bar") });
    }
    if (tdDiscountPermission && tdDiscountPermission == "true") {
      this.setState({ permissions: this.state.permissions.push("tdDiscount") });
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  changed(checked, item) {
    let toggledState = {};
    if (checked) {
      console.log("checked");
      toggledState[item] = 1;
    } else {
      console.log("unchecked");
      toggledState[item] = 0;
    }

    this.setState(toggledState, function () {
      console.log(this.state);
    });
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  addEmployee = (e) => {
    e.preventDefault();
    // /employee post request

    var data = JSON.stringify({
      email: this.state.email,
      hrm: this.state.hrm,
      crm: this.state.crm,
      taccountant: this.state.taccountant,
      invoice: this.state.invoice,
      stock: this.state.stock,

      managecat: this.state.managecat,
      branchId: localStorage.bid,
      openaccount: this.state.openaccount,
      tableview: this.state.tableview,
      kitchen: this.state.kitchen,
      bar: this.state.bar,
      tdDiscountPermission: this.state.tdDiscountPermission,
      empType: "EMPLOYEE"
    });

    console.log(data);

    fetch(`${appConfig.endpoint}/employee`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": localStorage.token,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            console.log(result);
            this.openNotification(
              "error",
              result.data.errors ? result.data.errors[0].msg : result.data
            );
          } else {
            this.openNotification("success", result.message);
            // this.props.history.push('/hrm');
            this.setState({ designation: [], email: "" });
            console.log(this.state);
            this.toggleModal();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  addDesignation = (e) => {
    var designation = this.state.designation.slice();
    designation.findIndex((item) => item);
    var newDes = designation.concat(e.target.value);
    this.setState({ designation: newDes });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    console.log("KTICHEN permission", kitchenpermission);
    var permissionsAlt = {
      managecat: "Manage",
      taccountant: "Arkiv",
      stock: "Lager",
      crm: "Kunder",
      hrm: "Ansatte",
      invoice: "Faktura",
      openaccount: "Opprett Konto",
      tableview: "Bord"
      //  kitchen: "Kitchen",
      // bar: "Bar"
    };
    if (kitchenpermission && kitchenpermission == "true") {
      permissionsAlt["kitchen"] = "Kjøkken";
    }
    if (barpermission && barpermission == "true") {
      permissionsAlt["bar"] = "Bar";
    }

    const { permissions } = this.state;
    return (
      <Home isAdmin={true}>
        <div className="report-container-flex">
          <button className="box shadow stock-bg">
            <p className="text-items">ANSATTE</p>
          </button>
        </div>
        <div className="different-reports">
          <div id="flex-crm">
            <button
              onClick={() => {
                this.navigate("/emptable");
              }}
              className="box shadow orange-bg"
            >
              <p className="text-items">ANSATTE LISTE</p>
            </button>
          </div>
          <div id="flex-crm">
            <button className="box shadow black-bg" onClick={this.toggleModal}>
              <p className="text-items">LEGG TIL ANSATTE</p>
            </button>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.toggleModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Legg Til Ansatte"
        >
          <div className="button-container">
            <button onClick={this.toggleModal} className="close-button">
              X
            </button>
          </div>
          <form className="add-employee">
            <li>
              <center>
                <h2>Legg Til Ansatte</h2>
              </center>
            </li>
            <li>
              <input
                type="text"
                onChange={this.handleChange}
                name="email"
                className="input2"
                placeholder="E-mail"
              />
            </li>
            <li className="designation-title">Ansatte Tillatelser</li>
            <li className="switches">
              {permissions.map((item) => {
                return (
                  <Switch
                    checkedChildren={permissionsAlt[item]}
                    unCheckedChildren={permissionsAlt[item]}
                    onChange={(checked) => this.changed(checked, item)}
                  />
                );
              })}
            </li>
            <li>
              <button
                onClick={this.addEmployee}
                type="submit"
                id="submit-landing2"
              >
                SEND
              </button>
            </li>
          </form>
        </Modal>
      </Home>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const HRM = connect(mapStateToProps)(hrmComponent);
export default HRM;
