import React, { useEffect, useState } from "react";
import Home from "../../Home";
import { connect } from "react-redux";
import { getReportView } from "../../../action/ReportAction";

import SingleInvoice from "./SingleInvoice";

import { NavLink } from "react-router-dom";
const ViewReport = (props) => {
  let [rLink, setRLink] = useState("");
  useEffect(() => {
    async function fetchData() {
      await props.getReportView();
    }
    fetchData();
  }, []);
  const { reportName } = props.viewReport;
  useEffect(() => {
    if (reportName) goBackButton(reportName);
  }, [reportName]);
  const goBackButton = (reportName) => {
    switch (reportName) {
      case "UKENTLIG RAPPORT":
        setRLink("/arkiv/week-report");
        break;
      case "MÅNEDLIG RAPPORT":
        setRLink("/arkiv/monthly-report");
        break;
      case "ÅRLIG RAPPORT":
        setRLink("/arkiv/year-report");
        break;
    }
  };
  const singleInvoice = () => {
    if (
      props.viewReport.billData &&
      Object.keys(props.viewReport.billData).length
    ) {
      return (
        <SingleInvoice
          data={props.viewReport.billData}
          branch={props.viewReport.branch}
        />
      );
    }
  };
  return (
    <Home>
      <div className="report-container">
        <span className="item ">
          <span>Arkiv /{props.viewReport.reportName}: </span> FAKTURA NR:
          {props.viewReport ? props.viewReport.billData.invoiceNumber : ""}
           <NavLink className="back-btn" to={rLink}>

            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
        </span>
      </div>
      {singleInvoice()}
    </Home>
  );
};
const mapStateToProps = ({ viewReport }) => {
  return {
    viewReport
  };
};
export default connect(mapStateToProps, {
  getReportView
})(ViewReport);
