import { GET_ANSATTE } from "./../../action/types";
const state={
  employeeList:[],
  empPermissions:[]
}
const ansatteReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ANSATTE:
      return {...state,employeeList:action.payload.data.employeeList,empPermissions :action.payload.data.permissions};
    case 'GET_EMP_PERMISSIONS':
      console.log("-----------GET_EMP_PERMISSIONS--------",action.payload.empPermissions)
      return {...state,empPermissions :action.payload.empPermissions}
    case 'GET_SWITCH_PERMISSIONS':
      console.log("-----------GET_SWITCH_PERMISSIONS--------",action.payload.data.empPermissions)
      console.log("-----------GET_SWITCH_PERMISSIONS-----DATA---",action.payload.data)
      return {...state,empPermissions :action.payload.data.empPermissions}
    default:
      return state;
  }
};
export default ansatteReducer