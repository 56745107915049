import axios from "axios";
import appConfig from "../helpers/appConfig";
import {
  GET_FAKTURA,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  GET_LAGER_REPORT,
  GET_GROUP_REPORT,
  GET_EMPLOYEES_TIP_REPORT,
  LEG_TIL_FILER,
} from "./types";
import { isServerLogout } from "../utils/isServerLogout";
export const getFatura = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/faktura`,
      data
    );
    dispatch({ type: GET_FAKTURA, payload: res.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const downloadPdFile = (path) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/reports/restReport`,
      path
    );
    window.open(res.data.data.url, "_blank");
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const markaspaid = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/markPaid`,
      id
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "markaspaid" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const markInvoicePaid = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/markInvoicePaid`,
      id
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "markaspaid" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getGroupReport = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/group-report`,
      data
    );
    dispatch({
      type: GET_GROUP_REPORT,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const printGroupReport = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/print-group-report`,
      data
    );
    window.open(res.data.data.url, "_blank");
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getLagerReport = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${appConfig.endpoint}/stockredlist`);
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/arkiv/lager-report`
    );
    dispatch({ type: GET_LAGER_REPORT, payload: res.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const employeesTipReport = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/tip-report`,
      data
    );
    dispatch({ type: GET_EMPLOYEES_TIP_REPORT, payload: res.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const leggTilFiler = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/arkiv/leggTilFilerpdfUpload`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: LEG_TIL_FILER },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
