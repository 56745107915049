import React, { useEffect, useState } from "react";
import Home from "../../Home";
import { Country, State, City } from "country-state-city";
import { Input, Table, Button, notification, Icon } from "antd";
import "../VendorOrder.css";
import { Select } from "antd";
import { POSTAPI, GETAllAPI } from "../AllApi";
import appConfig from "../../../helpers/appConfig";

const { Search } = Input;
const { Option } = Select;
const { Column } = Table;

const VendorCities = () => {
  const [city, setCity] = useState([]);
  const [availCities, setAvailCities] = useState([]);
  let [editingKey, setEditingKey] = useState(false);
  let [editDriveCharge, setEditDriveCharge] = useState("");
  let [editCity , setEditCity] = useState("");
  const [inputState , setInputState] = useState(false);
  const [list, setList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [driveCharge, setDriveCharge] = useState("");
  let norway = Country.getCountryByCode("NO");
  let States = State.getStatesOfCountry("NO");
  let cities = City.getCitiesOfCountry("NO");
  //console.log("--------norway", norway);
  let allCountires = Country.getAllCountries();
  const bid = localStorage.getItem("bid");
  const vid = localStorage.getItem("vid");
  // console.log("cities", cities);



  //==========================set citites===============================//

  const setRecords = async (data) => {
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/vendor/apis/setCities`,
        data
      );
      if (resData.status == 200) {
        setAvailCities(resData.data.availCities);
        console.log(
          "avail city when status is 200 ===",
          resData.data.availCities
        );
        notification.open({
          message: "new city details added",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 1,
        });

        getCities();
        // setCity(data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  //================ to get cities======================//
  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      let resData = await GETAllAPI(
        `${appConfig.endpoint}/api/v1/vendor/apis/getCities`
      );
      setCity(resData.data[0].availCities);
      //setList(resData.data)
    } catch (error) {
      console.log(error);
    }
  };

  console.log("cities list===========", city);



  //-----------------search opeation-------------------//
  function onChange(value) {
    setSelectedCity(value);
  }

  function onBlur() {
    //console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    //console.log("search:", val);
  }



  // ----------------extract other info using selected city---------------------//
  let latitude;
  let longitude;
  let stateCode;
  let countryCode;
  let filteredData = cities.filter((val) => {
    if (val.name.toLowerCase() == selectedCity.toLowerCase()) {
      // console.log("filtered data===========", val)
      latitude = val.latitude;
      longitude = val.longitude;
      stateCode = val.stateCode;
      countryCode = val.countryCode;
    }
  });



  //-------------print all data -------------//
  let driveOutList = {};

  const addCharges = () => {
    if (selectedCity != "" && driveCharge != "") {
      driveOutList.city = selectedCity;
      driveOutList.driveOutCharges = driveCharge;
      setList([...list, driveOutList]);

      console.log("cities list===========", list);

      let postData = {
        cityData: {
          name: selectedCity,
          longitude: longitude,
          latitude: latitude,
          countryCode: countryCode,
          stateCode: stateCode,
          driveOutCharge: driveCharge,
        },
        branchId: bid,
      };
      setRecords(postData);
    } else {
      notification.open({
        message: "Please enter details of driveout",
        icon: <Icon type="close-circle" style={{ color: "red" }} />,
        style: { top: "-50px" },
        duration: 2,
      });
    }
  };
  // console.log("value is=============",list)



  
  //------------------------    delete selected data   ----------------------------//
  const handleDeleteTodo = (id) => {
    console.log("--------id",id)
  };



//----------------------------- delete button functionality  ------------------//

const delCity = async (id) => {
  try {
    let resData = await POSTAPI(
      `${appConfig.endpoint}/api/v1/vendor/apis/delCity`,
      {id}
    );
    if (resData.status == 200) {
      // setAvailCities(resData.data.availCities);
      // console.log(
      //   "avail city when status is 200 ===",
      //   resData.data.availCities
      // );
      notification.open({
        message: "successfully updated",
        icon: <Icon type="check-circle" style={{ color: "green" }} />,
        duration: 1,
      });
      getCities();
      // setCity(data);
    }
  } catch (error) {
    console.log(error);
  }
};


  //-------------------edit button functionality-------------------//

  const editData = async (render) => {
    let id = render._id;
    setEditingKey(id);
    setEditDriveCharge(render.driveOutCharge);
    setEditCity(render.name)

  };



  // ===========================  check edit button functionality   ============================= //

  const checkEditedData = async ( data ) =>{
    let payload = {};
    payload.branchId = bid;
    let cityData = {};
    cityData._id = editingKey;
    cityData.name = editCity;
    cityData.driveOutCharge = editDriveCharge;
    payload.cityData = cityData;

    console.log("editable data=============", payload)

    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/vendor/apis/updateCity`,
        payload
      );
      if (resData.status == 200) {
        setAvailCities(resData.data.availCities);
        notification.open({
          message: "updated driveut charges",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          style: { top: "-10px" },
          duration: 1.5,
        });
        getCities();
      }
    } catch (error) {
      console.log(error);
    }
    setEditingKey(" ");
  }

//-------------------------   close edited data    --------------------//

const closeEditedData = () =>{
  getCities();
  setEditingKey(" ");
}


  return (
    <div>
      <Home>
        {" "}
        <div className="justify-content-center d-flex m-3 ">
          <Select
            className="m-4"
            showSearch
            style={{ width: "200px" }}
            placeholder="Select City"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities.map((city) => (
              <Option key={city._id} value={city.name}>
                {city.name}
              </Option>
            ))}
          </Select>
          <Input
            onChange={(e) => setDriveCharge(e.target.value)}
            value={driveCharge}
            className="m-4"
            placeholder="Drive Out Charges"
            style={{ width: "200px" }}
            type="Number"
          />
          <Button
            type="primary"
            className="city-search m-4"
            onClick={() => addCharges()}
          >
            ADD
          </Button>
        </div>
        <div
          className="d-flex flex-column"
          style={{ marginLeft: "40px", marginRight: "40px" }}
        >
          <Table
            id="tablePrint"
            bordered
            dataSource={city}
            pagination={true}
            style={{ borderBlockStartColor: "red", marginTop: "20px" }}
          >
            <Column
              title="Sr. No."
              key="srNo"
              width="10%"
              render={(text, render, index) => index + 1}
            />
            <Column
              title="City"
              key="city"
              width="30%"
              render={(text, render) => render.name}
            />
            <Column
              title="DriveOut Charges"
              width="30%"
              render={(text, render) => {
                return editingKey == render._id ? (
                  <Input
                  inputState = {inputState}
                    value={editDriveCharge}
                    onChange={(e) => setEditDriveCharge(e.target.value)}
                  />
                ) : (
                  `Kr.${Number(render.driveOutCharge)}`
                  // <a onClick={() => redirectToProduct(record)}>{text}</a>
                );
              }}
            />
            <Column
              title="Handling"
              width="30%"
              render={(text, render, index) => {
                return editingKey == render._id ? (
                  <>
                    <Icon  type="check" className="checkIcon" 
                    onClick={()=>checkEditedData(render)}
                    style={{ color: "green" }} />
                    <Icon type="close"  
                    onClick={()=>closeEditedData()}
                    className="closeIcon"/>
                    <Button
                      type="button"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleDeleteTodo(index, render)}
                    >
                      <i
                        title="Detete Category"
                        style={{ color: "#dcbb68", fontWeight: "500" }}
                        className="fa fa-trash"
                      ></i>
                    </Button>
                  </>
                ) : (
                  <>
                  <Button
                    type="button"
                    name="isEdited"
                    onClick={() => editData(render)}
                  >
                    <i
                      title="Update Category"
                      style={{ color: "#13CD52", fontWeight: "900" }}
                      className="fa fa-edit"
                    ></i>
                  </Button>
                  <Button
                      type="button"
                      style={{ marginRight: "10px" }}
                      onClick={() => delCity(text._id)}
                    >
                      <i
                        title="Detete Category"
                        style={{ color: "#dcbb68", fontWeight: "500" }}
                        className="fa fa-trash"
                      ></i>
                    </Button>
                    </>
                );
              }}
            />
          </Table>
        </div>
      </Home>
    </div>
  );
};

export default VendorCities;
