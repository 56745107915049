import React from "react";
import Header from "./Header";

import { Row, Col } from "react-bootstrap";

const List = (props) => {
  return (
    <>
      <Row className="mb-5">
        <Col>
          <Header
            goBackMainPage={props.goBackMainPage}
            lagerReport={props.lagerReport}
          />
        </Col>
      </Row>
    </>
  );
};

export default List;
