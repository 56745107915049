import Axios from "axios";
import appConfig from "../../helpers/appConfig";

export const updateReserveSeats=async(value)=>{

    try {
        console.log("---data--------",{
            value
        })
    
    
        let data={
            availSeats:value
        }
    
        let resData=await Axios.post(`${appConfig.endpoint}/api/v1/employee/information/setAvailSeats`,data)
    
        // console.log("resData",resData)
        if(resData.data.status==200){
            return resData.data
        }else{

            return {
                status:400,
                message:"Error in updating seats"
            }
        }
        
    } catch (error) {
        
    }
  

}


