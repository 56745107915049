import Axios from "axios";

const GETAllAPI = async (url, type = "") => {
  try {
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    console.log("Something Went Wrong on Post Api", error);
  }
};

const POSTAPI = async (url, data = {}) => {
  try {
    let res = await Axios.post(url, data);
    return res.data;
  
  } 
  catch (error) {
    console.log("Something Went Wrong on Post Api", error);
  }
};



export { GETAllAPI, POSTAPI };
