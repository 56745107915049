import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import Home from "../Home";
import "./Reports.css";
import  { socket } from "../../utils/socketNew";
import { subscribeToOrder, subscribeToTableOrder,subscribeToTreeDriveOrder } from "../../helpers/socket";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnlineTableBookingCount,
  getOrderCount,
  getOrderCountTreeDrive
} from "../../action/ordersAction";
const Report = (props) => {
  const dispatch = useDispatch();
  const [onlineOrderCount, setonlineOrderCount] = useState("");
  const [onlineTableBookingCount, setonlineTableBookingCount] = useState();
  const onlineCount = useSelector((state) => state.orderCount.orders);
  const [onlineTreeDriveOrderCount,setOnlineTreeDriveOrderCount]=useState("")
  const treeDriveCount=useSelector((state)=>state.treeDriveOrderCount.orders);
  const onlineTableCount = useSelector(
    (state) => state.onlineTableBookingCount.orders
  );

  useEffect(() => {
    subscribeToOrder(async (err, orders) => {
      dispatch(getOrderCount());
    });
    subscribeToTableOrder(async (err, orders) => {
      dispatch(getOnlineTableBookingCount());
    });
    subscribeToTreeDriveOrder(async(err,orders)=>{
      
      dispatch(getOrderCountTreeDrive)
    })
  }, []);

  // useEffect(()=>{
  //   socket.on("orderReceiveTreeDrive",async (data)=>{
  //     console.log("inside socket of treeDrive order receive--->",data)
  //   })
  // },[])

  useEffect(() => {
    let count1 = localStorage.getItem("treeDriveOrderCount");
    // console.log("inside the tree drive order count---->",count1)
    // console.log("treeDiveCount useEffect------>",treeDriveCount)
    setOnlineTreeDriveOrderCount(count1);
  }, [treeDriveCount]);

  useEffect(() => {
    let count1 = localStorage.getItem("onlineOrderCount");
    setonlineOrderCount(count1);
  }, [onlineCount]);

  useEffect(() => {
    let count2 = localStorage.getItem("onlineTableCount");
    setonlineTableBookingCount(count2);
  }, [onlineTableCount]);

  const renderPage = () => {
    return (
      <Home>
        <div className="report-container text-center">
          <span className="item ">Rapporter</span>
        </div>
        <Card>
          <Card.Body>
            <Row className="m-b-1">
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/daily-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  DAILY BILLS 
                </NavLink>
              </Col>
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/x-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  X REPORT
                </NavLink>
              </Col>
              
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/cash-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                 CASH BOX REPORT
                </NavLink>
              </Col>
            </Row>
            {/* <Row className="m-b-1">
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/online-order"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  ONLINE ORDERS
                </NavLink>
                {onlineOrderCount && Number(onlineOrderCount) ? (
                  <Badge className="badgeCount" bg="danger">
                    {onlineOrderCount}
                  </Badge>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            {JSON.parse(localStorage.getItem("branchInfo")).isQrAllow && (
              <Row className="m-b-1">
                <Col className=" text-center">
                  <NavLink
                    to="/rapporter/dailydining"
                    className="btn btn-link"
                    style={{ width: "120px", padding: "30px" }}
                  >
                    DIGITAL DINE IN
                  </NavLink>
                  {onlineTableBookingCount &&
                  Number(onlineTableBookingCount) ? (
                    <Badge className="badge_Count" bg="danger">
                      {onlineTableBookingCount}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            )}
            
            {JSON.parse(localStorage.getItem("isTreeDriveAllowed")) && (
              <Row className="m-b-1">
                <Col className=" text-center">
                  <NavLink
                    to="/rapporter/treedriveorders"
                    className="btn btn-link"
                    style={{ width: "120px", padding: "30px" }}
                  >
                    TREEDRIVE ORDERS
                  </NavLink>
                  {onlineTreeDriveOrderCount &&
                  Number(onlineTreeDriveOrderCount) ? (
                    <Badge className="badge_Count" bg="danger">
                      {onlineTreeDriveOrderCount}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            )} */}
          </Card.Body>
        </Card>
        <div className="report-container text-center order-type">
          <span className="item ">Orders Type & Details </span>
        </div>
        <Card>
          <Card.Body>
            {/* <Row className="m-b-1">
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/daily-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  DAILY REPORT
                </NavLink>
              </Col>
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/x-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  X REPORT
                </NavLink>
              </Col>
            </Row> */}
            <Row className="m-b-1">
              {/* <Col className=" text-center">
                <NavLink
                  to="/rapporter/cash-report"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  CASH REPORT
                </NavLink>
              </Col> */}
             
            </Row>

            
              <Row className="m-b-1">
              <Col className=" text-center">
                {JSON.parse(localStorage.isRestaurantAvailable) ? <NavLink
                  to="/rapporter/online-order"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  ONLINE ORDERS
                </NavLink> : ""}
                {onlineOrderCount && Number(onlineOrderCount) ? (
                  <Badge className="badgeCount" bg="danger">
                    {onlineOrderCount}
                  </Badge>
                ) : (
                  ""
                )}
              </Col>
              {JSON.parse(localStorage.getItem("branchInfo")).isQrAllow && (
                <Col className=" text-center">
                  <NavLink
                    to="/rapporter/dailydining"
                    className="btn btn-link"
                    style={{ width: "120px", padding: "30px" }}
                  >
                    DIGITAL DINE IN
                  </NavLink>
                  {onlineTableBookingCount &&
                  Number(onlineTableBookingCount) ? (
                    <Badge className="badge_Count" bg="danger">
                      {onlineTableBookingCount}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Col>
            )}
              {JSON.parse(localStorage.getItem("isTreeDriveAllowed")) && (
                <Col className=" text-center">
                  <NavLink
                    to="/rapporter/treedriveorders"
                    className="btn btn-link"
                    style={{ width: "120px", padding: "30px" }}
                  >
                    TREEDRIVE ORDERS
                  </NavLink>
                  {onlineTreeDriveOrderCount &&
                  Number(onlineTreeDriveOrderCount) ? (
                    <Badge className="badge_Count" bg="danger">
                      {onlineTreeDriveOrderCount}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Col>
            )}
              </Row>
            
          
          </Card.Body>
        </Card>
      </Home>
    );
  };
  return <>{renderPage()}</>;
};

export default Report;
