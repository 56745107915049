import React, { useEffect, useState } from 'react'
import Home from '../../../Home';
import { Button, Card, Col, Row } from "react-bootstrap";
import appConfig from '../../../../helpers/appConfig';
import { POSTAPI } from '../../AllApi';
import SingleBills from './SingleBills';
import history from '../../../../history';
import moment from "moment";
import { Table } from "antd";
import { Select, DatePicker, Icon } from "antd";
import TableSumX from '../../../Table/TableSumX'
import './DailyBills.css'


const DailyBills = (props) => {

    const [dailyData, setDailyData] = useState([])
    const [loading, setLoading] = useState(false);
    const [onlineDate, setOnlineDate] = useState("");
    const [singleBill, setSingleBill] = useState(false);
    const [page, setPage] = useState("list");
    const [singleBillData, setSingleBillData] = useState([]);
    //const [ taxArray , setTaxArray] = useState([]);
    const bid = localStorage.getItem("bid")



    useEffect(() => {
        console.log("====single bill data===", singleBillData)
    }, [singleBillData])

 


    const getDailyRecord = async (data) => {
        try {
            let resData = await POSTAPI(`${appConfig.endpoint}/api/v1/vendor/invoices/getVendorInvoices`,
                data
            )
            setDailyData(resData.data)
            setLoading(false)
            console.log("====getVendor orders===", resData.data)


        } catch (error) {
            console.log(error)
        }
    }
    // let count = dailyData.length;
    // if(dailyData.length){
    //     history.push({
    //         pathname: "/vendor",
    //         state: {
    //             count: count
    //         }
    //     })
    // }else{
    //     console.log("error")
    // }

    const printbill = () => {
        window.print();
    };

    useEffect(() => {
        setLoading(true);
        const date = onlineDate ? onlineDate : moment().date()._d;
        filterData(date);
    }, [])

    const filterData = (date) => {
        setOnlineDate(date)
        const startDate = moment(date);
        const endDate = moment(date).add(1, "days");
        let postData = {
            startDate: startDate,
            branchId: bid,
            endDate: endDate
        }
        console.log("======data=====", postData)
        getDailyRecord(postData)
    }

    const showOrder = (id) => {
        setSingleBill(true)
        let filteredData = dailyData.filter((item) => {
            return item._id == id
        })
        setSingleBillData(filteredData[0]);
    }

    const goBackMainPage = () => {
        if (singleBill) {
            props.history.push("/vendor-DailyBills");
            setSingleBill(false)
        } else {
            props.history.push("/vendor");
        }
        setPage("list");

    };

    //console.log("all data ====>", dailyData)

    const columns = () => [
        {
            title: "Invoice Number",
            key: "invoiceNumber",
            width: "10%",
            render: (text, record) => record.invoiceNumber

        }
        , {
            title: "Order time",
            key: "orderTime",
            width: "18%",
            render: (text, record) =>
                `${moment(record.created).format("DD/MM/YYYY")} - ${new Date(
                    record.created
                ).toLocaleTimeString()}`
        },
        // {
        //     title: "No. of Products",
        //     key: "noProducts",
        //     width: "9%",
        //     render: (text, record) => record.items.length
        // },
        {
            title: "Total",
            key: "total",
            width: "9%",
            render: (text, record) => {
                return `Kr.${record.totalPrice.toFixed(2)}`;
            }
        }, {
            title: "View",
            className:"view-title",
            key: "view",
            width: "10%",
            render: (text, record) => (
                <Button className="btn-ab" onClick={() => showOrder(record._id)}>
                    <i className="fa fa-eye"></i>
                </Button>
            )
        }
    ]

    const ReportSummaryData = [
        {
            key: "-1",
            render: () => "test"
        }
    ];

    let totalBillPrice = 0;
    dailyData.map((e) => {
        totalBillPrice += e.totalPrice;
    })

    console.log("total price ====", totalBillPrice)

    const ReportSummary = (totalBillPrice) => [
        {
            title: "Invoice Number",
            key: "invoiceNumber",
            width: "58%",
            render: () => <strong>Total</strong>

        },
        {
            title: "Total",
            key: "total",
            render: () => `Kr.${totalBillPrice.toFixed(2)}`

        }
    ]



    return (
        <div>
            <Home>

                <Row>
                    <Col>
                        {singleBill ? <SingleBills billData={singleBillData} singleBill={singleBill} setSingleBill={setSingleBill} /> :
                            <>
                                <Row>
                                    <Col>
                                        <div className="report-container">
                                            <span className="item" style={{ width: "60%" }}>
                                                <span id="less-visible">Vendor / </span>
                                                Daily Bills
                                            </span>
                                            <span id="less-visible">
                                                <Button
                                                    onClick={() => goBackMainPage()}
                                                    className="ant-btn back-btn"
                                                >
                                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                                </Button>
                                            </span>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <DatePicker
                                                    onChange={(date, dateString) => {
                                                        filterData(date)
                                                    }}
                                                    format="YYYY-MM-DD"
                                                    placeholder="Select day"
                                                />
                                            </div>
                                            <div className=' mb-2'
                                                style={{ paddingLeft: "700px" }}
                                                id="printbtn">
                                                <Button
                                                    onClick={() => printbill()}
                                                    type="primary"
                                                    size="large"
                                                    className="btn-ab  text-color tablePrint"
                                                >
                                                    <i
                                                        className="fa fa-print"
                                                        style={{ fontSize: 20 }}
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </div>
                                        </div>
                                        <div>
                                            <TableSumX
                                                id="tablePrint"
                                                loading={loading}
                                                columns={columns()}
                                                data={dailyData}
                                                summaryData={ReportSummaryData}
                                                summaryColumns={ReportSummary(totalBillPrice)}
                                                striped
                                                bordered
                                                rowKey={props.rowKey}


                                            />
                                        </div>
                                    </Col>

                                </Row>
                            </>
                        }
                    </Col>
                </Row>




            </Home>
        </div>
    )
}

export default DailyBills
