import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, Button, Col,Row, Popover } from "antd";
import moment from "moment";
import pdfimg from "../../../assets/Paid.png";
const Option = Select.Option;
const { MonthPicker, WeekPicker } = DatePicker;
const calenderObj = [
  { key: "byDay", val: "By Day" },
  { key: "byWeek", val: "By Week" },
  { key: "byMonth", val: "By Month" }
];
const Betalt = ({
  getFatura,
  faktura,
  type,
  markaspaid,
  downloadPdFile,
  successData
}) => {
  let [searchKey, setSearchKey] = useState("");
  let [calender, setCalender] = useState(calenderObj);
  let [selectedDateType, setSelectedDateType] = useState("byDay");
  let [isDay, setIsDay] = useState(true);
  let [isWeak, setIsWeak] = useState(false);
  let [isMonth, setIsMonth] = useState(false);
  let [searchType, setSearchType] = useState("byDay");
  let [searchDate, setSearchDate] = useState(moment(new Date()));
  useEffect(() => {
    getRestReports(searchDate);
  }, []);

  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "markaspaid"
    ) {
      getRestReports(searchDate);
    }
  }, [successData]);
  const sendFileApis = () => {
    getRestReports(searchDate);
  };
  // const getRestReports = (date) => {
  //   const branchId = localStorage.getItem("bid");
  //   let startDate;
  //   let endDate;
  //   switch (searchType) {
  //     case "byDay":
  //       startDate = moment(date).set({
  //         hour: 4,
  //         minute: 0,
  //         second: 0
  //       });
  //       endDate = moment(date).add(1, "days").set({
  //         hour: 3,
  //         minute: 59,
  //         second: 59
  //       });
  //       break;
  //     case "byWeek":
  //       startDate = moment(date).startOf("week");
  //       endDate = moment(date).endOf("week");
  //       break;
  //     case "byMonth":
  //       startDate = moment(date).startOf("month");
  //       endDate = moment(date).endOf("month");
  //       break;
  //     default:
  //   }
  //   const data = {
  //     startDate: startDate,
  //     endDate: endDate,
  //     branchId: branchId,
  //     searchKey: searchKey,
  //     type: type
  //   };
  //   getFatura(data);
  // };


  const getRestReports = (date) => {
    const branchId = localStorage.getItem("bid");
    let startDate;
    let endDate;
    switch (searchType) {
      case "byDay":
        startDate = moment(date)
        endDate = moment(date).add(1, "days")
        break;
      case "byWeek":
        startDate = moment(date).startOf("week").add(2, "days");
        endDate = moment(date).endOf("week").add(2, "days");
        break;
      case "byMonth":
        startDate= moment(date).startOf("month").add(1, "days");
        endDate= moment(date).endOf("month").add(1, "days")
        break;
      default:
    }
    const data = {
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      searchKey: searchKey,
      type: type
    };
    getFatura(data);
  };
  const handleCalender = (e) => {
    if (e === "byDay") {
      isDay = true;
      isWeak = false;
      isMonth = false;
    } else if (e === "byWeek") {
      isDay = false;
      isWeak = true;
      isMonth = false;
    } else if (e === "byMonth") {
      isDay = false;
      isWeak = false;
      isMonth = true;
    }
    setSelectedDateType(e);
    setIsDay(isDay);
    setIsWeak(isWeak);
    setIsMonth(isMonth);
  };
  const setDateFilter = (type, date) => {
    setSearchType(type);
    setSearchDate(date);
  };
  const showDateFilter = () => {
    if (isDay) {
      return (
        <DatePicker
          onChange={(date, dateString) => {
            setDateFilter("byDay", date);
          }}
          format="YYYY-MM-DD"
          placeholder="Select Day"
        />
      );
    } else if (isWeak) {
      return (
        <WeekPicker
          onChange={(date, dateString) => setDateFilter("byWeek", date)}
          placeholder="Select Week"
        />
      );
    } else if (isMonth) {
      return (
        <MonthPicker
          onChange={(date, dateString) => setDateFilter("byMonth", date)}
          placeholder="Select Month"
        />
      );
    }
  };
  const downloadPdf = (path) => {
    var dtt = path.replace(/\//, "&").split("&")[1];
    var srr = dtt.split("/")[1];
    if (srr == "restReports") {
      path = dtt;
    } else {
      path = path;
    }
    downloadPdFile({ path: path });
  };
  const showReport = () => {
    return faktura.length > 0 ? (
      faktura.map((rest) => {
        return (
          <Col
            key={rest._id}
            span={8}
            className={type === "UNPAID" ? "Unpaid" : "Paid"}
          >
            <a id={rest.pdf} onClick={() => downloadPdf(rest.pdf)}>
              <img src={pdfimg}></img>
              <p className="pl-2">{rest.fileName}, {moment(rest.createdAt).format("DD, MMM, YY")}</p>
            </a>
            {type === "UNPAID" && (
              <Popover
                placement="top"
                title="MERKE SOM BETALT"
                content={
                  <Button onClick={() => markaspaid({ id: rest._id })}>
                    MERKE
                  </Button>
                }
                trigger="click"
              >
                <Button shape="round">MERKE SOM BETALT</Button>
              </Popover>
            )}
          </Col>
        );
      })
    ) : (
      <p className="text-center">No Files Found!</p>
    );
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-5">
              <Input
                placeholder="Search by Name"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
            </div>
            <div className="col-3">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleCalender(e)}
                value={selectedDateType}
              >
                {calender.map((data) => (
                  <Option key={data.key} value={data.key}>
                    {data.val}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="col-3">{showDateFilter()}</div>
            <div className="col-1">
              <Button className="btn-ab" onClick={() => sendFileApis()}>
                <i className="fa fa-search" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Row className="fileList">{showReport()}</Row>
        </div>
      </div>
    </>
  );
};
export default Betalt;
