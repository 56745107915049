import { ERROR_MASSAGE, EMPTY_ERROR_MASSAGE } from "../action/types";
import history from "../history";
const errorMassageReducer = (state = "", action) => {
  switch (action.type) {
    case ERROR_MASSAGE:
      return action.payload.data && action.payload.data.message
        ? action.payload.data.message
        : action.payload.message
        ? action.payload.message
        : "Something went wrong";
    case EMPTY_ERROR_MASSAGE:
      return action.payload;
    default:
      return state;
  }
};
export default errorMassageReducer;
