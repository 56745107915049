import { STOCKS_HISTORY } from "../../action/types";

const stockHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case STOCKS_HISTORY:
      return action.payload.data;
    default:
      return state;
  }
};
export default stockHistoryReducer
