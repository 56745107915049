import axios from "axios";
import {
  GET_ALL_TABLE,
  SAVE_TABLE,
  DELETE_TABLE,
  UPDATE_TABLE,
  ERROR_MASSAGE
} from "./types";
import appConfig from "../helpers/appConfig";
import {isServerLogout} from "../utils/isServerLogout";
export const getallTablesBegin = () => {
  return {};
};

export const getallTables = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/board/getalltable`
    );
    dispatch({
      type: GET_ALL_TABLE,
      payload: res.data.data
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
