import React, { useEffect, useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import Countdown from "react-countdown";
import moment from "moment";
import { Icon as RIcon } from "react-icons-kit";
import { check } from "react-icons-kit/fa/check";
import { Table, Modal, TimePicker, Radio } from "antd";
const { Column } = Table;
const View = ({
  orderData,
  setPage,
  timeString,
  setTimeString,
  changeStatus,
  setCancelReason,
  cancelReason,
  setCancelReasonModel,
  cancelReasonModel,
  setTimemodalvisible,
  timemodalvisible,
  searchDriverReducer,
  searchDriverAction
}) => {
  const [dOCTax, setdOCTax] = useState(0);
  const [resionError, setResionError] = useState("");
  useEffect(() => {
    let dCTax = Number(orderData.driveOutChargeTax) ? 100 + Number(orderData.driveOutChargeTax) : 125;
    setdOCTax(dCTax);
  }, []);
  const btnstyle = {
    background: "transparent",
    border: "1px solid black",
    color: "black"
  };
  const deliveryTime = orderData.deliveryTime;

  const Completionist = () => (
    orderData && orderData.orderStatus!="PENDING"?(<span style={{ color: "red", fontWeight: "bold" }}>
      ORDER TIME COMPLETED!
    </span>
    ):(<span style={{ color: "blue", fontWeight: "bold" }}>
    ORDER CONFIRMATION IS PENDING
  </span>
  )
  );
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const proInst = ({ items }) => {
    let list = "";
    if (items) {
      let i = 1;
      console.log("itemsssss",items)
      for (const [idx, itm] of items.entries()) {
        if (itm.instruction) {
         
          for (const [ix, inst] of itm.instruction.entries()) {
            console.log("inst",inst)
            let extraInstruction=inst.extraInstructions?inst.extraInstructions:""
            console.log("extraInstruction",extraInstruction)
            let extra =
              itm.extraItems && itm.extraItems[ix] ? itm.extraItems[ix] : [];
            
            let desInt = inst.instruction
              ? inst.instruction.replace(/(^,)|(,$)/g, "")
              : "";
            let det = [];
            if (!Array.isArray(desInt)) {
              det = desInt.split(",");
            }
            list += `<ul class="product_ul">`;
            list += `<li>${i}) For ${inst.quantity} ${inst.productName} -</li>`;
            if (desInt && det && det.length > 0) {
              console.log("inside dest Int")
              // list += `<ul>`;
              list += `<ul>`;
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i> Bruksanvisning</h5>`;
              list += `<ul style="list-style-type:none;font-size:15px;">`;
             
              
              if (Array.isArray(det)) {
                for (const [eix, v] of det.entries()) {
                  list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${v}</li>`;
                 
                  
                }
                if(extraInstruction){
                  list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`
                }
              } else {
                list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${det}</li>`;
                // if(extraInstruction){
                //   list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`
                // }
              }
              list += `</ul>`;
              list += `</ul>`;
              // list += `</ul>`;
            }else{
              list += `<ul>`;
              if(extraInstruction){
                list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`
              }
            }
            list += `<ul>`;
            if (extra.length > 0) {
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i>  Ekstra produkt</h5>`;
              let ic = <RIcon size={15} icon={check} />;
              for (const [eix, exta] of extra.entries()) {
                list += `<ul style="list-style-type:none;font-size:15px;">`;
                list += `<li><i class="fa fa-check"></i> ${exta.name}</li>`;
                list += `</ul>`;
              }
            }
            list += `</ul>`;
            list += `</ul>`;
            list += `</ul>`;
            i++;
          }
        }
      }
      return list;
    }
  };
  const orderType = orderData.orderType;
  let driveoutCharge = parseFloat(orderData.driveoutCharge.toFixed(3));
  const dataSource = [
    {
      key: "1",
      totalUnitPrice: `Kr.${orderData.items.reduce(
        // (acc, current) => acc + current.price, // prev
        (acc, current) => acc + current.totalPrice,
        0
      )}`,
      totalExtraPrice: `Kr.${orderData.items.reduce(
        (acc, current) => acc + (current.extraPrice ? current.extraPrice : 0),
        0
      )}`,
      totalQuantity: orderData.items.reduce(
        (acc, current) => acc + current.quantity,
        0
      ),
      total: `Kr.${
        orderData.items.reduce(
          (acc, current) =>
            acc +
            current.totalPrice +
            (current.extraPrice ? current.extraPrice : 0),
          0
        ) + (orderType == "Drive Out" ? driveoutCharge : 0)
      }`,
      driveoutTax:
        orderType == "Drive Out"
          ? `Kr. ${(driveoutCharge - (driveoutCharge * 100) / dOCTax).toFixed(
              3
            )}`
          : 0,
      driveoutCharge:
        orderType == "Drive Out"
          ? `Kr. ${((driveoutCharge * 100) / dOCTax).toFixed(3)}`
          : 0
    }
  ];

  const columns = [
    {
      title: "Drive Out Tax",
      dataIndex: "driveoutTax",
      width: "10%",
      key: "driveoutTax"
    },
    {
      title: "Drive Out Charge",
      dataIndex: "driveoutCharge",
      width: "15%",
      key: "driveoutCharge"
    },
    {
      title: "Total Unit Price",
      dataIndex: "totalUnitPrice",
      width: "13%",
      key: "totalUnitPrice"
    },
    {
      title: "Total Extra Price",
      dataIndex: "totalExtraPrice",
      width: "13%",
      key: "totalExtraPrice"
    },
    { width: "10%" },

    {
      title: "Total quantity",
      dataIndex: "totalQuantity",
      width: "15%",
      key: "totalQuantity"
    },

    {
      title: "Total",
      dataIndex: "total",
      width: "13%",
      key: "total"
    }
  ];
  const handleCancel = () => {
    setTimemodalvisible(false);
    setCancelReasonModel(false);
  };
  const validateTime = () => {
    if (timeString)
      changeStatus(
        "IN-PROCESS",
        orderData._id,
        timeString,
        orderData.orderType
      );
  };
  const timeModel = () => {
    return (
      <Modal
        title="Select Time"
        visible={timemodalvisible}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateTime()}
          >
            Submit
          </Button>
        ]}
      >
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          <TimePicker
            style={{ width: "80%" }}
            onChange={onChangetime}
            defaultValue={moment("00:00", "HH:mm")}
            format={"HH:mm"}
          />
        </div>
      </Modal>
    );
  };
  const searchDriver=async(orderId,time)=>{
    console.log("order id in search driver buttion ------>",orderId);
    const res=await searchDriverAction(orderId,time);
    console.log("inside the searchDriver in the view page----->",searchDriverReducer)

  }
  const validateResion = () => {
    if (cancelReason) {
      changeStatus("CANCEL", orderData._id, timeString, orderData.orderType);
    } else {
      setResionError("Please Select Reason");
    }
  };
  const resionModel = () => {
    return (
      <Modal
        title="Select Reason for cancelling order"
        visible={cancelReasonModel}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateResion()}
          >
            Submit
          </Button>
        ]}
      >
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => setCancelReason(e.target.value)}
            defautValue={cancelReason}
          >
            <Radio.Button value="Item Not Available">
              Items Not present
            </Radio.Button>
            <Radio.Button value="No One to deliver">
              No One to deliver
            </Radio.Button>
            <Radio.Button value="Restaurant closed">
              Restaurant Closed
            </Radio.Button>
            <Radio.Button value=" Not accepting orders">
              Not accepting orders
            </Radio.Button>
          </Radio.Group>
          <p className="text-center text-danger">{resionError}</p>
        </div>
      </Modal>
    );
  };
  const onChangetime = (time, timeString) => {
    setTimeString(timeString);
  };
  return (
    <>
      <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
          <span id="less-visible">Rapporter/ </span>
          Online bestilling/ Ordre detaljer
        </span>
        <span id="less-visible">
          <Button onClick={() => setPage("list")} className="ant-btn back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </Button>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card
                className="od"
                style={{ width: "100%", wordWrap: "break-word" }}
              >
                <Card.Body>
                  {(orderData.customerId[0] || orderData.appUserId[0]) && (
                    <>
                      <p>
                        <strong>OTP: {orderData.otp}</strong>
                      </p>
                      <Countdown
                        style={{ color: "red" }}
                        date={
                          moment(orderData.updatedAt) + Number(deliveryTime)
                        }
                        renderer={renderer}
                      />
                      <p>
                        Name:{" "}
                        {orderData.customerId[0]
                          ? `${orderData.customerId[0].firstName} ${orderData.customerId[0].lastName}`
                          : `${orderData.appUserId[0].firstName} ${orderData.appUserId[0].lastName}`}
                      </p>
                      <p>
                        Phone No.:{" "}
                        {orderData.customerId[0]
                          ? orderData.customerId[0].phoneNo
                          : orderData.appUserId[0].phoneNumber}
                      </p>
                      <p>
                        Email:{" "}
                        {orderData.customerId[0]
                          ? orderData.customerId[0].email
                          : orderData.appUserId[0].email}
                      </p>

                      {orderData.orderStatus == "CANCEL" ? (
                        <span>
                          <p>
                            Order Status:{" "}
                            <b style={{ color: "red" }}>CANCELLED</b>
                          </p>
                          <p style={{ color: "red" }}>
                            <b>Cancel Reason :</b>
                            {orderData.cancelReason}
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {orderData.customerId[0] &&
                    orderData.customerId[0].address &&
                    orderData.orderType == "Drive Out" && (
                      <div style={{ wordWrap: "break-word" }}>
                        <p>
                          Address:
                          {orderData.customerId[0].address.line1}{" "}
                          {orderData.customerId[0].address.line2}
                        </p>
                        <p>
                          {orderData.customerId[0].address.city
                            ? orderData.customerId[0].address.city
                            : ""}{" "}
                          {orderData.customerId[0].address.state
                            ? "," + orderData.customerId[0].address.state
                            : ""}
                        </p>
                        <p>{orderData.customerId[0].address.pin}</p>
                        <p>{orderData.customerId[0].address.country}</p>
                      </div>
                    )}

                  {orderData.appUserId[0] &&
                    orderData.appUserId[0].address &&
                    orderData.orderType == "Drive Out" && (
                      <div style={{ wordWrap: "break-word" }}>
                        <p>
                          Address:
                          {orderData.appUserId[0].address.line1}{" "}
                          {orderData.appUserId[0].address.line2}
                        </p>
                        <p>
                          {orderData.appUserId[0].address.city
                            ? orderData.appUserId[0].address.city
                            : ""}{" "}
                          {orderData.appUserId[0].address.state
                            ? "," + orderData.appUserId[0].address.state
                            : ""}
                        </p>
                        <p>{orderData.appUserId[0].address.pin}</p>
                        <p>{orderData.appUserId[0].address.country}</p>
                      </div>
                    )}
                  {orderData.isAppOrder===false && orderData.orderStatus !== "DELIVERED" && (
                    <>
                      {orderData.orderStatus === "PENDING" && (
                        <Button
                          style={btnstyle}
                          onClick={() => setTimemodalvisible(true)}
                        >
                          Confirm
                        </Button>
                      )}
                      {orderData.orderStatus === "IN-PROCESS" && (
                        <Button
                          className="btn-ab"
                          onClick={() =>
                            changeStatus(
                              "DELIVERED",
                              orderData._id,
                              timeString,
                              orderData.orderType
                            )
                          }
                        >
                          Deliver
                        </Button>
                      )}
                      {"  "}
                      {(orderData.orderStatus === "PENDING" ||
                        orderData.orderStatus === "IN-PROCESS") && (
                        <Button
                          style={btnstyle}
                          onClick={() => setCancelReasonModel(true)}
                        >
                          Cancel
                        </Button>
                      )}
                    </>
                  )}
                  {orderData.isAppOrder===true && orderData.orderStatus !== "DELIVERED" && (
                    <>
                      {orderData.orderStatus === "PENDING" && (
                        <Button
                          style={btnstyle}
                          onClick={() =>searchDriver(orderData._id)}
                        >
                          SEARCH FOR DRIVERS
                        </Button>
                      )}
                      {orderData.orderStatus==="PENDING" && orderData.driverid!=null && (
                         <Button
                         style={btnstyle}
                         onClick={() => setTimemodalvisible(true)}
                       >
                         Confirm
                       </Button>
                      )}
                      {orderData.orderStatus === "IN-PROCESS" && (
                        <Button
                          className="btn-ab"
                          onClick={() =>
                            changeStatus(
                              "DELIVERED",
                              orderData._id,
                              timeString,
                              orderData.orderType
                            )
                          }
                        >
                          HANDOVER TO DRIVER
                        </Button>
                      )}
                      {"  "}
                      {(orderData.orderStatus === "PENDING" ||
                        orderData.orderStatus === "IN-PROCESS") && (
                        <Button
                          style={btnstyle}
                          onClick={() => setCancelReasonModel(true)}
                        >
                          Cancel
                        </Button>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <div className="mycontent-right">
                    <>
                      <h5>Instruksjoner av kunden</h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: proInst(orderData)
                        }}
                      />
                    </>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table
                    dataSource={orderData.items}
                    pagination={false}
                    style={{ borderBlockStartColor: "red" }}
                  >
                    <Column title="Product Name" dataIndex="name" key="name" />
                    <Column title="Item No." dataIndex="itemNo" key="itemNo" />
                    <Column
                      title="Unit Price"
                      dataIndex="price"
                      render={(text) => `Kr.${text}`}
                      key="price"
                    />
                    <Column
                      title="Quantity"
                      dataIndex="quantity"
                      key="quantity"
                    />
                    <Column
                      title="Extra Item Price"
                      dataIndex="extraPrice"
                      render={(text, record) =>
                        `Kr.${record.extraPrice ? record.extraPrice : 0}`
                      }
                      key="extraPrice"
                    />

                    <Column
                      title="Total Price"
                      dataIndex="totalPrice"
                      render={(text, record) =>
                        `Kr.${Number(
                          text + (record.extraPrice ? record.extraPrice : 0)
                        )}`
                      }
                      key="totalPrice"
                    />
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {timeModel()}
      {resionModel()}
    </>
  );
};
export default View;
