import React, { Component } from "react";
import IconGridItem from "../IconGridItem/IconGridItem";
import { Col } from "antd";
import "./IconGrid.css";
import appConfig from "../../helpers/appConfig";

const bgColors = [
  "#359aff",
  "#3caa3c",
  "#20b8bd",
  "#bb214b",
  "#19467f",
  "#dcb016",
  "#696969",
  "#9e82ed",
  "#bb214b",
  "#7cde97",
  "#282928",
  "#f2bb15"
];

class IconGridList extends Component {
  render() {
    if (this.props.products.length < 1) {
      return <React.Fragment />;
    } else {
      const iconGridList = this.props.products.map((product, index) => {
        if (this.props.isBilling === false) {
          var qtyTrack = [];
          return (
            <Col
              className="gutter-row"
              span={6}
              style={
                this.props.category
                  ? { marginBottom: "4.5em" }
                  : { marginBottom: "4.5em" }
              }
            >
              <IconGridItem
                // color={bgColors[index % bgColors.length]}
                category={this.props.category}
                productName={product.name}
                productPrice={product.price}
                // Newly Added sameer
                superCategory={
                  product.categoryId ? product.categoryId.superCategory._id : ""
                }
                sCatType={
                  product.categoryId
                    ? product.categoryId.superCategory.sCatType
                    : ""
                }
                status="IN-PROCESS"
                qtyTrack={qtyTrack}
                // superCategory={product.superCategory._id}
                categoryId={product.categoryId ? product.categoryId._id : ""}
                catType={product.categoryId ? product.categoryId.catType : ""}
                //=== end of newly added
                icon={
                  product.thumbnail
                    ? `${appConfig.s3url}/${product.thumbnail}`
                    : `${appConfig.s3url}/${product.image}`
                }
                id={product._id}
                clicked={(productInfo) => this.props.handleClick(productInfo)}
                ingredents={product.ingredents ? product.ingredents : []}
              />
            </Col>
          );
        } else {
          const type = this.props.type;
          // if (product.catType && product.catType[type] == true) {
          if (
            type === "Take away" ||
            type === "table" ||
            type === "Drive Out" ||
            type === "No Table"
          ) {
            return (
              <Col
                className="gutter-row billing-card"
                span={6}
                // style={
                //   this.props.category
                //     ? { marginBottom: "4.5em" }
                //     : { marginBottom: "4.5em" }
                // }
              >
                <IconGridItem
                  // color={bgColors[index % bgColors.length]}
                  category={this.props.category}
                  productName={product.name}
                  productPrice={product.price}
                  icon={
                    product.thumbnail
                      ? `${appConfig.s3url}/${product.thumbnail}`
                      : `${appConfig.s3url}/${product.image}`
                  }
                  id={product._id}
                  clicked={(productInfo) => this.props.handleClick(productInfo)}
                />
              </Col>
            );
          }
        }
      });
      return iconGridList;
    }
  }
}

export default IconGridList;
