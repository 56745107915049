import React, { useEffect, useState } from "react";
import "./Reports.css";
import TableSum from "../../Table/TableSumX";
import Home from "../../Home";
import PrintReport from "../../../containers/PrintReport/PrintReport";
import moment from "moment";
import {
  dailySummaryColumns,
  dailyDiningColumns,
  summaryData
} from "../../Table/TableColumns";
import { Card, Row, Col } from "react-bootstrap";
import { DatePicker, Button } from "antd";
const List = (props) => {
  let [totalSubtotal, setTotalSubtotal] = useState(0);
  let [totalTax, setTotalTax] = useState(0);
  let [totalDriveoutCharge, setTotalDriveoutCharge] = useState(0);
  let [totalTaxPrice, setTotalTaxPrice] = useState(0);
  let [items, setItems] = useState([]);
  let [sendProps, setSendProps] = useState(false);
  useEffect(() => {
    async function setItemsData() {
      items = await getItems(props.dailyDining);
      setItems(items);
      setTotal(props.dailyDining);
      setSendProps(true);
    }
    setItemsData();
  }, [props.dailyDining]);
  const getItems = (dailyReport) => {
    return dailyReport.invoiceData.length
      ? dailyReport.invoiceData.map((item) => ({
          ...item,
          createdDate: moment(item.created).format("DD/MM/YYYY"),
          // createdDate: new Date(item.created).toLocaleDateString(),
          createdTime: new Date(item.created).toLocaleTimeString(),
          taxPrice: item.taxPrice.toFixed(3),
          isLoaded: true,
          key: item._id
        }))
      : [];
  };
  const setTotal = (dailyReport) => {
    totalSubtotal = 0;
    totalTax = 0;
    totalDriveoutCharge = 0;
    totalTaxPrice = 0;
    dailyReport.invoiceData.length &&
      dailyReport.invoiceData.forEach((current, index) => {
        current.taxPrice = parseFloat(current.taxPrice);
        current.tax = parseFloat(current.tax);
        current.driveoutCharge = parseFloat(current.driveoutCharge || 0);
        current.totalPrice = parseFloat(current.totalPrice || 0);
        totalSubtotal =
          totalSubtotal +
          (current.taxPrice -
            (current.taxPrice -
              (100 * current.taxPrice) / (100 + current.tax)));

        totalTax =
          totalTax +
          (current.taxPrice - (100 * current.taxPrice) / (100 + current.tax));

        if (current.orderType === "Drive Out")
          totalDriveoutCharge =
            totalDriveoutCharge +
            current.driveoutCharge +
            current.extraDriveOut;

        totalTaxPrice = totalTaxPrice + current.totalPrice;
        setTotalSubtotal(totalSubtotal);
        setTotalTax(totalTax);
        setTotalDriveoutCharge(totalDriveoutCharge);
        setTotalTaxPrice(totalTaxPrice);
      });
  };

  const showInvoice = async (invoiceNumber) => {
    let index = await items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    const data = {
      ele: items[index],
      branch: props.dailyDining.branch ? props.dailyDining.branch : {},
      reportName: "Daily Report"
    };
    await props.viewDailyReport(data).then(() => {
      props.setPage("view");
    });
  };
  const Table = () => {
    return (
      <>
        <TableSum
          loading={props.isLoaded}
          columns={dailyDiningColumns(showInvoice)}
          summaryColumns={dailySummaryColumns(
            totalSubtotal,
            totalTax,
            totalDriveoutCharge,
            totalTaxPrice
          )}
          data={items}
          summaryData={summaryData()}
          bordered
        />
        <div style={{ display: "none" }}>
          {sendProps ? (
            <PrintReport
              columns={dailyDiningColumns(showInvoice)}
              summaryColumns={dailySummaryColumns()}
              data={items}
              summaryData={summaryData()}
              branch={props.dailyDining.branch ? props.dailyDining.branch : {}}
              totalTaxPrice={totalTaxPrice}
              totalSubtotal={totalSubtotal}
              totalTax={totalTax}
              type="daily-reports"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Home>
        <div className="report-container">
          <span className="item" style={{ width: "60%" }}>
            <span id="less-visible">Hjem / </span>
            Daily Dining
          </span>
          <span id="less-visible">
            <Button
              onClick={() => props.goBackMainPage()}
              className="ant-btn back-btn"
            >
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </Button>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row className="filter m-b-1 justify-content-center">
              <Col span={12} className="">
                <DatePicker
                  onChange={(date, dateString) => {
                    props.handleDateChange(date);
                  }}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />
              </Col>
              <Col span={12} className="text-right">
                <Button
                  className="ant-btn btn-ab"
                  style={{ background: "#dcbb68" }}
                  onClick={() => props.printReportHandler()}
                >
                  <i
                    className="fa fa-print"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Home>
    </>
  );
};

export default List;
