import React, { useEffect, useState } from "react";
import Layout from "../Home";
import { connect, useDispatch } from "react-redux";
import { getCategory,productData } from "../../action/menuActions";
import { getCatProducts } from "../../action/productsAction";
import appConfig from "../../helpers/appConfig";
import offerIcon from "../../assets/offer-icon.png"
import "./style.css";
import axios from "axios";
import { Col, Button, Row,Badge } from "antd";
import { socket } from "../../utils/socketNew";
import {
  getOnlineOrders,
  getOnlineTableBookingCount,
  getOrderCount,
  getOrderCountTreeDrive,
  getResreveTableCount,
  getOrderCountVendor
} from "../../action/ordersAction";
import {
  playSound,
  subscribeToOrder,
  subscribeToResreveTableCount,
  subscribeToTableOrder,
  subscribeToTreeDriveOrder,
  subscribeToVendorOrder
} from "../../helpers/socket";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  let [pId, setPid] = useState("");
  let [page, setPage] = useState("cat");
  let [catName, setCatName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // props.getCategory();
      props.productData()
      dispatch(getOrderCount());
      dispatch(getOrderCountTreeDrive());
      dispatch(getOnlineTableBookingCount());
      dispatch(getResreveTableCount());
      dispatch(getOrderCountVendor())

      subscribeToOrder(async (err, orders) => {
        dispatch(getOrderCount());
      });
      subscribeToTableOrder(async (err, orders) => {
        dispatch(getOnlineTableBookingCount());
      });
      subscribeToTreeDriveOrder(async (err, orders) => {
        dispatch(getOrderCountTreeDrive());
      
      });
      subscribeToVendorOrder(async (err, orders) => {
        dispatch(getOrderCountVendor());
      });

      subscribeToResreveTableCount(async (error, socketData) => {
        console.log("im called");
        dispatch(getResreveTableCount());
      });
    }
  }, []);
  useEffect(() => {
    if (pId) {
      props.getCatProducts(pId);
    }
  }, [pId]);
  const handleChange = (id, name) => {
    setPid(id);
    setPage("pro");
    setCatName(name);
  };

  const checkOffer=(products)=>{
 

    let isEnable= products.some((product)=>{
    let price=product.price
    let offer=product.offer
    let offerEnable=product.offerEnable
    let offerPrice=offer.offerPrice?offer.offerPrice:price
    let startDate=offer.startDate?offer.startDate:price
    let endDate=offer.endDate?offer.endDate:price
    let now=new Date()

    return offerEnable && (now<= new Date(endDate) && now >= new Date(startDate))
    })

    // console.log("-------isEnable",isEnable)
    return isEnable

  }
  const IconsGridCat = () => {
    const { categories } = props;
    // let catProducts=
    return (
      categories &&
      categories.map((category) => {
        let isCathasOffer=false
        let catProducts=category.products
        if(category.products)isCathasOffer=checkOffer(catProducts)
        let icon = category.thumbnail
          ? `${appConfig.s3url}/${category.thumbnail}`
          : `${appConfig.s3url}/${category.image}`;
        return (
          <Col key={category._id} className="items gutter-row">
            <div className="button-style font">
              <Button
                className="button-size-icon"
                onClick={() => handleChange(category._id, category.name)}
              >
                <div className="menu-options h-100 d-flex flex-column justify-content-between align-items-center">
                {isCathasOffer?  <img src={offerIcon}className="offerIcon" style={{width:"40px"}}/>:""}
                  <img src={icon} className="product-image" />
                  <span className="menu-options name">
                  {category.name.length < 20 ? category.name :
                  category.name.substr(0,20)+ "..."}
                    {/* {category.name} */}
                    </span>
                </div>
                {/* <div className="menu-options details">
                  <span className="menu-options name">{category.name}</span>
                </div> */}
              </Button>
            </div>
          </Col>
        );
      })
    );
  };
  const IconsGridProd = () => {
    const { products } = props;
    const proArry = products.data;

   

    return (
      proArry &&
      proArry.map((product) => {
        if(product){
          // let {price,offer,offerEnable}=product
         
        } let price=product.price
          let offer=product.offer
          let offerEnable=product.offerEnable
          // let{startDate,endDate}=offer;
          let offerPrice=offer.offerPrice?offer.offerPrice:price
          let startDate=offer.startDate?offer.startDate:price
          let endDate=offer.endDate?offer.endDate:price
        
        //  if(offer){
          
        //  }else{
        //  let  offerPrice=price

        //  }
     
        
         let now=new Date()
         let isofferValid= Boolean(now<= new Date(endDate) && now >= new Date(startDate))
        //  if(isofferValid && offerEnable){

        //  }
         console.log("isofferValid",isofferValid)
    console.log("product",product)
        let icon = product.thumbnail
          ? `${appConfig.s3url}/${product.thumbnail}`
          : `${appConfig.s3url}/${product.image}`;
        return (
          <Col key={product._id} className="items gutter-row">
            <div className="button-style">
              <Button className="button-size-icon">
                <div className="menu-options  h-100 d-flex flex-column justify-content-between align-items-center">
                  
                 {offerEnable && isofferValid?  <img src={offerIcon}className="offerIcon" style={{width:"40px"}}/>:""}
                  
                  <img src={icon} className="product-image" />
                  <div className="menu-options details d-flex flex-column justify-content-between align-items-center">
                    <span className="menu-options name">
                      {product.name.length < 20
                        ? product.name
                        : product.name.substr(0, 20) + "..."}
                    </span>
                    <span className="menu-options price">
                      Kr. {offerEnable && isofferValid?

                      <>{offerPrice?offerPrice:price} (<s>{price}</s>)</>
                      
                      :
                      
                      price} 
                    </span>
                  </div>
                </div>
              </Button>
            </div>
          </Col>
        );
      })
    );
  };
  const backBtn = () => {
    if (page === "pro") {
      return (
        <Button className="back-btn" onClick={() => setPage("cat")}>
          <i
            className="fa fa-arrow-circle-left"
            style={{ fontSize: 20, marginTop: 1 }}
            aria-hidden="true"
          />
        </Button>
      );
    }
  };
  return (
    <Layout>
      <div className="report-container text-center">
        <span className="item">
          <span>Hjem / </span>
          {page === "cat" ? "Kategori" : `Produkt: ${catName}`}
        </span>
        {backBtn()}
      </div>
      <Row className="dashboard-flex" type="flex" align="middle" gutter={0}>
        {page === "cat" ? IconsGridCat() : IconsGridProd()}
      </Row>
    </Layout>
  );
};

const mapStateToProps = ({ categories, products }) => {
  return {
    categories,
    products
  };
};
export default connect(mapStateToProps, { getCategory,productData, getCatProducts })(
  Dashboard
);
