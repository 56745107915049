import React, { Component, Fragment } from "react";
import {
  Card,
  Table,
  Button,
  Avatar,
  notification,
  Tag,
  Row,
  Divider
} from "antd";
import "./singleInvoice.css";
import appConfig from "../../helpers/appConfig";
import moment from "moment";
const { Meta } = Card;

const columns = [
  {
    title: "Sr Nr.",
    dataIndex: "no"
  },
  {
    title: "Navn",
    dataIndex: "name"
  },
  {
    title: "QTY",
    dataIndex: "quantity"
  },
  {
    title: "Pris",
    dataIndex: "price"
  },
  {
    title: "Ekstra vare Pris",
    dataIndex: "extraPrice"
  },
  {
    title: "Beløp",
    dataIndex: "amount"
  }
];

const paymentStyle = {
  marginTop: 5,
  marginBottom: 5
};

const gridStyle = {
  flex: "1 0 50%",
  maxWidth: "50%",
  padding: "10px",
  boxShadow: "none",
  border: "none"
};

const fullWidth = {
  flex: "1 0 100%",
  boxShadow: "none",
  border: "none"
};

class SingleInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      onlineOrder: false

    };
    //console.log("brbrbr", this.props.branch);
    this.orderType = "";
    console.log("view report bill data",this.props.data);
    
  }
 componentDidMount(){
    if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == true &&
      this.props.data.isTableOnline == true
    ) {
      this.setState({ onlineOrder: true });
      this.orderType = "Online Order";
    console.log(this.state.onlineOrder);
    } else if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == true &&
      this.props.data.isTableOnline == false
    ) {
      this.setState({ onlineOrder: true });
      this.orderType = "Table";
    } else if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == false
    ) {
      this.orderType = "Paid By Faktura";
  }else{
  this.orderType="";
  }
 }
  markPaid = (id) => {
    const hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/invoice/${id}`, {
      method: "PUT",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.message);
          } else {
            this.openNotification("success", result.message);
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  printbill = () => {
    window.print();
  };

  render() {
    const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
    const branchLogo = localStorage.getItem("logo");
    const { data } = this.props;
    const { customerId, isPaid, _id } = this.props.data;
    const { name, address, taxNo, phoneNo, bEmail, accountNumber } = branchInfo;
    const table = data.items;
    let discounTd = data.tdDiscountAbs
      ? data.tdDiscountAbs
      : data.tdDiscountPickUpAbs
      ? data.tdDiscountPickUpAbs
      : 0;
   let tdTotal;
    let tableData = table.map((item, index) => {
      let ext = item.extraPrice ? item.extraPrice : 0;
     
      return {
        no: index + 1,
        name: item.productName,
        quantity: item.quantity,
        price:`Kr.${item.productPrice}`,
        extraPrice: `Kr.${ext}`,
        amount: `Kr. ${Number(item.quantity * item.productPrice + ext).toFixed(
          2
        )}`
      };
    });
    console.log("tdTotal======>",tdTotal);
    return (
      <div
        id="singleinvoice"
        style={{
          margin: "10px auto",
          borderWidth: 1,
          borderColor: "green"
        }}
        className="single-invoice"
      >
        <Card className="p-3">
          <Row>
            <div
              id="printbtn"
              className="d-flex align-items-center justify-content-between"
            >
              <Meta
                avatar={
                  <Avatar
                    src={
                      branchLogo === null
                        ? ""
                        : `${appConfig.s3url}/${branchLogo}`
                    }
                  />
                }
                title={name}
              />
            <Button
              onClick={this.printbill}
              style={{
                background: "transparent",
                color: "black",
                border: "1px solid grey",
                float: "right",
                marginLeft: "120px"
              }}
            >
              Skrive ut
            </Button>
          </div>
          </Row>
          <Divider />
          <Row style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Card.Grid style={gridStyle}>
              {address && (
                <>
                  <p>
                    {address.line1 ? address.lin1 : ""}
                    {address.line2 ? " " + address.line2 : ""}
                  </p>
                  <p>
                    {address.city ? address.city : ""}
                    {address.pin ? "," + " " + address.pin : ""}
                    {address.state ? "," + " " + address.state : ""}
                  </p>
                  <p>
                    <b>Mva Nr.</b>: {taxNo}
                  </p>
                  {!isPaid ? (
                    <p>
                      <b>Regnskap Nr. </b>: {accountNumber}
                    </p>
                  ) : null}
                  <p>
                    <b>Telefon Nr.</b>: {phoneNo}
                  </p>
                  <p>
                    <b>E-post</b>: {bEmail}
                  </p>
                </>
              )}
            </Card.Grid>
            <Card.Grid style={{ ...gridStyle, textAlign: "right" }}>
              <p>
                <b>Faktura Nr.</b>: {data.invoiceNumber}
              </p>
              <p>
                <b>Dato</b>:{" "}
                {data.createdDate
                  ? moment(data.createdDate).format("DD/MM/YYYY")
                  : moment(new Date(data.created).format("DD/MM/YYYY"))}
              </p>
              <p>
                <b>Tid</b>:{" "}
                {data.createdTime
                  ? data.createdTime
                  : new Date(data.created).toLocaleTimeString()}
              </p>
              <p>
                <b>Bestillingstype</b>: {data.orderType}
              </p>
            </Card.Grid>
            <hr />
            {console.log(
              "this.props.data.isAppOrder======>",
              this.props.data.isAppOrder
            )}
            {customerId ? (
              <Card.Grid style={gridStyle}>
                <h3>Kundedetaljer :</h3>
                <hr />
                <p>
                  <b>Name:</b> &nbsp;{customerId.firstName}{" "}
                  {customerId.lastName}
                </p>

                <p>
                  {" "}
                  <b>Address:</b> &nbsp;{customerId.address.line1} ,{" "}
                  {customerId.address.line2}
                </p>

                {customerId.address.line1 ? (
                  <>
                  <p>
                      <b>City:</b> &nbsp;{customerId.address.city}{" "}
                    </p>
                    <p>
                      <b>State:</b> &nbsp;
                    {customerId.address.state
                      ? "," + customerId.address.state
                      : ""}
                  </p>
                    <p>
                      <b>PIN:</b> &nbsp;
                      {customerId.address.pin
                        ? "," + customerId.address.pin
                        : ""}{" "}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </Card.Grid>
            ) : !data.isAppOrder ? (
              <h4 style={{ marginLeft: 10, fontWeight: "bold", color: "grey" }}>
                No Customer Details
              </h4>
            ):(
              <h4 style={{ marginLeft: 10, fontWeight: "bold", color: "grey" }}>
                Tree Drive Order
            </h4>
            )}
            {customerId? (
              <Card.Grid
                style={{
                  ...gridStyle,
                  flex: "auto",
                  marginTop: 15,
                  textAlign: "right"
                }}
              >
                <p>
                  <b>Telefon:</b> {customerId.phoneNo}
                </p>
                <p>
                  <b>e-post:</b> {customerId.email}
                </p>
                <p>
                  <b>Referanse:</b> {data.reference}
                </p>
              </Card.Grid>
            ) : (
              ""
            )}
            <hr />
            <Table
              bordered
              id="producttab"
              style={{ ...fullWidth, marginTop: 20 }}
              columns={columns}
              dataSource={tableData}
              size="middle"
              pagination={false}
            />
            <Card.Grid
              style={{
                ...fullWidth,
                textAlign: "right",
                paddingRight: "0 40px",
                lineHeight: 1.65
              }}
            >
              <p>
                <span style={{ fontWeight: 700 }}>Sub-Totalt: </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {console.log("this.props.data =======>",this.props.data)}
                  {/* {`Kr. ${(
                    data.taxPrice -
                    (
                      data.taxPrice -
                      (100 * data.taxPrice) / (100 + data.tax)
                    ).toFixed(3)
                  ).toFixed(2)}`} */}
                  {`Kr. ${
                    data.isAppOrder 
                      && data.orderType == "reserveTable"
                      ? ((data.taxPriceInc - discounTd) / 1.25).toFixed(2)
                      :
                    data.isAppOrder 
                    ? ((data.taxPriceInc - discounTd) / 1.15).toFixed(2)
                      :
                    data.isAppOrder
                      ? ((data.taxPriceInc - discounTd) / 1.15).toFixed(2)
                      : (
                    data.taxPrice -
                    (
                      data.taxPrice -
                      (100 * data.taxPrice) / (100 + data.tax)
                    ).toFixed(3)
                        ).toFixed(2)
                  }`}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 700 }}>
                  Mva(
                  {`${data.tax.toFixed(2)}%`}
                  ):{" "}
                </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {`Kr. ${
                  data.isAppOrder 
                        && data.orderType == "reserveTable"
                      ? (
                          data.taxPriceInc -
                          discounTd -
                        ((data.taxPriceInc - discounTd) / 1.25).toFixed(2)
                          ).toFixed(2)
                      :
                    data.isAppOrder
                      ? (
                          data.taxPriceInc -
                          discounTd -
                          ((data.taxPriceInc - discounTd) / 1.15).toFixed(2)
                        ).toFixed(2)
                      : (
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                        ).toFixed(2)
                  }`}
                  {/* {`Kr. ${(
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                  ).toFixed(2)}`} */}
                </span>
              </p>
              {data.tdDiscountPickUp ? (
                <>
                  <p>
                    <span style={{ fontWeight: 700 }}>
                      Rabatt ({`${data.tdDiscountPickUp}%`}) :
                    </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {`- Kr. ${Number(
                        data.taxPrice * (data.tdDiscountPickUp / 100)
                      ).toFixed(2)}`}
                    </span>
                  </p>
                  {/* <p>
                    <span style={{ fontWeight: 700 }}>Service Charges Tax</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {(data.orcFeesFromRestaurant - (data.orcFeesFromRestaurant
                        ? data.orcFeesFromRestaurant / 1.25
                        : 0)).toFixed(2)}
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 700 }}>Service Charges</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {(data.orcFeesFromRestaurant
                        ? data.orcFeesFromRestaurant / 1.25
                        : 0).toFixed(2)}
                    </span>
                  </p> */}
                </>
              ) : (
                <>
                  <p>
                <span style={{ fontWeight: 700 }}>
                      {/* Rabatt (
                  {data.discountType === "percent" ? `${data.discount}%` : "0%"}
                  ) : */}
                  Rabatt (
                      {data.isAppOrder
                        ? `${data.tdDiscount}%`
                        : `${data.discount}`}
                  ) :
                </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {/* {`- Kr. ${(
                    (100 * Number(data.taxPrice)) / (100 - data.discount) -
                    data.taxPrice
                  ).toFixed(2)}`} */}
                      {`- Kr. ${
                        data.isAppOrder
                          ? `${data.tdDiscountAbs}`
                          : `${
                              (100 * Number(data.taxPrice)) /
                                (100 - data.discount) -
                    data.taxPrice
                            }`
                      }`}
                    </span>
                  </p>
                  {data.isAppOrder &&  data.tdServiceCharge > 0 ? (
                   <>
                   <p>
                      <span style={{ fontWeight: 700 }}>Service Charges Tax :</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {`Kr. ${(data.tdServiceCharge - (data.tdServiceCharge / 1.25)).toFixed(2) }`}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 700 }}>Service Charges :</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {`Kr. ${(data.tdServiceCharge / 1.25).toFixed(2)}`}
                      </span>
                    </p>
                  </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {data.flatDiscount && data.flatDiscount !== 0 ? (
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Flat Rabatt (kr.{data.flatDiscount}) :
                  </span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {`-
                    Kr. ${Number(data.flatDiscount).toFixed(3)}`}
                  </span>
                </p>
              ) : null}

              {data.orderType == "Drive Out" ? (
                
                <p>
                  <p>
                    {" "}
                    {/* <span style={{ fontWeight: 700 }}>Drive Out Tax (25%)</span> */}
                    <span style={{ fontWeight: 700 }}>Drive Out Tax</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.isAppOrder ? (
                        <>+ Kr. {( data.tdDriveOutCharge - (data.tdDriveOutCharge / 1.25)).toFixed(2)}</>
                      ) : (
                        <>
                          + Kr.{" "}
                          {(
                            (data.driveoutCharge + (data.extraDriveOut ? data.extraDriveOut : 0)) - 
                            ( (100 * (data.driveoutCharge + data.extraDriveOut)) / 125)  
                          ).toFixed(2)}
                        </>
                      )}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ fontWeight: 700 }}>Drive Out Charges</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.isAppOrder ? (
                        <>+ Kr. {(data.tdDriveOutCharge / 1.25).toFixed(2)}</>
                      ) : (
                        <>
                          + Kr.{" "}
                          {(
                        (100 * (data.driveoutCharge + data.extraDriveOut)) /
                        125
                          ).toFixed(2)}
                        </>
                      )}
                    </span>
                  </p>
                  <span style={{ fontWeight: 700 }}>Totalt:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {`Kr.${parseFloat(data.totalPrice).toFixed(2)}`}
                  </span>
                </p>
              ) : (
                <span>
                  <span style={{ fontWeight: 700 }}>Totalt:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {/* {`Kr. ${parseFloat(data.taxPrice).toFixed(2)}`} */}
                    {Number(data.totalPrice).toFixed(2)}
                  </span>
                </span>
              )}

              <br />
              {!this.state.onlineOrder ? (
                <>
                  <span style={paymentStyle}>
                <span style={{ fontWeight: 700 }}>CASH:</span>

                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                  <Tag
                    color={data.cash !== 0 ? "#87d068" : "grey"}
                  >{`Kr.${parseFloat(data.cash).toFixed(2)}`}</Tag>
                </span>
              </span>
              <br />
              <span style={paymentStyle}>
                <span style={{ fontWeight: 700 }}>BANK:</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                  <Tag
                    color={data.bank !== 0 ? "#87d068" : "grey"}
                  >{`Kr.${parseFloat(data.bank).toFixed(2)}`}</Tag>
                </span>
                  </span>
                </>
              ) : (
                ""
                // <strong>{this.orderType}</strong>
              )}
              <br />
              {data.coupon>0 && (
                <span style={paymentStyle}>
                  <span style={{ fontWeight: 700 }}>COUPON:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    <Tag
                      color={data.coupon !== 0 ? "#87d068" : "grey"}
                    >{`Kr.${parseFloat(data.coupon).toFixed(2)}`}</Tag>
                  </span>
                </span>
              )}
              <br />
              <span>
                {data.transaction && data.transaction.vendorName=="Vipps"? (
                  <>
                    <br />
                    <h4>
                      <b>Paid With Vipps</b>
                    </h4>
                    
                  </>
                ) : (
                  data.transaction.card && (
                  <>
                    <br />
                    <h4>
                      <b>Card Details</b>
                    </h4>
                    <p>
                      <span style={{ fontWeight: 700 }}>Card:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.masked_pan}
                      </span>
                      <br />
                      <span style={{ fontWeight: 700 }}>Card Type:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.brand}
                      </span>
                      <br />
                      <span style={{ fontWeight: 700 }}>Expiry Date:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.expiry_date}
                      </span>
                        <br />
                        {data.transaction.card.approvedCode?  <>
                          <span style={{ fontWeight: 700 }}>Approved code : </span>
                        <span
                          style={{ minWidth: "100px", display: "inline-block" }}
                        >
                          {data.transaction.card.approvedCode?data.transaction.card.approvedCode:""}
                        </span>
                        </>:""}
                    </p>
                  </>
                  )
                )}
              </span>
              <span>
                {isPaid ? (
                  ""
                ) : (
                  <Button
                    id="markpaidbtn"
                    style={{ margin: "20px 0" ,background: "green"}}
                    type="danger"
                    onClick={() => this.markPaid(_id)}
                  >
                    Ubetalt
                  </Button>
                )}
                {/* <Button
                  id="markrevertbtn"
                  style={{ margin: "20px 10px" }}
                  type="danger"
                  title="REVERT INVOICE"
                  onClick={() => this.markPaid(_id)}
                /> */}
              </span>
              <br />
              <span></span>
            </Card.Grid>
          </Row>
        </Card>
      </div>
    );
  }
}

export default SingleInvoice;
