import React , {useState , useEffect} from 'react'
import Home from '../../Home'
import { Row, Col, Button,Card, DatePicker, Table, Statistic } from "antd";
import history from '../../../history';
import moment from "moment";
import { POSTAPI } from '../AllApi';
import appConfig from '../../../helpers/appConfig';
import './dailyDeposit.css'
const bid = localStorage.getItem("bid");
const { MonthPicker , WeekPicker} = DatePicker;

const dailyDeposit = () => {

  const [dailyDeposit , setDailyDeposit ] = useState([]);
  const [reportType , setReportType ] = useState("");
  const [sDate , setSDate ] = useState("");
  const [eDate , setEDate ] = useState("");
  const [currDate , setCurrDate ] = useState("");
  const [obj , setObj ] = useState("");
  const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
  const restName = branchInfo.name


  useEffect(() => {
    //setLoading(true);
    filterData(moment(new Date()), "d");
  }, []);
    
    useEffect(()=>{
      console.log("daily deposit dat=============", dailyDeposit)
    },[dailyDeposit])

    const getDailyInvoices = async (data) => {
      try {
        let resData = await POSTAPI(
          `${appConfig.endpoint}/api/v1/vendor/invoices/getDailyInvoices`,
          data
        );
        if(resData.data){
          setDailyDeposit(resData.data);
          // setObj(resData.data)
        }

      } catch (error) {
        setDailyDeposit([]);
        console.log(error);
      }
    };


    const printbill = () => {
      window.print();
    };


    const goBackMainPage = () => {
      history.push("/vendor");
    };

  
console.log("length of daily deposit ===== ", dailyDeposit.length)
    useEffect(()=>{
      console.log("date ===========" , sDate , eDate , currDate)
      let dd = {
        startDate: sDate,
        endDate: eDate,
        branchId: bid
      }

      //getDailyInvoices(dd);
    },[sDate , eDate , currDate])

   
    const filterData = (date, type) => {
      let postdata = {};
      let day  = ""; 
      day = type ; 
      // console.log("type==========" , day )
    
        switch (type) {
          case "m":
            postdata = {
              startDate: moment(date).startOf("month").add(1, "days"),
              endDate: moment(date).endOf("month").add(1, "days"),
            branchId: bid,
            };
            break;
            case "w":
              postdata = {
                startDate :moment(date).startOf("week").add(2, "days"),
                endDate : moment(date).endOf("week").add(2, "days"),
                branchId: bid,
              };
              break;
          case "d":
            postdata = {
              startDate: moment(date).add(1, "days"),
              endDate: moment(date).add(1, "days"),
              branchId: bid,
            };
        }

        setReportType(day);
        setSDate(postdata.startDate.subtract(1, "days").format("DD MMMM, YYYY"));
        setEDate(postdata.endDate.format("DD MMMM, YYYY "));
        setCurrDate(`${moment(date).format("DD MMMM, YYYY")}`);

        getDailyInvoices(postdata);
        
        // setSDate(`${moment(postdata.startDate).format("DD MMMM, YYYY HH:mm:ss")}`);
        // setEDate(`${moment(postdata.endDate).format("DD MMMM, YYYY HH:mm:ss")}`);
        
      };

      let OPPGJØRTax = dailyDeposit.netAmount * 0.25;
      let OPPGJØRTotal = dailyDeposit.netAmount * 0.25 + dailyDeposit.netAmount;
      let tdOPPGJØRTax = dailyDeposit.TdExtraTotal - ( dailyDeposit.TdExtraTotal / 1.25);
      let tdOPPGJØRTotal = dailyDeposit.TdExtraTotal / 1.25;
      let refundResult = dailyDeposit.refundResult?dailyDeposit.refundResult:[];  

      console.log("data ==== ",OPPGJØRTax , OPPGJØRTotal , tdOPPGJØRTax , refundResult)

return (
    <Home>
    <Row className="m-b-1">
    <Col>
    <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
            <span id="less-visible">Rapport / </span>
            Daily deposit
        </span>
        <span id="less-visible">
            <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
            >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </Button>
        </span>
    </div>
    <div className="d-flex justify-content-evenly">
            <DatePicker onChange={(e) => filterData(e, "d")} />
            <WeekPicker
            onChange={(e) => filterData(e, "w")}
            placeholder="Select week"
          />
            <MonthPicker
                placeholder="Select Month"
                onChange={(e) => filterData(e, "m")} />
            <div className='justify-content-end
            '>
            <Button
                  onClick={() => printbill()}
                  type="primary"
                  size="large"
                  className="btn-ab  text-color tablePrint"
            >
          <i
              className="fa fa-print"
              style={{ fontSize: 20 }}
              aria-hidden="true"
          />
      </Button>
            </div>
      </div>
      {
      Object.keys(dailyDeposit).length === 0 ? 
      <div className='pt-5'>
      <p className="text-center noDeposit">No Files Found!</p>
      </div>
      :
      <>
      <div className="report-div ml-5 justify-content-between">
      <p className='report-heading'>Date : {currDate} </p>
      <div className='d-flex  mr-5 justify-content-between'>
      <span className='venName'>RESTAURANT : {restName}</span>
      <span className='venName'>Oppgjør nr: </span>
      <span className='venName'>ACC: {dailyDeposit.accNo}</span>
      </div>
      <p className='venName'>Tax No.{dailyDeposit.taxNo}</p>
      {/* <p className='venName'>TOTAL SALE: </p> */}
      </div>
      <div className="d-flex report-div">
      {
        reportType == "d" ? 
        <p className='report-heading'>DAILY VENDOR SALES REPORT</p> 
        : ""
      }
      {
        reportType == "w" ? 
        <p className='report-heading'>WEEKLY VENDOR SALES REPORT</p> 
        : ""
      }
      {
        reportType == "m" ? 
        <p className='report-heading'>MONTHLY VENDOR SALES REPORT</p> 
        : ""
      }
        </div>
        <div className="d-flex report-time">
        <p className='report-heading'>{sDate} 5:00 A.M to {eDate} 4:59 A.M</p>
        </div>
        <div className="justify-content-center d-flex m-3 ">
      <>
      {console.log("dailyyyyyyy =========== " ,  Object.keys(dailyDeposit).length )}
      <table className='dailyTable'  id="dailyTablePrint">
      {/* <tr className='dailyRow' >
                    <td className='dailyHeader'>Sale Type</td>
                    <td className='dailyHeader'> </td>
                    <td className='dailyHeader'>Totall(Kr.)</td>
                </tr> */}
                          <>
                      <tr className='dailyRow' >
                            <td className='dailyData'>TOTAL SALES</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.totalSale}</td>
                        </tr>
                      <tr className='dailyRow' >
                            <td className='dailyData'>Web Sales</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.webSale}</td>
                        </tr>
                      
                      <tr className='dailyRow' >
                            <td className='dailyData'>TD Sales</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.tdSale}</td>
                        </tr>
                        {/* <tr className='dailyRow' key={key}>
                            <td className='dailyData'>Machine Card Sales</td>
                            <td className='dailyData' >{val.machCardTotal}</td>
                        </tr>
                        <tr className='dailyRow' key={key}>
                            <td className='dailyData'>Machine Cash Sales</td>
                            <td className='dailyData' >{val.machCashTotal}</td>
                        </tr>
                        <tr className='dailyRow' key={key}>
                            <td className='dailyData'>Machine Coupon Sales</td>
                            <td className='dailyData' >{val.machCouponTotal}</td>
                        </tr>
                        <tr className='dailyRow' key={key}>
                            <td className='dailyData'>Machine Vipps Sales</td>
                            <td className='dailyData' >{val.machVippsTotal}</td>
                        </tr> */}
                        <tr className='dailyRow'>
                            <td className='dailyMainData'>OPPGJØR ORC</td>
                            
                        </tr>
                        <tr className='dailyRow'>
                            <td className='dailyHeader'>Net Amount(Kr.) </td>
                            <td className='dailyHeader' >Tax(Kr.)</td>
                            <td className='dailyHeader' >Totall(Kr.)</td>
                        </tr>
                        <tr className='dailyRow' >
                            <td className='dailyMainData' >{Number(dailyDeposit.netAmount).toFixed(2)}</td>
                            <td className='dailyMainData' >{Number(OPPGJØRTax).toFixed(2)}</td>
                            <td className='dailyMainData' >{Number(OPPGJØRTotal).toFixed(2)}</td>
                        </tr>
                  
                        <tr className='dailyRow'>
                            <td className='dailyData'>TD Driverout Charges</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.tdDriverCharges}</td>
                        </tr>
                        <tr className='dailyRow' >
                            <td className='dailyData'>TD Enhance Price</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.tdIncPriceDiff}</td>
                        </tr>
                        <tr className='dailyRow'>
                            <td className='dailyData'>TD Service Charges</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.tdServiceCharges}</td>
                        </tr>
                        {/* <tr className='dailyRow' key={key}>
                            <td className='dailyData'>Machine Total Price</td>
                            <td className='dailyData' >{val.machTotalPrice}</td>
                        </tr> */}

                        <tr className='dailyRow'>
                            <td className='dailyMainData'>TD OPPGJØR </td>
                            
                        </tr>
                        <tr className='dailyRow'>
                            <td className='dailyHeader'>Net Amount(Kr.) </td>
                            <td className='dailyHeader' >Tax(Kr.)</td>
                            <td className='dailyHeader' >Totall(Kr.)</td>
                        </tr>
                        <tr className='dailyRow'>
                            <td className='dailyMainData' >{Number(tdOPPGJØRTotal).toFixed(2)}</td>
                            <td className='dailyMainData' >{Number(tdOPPGJØRTax).toFixed(2)}</td>
                            <td className='dailyMainData' >{dailyDeposit.TdExtraTotal}</td>
                        </tr>

                        <tr className='dailyRow'>
                            <td className='dailyMainData'>Balance C/F</td>
                            <td className='dailyData'></td>

                            <td className='dailyMainData' >{Number((dailyDeposit.totalCommision - OPPGJØRTotal) - dailyDeposit.TdExtraTotal).toFixed(2)}</td>
                        </tr>
                        <tr className='dailyRow'>
                            <td className='dailyData'>Total Commision</td>
                            <td className='dailyData'></td>
                            <td className='dailyData' >{Number(dailyDeposit.totalCommision).toFixed(2) }</td>
                        </tr>

                        <tr className='dailyRow'>
                            <td className='dailyTotalData'>Total OPPGJØR </td>
                            <td className='dailyData'></td> 
                            <td className='dailyTotalData'>{ Number(dailyDeposit.alreadyReceived - dailyDeposit.totalCommision).toFixed(2)}</td> 
                        </tr>   
                        {refundResult.length ?
                        <>
                        <tr className='dailyRow'>
                            <th className='dailyHeader'>REFUND DATA</th>
                        </tr>
                          <tr className='dailyRow' >
                            <td className='dailyHeader'>Vendor</td>
                            <td className='dailyHeader'>Customer Details</td> 
                            <td className='dailyHeader'>Total</td>
                        </tr>   
                        </> 
                        : ''}
                        {
                          refundResult.map((e ,key) =>{
                            return (
                              <>                            
                          <tr className='dailyRow' key={key}>
                            <td className='dailyData'>{e.paymentMode}</td>
                            <td className='dailyData'>{e.cardDetails ? e.cardDetails : e.custPhone}</td> 
                            <td className='dailyData'>{e.totalPrice
                            }</td>
                        </tr>  
                        </>
                          )})
                        }  
                        
                        <tr className='dailyRow'>
                            <td className='dailyData'>Total Refund</td> 
                            <td className='dailyData'></td>
                            <td className='dailyData' >{dailyDeposit.totalRefund}</td>
                        </tr>
                      </>
              
                    
            </table>
      </>
      </div>
      </>
      }
      
    </Col>
    </Row>

    </Home>
)
}

export default dailyDeposit
