import React from "react";
import Home from "../../Home";
import { NavLink } from "react-router-dom";
import ReportTable from "./ReportTable";
const MonthReport = () => {
  return (
    <Home>
      <div className="report-container">
        <span className="item ">
          <span>Arkiv : </span>Månedlig Rapport
          <NavLink to="/arkiv" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
        </span>
      </div>
      <ReportTable page="month"/>
    </Home>
  );
};
export default MonthReport;
