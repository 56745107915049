import { SUCCESS_MESSAGE, EMPTY_SUCCESS_MESSAGE } from "../action/types";
let initialState = {
  message: "",
  data: {}
};
const successMassageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        data: { ...action.payload.data, actionType: action.payload.actionType }
      };
    case EMPTY_SUCCESS_MESSAGE:
      return {
        ...state,
        message: action.payload
      };
    default:
      return state;
  }
};
export default successMassageReducer;
