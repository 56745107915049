import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Table } from "antd";
import './table.css';
// import "./TableSum.css";

const TableSum = (props) => {
  const myRef = useRef();
  useEffect(() => {
    //! Antd table hack, try not to mess it up...
    const dom = myRef.current;
    const table = dom.querySelectorAll(".ant-table-body");
    // let l = table[0];
    // l.style.overflowX = "hidden";
    // let r = table[1];
    // r.addEventListener("scroll", function () {
    //   l.scrollLeft = r.scrollLeft;
    // });
  }, []);
  return (
    <div ref={myRef}>
      <Table 
        loading={props.loading}
        columns={props.columns}
        pagination={ {position:"top"}}
        dataSource={props.data}
        scroll={props.scroll}
        bordered={props.bordered}
        id ={props.id}
        title={
          props.data.length > 0
            ? () => (
                <div>
                  <Table
                  bordered
                    columns={props.summaryColumns}
                    dataSource={props.summaryData}
                    pagination={false}
                    showHeader={false}
                    scroll={props.scroll}
                    rowClassName="highlighted-bg"
                    rowKey={props.rowKey}
                  />
                </div>
              )
            : () => (
                <div style={{ display: "none" }}>
                  <Table
                    columns={props.summaryColumns}
                    dataSource={props.summaryData}
                    pagination={false}
                    showHeader={false}
                    scroll={props.scroll}
                    rowClassName="highlighted-bg"
                    rowKey={props.rowKey}
                  />
                </div>
              )
        }
        className="ant-table-body"
      />
    </div>
  );
};

export default TableSum;
