import React, { Component, useEffect } from "react";
import "./assets/css/bootstrap.min.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.css";
import { createBrowserHistory } from "history";
import createStore from "./reducers/create";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import ApiClient from "./helpers/ApiClient";
import { Provider } from "react-redux";
import Main from "./Main";
import {socket} from './utils/socketNew'
import notificationFile from './assets/plucky.mp3';
const history = createBrowserHistory();
const client = new ApiClient();
const store = createStore(history, client, window.__INITIAL_STATE__,+  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
if (localStorage.token) {
  setAuthorizationToken(localStorage.token);
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Main history={history} />
      </Provider>
    );
  }
}

export default App;
