import React, { useState, useEffect } from "react";
import Home from "../../../Home";
import { Icon as RIcon } from "react-icons-kit";
import { bank } from "react-icons-kit/fa/bank";
import { dollar } from "react-icons-kit/fa/dollar";
import moment from "moment";
import history from "../../../../history";
import { Row, Col, Button, DatePicker, Table, Statistic } from "antd";
import appConfig from "../../../../helpers/appConfig";
import { POSTAPI, GETAllAPI } from "../../AllApi";
import { Card } from "react-bootstrap";
import "./ZRepport.css";
import { record } from "react-icons-kit/iconic";
const { MonthPicker } = DatePicker;

const ZRepport = () => {
  const [reportData, setRepportData] = useState([]);
  const [discountData, setDiscountData] = useState([]);
  const [taxArr, setTaxArr] = useState([]);
  const [ serviceData , setServiceData] = useState([]);
  const [ driveoutData , setDriveoutData] = useState([]);
  const [product, setProduct] = useState({});
  const [onlineDate, setOnlineDate] = useState("");
  const [loading, setLoading] = useState(false);
  const bid = localStorage.getItem("bid");

  const getZRepport = async (data) => {
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/vendor/invoices/getZReportVendor`,
        data
      );

      if (resData.data) {
        console.log("found data ===");
        setRepportData(resData.data);
        setTaxArr(resData.data.taxArr);
        setServiceData([resData.data.totalServiceCharge])
        setDiscountData(resData.data.discountData);
        setDriveoutData([resData.data.totalDriveoutCharge])
        setLoading(false);
      } else {
        console.log("no found data ===");
        setRepportData([]);
        setTaxArr([]);
        setServiceData([])
      }


      // console.log("====get z-repport ===", resData.data.taxArr)
    } catch (error) {
      console.log(error);
      setRepportData([]);
      setTaxArr([]);
      setDiscountData([]);
      setServiceData([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    filterData(moment(new Date()), "d");
  }, []);

  const filterData = (date, type) => {
    let postdata = {};

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days"),
          branchId: bid,
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days"),
          branchId: bid,
        };
    }
    getZRepport(postdata);
    console.log(postdata);
  };

  const goBackMainPage = () => {
    history.push("/vendor");
  };
let totalQnty = 0;
  let data = taxArr.map((e) => {
    totalQnty += e.totalQuantity
  });

  //calculate totalDiscount price
  let totalDiscountPrice = 0;
  discountData.map((e) => {
    totalDiscountPrice += e.discountAbs;
  })

  // console.log("servicee============", serviceData)
  // console.log("taxx=============", taxArr)

  const ReportColumns = (tax) => [
    {
      title: "Product",
      width: "18%",
      key: "name",
      dataIndex: "prodName",
    },
    {
      title: "Total Quantity",
      width: "15%",
      key: "quantity",
      render: (text, record) => {
        return record.availQuantity ? 
        `${record.quantity}pkt X ${record.availQuantity}${record.unitType}`:
        `${record.quantity}`
      },
    },
    {
      title: `Mva ${tax}%`,
      width: "20%",
      key: "prodTax",
      render: (text, record) => {
        return `Kr.${(
          parseFloat(record.totalPrice) -
          parseFloat(record.totalPrice) / parseFloat((100 + Number(tax)) / 100)
        ).toFixed(2)}`;
      },
    },
    {
      title: "Net Sum(Kr.)",
      width: "20%",
      render: (text, record) => {
        return `Kr.${(
          parseFloat(record.totalPrice) -
          (parseFloat(record.totalPrice) -
            parseFloat(record.totalPrice) /
            parseFloat((100 + Number(tax)) / 100))
        ).toFixed(2)}`;
      },
    },
    {
      title: `Total(Kr.) `,
      width: "30%",
      key: "name",
      render: (text, record) => {
        return `Kr.${record.totalPrice.toFixed(2)}`;
      },
    },
  ];

 
let arr =[];
  let datta = {};
  datta.report = reportData;
  arr.push(datta)

  console.log("reportttttttttt", arr)

  const discountColumns = () => [
    {
      title: "Invoice No. ",
      width: "20%",
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Discount(%)",
      width: "20%",
      key: "discount",
      dataIndex: "discount",
    },
    {
      title: "Discount Price",
      width: "20%",
      key: "discountPrice",
      // dataIndex: 'discountAbs.toFixed(2)',
      render: (text, record) =>
      record.discountAbs.toFixed(2)
    },
  ];



  const ReportSummaryData = [
    {
      title: "taxArr",
    },
  ];

  const ReportSummary = (
    totalQuantity,
    totalTaxAmount,
    taxvalue,
    totalPrice
  ) => [
      {
        title: "Product",
        width: "18%",
        render: () => <strong>Total</strong>,
      },
      {
        title: "Quantity",
        width: "15%",
        key: "totalQuantity",
        render: () => `${totalQuantity} pkts.` ,
      },
      {
        title: `Mva ${taxvalue}%`,
        width: "20%",
        key: "prodTax",
        render: () => totalTaxAmount.toFixed(2),
      },
      {
        title: "Net Sum(Kr.)",
        width: "20%",
        render: () => (totalPrice - totalTaxAmount).toFixed(2),
      },
      {
        title: `Total(Kr.) `,
        width: "30%",
        key: "name",
        render: () => `Kr.${totalPrice.toFixed(2)}`,
      },
    ];


  const discountSummaryData = [
    {
      title: "discountData",
    },
  ];
  const discountSummary = (totalDiscountPrice) => [
    {
      title: "Invoice No. ",
      width: "65%",
      key: "invoiceNumber",
      render: () => <strong>Total</strong>
    },
    {
      title: "Discount Price",
      key: "discountPrice",
      render: () => totalDiscountPrice.toFixed(2)

    },
  ];

  console.log("service charge ======", reportData)


  // console.log('ggggggggggggggggggggjh',totalServiceChrge)

  const serviceColumn = () => [
    {
      title: "MVA 25%",
      width: "20%",
      render: () => serviceData *(25/100)
    },, {
      title: "Nett Sum",
      width: "20%",
      render: () => serviceData - serviceData *(25/100)
    }, {
      title: "Total Service Charge",
      width: "20%",
    },
  ];
  const driveoutColumn = () => [
    {
      title: "Total Orders",
      width: "20%",
      render: () => totalQnty
    },
    {
      title: "Total Driveout Charges",
      width: "20%",
      render: () => driveoutData
    }
  ];
  const printbill = () => {
    window.print();
  };

  return (
    <div>
      <Home>
        <Row className="m-b-1">
          <Col>
            <div className="report-container">
              <span className="item" style={{ width: "60%" }}>
                <span id="less-visible">Vendor : </span>Z - Report
              </span>
              <span id="less-visible">
                <Button
                  onClick={() => goBackMainPage()}
                  className="ant-btn back-btn"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                </Button>
              </span>
            </div>
            <div className="d-flex">
              <DatePicker onChange={(e) => filterData(e, "d")} />
              <MonthPicker
                placeholder="Select Month"
                onChange={(e) => filterData(e, "m")}
              />
              {/* <div id="printbtn" style={{ paddingLeft: "500px" }}>
                <Button
                  onClick={() => printbill()}
                  type="primary"
                  size="large"
                  className="btn-ab  text-color printt-btn tablePrint"
                >
                  <i
                    className="fa fa-print"
                    style={{ fontSize: 20 }}
                    aria-hidden="true"
                  />
                </Button>
              </div> */}
            </div>
          </Col>
        </Row>
        <Row gutter={[32, 48]} id="tablePrint"  className="TablePrint">
          <Col span={12}>
            <Card className="z-box-shadow">
              <Card.Body>
                <Statistic
                  title="CARD"
                  precision={2}
                  valueStyle={{ color: "#3f8600", fontSize: 30 }}
                  prefix={<RIcon size={48} icon={bank} />}
                  value={reportData.cardTotal ? reportData.cardTotal : "0"}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col span={12}>
            <Card className="z-total">
              <Card.Body>
                <Statistic
                  precision={2}
                  valueStyle={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                  value={reportData.cardTotal}
                  prefix={
                    <div style={{ fontSize: 20 }}>
                      <RIcon size={20} icon={dollar} /> TOTAL{" "}
                    </div>
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row id="tablePrint"  className="TablePrint">
          <Col span={24}>
            {taxArr.map((e) => {
              return (
                <Card >
                  <Card.Header className="h4">Tax {e.taxvalue}</Card.Header>
                  <Card.Body>
                    {/* <Card.Title>
                      Report
                      <span className="gr-sbH"> After Discount</span>
                    </Card.Title> */}
                    <Table
                      id="tablePrint"
                      loading={loading}
                      // summaryColumns={ordersSummaryColumn}
                      //scroll={{ x: true }}
                      columns={ReportColumns(e.taxvalue)}
                      // summaryData={summaryData}
                      dataSource={e.products}
                      striped
                      bordered
                      hover
                      pagination={{
                        pageSize: 10,
                      }}
                      footer={
                        e.products.length > 0
                          ? () => (
                            <>
                              <Table
                                dataSource={ReportSummaryData}
                                columns={ReportSummary(
                                  e.totalQuantity,
                                  e.totalTaxAmount,
                                  e.taxvalue,
                                  e.totalPrice
                                )}
                                showHeader={false}
                                pagination={false}
                                rowClassName="highlighted-bg"
                              //rowKey={props.rowKey}
                              />
                            </>
                          )
                          : ""
                      }
                    />
                  </Card.Body>
                </Card>
              );
            })}
            {/* {discountData.length ? 
              <Card>
              <Card.Header className="h4">Discount</Card.Header>
              <Card.Body>
                <Card.Title>
                  Report
                  <span className="gr-sbH"> Discount</span>
                </Card.Title>
                <Table
                className="TablePrint"
                  id="tablePrint"
                  loading={loading}
                  columns={discountColumns()}
                  dataSource={discountData}
                  striped
                  bordered
                  hover
                  pagination={{
                    pageSize: 10,
                  }}
                  footer={
                    discountData.length > 0
                      ? () => (
                        <>
                          <Table
                            dataSource={discountSummaryData}
                            columns={discountSummary(totalDiscountPrice)}
                            showHeader={false}
                            pagination={false}
                            rowClassName="highlighted-bg"
                          //rowKey={props.rowKey}
                          />
                        </>
                      )
                      : ""
                  }
                />
              </Card.Body>
            </Card> : ""
            } */}
            { serviceData != 0 ? 
              <Card>
              <Card.Header className="h4">Service</Card.Header>
              <Card.Body>
                {/* <Card.Title>
                  Report
                  <span className="gr-sbH"> Service</span>
              </Card.Title> */}
              <Table
                className="TablePrint"
                  id="tablePrint"
                  loading={loading}
                  pagination={false}
                  columns={serviceColumn()}
                  dataSource={serviceData}
                  striped
                  bordered
                  hover />
              </Card.Body>
            </Card> : ""
          
            }
            { driveoutData != 0 ? 
              <Card>
              <Card.Header className="h4">Driveout Charges</Card.Header>
              <Card.Body>
                {/* <Card.Title>
                  Report
                  <span className="gr-sbH"> Service</span>
              </Card.Title> */}
              <Table
                className="TablePrint"
                  id="tablePrint"
                  pagination={false}
                  loading={loading}
                  columns={driveoutColumn()}
                  dataSource={driveoutData}
                  striped
                  bordered
                  hover />
              </Card.Body>
            </Card> : ""
          
            }
          
          
          </Col>
        </Row>
      </Home>
    </div>
  );
};

export default ZRepport;
