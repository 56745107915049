import React, { Component } from "react";
import { connect } from "react-redux";
import Home from "./../Home";
import { Button as BootButton, Card } from "react-bootstrap";
import { Icon as RIcon } from "react-icons-kit";
import { search } from "react-icons-kit/fa/search";
import { Table, Input, InputNumber, Form, Button } from "antd";
import Highlighter from "react-highlight-words";
import SingleInvoice from "./../Arkiv/Reports/SingleInvoice";
import Modal from "react-modal";
import "./kunder.css";
import { Modal as AntModal } from "antd";
import {
  getKunder,
  updateKunder,
  getKunderInvoice,
  addKunder
} from "./../../action/customerAction";
import AddForm from "./AddForm";
const FormItem = Form.Item;
const EditableContext = React.createContext();
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: "",
      error: null,
      isLoaded: false,
      visible: false,
      isAuthenticated: false,
      first_name: "",
      last_name: "",
      address: "",
      currentPage: "kunder",
      invoiceData: [],
      items: [],
      filtered: [],
      branch: "",
      modalIsOpen: false,
      firstName: "",
      lastName: "",
      phoneNo: "",
      reference: "",
      email: "",
      line1: "",
      line2: "",
      pin: "",
      city: "",
      state: "",
      country: "",
      id: "",
      searchQ: "",
      searchText: "",
      searchedColumn: "",
      addModalIsOpen: false,
      page: 1,
      pageSize: 6,
      totalDocs: 0,
      totalPages: 0
    };

    // this.addToggleModal = this.addToggleModal.bind(this)
    this.columns = [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: Math.random(),
        editable: false,
        width: "18%",
        ...this.getColumnSearchProps("firstName")
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: Math.random(),
        editable: false,
        width: "18%",
        ...this.getColumnSearchProps("lastName")
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "emaill",
        width: "20%",
        ...this.getColumnSearchProps("email")
      },

      {
        title: "Phone",
        dataIndex: "phoneNo",
        key: Math.random(),
        editable: false,
        width: "15%",
        ...this.getColumnSearchProps("phoneNo"),
        render: (text) => (text === 0 ? "" : text)
      },
      {
        title: "Actions",
        dataIndex: "operation",
        width: "30%",
        key: "operation",
        render: (text, record) => {
          return (
            <div className="kunderTable">
              <Button
                onClick={() => this.showModal(record)}
                className="viewButton"
              >
                View
              </Button>{" "}
              <Button onClick={() => this.toggleModal(record)}>Edit</Button>{" "}
              <Button onClick={() => this.showCustomerInvoices(record._id)}>
                Faktura
              </Button>
            </div>
          );
        }
      }
    ];
  }
  componentDidMount() {
    this.setState({ isLoaded: true });
    this.props.getKunder().then((res) => {
      this.setState({
        totalDocs: this.props.kunder.totalDocs,
        page: this.state.totalPages
      });
      this.setState({ isLoaded: false });
      // this.setState({totalPages:this.props.kunder.totalPages});
      //  this.props.getKunder(this.state.totalPages-(this.state.page)).then((res)=>{
      //   console.log("gprops",this.props)
      //  })
    });
  }

  isEditing = (record) => record.key === this.state.editingKey;
  showModal = (record) => {
    const { firstName, lastName, email, phoneNo, reference } = record;
    const { line1, line2, city, pin, state, country } = record.address;
    this.setState({
      visible: true,
      firstName,
      lastName,
      phoneNo,
      reference,
      email,
      line1,
      line2,
      city,
      state,
      pin,
      country
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };
  addToggleModal = (e, isS) => {
    this.setState({ addModalIsOpen: !e });
    if (isS) this.props.getKunder();
  };
  toggleModal(record) {
    const { _id, firstName, lastName, phoneNo, email, address } = record;
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      id: _id,
      firstName,
      lastName,
      phoneNo,
      email,
      line1: address.line1,
      line2: address.line2,
      pin: address.pin,
      city: address.city,
      state: address.state,
      country: address.country
    });
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  delete(record) {
    const newData = [...this.state.items];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1);
      this.setState(
        {
          items: newData,
          editingId: item._id
        }
        // this.state.category
        //   ? this.deleteCategory(item._id)
        //   : this.deleteProduct(item._id)
      );
    } else {
      newData.push(record);
      this.setState({ items: newData, editingKey: "" });
    }
  }
  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState({ data: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateCustomer = (e) => {
    e.preventDefault();
    let hdr = localStorage.token;
    const {
      id,
      firstName,
      lastName,
      phoneNo,
      email,
      line1,
      line2,
      pin,
      city,
      state,
      country
    } = this.state;

    let data = {
      firstName,
      lastName,
      phoneNo,
      email,
      line1,
      line2,
      pin,
      city,
      state,
      country
    };
    this.setState({ isLoaded: true });
    this.props.updateKunder(data, id).then(() => {
      this.props.getKunder();
      this.setState({ isLoaded: false, modalIsOpen: false });
    });
  };
  showCustomerInvoices = (id) => {
    this.setState({ isLoaded: true });
    this.props.getKunderInvoice(id).then(() => {
      this.setState({ isLoaded: false, currentPage: "kunderInvoice" });
    });
  };
  showInvoice = (invoiceNumber) => {
    let index = this.props.invoiceData.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    this.setState({
      currentPage: "invoiceView",
      billData: this.props.invoiceData[index]
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <BootButton
          variant="outline-warning"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </BootButton>
        <BootButton
          variant="outline-dark"
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </BootButton>
      </div>
    ),
    filterIcon: (filtered) => (
      <RIcon
        icon={search}
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const {
      error,
      items,
      isLoaded,
      currentPage,
      invoiceData,
      filtered,
      firstName,
      lastName,
      reference,
      country,
      city,
      email,
      line1,
      line2,
      phoneNo,
      pin,
      state,
      searchQ
    } = this.state;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });
    const invoiceColumn = [
      {
        title: "Invoice No.",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber"
      },
      {
        title: "Date",
        dataIndex: "createdDate",
        key: "createdDate"
      },
      {
        title: "Time",
        dataIndex: "createdTime",
        key: "createdTime"
      },
      {
        title: "Order Type",
        dataIndex: "orderType",
        key: "orderType"
      },
      {
        title: "Total",
        dataIndex: "taxPrice",
        key: "taxPrice",
        render: (text, record) => {
          return `Kr.${text}`;
        }
      },
      {
        title: "Invoice",
        key: "_id",
        render: (text, record) => (
          <Button onClick={() => this.showInvoice(record.invoiceNumber)}>
            View
          </Button>
        )
      }
    ];
    const getKunder = () => {
      return (
        <>
          <div className="report-container text-center">
            <span className="item ">Kunder</span>
          </div>
          <Card className="text-right">
            <Card.Body>
              <BootButton
                onClick={() =>
                  this.addToggleModal(this.state.addModalIsOpen, false)
                }
                className="btn-ab"
              >
                LEGG TIL KUNDER
              </BootButton>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {/* <EditableContext.Provider value={this.props.form}> */}
              <Table
                loading={isLoaded}
                components={components}
                bordered
                dataSource={this.props.kunder.docs}
                columns={columns}
                rowKey={(record) => Math.random()}
                rowClassName="editable-row"
                scroll={{
                  y: 500
                }}
                pagination={{
                  total: this.state.totalDocs,
                  current: this.state.page,
                  pageSize: this.state.pageSize,
                  onChange: (page, pageSize) => {
                    this.setState({ isLoaded: true });
                    this.setState({ page: page });
                    this.setState({ pageSize: pageSize });
                    this.props.getKunder(page, pageSize).then((res) => {
                      this.setState({ isLoaded: false });
                    });
                  }
                }}
              />
              {/* </EditableContext.Provider> */}
            </Card.Body>
          </Card>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Customer"
          >
            <div className="button-container">
              <button
                onClick={() => this.closeModal()}
                className="close-button"
              >
                X
              </button>
            </div>
            <form
              className="add-employee add-customer"
              onSubmit={(e) => this.updateCustomer(e)}
            >
              <li>
                <center>
                  <h2>Edit Customer</h2>
                </center>
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="firstName"
                  className="input2"
                  placeholder="First Name"
                  value={firstName}
                />
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="lastName"
                  className="input2"
                  placeholder="Last Name"
                  value={lastName}
                />
              </li>
              <li>
                <input
                  type="email"
                  onChange={this.handleChange}
                  name="email"
                  className="input2"
                  placeholder="E-mail"
                  value={email}
                />
              </li>
              <li>
                <input
                  type="number"
                  onChange={this.handleChange}
                  name="phoneNo"
                  className="input2"
                  min="0000000000"
                  max="9999999999"
                  required
                  maxlength="10"
                  pattern="[0-9]"
                  placeholder="Phone No."
                  value={phoneNo === 0 ? "" : phoneNo}
                />
              </li>
              <p>Address</p>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line1"
                  className="input2"
                  placeholder="Address Line 1"
                  value={line1}
                />
              </li>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line2"
                  className="input2"
                  placeholder="Address Line 2"
                  value={line2}
                />
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="city"
                  className="input2"
                  placeholder="City"
                  value={city}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="number"
                  onChange={this.handleChange}
                  name="pin"
                  className="input2"
                  placeholder="PIN"
                  value={pin}
                />
              </li>
              <li>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="state"
                  className="input2"
                  placeholder="State"
                  value={state}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="country"
                  className="input2"
                  placeholder="Country"
                  value={country}
                />
              </li>
              <li>
                <button type="submit" id="submit-landing2">
                  Submit
                </button>
              </li>
            </form>
          </Modal>
          <AntModal
            title={`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={[
              <BootButton variant="outline-dark" onClick={this.handleOk}>
                Ok
              </BootButton>
            ]}
          >
            <p>
              <b>Email:</b> {email ? email : ""}
            </p>
            <p>
              <b>Phone:</b> {phoneNo ? phoneNo : ""}
            </p>
            <p>
              <b>reference:</b> {reference ? reference : ""}{" "}
            </p>
            <h5>Address:</h5>
            <p>{`${line1 ? line1 : ""} ${line2 ? line2 : ""}`}</p>
            <p>{`${city ? city : ""} ${pin ? pin : ""}`}</p>
            <p>{`${state ? state : ""} ${country ? country : ""}`}</p>
          </AntModal>
          <AddForm
            addModalIsOpen={this.state.addModalIsOpen}
            addToggleModal={this.addToggleModal}
            addKunder={this.props.addKunder}
          />
        </>
      );
    };
    const getKunderInvoice = () => {
      return (
        <>
          <div className="report-container">
            <span className="item">CMS /Invoice</span>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() => this.setState({ currentPage: "kunder" })}
                  className="ant-btn back-btn"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                </div>
              </span>
            </span>
          </div>
          <Card>
            <Card.Body>
              <Table
                loading={isLoaded}
                dataSource={this.props.invoiceData}
                components={components}
                rowKey={(record) => record.email}
                columns={invoiceColumn}
                bordered
              />
            </Card.Body>
          </Card>
        </>
      );
    };
    const getInvoiceView = () => {
      return (
        <>
          <div className="report-container">
            <span className="item">
              CMS /Invoice/Invoice No:
              {this.state.billData ? this.state.billData.invoiceNumber : ""}
            </span>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() =>
                    this.setState({ currentPage: "kunderInvoice" })
                  }
                  className="ant-btn back-btn"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                </div>
              </span>
            </span>
          </div>

          <SingleInvoice
            data={this.state.billData}
            branch={this.state.branch}
          />
        </>
      );
    };
    if (currentPage === "kunder") {
      return <Home>{getKunder()}</Home>;
    } else if (currentPage === "kunderInvoice") {
      return <Home>{getKunderInvoice()}</Home>;
    } else if (currentPage === "invoiceView") {
      return <Home>{getInvoiceView()}</Home>;
    }
  }
}

const mapStateToProps = ({ kunder, KunderInvoice }) => {
  return {
    kunder,
    invoiceData: KunderInvoice.invoiceData,
    branch: KunderInvoice.branch
  };
};
export default connect(mapStateToProps, {
  getKunder,
  updateKunder,
  getKunderInvoice,
  addKunder
})(Index);
