import React from "react";
import Header from "./Header";
import { cashReportColumn } from "../../Table/TableColumns";
import { Row, Col } from "react-bootstrap";
import { Table } from "antd";
import "./TableColumn.css";
const List = (props) => {
  return (
    <>
      <Row>
        <Col>
          <Header
            printReportHandler={props.printReportHandler}
            goBackMainPage={props.goBackMainPage}
            handleDateChange={props.handleDateChange}
          />
        </Col>
      </Row>
      <Row >
        <Col>
          <Table
          bordered
            loading={props.isLoading}
            dataSource={props.cashReport.data}
            columns={cashReportColumn()}
          />
        </Col>
      </Row>
    </>
  );
};

export default List;
