import { GET_INFORMATION } from "../../action/types";

const initialState = {};

const getInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFORMATION:
      return action.payload.data;
    default:
      return state;
  }
};

export default getInformationReducer;
