import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import {
  Table,
  Input,
  InputNumber,
  Popover,
  Form,
  Button,
  notification,
  Modal,
  Row,
  Col,
  Select,
  Switch
} from "antd";

import { ic_clear } from "react-icons-kit/md/ic_clear";

import { Icon as RIcon } from "react-icons-kit";
import { ic_restaurant_menu } from "react-icons-kit/md/ic_restaurant_menu";
import { ic_create } from "react-icons-kit/md/ic_create";
import { ic_image } from "react-icons-kit/md/ic_image";
import { ic_done } from "react-icons-kit/md/ic_done";
import appConfig from "../../helpers/appConfig";
import ProductForm from "./ProductForm";
import IngredentsModel from "./Ingredents";
const data = [];
const Option = Select.Option;
const FormItem = Form.Item;
const EditableContext = React.createContext();
const { TextArea } = Input;
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;

    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data,
      editingKey: "",
      isLoaded: false,
      error: false,
      visible: false,
      visiblePopover: false,
      visibleEditPopover: false,
      category: true,
      editingRecipe: false,
      iconModalVisible: false,
      PLU1: false,
      PLU2: false,
      items: [],
      superCatItems: [],
      icons: [],
      defaultTagValues: [],
      catId: "",
      newItem: "",
      superCat: "",
      newPrice: "",
      currentPage: 1,
      itemNo: "",
      productName: "",
      productId: "",
      stockProductId: "",
      recipes: [],
      stockCats: [],
      stockProds: [],
      quantity: "",
      recipeName: "",
      recipeQuantity: "",
      editingRecipeIndex: "",
      productFile: "",
      productImageEditModal: false,
      imageEditingId: "",
      productEditing: {},
      productEditingIngredent: {},
      productIngredentEditModal: false,
      inputIngredentInvisible: false,
      ingredents: [],
      ingredentsText: "",
      productUploadingLoading: false,
      isWebDisabled: [],
      taxType: [],
      taxes: []
    };
    this.supercategoryColumns = [
      {
        title: "Sr. Nr.",
        width: "8%",
        editable: false,
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: "Gruppert Kode",
        dataIndex: "name",
        width: "20%",
        editable: true,
        render: (text, record, index) => <p>{text}</p>
      }
    ];
    this.categoryColumns = [
      {
        title: "Sr. Nr.",
        width: "8%",
        editable: false,
        render: (text, record, index) => {
          return index + (this.state.currentPage - 1) * 10 + 1;
        }
      },
      {
        title: "Produkt Navn",
        dataIndex: "name",
        width: "18%",
        editable: true,
        render: (text, record, index) => (
          <a
            onClick={() =>
              this.handleClick(
                //? for correct sr no., check antd pagination and table/pagination docs
                //? index + (currentpage - 1)*10
                this.state.items[index + (this.state.currentPage - 1) * 10]._id
              )
            }
          >
            {text}
          </a>
        )
      },
      {
        title: "Gruppert Kode",
        width: "16%",
        dataIndex: "superCategory",
        // editable: true,
        render: (text, record) => {
          const { editingKey } = this.state;
          //console.log(editingKey);
          return editingKey === record.name ? (
            <Select
              // mode="tags"
              style={{ width: "100%" }}
              placeholder="Choose Type"
              defaultValue={
                record.superCategory && record.superCategory._id
                  ? record.superCategory._id
                  : ""
              }
              onChange={this.supercatEdithandleChange}
              required={true}
            >
              {this.state.superCatItems &&
                this.state.superCatItems.map((data, index) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
            </Select>
          ) : (
            <Fragment>
              <span>
                {record.superCategory && record.superCategory.name
                  ? record.superCategory.name
                  : ""}
              </span>
            </Fragment>
          );
        }
      },
      {
        title: "Mva",
        width: "14%",
        render: (text, record) => {
          const { editingKey, taxType } = this.state;
          return editingKey === record.name ? (
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Choose Type"
              value={this.state.defaultTagValues}
              onChange={this.handleEditChange}
              required={true}
            >
              {taxType.map((itm) => {
                return (
                  <Option value={itm.key} key={itm.key}>
                    {itm.val}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <Fragment>
              <span>
                {this.getTaxesName(record.catTaxes)}
                {/* {record.catTaxes && record.catTaxes.length > 0
                  ? record.catTaxes.toString()
                  : "-"} */}
              </span>
            </Fragment>
          );
        }
      },
      {
        title: "Bilde",
        dataIndex: "image",
        width: "20%",
        render: (text, record) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center"
              }}
            >
              <img
                src={`${appConfig.s3url}/${text}`}
                style={{ maxWidth: 80, maxHeight: 100 }}
              />
            </div>
          );
        }
      },
      {
        title: "Vis på nettet",
        dataIndex: "isWeb",
        width: "6%",
        render: (text, record, index) => {
          return (
            <div>
              <Switch
                size="small"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(e) => this.onChangeIsWeb(e, record, index)}
                defaultChecked={record.isShowWeb}
              />
            </div>
          );
        }
      },
      {
        title: "Vis som ekstra",
        dataIndex: "isShowAsExtra",
        width: "6%",
        render: (text, record, index) => {
          return (
            <div>
              <Switch
                size="small"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(e) => this.onChangeIsExtra(e, record, index)}
                defaultChecked={record.isShowAsExtra}
              />
            </div>
          );
        }
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "10%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() =>
                          this.save(form, record.name, record.superCategory._id)
                        }
                        style={{ marginRight: 8 }}
                      >
                        <RIcon
                          icon={ic_done}
                          style={{ fontWeight: "bold" }}
                          size={22}
                        />
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          AVBRYT
                        </a>
                      </span>
                    }
                    title="Er du sikker?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      <RIcon
                        icon={ic_clear}
                        style={{ fontWeight: "bold", color: "red" }}
                        size={22}
                      ></RIcon>
                    </a>
                  </Popover>
                </span>
              ) : (
                <a
                  style={{ color: "#1890ff" }}
                  onClick={() => this.edit(record.name, record)}
                >
                  Edit
                </a>
              )}
            </div>
          );
        }
      },
      {
        title: "Bilde",
        width: "12%",
        render: (text, record) => (
          <a
            style={{ color: "#1890ff" }}
            onClick={() => this.editIcon(record._id)}
          >
            Bytt
          </a>
        )
      },
      {
        title: "Slett",
        width: "12%",
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <Fragment>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        AVBRYT
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        SLETT
                      </a>
                    </span>
                  }
                  title="Are you Sure?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    SLETT
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record.name)}
                  style={{ color: "#F44336" }}
                >
                  SLETT
                </a>
              )}
            </Fragment>
          );
        }
      }
    ];

    this.productColumns = [
      {
        title: "Produkt Nummer",
        dataIndex: "itemNo",
        width: "13%",
        editable: true
      },
      {
        title: "Produkt Navn",
        dataIndex: "name",
        width: "20%",
        editable: true,
        render: (text, record) => {
          return text;
        }
      },
      {
        title: "Produkt bilde",
        // dataIndex: "name",
        width: "20%",
        editable: false,
        render: (text, record) => {
          return (
            <div>
              {/* // onClick={() => alert(record._id)}> */}
              <img
                src={`${appConfig.s3url}/${record.thumbnail}`}
                style={{ maxWidth: 120, maxHeight: 120, borderRadius: "100%" }}
              />
            </div>
          );
          // return text;
        }
      },

      {
        title: "Produkt Pris",
        key: "_id",
        width: "15%",
        editable: true,
        dataIndex: "price",
        render: (text) => `Kr.${text}`
      },
      {
        title:
          "Edit - [Produkt Navn/Pris, Produkt bilde, Produkt Ingredienser ]",
        dataIndex: "operation",
        width: "25%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() => this.save(form, record.name)}
                        style={{ marginRight: 8 }}
                      >
                        LAGRE
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          AVBRYT
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      AVBRYT
                    </a>
                  </Popover>
                </span>
              ) : (
                <a
                  title="Edit Produkt navn,Produkt Pris"
                  style={{ color: "#1890ff" }}
                  onClick={() => this.editProd(record.name)}
                >
                  {/* Edit */}
                  <RIcon size={18} icon={ic_create} />
                </a>
              )}
              <Button
                title="Edit Image"
                onClick={() =>
                  this.setState({
                    productEditing: record,
                    productImageEditModal: true
                  })
                }
                type="link"
              >
                <RIcon size={18} icon={ic_image} />
              </Button>
              <Button
                title="Edit Ingredienser"
                type="link"
                onClick={() =>
                  this.setState({
                    productEditingIngredent: record,
                    ingredents: record.ingredents ? record.ingredents : [],
                    ingredentsText: record.ingredentsDesc
                      ? record.ingredentsDesc
                      : "",
                    productIngredentEditModal: true
                  })
                }
              >
                <RIcon icon={ic_restaurant_menu} size={18} />
              </Button>
            </div>
          );
        }
      },
      {
        title: "Slett",
        width: "14%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <Fragment>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        AVBRYT
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        SLETT
                      </a>
                    </span>
                  }
                  title="Er du sikker?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    SLETT
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record.name)}
                  style={{ color: "#F44336" }}
                >
                  SLETT
                </a>
              )}
            </Fragment>
          );
        }
      }
    ];
  }

  componentDidMount() {
    this.categoryPage();
    this.getSuperCat();
  }
  handleProductChange = (e) => {
    const { id } = this.state;
    const file = e.target.files[0];
    this.setState({ productFile: file });
  };

  isEditing = (record) => {
    return record.name === this.state.editingKey;
  };

  isDeleting = (record) => {
    return record.name === this.state.deletingKey;
  };

  edit = (key, record) => {
    let taxType = [];
    let sCatName = (record.superCategory && record.superCategory._id) || "";
    if (sCatName) {
      const cat = this.state.superCatItems.find((ele) => sCatName == ele._id);
      const tTax = cat && cat.taxType ? cat.taxType : [];
      let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
      const taxes = JSON.parse(localStorage.getItem("taxes"));
      if (taxId.length > 0) {
        taxType = taxes.map((tax) => {
          if (taxId.includes(tax._id)) {
            return { key: tax._id, val: tax.taxName };
          }
        });
      }
    }

    this.setState({
      editingKey: key,
      visibleEditPopover: false,
      defaultTagValues: record.catTaxes || [],
      taxType: taxType
    });
  };
  getTaxesName = (taxId) => {
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId && taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          return tax.taxName;
        }
      });
    }
    return isTax.length > 0 ? isTax.toString().replace(/(^,)|(,$)/g, "") : "-";
  };
  editProd = (key) => {
    this.setState({
      editingKey: key,
      visibleEditPopover: false
    });
  };

  openEditPopover = () => {
    this.setState({ visibleEditPopover: true });
  };

  deleteTemp(key) {
    this.setState({ deletingKey: key });
  }

  save(form, key, superCategoryId) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.items];
      const index = newData.findIndex((item) => key === item.name);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState(
          {
            items: newData,
            editingKey: ""
          },
          this.state.category
            ? this.updateCategory(
                row.name,
                this.state.superCat,
                item._id,
                superCategoryId
              )
            : this.updateProduct(row.name, row.price, row.itemNo, item._id)
        );
      } else {
        newData.push(row);
        this.setState({ items: newData, editingKey: "" });
      }
    });
  }

  handleVisibleChange = () => {
    this.setState({ visiblePopover: true });
  };

  hideDelete = () => {
    this.setState({ deletingKey: "" });
  };

  delete = (e, record) => {
    e.preventDefault();
    const newData = [...this.state.items];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1);
      this.setState(
        {
          items: newData,
          editingId: item._id
        },
        this.state.category
          ? this.deleteCategory(item._id)
          : this.deleteProduct(item._id)
      );
    } else {
      newData.push(record);
      this.setState({ items: newData, editingKey: "" });
    }
  };

  onChangeIsWeb = (e, record) => {
    let hdr = localStorage.token;
    let data = JSON.stringify({ isCheck: e, id: record._id });
    fetch(`${appConfig.endpoint}/category/isShowWeb`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.message);
        } else {
          this.openNotification("success", result.message);
        }
      });
  };
  onChangeIsExtra = (e, record, index) => {
    let hdr = localStorage.token;
    let data = JSON.stringify({ isCheck: e, id: record._id });
    fetch(`${appConfig.endpoint}/category/isShowExtra`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.message);
        } else {
          this.openNotification("success", result.message);
        }
      });
  };

  cancel = () => {
    this.setState({
      editingKey: "",
      productImageEditModal: false,
      productIngredentEditModal: false,
      ingredents: [],
      ingredentsText: ""
    });
  };

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      visible: false,
      stockProductId: ""
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      visible: false,
      // editingRecipe: true,
      stockProductId: ""
    });
  };

  handleIconModalCancel = (e) => {
    this.setState({
      iconModalVisible: false
    });
  };

  handleEdit = (index, recipeName, quantity, stockProductId) => {
    //console.log(stockProductId);
    this.setState({
      editingRecipe: true,
      editingRecipeIndex: index,
      recipeName,
      quantity,
      stockProductId
    });
  };

  handleRecipeChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  pageChange = (pagination) => {
    if (pagination) {
      this.setState({ currentPage: pagination.current });
    }
  };

  handleChange = (value) => {
    this.setState({
      taxes: value
    });
  };
  handleEditChange = (value) => {
    this.setState({
      taxes: value,
      defaultTagValues: value
    });
  };

  selectIcon = (id) => {
    let hdr = localStorage.token;
    const { catId } = this.state;
    let data = JSON.stringify({ imageId: id });
    //console.log(data);
    fetch(`${appConfig.endpoint}/category/icon/${catId}`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
            this.openNotification("error", result.data);
          } else {
            //console.log(result);
            this.categoryPage();
            this.openNotification("success", result.message);
            this.setState({ iconModalVisible: false });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  editIcon = (id) => {
    this.setState({ catId: id });
    this.getCategoryIcon();
  };

  handleClick = (id) => {
    if (this.state.category) {
      this.setState({ isLoaded: false });
      this.productPage(id);
    } else {
      //console.log("beep bop");
    }
  };

  goToCat = () => {
    this.setState({ category: true, isLoaded: false, items: [] });
    this.categoryPage();
  };

  handleAddCategory = (e) => {
    e.preventDefault();
    const { newItem, taxes } = this.state;
    const newData = {
      // key: count,
      name: newItem
    };

    var hdr = localStorage.token;
    var data = JSON.stringify({
      name: newData.name,
      // catType: { PLU1: this.state.PLU1, PLU2: this.state.PLU2 },
      taxes,
      superCategory: this.state.superCat
    });
    if (taxes.length === 0) {
      return this.openNotification("error", "Please select atleast one tag");
    }
    //console.log(`data = `,data);

    fetch(`${appConfig.endpoint}/category`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
            this.openNotification("error", result.data);
          } else {
            //console.log(result);
            this.setState({ catId: result.data._id, superCat: "" });
            this.getCategoryIcon();
            this.categoryPage();

            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleAddSuperCategory = (e) => {
    e.preventDefault();
    const { superCat } = this.state;
    const supercatData = {
      name: superCat
    };
    var hdr = localStorage.token;

    fetch(`${appConfig.endpoint}/prcat/supercat`, {
      method: "POST",
      body: JSON.stringify(supercatData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log("SUPER CATEGORY ADDED", result);
            this.getSuperCat();
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  getCategoryIcon = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/icon`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
          } else {
            this.setState({ icons: result.data });
            this.openIconModal();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openIconModal = () => {
    this.setState({ iconModalVisible: true });
  };

  handleAddProduct = (e) => {
    e.preventDefault();
    this.setState({ productUploadingLoading: true });
    const { newItem, newPrice, catId, itemNo } = this.state;
    var formData = new FormData();
    const newData = {
      name: newItem,
      price: newPrice
      //itemNo // uncomment later, backend needs to be changed to accept this.
    };

    var hdr = localStorage.token;
    formData.append(
      "image",
      this.state.productFile,
      this.state.productFile.name
    );
    formData.append("name", newData.name);
    formData.append("price", newData.price);
    formData.append("itemNo", itemNo);
    //console.log(data);
    fetch(`${appConfig.endpoint}/product/${catId}`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
        // "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
            this.setState({ productUploadingLoading: false });
            this.openNotification("error", result.data);
          } else {
            //console.log(result);
            this.setState({ productUploadingLoading: false });
            this.productPage(catId);
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  categoryPage = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/category`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              items: result.data,
              newItem: "",
              newPrice: ""
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  getSuperCat = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/prcat/supercat`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log("super cat items", result.data1);
            this.setState({
              isLoaded: true,
              superCatItems: result.data1,
              superCat: "",
              newPrice: ""
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  StockCategoryPage = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            //console.log(result);
            this.setState({
              isLoaded: true,
              stockCats: result.data
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  productPage = (id) => {
    // alert("IN HERE");
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/product/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              // items: result.data.reverse(),
              items: result.data,
              category: false,
              catId: id,
              newItem: "",
              newPrice: "",
              itemNo: ""
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  updateCategory = (name, superCategoryId, id, sctid) => {
    let hdr = localStorage.token;
    const { taxes } = this.state;
    let data = JSON.stringify({
      name,
      superCategory: superCategoryId === sctid ? sctid : superCategoryId,
      taxes
    });
    fetch(`${appConfig.endpoint}/category/${id}`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.message);
        } else {
          this.categoryPage();
          this.openNotification("success", "Category updated successfully.");
          //console.log(result.message);
        }
      });
  };

  updateProduct = (name, price, itemNo, id) => {
    let hdr = localStorage.token;
    let data = JSON.stringify({ name, price, itemNo });
    fetch(`${appConfig.endpoint}/op-product/${id}`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", "err");
        } else {
          this.productPage(this.state.catId);
          this.openNotification("success", "Product updated successfully.");
        }
      });
  };

  updateProductImage = (id) => {
    let hdr = localStorage.token;
    let formData = new FormData();
    formData.append(
      "image",
      this.state.productFile,
      this.state.productFile.name
    );
    fetch(`${appConfig.endpoint}/imageeditproduct/${id}`, {
      method: "PUT",
      body: formData,
      headers: {
        "x-auth-token": hdr
        // "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", "err");
        } else {
          this.setState({ productFile: "", category: true });
          // this.categoryPage();

          this.productPage(this.state.catId);
          this.setState({ productImageEditModal: false });
          this.openNotification("success", "Product image updated.");
        }
      });
  };

  deleteCategory = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/category/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          this.openNotification("success", "Category deleted successfully.");
          //console.log(result.message);
        }
      });
  };
  editHandleImageChange = (e) => {
    alert(e.target.file);
  };

  deleteProduct = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/op-product/${id}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          this.openNotification("success", "Product deleted successfully.");
          //console.log(result.message);
        }
      });
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  showRecipe = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/singleProduct/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              visible: true,
              recipes: result.data.recipe,
              productId: id
            });
          }
          this.StockCategoryPage();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleCatChange = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock/product/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({ stockProds: result.data });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleProdChange = (id) => {
    this.setState({ stockProductId: id });
  };

  addRecipe = () => {
    let hdr = localStorage.token;
    const { productId, stockProductId, quantity } = this.state;
    let data = JSON.stringify({ stockProductId, quantity });
    //console.log(data);
    fetch(`${appConfig.endpoint}/recipe/${productId}`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", result.message);
            this.setState({
              editingRecipe: false,
              editingRecipeIndex: -1,
              visible: false,
              stockProductId: ""
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  deleteRecipe = (recipeId) => {
    let hdr = localStorage.token;
    //console.log(recipeId);
    fetch(`${appConfig.endpoint}/recipe/${recipeId}`, {
      method: "DELETE",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", result.message);
            this.setState({
              editingRecipe: false,
              editingRecipeIndex: -1,
              visible: false
              // stockProductId: ''
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
  };

  supercathandleChange = (value) => {
    const cat = this.state.superCatItems.find((ele) => value == ele._id);
    const tTax = cat && cat.taxType ? cat.taxType : [];
    let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          return { key: tax._id, val: tax.taxName };
        }
      });
    }
    this.setState({ superCat: value, taxType: isTax });
  };
  supercatEdithandleChange = async (value) => {
    const cat = await this.state.superCatItems.find((ele) => value == ele._id);
    const tTax = cat && cat.taxType ? cat.taxType : [];
    let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          return { key: tax._id, val: tax.taxName };
        }
      });
    }
    this.setState({ superCat: value, taxType: isTax, defaultTagValues: [] });
  };
  getTaxType = () => {
    return this.state.taxType.map((itm) => {
      return (
        <Option value={itm} key={itm}>
          {itm}
        </Option>
      );
    });
  };
  showIngredentInput = () => {
    this.setState({ inputIngredentInvisible: true });
  };
  saveInputRef = (input) => (this.input = input);

  render() {
    const {
      isLoaded,
      error,
      category,
      productName,
      recipes,
      quantity,
      editingRecipe,
      editingRecipeIndex
    } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const categoryColumns = this.categoryColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          // superCateory: col.superCategory.name,
          editing: this.isEditing(record)
        })
      };
    });

    const supercategoryColumns = this.supercategoryColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    const productColumns = this.productColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              OPPRETT MENY
              {/* //{category ? "Categories" : "Products"} */}
            </span>
            <span className="item">
              <span id="less-visible">
                {!this.state.category ? (
                  <div onClick={this.goToCat} className="back-button-border">
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    <span>Tilbake</span>
                  </div>
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>

          {category ? (
            <Fragment className="d-flex w-100 justify-content-between">
              <Form layout="inline" onSubmit={this.handleAddCategory}>
                <FormItem>
                  <Input
                    style={{ width: 200, marginRight: "10px" }}
                    // compact={true}
                    onChange={(e) => this.setState({ newItem: e.target.value })}
                    placeholder="Produkt Navn"
                    value={this.state.newItem}
                    required={true}
                  />
                </FormItem>
                <FormItem>
                  <Select
                    style={{ width: 180 }}
                    placeholder="Velg G. Kode"
                    onChange={(e) => this.supercathandleChange(e)}
                    required={true}
                  >
                    {this.state.superCatItems &&
                      this.state.superCatItems.map((data, index) => {
                        return <Option value={data._id}>{data.name}</Option>;
                      })}
                  </Select>
                </FormItem>
                <FormItem>
                  <Select
                    mode="tags"
                    style={{ width: 120 }}
                    placeholder="Velg Type"
                    onChange={this.handleChange}
                    required={true}
                  >
                    {this.state.taxType.map((itm) => {
                      return (
                        <Option value={itm.key} key={itm.key}>
                          {itm.val}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginBottom: 16 }}
                  >
                    Oprett ny Produkt{" "}
                  </Button>
                </FormItem>
              </Form>
              <Table
                rowKey={(record) => record.name}
                loading={isLoaded ? false : true}
                onChange={(pagination) => this.pageChange(pagination)}
                components={components}
                bordered
                dataSource={this.state.items}
                columns={categoryColumns}
                rowClassName="editable-row"
              />
              <Modal
                title="Bytt Bilde"
                visible={this.state.iconModalVisible}
                footer={null}
                width={"80%"}
                onCancel={this.handleIconModalCancel}
              >
                {this.state.icons.map((icon, index) => {
                  return (
                    <span key={icon._id}>
                      <img
                        src={`${appConfig.s3url}/${icon.path}`}
                        className="choose-icon"
                        onClick={() => this.selectIcon(icon._id)}
                      />
                    </span>
                  );
                })}
              </Modal>
            </Fragment>
          ) : (
            <Fragment>
              <ProductForm
                {...this.state}
                openNotification={this.openNotification}
                productPage={this.productPage}
              />
              <Table
                rowKey={(record) => record.name}
                loading={isLoaded ? false : true}
                onChange={(pagination) => this.pageChange(pagination)}
                components={components}
                bordered
                dataSource={this.state.items}
                columns={productColumns}
                rowClassName="editable-row"
              />
              <Modal
                title={this.state.productEditing.name}
                onCancel={this.cancel}
                onOk={() =>
                  this.updateProductImage(this.state.productEditing._id)
                }
                visible={this.state.productImageEditModal}
                footer={[
                  <Button key="back" onClick={this.cancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    disabled={!this.state.productFile}
                    // loading={loading}
                    onClick={() =>
                      this.updateProductImage(this.state.productEditing._id)
                    }
                  >
                    Upload
                  </Button>
                ]}
              >
                <div style={{ marginLeft: "11%" }}>
                  <img
                    style={{ width: "85%" }}
                    src={
                      appConfig.s3url + "/" + this.state.productEditing.image
                    }
                  ></img>
                </div>
                <hr />
                <p style={{ width: 100 }}>
                  Change Image
                  <input
                    id="files"
                    accept="image/*"
                    onChange={this.handleProductChange}
                    type="file"
                  />
                </p>
              </Modal>
              <IngredentsModel
                {...this.state}
                productPage={this.productPage}
                updateProductImage={this.updateProductImage}
                StockCategoryPage={this.StockCategoryPage}
                cancel={this.cancel}
                openNotification={this.openNotification}
              />

              <Modal
                title={`${productName} Recipe`}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                width={640}
              >
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                  <Row type="flex" justify="space-between">
                    <Col span={2} />
                    <Col span={20}>
                      <table>
                        <tr>
                          <th style={{ padding: "5px 15px" }}>Navn</th>
                          <th style={{ padding: "5px 15px" }}>Antall</th>
                        </tr>
                        {recipes.map((item, index) => {
                          return (
                            <Fragment>
                              {editingRecipe && index === editingRecipeIndex ? (
                                <tr>
                                  <td>{item.stockProduct.name}</td>
                                  <td>
                                    <Input
                                      value={quantity}
                                      onChange={this.handleRecipeChange}
                                      name="quantity"
                                      type="number"
                                    />
                                  </td>
                                  <td>
                                    <Button onClick={this.addRecipe}>
                                      Lagre
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      type="danger"
                                      onClick={() =>
                                        this.deleteRecipe(item._id)
                                      }
                                    >
                                      Slett
                                    </Button>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td> {item.stockProduct.name}</td>
                                  <td>{item.quantity}</td>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        this.handleEdit(
                                          index,
                                          item.stockProduct.name,
                                          item.quantity,
                                          item.stockProduct._id
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      type="danger"
                                      onClick={() =>
                                        this.deleteRecipe(item._id)
                                      }
                                    >
                                      Slett
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                      </table>
                    </Col>
                    <Col span={2} />
                  </Row>
                </Form>
              </Modal>
            </Fragment>
          )}
        </Home>
      );
    }
  }
}

export default EditableTable;
