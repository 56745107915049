import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Card, Col } from "react-bootstrap";
import { Button, Table } from "antd";
import { getCoupan } from "../../action/coupanAction";
import Home from "../Home/index";
import { text } from "react-icons-kit/iconic";
import axios from "axios";
import appConfig from "../../helpers/appConfig";
import history from "../../history";

const CoupanList = (props) => {
  const [isLoaded, setisLoaded] = useState(false);
  let dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "S.No",
      key: "sr.no",
      render: (text, record, index) => {
        return index + 1 + (page - 1) * pageSize;
      }
    },
    {
      title: "Coupon Code",
      dataIndex: "coupanCode",
      key: "coupanCode"
    },
    {
      title: "Coupan Value",
      dataIndex: "coupanValue",
      key: "coupanValue",
      render: (text, record, index) => {
        return `Kr. ${text}`;
      }
    },
    {
      title: " Remaining Value",
      dataIndex: "reamainigValue",
      key: "reamainigValue",
      render: (text, record, index) => {
        return `Kr. ${text ? text.toFixed(2) : "0"}`;
      }
    },
    {
      title: "Generated On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return `${new Date(text).toLocaleDateString()}  ${new Date(
          text
        ).toLocaleTimeString()}`;
      }
    },
    {
      title: "Expiry Date",
      dataIndex: "expirayDate",
      key: "expirayDate",
      render: (text, record, index) => {
        return `${new Date(text).toLocaleDateString()}`;
      }
    },
    {
      title: "Coupan Type",
      dataIndex: "coupanType",
      key: "coupanType"
    },

    {
      title: "Coupan Status",
      key: "isActive",
      dataIndex: "isActive",

      render: (text, record, index) => {
        return text === true
          ? "Active"
          : text === false
          ? "Inactive"
          : "Expired";
      }
    },
    {
      title: "Action",
      width: "5%",
      render: (text, record) => (
        <Button onClick={() => history.push(`/coupons/${record._id}`)}>
          <i className="fa fa-eye"></i>
        </Button>
      ),
      key: "action"
    }
  ];

  let coupans = useSelector((state) => state.coupanReducer.data.docs);
  let totalDocs = useSelector((state) => state.coupanReducer.data.totalDocs);
  useEffect(() => {
    setisLoaded(true);
    dispatch(getCoupan(page, pageSize)).then((res) => {
      setisLoaded(false);
    });
  }, []);

  useEffect(() => {
    console.log("coupanss are ", coupans);
  }, [coupans]);

  return (
    <Home>
      <Row  className="report-container justify-content-center" >
            <span className="item text-center" style={{ width: "60%" }}>
              {/* <span id="less-visible">Hjem / </span> */}
              Coupon
            </span>
            {/* <span id="less-visible">
              <Button onClick={() => {}} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span> */}
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                {/* <Table
                    loading={isLoaded}
                    bordered
                    dataSource={coupans}
                    columns={columns}
                    pagination={{
                      total: totalDocs,
                      current: page,
                      pageSize: 8,
                      // onChange: (page, pageSize) => {
                      //   setisLoaded(true);
                      //   setPage(page);
                      //   setPageSize(pageSize);
                      //   dispatch(getCoupan(page, pageSize)).then((res) => {
                      //     setisLoaded(false);
                      //   });
                      // }
                    }}
                  /> */}
                <Table
                  loading={isLoaded}
                  dataSource={coupans}
                  columns={columns}
                  bordered
                  pagination={{
                    total: totalDocs,
                    current: page,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setisLoaded(true);
                      setPage(page);
                      setPageSize(pageSize);
                      dispatch(getCoupan(page, pageSize)).then((res) => {
                        setisLoaded(false);
                      });
                    }
                  }}
                />
                ; ;
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Home>
  );
};

export default CoupanList;
