import React, { useEffect, useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import Countdown from "react-countdown";
import moment from "moment";
import { Icon as RIcon } from "react-icons-kit";
import { check } from "react-icons-kit/fa/check";
import { Table, Modal, TimePicker, Radio } from "antd";
import { socket } from "../../../utils/socketNew";
const { Column } = Table;
const View = ({
  orderData,
  setPage,
  timeString,
  setTimeString,
  changeStatus,
  setCancelReason,
  cancelReason,
  setCancelReasonModel,
  cancelReasonModel,
  setTimemodalvisible,
  timemodalvisible,
  searchDriverReducer,
  searchDriverAction,
  allAvailableDriversReducer,
  allAvailableDrivers
}) => {
  const [dOCTax, setdOCTax] = useState(0);
  const [resionError, setResionError] = useState("");
  const [searchDrivers, setSearchDrivers] = useState("Search for Drivers");
  const [foundDriver, setFoundDriver] = useState(false);
  const [driverModalVisible, setDriverModalVisible] = useState(false);
  const [takeAwayModalVisible, setTakeAwayModalVisible] = useState(false);

  const [driverData, setDriverData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const [allDrivers, setAllDrivers] = useState(0);
  const [iDriveMyself, setIDriveMyself] = useState(false);
  const DriveMyselfPermission = localStorage.getItem("DriveMyselfPermission");
  const isFindDriver = localStorage.getItem("isFindDriver");
  // console.log("orderData=======",orderData)
  useEffect(() => {
    // console.log("inside useErfferct orderData----->",orderData)
    if (orderData.driverId) {
      const [{ firstName, lastName, phoneNumber, email }] =
        orderData.driverInfo;
      console.log(
        "driverInfo in order data in useEffect---->",
        firstName,
        lastName,
        phoneNumber
      );
      setFoundDriver(true);
      setDriverData((prevState) => ({
        ...prevState,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber
      }));
    }
    socket.on("driverFound", async (data) => {
      console.log("data after driver found socket it--->", data);
      if (data.driverId != null) {
        setFoundDriver(true);
        setDriverData((prevState) => ({
          ...prevState,
          firstName: data.driverFirstName,
          lastName: data.driverLastName,
          phoneNumber: data.driverPhoneNumber
        }));
      }
    });
    socket.on("availableDrivers", async (data) => {
      console.log("socket hitting available drivers--->", data);

      setAllDrivers(data.totalAvailableDrivers);
    });

    let dCTax = Number(orderData.driveOutChargeTax)
      ? 100 + Number(orderData.driveOutChargeTax)
      : 125;
    setdOCTax(dCTax);
    ///allAvailableDrivers();
    allAvailableDrivers(orderData.branchId);
    
  }, []);
  const btnstyle = {
    background: "transparent",
    border: "1px solid black",
    color: "black"
  };
  console.log("allDrivers socket---->", allDrivers);
  console.log(
    "all available drivers in allAvailableDriversReducer--> ",
    allAvailableDriversReducer
  );
  const deliveryTime = orderData.deliveryTime;

  const Completionist = () =>
    orderData && orderData.orderStatus != "PENDING" ? (
      <span style={{ color: "red", fontWeight: "bold" }}>
        ORDER TIME COMPLETED!
      </span>
    ) : (
      <span style={{ color: "blue", fontWeight: "bold" }}>
        ORDER CONFIRMATION IS PENDING
      </span>
    );
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const proInst = ({ items }) => {
    let list = "";
    if (items) {
      let i = 1;
      console.log("itemsssss", items);
      for (const [idx, itm] of items.entries()) {
        if (itm.instruction) {
          for (const [ix, inst] of itm.instruction.entries()) {
            console.log("inst", inst);
            let extraInstruction = inst.extraInstructions
              ? inst.extraInstructions
              : "";
            console.log("extraInstruction", extraInstruction);
            let extra =
              itm.extraItems && itm.extraItems[ix] ? itm.extraItems[ix] : [];

            let desInt = inst.instruction
              ? inst.instruction.replace(/(^,)|(,$)/g, "")
              : "";
            let det = [];
            if (!Array.isArray(desInt)) {
              det = desInt.split(",");
            }
            list += `<ul class="product_ul">`;
            list += `<li>${i}) For ${inst.quantity} ${inst.productName} -</li>`;
            if (desInt && det && det.length > 0) {
              console.log("inside dest Int");
              // list += `<ul>`;
              list += `<ul>`;
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i> Bruksanvisning</h5>`;
              list += `<ul style="list-style-type:none;font-size:15px;">`;

              if (Array.isArray(det)) {
                for (const [eix, v] of det.entries()) {
                  list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${v}</li>`;
                }
                if (extraInstruction) {
                  list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`;
                }
              } else {
                list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${det}</li>`;
                // if(extraInstruction){
                //   list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`
                // }
              }
              list += `</ul>`;
              list += `</ul>`;
              // list += `</ul>`;
            } else {
              list += `<ul>`;
              if (extraInstruction) {
                list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${extraInstruction}</li>`;
              }
            }
            list += `<ul>`;
            if (extra.length > 0) {
              list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i>  Ekstra produkt</h5>`;
              let ic = <RIcon size={15} icon={check} />;
              for (const [eix, exta] of extra.entries()) {
                list += `<ul style="list-style-type:none;font-size:15px;">`;
                list += `<li><i class="fa fa-check"></i> ${exta.name}</li>`;
                list += `</ul>`;
              }
            }
            list += `</ul>`;
            list += `</ul>`;
            list += `</ul>`;
            i++;
          }
        }
      }
      return list;
    }
  };
  const proInst2 = ({ items }) =>{
    let list = "";
    items.map((data, i) => {
      list += `<ul class="product_ul">`;
      list += `<li>${i+1}) For ${data.name} -</li>`;
      list += `<li><h5><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i>  Ekstra produkt</h5>`;
      list += `<ul style="list-style-type:none;font-size:15px;">`;
        data.extraNamesList.map(val => {
          list += `<li><i class="fa fa-check"></i> ${val}</li>`;
          return list;
        })
      list += `</ul>`;
      list += `</ul>`;
    })
    return list;
  }
  console.log("orderData inside treeDrive", orderData);
  const orderType = orderData.orderType;
  let driveoutCharge = parseFloat(orderData.driveoutCharge.toFixed(3));
  const dataSource = [
    {
      key: "1",
      // totalUnitPrice: `Kr.${orderData.items.reduce(
      //   // (acc, current) => acc + current.price, // prev
      //   (acc, current) =>{
      //     console.log("orderdata in total UNit price====>",orderData);
      //     console.log("current in total unit price=====>",current);
      //    if  (orderData.iDriveMyself){

      //     return acc + (current.totalPrice)*100/(orderData.tdExtraPriceIncrease+100) +orderData.iDriveMyselfPay
      //    }else{
      //     return acc + (current.totalPrice)*100/(orderData.tdExtraPriceIncrease+100)
      //    }
      //   },
      //   0
      // )}`,
      taxPriceInc: (
        orderData.items.reduce((acc, curr) => {
          acc = acc + (curr.price * curr.quantity + curr.extraPrice);
          return acc;
        }, 0) -
        (orderData.orderType == "Take Away"
          ? orderData.tdDiscountPickUpAbs
          : orderData.tdDiscountAbs)
      ).toFixed(2),
      discount: `Kr.${
        orderData.orderType == "Take Away"
          ? orderData.tdDiscountPickUpAbs
          : orderData.tdDiscountAbs
      }`,
      tdServiceCharge: `Kr.${orderData.tdServiceCharge}`,
      totalExtraPrice: `Kr.${orderData.items.reduce(
        (acc, current) =>
          acc +
          (current.extraPrice
            ? Number(
                (current.extraPrice * 100) /
                  (orderData.tdExtraPriceIncrease + 100)
              ).toFixed(2)
            : 0),
        0
      )}`,
      totalQuantity: orderData.items.reduce(
        (acc, current) => acc + current.quantity,
        0
      ),
      // total: `Kr.${
      //   orderData.items.reduce(
      //     (acc, current) =>{
      //       console.log("current in total---->",current.td)
      //      return acc +
      //       Number(Number((current.price)*100)/Number(100+Number(orderData.tdExtraPriceIncrease))) * current.quantity+
      //       (current.extraPrice ? Number(current.extraPrice*100/(100+orderData.tdExtraPriceIncrease)) : 0)
      //     }
      //      ,
      //     0
      //   )
      // }`,
      total: `${Number(
        orderData.items.reduce((acc, curr) => {
          acc += curr.price * curr.quantity + curr.extraPrice;
          return acc;
        }, 0) +
          orderData.tdServiceCharge +
          orderData.tdDriveOutCharge -
          (orderData.orderType == "Take Away"
            ? orderData.tdDiscountPickUpAbs
            : orderData.tdDiscountAbs
          ).toFixed(2)
      ).toFixed(2)}`,
      // driveoutTax:
      //   orderType == "Drive Out"  && orderData.iDriveMyself
      //     ?  `Kr.${(
      //       orderData.iDriveMyselfPay -
      //       (100 * (orderData.iDriveMyselfPay )) / 125
      //     ).toFixed(2)}`
      //     : "0",
      driveoutTax: orderData.isAppOrder
        ? `Kr.${(
            orderData.tdDriveOutCharge -
            orderData.tdDriveOutCharge / 1.25
          ).toFixed(2)}`
        : (
            orderData.tdDriveOutCharge -
            orderData.driveoutCharge / 1.25
          ).toFixed(2),
      // driveoutCharge:
      //   orderType == "Drive Out" && orderData.iDriveMyself
      //     ? ` Kr.${(
      //       (100 * (orderData.iDriveMyselfPay )) /
      //       125
      //     ).toFixed(2)}`
      //     : "0"

      driveoutCharge: orderData.isAppOrder
        ? ` Kr.${(orderData.tdDriveOutCharge / 1.25).toFixed(2)}`
        : (orderData.driveoutCharge / 1.25).toFixed(2)
    }
  ];

  const columns = [
    {
      title: "Drive Out Tax",
      dataIndex: "driveoutTax",
      width: "10%",
      key: "driveoutTax"
    },
    {
      title: "Drive Out Charge",
      dataIndex: "driveoutCharge",
      width: "15%",
      key: "driveoutCharge"
    },
    {
      title: "Total Unit Price",
      dataIndex: "taxPriceInc",
      width: "13%",
      key: "taxPriceInc"
    },
    // {
    //   title: "Total Extra Price",
    //   dataIndex: "totalExtraPrice",
    //   width: "13%",
    //   key: "totalExtraPrice"
    // },
    {
      title: `Service Charge`,
      dataIndex: "tdServiceCharge",
      width: "13%",
      key: "tdServiceCharge"
    },
    {
      title: "Total quantity",
      dataIndex: "totalQuantity",
      width: "15%",
      key: "totalQuantity"
    },

    {
      title: "Total",
      dataIndex: "total",
      width: "13%",
      key: "total"
    }
  ];

  const treeDriveDataSource = [
    {
      firstName: driverData.firstName,
      lastName: driverData.lastName,
      phoneNumber: driverData.phoneNumber
    }
  ];
  const driverColumns = [
    {
      title: "FirstName",
      dataIndex: "firstName",
      width: "10%",
      key: "driverFirstName"
    },
    {
      title: "LastName",
      dataIndex: "lastName",
      width: "15%",
      key: "driverLastName"
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: "13%",
      key: "driverPhoneNumber"
    }
  ];
  const handleCancel = () => {
    setTimemodalvisible(false);
    setTakeAwayModalVisible(false);
    setDriverModalVisible(false);
    setCancelReasonModel(false);
  };
  const validateTime = () => {
    console.log("inside the confirm button clicked--->", iDriveMyself);
    console.log("timeString-->", timeString);
    if (timeString) {
      changeStatus(
        "IN-PROCESS",
        orderData._id,
        timeString,
        orderData.orderType,
        iDriveMyself
      );
    }else{
      changeStatus(
        "IN-PROCESS",
        orderData._id,
        timeString = orderData.requestedTime,
        orderData.orderType,
        iDriveMyself
      );
    }
  };
  const validateStaticTime= (orderId,orderType,time) => {
    console.log("inside the confirm button clicked--->", iDriveMyself);
    console.log("timeString-->------------------", time);
  if (orderType == "Drive Out"){
    searchDriver(orderId,orderType,time)
    setDriverModalVisible(false)
  }else{

    if (time) {
      changeStatus(
        "IN-PROCESS",
        orderId,
        time,
        orderType,
        iDriveMyself
      );
    }
  }
    
  };


  const SearchDriverModel = (orderType,orderId) => {
    console.log(('-----orderType------',orderType))

    return (
      <Modal
        title="Select Time"
        visible={driverModalVisible}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
         
        ]}
        closable={false}
      >
       


       
          
         <div className="d-flex justify-content-around">
         <Button
            key="submit"
            className="btn-ab"
            onClick={() =>    validateStaticTime(orderId,orderType,"00 Hours:30 Minutes")}
          >
            30 Min
          </Button>
        <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateStaticTime(orderId,orderType,"00 Hours:40 Minutes")}
          >
            40 Min
          </Button>
         </div>
       
      </Modal>
    );
  };
  const TakeAwaytimeModel = (orderType,orderId) => {
    console.log(('-----orderType------',orderType))

    return (
      <Modal
        title="Select Time"
        visible={takeAwayModalVisible}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
         
        ]}
        closable={false}
      >
      {
        orderData.requestedTime == "" ?
        <div className="d-flex justify-content-around">
      <Button
            key="submit"
            className="btn-ab"
            onClick={() =>    validateStaticTime(orderId,orderType,"00:15")}
          >
            15 Min
          </Button>
        <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateStaticTime(orderId,orderType,"00:20")}
          >
            20 Min
          </Button>
        <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateStaticTime(orderId,orderType,"00:25")}
          >
            25 Min
          </Button>
        <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateStaticTime(orderId,orderType,"00:30")}
          >
            30 Min
          </Button>

          <Button
            key="submit"
            className="btn"
            onClick={() => setTimemodalvisible(true)}
          >
            Set Time
          </Button>
      </div> 
        :
      <div>
      <div className="d-flex justify-content-around">
        <p className="requestedTimeClass1">User Requested Time :-  
        <span className="requestedTimeClass pl-2">
        {orderData.requestedTime}
        </span>
        </p>
        {/* <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateStaticTime(orderId,orderType,orderData.timeGap)}
          >
            Ok
          </Button> */}
          {/* <Button
            key="submit"
            className="btn-ab"
            onClick={() => setTimemodalvisible(true)}
          >
            Change Time
          </Button> */}
        </div>
        <div className="d-flex justify-content-around">
        <p className="requestedTimeClass1 ml-2">Change Time :-  
        <TimePicker
            style={{ width: "40%" }}
            className="ml-2"
            onChange={onChangetime}
            defaultValue={moment(orderData.requestedTime, "HH:mm")}
            format={"HH:mm"}
            minuteStep={10}
            placeholder=""
          />
            <Button
            key="submit"
            className="btn ml-2"
            onClick={() => validateTime()}
          >
            Confirm
          </Button>
        </p>
        </div>
      </div>
      }
      </Modal>
    );
  };
  const timeModel = (orderType,orderId) => {
    console.log(('-----orderType------',orderType))
    return (
      <Modal
        title="Select Time"
        visible={timemodalvisible}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateTime()}
          >
            Submit
          </Button>
        ]}
        closable={false}
      >
    
         { 
        <>
         <div className="d-flex justify-content-around">
        <p className="requestedTimeClass1">User Requested Time :-  
        <span className="requestedTimeClass pl-2">
        {orderData.requestedTime}
        </span>
        </p>
     </div>
     <div style={{ textAlign: "center", alignSelf: "center" }}> 
     <p className="requestedTimeClass1 ml-2">Change Time :- 
          <TimePicker
            style={{ width: "40%" }}
 className="ml-2"
            onChange={onChangetime}
            defaultValue={moment(orderData.requestedTime, "HH:mm")}
            format={"HH:mm"}
            minuteStep={10}
          />
     </p>  
        </div>
     </> 

        
        }
      </Modal>
    );
  };
  socket.on("driverFound", async (data) => {
    console.log("data after driver found socket it--->", data);
  });
  const searchDriver = async (orderId, orderType,time) => {
    if (orderType == "Take Away") {
      return;
    } else {
      console.log("order id in search driver buttion ------>", orderId);
      setSearchDrivers("Searching drivers.....Please wait");
      const res = await searchDriverAction(orderId,time);
      // socket.on("driverFound", async (data) => {
      //   console.log("data after driver found socket it--->",data)
      //  })
    }
  };
  const validateResion = () => {
    if (cancelReason) {
      changeStatus("CANCEL", orderData._id, timeString, orderData.orderType);
    } else {
      setResionError("Please Select Reason");
    }
  };
  console.log("searchDriverReducer-------->", searchDriverReducer);
  const resionModel = () => {
    return (
      <Modal
        title="Select Reason for cancelling order"
        visible={cancelReasonModel}
        footer={[
          <Button
            key="back"
            className="btn btn-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="btn-ab"
            onClick={() => validateResion()}
          >
            Submit
          </Button>
        ]}
      >
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => setCancelReason(e.target.value)}
            defautValue={cancelReason}
          >
            <Radio.Button value="Item Not Available">
              Items Not present
            </Radio.Button>
            <Radio.Button value="No One to deliver">
              No One to deliver
            </Radio.Button>
            <Radio.Button value="Restaurant closed">
              Restaurant Closed
            </Radio.Button>
            <Radio.Button value=" Not accepting orders">
              Not accepting orders
            </Radio.Button>
            <Radio.Button value="No Drivers Available">
              No Drivers Available
            </Radio.Button>
          </Radio.Group>
          <p className="text-center text-danger">{resionError}</p>
        </div>
      </Modal>
    );
  };
  const onChangetime = (time, timeString) => {
    setTimeString(timeString);
    console.log("time string",timeString )
  };
  return (
    <>
      <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
          <span id="less-visible">Rapporter / </span>
          Tree Drive Order
        </span>
        <span id="less-visible">
          <Button onClick={() => setPage("list")} className="ant-btn back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </Button>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card
                className="od"
                style={{ width: "100%", wordWrap: "break-word" }}
              >
                <Card.Body>
                  {orderData.appUserId[0] &&
                    orderData.orderType == "Drive Out" && (
                      <>
                        <p>
                          <strong>OTP: {orderData.otp}</strong>
                        </p>
                        <Countdown
                          style={{ color: "red" }}
                          date={
                            moment(orderData.updatedAt) + Number(deliveryTime)
                          }
                          renderer={renderer}
                        />
                        <p>
                          Name:&nbsp;&nbsp;
                          {/*  {`TREE DRIVE`} */}
                          {`${orderData.appUserId[0].firstName} ${orderData.appUserId[0].lastName}`}
                        </p>
                        <p>
                          Phone No.:&nbsp;&nbsp;
                          {/* {`+4791638384`} */}
                          {orderData.appUserId[0].phoneNumber}
                        </p>
                        <p>
                          Email: &nbsp;&nbsp;
                          {/* {`treedrive@yopmail.com`} */}
                          {orderData.appUserId[0].email}
                        </p>

                        {orderData.orderStatus == "CANCEL" ? (
                          <span>
                            <p>
                              Order Status:{" "}
                              <b style={{ color: "red" }}>CANCELLED</b>
                            </p>
                            <p style={{ color: "red" }}>
                              <b>Cancel Reason :</b>
                              {orderData.cancelReason}
                            </p>
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  {orderData.appUserId[0] &&
                    orderData.orderType == "Take Away" && (
                      <>
                        <p>
                          <strong>OTP: {orderData.otp}</strong>
                        </p>
                        <Countdown
                          style={{ color: "red" }}
                          date={
                            moment(orderData.updatedAt) + Number(deliveryTime)
                          }
                          renderer={renderer}
                        />
                        <p>
                          Name: {/* {`TREE DRIVE`} */}
                          {`${orderData.appUserId[0].firstName} ${orderData.appUserId[0].lastName}`}
                        </p>
                        <p>
                          Phone No.: {/* {`+4791638384`} */}
                          {orderData.appUserId[0].phoneNumber}
                        </p>
                        <p>
                          Email: {/* {`treedrive@yopmail.com`} */}
                          {orderData.appUserId[0].email}
                        </p>

                        {orderData.orderStatus == "CANCEL" ? (
                          <span>
                            <p>
                              Order Status:{" "}
                              <b style={{ color: "red" }}>CANCELLED</b>
                            </p>
                            <p style={{ color: "red" }}>
                              <b>Cancel Reason :</b>
                              {orderData.cancelReason}
                            </p>
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  {orderData.appUserId[0] &&
                    orderData.appUserId[0].address &&
                    orderData.orderType == "Take Away" && (
                      <>
                        <div style={{ wordWrap: "break-word" }}>
                          <p>
                            Address:
                            {orderData.appUserId[0].address.line1}{" "}
                            {orderData.appUserId[0].address.line2}
                          </p>
                          <p>
                            {orderData.appUserId[0].address.city
                              ? orderData.appUserId[0].address.city
                              : ""}{" "}
                            {orderData.appUserId[0].address.state
                              ? "," + orderData.appUserId[0].address.state
                              : ""}
                          </p>
                          <p>{orderData.appUserId[0].address.pin}</p>
                          <p>{orderData.appUserId[0].address.country}</p>
                        </div>
                      </>
                    )}
                  {orderData.isAppOrder === true &&
                  orderData.orderType == "Drive Out" &&
                  orderData.iDriveMyself == false ? (
                    <h1>Total Available Drivers:{allDrivers}</h1>
                  ) : (
                    ""
                  )}
                  {orderData.isAppOrder === false &&
                    orderData.orderStatus !== "DELIVERED" && (
                      <>
                        {orderData.orderStatus === "PENDING" && (
                          <Button
                            style={btnstyle}
                            onClick={() => setTimemodalvisible(true)}
                          >
                            Confirm
                          </Button>
                        )}
                        {orderData.orderStatus === "IN-PROCESS" && (
                          <Button
                            className="btn-ab"
                            onClick={() =>
                              changeStatus(
                                "DELIVERED",
                                orderData._id,
                                timeString,
                                orderData.orderType
                              )
                            }
                          >
                            Deliver
                          </Button>
                        )}
                        {"  "}
                        {(orderData.orderStatus === "PENDING" ||
                          orderData.orderStatus === "IN-PROCESS") && (
                          <Button
                            style={btnstyle}
                            onClick={() => setCancelReasonModel(true)}
                          >
                            Cancel
                          </Button>
                        )}
                      </>
                    )}
                  {orderData.isAppOrder === true &&
                    orderData.orderStatus !== "DELIVERED" && (
                      <>
                        {/* if first we have to do search driver then confirm then comment out this */}

                        {orderData.orderStatus === "PENDING" &&
                          orderType === "Take Away" && (
                            <Button
                              style={btnstyle}
                              onClick={() => setTakeAwayModalVisible(true)}
                            >
                              Confirm
                            </Button>
                          )}

                        {orderData.orderStatus === "PENDING" && isFindDriver&& isFindDriver== "true" && 
                          foundDriver === false &&
                          orderType !== "Take Away" && (
                            <Button
                              style={btnstyle}
                              // onClick={() => searchDriver(orderData._id)}
                              // onClick={() => setTimemodalvisible(true)}
                              onClick={() => setDriverModalVisible(true)}
                            >
                              {searchDrivers}
                            </Button>
                          )}
                        {/* {orderData.orderStatus==="PENDING" && foundDriver && (
                        <Button
                          style={btnstyle}
                         onClick={() => setTimemodalvisible(true)}
                        >
                         Confirm
                        </Button>
                      )} */}
                        {/* {orderData.orderStatus==="PENDING" && (foundDriver===false) && (
                         <Button
                         style={btnstyle}
                         onClick={() => setTimemodalvisible(true)}
                       >
                         Confirm
                       </Button>
                      )} */}
                        {orderData.orderStatus === "PENDING" &&
                          foundDriver === false &&
                          DriveMyselfPermission &&
                          DriveMyselfPermission == "true" &&
                          orderType !== "Take Away" && (
                            <Button
                              style={btnstyle}
                              onClick={() => {
                                setIDriveMyself(true);
                                console.log(
                                  "inside the button clicked on i drive myself---->",
                                  iDriveMyself
                                );
                                setTimemodalvisible(true);
                              }}
                            >
                              I Drive Myself
                            </Button>
                          )}
{/* 
                        {orderData.orderType == "Drive Out" &&
                          orderData.orderStatus === "IN-PROCESS" &&
                          foundDriver === false &&
                          orderData.iDriveMyself == false && (
                            <Button
                              style={btnstyle}
                              onClick={() =>
                                searchDriver(orderData._id, "Take Away")
                              }
                            >
                              {searchDrivers}
                            </Button>
                          )} */}


                          {/* driveMyself Deliver Case  */}
                        {orderData.orderStatus === "IN-PROCESS" &&
                          orderData.orderType !== "Take Away" &&
                          (orderData.iDriveMyself) && (
                            <>
                              {/* <div>Order confirmed by Restaurant</div> */}
                              <Button
                                className="btn-ab"
                                onClick={() =>
                                  changeStatus(
                                    "DELIVERED",
                                    orderData._id,
                                    timeString,
                                    orderData.orderType,
                                    orderData.iDriveMyself
                                  )
                                }
                              >
                                DELIVER
                              </Button>
                            </>
                          )}


    {/* driver Deliver Case  */}
                          {orderData.orderStatus === "IN-PROCESS" &&
                          orderData.orderType == "Drive Out" &&
                          (!orderData.iDriveMyself) && (
                            <>
                              {/* <div>Order confirmed by Restaurant</div> */}
                              <Button
                                className="btn-ab"
                                onClick={() =>
                                  changeStatus(
                                    "DELIVERED",
                                    orderData._id,
                                    timeString,
                                    orderData.orderType,
                                    true
                                  )
                                }
                              >
                               Handover 
                              </Button>
                            </>
                          )}


{/* Take Deliver Case  */}
                          {orderData.orderStatus === "IN-PROCESS" &&
                          orderData.orderType == "Take Away" &&
                           (
                            <>
                              {/* <div>Order confirmed by Restaurant</div> */}
                              <Button
                                className="btn-ab"
                                onClick={() =>
                                  changeStatus(
                                    "DELIVERED",
                                    orderData._id,
                                    timeString,
                                    orderData.orderType,
                                  )
                                }
                              >
                                HANDOVER
                              </Button>
                            </>
                          )}

                        {/* {orderData.orderStatus === "IN-PROCESS" &&
                          orderData.orderType == "Take Away" && (
                            <Button
                              className="btn-ab"
                              onClick={() =>
                                changeStatus(
                                  "DELIVERED",
                                  orderData._id,
                                  timeString,
                                  orderData.orderType
                                )
                              }
                            >
                              HANDOVER
                            </Button>
                          )} */}
                        {"  "}
                        {(orderData.orderStatus === "PENDING" ||
                          orderData.orderStatus === "IN-PROCESS") && (
                          <Button
                            style={btnstyle}
                            onClick={() => setCancelReasonModel(true)}
                          >
                            Cancel
                          </Button>
                        )}
                      </>
                    )}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <div className="mycontent-right">
                    <>
                      <h5>Instruksjoner av kunden</h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: orderData.items[0].extraNamesList ? proInst2(orderData) : proInst(orderData)
                        }}
                      />
                    </>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {foundDriver || orderData.driverId ? (
            <>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Table
                        bordered
                        dataSource={treeDriveDataSource}
                        columns={driverColumns}
                        pagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table
                    bordered
                    dataSource={orderData.items}
                    pagination={false}
                    style={{ borderBlockStartColor: "red" }}
                  >
                    <Column title="Product Name" dataIndex="name" key="name" />
                    <Column title="Item No." dataIndex="itemNo" key="itemNo" />
                    <Column
                      title="Unit Price"
                      dataIndex="price"
                      // render={(text) => `Kr.${Number(text*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2)}`}
                      render={(text, render) =>
                        `Kr.${Number(render.price).toFixed(2)}`
                      }
                      key="price"
                    />
                    <Column
                      title="Quantity"
                      dataIndex="quantity"
                      key="quantity"
                    />
                    <Column
                      title="Extra Item Price"
                      dataIndex="extraPrice"
                      render={(text, record) =>
                        // `Kr.${record.extraPrice ? Number(record.extraPrice*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2): 0}`
                        `Kr.${Number(record.extraPrice).toFixed(2)}`
                      }
                      key="extraPrice"
                    />
                    <Column
                      title="Discount"
                      dataIndex="tdDiscount"
                      render={(text, record) =>
                        // `Kr.${record.extraPrice ? Number(record.extraPrice*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2): 0}`
                        `Kr.${
                          orderData.orderType == "Take Away"
                            ? Number((
                                record.price * record.quantity +
                                record.extraPrice
                              ) *(orderData.tdDiscountPickUp / 100)).toFixed(2)
                            : Number((record.price * record.quantity +record.extraPrice) *(orderData.tdDiscount / 100)).toFixed(2)
                        }`
                      }
                      key="tdDiscount"
                    />

                    <Column
                      title="Total Price"
                      dataIndex="totalPrice"
                      render={
                        (text, record) => {
                          // let price=Number(text*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2);
                          // let extraPrice=record.extraPrice ? Number(record.extraPrice*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2): 0;
                          // let totalPrice=Number(price)+Number(extraPrice);
                          // return `Kr.${totalPrice}`
                          // return `Kr.${orderData.totalPrice}`
                          return `Kr.${
                            orderData.orderType == "Take Away"
                              ? (
                                  record.price * record.quantity +
                                  record.extraPrice -
                                  (record.price * record.quantity +
                                    record.extraPrice) *
                                    (orderData.tdDiscountPickUp / 100)
                                ).toFixed(2)
                              : (
                                record.price * record.quantity +
                                record.extraPrice -
                                (record.price * record.quantity +
                                  record.extraPrice) *
                                  (orderData.tdDiscount / 100)
                              ).toFixed(2)
                          }`;
                          // `Kr.${Number(text*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2)}`
                        }
                        // `Kr.${Number(
                        //   text + (record.extraPrice ? record.extraPrice : 0)
                        // )}`
                      }
                      key="totalPrice"
                    />
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {timeModel(orderData.orderType,orderData._id)}
      {SearchDriverModel(orderData.orderType,orderData._id)}
      {TakeAwaytimeModel(orderData.orderType,orderData._id)}
      {resionModel()}
    </>
  );
};
export default View;
