import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTablesData } from "../../action/bordAction";
import Home from "./../Home";
import BordCard from "./BordCard";
import { Col, Row } from "react-bootstrap";
import { getallTables } from "../../action/tableAction";
import "./bord.css";
import "./style.css";
// import {} from "./../../action/bordAction"

const Bord = () => {
  const dispatch = useDispatch();
  const { activeTables, driveOut, takeAway, noTable } = useSelector(
    (state) => state.allTables
  );

  useEffect(() => {
    dispatch(getallTables());
  }, []);

  const showTables = (tableName) => {
    return (
      <>
        <Row className="justify-content-md-center">
          {tableName.length > 0
            ? tableName.map((item) => {
                return (
                  <>
                    <Col md="auto" className="items gutter-row">
                      <BordCard item={item} />
                    </Col>
                  </>
                );
              })
            : ""}
        </Row>
      </>
    );
  };

  return (
    <>
      <Home>
        <div className="report-container text-center">
          <span className="item ">Bord</span>
        </div>
        {activeTables.length > 0 && <h1 className="heading">Active Tables </h1>}
        {showTables(activeTables)}
        {driveOut.length > 0 && <h1 className="heading">Drive Out </h1>}
        {showTables(driveOut)}
        {takeAway.length > 0 && <h1 className="heading">Take Away </h1>}
        {showTables(takeAway)}
        {noTable.length > 0 && <h1 className="heading">No Table </h1>}
        {showTables(noTable)}
      </Home>
    </>
  );
};

export default Bord;
