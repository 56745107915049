import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Divider, Table, Avatar,Icon, Button } from 'antd'
import moment from 'moment'
import appConfig from '../../../../helpers/appConfig';
import './DailyBills.css'

const { Meta } = Card;

const RetailerSingleBill = (props) => {

    let { SingleBills, setSingleBill } = props;
    // const [ isCustomerDetail , setIsCustomerDetail] = useState(false);
    let logo = localStorage.getItem("logo")
    let slug = localStorage.getItem("slug")
    const taxArray = props.billData.taxArr;
    const ServiceCharges = props.billData.tdServiceCharge;
    const driveoutCharge = props.billData.driveoutCharge;
    // const discount = props.billData.discount;
    let date = props.billData.created;
    let orderType = props.billData.orderType;
    let resName = props.billData.customerDetail.name;
    let resEmail = props.billData.customerDetail.email;
    let resPhone= props.billData.customerDetail.phoneNo;
    let resAddress = props.billData.customerDetail.address;
    // let userEmail = props.billData.customerDetail.userEmail;
    // let customerPhone = props.billData.customerDetail.customerPhone;

    // Calculate sub-total 
    let TotalTax = 0;
    taxArray.map((e) => {
        TotalTax += e.taxTotal
    })



    const gridStyle = {
        flex: "1 0 50%",
        maxWidth: "50%",
        padding: "10px",
        boxShadow: "none",
        border: "none"
    };
    const fullWidth = {
        flex: "1 0 100%",
        boxShadow: "none",
        border: "none"
    };

    const columns = [
        {
            title: "Sr No.",
            dataIndex: "no",
            render: (text, record, index) => index + 1
        },
        {
            title: "Product Name",
            dataIndex: "name"
        },
        {
            title: "Quantity",
            render: (text, record) => {
                return `${record.quantity}pkt X ${record.availQuantity}${record.unitType}`;
            },
    
        },
        {
            title: "Price",
            render: (text, record) => {
                return `Kr.${record.price.toFixed(2)}`;
              },
            
        },
        // {
        //     title: "MVA %",
        //     dataIndex: "prodTax"
        //    // render: (text, record) => {record.prodTax}
        // },
        // {
        //     title: "MVA Price",
        //    render: (text, record) => {
        //     let totalMva = 0;
        //     totalMva += record.price - (100 * record.price)/(100 + record.prodTax)
        //     return `Kr. ${Number(totalMva).toFixed(2)}`;
        //   },
        // },
        {
            title: "Total Price",
            render: (text, record) => {
                return `Kr.${record.totalPrice.toFixed(2)}`;
              },
        }
    ];

    const ReportSummaryData = [
        {
          title: "taxArr",
        },
      ];

      const ReportSummary = (
        totalQuantity,
        totalTaxAmount,
        taxvalue,
        totalPrice
      ) => [
          {
            title: "Product",
            width: "18%",
            render: () => <strong>Total</strong>,
          },
          {
            title: "Quantity",
            width: "15%",
            key: "totalQuantity",
            render: () => totalQuantity.toFixed(2),
          },
          {
            title: `Mva ${taxvalue}%`,
            width: "20%",
            key: "prodTax",
            render: () => totalTaxAmount.toFixed(2),
          },
          {
            title: "Net Sum(Kr.)",
            width: "20%",
            render: () => (totalPrice - totalTaxAmount).toFixed(2),
          },
          {
            title: `Total(Kr.) `,
            width: "30%",
            key: "name",
            render: () => `Kr.${totalPrice.toFixed(2)}`,
          },
        ];
    
    const goBackMainPage = () => {
        if (SingleBills) {
            props.history.push("/retailerDailyBill");
        }
        setSingleBill(false)
    };

    console.log("GET DATA BY PROPS =====>", props.billData)

    const printbill = () => {
        window.print();
    };

  return (
    <div>

    <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
            <span id="less-visible">Hjem / </span>
            Invoice No.  {" "}
            {props.billData.invoiceNumber}
        </span>
        <span id="less-visible">
            <Button onClick={() => goBackMainPage()} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </Button>
        </span>
    </div>
    <div
        id="singleinvoice"
        style={{
            margin: "10px auto",
            borderWidth: 1,
            borderColor: "green"
        }}
        className="single-invoice"
    >
        <Card className="p-3">
            <Row>
                {/* <div
                    id="printbtn"
                    className="d-flex align-items-center justify-content-between"
                >
                    {/* <Meta
                        avatar={
                            <Avatar
                                src={
                                    `${appConfig.s3url}/${logo}`
                                }
                            />
                        }
                        title={slug}
                    /> */}
                {/* <Meta>
                    </Meta> */}
                {/* <Button
                        //onClick={printbill}
                        style={{
                            background: "transparent",
                            color: "black",
                            border: "1px solid grey",
                            float: "right",
                            marginLeft: "120px"
                        }}
                    >
                        Print
                    </Button> */}
                {/* </div> */}
            </Row>
            {/* <Divider /> */}
            <div className='d-flex justify-content-end'>
                <div className=' mb-2'
                    id="printbtn">
                    <Button
                        onClick={() => printbill()}
                        type="primary"
                        size="large"
                        className="btn-ab  text-color tablePrint"
                    >
                    Print
                    </Button>
                </div>
            </div>
            <Row style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                <Card.Grid style={gridStyle}>
                    <>
                        <p>
                            <b>Name</b>: {resName}
                        </p>
                        <p>
                            <b>Phone </b>: {resPhone}
                        </p>
                        <p>
                            <b>Email</b>: {resEmail}
                        </p>
                        <p>
                            <b>Address</b>: {resAddress}
                        </p> 
                        {/* { userEmail ?
                        <p>
                            <b> User Email</b>: {userEmail}
                        </p> :""
                        }
                        {
                            customerPhone ?
                        <p>
                            <b>Customer Phone</b>: {customerPhone}
                        </p>
                        : ""
                        } */}
                    </> 
            
                </Card.Grid>
                <Card.Grid style={{ ...gridStyle, textAlign: "right" }}>
                    <p>
                        <b>Invoice No. </b>:  {props.billData.invoiceNumber}
                    </p>
                    <p>
                        <b>Date</b>: {`${moment(date).format("DD/MM/YYYY")}`}
                    </p>
                    <p>
                        <b>Time </b>: {`${new Date(date).toLocaleTimeString()}`}
                    </p>
                    <p>
                        <b>Order type</b>:  {orderType.charAt(0).toUpperCase() + orderType.slice(1)}
                    </p>
                </Card.Grid>
                <hr />
                {/* <h4 style={{ marginLeft: 10, fontWeight: "bold", color: "grey" }}>
                    Tree Drive Order
                </h4> */}
                <Table
                    bordered
                    id="producttab"
                    style={{ ...fullWidth, marginTop: 20 }}
                    columns={columns}
                    dataSource={props.billData.items}
                    size="middle"
                    pagination={false}
            //         footer={
            //         props.billData.items > 0
            //       ? () => (
            //         <>
            //           <Table
            //             dataSource={ReportSummaryData}
            //             // columns={ReportSummary(
            //             //   e.totalQuantity,
            //             //   e.totalTaxAmount,
            //             //   e.taxvalue,
            //             //   e.totalPrice
            //             // )}
            //             showHeader={false}
            //             pagination={false}
            //             rowClassName="highlighted-bg"
            //           //rowKey={props.rowKey}
            //           />
            //         </>
            //       )
            //       : ""
            //   }
                />
                <Card.Grid
                    style={{
                        ...fullWidth,
                        textAlign: "right",
                        paddingRight: "0 40px",
                        lineHeight: 1.65
                    }}
                >
                    <>
                        <p>
                            <span style={{ fontWeight: 700 }}>Sub-Total: </span>
                            <span style={{ minWidth: "100px", display: "inline-block" }}>
                                {`Kr.${(Number(props.billData.totalPrice) - (TotalTax) -(driveoutCharge)).toFixed(2)}`}
                            </span>
                        </p>
                        {taxArray.map((e) => {
                            return (
                                <p>
                                    <span style={{ fontWeight: 700 }}>
                                        Mva(
                                        {`${e.tax}%`}):{" "}
                                    </span>
                                    <span style={{ minWidth: "100px", display: "inline-block" }}>
                                        {`Kr.${e.taxTotal.toFixed(2)}`}
                                    </span>
                                </p>
                            )
                        })}
                        <>
                            {/* <p>
                                <span style={{ fontWeight: 700 }}>Discount: </span>
                                <span style={{ minWidth: "100px", display: "inline-block" }}>
                                    {`Kr. ${discount}`}
                                </span>
                            </p> */}
                            <p>
                                <span style={{ fontWeight: 700 }}>Service Charges: </span>
                                <span style={{ minWidth: "100px", display: "inline-block" }}>
                                    {`Kr. ${ServiceCharges}`}
                                </span>
                            </p>
                            <p>
                                <span style={{ fontWeight: 700 }}>Driveout Charges: </span>
                                <span style={{ minWidth: "100px", display: "inline-block" }}>
                                    {`Kr. ${driveoutCharge}`}
                                </span>
                            </p>
                            <span style={{ fontWeight: 700 }}>Total:</span>
                            <span style={{ minWidth: "100px", display: "inline-block" }}>
                                {/* {`Kr.${totalPrice.toFixed(2)}`} */}
                                {`Kr. ${Number(props.billData.totalPrice).toFixed(2)}`}
                            </span>
                        </>

                    </>
                </Card.Grid>

            </Row>
        </Card>

    </div>

</div>     
  )
}

export default RetailerSingleBill
