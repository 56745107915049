import React, { Component, Fragment } from "react";
import appConfig from "../../helpers/appConfig";

const logo = localStorage.getItem("logo");

const logoUrl = `${appConfig.s3}/${logo}`;

const styles = {
  borderCollapse: "collapse",
  border: "1px solid black",
  width: "90%",
  padding: "0 5px",
  margin: "2% 5%"
  //border: "solid 1px"
};

const styles2 = {
  padding: "2px 5px"
};

class PrintReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: ""
    };
  }

  render() {
    const {
      columns,
      data,
      type,
      branch,
      totalSubtotal,
      totalTax,
      totalTaxPrice,
      total15Tax,
      total25Tax,
      total15Quantity,
      total25Quantity,
      totalDiscount,
      finalTotal,
      unitPriceTotal
    } = this.props;

    if (type === "daily-reports" && branch.address !== undefined) {
      return (
        <>
          <table className="print-table" style={styles} border="1">
            <thead>
              <tr>
                {columns.length > 0
                  ? columns.map((item) => {
                      if (item.title !== "Invoice") {
                        return <th style={styles2}>{item.title}</th>;
                      }
                    })
                  : ""}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span
                    style={{
                      display: "flex",
                      margin: "2% 5%",
                      justifyContent: "space-between"
                    }}
                  >
                    <img
                      src={logoUrl}
                      style={{
                        maxHeight: 160
                      }}
                    />
                    <span>
                      <p>{branch.name}</p>
                      <p>Phone Number: {branch.phoneNo}</p>
                      <p>
                        Email: {branch.email} | Website: {branch.website}
                      </p>
                      <p>Tax Number: {branch.taxNo}</p>
                      <p>Account Number{branch.accountNumber}</p>
                      <p>
                        {`${branch.address.line1}, ${branch.address.line2}, ${branch.address.city}, ${branch.address.pin}`}
                      </p>
                      <p>{`${branch.address.state} ${branch.address.country}`}</p>
                    </span>
                  </span>
                </td>
              </tr>
              {data.length > 0 ? (
                data.map((item, index) => {
                  return (
                    <tr>
                      <td style={styles2}>{item.invoiceNumber}</td>
                      <td>{new Date(item.created).toLocaleDateString()}</td>
                      <td>{new Date(item.created).toLocaleTimeString()}</td>
                      <td>{item.orderType}</td>
                      <td>
                        Kr.
                        {(
                          item.taxPrice -
                          (
                            item.taxPrice -
                            (100 * item.taxPrice) / (100 + item.tax)
                          ).toFixed(3)
                        ).toFixed(2)}
                      </td>
                      <td>
                        Kr.
                        {(
                          item.taxPrice -
                          (100 * item.taxPrice) / (100 + item.tax)
                        ).toFixed(2)}
                      </td>
                      <td>Kr.{item.taxPrice}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                <td>Kr.{totalSubtotal.toFixed(2)}</td>
                <td>Kr.{totalTax.toFixed(2)}</td>
                <td>Kr.{totalTaxPrice.toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </>
      );
    } else if (type === "x-reports" && branch.address !== undefined) {
      return (
        <>
          <table className="print-table" style={styles}>
            <span
              style={{
                display: "flex",
                margin: "2% 5%",
                justifyContent: "space-between"
              }}
            >
              <img
                src={logoUrl}
                style={{
                  maxHeight: 160
                }}
              />
              <span>
                <p>{branch.name}</p>
                <p>Phone Number: {branch.phoneNo}</p>
                <p>
                  Email: {branch.email} | Website: {branch.website}
                </p>
                <p>Tax Number: {branch.taxNo}</p>
                <p>Account Number: {branch.accountNumber}</p>
                <p>Address:</p>
                <p>
                  {`${branch.address.line1}, ${branch.address.line2}, ${branch.address.city}, ${branch.address.pin}`}
                </p>
                <p>{`${branch.address.state} ${branch.address.country}`}</p>
              </span>
            </span>
            <thead>
              <tr>
                {columns.length > 0
                  ? columns.map((item) => {
                      if (item.title === "Tax" || item.title === "Quantity") {
                        return (
                          <th colSpan="2" style={{ textAlign: "center" }}>
                            {item.title}
                          </th>
                        );
                      } else if (item.title === "Invoice") {
                      } else {
                        return (
                          <th style={{ textAlign: "center" }}>{item.title}</th>
                        );
                      }
                    })
                  : ""}
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th />
                <th />
                <th>15%</th>
                <th>25%</th>
                <th />
                <th>15%</th>
                <th>25%</th>
                <th />
                <th />
              </tr>
              {data.length > 0
                ? data.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.productName}</td>
                        <td>
                          Kr. {item.taxPrice15 && item.taxPrice15.toFixed(2)}
                        </td>
                        <td>
                          Kr.{item.taxPrice25 && item.taxPrice25.toFixed(2)}
                        </td>
                        <td>
                          Kr.{" "}
                          {item.productPrice && item.productPrice.toFixed(2)}
                        </td>
                        <td>{item.unitoftax15}</td>
                        <td>{item.unitoftax25}</td>
                        <td>
                          Kr.
                          {item.discountPrice && item.discountPrice.toFixed(2)}
                        </td>
                        <td>Kr.{item.total && item.total.toFixed(2)}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2">Total</th>
                <td>Kr.{total15Tax && total15Tax.toFixed(2)}</td>
                <td>Kr.{total25Tax && total25Tax.toFixed(2)}</td>
                <td>Kr.{unitPriceTotal && unitPriceTotal.toFixed(2)}</td>
                <td>{total15Quantity}</td>
                <td>{total25Quantity}</td>
                <td>Kr.{totalDiscount && totalDiscount.toFixed(2)}</td>
                <td>Kr.{finalTotal && finalTotal.toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </>
      );
    } else if (type === "online") {
      return <>sd</>;
    } else {
      return <div>Error!!</div>;
    }
  }
}

export default PrintReport;
