import { GET_COSTOMERS_INVOICE } from "./../../action/types";
const initialState = {
  invoiceData: [],
  branch: {}
};
const KunderInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COSTOMERS_INVOICE:
      let data = action.payload.data.invoiceData.map((item) => ({
        ...item,
        createdDate: new Date(item.created).toLocaleDateString(),
        createdTime: new Date(item.created).toLocaleTimeString(),
        taxPrice: item.taxPrice.toFixed(3),
        isLoaded: true
      }));
      var invoiceToLoad = data.reverse();
      return {
        invoiceData: invoiceToLoad,
        branch: action.payload.data.branch
      };
    default:
      return state;
  }
};
export default KunderInvoiceReducer;
