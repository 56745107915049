import React, { useEffect, useState } from "react";
import {
  getDailyReportDining,
  getDailyReportPrint,
  viewDailyReport,
  changeDeliveryStatusAction,
} from "../../../action/ReportAction";
import { connect } from "react-redux";
import "./Reports.css";
import List from "./List";
import ViewReport from "./ViewReport";
import { setDefaultDates } from "../../../helpers/commonDates";
import moment from "moment";

const DailyReport = (props) => {
  let [page, setPage] = useState("list");
  let [date, setDate] = useState(moment().date()._d);
  let [isLoaded, setIsLoaded] = useState(false);
  let [deliverStatus, setDeliverStatus] = useState(false);
  useEffect(() => {
    async function fetchData(tSDay, tEDay) {
      const data = {
        startDate: tSDay,
        endDate: tEDay,
      };
      setIsLoaded(true);
      await props.getDailyReportDining(data).then(() => setIsLoaded(false));
    }
    const { tSDay, tEDay } = setDefaultDates(date);
    fetchData(tSDay, tEDay);
  }, [date]);
  const handleDateChange = (date) => {
    setDate(date);
  };

  const changeDeliveryStatus = async (id, tableNumber) => {
    async function fetchData(tSDay, tEDay) {
      //anas sir code commented out
      // const data = {
      //   startDate: tSDay,
      //   endDate: tEDay,
      //   id: id,
      //   tableNumber: tableNumber,
      // };
      //end of anas sir code
      const data = {
        invoiceId:id
      };
      setIsLoaded(true);
      await props.changeDeliveryStatusAction(data).then(() => {
        setIsLoaded(false);
        setPage("list");
        setDate(new Date());
      });
    }
    const { tSDay, tEDay } = setDefaultDates(date);
    fetchData(tSDay, tEDay);
  };

  const printReportHandler = async () => {
    const { tSDay, tEDay } = await setDefaultDates(date);
    const data = {
      startDate: tSDay,
      endDate: tEDay,
      title: "DAILY REPORTS",
      type: "N",
    };
    setIsLoaded(true);
    await props.getDailyReportPrint(data).then(() => setIsLoaded(false));
  };
  const goBackMainPage = () => {
    props.history.push("/rapporter");
  };
  const renderPage = () => {
    if (page === "list") {
      return (
        <List
          setPage={setPage}
          handleDateChange={handleDateChange}
          printReportHandler={printReportHandler}
          goBackMainPage={goBackMainPage}
          isLoaded={isLoaded}
          dailyDining={props.dailyDining}
          setDeliverStatus={setDeliverStatus}
          viewDailyReport={props.viewDailyReport}
        />
      );
    } else if (page === "view") {
      return (
        <ViewReport
          changeDeliveryStatus={changeDeliveryStatus}
          setDeliverStatus={setDeliverStatus}
          setPage={setPage}
        />
      );
    } else {
      return <List />;
    }
  };

  return <>{renderPage()}</>;
};
const mapStateToProps = ({ dailyDining }) => {
  return {
    dailyDining,
  };
};
export default connect(mapStateToProps, {
  getDailyReportDining,
  getDailyReportPrint,
  viewDailyReport,
  changeDeliveryStatusAction,
})(DailyReport);
