const productsUnits = {
  all: [
    { key: "kg", value: "kilogram" },
    { key: "gm", value: "gram" },
    { key: "mg", value: "milligram" },
    { key: "lt", value: "liter" },
    { key: "ct", value: "centiliter" },
    { key: "mt", value: "milliliters" },
    { key: "unit", value: "unit" }
  ],
  mg: [
    { key: "kg", value: "kilogram" },
    { key: "gm", value: "gram" },
    { key: "mg", value: "milligram" }
  ],
  mt: [
    { key: "lt", value: "liter" },
    { key: "ct", value: "centiliter" },
    { key: "mt", value: "milliliters" }
  ],
  unit: [{ key: "unit", value: "unit" }]
};
export default productsUnits;
