import React from "react";
import { Modal } from "antd";
import appConfig from "../../helpers/appConfig";
const IconsModel = ({
  isModel,
  setIsModel,
  icons,
  catId,
  updateIcon,
  getCategory
}) => {
  const selectIcon = (iconId, catId) => {
    const data = {
      iconId,
      catId
    };
    updateIcon(data).then(() => {
      getCategory();
      setIsModel(false);
    });
  };
  return (
    <Modal
      title="Bytt Bilde"
      visible={isModel}
      footer={null}
      width={"80%"}
      onCancel={() => setIsModel(false)}
    >
      {icons.length > 0 &&
        icons.map((icon, index) => {
          return (
            <span key={icon._id}>
              <img
                src={`${appConfig.s3url}/${icon.path}`}
                className="choose-icon"
                onClick={() => selectIcon(icon._id, catId)}
              />
            </span>
          );
        })}
    </Modal>
  );
};
export default IconsModel;
