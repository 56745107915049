import {
  GET_ONLINE_ORDER,
  UPDATE_ORDER_STATUS_kITCHEN
} from "../../action/types";
const initialState = {
  items: [],
  onlineTotal: 0,
  updatedKitchenOrder: {}
};
const onlineOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ORDER:
      return {
        ...state,
        items:
          action.payload.data && action.payload.data.order
            ? action.payload.data.order
            : [],
        onlineTotal:
          action.payload.data && action.payload.data.total[0]
            ? action.payload.data.total[0].totalAmount
            : 0
      };

   
    case UPDATE_ORDER_STATUS_kITCHEN:
      let onlineOrder = [...state.items];

      let index = onlineOrder.findIndex((res) => {
        return res._id == action.payload.orderId;
      });
      if (index < 0) {
        return { ...state };
      }

      onlineOrder[index] = action.payload.order;

      return {
        ...state,
        items: onlineOrder,
        updatedKitchenOrder: action.payload.order
      };
    default:
      return state;
  }
};
export default onlineOrdersReducer;
