import React, { Component } from "react";
import Home from "../Home/Home";
import "./crm.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Modal from "react-modal";
import { notification } from "antd";

import appConfig from "../../helpers/appConfig";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class crmComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      name: "",
      email: "",
      address: "",
      phoneNo: "",
      form: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  toggleform = (e) => {
    let state = Object.assign(this.state);
    state.form = !state.form;
    this.setState(state);
  };
  Closeform = (e) => {
    this.setState({ form: false });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  // handleSubmit = e => {
  //   e.preventDefault();
  //   var data = JSON.stringify({
  //     name: this.state.name,
  //     email: this.state.email,
  //     address: this.state.address,
  //     phoneNo: this.state.phoneNo
  //   });
  //   var hdr = localStorage.token;
  //   fetch(`${appConfig.endpoint}/customer`, {
  //     method: 'POST',
  //     body: data,
  //     headers: {
  //       'x-auth-token': hdr,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(
  //       result => {
  //         console.log(result);
  //         if (result.status === 'failure') {
  //           alert('Please fill all the details properly.');
  //         } else {
  //           alert('Customer added successfully.');
  //         }
  //       },
  //       error => {
  //         this.setState({
  //           isLoaded: true,
  //           error
  //         });
  //       }
  //     );
  // };

  addCustomer = (e) => {
    e.preventDefault();
    // /employee post request
    var letter = /^[A-Za-z_ ]*$/;
    // var letterNo = /^\(?([0-9]{5})\)?[-. ]?([0-9]{5})$/;
    if (!this.state.firstName.match(letter)) {
      return this.openNotification(
        "error",
        "please enter alphabets in First Name"
      );
    } else if (!this.state.lastName.match(letter)) {
      return this.openNotification(
        "error",
        "Please enter alphabets in Last Name"
      );
    } else if (!this.state.state.match(letter)) {
      return this.openNotification("error", "Please enter Alphabets in State");
    } else if (!this.state.country.match(letter)) {
      return this.openNotification(
        "error",
        "Please enter the Alphabets in Country"
      );
    }
    var data = JSON.stringify({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNo: this.state.phoneNo,
      reference: this.state.reference,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      pin: this.state.pin,
      state: this.state.state,
      country: this.state.country
    });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/customer`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            // this.props.history.push('/hrm');
            this.openNotification("success", result.message);
            this.toggleModal();
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    return (
      <Home isAdmin={true}>
        <div className="report-container-flex">
          <button className="box shadow stock-bg">
            <p className="text-items">KUNDER</p>
          </button>
        </div>
        <div className="different-reports">
          <div id="flex-crm">
            <button
              className="box shadow orange-bg"
              onClick={() => {
                this.navigate("/CTable");
              }}
            >
              <p className="text-items">KUNDER </p>
            </button>
            {/* <button className="box shadow orange-bg">
              <p className="text-items">NOTIFICATIONS</p>
            </button> */}
          </div>
          <div id="flex-crm">
            <button className="box shadow black-bg" onClick={this.toggleModal}>
              <p className="text-items">LEGG TIL KUNDER</p>
            </button>
            {/* <button className="box shadow black-bg">
              <p className="text-items">FEEDBACK</p>
            </button> */}
          </div>
        </div>
        <div className={this.state.form ? "cus-blur" : ""}>
          <div id={this.state.form ? "cus" : "something4"}>
           
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.toggleModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="LEGG TIL KUNDER"
        >
          <div className="button-container">
            <button onClick={this.toggleModal} className="close-button">
              X
            </button>
          </div>
          <form
            className="add-employee add-customer"
            onSubmit={this.addCustomer}
          >
            <li>
              <center>
                <h2>LEGG TIL KUNDER</h2>
              </center>
            </li>
            <li style={{ "margin-top": "1em" }}>
              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                required
                onChange={this.handleChange}
                name="firstName"
                className="input2"
                placeholder="Navn"
              />
              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="text"
                onChange={this.handleChange}
                name="lastName"
                className="input2"
                required
                placeholder="Etter Navn"
              />
            </li>
            <li>
              <input
                type="email"
                onChange={this.handleChange}
                name="email"
                required
                className="input2"
                placeholder="E-mail"
              />
            </li>
            <li>
              <input
                type="number"
                onChange={this.handleChange}
                name="phoneNo"
                className="input2"
                maxlength="10"
                min="0000000000"
                pattern="[0-9]"
                max="9999999999"
                placeholder="Tlf. Nr."
              />
            </li>
            <li>
              <textarea
                onChange={this.handleChange}
                name="reference"
                placeholder="Skriv inn referanse"
                className="input2"
              ></textarea>
            </li>
            <p>Adresse</p>
            <li>
              <input
                type="text"
                onChange={this.handleChange}
                name="line1"
                className="input2"
                maxlength="20"
                placeholder="Adresse"
              />
            </li>
            <li>
              <input
                type="text"
                onChange={this.handleChange}
                name="line2"
                maxlength="20"
                className="input2"
                placeholder="Adresse"
              />
            </li>
            <li style={{ "margin-top": "1em" }}>
              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                onChange={this.handleChange}
                name="city"
                maxlength="15"
                className="input2"
                required
                placeholder="By"
              />

              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="number"
                onChange={this.handleChange}
                name="pin"
                className="input2"
                required
                pattern="[0-9]"
                // maxlength="10"
                min="0000000000"
                max="1000000000"
                placeholder="PIN KODE"
              />
            </li>
            <li>
              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 2% 0 8%"
                }}
                type="text"
                onChange={this.handleChange}
                name="state"
                className="input2"
                maxlength="15"
                required
                placeholder="Fylke"
              />

              <input
                style={{
                  "max-width": "40%",
                  float: "left",
                  margin: "0 8% 0 2%"
                }}
                type="text"
                onChange={this.handleChange}
                name="country"
                className="input2"
                maxlength="15"
                placeholder="Land"
                required
              />
            </li>

            <li>
              <button type="submit" id="submit-landing2">
                Send
              </button>
            </li>
          </form>
        </Modal>
      </Home>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const CRM = connect(mapStateToProps)(crmComponent);
export default CRM;
