import { GET_ORDER_COUNT, GET_RESREVE_TABLE_COUNT } from "../../action/types";
const initialState = {
  orders: 0,
  reserveTableCount:0
};

const orderCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_COUNT:
      return {
        ...state,
        orders: action.payload
      };
      case GET_RESREVE_TABLE_COUNT:
        return {
          ...state,
          reserveTableCount:action.payload
        };
    default:
      return state;
  }
};

// export const reserveTableCountReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case GET_RESREVE_TABLE_COUNT:
//       return {
//         ...state,
//         reserveTableCount:action.payload
        
//       };
//     default:
//       return state;
//   }
// };

export default orderCountReducer;
