import { GET_CATEGORIES } from "../../action/types";

const categoriesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return action.payload.data;
    default:
      return state;
  }
};
export default categoriesReducer
