import React, { Component, Fragment } from "react";
import appConfig from "../../helpers/appConfig";
import { Form, Input, Button, notification, Spin } from "antd";
const FormItem = Form.Item;
class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newItem: "",
      newPrice: "",
      itemNo: "",
      productUploadingLoading: false,
      productFile: ""
    };
  }
  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  handleProductChange = (e) => {
    const { id } = this.state;
    const file = e.target.files[0];
    this.setState({ productFile: file });
  };

  handleAddProduct = (e) => {
    e.preventDefault();
    this.setState({ productUploadingLoading: true });
    const { newItem, newPrice, itemNo } = this.state;
    const { catId, productPage } = this.props;
    var formData = new FormData();
    const newData = {
      name: newItem,
      price: newPrice
    };
    var hdr = localStorage.token;
    formData.append(
      "image",
      this.state.productFile,
      this.state.productFile.name
    );
    formData.append("name", newData.name);
    formData.append("price", newData.price);
    formData.append("itemNo", itemNo);
    fetch(`${appConfig.endpoint}/product/${catId}`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.setState({ productUploadingLoading: false });
            this.openNotification("error", result.data);
          } else {
            this.setState({ productUploadingLoading: false });
            productPage(catId);
            this.openNotification("success", result.message);
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  render() {
    return (
      <Fragment>
        <Form layout="inline" onSubmit={this.handleAddProduct}>
          <FormItem>
            <Input
              style={{ width: 200, marginRight: "10px" }}
              onChange={(e) => this.setState({ newItem: e.target.value })}
              placeholder="Produkt Navn"
              value={this.state.newItem}
              required
            />
          </FormItem>
          <FormItem>
            <Input
              type="nummer"
              prefix="Kr."
              style={{ width: 100, marginRight: "10px" }}
              onChange={(e) => this.setState({ newPrice: e.target.value })}
              placeholder="Pris"
              min="0"
              value={this.state.newPrice}
              required
            />
          </FormItem>

          <FormItem>
            <Input
              type="nummer"
              style={{ width: 100, marginRight: "10px" }}
              onChange={(e) => this.setState({ itemNo: e.target.value })}
              placeholder="Nr."
              min="0"
              value={this.state.itemNo}
              required
            />
          </FormItem>
          <FormItem>
            <Input
              style={{ marginTop: 4 }}
              className="file-input"
              type="file"
              name="info"
              accept="image/*"
              onChange={this.handleProductChange}
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.state.productUploadingLoading}
              style={{ marginBottom: 16 }}
            >
              {this.state.productUploadingLoading ? (
                <Spin tip="Uploading..."></Spin>
              ) : (
                "Legg til Produkt"
              )}
            </Button>
          </FormItem>
        </Form>
      </Fragment>
    );
  }
}
export default ProductForm;
