import React from "react";
import { NavLink } from "react-router-dom";
const ReportsButton = (props) => {
  if (props.title === "DAILY REPORTS") {
    return (
      <NavLink
        style={{
          position: "relative",
          padding: "24px 32px"
        }}
        className={props.color + " " + props.css}
        to="/daily-report"
      >
        <p className="text-items text-center">{props.title}</p>
      </NavLink>
    );
  } else if (props.title === "TABLE ORDERS") {
    return (
      <NavLink
        style={{
          position: "relative",
          padding: "24px 32px"
        }}
        className={props.color + " " + props.css}
        to="/onlinetableorder"
      >
        {props.tableOrders > 0 && (
          <span
            style={{
              background: "red",
              borderRadius: 100,
              width: 42,
              height: 42,
              padding: 9,
              color: "white",
              position: "absolute",
              top: -9,
              right: -5,
              textAlign: "center"
            }}
          >
            {props.tableOrders}
          </span>
        )}
        <p className="text-items text-center">{props.title}</p>
      </NavLink>
    );
  } else {
    return (
      <button
        style={{
          position: "relative"
        }}
        className={props.color + " " + props.css}
        onClick={props.clicked}
      >
        {props.title === "ONLINE ORDERS" && props.orders > 0 && (
          <span
            style={{
              background: "red",
              borderRadius: 100,
              width: 42,
              height: 42,
              padding: 9,
              color: "white",
              position: "absolute",
              top: -5,
              right: -5
            }}
          >
            {props.orders}
          </span>
        )}
        <p className="text-items">{props.title}</p>
      </button>
    );
  }
};

export default ReportsButton;
