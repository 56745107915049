import React, { Component } from "react";
import { Col, Button } from "antd";
import "./IconStyle.css";
class IconGridItem extends Component {
  render() {
    return (
      <div className="items gutter-row billing-card button-style">
         <Button
            style={{ backgroundColor: this.props.color }}
            className="button-size-icon"
            id={this.props.id}
            onClick={() => this.props.clicked(this.props)}
          >
            <div className="menu-options white">
              <img
                src={this.props.icon}
                // style={{ marginTop: "20px"}}
                className="product-image"
              />
            </div>
            <span className="menu-options name" style={{ marginTop: "25px" }}>
              {this.props.category ? "" : `Kr. ${this.props.productPrice}`}
            </span>
            <div className="menu-options details">
              <span className="menu-options price">
                {this.props.productName}{" "}
              </span>
            </div>
          </Button>
      </div>
    );
  }
}

export default IconGridItem;
