import React, { useEffect, useState } from "react";
import { Divider, Table } from "antd";
import { Col, Row } from "react-bootstrap";
import TableSumX from "../../Table/TableSumX";
import {
  posColumns,
  posSummaryColumns,
  posDriveoutColumns
} from "../../Table/TableColumns";
import PrintReport from "../../PrintReport/PrintReportFunc";
export const List = (props) => {
  let [sendProps, setSendProps] = useState(true);
  let [dcount, setdCount] = useState("");
  let [tatalOrdersPrice, setTatalOrdersPrice] = useState(0);
  let [items, setItems] = useState([]);
  let [totalTax, setTotalTax] = useState([]);
  let [allTax, setAllTax] = useState([]);
  let [finalTotal, setFinalTotal] = useState(0);
  let [totalDiscount, setTotalDiscount] = useState(0);
  let [unitPriceTotal, setUnitPriceTotal] = useState(0);
  let [totalExtra, setTotalExtra] = useState(0);
  let [dOCTax, setdOCTax] = useState(0);
  useEffect(() => {
    let result = props.dailyXReport;
    async function setTotalValues(result) {
      setTotal(result);
      setItems(result.data.reportData);
      setTotalTax(result.data.totalTax);
      setAllTax(result.data.allTax);
      setdCount(result.data.dcount ? result.data.dcount : "");
      setdOCTax(result.data.dOT);
      // setDriveOutCharge(result.data.branch.driveoutCharge);
      setTatalOrdersPrice(
        result.data.tatalOrdersPrice ? result.data.tatalOrdersPrice : 0
      );
      setSendProps(true);
    }
    setTotalValues(result);
  }, [props.dailyXReport]);

  const setTotal = (result) => {
    let unitPriceTotal = result.data.reportData.reduce((acc, record, index) => {
      return (
        acc +
        (record.total -
          (record.taxObj.reduce((sum, obj) => {
            return sum + obj.taxPrice;
          }, 0) -
            (record.extraPrice ? record.extraPrice : 0)))
      );
    }, 0);
    let finalTotal = result.data.reportData.reduce((acc, current, index) => {
      return acc + current.total;
    }, 0);

    let totalDiscount = result.data.reportData.reduce((acc, current, index) => {
      return acc + current.discountPrice;
    }, 0);
    let totalExtra = result.data.reportData.reduce((acc, current, index) => {
      if (current.extraPrice) {
        return acc + current.extraPrice;
      }
      return acc;
    }, 0);
    setUnitPriceTotal(unitPriceTotal);
    setFinalTotal(finalTotal);
    setTotalDiscount(totalDiscount);
    setTotalExtra(totalExtra);
  };

  var dataSource = [
    {
      key: "1",
      dcount: dcount,
      driveoutTax: `Kr. ${(
        parseInt(tatalOrdersPrice) -
        (parseInt(tatalOrdersPrice) * 100) / (100 + dOCTax)
      ).toFixed(2)}`,
      driveoutCharge: `Kr. ${(
        (tatalOrdersPrice * 100) /
        (100 + dOCTax)
      ).toFixed(2)}`,
      totalDcharge: tatalOrdersPrice
    }
  ];

  const summaryData = [
    {
      key: "-1",
      render: () => "test"
    }
  ];
  const TableAll = () => {
    return (
      <>
        <h5>Utkjøring:</h5>
        <Table
        bordered
          dataSource={dataSource}
          columns={posDriveoutColumns}
          pagination={false}
        />
        <Divider />
        <h5>Bestillingene:</h5>
        <TableSumX
        bordered
          loading={props.isLoaded}
          columns={posColumns(items, allTax)}
          summaryColumns={posSummaryColumns(
            totalTax,
            allTax,
            tatalOrdersPrice,
            unitPriceTotal,
            totalDiscount,
            totalExtra,
            finalTotal
          )}
          data={items}
          summaryData={summaryData}
        />
        <div style={{ display: "none" }}>
          {sendProps ? (
            <PrintReport
              columns={posColumns(
                tatalOrdersPrice,
                unitPriceTotal,
                totalDiscount,
                totalExtra,
                finalTotal
              )}
              data={items}
              totalDiscount={totalDiscount}
              finalTotal={finalTotal}
              unitPriceTotal={unitPriceTotal}
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {/* <Row className="filter m-b-1">
              <Col span={4}> </Col>
              <Col span={4} className="">
                <DatePicker
                  onChange={(date, dateString) => {
                    props.handleDateChange(date);
                  }}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />
              </Col>
              <Col span={4} className="" align="left">
                <Button
                  className="ant-btn btn-ab"
                  style={{ background: "#dcbb68" }}
                  onClick={() => props.printReportHandler()}
                >
                  <i
                    className="fa fa-print"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </Button>
              </Col>
            </Row> */}
      <Row>
        <Col>
          <TableAll />
        </Col>
      </Row>
    </>
  );
};

export default List;
