import React, { useEffect, useState, useRef } from "react";
import { Input, Form, Button, Upload, Card, Row, Col } from "antd";
import uploadIcon from "./../../assets/uploadIcon.png";
import "./style.css";
const FormItem = Form.Item;
const ProductForm = ({
  categoryId,
  addProduct,
  successData,
  errorMassage,
  getProducts
}) => {
  const inputFile = useRef(null);
  let [name, setName] = useState("");
  let [price, setPrice] = useState("");
  let [itemNo, setItemNo] = useState("");
  let [fileImage, setFileImage] = useState("");
  let [btnDisabled, setBtnDisabled] = useState(false);
  let [icon, setIcon] = useState(uploadIcon);
  const errorsObj = {
    name: {
      show: false,
      message: "*required"
    },
    itemNo: {
      show: false,
      message: "*required"
    },
    price: {
      show: false,
      message: "*required"
    },
    file: {
      show: false,
      message: "*required"
    }
  };
  let [errors, setErrors] = useState(errorsObj);
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorsObj = { ...errors };
    let flag = false;
    if (!name) {
      errorsObj.name.show = true;
      flag = true;
    } else {
      errorsObj.name.show = false;
    }
    if (!itemNo) {
      errorsObj.itemNo.show = true;
      flag = true;
    } else {
      errorsObj.itemNo.show = false;
    }
    if (!price) {
      errorsObj.price.show = true;
      flag = true;
    } else {
      errorsObj.price.show = false;
    }
    if (!fileImage) {
      errorsObj.file.show = true;
      flag = true;
    } else {
      errorsObj.file.show = false;
    }
    flag ? setErrors(errorsObj) : saveCategory();
  };
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let errorsObj = { ...errors };
      let imageFile = e.target.files[0];
      let fileName = imageFile.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|svg)$/)) {
        errorsObj.file.show = true;
        errorsObj.file.message = "Please select valid image.";
        setErrors(errorsObj);
        return false;
      }
      const isLt10M = imageFile.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        errorsObj.file.show = true;
        errorsObj.file.message = "Image must smaller than 10MB!";
        setErrors(errorsObj);
        return false;
      }
      setFileImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        errorsObj.file.show = false;
        errorsObj.file.message = "";
        setIcon(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }
  };
  const saveCategory = () => {
    let formData = new FormData();
    formData.append("image", fileImage, fileImage.name);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("itemNo", itemNo);
    formData.append("categoryId", categoryId);
    //  setBtnDisabled(true);
    addProduct(formData);
  };
  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "addProduct"
    ) {
      setName("");
      setPrice("");
      setItemNo("");
      setFileImage("");
      setBtnDisabled(false);
      getProducts(categoryId);
      setIcon(uploadIcon);
      inputFile.current.value = "";
    }
  }, [successData]);
  useEffect(() => {
    setBtnDisabled(false);
  }, [errorMassage]);
  const triggerFile = () => {
    inputFile.current.click();
  };
  const createForm = () => {
    return (
      <>
        <Card bordered={false} style={{ marginBottom: "1rem" }}>
          <Form layout="inline" onSubmit={(e) => handleSubmit(e)}>
            <Row className="catForm" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={8}>
                <FormItem style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Produkt Navn"
                    value={name}
                    required={true}
                  />
                  <p style={{ color: "red" }}>
                    {errors.name.show ? errors.name.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={6}>
                <FormItem style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Kr.Pris"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={price}
                    type="number"
                    required={true}
                  />
                  <p style={{ color: "red" }}>
                    {errors.price.show ? errors.price.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={4}>
                <FormItem style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setItemNo(e.target.value)}
                    placeholder="Nr"
                    value={itemNo}
                    type="number"
                    required={true}
                  />
                  <p style={{ color: "red" }}>
                    {errors.itemNo.show ? errors.itemNo.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={2}>
                <FormItem style={{ width: "100%", cursor: "pointer" }}>
                  <img
                    src={icon}
                    width="30"
                    height="30"
                    alt="icon"
                    onClick={triggerFile}
                  />
                  <input
                    ref={inputFile}
                    type="file"
                    style={{ display: "none" }}
                    name="file"
                    onChange={(e) => onChangePicture(e)}
                    accept="image/*"
                  />
                  <p style={{ color: "red" }}>
                    {errors.file.show ? errors.file.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={2}>
                <FormItem>
                  <Button
                    className="btn-ab search"
                    htmlType="submit"
                    disabled={btnDisabled}
                  >
                    Oprett ny Produkt
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    );
  };
  return createForm();
};

export default ProductForm;
