import history from "../history"
import setAuthorixationToken from "./setAuthorizationToken"
import {socket} from "../utils/socketNew";
export const isServerLogout = (res) => {
    if(res.status === 401){
        localStorage.removeItem("token");
        localStorage.removeItem('socketOrders');
        setAuthorixationToken(false);
        socket.emit("logout");
        history.push("/")
    }
    return;
}