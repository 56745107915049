import React, { Component } from "react";
import productsUnits from "../../helpers/productUnits";
import appConfig from "../../helpers/appConfig";
import { errorFunc, validateFunc } from "./Functions";
import { Input, Button, Modal, Row, Col, Select, Divider } from "antd";
const { TextArea } = Input;

const Option = Select.Option;
class Ingredents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        { stock: null, quantity: null, qtyUnit: null, stockproduct: null }
      ],
      ingredents: [[{ base_unit: null }]],
      errors: [errorFunc()],
      productsUnit: [],
      isCallProduct: true,
      isUpdateList: true,
      ingredentsDesc: ""
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (
      state.isUpdateList &&
      state.fields.length < 2 &&
      props.ingredents.length > 0
    ) {
      let errors = [];
      let ingredents = [];
      let ingredentsDesc = props.ingredentsText;
      for (let i = 0; i < props.ingredents.length; i++) {
        errors.push(...state.errors);
        ingredents.push(...state.ingredents);
      }
      return {
        fields: props.ingredents,
        errors,
        ingredents,
        isUpdateList: false,
        ingredentsDesc
      };
    }
    return null;
  }
  componentDidUpdate(prevState, prevProps) {
    const isCall = this.props.productIngredentEditModal;
    if (isCall && prevState.stockCats.length == 0) {
      this.props.StockCategoryPage();
    }
    if (
      prevProps.isCallProduct &&
      this.state.fields &&
      this.state.fields[0].stock !== null
    ) {
      this.setState({ isCallProduct: false });
      for (let [idx, props] of this.state.fields.entries()) {
        if (props.stock !== null) {
          let e = { key: props.stock };
          this.handleProductChange({ e, idx });
        }
      }
    }
  }
  handleProductChange = (event) => {
    let hdr = localStorage.token;
    const id = event.e.key;
    fetch(`${appConfig.endpoint}/stock/ingredients/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        const ingre = [...this.state.ingredents];
        ingre[event.idx] = result.data ? [...result.data] : [];
        const values = [...this.state.fields];
        const proUnit = [...this.state.productsUnit];
        if (event.e.item) {
          let name = event.e.item
            ? event.e.item.props.name
            : event.e.target.name;
          let value = event.e.item
            ? event.e.item.props.value
            : event.e.target.value;
          values[event.idx][name] = value;
        }
        let unit = result.data
          ? this.getQtyUnits(values[event.idx], result.data)
          : [];
        proUnit[event.idx] =
          unit && productsUnits[unit.base_unit]
            ? productsUnits[unit.base_unit]
            : productsUnits["all"];
        this.setState({
          fields: values,
          ingredents: ingre,
          productsUnit: proUnit
        });
      });
  };
  getQtyUnits = (val, res) => {
    return res.find((data) => data._id === val.stockproduct);
  };
  render() {
    const {
      cancel,
      openNotification,
      productEditingIngredent,
      productIngredentEditModal,
      productPage,
      catId,
      stockCats
    } = this.props;
    const setFields = (values, errors, ingredents) => {
      this.setState({ fields: values, errors, ingredents });
    };
    const handleChangeDisc = (e) => {
      this.setState({ ingredentsDesc: e.target.value });
    };
    const handleChange = (event) => {
      const values = [...this.state.fields];
      const errors = [...this.state.errors];
      const ingredents = [...this.state.ingredents];
      let name = event.e.item ? event.e.item.props.name : event.e.target.name;
      let value = event.e.item
        ? event.e.item.props.value
        : event.e.target.value;
      values[event.idx][name] = value;
      setFields(values, errors, ingredents);
    };
    const handleAdd = () => {
      const values = [...this.state.fields];
      const errors = [...this.state.errors];
      const ingredents = [...this.state.ingredents];
      values.push({
        stock: null,
        quantity: null,
        qtyUnit: null,
        stockproduct: null
      });
      errors.push(errorFunc());
      ingredents.push([{ base_unit: null }]);
      setFields(values, errors, ingredents);
    };

    const handleRemove = (i) => {
      const values = [...this.state.fields];
      const errors = [...this.state.errors];
      const ingredents = [...this.state.ingredents];
      values.splice(i, 1);
      errors.splice(i, 1);
      ingredents.splice(i, 1);
      setFields(values, errors, ingredents);
    };

    const updateIngredents = (id) => {
      const res = validateFunc(this.state);
      const fields = this.state.fields;
      this.setState({ errors: res.allErroes });
      if (res.isError == 0) {
        sendIngredents(fields, id);
      }
    };
    const sendIngredents = (fields, id) => {
      let hdr = localStorage.token;
      let data = JSON.stringify({
        ingredents: fields,
        ingredentsDesc: this.state.ingredentsDesc
      });
      fetch(`${appConfig.endpoint}/op-producting/${id}`, {
        method: "PUT",
        body: data,
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "failure") {
            openNotification("error", "err");
          } else {
            productPage(catId);
            cancel();
            openNotification("success", "Product updated successfully.");
          }
        });
    };
    const modelClose = (e) => {
      const fields = [
        { stock: null, quantity: null, qtyUnit: null, stockproduct: null }
      ];
      const ingredents = [[{ base_unit: null }]];
      this.state.fields = fields;
      this.state.ingredents = ingredents;
      this.state.ingredentsDesc = "";
      this.state.isCallProduct = true;
      this.state.isUpdateList = true;
    };
    const buttonRender = (idx) => {
      if (idx === 0) {
        return (
          <Button
            // type="button"
            type="primary"
            onClick={() => handleAdd()}
            ghost
          >
            +
          </Button>
        );
      } else {
        return (
          <Button
            // type="button"
            type="danger"
            onClick={() => handleRemove(idx)}
            ghost
          >
            X
          </Button>
        );
      }
    };
    const handleStockChange = (event) => {
      handleChange(event);
      const proUnit = [...this.state.productsUnit];
      proUnit[event.idx] =
        event.e.item.props &&
        event.e.item.props.qtyUnitType &&
        productsUnits[event.e.item.props.qtyUnitType]
          ? productsUnits[event.e.item.props.qtyUnitType]
          : productsUnits["all"];
      this.setState({ productsUnit: proUnit });
    };
    return (
      <>
        <Modal
          width="800px"
          title={`Enter Ingredents for ${productEditingIngredent.name}`}
          onCancel={cancel}
          visible={productIngredentEditModal}
          afterClose={(e) => modelClose(e)}
          footer={[
            <Button key="back" onClick={cancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => updateIngredents(productEditingIngredent._id)}
            >
              Save
            </Button>
          ]}
        >
          <Row key="key1">
            <Col span={6} style={{ marginRight: "10px" }}>
              <b>Produkt</b>
            </Col>
            <Col span={6} style={{ marginRight: "10px" }}>
              <b>Ingredienser</b>
            </Col>
            <Col span={4} style={{ marginRight: "10px" }}>
              <b>Antall</b>
            </Col>
            <Col span={4} style={{ marginRight: "10px" }}>
              <b>Enhet</b>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Divider style={{ marginBottom: "10px" }} />

          {this.state.fields.map((field, idx) => {
            return (
              <Row key={`${field}-${idx}`}>
                <Col
                  span={6}
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Plukke ut"
                    showSearch
                    value={this.state.fields[idx].stock}
                  >
                    {stockCats.length > 0 &&
                      stockCats.map((product) => (
                        <Option
                          value={product._id}
                          name="stock"
                          label={product.name}
                          key={product._id}
                          onClick={(e) => this.handleProductChange({ e, idx })}
                        >
                          {product.name}
                        </Option>
                      ))}
                  </Select>
                  {this.state.errors[idx].stock.isValidationFail ? (
                    <small>{this.state.errors[idx].stock.message}</small>
                  ) : (
                    ""
                  )}
                </Col>
                <Col
                  span={6}
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Plukke ut"
                    showSearch
                    value={this.state.fields[idx].stockproduct}
                  >
                    {this.state.ingredents &&
                      this.state.ingredents[idx] &&
                      this.state.ingredents[idx].length > 0 &&
                      this.state.ingredents[idx].map((product) => (
                        <Option
                          name="stockproduct"
                          value={product._id}
                          qtyUnitType={product.base_unit}
                          label={product.name}
                          key={`${product._id}-${this.state.fields[idx].stockproduct}`}
                          onClick={(e) => handleStockChange({ e, idx })}
                        >
                          {product.name}
                        </Option>
                      ))}
                  </Select>
                  {this.state.errors[idx].stockproduct.isValidationFail ? (
                    <small>{this.state.errors[idx].stockproduct.message}</small>
                  ) : (
                    ""
                  )}
                </Col>
                <Col
                  span={4}
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                >
                  <Input
                    name="quantity"
                    type="number"
                    onChange={(e) => handleChange({ e, idx })}
                    value={this.state.fields[idx].quantity}
                    placeholder="Antall"
                    required
                  />
                  {this.state.errors[idx].quantity.isValidationFail ? (
                    <small>{this.state.errors[idx].quantity.message}</small>
                  ) : (
                    ""
                  )}
                </Col>
                <Col
                  span={4}
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Plukke ut"
                    value={this.state.fields[idx].qtyUnit}
                  >
                    {this.state.productsUnit &&
                      this.state.productsUnit[idx] &&
                      this.state.productsUnit[idx].length > 0 &&
                      this.state.productsUnit[idx].map((product) => (
                        <Option
                          value={product.key}
                          name="qtyUnit"
                          label={product.name}
                          key={product.value}
                          onClick={(e) => handleChange({ e, idx })}
                        >
                          {product.value}
                        </Option>
                      ))}
                  </Select>
                  {this.state.errors[idx].qtyUnit.isValidationFail ? (
                    <small>{this.state.errors[idx].qtyUnit.message}</small>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={2}>{buttonRender(idx)}</Col>
              </Row>
            );
          })}
          <Divider style={{ marginBottom: "10px" }} />
          <Col span={21} style={{ marginRight: "10px", marginBottom: "5px" }}>
            <TextArea
              rows={2}
              value={this.state.ingredentsDesc}
              onChange={(e) => handleChangeDisc(e)}
              placeholder="Produkt Beskrivelse"
            />
          </Col>
          <div className="clearfix"></div>
        </Modal>
      </>
    );
  }
}
export default Ingredents;
