import React from "react";
const KitchenHeading = (props) => {
  return (
    <h3
      style={{
        width: "100%",
        fontWeight: "bold",
        background: "grey",
        padding: 10,
        color: "#FFFFFF",
      }}
    >
      {props.heading}
    </h3>
  );
};
export default KitchenHeading;
