import axios from "axios";
import appConfig from "../helpers/appConfig";
import {
  GET_COSTOMERS,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  GET_COSTOMERS_INVOICE,
} from "./types";
import { isServerLogout } from "../utils/isServerLogout";
export const getKunder = (page = 1, pageSize = 6) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/kunder`,
      {
        params: {
          page: page,
          pageSize: pageSize,
        },
      }
    );
    dispatch({
      type: GET_COSTOMERS,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateKunder = (data, id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/kunder/${id}`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateKunder" },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getKunderInvoice = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/kunder/invoice/${id}`
    );
    dispatch({
      type: GET_COSTOMERS_INVOICE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const addKunder = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/kunder`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "addKunder" },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response);
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
