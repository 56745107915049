import React, { Component } from "react";
import { Table, Card, Button, Modal, Radio, notification, Tag } from "antd";
import { TimePicker } from "antd";
import { connect } from "react-redux";
import appConfig from "../../helpers/appConfig";
import { Icon as RIcon } from "react-icons-kit";
import { check } from "react-icons-kit/fa/check";
import moment from "moment";
import { tab } from "react-icons-kit/icomoon";

class SingleKitchenBarComponent extends Component {
  constructor(props) {
    super(props);
    
  }
  openNotification = (type, message) => {
    if (message.errors) {
      if (message.errors.length) {
        message = message.errors[0].msg;
      } else {
        message = "Something went wrog, please try again";
      }
    }

    notification[type]({
      message: message
    });
  };

  updatetableproduct = (id, tableId) => {
    this.props.updatetableproduct(id, tableId);

    
  };

  updateorderItems = async (id, record) => {
    this.props.updateOnlineOrderKitchenStatus(id, record);
   
  };

  render() {
    console.log("rerender again nd again");
    const { data, updatetableproduct } = this.props;

    var dataSource = [];
    if (this.props.crrurl === "/kitchen") {
      data.items.forEach((element) => {
        if (element.sCatType == "KITCHEN") {
          dataSource.push(element);
        }
      });
    } else if (this.props.crrurl === "/bar") {
      console.log("this is bar data", data);
      data.items.forEach((element) => {
        if (element.sCatType == "BAR") {
          dataSource.push(element);
        }
      });
    }

    const onlinecolumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record, index) => {
          console.log("this is record))))", record);
          
          var qtsum = 0;
          record.qtyTrack.map((qt) => {
            qtsum += qt.quantity;
          });
          if (record.qtyTrack.length !== 0) {
            return record.quantity - qtsum;
          } else {
            return <p>{text}</p>;
          }
        }
      },
     
      {
        title: "Action",
        key: "_id",
        width: "9%",
        dataIndex: "_id",
        render: (text, record, index) =>
          record.status == "IN-PROCESS" ? (
            <Button
              style={{ padding: 0, height: "auto" }}
              onClick={() =>
                this.updateorderItems(this.props.data._id, text, record)
              }
            >
              MARK AS DONE
            </Button>
          ) : (
            <Tag color="#87d068">PREPARED</Tag>
          )
      }
    ];

    const tableColumn = [
      {
        title: "Name",
        dataIndex: "productName",
        key: "productName"
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record, index) => {
          console.log("this is record))))", record);
          
          var qtsum = 0;
          record.qtyTrack.map((qt) => {
            qtsum += qt.quantity;
          });
          if (record.qtyTrack.length !== 0) {
            return record.quantity - qtsum;
          } else {
            return <p>{text}</p>;
          }
        }
      },
      {
        title: "Invoice",
        key: "id",
        width: "9%",
        dataIndex: "id",
        render: (text, record, index) =>
          record.status == "IN-PROCESS" ? (
            <Button
              onClick={() => this.updatetableproduct(this.props.data._id, text)}
              style={{ padding: 0, height: "auto",width:"120px" }}
            >
              MARK AS DONE
            </Button>
          ) : (
            <Tag color="#87d068">PREPARED</Tag>
          )
      }
    ];
    const productList = ({ items }) => {
      let list = "";
      if (items) {
        for (const [idx, itm] of items.entries()) {
          list += `<ul class="product_ul">`;
          list += `<li>${idx + 1}) For ${itm.quantity} ${itm.name} -</li>`;
          list += `<ul>`;
          for (const [ix, ingredents] of itm.ingredents.entries()) {
            list += `<li>${ingredents.quantity} ${ingredents.qtyUnit} ${ingredents.stockproduct.name}</li>`;
          }
          list += `</ul>`;
          list += `</ul>`;
        }
        return list;
      }
    };
    const proExtra = (items) => {
      let list = "";
      if (items) {
        let i = 1;
        for (const [idx, itm] of items.entries()) {
          if (itm.instruction) {
            for (const [ix, inst] of itm.instruction.entries()) {
              let extra =
                itm.extraItems && itm.extraItems[ix] ? itm.extraItems[ix] : [];
              list += `<ul class="product_ul">`;
              list += `<li>${i}) For ${inst.quantity} ${inst.productName} -</li>`;
              list += `<ul>`;
              if (extra.length > 0) {
                list += `<li><h6><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i> Ekstra produkt</h6>`;
                let ic = <RIcon size={15} icon={check} />;
                for (const [eix, exta] of extra.entries()) {
                  list += `<ul style="list-style-type:none;font-size:15px;">`;
                  list += `<li><i class="fa fa-check"></i> ${exta.name}</li>`;
                  list += `</ul>`;
                }
              }
              list += `</ul>`;
              list += `</ul>`;
              list += `</ul>`;
              i++;
            }
          }
        }
        return list;
      }
    };
    const proInst = (items) => {
      let list = "";
      if (items) {
        let i = 1;
        for (const [idx, itm] of items.entries()) {
          if (itm.instruction) {
            for (const [ix, inst] of itm.instruction.entries()) {
              let desInt = inst.instruction
                ? inst.instruction.replace(/(^,)|(,$)/g, "")
                : "";
              let det = [];
              if (!Array.isArray(desInt)) {
                det = desInt.split(",");
              }
              if (desInt) {
                list += `<ul class="product_ul">`;
                list += `<li>${i}) For ${inst.quantity} ${inst.productName} -</li>`;
                if (desInt && det && det.length > 0) {
                  list += `<ul>`;
                  list += `<li><h6><i style="font-size:10px;" class="fa fa-circle-o fa-1"></i> Bruksanvisning</h6>`;
                  list += `<ul style="list-style-type:none;font-size:15px;">`;
                  if (Array.isArray(det)) {
                    for (const [eix, v] of det.entries()) {
                      list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${v}</li>`;
                    }
                  } else {
                    list += `<li><i class="fa fa-hand-o-right" style="color:red"></i> ${det}</li>`;
                  }
                  list += `</ul>`;
                  list += `</ul>`;
                }
                list += `</ul>`;
              }
              list += `</ul>`;
              i++;
            }
          }
        }
        return list;
      }
    };
    return (
      <Card>
        {this.props.onlineorder && this.props.onlineorder.length > 0 ? (
          <>
            <Card.Grid style={{ width: "50%" }}>
              <h5>Instruksjoner av kunden</h5>
              <div dangerouslySetInnerHTML={{ __html: proInst(dataSource) }} />
            </Card.Grid>
            <Card.Grid style={{ width: "50%" }}>
              <h5>Tilleggsartikler</h5>
              <div dangerouslySetInnerHTML={{ __html: proExtra(dataSource) }} />
            </Card.Grid>
          </>
        ) : (
          ""
        )}

        <br />
        <Table
          dataSource={dataSource}
          columns={this.props.onlineorder ? onlinecolumns : tableColumn}
          pagination="false"
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const SingleKitchenBar = connect(mapStateToProps)(SingleKitchenBarComponent);
export default SingleKitchenBar;
