import { GET_SUPER_CATEGORY } from "../../action/types";

const superCategoryReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SUPER_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};
export default superCategoryReducer;
