import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Home from "../Home/Home";
import { Button, Col, Row, notification, Card, Badge } from "antd";
// import "./Tables.css";
import PropTypes from "prop-types";
import moment from "moment";
import appConfig from "../../helpers/appConfig";
import Loader from "../Loader/Loader";
import SingleKitchenInvoice from "../SingleKitchenBar/SingleKitchenBar";
import Countdown from "react-countdown";
import { socket } from "../../utils/socketNew";
class BarViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      activeTable: [],
      noTable: [],
      driveOut: [],
      takeAway: [],
      items: [],
      currentPage: "BarView",
      pendingOnlineOrders: [],
      onlineorderData: []
    };
    socket.on("tableSaved", (socketData) => {
      console.log("bar socket",socketData)
      this.getallTables();
      // this.props.dispatch(getRestaurantInfo(restaurantId));
    });
  }
  goBackButton() {
    this.setState({ currentPage: "BarView" });
  }
  showOnlineOrder = (item) => {
    this.setState({
      currentPage: "Singleorderkitchenview",
      onlineorderData: item
    });
  };

  showTable = (item) => {
    // localStorage.setItem("table", id);
    // this.props.history.push("/billing");
    console.log("tables table id", item);
    console.log("items", item);
    // var irit = [];
    // // if(this.props.location.pathname)
    // item.items.forEach((element, index) => {
    //   console.log("insidei ifone", element);
    //   if (element.sCatType == "BAR") {
    //     console.log("insidei if", element.sCatType);
    //     irit.push(element);
    //   } else {
    //     item.items.splice(index, 1);
    //   }
    // });
    // item.items = irit;
    this.setState({
      currentPage: "Singlekitchenview",
      orderData: item
    });
  };
  getallTables() {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/table`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              activeTable: result.data.activeTable,
              noTable: result.data.noTable,
              driveOut: result.data.driveOut,
              takeAway: result.data.takeAway
            });
            // console.log(this.state);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  getOnlineOrders = (filter) => {
    //console.log("FUNCTION IS CALLED ONLINE ORDERS");
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/getorders`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          // this.openNotification('error', result.data);
        } else {
          //console.log("online", result);

          let items = [];
          let onlineTotal = "";

          switch (filter) {
            case "all":
              items = result.data;
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce((acc, curr) => acc + curr.totalPrice, 0),
                0
              );
              break;
            case "active":
              items = result.data.filter(
                (item) => item.orderStatus === "IN-PROCESS"
              );
              console.log("got the online items", items);
              this.setState({ pendingOnlineOrders: items });
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce(
                    (acc, curr) => acc + curr.totalPrice,
                    current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0,
                    0
                  ),
                0
              );
              break;
            case "delivered":
              items = result.data.filter(
                (item) => item.orderStatus === "DELIVERED"
              );

              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce(
                    (acc, curr) => acc + curr.totalPrice,
                    current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0,
                    0
                  ),
                0
              );
              break;
            case "cancelled":
              items = result.data.filter(
                (item) => item.orderStatus === "CANCEL"
              );
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  current.items.reduce(
                    (acc, curr) => acc + curr.totalPrice,
                    current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0,
                    0
                  ),
                0
              );
              break;
            default:
              console.log("got the online items", result.data);
              items = result.data;

              // this.setState({ pendingOnlineOrders: items });
              // onlineTotal = items.reduce(
              //   (acc, current) =>
              //     acc +
              //     current.items.reduce(
              //       (acc, curr) => acc + curr.totalPrice,
              //       current.orderType == "Drive Out"
              //         ? current.driveoutCharge
              //         : 0,
              //       0
              //     ),
              //   0
              // );
              break;
          }

          this.setState({
            pendingOnlineOrders: items,
            onlineTotal,
            isLoaded: true,
            sendProps: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error
        });
      });
  };

  componentWillMount() {
    this.getallTables();
    this.getOnlineOrders("active");
  }

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    const Completionist = () => (
      <span style={{ color: "red", fontWeight: "bold" }}>
        ORDER TIME COMPLETED!
      </span>
    );
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          <span>
            {hours}:{minutes}:{seconds}
          </span>
        );
      }
    };
    //console.log(this.state);
    const { error, isLoaded, activeTable, driveOut, takeAway, noTable } =
      this.state;
    // console.log(items);
    if (this.state.currentPage == "BarView") {
      if (!isLoaded) {
        return (
          <Home>
            <Loader />
          </Home>
        );
      } else if (error) {
        return <div>Error: {error.message}</div>;
      } else if (isLoaded) {
        // console.log(items);
        return (
          <Home>
            <div
              className={
                this.props.isBilling
                  ? "dashboard-billing"
                  : "dashboard-main-wrapper"
              }
            >
              <Row className="dashboard-flex">
                {/* {activeTable.length > 0 ? ( */}
                <h3
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    background: "grey",
                    padding: 10,
                    color: "#FFFFFF"
                  }}
                >
                  AKTIV BORD
                </h3>
                {activeTable.length > 0
                  ? activeTable.map((item, index) => {
                      var acntr = 0;
                      console.log("tttim", item);
                      item.items.length > 0 &&
                        item.items.forEach((it, ind) => {
                          if (
                            it.sCatType === "BAR" &&
                            it.status !== "PREPARED"
                          ) {
                            acntr += 1;
                          }
                        });
                      console.log("cntr after", acntr);
                      if (Number(acntr) > 0) {
                        console.log("insdie acntr", item);
                        return (
                          <Fragment>
                            <Col
                              onClick={() => {
                                this.showTable(item);
                              }}
                              key={index}
                              className="items gutter-row"
                            >
                              <div className="button-style">
                                <Button
                                  className="drinks button-size-icon"
                                  shape="circle"
                                >
                                  <div className="menu-options white">
                                    {item.orderType === "PLU1" &&
                                    item.number > 0 ? (
                                      <span>
                                        Table <b>{item.number}</b>
                                      </span>
                                    ) : (
                                      <span>
                                        {item.orderType} <b>{item.number}</b>
                                      </span>
                                    )}
                                  </div>
                                  <br />
                                  <b>{item.employeeId.firstName} </b>
                                </Button>
                              </div>
                            </Col>
                          </Fragment>
                        );
                      }
                    })
                  : ""}
                {/* {takeAway.length > 0 ? ( */}
                <h3
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    background: "grey",
                    padding: 10,
                    color: "#FFFFFF"
                  }}
                >
                  TA-MED
                </h3>
                {takeAway.length > 0
                  ? takeAway.map((item, index) => {
                      var tcntr = 0;
                      console.log("cntr", tcntr);
                      item.items.length > 0 &&
                        item.items.forEach((it, ind) => {
                          console.log("IT INDEX", it);
                          if (
                            it.sCatType === "BAR" &&
                            it.status !== "PREPARED"
                          ) {
                            tcntr += 1;
                          }
                        });
                      console.log("cntr after", tcntr);
                      if (Number(tcntr) > 0) {
                        return (
                          <Fragment>
                            <Col
                              onClick={() => {
                                this.showTable(item);
                              }}
                              key={index}
                              className="items gutter-row"
                            >
                              <div className="button-style">
                                <Button
                                  className="drinks button-size-icon"
                                  shape="circle"
                                >
                                  <div className="menu-options white">
                                    {item.orderType === "PLU1" &&
                                    item.number > 0 ? (
                                      <span>
                                        Table <b>{item.number}</b>
                                      </span>
                                    ) : (
                                      <span>{item.orderType}</span>
                                    )}
                                  </div>
                                  <br />
                                  <b>{item.employeeId.firstName}</b>
                                </Button>
                              </div>
                            </Col>
                          </Fragment>
                        );
                      }
                    })
                  : ""}

                {/* {driveOut.length > 0 ? ( */}
                <h3
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    background: "grey",
                    padding: 10,
                    color: "#FFFFFF"
                  }}
                >
                  UTKJØRING{" "}
                </h3>
                {driveOut.length > 0
                  ? driveOut.map((item, index) => {
                      var dcntr = 0;
                      console.log("cntr", dcntr);
                      item.items.length > 0 &&
                        item.items.forEach((it, ind) => {
                          if (
                            it.sCatType === "BAR" &&
                            it.status !== "PREPARED"
                          ) {
                            dcntr += 1;
                          }
                        });
                      console.log("cntr after", dcntr);
                      if (Number(dcntr) > 0) {
                        return (
                          <Fragment>
                            <Col
                              onClick={() => {
                                this.showTable(item);
                              }}
                              key={index}
                              className="items gutter-row"
                            >
                              <div className="button-style">
                                <Button
                                  className="drinks button-size-icon"
                                  shape="circle"
                                >
                                  <div className="menu-options white">
                                    {item.orderType === "PLU1" &&
                                    item.number > 0 ? (
                                      <span>
                                        Table <b>{item.number}</b>
                                      </span>
                                    ) : (
                                      <span>{item.orderType}</span>
                                    )}
                                  </div>
                                  <br />
                                  <b>{item.employeeId.firstName}</b>
                                </Button>
                              </div>
                            </Col>
                          </Fragment>
                        );
                      }
                    })
                  : ""}

                {/* {noTable.length > 0 ? ( */}
                <h3
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    background: "grey",
                    padding: 10,
                    color: "#FFFFFF",
                    marginBottom: 20
                  }}
                >
                  INGEN - BORD
                </h3>
                {noTable.length > 0
                  ? noTable.map((item, index) => {
                      var cntr = 0;
                      console.log("cntr", cntr);
                      item.items.length > 0 &&
                        item.items.forEach((it, ind) => {
                          console.log("IT NO TABLE", it);
                          if (
                            it.sCatType === "BAR" &&
                            it.status !== "PREPARED"
                          ) {
                            cntr += 1;
                          }
                        });
                      console.log("cntr after", cntr);
                      if (Number(cntr) > 0) {
                        return (
                          <Fragment>
                            <Col
                              onClick={() => {
                                this.showTable(item);
                              }}
                              key={index}
                              className="items gutter-row"
                            >
                              <div className="button-style">
                                <Button
                                  className="drinks button-size-icon"
                                  shape="circle"
                                >
                                  <div className="menu-options white">
                                    {item.orderType === "PLU1" &&
                                    item.number > 0 ? (
                                      <span>
                                        Table <b>{item.number}</b>
                                      </span>
                                    ) : (
                                      <span>{item.orderType}</span>
                                    )}
                                  </div>
                                  <br />
                                  <b>{item.employeeId.firstName}</b>
                                </Button>
                              </div>
                            </Col>
                          </Fragment>
                        );
                      }
                    })
                  : ""}
              </Row>
            </div>

            {this.state.pendingOnlineOrders.length > 0 ? (
              <h3
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  background: "grey",
                  padding: 10,
                  color: "#FFFFFF"
                }}
              >
                ONLINE ORDERS
                {/* <span style={{ color: "#74b9ff", float: "right" }}>
                  <Badge
                    count={this.state.pendingOnlineOrders.length}
                    style={{
                      backgroundColor: "#00b894",
                      color: "#FFFFFF",
                      fontSize: 14,

                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  />
                </span> */}
              </h3>
            ) : (
              ""
            )}
            <div style={{ padding: "20px" }}>
              <Row gutter={16}>
                {this.state.pendingOnlineOrders.map((order, index) => {
                  var cntr = 0;
                  order.items.length > 0 &&
                    order.items.forEach((it, ind) => {
                      if (it.sCatType === "BAR" && it.status !== "PREPARED") {
                        cntr += 1;
                      }
                    });
                  console.log("cntr after", cntr);
                  if (Number(cntr) > 0) {
                    return (
                      <Col
                        span={7}
                        style={{ cursor: "pointer" }}
                        onClick={() => this.showOnlineOrder(order)}
                      >
                        <Card
                          title={order.orderStatus}
                          // extra={moment(order.updatedAt)
                          //   .add(order.deliveryTime)
                          //   .format("hh:mm A")}
                          bordered={false}
                        >
                          <b>OTP</b> - {order.otp}
                          <br />
                          <b>Order Type</b> - {order.orderType}
                          <br />
                          <b>Order Time</b> -{" "}
                          <Countdown
                            style={{ color: "red" }}
                            date={
                              moment(order.updatedAt) +
                              Number(order.deliveryTime)
                            }
                            renderer={renderer}
                          ></Countdown>
                        </Card>
                      </Col>
                    );
                  }
                })}
              </Row>
            </div>
          </Home>
        );
      } else {
        return <Home />;
      }
    } else if (this.state.currentPage === "Singlekitchenview") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Order Details
            </span>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() => this.goBackButton()}
                  className="back-button-border"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <SingleKitchenInvoice
            data={this.state.orderData}
            saveTable={() => this.saveTable()}
            crrurl={this.props.location.pathname}
            // cancelReason={this.state.cancelReason}
            getallTables={() => this.getallTables()}
          />
        </Home>
      );
    } else if (this.state.currentPage === "Singleorderkitchenview") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Online Order Details
            </span>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() => this.goBackButton()}
                  className="back-button-border"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <SingleKitchenInvoice
            data={this.state.onlineorderData}
            getOnlineOrders={() => this.getOnlineOrders("active")}
            crrurl={this.props.location.pathname}
            onlineorder={true}
          />
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const BarView = connect(mapStateToProps)(BarViewComponent);
export default BarView;
