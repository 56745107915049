import React, { Component, Fragment } from "react";
import Home from "../Home/Home";
import ReactToPrint from "react-to-print";
import "./Reports.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import ReportsButton from "../ReportsButton/ReportsButton";
import {
  DatePicker,
  Input,
  Form,
  Button,
  notification,
  Select,
  Table
} from "antd";
import PrintReport from "../PrintReport/PrintReport";
import appConfig from "../../helpers/appConfig";
import SingleInvoice from "../SingleInvoice/SingleInvoice";
import SingleOrder from "../SingleOrder/SingleOrder";
import TableSum from "../TableSum/TableSum";
import TableSumPag from "../TableSum/TableSumPag";
import moment from "moment";
import { subscribeToOrder, subscribeToTableOrder } from "../../helpers/socket";
import { any } from "prop-types";
const { MonthPicker, WeekPicker } = DatePicker;
const Option = Select.Option;

class ReportsComponent extends Component {
  isQrTable = "false";
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      sendProps: false,
      error: false,
      clicked: false,
      items: [],
      branchInfo: "",
      cashreportData: "",
      title: "Reports",
      startDate: "",
      endDate: "",
      year: "",
      currentPage: "buttons",
      paginationNo: 1,
      billData: "",
      orderData: "",
      tax: "",
      driveout: 0,
      unitPriceTotal: 0,
      total15Tax: 0,
      total25Tax: 0,
      total15Quantity: 0,
      total25Quantity: 0,
      finalTotal: 0,
      totalSubtotal: 0,
      totalTax: 0,
      totalTaxPrice: 0,
      totalDiscount: 0,
      totalDriveoutCharge: 0,
      orders: 0,
      dcount: 0,
      timemodalvisible: false,
      apiData: null,
      timeString: "00:00",
      cancelModalvisible: false,
      cancelReason: "",
      totalExtra: 0,
      onlineStatus: "all",
      onlineDate: "",
      fetchApi: true,
      tableOrders: 0,
      isQrTable: "false",
      onlineTableorder: 0
    };

    subscribeToOrder((err, orders) =>
      this.setState({
        orders
      })
    );

    subscribeToTableOrder(async (err, orders) => {
      this.setState({
        onlineTableorder: orders
      });
    });
  }

  componentDidMount() {
    this.isQrTable = localStorage.getItem("isQrAllow");
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/v1/orders/ordercount`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ orders: res.data });
        localStorage.setItem("currentOrders", res.data);
      })
      .catch((err) => console.log(err));
  }

  //  TIME MODAL FUNCTIONS STARTING
  showtModal = () => {
    this.setState({
      timemodalvisible: true
    });
  };
  showcancelModel = () => {
    this.setState({ cancelModalvisible: true });
  };
  onChangetime = (time, timeString) => {
    console.log("this is time", timeString);
    timeString = timeString;
    this.setState({ timeString: timeString });
    // changeStatus("IN-PROCESS", data._id, timeString, data.orderType);
  };
  onChangeCancelReason = (rsn) => {
    // alert("HERE TOU ARE ");
    console.log("all the values ", rsn.target.value);
    this.setState({ cancelReason: rsn.target.value });
  };

  // oncancelChange = e => {
  //   console.log("radio checked", e.target.value);
  //   this.setState({
  //     radiocancelvalue: e.target.value
  //   });
  // };

  handleOk = () => {
    // console.log(e);
    this.setState({
      timemodalvisible: false
    });
  };

  handleCancel = () => {
    // console.log(e);
    this.setState({
      timemodalvisible: false,
      cancelModalvisible: false
    });
  };
  //  TIME MODAL FUNCTION ENDING

  getInvoices = (title, startDate, endDate, change) => {
    let data;
    let pageNo = this.state.paginationNo;
    if (title === "DAILY REPORTS") {
      data = JSON.stringify({
        startDate: moment(startDate).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(endDate).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        }),
        pageNo: pageNo
      });
    } else if (title !== "X-REPORTS") {
      data = JSON.stringify({
        startDate: moment(startDate).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(endDate).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        }),
        pageNo: pageNo
      });
    } else {
      console.log("we are in x-report");
      data = JSON.stringify({
        startDate: moment(startDate),
        endDate: moment(endDate),
        pageNo: pageNo
      });
    }

    this.setState({
      isLoaded: false,
      apiData: data
    });

    //console.log(`~~~~~~~~~~~~~~`, title, data, `~~~~~~~~~~~~~~`);

    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/invoice`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result.data);
            this.openNotification("error", result.data);
          } else {
            //! Magic, Do Not Touch
            let branchInfo = result.data.branch;
            let totalDocs = result.data.invoiceData.totalDocs;
            let pageLimit = result.data.invoiceData.limit;
            let curPageNo = result.data.invoiceData.page;
            let data = result.data.invoiceData.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(3),
              isLoaded: true,
              key: item._id
            }));
            let invoiceToLoad = data.reverse();
            this.setState({
              isLoaded: true,
              items: invoiceToLoad,
              branchInfo,
              sendProps: true,
              totalDocs,
              pageLimit,
              paginationNo: curPageNo
            });

            let totalSubtotal = 0;
            let totalTax = 0;
            let totalTaxPrice = 0;
            let totalDriveoutCharge = 0;
            result.data.invoiceData.forEach((current, index) => {
              current.taxPrice = parseFloat(current.taxPrice);
              current.tax = parseFloat(current.tax);
              current.driveoutCharge = parseFloat(current.driveoutCharge || 0);
              current.totalPrice = parseFloat(current.totalPrice || 0);

              totalSubtotal =
                totalSubtotal +
                (current.taxPrice -
                  (current.taxPrice -
                    (100 * current.taxPrice) / (100 + current.tax)));

              totalTax =
                totalTax +
                (current.taxPrice -
                  (100 * current.taxPrice) / (100 + current.tax));

              if (current.orderType === "Drive Out")
                totalDriveoutCharge =
                  totalDriveoutCharge +
                  current.driveoutCharge +
                  current.extraDriveOut;

              totalTaxPrice = totalTaxPrice + current.totalPrice;

              //console.log(current.invoiceNumber, current, index, totalSubtotal, totalTax, totalDriveoutCharge, totalTaxPrice);
            });

            totalSubtotal = parseFloat(totalSubtotal.toFixed(2));
            totalTax = parseFloat(totalTax.toFixed(2));
            totalDriveoutCharge = parseFloat(totalDriveoutCharge.toFixed(2));
            totalTaxPrice = parseFloat(totalTaxPrice.toFixed(2));

            this.setState({
              totalSubtotal,
              totalTax,
              totalDriveoutCharge,
              totalTaxPrice
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  printReportHandler = async () => {
    let { apiData, title } = this.state;

    if (!apiData) {
      apiData = JSON.stringify({
        startDate: moment().set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment().add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    }

    apiData = JSON.parse(apiData);

    let type = "N"; // Normal Reports
    if (title === "X-REPORTS") type = "X";
    // X Reports
    else if (title === "CASH REPORTS") type = "C"; //  Cash Reports

    console.log(
      "~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!",
      apiData,
      "~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!",
      this.state.currentPage,
      this.state.title
    );

    await this.setState({
      isLoaded: false
    });

    let result = await fetch(
      `${appConfig.endpoint}/api/v1/employee/reports/print`,
      {
        method: "POST",
        body: JSON.stringify({
          type,
          ...apiData,
          title: this.state.title
        }),
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "application/json"
        }
      }
    ).then((res) => res.json());

    await this.setState({
      isLoaded: true
    });

    if (result.success === -1) {
      // Not Logged In
      this.openNotification("error", "Please login again to continue");
      this.props.history.push("/");
      return;
    } // Not Logged In

    if (result.success !== 1) {
      this.openNotification("error", result.message);
      return;
    }
    this.openNotification("success", "Report Printed successfully");
    window.open(result.data.url, "_blank");
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  // handleClick = title => {
  //   this.setState(prevState => {
  //     return { clicked: !prevState.clicked, title: title };
  //   }, this.getInvoices(title, moment().startOf('day')._d, moment().endOf('day')._d));
  // };

  //On page load
  handleClick = (title) => {
    if (
      title === "BANK REPORTS" ||
      title === "TFL REPORTS"
      // ||
      // title === "VIPPS REPORTS"
    ) {
      this.setState({ currentPage: "buttons" });
    } else {
      this.setState(
        {
          items: [],
          currentPage: "reports",
          title
        },
        this.sendDate(title)
      );
    }
  };

  sendDate = (title) => {
    // alert("sendDate");
    let startDate, endDate, date;
    switch (title) {
      case "DAILY REPORTS":
        startDate = moment().date()._d;
        endDate = moment().date()._d;
        return this.getInvoices(title, startDate, endDate);
      case "WEEKLY REPORTS":
        // startDate = moment().startOf('week')._d;
        // endDate = moment().endOf('week')._d;
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        return this.getInvoices(title, startDate, endDate);
      case "MONTHLY REPORTS":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        return this.getInvoices(title, startDate, endDate);
      case "YEARLY REPORTS":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        return this.getInvoices(title, startDate, endDate);
      case "X-REPORTS":
        date = moment().date()._d;
        const time = moment(date).format("HH");
        if (Number(time) < 4) {
          date = moment(date).subtract(1, "days");
        }
        return this.onOk(date);
      case "ONLINE ORDERS":
        //console.log("FORM HERE ITS CALLED");
        return this.getOnlineOrders(date);
      case "CASH REPORTS":
        startDate = moment().date()._d;
        endDate = moment().date()._d;
        //console.log("FROM HERE CASH REPORTS IS CALLED");
        return this.getCashReports(startDate, endDate);
      default:
        return 0;
    }
  };

  getCashReports = (startDate, endDate) => {
    var datedata = JSON.stringify({
      startDate: moment(startDate).set({
        hour: 4,
        minute: 0,
        second: 0
      }),
      endDate: moment(endDate).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      })
    });
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/getcashreport`, {
      method: "POST",
      body: datedata,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        var dd = JSON.parse(JSON.stringify(result)).data;
        this.setState({ cashreportData: dd });
      });
  };
  getOnlineOrders = (date) => {
    this.setState({ onlineDate: date });
    const hdr = localStorage.token;
    const startDate = moment(date).set({
      hour: 4,
      minute: 0,
      second: 0
    });
    const endDate = moment(date).add(1, "days").set({
      hour: 3,
      minute: 59,
      second: 59
    });
    const orderType = this.state.onlineStatus;
    const data = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      orderType: orderType
    });
    fetch(`${appConfig.endpoint}/api/v1/employee/orders/getOrders`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          let items = result.data && result.data.order ? result.data.order : [];
          let onlineTotal =
            result.data && result.data.total[0]
              ? result.data.total[0].totalAmount
              : 0;
          this.setState({
            items,
            onlineTotal,
            isLoaded: true,
            sendProps: true
          });
        }
      });
  };
  getOnlineOrders_bk = (filter) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/getorders`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          let items = [];
          let onlineTotal = "";
          switch (filter) {
            case "all":
              items = result.data;
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
            case "active":
              items = result.data.filter(
                (item) => item.orderStatus === "IN-PROCESS"
              );
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
            case "delivered":
              items = result.data.filter(
                (item) => item.orderStatus === "DELIVERED"
              );

              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
            case "pending":
              items = result.data.filter(
                (item) => item.orderStatus === "PENDING"
              );

              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
            case "cancelled":
              items = result.data.filter(
                (item) => item.orderStatus === "CANCEL"
              );
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
            default:
              items = result.data;
              onlineTotal = items.reduce(
                (acc, current) =>
                  acc +
                  (current.items.reduce(
                    (acc, curr) =>
                      acc +
                      curr.totalPrice +
                      (curr.extraPrice ? curr.extraPrice : 0),
                    0
                  ) +
                    (current.orderType == "Drive Out"
                      ? current.driveoutCharge
                      : 0)),
                0
              );
              break;
          }
          this.setState({
            items,
            onlineTotal,
            isLoaded: true,
            sendProps: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error
        });
      });
  };

  changeStatus = (type, id, timeString, orderType) => {
    const data = JSON.stringify({
      orderStatus: type,
      id,
      time: this.state.timeString ? this.state.timeString : 0,
      orderType: orderType,
      cancelReason: this.state.cancelReason ? this.state.cancelReason : ""
    });
    let hdr = localStorage.token;
    if (this.state.fetchApi) {
      this.setState({ fetchApi: false });
      fetch(
        `${appConfig.endpoint}/api/v1/employee/orders/onlineinvoicecreation`,
        {
          method: "POST",
          headers: {
            "x-auth-token": hdr,
            "Content-Type": "application/json"
          },
          body: data
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "failure") {
            this.setState({ fetchApi: true });
          } else {
            if (type === "DELIVERED") {
              this.reduceDeliveredStock(id);
            }
            this.handleCancel();
            this.setState({ fetchApi: true });
            this.setState(
              {
                currentPage: "reports"
              },
              () => this.getOnlineOrders(this.state.onlineDate)
            );
          }
        })
        .catch((error) => {
          this.setState({
            error,
            fetchApi: true
          });
        });
    }
  };
  // when someone changes the date
  reduceDeliveredStock = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/reduceDeliveredStock`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("failed to reduce the ingredients!");
        } else {
          console.log("Successfully reduced the ingredients!");
        }
      });
  };

  onChange = (title, date, dateString) => {
    this.setState({ filterDate: date });
    let startDate, endDate;
    switch (title) {
      case "DAILY REPORTS":
        startDate = date;
        endDate = date;
        return this.getInvoices(title, startDate, endDate, "change");
      case "CASH REPORTS":
        startDate = date;
        endDate = date;
        // return this.getInvoices(title, startDate, endDate);
        return this.getCashReports(startDate, endDate);
      case "WEEKLY REPORTS":
        startDate = moment(date).startOf("week");
        endDate = moment(date).endOf("week");
        return this.getInvoices(title, startDate, endDate);
      case "MONTHLY REPORTS":
        startDate = moment(date).startOf("month");
        endDate = moment(date).endOf("month");
        return this.getInvoices(title, startDate, endDate);
      case "YEARLY REPORTS":
        startDate = moment(date).startOf("year");
        endDate = moment(date).endOf("year");
        return this.getInvoices(title, startDate, endDate);

      default:
        return "error";
    }
  };
  onFilterChange = async (title, date, dateString) => {
    await this.setState({ paginationNo: 1 });
    await this.onChange(title, date, dateString);
  };
  pageChange = async (pagination) => {
    if (pagination) {
      await this.setState({ paginationNo: pagination.current });
      await this.onChange(this.state.title, this.state.filterDate, "");
    }
  };

  onOk = (date) => {
    this.setState({ today: date });
    let data = JSON.stringify({
      startDate: moment(date).set({
        hour: 4,
        minute: 0,
        second: 0
      }),
      endDate: moment(date).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      })
    });
    this.setState({ isLoaded: false });
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/report`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data.data);
          } else {
            let unitPriceTotal = result.data.reportData.reduce(
              (acc, record, index) => {
                return (
                  acc +
                  (record.total -
                    (record.taxPrice15 && record.taxPrice25
                      ? record.taxPrice15 + record.taxPrice25
                      : record.taxPrice15
                      ? record.taxPrice15
                      : record.taxPrice25) -
                    (record.extraPrice ? record.extraPrice : 0))
                );
              },
              0
            );

            let finalTotal = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.total;
              },
              0
            );

            let total15Quantity = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.unitoftax15;
              },
              0
            );

            let total25Quantity = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.unitoftax25;
              },
              0
            );

            let total25Tax = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.taxPrice25;
              },
              0
            );

            let total15Tax = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.taxPrice15;
              },
              0
            );

            let totalDiscount = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.discountPrice;
              },
              0
            );
            let totalExtra = result.data.reportData.reduce(
              (acc, current, index) => {
                if (current.extraPrice) {
                  return acc + current.extraPrice;
                }
                return acc;
              },
              0
            );
            this.setState({
              isLoaded: true,
              sendProps: true,
              dcount: result.data.dcount ? result.data.dcount : "",
              tatalOrdersPrice: result.data.tatalOrdersPrice
                ? result.data.tatalOrdersPrice
                : 0,
              items: result.data.reportData,
              tax: result.data.tax,
              driveoutCharge: result.data.branch.driveoutCharge,
              branchInfo: result.data.branch,
              finalTotal,
              total15Quantity,
              total25Quantity,
              total25Tax,
              total15Tax,
              totalDiscount,
              unitPriceTotal,
              totalExtra
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  goBackButton = () => {
    let { currentPage } = this.state;
    if (currentPage === "reports") {
      this.setState({
        currentPage: "buttons"
      });
    } else if (currentPage === "invoice" || currentPage === "order") {
      this.setState({
        currentPage: "reports"
      });
    }
  };

  searchType = (e) => {
    this.setState({ year: e.target.value });
  };

  showInvoice = (invoiceNumber) => {
    let index = this.state.items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    this.setState({
      currentPage: "invoice",
      billData: this.state.items[index]
    });
  };

  showOrder = (id) => {
    //console.log("items", this.state.items);
    let index = this.state.items.findIndex((item) => item._id === id);
    this.setState({
      currentPage: "order",
      orderData: this.state.items[index]
    });
  };

  searchYear = async (e) => {
    let startDate = moment(e.key);
    let endDate = moment(startDate).endOf("year");
    await this.setState({ paginationNo: 1 });
    return await this.getInvoices("YEARLY REPORTS", startDate, endDate);
  };

  handleSwitch = (title) => {
    switch (title) {
      case "DAILY REPORTS":
        return (
          <DatePicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            format="YYYY-MM-DD"
            placeholder="Select day"
          />
        );
      case "CASH REPORTS":
        return (
          <DatePicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            format="YYYY-MM-DD"
            placeholder="Select day"
          />
        );
      case "WEEKLY REPORTS":
        return (
          <WeekPicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            placeholder="Select week"
          />
        );
      case "MONTHLY REPORTS":
        return (
          <MonthPicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            placeholder="Select month"
          />
        );
      case "YEARLY REPORTS":
        const currentYear = new Date().getFullYear();
        const arr = [];
        for (let i = 2018; i <= currentYear; i++) {
          arr.push(i.toString());
        }
        return (
          <>
            <Select
              labelInValue
              defaultValue={{ key: "2019" }}
              style={{ width: 120 }}
              onChange={(e) => this.searchYear(e)}
            >
              {arr.map((year) => (
                <Option value={year}>{year}</Option>
              ))}
            </Select>
          </>
        );
      case "X-REPORTS":
        return <span>Today's Reports</span>;
      default:
        return "";
    }
  };

  render() {
    const firstButtonItems = ["DAILY REPORTS", "CASH BOX REPORT"];

    const secondButtonItems = ["X-REPORTS", "ONLINE ORDERS"];
    const thirdButtonItems = ["DAILY REPORTS"];

    const ordersSummaryColumn = [
      {
        title: "Total",
        width: "72%",
        render: () => <strong>Total</strong>
      },
      {
        title: "Total",
        render: () => `Kr.${this.state.onlineTotal}`
      }
    ];
    const cashReportsColumn = [
      {
        title: "Employee Name",
        width: "72%",
        render: (text, record) =>
          `${new Date(record.createdAt).toLocaleDateString()} - ${new Date(
            record.createdAt
          ).toLocaleTimeString()}`
      },
      {
        title: "Date/Time"
      }
    ];

    const dailySummaryColumns = [
      {
        title: "emp",
        width: "10.3%",
        render: () => ``
      },
      {
        title: "Total",
        width: "13.8%",
        render: () => <strong>Total</strong>
      },
      {
        title: "emp",
        width: "12%"
      },
      {
        title: "emp",
        width: "9.4%"
      },
      {
        title: "Sub-Total",
        width: "12.7%",
        render: () => `Kr.${this.state.totalSubtotal.toFixed(2)}`
      },
      {
        title: "Tax",
        width: "9.4%",
        render: () => `Kr.${this.state.totalTax.toFixed(2)}`
      },
      {
        title: "total drive out",
        width: "12%",
        render: () => {
          return `Kr.${this.state.totalDriveoutCharge}`;
        }
      },
      {
        title: "Total",
        width: "15%",
        render: () => `Kr.${this.state.totalTaxPrice.toFixed(2)}`
      },
      {
        title: "emp",
        width: "9%"
      }
    ];

    const summaryColumns = [
      {
        title: "Name",
        width: "23%",
        render: () => <strong>Total + Total Drive Out</strong>
      },
      {
        title: "Tax",
        width: "13%",
        children: [
          {
            title: "Total 15% Tax",
            width: "13%",
            render: () => `Kr.${this.state.total15Tax.toFixed(2)}`
          },
          {
            title: "Total 25% Tax",
            width: "12%",
            render: () => {
              return `Kr.${(
                Number(this.state.total25Tax) +
                Number(this.state.tatalOrdersPrice) -
                Number(this.state.tatalOrdersPrice * 100) / 125
              ).toFixed(2)}`;
            }
          }
        ]
      },

      {
        title: "Total Unit Price",
        width: "13%",
        render: () => {
          return `Kr.${(
            this.state.unitPriceTotal +
            (this.state.dcount
              ? parseInt(this.state.tatalOrdersPrice * 100) / 125
              : 0)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Total Quantity",
        width: "8%",
        children: [
          {
            title: "15%",
            dataIndex: "unitoftax15",
            width: "7%",
            key: "unitoftax15",
            render: () => this.state.total15Quantity
          },
          {
            title: "25%",
            width: "7%",
            dataIndex: "unitoftax25",
            key: "unitoftax25",
            render: () => this.state.total25Quantity
          }
        ]
      },
      {
        title: "Discount",
        width: "12%",
        render: () => `Kr.${this.state.totalDiscount.toFixed(2)}`
      },
      {
        title: "Ekstra varepris",
        width: "13%",
        render: () => `Kr.${this.state.totalExtra.toFixed(2)}`
      },
      {
        title: "Final Total",
        width: "14%",
        render: () =>
          `Kr.${(this.state.finalTotal + this.state.tatalOrdersPrice).toFixed(
            2
          )}`
      }
    ];

    const summaryData = [
      {
        key: "-1",
        render: () => "test"
      }
    ];

    const xColumns = [
      {
        title: "Sr. No.",
        editable: false,
        width: "8%",
        render: (text, record, index) => {
          return (
            index +
            ((!isNaN(this.state.paginationNo) ? this.state.paginationNo : 1) -
              1) *
              10 +
            1
          );
          // return index + 1;
        }
      },
      {
        title: "Name",
        dataIndex: "productName",
        key: "productName",
        width: "15%"
      },
      {
        title: "Tax",
        width: "14%",
        children: [
          {
            title: "15%",
            width: "13%",
            dataIndex: "taxPrice15",
            render: (text) => {
              return text ? `Kr.${text.toFixed(2)}` : text;
            }
          },
          {
            title: "25%",
            width: "13%",
            dataIndex: "taxPrice25",
            render: (text) => {
              return `Kr.${text.toFixed(2)}`;
            }
          }
        ]
      },
      {
        title: "Unit Price",
        dataIndex: "productPrice",
        key: "productPrice",
        width: "13%",
        render: (text, record) => {
          //console.log("TEXTTEXTTEXT", text);
          return `Kr.${
            text
              ? (
                  record.total -
                  (record.taxPrice15 && record.taxPrice25
                    ? record.taxPrice15 + record.taxPrice25
                    : record.taxPrice15
                    ? record.taxPrice15
                    : record.taxPrice25) -
                  (record.extraPrice ? record.extraPrice : 0)
                ).toFixed(2)
              : ""
          }`;
        }
      },
      {
        title: "Quantity",
        width: "13%",
        children: [
          {
            title: "15%",
            dataIndex: "unitoftax15",
            key: "unitoftax15"
          },
          {
            title: "25%",
            dataIndex: "unitoftax25",
            key: "unitoftax25"
          }
        ]
      },
      {
        title: "Discount",
        key: "discountPrice",
        dataIndex: "discountPrice",
        width: "12%",
        render: (text) => {
          return `Kr.${text.toFixed(2)}`;
        }
      },
      {
        title: "Extra Price",
        key: "extraPrice",
        dataIndex: "extraPrice",
        width: "12%",
        render: (text, record) => {
          return record.extraPrice
            ? `Kr.${record.extraPrice.toFixed(2)}`
            : `Kr.0`;
        }
      },
      {
        title: "Total",
        key: "total",
        dataIndex: "total",
        width: "13%",
        render: (text, record) => {
          var dcount = 0;
          var invoiceNumberArr = [];
          return `Kr.${text ? text.toFixed(2) : ""}`;
        }
      }
    ];

    const ordersColumns = [
      {
        title: "Sr. No.",
        key: "orderNumber",
        width: "5%",
        render: (text, record, index) => index + 1
      },
      {
        title: "Order time",
        key: "orderTime",
        width: "18%",
        render: (text, record) =>
          `${new Date(record.createdAt).toLocaleDateString()} - ${new Date(
            record.createdAt
          ).toLocaleTimeString()}`
      },
      {
        title: "No. of products",
        key: "noProducts",
        width: "9%",
        render: (text, record) =>
          record.items.reduce((acc, current) => acc + current.quantity, 0)
      },
      {
        title: "Status",
        key: "status",
        width: "11%",
        render: (text, record) => {
          if (record.orderStatus == "DELIVERED") {
            return (
              <p
                style={{
                  background: "green",
                  textAlign: "center",
                  borderRadius: 5,
                  color: "white"
                }}
              >
                {record.orderStatus}
              </p>
            );
          } else if (record.orderStatus == "IN-PROCESS") {
            return (
              <p
                style={{
                  background: "orange",
                  textAlign: "center",
                  borderRadius: 5,
                  color: "white"
                }}
              >
                {record.orderStatus}
              </p>
            );
          } else if (record.orderStatus == "CANCEL") {
            return (
              <p
                style={{
                  background: "red",
                  textAlign: "center",
                  borderRadius: 5,
                  color: "white"
                }}
              >
                {record.orderStatus}
              </p>
            );
          } else {
            return record.orderStatus;
          }
        }
      },
      {
        title: "Order Type",
        key: "status",
        width: "10%",
        render: (text, record) => record.orderType
      },
      {
        title: "Total",
        key: "total",
        width: "9%",
        render: (text, record) => {
          if (record.orderType === "Drive Out") {
            return `Kr.${
              record.items.reduce(
                (acc, current) =>
                  acc +
                  current.quantity * current.price +
                  (current.extraPrice ? +current.extraPrice : 0),
                0
              ) + record.driveoutCharge
            }`;
          } else {
            return `Kr.${record.items.reduce(
              (acc, current) =>
                acc +
                current.quantity * current.price +
                (current.extraPrice ? +current.extraPrice : 0),
              0
            )}`;
          }
        }
      },
      {
        title: "View",
        key: "view",
        width: "10%",
        render: (text, record) => (
          <Button onClick={() => this.showOrder(record._id)}>View</Button>
        )
      }
    ];

    const dailyColumns = [
      {
        title: "Invoice No.",
        dataIndex: "invoiceNumber",
        width: "5%",
        render: (text, record) => {
          return record.isPaid == false ? (
            <p style={{ color: "red" }}>{text}</p>
          ) : (
            text
          );
        }
      },
      {
        title: "Date",
        dataIndex: "createdDate",
        key: "createdDate",
        width: "5%"
      },
      {
        title: "Time",
        dataIndex: "createdTime",
        key: "createdTime",
        width: "12%"
      },
      {
        title: "Order Type",
        dataIndex: "orderType",
        key: "orderType",
        width: "10%"
      },

      {
        title: "Sub-Total",
        width: "15%",
        render: (text, record) => {
          return `Kr.${(
            record.taxPrice -
            (
              record.taxPrice -
              (100 * record.taxPrice) / (100 + record.tax)
            ).toFixed(3)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Tax",
        width: "11%",
        render: (text, record) => {
          return `Kr.${(
            record.taxPrice -
            (100 * record.taxPrice) / (100 + record.tax)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Drive out Charge",
        width: "12%",
        render: (text, record) => {
          if (record.orderType == "Drive Out") {
            var extra = record.extraDriveOut ? record.extraDriveOut : 0;
            return `Kr. ${(record.driveoutCharge + extra).toFixed(2) || 0}`;
          } else {
            return `Kr. 0`;
          }
        }
      },
      {
        title: "Total",
        dataIndex: "totalPrice",
        key: "totalPrice",
        width: "15%",
        render: (text, record) => {
          return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
        }
      },
      {
        title: "Action",
        key: "_id",
        width: "5%",
        render: (text, record) => (
          <Button onClick={() => this.showInvoice(record.invoiceNumber)}>
            <i className="fa fa-eye"></i>
          </Button>
        )
      }
    ];
    const { isLoaded, error, currentPage, title } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (currentPage === "buttons") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">Reports</span>
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Reports
            </span>
          </div>
          <div className="different-reports">
            <div id="flex">
              {firstButtonItems.map((x) => (
                <ReportsButton
                  key={x}
                  title={x}
                  css="box shadow"
                  color="orange-bg"
                  clicked={() => this.handleClick(x)}
                />
              ))}
            </div>
            <div id="flex">
              {secondButtonItems.map((x) => (
                <ReportsButton
                  orders={localStorage.getItem("currentOrders")}
                  key={x}
                  title={x}
                  css="box shadow"
                  color="black-bg"
                  clicked={() => this.handleClick(x)}
                />
              ))}
            </div>
          </div>
          {this.isQrTable == "true" && (
            <div id="flex-table">
              {thirdButtonItems.map((x) => (
                <ReportsButton
                  tableOrders={localStorage.getItem("ontableOrders")}
                  key={x}
                  title="TABLE ORDERS"
                  css="box shadow"
                  color="black-bg"
                  clicked={() => this.handleClick(x)}
                />
              ))}
            </div>
          )}
        </Home>
      );
    } else if (currentPage === "reports") {
      const dcharge = this.state.driveoutCharge;
      var dataSource = [
        {
          key: "1",
          dcount: this.state.dcount,
          driveoutTax: `Kr. ${(
            parseInt(this.state.tatalOrdersPrice) -
            (parseInt(this.state.tatalOrdersPrice) * 100) / 125
          ).toFixed(2)}`,
          driveoutCharge: `Kr. ${(
            (this.state.tatalOrdersPrice * 100) /
            125
          ).toFixed(2)}`,
          totalDcharge: this.state.tatalOrdersPrice
        }
      ];

      const cashcolumns = [
        {
          title: "Employee Name",
          width: "25%",
          key: "firstName",
          render: (text) => {
            return `${text.employeeId.firstName} ${text.employeeId.lastName}`;
          }
        },
        {
          title: "Email",
          dataIndex: "employeeId.email",
          width: "25%",
          key: "employeeId.email"
        },
        {
          title: "Date/Time",
          dataIndex: "createdAt",
          width: "25%",
          // key: "createdAt",
          render: (text, record) => {
            return moment(record.createdAt).format("MM-DD-YYYY h:mm a");
          }
        }
      ];

      const columns = [
        {
          title: "Total Orders",
          dataIndex: "dcount",
          width: "10%",
          key: "dcount"
        },
        {
          title: "Drive Out Tax",
          dataIndex: "driveoutTax",
          width: "10%",
          key: "driveoutTax"
        },
        {
          title: "Drive Out Charge",
          dataIndex: "driveoutCharge",
          width: "15%",
          key: "driveoutCharge"
        },

        {
          title: "Total Drive Out Charge",
          dataIndex: "totalDcharge",
          width: "13%",
          key: "totalDcharge"
        }
      ];
      const handleOnlineStatus = async (e) => {
        this.setState({ onlineStatus: e });
        setTimeout(() => this.getOnlineOrders(this.state.onlineDate), 200);
      };
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">Hjem / </span>
              {this.state.title}
            </span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "61%" }}
            >
              {this.handleSwitch(this.state.title)}
            </span>
            {title === "ONLINE ORDERS" && (
              <span
                className="item"
                style={{ position: "absolute", right: 43, top: 38 }}
              >
                <Select
                  style={{ width: 120 }}
                  onChange={(e) => handleOnlineStatus(e)}
                  value={this.state.onlineStatus}
                >
                  <Option key="all" value="all">
                    All
                  </Option>
                  <Option key="pending" value="pending">
                    Pending
                  </Option>
                  <Option key="active" value="active">
                    Active
                  </Option>
                  <Option key="delivered" value="delivered">
                    Delivered
                  </Option>
                  <Option key="cancelled" value="cancelled">
                    Cancelled
                  </Option>
                </Select>
                {/* <button
                    className="back-button-border outlined  "
                    style={{ margin: "0 2px" }}
                    onClick={() => this.getOnlineOrders("all")}
                  >
                    All
                  </button>
                  <button
                    className="back-button-border outlined  "
                    style={{ margin: "0 2px" }}
                    onClick={() => this.getOnlineOrders("pending")}
                  >
                    Pending
                  </button>
                  <button
                    className="back-button-border outlined"
                    style={{ margin: "0 2px" }}
                    onClick={() => this.getOnlineOrders("active")}
                  >
                    Active
                  </button>
                  <button
                    className="back-button-border outlined"
                    style={{ margin: "0 2px" }}
                    onClick={() => this.getOnlineOrders("delivered")}
                  >
                    Delivered
                  </button>
                  <button
                    className="back-button-border outlined"
                    style={{ margin: "0 2px" }}
                    onClick={() => this.getOnlineOrders("cancelled")}
                  >
                    Cancelled
                  </button> */}
                <DatePicker
                  onChange={(date, dateString) => {
                    this.getOnlineOrders(date);
                  }}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />
              </span>
            )}
            {title !== "ONLINE ORDERS" && (
              <span
                className="item"
                style={{ position: "absolute", marginLeft: "85%" }}
              >
                {/* <ReactToPrint
                  trigger={() => (
                    <button className="back-button-border">
                      Print
                    </button>
                  )}
                  content={() => {
                    console.log(`this.componentRef = `, this.componentRef);
                    console.log(`title = ${title}`)
                    return this.componentRef;
                  }}
                  copyStyles={true}
                /> */}
                <button
                  className="back-button-border"
                  onClick={this.printReportHandler}
                >
                  <i
                    className="fa fa-print"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  ></i>
                </button>
              </span>
            )}

            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                  {/* <span>Back</span> */}
                </div>
              </span>
            </span>
          </div>
          {
            //console.log("CASH DATA DATA DATA", this.state.cashreportData)
          }
          {title === "CASH REPORTS" &&
            JSON.parse(JSON.stringify(this.state.cashreportData)) && (
              <Table
              bordered
                dataSource={JSON.parse(
                  JSON.stringify(this.state.cashreportData)
                )}
                columns={cashcolumns}
                pagination={false}
              />
            )}

          {title === "X-REPORTS" && (
            <div>
              <h4>DRIVE OUT ORDERS</h4>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
              <br />
              <TableSum
                loading={isLoaded ? false : true}
                columns={xColumns}
                summaryColumns={summaryColumns}
                data={this.state.items}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                rowKey={(record) => record.productName}
                totalDocs={this.state.totalDocs}
                curPageNo={this.state.paginationNo}
                pageLimit={this.state.pageLimit}
                bordered
              />
            </div>
          )}
          {(title === "DAILY REPORTS" ||
            title === "WEEKLY REPORTS" ||
            title === "MONTHLY REPORTS" ||
            title === "YEARLY REPORTS") && (
            <Fragment>
              <TableSum
                loading={isLoaded ? false : true}
                columns={dailyColumns}
                summaryColumns={dailySummaryColumns}
                data={this.state.items}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                rowKey={(record) => record.productName}
                totalDocs={this.state.totalDocs}
                curPageNo={this.state.paginationNo}
                pageLimit={this.state.pageLimit}
                bordered
              />
              <div style={{ display: "none" }}>
                {this.state.sendProps ? (
                  <PrintReport
                    columns={dailyColumns}
                    summaryColumns={dailySummaryColumns}
                    data={this.state.items}
                    summaryData={summaryData}
                    ref={(el) => (this.componentRef = el)}
                    branch={this.state.branchInfo}
                    totalTaxPrice={this.state.totalTaxPrice}
                    totalSubtotal={this.state.totalSubtotal}
                    totalTax={this.state.totalTax}
                    type="daily-reports"
                  />
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          )}
          {title === "ONLINE ORDERS" && (
            <Fragment>
              <TableSum
                loading={isLoaded ? false : true}
                columns={ordersColumns}
                summaryColumns={ordersSummaryColumn}
                data={this.state.items}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                rowKey={(record) => record.productName}
                bordered
              />
              <div style={{ display: "none" }}>
                {this.state.sendProps && (
                  <PrintReport
                    columns={ordersColumns}
                    summaryColumns={ordersSummaryColumn}
                    data={this.state.items}
                    summaryData={summaryData}
                    ref={(el) => (this.componentRef = el)}
                    branch={this.state.branchInfo}
                    totalTaxPrice={this.state.totalTaxPrice}
                    totalSubtotal={this.state.totalSubtotal}
                    totalTax={this.state.totalTax}
                    type="online"
                  />
                )}
              </div>
            </Fragment>
          )}
        </Home>
      );
    } else if (currentPage === "invoice") {
      // //console.log("BRANCH INFO", this.state.branchInfo);
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Invoice No. : {this.state.billData.invoiceNumber}
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                  {/* <span>Back</span> */}
                </div>
              </span>
            </span>
          </div>
          <SingleInvoice
            data={this.state.billData}
            branch={this.state.branchInfo}
          />
        </Home>
      );
    } else if (currentPage === "order") {
      return (
        <>
          <Home isAdmin={true}>
            <div className="report-container">
              <span className="item">
                <span id="less-visible">Hjem / </span>
                Order Details
              </span>
              <span className="item">
                <span id="less-visible">
                  <div
                    onClick={this.goBackButton}
                    className="back-button-border"
                  >
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    <span>Back</span>
                  </div>
                </span>
              </span>
            </div>
            <SingleOrder
              data={this.state.orderData}
              changeStatus={(type, id, timeString, orderType) =>
                this.changeStatus(type, id, timeString, orderType)
              }
              timemodalvisible={this.state.timemodalvisible}
              cancelModalvisible={this.state.cancelModalvisible}
              showcancelModel={() => this.showcancelModel()}
              showtModal={() => this.showtModal()}
              // handleOk={() => this.handleOk()}
              handleCancel={() => this.handleCancel()}
              onChangetime={this.onChangetime}
              timeString={this.state.timeString}
              onChangeCancelReason={() => this.onChangeCancelReason}
              cancelReason={this.state.cancelReason}
            />
          </Home>
        </>
      );
    } else {
      return <div />;
    }
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}

const mapStateToProps = (state) => {
  return {};
};
const Reports = connect(mapStateToProps)(ReportsComponent);
export default Reports;
