import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { emptySuccessMsg, emptyErrorMsg, logout } from "../../action";
import { getProfileDetails, switchBranch } from "../../action/profileAction";
import { NavLink } from "react-router-dom";
import { Icon as RIcon } from "react-icons-kit";
import { home as HomeIcon, folderOpen } from "react-icons-kit/icomoon";
import { info, logout as logoutIcon } from "react-icons-kit/iconic";
import { gear } from "react-icons-kit/fa/gear";
// import { isMobile } from "react-device-detect";
import MobileView from "../Mobile/MobileView";

import { user as ProfileIcon } from "react-icons-kit/ikons/user";
import FloatingBtn from "./FloatingBtn";
import appConfig from "../../helpers/appConfig";
import history from "../../history";
import BellIcon from "../../assets/bell.png";
import ProfileDrawer from "./ProfileDrawer";
import { sendNotification } from "../../helpers/commFuc";
import "./Home.css";
import Clock from "../../containers/Clock/Clock";
import SideMenu from "./SideMenu";
import { subscribeToOrder, subscribeToTableOrder } from "../../helpers/socket";
import { Badge } from "react-bootstrap";
import {
  getOnlineTableBookingCount,
  getOrderCount,
} from "../../action/ordersAction";
import table from "../../assets/table.png";
import { socket } from "../../utils/socketNew";
import { isMobile } from "react-device-detect";

import axios from "axios";
const branchType = localStorage.getItem("branchType");
console.log("------------branchType HOME", branchType);
const Home = (props) => {
  let pathname = window.location.pathname;
  const dispatch = useDispatch();
  const onlineCount = useSelector((state) => state.orderCount.orders);
  const myReserveTableCount = useSelector(
    (state) => state.orderCount.reserveTableCount
  );

  const onlineTableCount = useSelector(
    (state) => state.onlineTableBookingCount.orders
  );
  const treeDriveCount = useSelector(
    (state) => state.treeDriveOrderCount.orders
  );

  const vendorOrderCount = useSelector(
    (state) => state.vendorOrderCount.orders
  );
  const [orderS, setOrderS] = useState("");
  let [isVisibleProfile, setIsVisibleProfile] = useState(false);
  let [branchChange, setBranchChange] = useState(0);
  const [sideBar, setSidBar] = useState(true);
  const [reserveTableCount, setReserveTableCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);

  let event = new Date();
  useEffect(() => {
    if (isVisibleProfile) {
      props.getProfileDetails();
    }
  }, [isVisibleProfile, branchChange]);

  const countTotal = () => {
    let count1 = localStorage.getItem("onlineOrderCount");
    let count2 = localStorage.getItem("onlineTableCount");
    let count3 = localStorage.getItem("treeDriveOrderCount");
    if (isNaN(Number(count1))) {
      count1 = 0;
    }
    if (isNaN(Number(count2))) {
      count2 = 0;
    }
    if (isNaN(Number(count3))) {
      count3 = 0;
    }
    let total = Number(count1) + Number(count2) + Number(count3);
    return total;
  };

  useEffect(() => {
    countTotal();
    setOrderS(countTotal());
  }, [onlineCount, onlineTableCount, treeDriveCount]);

  useEffect(() => {
    let vendorOrders = localStorage.getItem("vendorOrderCount");
  }, [vendorOrderCount]);
  console.log("vendorOrderCount", vendorOrderCount);

  useEffect(() => {
    let myReserveTableCount2 = localStorage.getItem("reserveTableCount");
    setReserveTableCount(myReserveTableCount2);
  }, [myReserveTableCount]);


  useEffect(() => {
    let myTodayCount = localStorage.getItem("todayCount");
    setTodayCount(myTodayCount)
  }, [todayCount]);
  console.log("myTodayCount", todayCount);

  useEffect(() => {
    if (props.errorMassage) {
      sendNotification("error", props.errorMassage);
      props.emptyErrorMsg();
    }
    return () => {
      props.emptyErrorMsg();
    };
  }, [props.errorMassage]);

  useEffect(() => {
    if (props.successMassage) {
      sendNotification("success", props.successMassage);
    }
    return () => {
      props.emptySuccessMsg();
    };
  }, [props.successMassage]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const branchId = localStorage.getItem("bid");
    socket.emit("logout");
    socket.emit("addUser", { email: user.email, branchId });
  }, []);
  useEffect(() => {
    if (pathname == "/plu" || pathname == "/billing") {
      setSidBar(false);
    } else {
      setSidBar(true);
    }
  }, [pathname]);

  let dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let [date, setDate] = useState(
    event.toLocaleDateString("en-GB", dateOptions)
  );

  const logo = localStorage.getItem("logo");
  let user = JSON.parse(localStorage.getItem("user"));
  const branchType = localStorage.getItem("branchType");
  const orders = localStorage.getItem("currentOrders");
  let profileImage = localStorage.getItem("profileImage");
  let firstName = user && user.firstName ? user.firstName : "";
  let branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
  if (!branchInfo || !user) {
    localStorage.removeItem("token");
    history.push("/");
  }
  const handleSwitch = (id) => {
    if (id !== props.details.branchId._id) {
      props
        .switchBranch({ id: id })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1);
        })
        .catch((err) => {
          console.log(err);
        });
      setBranchChange(branchChange + 1);
    }
  };
  return (
    <>
      {isMobile && <MobileView />}
      {!isMobile && (
        <>
          <header className="homeLayout">
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top justify-content-between">
              <NavLink
                className="navbar-brand d-position m-0 col-2 p-0"
                to="/dashboard"
              >
                <img
                  src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                  className="company-logo-header hide-logo p-0"
                />
                <span>
                  <h5
                    style={{
                      color: "#1b3045",
                      fontSize: "14px",
                      fontWeight: "700",
                      paddingTop: "10px",
                      marginLeft:"10px"
                    }}
                    className="m-0"
                  >
                    {" "}
                    {branchInfo && branchInfo.name}
                  </h5>
                </span>
                <img
                  src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                  className="company-logo-header2 hide-logo2"
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="col-5 collapse navbar-collapse main-menu d-flex justify-content-around"
                id="navbarNavDropdown"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      activeClassName="nav-active"
                      to="/dashboard"
                    >
                      <RIcon size={32} icon={HomeIcon} />
                      <p className="para">Hjem</p>
                    </NavLink>
                  </li>
                  {branchType == "rest" ? (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeClassName="nav-active"
                        to="/rapporter"
                      >
                        {orderS && Number(orderS) ? (
                          <Badge bg="danger badge-folder-cus">{orderS}</Badge>
                        ) : (
                          ""
                        )}
                        <RIcon size={32} icon={folderOpen} />
                        <p className="para">Rapporter</p>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {branchType == "vendor" ? (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeClassName="nav-active"
                        to="/vendor"
                      >
                        {vendorOrderCount && Number(vendorOrderCount) ? (
                          <Badge bg="danger badge-folder-cus">
                            {vendorOrderCount}
                          </Badge>
                        ) : (
                          ""
                        )}
                        <RIcon size={32} icon={folderOpen} />
                        <p className="para">Rapporter</p>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {branchType == "retailer" ? (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeClassName="nav-active"
                        to="/retailer"
                      >
                        {/* {vendorOrderCount && Number(vendorOrderCount) ? (
                          <Badge bg="danger badge-folder-cus">
                            {vendorOrderCount}
                          </Badge>
                        ) : (
                          ""
                        )} */}
                        <RIcon size={32} icon={folderOpen} />
                        <p className="para">Rapporter</p>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-active"
                      to="/information"
                    >
                      <RIcon size={32} icon={info} />
                      <p className="para">Info</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-active"
                      to="/profil"
                    >
                      <RIcon size={32} icon={ProfileIcon} />
                      <p className="para">Profil</p>
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="nav-active"
                  to="/iksystem"
                >
                  <RIcon size={32} icon={gear} />
                  <p className="para">Ik System</p>
                </NavLink>
              </li> */}

                  {branchType == "rest" ? (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link reserveBtn"
                        activeClassName="nav-active"
                        to="/reservetable"
                      >
                        <img
                          src={table}
                          alt="table"
                          style={{ width: "32px" }}
                        />
                        {Number(reserveTableCount) > 0 && (
                          <Badge bg="danger badge-table">
                            {reserveTableCount}
                          </Badge>
                        )}
                        <p className="para">Reserve Table</p>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* {branchType == "rest" ? (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link reserveBtn"
                        activeClassName="nav-active"
                        to="/reservetable"
                      >
                        <img
                          src={table}
                          alt="table"
                          style={{ width: "32px" }}
                        />
                        {Number(todayCount) > 0 && (
                          <Badge  bg="danger badge-table">
                            {todayCount}
                          </Badge>
                        )}
                        <p className="para">Today's Order</p>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )} */}

                  <li className="nav-item">
                    <button
                      style={{
                        border: "none",
                        height: "inherit",
                        backgroundColor: "transparent",
                      }}
                      className="nav-link"
                      onClick={() => props.logout()}
                    >
                      <RIcon size={32} icon={logoutIcon} />
                      <p className="para">Logg Ut</p>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="time-profile col-3">
                <span className="header-info-wrapper text-white">
                  {date} | <Clock />
                </span>
              </div>
              <div
                className="collapse navbar-collapse col-2"
                id="navbarNavDropdown-2"
              >
                <ul className="navbar-nav ">
                  <li className="nav-item p-tb-10 d-flex align-items-center">
                    {orderS && Number(orderS) ? (
                      <Badge bg="danger badge-cus">{orderS}</Badge>
                    ) : (
                      ""
                    )}
                    <img src={BellIcon} className="bell-img" />
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="#"
                      className="nav-link p-0 d-flex"
                      onClick={() => setIsVisibleProfile(true)}
                    >
                      <img
                        className="profile-img float-right"
                        src={
                          profileImage
                            ? `${appConfig.s3url}/${profileImage}`
                            : ""
                        }
                      />
                      <span className="username">
                        {/* {firstName} */}
                        <i className="fa fa-angle-down" aria-hidden="true" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
            <ProfileDrawer
              visible={isVisibleProfile}
              setIsVisibleProfile={setIsVisibleProfile}
              details={props.details}
              switchBranch={props.switchBranch}
              handleSwitch={handleSwitch}
            />
          </header>
          <div className="inner-main bg-image ">
            <div className="container main-c">
              <div className=" m-t-1r">
                <div className="card-body bg">{props.children}</div>
              </div>
            </div>
            <SideMenu />
            {sideBar  ? <FloatingBtn /> : <></>}
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = ({ profileDetails, errorMassage, successMassage }) => {
  return {
    details: profileDetails,
    errorMassage,
    successMassage: successMassage.message,
  };
};
export default connect(mapStateToProps, {
  getProfileDetails,
  switchBranch,
  emptySuccessMsg,
  emptyErrorMsg,
  logout,
})(Home);
