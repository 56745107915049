import { Employee_Tips } from "../../action/types";
const initialState = {};
const employeeTipReducer = (state = initialState, action) => {
  switch (action.type) {
    case Employee_Tips:
      return action.payload.data;
    default:
      return state;
  }
};
export default employeeTipReducer;
