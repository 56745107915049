import axios from "axios";
import history from "./../history";
import {
  GET_CATEGORIES,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  GET_CAT_PRODUCT,
  PRODUCT_STOCKS,
  STOCKS_HISTORY,
} from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/lager/categories`
    );
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const addCategory = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/lager/categories`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getStockProduct = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/lager/categories/product/${id}`
    );
    dispatch({
      type: GET_CAT_PRODUCT,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateStockCategory = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/lager/categories`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const deleteStockCategory = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${appConfig.endpoint}/api/v1/employee/lager/categories/${id}`
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const addCatProduct = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/lager/categories/product`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateCatProduct = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/lager/categories/product`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const deleteProduct = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${appConfig.endpoint}/api/v1/employee/lager/categories/product/${id}`
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getStockProducts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/lager/stockProducts/${id}`
    );
    dispatch({
      type: PRODUCT_STOCKS,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const saveStock = (data, id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/lager/stockProducts/${id}`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getStcokHstory = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/lager/stockHistory`,
      data
    );
    dispatch({
      type: STOCKS_HISTORY,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
