import { notification } from "antd";
const getModuleTax = (module) => {
  const taxes = JSON.parse(localStorage.getItem("taxes"));
  const taxvalue = taxes.find(
    (tax) => tax.taxModules && tax.taxModules.includes(module)
  );
  return taxvalue && taxvalue.taxValue ? taxvalue.taxValue : 0;
};

const sendNotification = (type, message) => {
  notification[type]({
    message: message
  });
};
export { getModuleTax, sendNotification };
