/* eslint-disable eqeqeq */
const billHtmlGenerate = (data) => {
  let discount = data.discount;
  let discountPrice = 0;
  if (discount !== 0) {
    discountPrice = (
      (100 * Number(data.taxPrice)) / (100 - Number(data.discount)) -
      data.taxPrice
    ).toFixed(2);
  }

  console.error("billHtmlGenerate = ", data);

  return `<html style="width:100mm"  id="functional">
    <div id="invoice-POS" style="width:80mm;padding: 2mm;margi-left:4mm;font-family: monospace;">

        <center id="top" style="border-bottom:1px dashed #000000;padding-bottom: 10px;margin-bottom: 10px;">
            <div class="info"> 
                <h2 style="text-align:center;font-size:1.3em;margin-bottom: 2px;"> ${
                  data.branchId.name
                }</h2>
                <h6 style="text-align:center; margin: 0 ;font-size:10px;letter-spacing: 0.4px;font-weight: 100;"> ${
                  data.branchId.address.line1
                }</h6>
                <h6 style="text-align:center; margin: 0 ;font-size:10px;letter-spacing: 0.4px;font-weight: 100;"> ${
                  data.branchId.address.line2
                }  ${data.branchId.address.city}  </h6>
                <h6 style="text-align:center; margin: 0 ;font-size:10px;letter-spacing: 0.4px;font-weight: 100;">  tlf :  ${
                  data.branchId.phoneNo
                }</h6> 
                <h6 style="text-align:center; margin: 0 ;font-size:10px;letter-spacing: 0.4px;font-weight: 100;">   org: ${
                  data.branchId.taxNo
                } mva</h6>
            </div>
        </center>

        <div id="bot">
        <div id="table">
            <table>
            ${data.items
              .map((element) => {
                return `<tr style="font-size: 18px;font-weight:'bold'">
                        <td class="tableitem" style="width:80%">
                            <p class="itemtext">
                            ${element.quantity}
                                <span style="margin-left: 4px;">${
                                  element.productName
                                }</span>
                            </p>
                        </td>
                        <td class="tableitem">
                            <p style="text-align:right;" class="itemtext">
                            ${
                              parseInt(element.productPrice) *
                              parseInt(element.quantity)
                            }
                            </p>
                        </td>
                    </tr>`;
              })
              .join("")}

            <tr class="tabletitle">
                <td style=" font-size: 18px;">
                    <table >
                        <tr>
                        <td style="font-size: 15px;">
                            <p style="margin:0;">herav Mva(${data.tax}%)</p>
                        </td>
                        </tr>
                    </table>
                </td>
                
                <td style=" font-size: 18px;">
                    <table style="width: 100%;">
                        <tr>
                            <td style="font-size: 15px;">
                            <p style="text-align:right;margin:0;"> ${(
                              data.taxPrice -
                              (100 * data.taxPrice) / (100 + data.tax)
                            ).toFixed(2)}</p>
                                </td>
                        </tr>
                    </table>
                </td>
            </tr>
                ${
                  data.orderType == "Drive Out"
                    ? `
                    <tr class="tabletitle">
                      <td style=" font-size: 15px;">
                        <p style="margin:0;">Drive out tax(25%)</p>
                        <table>
                          <tr>
                            <td style="font-size: 15px;">
                              <p style="margin:0;">Drive out charges</p>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td style=" font-size: 15px;">
                        <p style="text-align: right;margin:0; ">
                          ${
                            data.orderType == "Drive Out"
                              ? (
                                  data.driveoutCharge -
                                  (100 * data.driveoutCharge) / 125
                                ).toFixed(2)
                              : 0.0
                          }
                        </p>

                        <table style="width: 100%;">
                          <tr>
                            <td style="font-size: 15px;">
                              <p style="margin:0; text-align: right">
                                ${
                                  data.orderType == "Drive Out"
                                    ? (
                                        (100 * data.driveoutCharge) /
                                        125
                                      ).toFixed(2)
                                    : 0.0
                                }
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>`
                    : " "
                }  
            <tr style="font-size: 18px;font-weight:'bold'">
            <td class="tableitem" style="width:60%">
                <p class="itemtext">
                    Sub-Total :
                </p>
            </td>
            <td class="tableitem">
                <p style="text-align:right;" class="itemtext">
                ${(
                  data.taxPrice -
                  (
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                  ).toFixed(3)
                ).toFixed(2)}
                </p>
            </td>
        </tr>

            <tr style="font-size: 18px;font-weight:'bold'">
                <td class="tableitem" style="width:60%">
                    <p class="itemtext">
                        Discount(${discount}%): 
                    </p>
                </td>
                <td class="tableitem">
                    <p style="text-align:right;" class="itemtext">
                      -${discountPrice}
                    </p>
                </td>
            </tr>

            <tr>
                <td style="font-size: 23px;">
                    Total
                </td>
                <td style="font-size: 28px;">
                    ${data.totalPrice.toFixed(2)}
                </td>
            </tr>

            </table>
        </div>


        <div style="text-align:center;font-size:17px;border-top:1px dashed #000000;padding-top:7px;margin-top:7px;">

        <table>

            <tr style="font-size: 18px;font-weight:'bold'">
                <td class="tableitem" style="width:80%">
                    <p class="itemtext">
                        Invoice Number: 
                    </p>
                </td>
                <td class="tableitem">
                    <p style="text-align:right;" class="itemtext">
                        #${data.invoiceNumber}
                    </p>
                </td>
            </tr>


            <tr style="font-size: 18px;font-weight:'bold'">
                <td class="tableitem">
                    <p class="itemtext">
                        Date: 
                    </p>
                </td>
                <td class="tableitem">
                    <p class="itemtext">
                        ${data.date}
                    </p>
                </td>
            </tr>

            <tr style="font-size: 18px;font-weight:'bold'">
                <td class="tableitem" style="width:80%">
                    <p class="itemtext">
                        Time: 
                    </p>
                </td>
                <td class="tableitem">
                    <p style="text-align:right;" class="itemtext">
                        ${data.time}
                    </p>
                </td>
            </tr>

        </table>

        </div>

        <div style="text-align:center;font-size:17px;border-top:1px dashed #000000;padding-top:7px;margin-top:7px;">
          <p class="legal ">Thanks for the visit,<br> Welcome back!</p>
        </div>

      </div>
</div>
</html>`;
};

exports.billHtmlGenerate = billHtmlGenerate;
