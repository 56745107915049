import React from "react";
import { Popover, Form } from "antd";
import { Icon as RIcon } from "react-icons-kit";
import { ic_done, ic_clear } from "react-icons-kit/md";

const EditForm = ({
  record,
  edit,
  isEditing,
  save,
  cancel,
  setVisibleEditPopover,
  visibleEditPopover
}) => {
  const editable = isEditing(record);
  const onMode = () => {
    return (
      <>
        <a onClick={() => save(record._id)} style={{ marginRight: 8 }}>
          <RIcon icon={ic_done} style={{ fontWeight: "bold" }} size={22} />
        </a>
        <Popover
          content={
            <span
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <a
                style={{ color: "#F44336" }}
                onClick={() => cancel(record.name)}
              >
                AVBRYT
              </a>
            </span>
          }
          title="Er du sikker?"
          trigger="click"
          visible={visibleEditPopover}
        >
          <a
            style={{ color: "#1890ff" }}
            onClick={() => setVisibleEditPopover(true)}
          >
            <RIcon
              icon={ic_clear}
              style={{ fontWeight: "bold", color: "red" }}
              size={22}
            />
          </a>
        </Popover>
      </>
    );
  };
  const offMode = () => {
    return (
      <a
        style={{ color: "#1890ff", fontSize: "20px", padding: "0 7px" }}
        onClick={() => edit(record.name, record)}
      >
        <i title="Update Category" className="fa fa-edit"></i>
      </a>
    );
  };
  return editable ? onMode() : offMode();
};

export default EditForm;
