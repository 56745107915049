const errorFunc = () => {
  return {
    stock: {
      isValidationFail: false,
      message: "Produkt is required"
    },
    quantity: {
      isValidationFail: false,
      message: "Antall is required"
    },
    qtyUnit: {
      isValidationFail: false,
      message: "Enhet is required"
    },
    stockproduct: {
      isValidationFail: false,
      message: "Ingredienser is required"
    }
  };
};
const validateFunc = ({ fields, errors }) => {
  const allErroes = [...errors];
  let isError = 0;
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].stock == "" || fields[i].stock === null) {
      allErroes[i]["stock"]["isValidationFail"] = true;
      isError++;
    } else {
      allErroes[i]["stock"]["isValidationFail"] = false;
    }
    if (fields[i].quantity == "" || fields[i].quantity === null) {
      allErroes[i]["quantity"]["isValidationFail"] = true;
      isError++;
    } else {
      allErroes[i]["quantity"]["isValidationFail"] = false;
    }
    if (fields[i].qtyUnit == "" || fields[i].qtyUnit === null) {
      allErroes[i]["qtyUnit"]["isValidationFail"] = true;
      isError++;
    } else {
      allErroes[i]["qtyUnit"]["isValidationFail"] = false;
    }
    if (fields[i].stockproduct == "" || fields[i].stockproduct === null) {
      allErroes[i]["stockproduct"]["isValidationFail"] = true;
      isError++;
    } else {
      allErroes[i]["stockproduct"]["isValidationFail"] = false;
    }
  }
  return {
    isError,
    allErroes
  };
};
export { errorFunc, validateFunc };
