import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UnpaidInvoices from "./UnpaidInvoice";
import { getUnpaidInvoices } from "./../../action/unpaidInvoices";

const UnpaidInvoice = (props) => {
  let [isLoaded, setIsLoaded] = useState(false);
  let [count, setCount] = useState(0);
  let [refresh, setRefresh] = useState(0);
  useEffect(() => {
    const getInvoicesUnpaid = async () => {
      await props.getUnpaidInvoices().then(() => {
        setIsLoaded(true);
        setCount(count + 1);
      });
    };
    getInvoicesUnpaid();
  }, [refresh]);

  const changeCount = () => {
    console.log("inside the change refresh--->", refresh);
    setRefresh(refresh + 1);
  };

  return (
    <UnpaidInvoices
      UnpaidInvoices={props.UnpaidInvoices}
      isLoaded={isLoaded}
      count={count}
      setCount={setCount}
      changeCount={changeCount}
    />
  );
};

const mapStateToProps = ({ UnpaidInvoices }) => {
  return { UnpaidInvoices };
};
export default connect(mapStateToProps, {
  getUnpaidInvoices
})(UnpaidInvoice);
