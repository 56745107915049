import { All_AVAILABLE_DRIVERS } from "../../action/types";
const initialState = {
    totalAvailable:null
};
//this is for online table booking
const allAvailableDriversReducer = (state = initialState, action) => {
    
  switch (action.type) {
    
    case All_AVAILABLE_DRIVERS:
        console.log("action inside reducer alka all driver--->",action)
      return {
        ...state,
        totalAvailable: action.payload.totalAvailable,
        
      };
    default:
      return state;
  }
};

export default allAvailableDriversReducer;