import React from 'react'
import Home from '../Home';
import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
const VendorOpprettKontoList = () => {
  return (
    <Home>
      <div className="report-container text-center"
        style={{ marginBottom: "0px" }}
      >
        <span className="item ">Opprett konto</span>
      </div>
      <Card>
        <Card.Body>
          <Row  justify="space-evenly" className="m-b-1">
            <Col span={6} className="text-left" style={{ marginTop: "15px", marginLeft: "40px" }}>
              <NavLink
                to="/VendorOpprettKontoPickup"
                type="primary"
                  className="btn-link-ok test3"
                style={{ width: "100px", padding: "15px", borderRadius:"5px" }}
              >
                <span>TAKE AWAY ORDERS</span>
              </NavLink>
              
                  
            </Col>
            <Col span={6} className="text-left" style={{ marginTop: "15px" }}>
              <NavLink
                to="/VendorOpprettKontoDriveOut"
                type="primary"
                  className="btn-link-ok test3"
                style={{ width: "100px", padding: "15px", borderRadius:"5px" }}
              >
                DRIVE OUT ORDERS
              </NavLink>
              
                  
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </Home>
  )
}

export default VendorOpprettKontoList
