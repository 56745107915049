import { GET_COUPAN, GET_COUPAN_INVOICES } from "../../action/types";

const initialState = {
  data: []
};

const coupanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPAN:
      console.log(action.data);
      return {
        ...state,
        data: action.data
      };
    case GET_COUPAN_INVOICES:
      return{
        ...state,
        data:action.data
      }  
    default:
      return state;
  }
};

export default coupanReducer;
