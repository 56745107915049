import axios from "axios";
import appConfig from "../helpers/appConfig";
import {
  PROFILE_DETAIL,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  PROFILE_SWITCH_BRANCH,
  Employee_Tips,
} from "./types";
import { getSwitchPermissions } from "./ansatteAction";
import { isServerLogout } from "../utils/isServerLogout";
export const getProfileDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/profile/details`
    );
    // console.log("-----PROFILE_DETAIL----------",res.data)
    dispatch(getSwitchPermissions(res.data))
    dispatch({
      type: PROFILE_DETAIL,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const switchBranch = (id) => async (dispatch) => {
  const {bid, eid} = localStorage;
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/profile/switch`,
      {...id, branchId:bid, employeeId:eid}
    );
    dispatch({
      type: PROFILE_SWITCH_BRANCH,
      payload: res.data,
    });
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "switchBranch" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const updateInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/profile/update`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateInfo" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const changePassword = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/profile/changePassord`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "changePassword" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getEmployeeTips = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/profile/empTip`,
      data
    );
    dispatch({
      type: Employee_Tips,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
