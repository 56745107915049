import React, { Component } from "react";
import Home from "../Home/Home";
import appConfig from "../../helpers/appConfig";
import moment from "moment";
import restreportsimg from "../../assets/restreports.png";
import pdfimg from "../../assets/Paid.png";
import unpaidimg from "../../assets/unpaid.png";
import {
  Row,
  Col,
  Button,
  DatePicker,
  PageHeader,
  Popover,
  notification,
  Select,
  Input
} from "antd";
const Option = Select.Option;
const { MonthPicker, WeekPicker } = DatePicker;

const hdr = localStorage.getItem("token");
export default class RestReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "Buttons",
      reportspdf: [],
      selectedDate: "",
      isDay: true,
      isWeak: false,
      isMonth: false,
      calender: [
        { key: "byDay", val: "By Day" },
        { key: "byWeek", val: "By Week" },
        { key: "byMonth", val: "By Month" }
      ],
      selectedDateType: "byDay",
      searchKey: "",
      searchDate: moment(new Date()),
      searchType: ""
    };
  }

  sendFileApis() {
    let date = this.state.searchDate;
    this.getRestReports(date);
  }
  getRestReports = (date) => {
    let { searchType, searchKey } = this.state;
    var branchId = localStorage.getItem("bid");
    // this.setState({
    //   selectedDate: moment(date).format("DD-MM-YYYY")
    // });
    let startDate;
    let endDate;
    switch (searchType) {
      case "byDay":
        startDate = moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        });
        endDate = moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        });
        break;
      case "byWeek":
        startDate = moment(date).startOf("week");
        endDate = moment(date).endOf("week");
        break;
      case "byMonth":
        startDate = moment(date).startOf("month");
        endDate = moment(date).endOf("month");
        break;
      default:
    }
    let data = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      searchKey: searchKey
    });
    this.getApiRestReport(data);
  };
  getApiRestReport(data) {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/restreportp`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ reportspdf: result.data });
      });
  }
  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  markaspaid = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/updaterestreportstatus/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          //console.log(result.data);
          this.openNotification("error", result.data);
        } else {
          this.openNotification("success", "Markert som betalt");
          let date = moment().date()._d;
          this.setState({
            searchType: "byDay",
            isDay: true,
            isWeak: false,
            isMonth: false
          });
          this.getRestReports(date);
        }
      });
  };
  downloadPdf = (path) => {
    var dtt = path.replace(/\//, "&").split("&")[1];
    var srr = dtt.split("/")[1];
    if (srr == "restReports") {
      path = dtt;
    } else {
      path = path;
    }
    console.log("PATH FINAL", path);
    // alert(path);
    var postData = {
      path: path
    };
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/rp/getpresignedurl`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          window.open(result, "_blank");
        }
      });
  };
  render() {
    const handleCalender = (e) => {
      let { isDay, isWeak, isMonth } = this.state;
      if (e === "byDay") {
        isDay = true;
        isWeak = false;
        isMonth = false;
      } else if (e === "byWeek") {
        isDay = false;
        isWeak = true;
        isMonth = false;
      } else if (e === "byMonth") {
        isDay = false;
        isWeak = false;
        isMonth = true;
      }
      this.setState({ selectedDateType: e, isDay, isWeak, isMonth });
    };
    const handleSearchInput = (e) => {
      this.setState({ searchKey: e.target.value });
    };
    const setDateFilter = (type, date) => {
      this.setState({ searchType: type, searchDate: date });
    };
    const showDateFilter = () => {
      const { isDay, isWeak, isMonth } = this.state;
      if (isDay) {
        return (
          <DatePicker
            onChange={(date, dateString) => {
              setDateFilter("byDay", date);
            }}
            format="YYYY-MM-DD"
            placeholder="Select day"
          />
        );
      } else if (isWeak) {
        return (
          <WeekPicker
            onChange={(date, dateString) => setDateFilter("byWeek", date)}
            placeholder="Select week"
          />
        );
      } else if (isMonth) {
        return (
          <MonthPicker
            onChange={(date, dateString) => setDateFilter("byMonth", date)}
            placeholder="Select month"
          />
        );
      }
    };
    const handleChangePage = async (page) => {
      await this.setState({
        currentPage: page,
        selectedDateType: "By Day",
        isDay: true,
        isWeak: false,
        isMonth: false,
        searchType: "byDay",
        searchKey: "",
        searchDate: moment(new Date())
      });
      await this.sendFileApis();
    };
    if (this.state.currentPage == "Buttons") {
      return (
        <Home isAdmin={true}>
          <div className="different-reports">
            <div className="different-reports-t">
              <div id="flex-stock">
                <button onClick={() => handleChangePage("betalt")}>
                  <img src={restreportsimg} style={{ height: 100 }} />
                  <h3 style={{ fontWeight: "bold" }} align="center">
                    BETALT
                  </h3>
                </button>
              </div>
              <div id="flex-stock">
                <button
                  className="box-t shadow black-bg"
                  onClick={() => handleChangePage("ubetalt")}
                >
                  <p className="text-items">UBETALT</p>
                </button>
              </div>
            </div>
          </div>
        </Home>
      );
    }
    if (this.state.currentPage == "betalt") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">BETALT [{this.state.selectedDate}]</span>
            <span>
              <Input
                placeholder="Search by Name"
                value={this.state.searchKey}
                onChange={(e) => handleSearchInput(e)}
              />
            </span>
            <span>
              <Select
                style={{ width: 120 }}
                onChange={(e) => handleCalender(e)}
                value={this.state.selectedDateType}
              >
                {this.state.calender.map((data) => (
                  <Option key={data.key} value={data.key}>
                    {data.val}
                  </Option>
                ))}
              </Select>
            </span>
            <span>{showDateFilter()}</span>
            <Button onClick={() => this.sendFileApis()}>
              <i className="fa fa-search" />
            </Button>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() => this.setState({ currentPage: "Buttons" })}
                  className="back-button-border"
                >
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          <Row>
            {this.state.reportspdf &&
              this.state.reportspdf.length > 0 &&
              this.state.reportspdf.map((rest) => {
                if (
                  rest.status == "PAID" &&
                  rest.fileName !== "ORC_SALES" &&
                  rest.showUser !== false
                ) {
                  return (
                    <Col
                      key={rest._id}
                      span={6}
                      style={{ textAlign: "center", height: "12rem" }}
                    >
                      <div>
                        <a
                          id={rest.pdf}
                          onClick={() => this.downloadPdf(rest.pdf)}
                        >
                          <img height={120} src={pdfimg}></img>
                          <p>{rest.fileName}</p>
                          <p>{moment(rest.createdAt).format("DD MMM, YYYY")}</p>
                        </a>
                      </div>
                    </Col>
                  );
                }
              })}
          </Row>
        </Home>
      );
    }
    if (this.state.currentPage == "ubetalt") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">UBETALT [{this.state.selectedDate}] </span>
            <span>
              <Input
                placeholder="Search by Name"
                value={this.state.searchKey}
                onChange={(e) => handleSearchInput(e)}
              />
            </span>
            <span>
              <Select
                style={{ width: 120 }}
                onChange={(e) => handleCalender(e)}
                value={this.state.selectedDateType}
              >
                {this.state.calender.map((data) => (
                  <Option key={data.key} value={data.key}>
                    {data.val}
                  </Option>
                ))}
              </Select>
            </span>
            <span>{showDateFilter()}</span>
            <Button onClick={() => this.sendFileApis()}>
              <i className="fa fa-search" />
            </Button>
            <span className="item">
              <span id="less-visible">
                <div
                  onClick={() => this.setState({ currentPage: "Buttons" })}
                  className="back-button-border"
                >
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          <Row>
            {this.state.reportspdf &&
              this.state.reportspdf.length > 0 &&
              this.state.reportspdf.map((rest) => {
                if (rest.status !== "PAID")
                  return (
                    <Col
                      key={rest._id}
                      span={6}
                      style={{ marginBottom: 20, textAlign: "center" }}
                    >
                      <div>
                        <a
                          id={rest.pdf}
                          download
                          href={
                            appConfig.endpoint +
                            "/" +
                            // "/uploads/restReports/" +
                            rest.pdf
                          }
                          target="_blank"
                        >
                          <img height={120} src={unpaidimg}></img>
                          <p>{rest.fileName}</p>
                          <p>{moment(rest.createdAt).format("DD MMM, YYYY")}</p>
                        </a>
                      </div>
                      <Popover
                        placement="top"
                        title="MERKE SOM BETALT"
                        content={
                          <Button onClick={() => this.markaspaid(rest._id)}>
                            MERKE
                          </Button>
                        }
                        trigger="click"
                      >
                        <Button shape="round">MERKE SOM BETALT</Button>
                      </Popover>
                    </Col>
                  );
              })}
          </Row>
        </Home>
      );
    }
  }
}
