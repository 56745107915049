import React, { Component } from "react";
import moment from "moment";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: "&nbsp;"
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(
      () => this.setState({ currentTime: moment().format("h:mm:ss a") }),
      1000
    );
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <span className="item-header-flex avatar-general">
        {this.state.currentTime}
      </span>
    );
  }
}

export default Clock;
