import openSocket from "socket.io-client";
import endpoint from "./endpoint";
import notificationFile from "../assets/plucky.mp3";
import { socket } from "../utils/socketNew";
async function subscribeToOrder(cb) {

  socket.on("orderReceive", async (orders) => {
    var oror = localStorage.getItem("currentOrders");
    var bid = localStorage.getItem("bid");
    if (orders && bid) {
      if (bid.toString() === orders.branchId.toString()) {
        localStorage.setItem("onlineOrderCount",orders.count)
        await localStorage.setItem("currentOrders", orders.count);
        cb(null, orders);
        if (Number(oror) !== Number(orders.count)) {
          playSound();
        }
        await localStorage.setItem("currentOrders", orders.count);
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}
async function subscribeToTreeDriveOrder(cb) {

  socket.on("orderReceiveTreeDrive", async (orders) => {
    console.log("inside the socket orderReceiveTreeDrive subscirbe--->",orders)
    var oror = localStorage.getItem("currentOrdersTreeDrive");
    var bid = localStorage.getItem("bid");
    if (orders && bid) {
      if (bid.toString() === orders.branchId.toString()) {
        localStorage.setItem("treeDriveOrderCount",orders.count)
        await localStorage.setItem("currentOrdersTreeDrive", orders.count);
        cb(null, orders);
        if (Number(oror) !== Number(orders.count)) {
          playSound();
        }
        await localStorage.setItem("currentOrdersTreeDrive", orders.count);
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}
async function subscribeToVendorOrder(cb) {

  socket.on("orderReceiveVendor", async (orders) => {
    console.log("inside the socket orderReceiveVendorsubscirbe--->",orders)
    var oror = localStorage.getItem("currentOrdersVendor");
    var bid = localStorage.getItem("bid");
    if (orders && bid) {
      if (bid.toString() === orders.branchId.toString()) {
        localStorage.setItem("vendorOrderCount",orders.count)
        await localStorage.setItem("currentOrdersVendor", orders.count);
        cb(null, orders);
        if (Number(oror) !== Number(orders.count)) {
          playSound();
        }
        await localStorage.setItem("currentOrdersVendor", orders.count);
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}
async function subscribeToTableOrder(cb) {

  socket.on("invoiceReceive", async (orders) => {
    var oror = localStorage.getItem("ontableOrders");
    var bid = localStorage.getItem("bid");
    if (orders && bid) {
      if (bid.toString() === orders.branchId.toString()) {
        localStorage.setItem("onlineTableCount",orders.count)
        cb(null, orders);
        if (Number(oror) !== Number(orders.count)) {
          playSound();
        }
         localStorage.setItem("ontableOrders", orders.count);
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}

async function subscribeToQrPermission(cb) {

  socket.on("isQrcode", async (orders) => {
    var oror = localStorage.getItem("isQrAllow");
    var bid = localStorage.getItem("bid");
    if (orders && bid) {
      if (bid.toString() === orders.branchId.toString()) {
        cb(null, orders);

        await localStorage.setItem("isQrAllow", orders.isQrAllow);
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}

async function subscribeToResreveTableCount(cb) {

  socket.on("reserveTable", async (socketData) => {

    var bid = localStorage.getItem("bid");
    if (socketData && bid) {
      if (bid.toString() == socketData.branchId.toString()) {
        localStorage.setItem("reserveTableCount",socketData.count)
        cb(null, socketData.count);
         playSound();
      }
    }

    // console.log("ORDER IN SOCKET", orders);
    //  if we can just can read order that we are getting
  });
}

function playSound() {
  const audio = new Audio(notificationFile);
  audio.play();
}

export { subscribeToOrder,subscribeToTreeDriveOrder, subscribeToTableOrder, subscribeToQrPermission ,playSound,subscribeToResreveTableCount,subscribeToVendorOrder};
