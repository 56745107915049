import React, { useCallback, useState, useEffect } from "react";
import { Table, Select, Switch, Input } from "antd";
import appConfig from "../../helpers/appConfig";
import EditForm from "./EditForm";
import IconUpdate from "./IconUpdate";
import DeleteCategory from "./DeleteCategory";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { sendNotification } from "../../helpers/commFuc";
const Option = Select.Option;

const CategoryList = ({
  categories,
  isLoaded,
  showWeb,
  isShowWebValues,
  setIsShowWebValues,
  setIsShowExtra,
  isShowExtra,
  showExtra,
  superCategory,
  updateCategory,
  getCategory,
  getIcons,
  icons,
  updateIcon,
  redirectToProduct,
  deleteCategory
}) => {
  let [currentPage, setCurrentPage] = useState(1);
  let [editingKey, setEditingKey] = useState("");
  let [visibleEditPopover, setVisibleEditPopover] = useState();
  let [tagValues, setTagValues] = useState([]);
  let [taxType, setTaxType] = useState([]);
  let [catName, setCatName] = useState("");
  let [superCat, setsuperCat] = useState("");
  let [imageModel, setImageModel] = useState(false);
  let [showToggle, setShowToggle] = useState(isShowWebValues);
  let [taxTypeCatArr, setTaxTypeCatArr] = useState([]);
  let [selectedTaxEdit, setSelectedTaxEdit] = useState([]);
  const taxes = JSON.parse(localStorage.getItem("taxes"));

  const getTaxesName = (taxId) => {
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId && taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          return tax.taxName;
        }
      });
    }
    return isTax.length > 0 ? isTax.toString().replace(/(^,)|(,$)/g, "") : "-";
  };

  const viewTaxesName = (taxId) => {
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId && taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          if(tax.taxName=="PLU1") return "Tax25" 
          if(tax.taxName=="PLU2") return "Tax15" 
          return tax.taxName

        }
      });
    }
    return isTax.length > 0 ? isTax.toString().replace(/(^,)|(,$)/g, "") : "-";
  };

  const onChangeIsWeb = (e, record, index) => {
    isShowWebValues[record._id] = e;
    showWeb({ isCheck: e, id: record._id });
    setIsShowWebValues(isShowWebValues);
  };

  const onChangeIsExtra = (e, record, index) => {
    isShowExtra[record._id] = e;
    showExtra({ isCheck: e, id: record._id });
    setIsShowExtra(isShowExtra);
  };
  const edit = (key, record) => {
    let taxType = [];
    let sCatName = (record.superCategory && record.superCategory.name) || "";

    if (sCatName) {
      const cat = superCategory.find((ele) => sCatName == ele.name);

      const catId = cat._id;
      let taxTypeCat = superCategory.filter((item) => {
        return item.name == sCatName;
      });
    
      setTaxTypeCatArr(taxTypeCat[0].taxType);
      const tTax = cat && cat.taxType ? cat.taxType : [];
      let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
      const taxes = JSON.parse(localStorage.getItem("taxes"));
      if (taxId.length > 0) {
        taxType = taxes.map((tax) => {
          if (taxId.includes(tax._id)) {
            return { key: tax._id, val: tax.taxName };
          }
        });
      }

      let catType = [];

      catType = taxes.map((tax) => {
        return tax.taxName;
      });

      const taxx = getTaxesName(record.catTaxes);

      let taxess = taxx.split(",");
      taxess = taxess.filter((tax) => {
        return tax != "-";
      });

      setVisibleEditPopover(false);
      setEditingKey(key);
      setTagValues(taxess);
      setTaxType(taxType);
      setCatName(record.name);
      setsuperCat(catId);
    }
  };
  const isEditing = (record) => {
    return record.name === editingKey;
  };
  const save = (key) => {
    if (validateForm()) {
      setEditingKey("");
      const taxes = JSON.parse(localStorage.getItem("taxes"));

      // let taxArrayId = [];
      // for (let i = 0; i < tagValues.length; i++) {
      //   for (let j = 0; j < taxes.length; j++) {
      //     if (taxes[j].taxName == tagValues[i]) {
      //       taxArrayId.push(taxes[j]._id);
      //     }
      //   }
      // }

      let taxArrayId = [];

      
      if (taxTypeCatArr.length && tagValues.length) {
        taxTypeCatArr.forEach((tax) => {
          tagValues.forEach((tagValue) => {
           
            if (tagValue == tax.taxName) {
              taxArrayId.push(tax.tax);
              // return tax.tax;
            }
          });
        });
       
        const data = {
          superCat,
          name: catName,
          taxes: taxArrayId,
          key,
        };
        
        updateCategory(data).then(() => {
          getCategory();
        });
      }
    }
  };

  const validateForm = () => {
    if (!catName.trim() || !superCat || !tagValues.length) {
      sendNotification("error", "All fields are required!");
      return false;
    }
    return true;
  };
  const cancel = () => {
    setEditingKey("");
    setImageModel(false);
  };

  const supercatEdithandleChange = async (value) => {
    const cat = await superCategory.find((ele) => value == ele._id);
    const tTax = cat && cat.taxType ? cat.taxType : [];
    let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
    const taxes = JSON.parse(localStorage.getItem("taxes"));
    let isTax = [];
    if (taxId.length > 0) {
      isTax = taxes.map((tax) => {
        if (taxId.includes(tax._id)) {
          return { key: tax._id, val: tax.taxName };
        }
      });
    }
    setTaxTypeCatArr(tTax);
    setTagValues(tagValues);
    setTaxType(isTax);
    setsuperCat(value);
  };
  const Columns = [
    {
      title: "#",
      width: "5%",
      editable: false,
      key: 1,
      render: (text, record, index) => {
        return index + (currentPage - 1) * 10 + 1;
      }
    },
    {
      title: "Produkt Navn",
      dataIndex: "name",
      width: "18%",
      key: 2,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input value={catName} onChange={(e) => setCatName(e.target.value)} />
        ) : (
          <a onClick={() => redirectToProduct(record)}>{text}</a>
        );
      }
    },
    {
      title: "Gruppert Kode",
      width: "13%",
      dataIndex: "superCategory",
      key: 3,
      render: (text, record) => {
        return editingKey === record.name ? (
          <Select
            style={{ width: "100%" }}
            placeholder="Choose Type"
            value={superCat}
            onChange={(e) => supercatEdithandleChange(e)}
            required={true}
          >
            {superCategory &&
              superCategory.map((data, index) => {
                return (
                  <Option key={data._id} value={data._id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        ) : (
          <>
            <span>
              {record.superCategory && record.superCategory.name
                ? record.superCategory.name
                : ""}
            </span>
          </>
        );
      }
    },
    {
      title: "Mva",
      width: "12%",
      key: 4,
      render: (text, record) => {
        return editingKey === record.name ? (
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Choose Type"
            value={tagValues}
            onChange={(e) => {
              setTagValues(e);
              setSelectedTaxEdit(e);
              
            }}
            required={true}
          >
            {/* {taxes.map((itm) => {
              console.log("taxes itm----->",record.superCategory.taxType)
              return (
                <Option value={itm.taxName} key={itm.taxName}>
                  {itm.taxName}
                </Option>
              );
            })} */}
            {/* {superCategory.taxType.map((itm) => {
              console.log("taxes itm----->", itm);
              return (
                <Option value={itm.taxName} key={itm.taxName}>
                  {itm.taxName}
                </Option>
              );
            })} */}
            {taxTypeCatArr.length &&
              taxTypeCatArr.map((itm) => {
                console.log("taxes itm----->", itm);
                return (
                  <Option value={itm.taxName} key={itm.taxName}>
                    {(itm.taxName=="PLU1"?"TAX25":itm.taxName=="PLU2"?"TAX15":itm.taxName)}
                  </Option>
                );
              })}
          </Select>
        ) : (
          <>{viewTaxesName(record.catTaxes)}</>
          
        );
      }
    },
    {
      title: "Bilde",
      dataIndex: "image",
      width: "13%",
      key: 5,
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center"
            }}
          >
            <img
              src={`${appConfig.s3url}/${text}`}
              style={{ maxWidth: 50, maxHeight: 50 }}
            />
          </div>
        );
      }
    },
    {
      title: "Vis på nettet",
      dataIndex: "isWeb",
      width: "12%",
      key: 6,
      render: (text, record, index) => {
        return (
          <BootstrapSwitchButton
            size="xs"
            style="btn-ab"
            onstyle="btn-ab"
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={isShowWebValues[record._id]}
            onChange={(e) => onChangeIsWeb(e, record, index)}
          />
        );
      }
    },
    {
      title: "Vis som ekstra",
      dataIndex: "isShowAsExtra",
      width: "12%",
      key: 7,
      render: (text, record, index) => {
        return (
          <div>
            <BootstrapSwitchButton
              style="btn-ab"
              onstyle="btn-ab"
              size="xs"
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(e) => onChangeIsExtra(e, record, index)}
              checked={isShowExtra[record._id]}
            />
          </div>
        );
      }
    },
    {
      title: "Handling",
      dataIndex: "operation",
      width: "15%",
      key: 8,
      render: (text, record) => {
        return (
          <>
            <EditForm
              record={record}
              edit={edit}
              isEditing={isEditing}
              save={save}
              cancel={cancel}
              visibleEditPopover={visibleEditPopover}
              setVisibleEditPopover={setVisibleEditPopover}
            />
            <IconUpdate
              record={record}
              getIcons={getIcons}
              icons={icons}
              updateIcon={updateIcon}
              getCategory={getCategory}
            />
            <DeleteCategory
              record={record}
              deleteCategory={deleteCategory}
              getCategory={getCategory}
            />
          </>
        );
      }
    }
  ];
  const categoryColumns = Columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === "price" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  return (
    <div className="categoryListTable">
      <Table
        rowKey={(record) => record._id}
        loading={isLoaded}
        onChange={(pagination) => setCurrentPage(pagination.current)}
        bordered
        dataSource={categories}
        columns={categoryColumns}
        rowClassName="editable-row"
        className="tableClass"
        scroll={{
          y: 500,
        }}
      />
    </div>
  );
};
export default CategoryList;
