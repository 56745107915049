import React, { Fragment, useEffect } from "react";
import { Button, Col, Row, notification, Card, Badge } from "antd";
import moment from "moment";
import Countdown from "react-countdown";

const OnlineOrder = (props) => {
  const Completionist = () => (
    <span style={{ color: "red", fontWeight: "bold" }}>
      Ordre Tid Fullført!
    </span>
  );

  const renderer = ({ hours, minutes, seconds, completed }) => {
    console.log("render again", "online table");
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <Fragment>
      {props.pendingOnlineOrders.map((order, index) => {
        console.log("online order", order);
        var cntr = 0;
        order.items.length > 0 &&
          order.items.forEach((it, ind) => {
            if (it.sCatType == props.refer && it.status !== "PREPARED") {
              cntr += 1;
            }
          });

        // console.log("cntr after", cntr);
        if (Number(cntr) > 0) {
          return (
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.showOnlineOrder(order);
              }}
              span={7}
            >
              <Card
                title={order.orderStatus}
                // extra={moment(order.updatedAt)
                //   .add(order.deliveryTime)
                //   .format("hh:mm A")}
                bordered={false}
              >
                <b>OTP</b> - {order.otp}
                <br />
                <b>Order Type</b> - {order.orderType}
                <br />
                <b>Order Time</b> -{" "}
                <Countdown
                  style={{ color: "red" }}
                  date={moment(order.updatedAt).format("DD, MMM, YY") + Number(order.deliveryTime)}
                  // date={moment(order.updatedAt) + Number(order.deliveryTime)}
                ></Countdown>
              </Card>
            </Col>
          );
        }
      })}
    </Fragment>
  );
};
export default OnlineOrder;
