import React, { Component } from "react";
import Home from "../Home/Home";
import "./stockChange.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  Card,
  label
} from "antd";
import appConfig from "../../helpers/appConfig";
import productsUnits from "../../helpers/productUnits";

const FormItem = Form.Item;
const Option = Select.Option;

class stockChangeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      categories: [],
      products: [],
      quantity: "",
      stockType: "",
      productUnit: "",
      allUnits: [],
      units: [],
      subCatValue: "",
      unitValue: "",
      quantity: ""
    };
  }

  handleStockChange = (value) => {
    this.setState({ stockType: value });
  };

  handleCatChange = (id) => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stock/product/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            let products = result.data.map((product) => {
              return { name: product.name, id: product._id };
            });
            let allUnits = result.data.map((product) => {
              const pUnit = productsUnits[product.base_unit]
                ? productsUnits[product.base_unit]
                : productsUnits["all"];
              return {
                id: product._id,
                unit: pUnit
              };
            });
            this.setState({
              products,
              allUnits,
              subCatValue: "",
              unitValue: "",
              quantity: ""
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleProdChange = async (id, name) => {
    let unitP = this.state.allUnits.find((e) => {
      return e.id == id;
    });
    this.setState({
      productId: id,
      units: unitP.unit,
      subCatValue: name,
      unitValue: "",
      quantity: ""
    });
  };

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
  };

  handleEmployeeChange = (id) => {
    this.setState({ employeeId: id });
  };
  handleProductChange = (id, value) => {
    this.setState({ productUnit: id, unitValue: value, quantity: "" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { productId, quantity, stockType, productUnit } = this.state;
    let data = {
      quantity,
      type: stockType,
      productUnit
    };
    if (stockType === "out") {
      data = {
        ...data,
        id: this.state.employeeId
      };
    }
    data = JSON.stringify(data);
    let hdr = localStorage.token;
    console.log(data);
    fetch(`${appConfig.endpoint}/stockProduct/${productId}`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.message);
          } else {
            this.openNotification("success", result.message);
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  componentDidMount = () => {
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/reducePageDetails`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            let categories = result.data.categories.map((category) => {
              return { name: category.name, id: category._id };
            });
            let employees = result.data.employee.map((employee) => {
              return {
                name: `${employee.firstName} ${employee.lastName}`,
                id: employee.id
              };
            });
            this.setState({ categories, employees });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  render() {
    const { categories, products, employees, stockType } = this.state;
    return (
      <Home isAdmin={true}>
        <div className="report-container">
          <span className="item">Lager</span>
          <span className="item">
            <span id="less-visible">HJEM / </span>
            Lager Inn/ut
          </span>
          <span className="item">
            <span id="less-visible">
              <div
                onClick={() => this.navigate("/stock")}
                className="back-button-border"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                <span>Back</span>
              </div>
            </span>
          </span>
        </div>
        <Row type="flex" justify="center">
          <Card bordered={false} style={{ width: "100%" }}>
            <Col span={24}>
              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Row>
                  <Col span={8}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Lager Inn / Lager ut
                      </label>
                      <Select
                        required
                        showSearch
                        onChange={this.handleStockChange}
                        placeholder="Lager Inn / Lager ut"
                        optionFilterProp="children"
                      >
                        <Option value="in">Lager Inn</Option>
                        <Option value="out">Lager ut</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg kategori
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg kategori"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categories.map((category) => {
                          return (
                            <Option
                              key={category.id}
                              value={category.name}
                              onClick={() => this.handleCatChange(category.id)}
                            >
                              {category.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg Produkt
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg Produkt"
                        optionFilterProp="children"
                        value={this.state.subCatValue}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {products.map((product) => {
                          return (
                            <Option
                              key={product.id}
                              value={product.name}
                              onClick={() =>
                                this.handleProdChange(product.id, product.name)
                              }
                            >
                              {product.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg Produktenhet
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg Produktenhet"
                        optionFilterProp="children"
                        value={this.state.unitValue}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.units.map((product) => {
                          return (
                            <Option
                              key={product.key}
                              value={product.value}
                              onClick={() =>
                                this.handleProductChange(
                                  product.key,
                                  product.value
                                )
                              }
                            >
                              {product.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Antall
                      </label>
                      <Input
                        type="Number"
                        min="1"
                        placeholder="Antall"
                        value={this.state.quantity}
                        onChange={(e) => this.handleQuantityChange(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "10px" }}>
                    {stockType === "out" ? (
                      <FormItem label="Assign Stock To:">
                        <Select
                          required
                          showSearch
                          placeholder="Opprett Ansatte"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {employees.map((employee) => {
                            return (
                              <Option
                                key={employee.id}
                                value={employee.name}
                                onClick={() =>
                                  this.handleEmployeeChange(employee.id)
                                }
                              >
                                {employee.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem>
                      <Button type="primary" htmlType="submit">
                        Send
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card>
        </Row>
      </Home>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const changeStock = connect(mapStateToProps)(stockChangeComponent);
export default changeStock;
