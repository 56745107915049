import React, { useEffect, useState } from "react";
import {
  getDailyReport,
  getDailyReportPrint,
  getDailyXReport
} from "./../../../action/ReportAction";
import { connect } from "react-redux";
import {  DatePicker } from "antd";
import List from "./List";
import ViewReport from "./ViewReport";
import { setDefaultDates, setMonthDates } from "../../../helpers/commonDates";
import moment from "moment";
import Home from "./../../Home";
import { Card, Row, Col, Button } from "react-bootstrap";
const PosReport = (props) => {
  const { MonthPicker, WeekPicker } = DatePicker;
  let [page, setPage] = useState("list");
  let [date, setDate] = useState(moment().date()._d);
  let [isLoaded, setIsLoaded] = useState(false);
  const [month, setmonth] = useState()
  useEffect(() => {
    const time = moment(date).format("HH");
    
    async function fetchData(tSDay, tEDay) {
      const data = {
        startDate: tSDay,
        endDate: tEDay
      };
      setIsLoaded(true);
      await props.getDailyXReport(data).then(() => setIsLoaded(false));
    }
   
    if(month){
      const { tSDay, tEDay } = setMonthDates(date);
      fetchData(tSDay, tEDay);
      setmonth(false)

    }else{
      const { tSDay, tEDay } = setDefaultDates(date);
      fetchData(tSDay, tEDay);

    }
   
   
  }, [date]);
  const handleDateChange = (date) => {
    setDate(date);
  };
  const printReportHandler = async () => {
    const { tSDay, tEDay } = await setDefaultDates(date);
    const data = {
      startDate: tSDay,
      endDate: tEDay,
      title: "POS-REPORTS",
      type: "P"
    };
    setIsLoaded(true);
    await props.getDailyReportPrint(data).then(() => setIsLoaded(false));
  };
  const goBackMainPage = () => {
    props.history.push("/arkiv");
  };
  const renderPage = () => {
    if (page === "list") {
      return (
        <Home>
          <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Arkiv:</span>
              POS Rapport
            </span>

            <span id="less-visible">
              <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
          <Card>
            <Card.Body>
              <Row className="filter m-b-1">
                <MonthPicker   onChange={(date, dateString) =>
            {
                   console.log("date is",dateString);
                  // this.loadReports(date, "month")
                  setmonth(true);
                  handleDateChange(date,"month");
            }
                }
                format="YYYY-MM-DD"
                placeholder="Select Month"></MonthPicker>
                <span/>
                <Col span={4} className="">
                  <DatePicker
                    onChange={(date, dateString) => {
                      handleDateChange(date);
                    }}
                    format="YYYY-MM-DD"
                    placeholder="Select day"
                  />
                </Col>
                <Col span={4} className="" align="right">
                  <Button
                    className="ant-btn btn-ab"
                    style={{ background: "#dcbb68" }}
                    onClick={() => printReportHandler()}
                  >
                    <i
                      className="fa fa-print"
                      style={{ fontSize: 20, marginTop: 1 }}
                      aria-hidden="true"
                    />
                  </Button>
                </Col>
              </Row>
              <List
                setPage={setPage}
                isLoaded={isLoaded}
                dailyXReport={props.dailyXReport}
              />
            </Card.Body>
          </Card>
        </Home>
      );
    } else if (page === "view") {
      return <ViewReport setPage={setPage} />;
    }
  };

  return <>{renderPage()}</>;
};
const mapStateToProps = ({ dailyXReport }) => {
  return {
    dailyXReport
  };
};
export default connect(mapStateToProps, {
  getDailyReport,
  getDailyReportPrint,
  getDailyXReport
})(PosReport);
