import { GET_X_REPORT } from "../../action/types";
const initialState = {
  data: {
    branch: {},
    dcount: 0,
    reportData: [],
    tatalOrdersPrice: 0,
    totalFlatDiscount: 0,
    allTax: [],
    totalTax: [],
    dOT: 0
  }
};

const dailyXReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_X_REPORT:
      return {
        ...state,
        data: {
          branch: action.payload.data.branch ? action.payload.data.branch : {},
          dcount: action.payload.data.dcount ? action.payload.data.dcount : 0,
          reportData: action.payload.data.reportData
            ? action.payload.data.reportData
            : [],
          tatalOrdersPrice: action.payload.data.tatalOrdersPrice
            ? action.payload.data.tatalOrdersPrice
            : 0,
          totalFlatDiscount: action.payload.data.totalFlatDiscount
            ? action.payload.data.totalFlatDiscount
            : 0,
          allTax: action.payload.data.allTax ? action.payload.data.allTax : [],
          totalTax: action.payload.data.totalTax
            ? action.payload.data.totalTax
            : [],
            dOT: action.payload.data.dOT
        }
      };
    default:
      return state;
  }
};

export default dailyXReportsReducer;
