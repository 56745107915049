import React, { Component } from "react";
import { Card } from "antd";

class RightMenuAdmin extends Component {
  render() {
    return (
      <div id="right-menu-main-wrapper">
        <Card className="card-style">
          <div>
            <div className="admin-setting-buttons">
              {/* <Button className="admin-circle1 shadow" shape="circle">
                <div className="setting-text-admin">
                  <RIcon size={32} icon={ic_settings} />
                  <p style={{ marginTop: '-3px' }}>Settings</p>
                </div>
              </Button> */}
            </div>
            <div className="admin-option-setting">
              <button
                onClick={() => this.props.handleClick(`/taccountant`)}
                className={
                  Number(localStorage.getItem("taccountant")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                {/* <p className="admin-options-text">T Accountant</p> */}
                <p className="admin-options-text">Arkiv</p>
              </button>
              <button
                onClick={() => this.props.handleClick(`/menu`)}//Manage
                className={
                  Number(localStorage.getItem("managecat")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Meny</p>
              </button>
              <button
                onClick={() => this.props.handleClick(`/crm`)}
                className={
                  Number(localStorage.getItem("crm")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Kunder</p>
              </button>
              <button
                onClick={() => this.props.handleClick(`/hrm`)}
                className={
                  Number(localStorage.getItem("hrm")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Ansatte</p>
              </button>
              <button
                onClick={() => this.props.handleClick(`/stock`)}
                className={
                  Number(localStorage.getItem("stock")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Lager</p>
              </button>
              <button
                onClick={() => this.props.handleClick(`/unpaid`)}
                className={
                  Number(localStorage.getItem("invoice")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Faktura</p>
              </button>

              {/*  New permissions added Kitchen and bar */}

              <button
                onClick={() => this.props.handleClick(`/kitchen`)}
                className={
                  Number(localStorage.getItem("kitchen")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Kjøkken</p>
              </button>

              <button
                onClick={() => this.props.handleClick(`/bar`)}
                className={
                  Number(localStorage.getItem("bar")) === 1
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">Bar</p>
              </button>

              <button
                onClick={() => this.props.handleClick(`/tdDiscount`)}
                className={
                  Number(localStorage.getItem("tdDiscount")) === "true"
                    ? "admin-option-items shadow"
                    : "admin-option-items shadow hide"
                }
              >
                <p className="admin-options-text">TD Discount</p>
              </button>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default RightMenuAdmin;
