import React ,{useState , useEffect} from 'react'
import Home from '../Home'
import appConfig from "../../helpers/appConfig";
// import "./VendorOrder.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
import {
  Row,
  Col,
  Input,
  Table,
  Card,
  Avatar,
  Select,
  Space,
  Descriptions,
  Menu,
  Dropdown,
  Button,
  Icon,
  Modal,
  notification,
} from "antd";
import history from "../../history";
import offerIcon from "../../assets/offer-icon.png";
import { GETAllAPI , POSTAPI } from '../Vendor/AllApi';
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";


const RetailerOpprettKontoDriveout = () => {

    const [productData, setProductData] = useState([]);
    const [filterProduct, setFilterProduct] = useState([]);
    const [categoryData, setCategoryData] = useState(true);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [searchData, setSearchData] = useState();
    const [cartData, setCartData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [totalOfProducts, setTotalOfProducts] = useState(0);
    const [unitType, setUnitType] = useState("");
    const [availQuantity, setAvailQuantity] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(16);
    const [totalTax, setTotalTax] = useState();
    const [mvaArray, setMvaArray] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [delProduct, setDelProduct] = useState("");
    const [cashConfirmModal, setCashConfirmModal] = useState(false);
    const [cashPay, setCashPay] = useState(0);
    const [driveOutCharges ,setDriveOutCharges] = useState("")
  
    const date = new Date().toISOString();
    const bid = localStorage.getItem("bid");
    const onSearch = (value) => console.log(value);
    const { Option } = Select;
    const { Search } = Input;
    const { Meta } = Card;
    // const vendorId = localStorage.getItem("vendorId");
    // const vendorId = props.location.state.vid;
    // const name = props.location.state.name;
    // const logo = props.location.state.logo;
    let cart = localStorage.getItem("cartData");
  
    
  
    const PlaceOrder = () => {
      setOrderPlaced(false);
  
      // if (cartData.length) {
      //   history.push({
      //     pathname: "/checkout",
      //     state: {
      //       cartData: cartData,
      //       total: totalOfProducts,
      //       // discount:discount,
      //       // discountPrice:discountPrice,
      //       mvaArray: mvaArray,
      //       //name: name,
      //       totalTax:totalTax
      //     },
      //   });
      // } else {
      //   notification.open({
      //     message: "Please add items in cart",
      //     icon: <Icon type="check-circle" style={{ color: "green" }} />,
      //     style: { top: "-50px" },
      //     duration: 2,
      //   });
      // }
    };
  
    useEffect(() => {
      // console.log("--cartdata-- change", cartData);
      productTotal();
      caltotalTax();
      mvaPercentage();
    }, [cartData, totalOfProducts, discountPrice]);
  
    let productTotal = () => {
      let total = cartData.reduce(
        (oldValue, newTotal) =>
          newTotal.prodHasOffer
            ? (oldValue +=
                newTotal.quantity * newTotal.offerPrice * newTotal.availQuantity)
            : (oldValue +=
                newTotal.quantity * newTotal.price * newTotal.availQuantity),
        0
      );
      setTotalOfProducts(total);
    };
  
    const caltotalTax = () => {
      let totalMva = 0;
      //console.log("tax calculation running")
      cartData.map((e) => {
        let prdPrice;
        if (e.prodHasOffer) {
          prdPrice = e.quantity * e.offerPrice;
        } else {
          prdPrice = e.quantity * e.price;
        }
  
        totalMva += prdPrice - (100 * prdPrice) / (100 + e.prodTax);
        // console.log("mva of one product====", mvaOfProduct)
      });
      setTotalTax(totalMva);
    };
  
    // --------------------------calculate tax % ---------------------------------- //
    const mvaPercentage = () => {
      let taxArr = [];
      let mvaTotal = 0;
      cartData.map((e) => {
        let prdPrice;
        if (e.prodHasOffer) {
          prdPrice = e.quantity * e.offerPrice;
        } else {
          prdPrice = e.quantity * e.price;
        }
  
        let prdMva = prdPrice - (100 * prdPrice) / (100 + e.prodTax);
        mvaTotal += prdMva;
        let taxArrObj = {
          tax: e.prodTax,
          taxTotal: prdMva,
        };
        let taxFound = false;
        if (taxArr.length) {
          taxArr.map((taxObj) => {
            if (taxObj.tax == taxArrObj.tax) {
              taxObj.taxTotal = taxObj.taxTotal + taxArrObj.taxTotal;
              taxFound = true;
            }
          });
          if (!taxFound) {
            taxArr.push(taxArrObj);
          }
        } else {
          taxArr.push(taxArrObj);
        }
      });
      setMvaArray(taxArr);
      //console.log("mva tax array", taxArr)
    };
  
    //--------------------------- print button functionality----------------------------//
    const printbill = () => {
      window.print();
    };
  
    //-------------------------set cartData in local storage----------------------------//
    useEffect(() => {
      if (cartData.length) {
        //localStorage.setItem("cartData", JSON.stringify(cartData));
      }
    }, [cartData]);
  
    if (!cartData.length && cart.length) {
      setCartData(JSON.parse(cart));
    }
  
    //--------------------------- VENDOR PRODUCTS--------------------------------------//
    useEffect(() => {
      let Data = {
        branchId: bid,
        type: "retailer",
      };
      let getCategories = async () => {
        let resData = await POSTAPI(
          `${appConfig.endpoint}/api/v1/vendor/vendorProducts`,
          Data
        );
  
        if (resData.status == 200) {
          setResponseData(resData.data);
          setFilterProduct(resData.data); 
          setCategoryData(true);
          setDriveOutCharges(resData.driveoutCharge)
        //   console.log("res data ",resData.data)
        }
      };
      getCategories();
    }, []);
  
    let backBtn = () => {
      setCategoryData(true);
    };
  
    //===========================display products=================================//
  
    const displayProducts = (id) => {
      // filter particular item
      const filterCategory = responseData.filter((item) => {
        return item._id == id;
      });
      //console.log("filterCategory", filterCategory)
  
      let catProducts = [];
      if (filterCategory.length) {
        catProducts = filterCategory[0].products;
      }
      setProductData(catProducts);
      setCategoryData(false);
    };
  
    //---------------------------   clear all Cart Data  -------------------------------//
    const clearCartPro = () => {
      localStorage.setItem("cartData", []);
      setCartData([]);
    };
  
    //------------------------------ discount added ------------------------------------//
    let discount = 0;
    if (totalOfProducts > 20) {
      if (totalOfProducts >= 20 && totalOfProducts < 50) {
        discount = 5;
        //console.log("discount is =======",10)
      } else if (totalOfProducts >= 50 && totalOfProducts < 100) {
        discount = 10;
        //console.log("discount is =======",20)
      } else if (totalOfProducts >= 100) {
        discount = 20;
        //console.log("discount is =======",30)
      } else {
        discount = 0;
      }
    }
    let discountPrice = (totalOfProducts * discount) / 100;
  
    // console.log("DISCOUNTTTT ============", discount ,discountPrice, totalOfProducts)
  
    // dropdown action
    const handleQuantity = (event, product) => {
      let availQty = event.target.value;
  
      //--------------------------   update cartData  ------------------------------//
      let updatedQty = cartData.map((e) => {
        console.log("update quantity==========", e);
        if (e.posId == product.posId) {
          let totalAmount;
          if (e.prodHasOffer) {
            totalAmount = e.offerPrice * e.quantity * availQty;
          } else {
            totalAmount = e.price * e.quantity * availQty;
          }
  
          return {
            ...e,
            availQuantity: availQty,
            totalAmount,
          };
        }
        return e;
      });
      setCartData(updatedQty);
      // console.log('---updatedd product---', cartData)
    };
  
    // ------------------------Deleted cart data on button click ------------------------------//
    const deleteData = (id) => {
      // console.log("------productId--------",productId,delProduct)
      let deletedData = cartData.filter((e) => e.posId !== id);
      // console.log("------deletedData--------",deletedData)
      if (deletedData.length == 0) {
        localStorage.setItem("cartData", []);
        setCartData([]);
        console.log("-----cart Data", cartData);
        setConfirmShow(false);
      } else {
        setCartData(deletedData);
        setConfirmShow(false);
      }
    };
    //console.log("=========clear data===========", cartData)
  
    //-----------------------   add product functionality in cart   --------------------------//
    let addProduct = (productInfo, productAction) => {
      if (productInfo.quantity < 2 && productAction == "minus") {
        handleShow(productInfo);
        //deleteData(productInfo.productId);
        return;
      }
      let availQuantity = Number(productInfo.availQuantity);
      
      
      //cart's updating
      let thisItem = cartData.filter(
        (e) => e.posId === productInfo.posId
      );
      if (thisItem.length > 0) {
        // console.log()
        //now update the item in cart
        let updateItem = cartData.map((e) => {
          if (e.posId == productInfo.posId) {
            let nQuantity;
            if (productAction == "add") {
              nQuantity = e.quantity + 1;
            } else if (productAction == "minus") {
              nQuantity = e.quantity - 1;
            }
            let nPrice;
            if (productInfo.prodHasOffer) {
              nPrice = e.offerPrice * nQuantity * availQuantity;
            } else {
              nPrice = e.price * nQuantity * availQuantity;
            }
            return {
              ...e,
              quantity: nQuantity,
              totalAmount: nPrice,
            };
          }
          return e;
        });
        // console.log("avail quantity====")
  
        setCartData(updateItem);
      } else {
        //no item found in cart so insert this item in cart
        console.log("product infor=========", productInfo);
        if (productInfo.prodHasOffer) {
          productInfo.totalAmount =
            productInfo.offerPrice * productInfo.availQuantity;
        } else {
          productInfo.totalAmount = productInfo.price * productInfo.availQuantity;
        }
  
        setCartData((old) => [...old, productInfo]);
      }
    };
  
    //table data
  
   
    // searching functionality
    const handleSearch = (e) => {
      setSearchData(e.target.value);
      //if (categoryData == true) {
      let filterData = responseData.filter((val) => {
        // console.log("response data ", val.name);
        // console.log(" input data ", searchData);
      });
      setFilterProduct(filterData);
    };
  
    //------------------ check offer price   ---------------------//
    const checkOffer = (responseData) => {
      let isEnable = responseData.some((product) => {
        let price = product.price;
        let offer = product.offer;
        let offerEnable = product.offerEnable;
        let offerPrice = offer.offerPrice ? offer.offerPrice : price;
        let startDate = offer.startDate ? offer.startDate : price;
        let endDate = offer.endDate ? offer.endDate : price;
        let now = new Date();
  
        return (
          offerEnable && now <= new Date(endDate) && now >= new Date(startDate)
        );
      });
  
      console.log("-------isEnable", isEnable);
      return isEnable;
    };
  
    
    const handleShow = (e) => {
      console.log("---handle show", e);
      setConfirmShow(true);
      setDelProduct(e.posId);
    };
  
    const handleCancel = () => {
      setConfirmCancel(true);
     
    };
    const handleClose = () => {
      setConfirmShow(false);
      setCashConfirmModal(false);
    };
  
  
  
    // --- cash payment function -------- //
    const cashFxn = () => {
      setCashConfirmModal(true);
    };
  
    const handleCash = (e) => {
      let enteredValue = e.target.value;
      setCashPay(enteredValue);
     
    };
    const handleOk = () => {
      if(totalOfProducts == cashPay){
        notification.open({
          message: "Invoice generated and order delivered",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          style: { top: "-50px" },
          duration: 2,
        });
        setCashConfirmModal(false);
      }else{
        notification.open({
              message: "Please enter correct amount",
              icon: <Icon type="close-circle" style={{ color: "red" }} />,
              style: { top: "-50px" },
              duration: 2,
            });
            setCashConfirmModal(true);
      }
      //getInvoices();
      
    };
  return (
    <>
    <Home isAdmin={true}>
    <Container fluid className="p-0">
  <Row
    className="d-flex justify-content-between bg-white p-0"
   // id="displayrow"
  >

    <Col span={16} className="d-flex flex-column py-2 px-4 ">
    <span className='back-button mt-2 mb-2'>
    <NavLink to="/RetailerOpprettKonto" className="back-btn2 pt-1 pb-1 pl-3 pr-3">
          <i className="fa fa-arrow-circle-left" aria-hidden="true" />
        </NavLink>
    </span>
   
      <div
        className="d-flex justify-content-end mb-3"
        style={{ justifyContent: "right" }}
      >
        {!categoryData ? (
          <>
            <Button onClick={() => backBtn()}>Back to Categories</Button>
          </>
        ) : (
          <h5 className="d-flex justify-content-center align-items-center mb-0">
            Main Categories
          </h5>
        )}
      </div>
      <div className="d-flex flex-wrap mt-3 productDisplay">
        {categoryData ? (
          <>
            {responseData.map((e) => {
              let catProducts = e.products;
              let isCathasOffer = checkOffer(catProducts);
              return (
                <div className="col-md-4 mb-2 pl-0">
                  <Card
                    key={e._id}
                    className="d-flex p-3 justify-content-between align-items-center productVendorCard"
                    hoverable
                    cover={
                      <>
                        {isCathasOffer ? (
                          <img
                            src={offerIcon}
                            className="offerIcon"
                            style={{ width: "35px" }}
                          />
                        ) : (
                          " "
                        )}
                        <img
                          alt="example"
                          src={`${appConfig.s3url}/${e.image}`}
                        />
                      </>
                    }
                    onClick={() => displayProducts(e._id)}
                  >
                    <Meta className="flex-wrap" title={e.name} />
                  </Card>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {productData.map((e) => {
              if (e) {
              }
              let price = e.price;
              let offer = e.offer;
              let offerEnable = e.offerEnable;
              // let{startDate,endDate}=offer;
              let offerPrice = offer.offerPrice ? offer.offerPrice : price;
              let startDate = offer.startDate ? offer.startDate : price;
              let endDate = offer.endDate ? offer.endDate : price;
              let now = new Date();
              let isofferValid = Boolean(
                now <= new Date(endDate) && now >= new Date(startDate)
              );

              if (offerEnable && isofferValid) {
                e["prodHasOffer"] = true;
              } else {
                e["prodHasOffer"] = false;
              }
              return (
                <div className="col-md-6 mb-2 pl-0 vendorProduct">
                  <Card
                    key={e._id}
                    className="d-flex py-1 px-2 align-items-center productVendorCard"
                    hoverable
                    cover={
                      <>
                        {offerEnable && isofferValid ? (
                          <img
                            src={offerIcon}
                            className="offerIcon"
                            style={{ width: "40px" }}
                          />
                        ) : (
                          ""
                        )}
                        <img
                          alt="example"
                          src={`${appConfig.s3url}/${e.image}`}
                        />
                      </>
                    }
                    onClick={() => {
                      addProduct(
                        {
                          posId: new Date().getTime(),
                          productId: e._id,
                          name: e.name,
                          price: e.price,
                          taxValue: e.taxValue,
                          quantity: 1,
                          labeledUnitType: e.unitType,
                          unitType: e.unitType,
                          labeledAvailQuantity: e.availQuantity,
                          availQuantity: e.availQuantity[0],
                          prodTax: e.prodTax,
                          offerPrice: e.offer.offerPrice,
                          prodHasOffer: e.prodHasOffer,
                        },
                        "add"
                      );
                    }}
                  >
                    <Meta
                      className="flex-wrap ml-2"
                      title={[
                        <>
                          {`${e.itemNo}: `}
                          {e.name}
                        </>,
                      ]}
                      description={[
                        <>
                          {e.unitType == "" ? (
                            ""
                          ) : (
                            <p>
                              {" "}
                              <i>{`(${e.unitType})`}</i>
                            </p>
                          )}
                          {/* {e.availQuantity == "" ? (
                            ""
                          ) : (
                            <p>
                              {" "}
                              <i>{`(${e.availQuantity})`}</i>
                            </p>
                          )} */}
                          <p className="text-dark mb-0">
                            {/* {" "}
                            <b>{`Kr. ${e.price} per ${e.unitType}`}</b> */}
                            Kr.{" "}
                            {offerEnable && isofferValid ? (
                              <>
                                {offerPrice ? offerPrice : price} (
                                <s>{price}</s>)
                              </>
                            ) : (
                              price
                            )}
                          </p>
                        </>,
                      ]}
                    />
                  </Card>
                </div>
                
              );
            })}
            <div className="d-flex flex-wrap pr-2 py-3 buttonDisplay mt-3">
            <div className='col-md-4 mb-2 pl-0'>
            <Button onClick={cashFxn} className="checkout-btn2 w-100">
          Cash
        </Button>
            </div>
            <div className='col-md-4 mb-2 pl-0'>
            <Button onClick={PlaceOrder} className="checkout-btn2 w-100">
          Vipps
        </Button>
            </div>
            <div className='col-md-4 mb-2 pl-0'>
            <Button onClick={PlaceOrder} className="checkout-btn2 w-100">
          Credit
        </Button>
            </div>
            
      
     
        </div>
          </>
        )}
      </div>
    </Col>
    <Col
      span={8}
      className="d-flex flex-column justify-content-between p-2"
    >
      <div>
        <div className="d-flex justify-content-end mb-3">
          <Button className="cart-btn" onClick={() => clearCartPro()}>
            Clear Cart
          </Button>
        </div>
        <div className="cartSec billDisplay">
          {/* {console.log("cartttt======",cartData)} */}
          {cartData.map((e) => {
            return (
              <div
                className="col-md-12 mb-2 pl-0"
                //  key={e._id}
              >
                <Card
                  className="py-0 px-2 cartProductCard"
                  cartData={cartData}
                >
                  <div className="d-flex w-100 justify-content-between">
                    <p>
                      <b>
                        {" "}
                        {e.name} &nbsp; {e.quantity}pkt.  x {e.availQuantity}
                        {e.labeledUnitType}
                      </b>
                    </p>
                    <p className="mb-0">
                      <b>Kr.{e.totalAmount}</b>
                    </p>
                  </div>
                  {!e.availQuantity ? (
                    ""
                  ) : (
                    <div className="d-flex w-100 justify-content-between mb-2">
                      <p className="mb-0">
                        Select Unit Value ({e.labeledUnitType}):
                      </p>
                      <select
                        className="unitType availQuantity"
                        name="availQuantity"
                        id="unitType availQuantity"
                        style={{
                          borderRadius: "5px",
                          fontSize: "12px",
                          borderBottom: "1px solid #dc3545",
                        }}
                        value={e.availQuantity}
                        onChange={(event) => handleQuantity(event, e)}
                      >
                        {/* {console.log("==ghghg==",e.availQuantity)} */}
                        {e.labeledAvailQuantity.map((qnty) => (
                          <option value={qnty}>{qnty} selected</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex w-100 justify-content-between">
                    <p>
                      <b> Total quantity</b>
                    </p>
                    <p className="mb-0">
                      <b>
                        {e.quantity * e.availQuantity}
                        {e.labeledUnitType}
                      </b>
                    </p>
                  </div>
                  <div className="d-flex w-100 justify-content-start my-2">
                    {e.quantity == 1 ? (
                      <Button
                        className="minus disabled"
                        disabled={e.quantity == 1 ? true : false}
                        onClick={() => {
                          addProduct(e, "minus");
                        }}
                      >
                        -
                      </Button>
                    ) : (
                      <Button
                        className="minus"
                        onClick={() => {
                          addProduct(e, "minus");
                        }}
                      >
                        -
                      </Button>
                    )}
                    <Button className="quantity">{e.quantity}</Button>
                    <Button
                      className="plus"
                      onClick={() => {
                        addProduct(e, "add");
                      }}
                    >
                      +
                    </Button>
                    <div className='align-items-end'>
                    <a
                      style={{ color: "red" , paddingLeft:"100px"}}
                      onClick={() => handleShow(e)}
                    >
                      <u>
                        <i>Delete</i>
                      </u>
                    </a>
                  </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="totalBillSummary">
          <Descriptions>
            <Descriptions.Item label="Sub-Total">
              {Number(totalOfProducts - totalTax).toFixed(2)}
            </Descriptions.Item>{" "}
          </Descriptions>
          <Descriptions>
            {mvaArray.map((e) => {
              let mvaLabel = `MVA(${e.tax}%)`;
              return (
                <Descriptions.Item label={mvaLabel}>
                  {Number(e.taxTotal).toFixed(2)}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
          {/* {!discount==0 ? 
            <Descriptions>
            <Descriptions.Item label={`Discount(${discount}%)`}>
              {Number(discountPrice).toFixed(2)}
            </Descriptions.Item>{" "}
          </Descriptions> : ""
          } */}
          <Descriptions>
            <Descriptions.Item label="Drive out charges">
              {/* {totalOfProducts- discountPrice} */}
              {
  totalOfProducts > 0 ? `${driveOutCharges}` :"0.00"}
            </Descriptions.Item>{" "}
          </Descriptions>


  <Descriptions>
            <Descriptions.Item label="Total">
              {/* {totalOfProducts- discountPrice} */}
              {
  totalOfProducts > 0 ?   `${totalOfProducts + driveOutCharges}`   : "0.00"}
            </Descriptions.Item>{" "}
          </Descriptions>
        
        
          <Descriptions></Descriptions>
        </div>
        {/* <Button onClick={PlaceOrder} className="checkout-btn w-100">
          Checkout
        </Button>{" "} */}
      </div>
    </Col>
  </Row>
  <Modal
    // open={isModalOpen}
    onCancel={handleCancel}
    //onOk={handleOk}
    visible={confirmShow}
    footer={[
      <Button className="modal-btn" onClick={() => deleteData(delProduct)}>
        Confirm
      </Button>,
      <Button
        key="back"
        className="modal-btn"
        onClick={() => handleClose()}
      >
        Cancel
      </Button>,
    ]}
    closable={true}
  >
    <h4>Are you sure you want to delete this item ??</h4>
  </Modal>

  <Modal
          centered
          visible={cashConfirmModal}
          title="Cash Payment"
          onOk={handleOk}
          onCancel={handleClose}
          footer={[
            <Button key="submit" onClick={handleOk}>
              Pay
            </Button>,
            <Button key="back" onClick={handleClose}>
              Close
            </Button>
          ]}
        >
          <div>
            <div className="d-flex justify-content-between">
              <p>Order Total</p>
              <p>Kr. {totalOfProducts + driveOutCharges}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Amount Paid</p>
              <Input
                prefix="Kr. "
                style={{ width: 150 }}
                type="number"
                value={cashPay}
                onChange={handleCash}
              />
            </div>
            <div className="d-flex justify-content-between">
              <p>Change</p>
              <p>Kr. </p>
            </div>
          </div>
        </Modal>
</Container>
    </Home>
</>
  )
}

export default RetailerOpprettKontoDriveout
