import React from "react";
import ReactDOM from "react-dom";
import { Table } from "antd";
import "./TableSum.css";
export default class TableSum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    //! Antd table hack, try not to mess it up...
    const dom = ReactDOM.findDOMNode(this);
    const table = dom.querySelectorAll(".ant-table-body");
    let l = table[0];
    l.style.overflowX = "hidden";
    let r = table[1];
    r.addEventListener("scroll", function () {
      l.scrollLeft = r.scrollLeft;
    });
  }

  render() {
    return (
      <Table
        loading={this.props.loading}
        columns={this.props.columns}
        dataSource={this.props.data}
        scroll={this.props.scroll}
        onChange={this.props.onChange}
        bordered={this.props.bordered}
        rowKey={(record) => record.productName}
        footer={
          this.props.data.length > 0
            ? () => (
                <div>
                  <Table
                    columns={this.props.summaryColumns}
                    dataSource={this.props.summaryData}
                    pagination={false}
                    showHeader={false}
                    scroll={this.props.scroll}
                    rowClassName="highlighted-bg"
                    rowKey={(record) => record.productName}
                  />
                </div>
              )
            : () => (
                <div style={{ display: "none" }}>
                  <Table
                    columns={this.props.summaryColumns}
                    dataSource={this.props.summaryData}
                    pagination={false}
                    showHeader={false}
                    scroll={this.props.scroll}
                    rowClassName="highlighted-bg"
                    rowKey={(record) => record.productName}
                  />
                </div>
              )
        }
        className="ant-table-body"
      />
    );
  }
}
