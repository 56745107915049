import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Button, Card } from "react-bootstrap";
import { LagerReportColumn } from "./../../Table/TableColumns";
import "./TableColumn.css";
import { DatePicker, Table } from "antd";

const Header = (props) => {
  const printReportHandler = async () => {
    window.print();
  };

  return (
    <>
      <div className="report-container">
        <span className="item" style={{ width: "60%" }}>
          <span id="less-visible">Arkiv/</span>
          Lager Report
        </span>
        <span id="less-visible">
          <Button
            onClick={() => props.goBackMainPage()}
            className="ant-btn back-btn"
          >
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </Button>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row className="pb-3">
            <Col md="10">
              <h4 className="mb-0">Lager Report</h4>
            </Col>
            <Col md={2} className="d-flex justify-content-end">
              <Button
                className="btn btn-ab"
                onClick={() => printReportHandler()}
              >
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                />
              </Button>
            </Col>
          </Row>
          <Row>
          <Table
                id="tablePrint"
                bordered
                loading={props.isloading}
                dataSource={props.lagerReport.data}
                columns={LagerReportColumn()}
              />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Header;
