import React, { useState } from "react";
import {
  Modal,
  Button,
  Tabs,
  Tab,
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "react-bootstrap";
import { TimePicker } from "antd";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment";
import { sendNotification } from "../../helpers/commFuc";
import InfoForm from "./InfoForm";
const EditModel = ({
  show,
  handleClose,
  allDays,
  details,
  setAllDays,
  updateDayAndTime,
  updateInfo
}) => {
  const [key, setKey] = useState("OnlineUkeTilgjengelighet");
  const updateTime = (time, timeString, idx, type) => {
    let days = [...allDays];
    console.log("--Type----", type, timeString);
    if (type === "opening") {
      days[idx].openingTime = timeString;
    } else if (type === "closing") {
      days[idx].closingTime = timeString;
    }

    if (type == "nightOpening") {
      days[idx].nightOpening = timeString;
    }
    if (type == "nightClosing") {
      days[idx].nightClosing = timeString;
    }

    setAllDays(days);
  };
  const onToggleSwitch = (val, idx) => {
    let days = [...allDays];
    days[idx].status = val;
    days[idx].disabled = !val;
    setAllDays(days);
  };
  const updateDayAvailiblity = (idx) => {
    const bid = localStorage.getItem("bid");
    let wDay = allDays[idx].day;
    console.log("---------DAY PAyload", allDays[idx]);
    let oTime = allDays[idx].openingTime;
    let cTime = allDays[idx].closingTime;
    let nightClosing = allDays[idx].nightClosing
      ? allDays[idx].nightClosing
      : "00:00";
    let nightOpening = allDays[idx].nightOpening
      ? allDays[idx].nightOpening
      : "00:00";
    let status = allDays[idx].status;
    if (wDay && bid && oTime && cTime) {
      const data = {
        weekday: wDay.toLowerCase(),
        status: status,
        branchId: bid,
        openingTime: oTime,
        closingTime: cTime,
        nightClosing,
        nightOpening
      };
      updateDayAndTime(data);
    } else {
      sendNotification("error", "All Fields are required.");
    }
  };
  const format = "HH:mm";
  const createAllDaysList = () => {
    return (
      allDays.length &&
      allDays.map((day, i) => {
        if (day)
          return (
            <ListGroupItem key={i}>
              <Row>
                {/* <Col className=" col-2 p-0">
                  <h5>{day.day}</h5>
                </Col> */}
                <Col className=" col-13">
                  
                  <Row className="justify-content-between">
                  <h6>*{allDays[i-1]?allDays[i-1].day:"Sunday"} </h6><p>Night Timings</p>
                    <TimePicker
                      style={{ marginLeft: "20px" }}
                      defaultValue={
                        day.nightOpening ? moment(day.nightOpening, format) : ""
                      }
                      format={format}
                      disabled={day.disabled}
                      onChange={(time, timeString) =>
                        updateTime(time, timeString, i, "nightOpening")
                      }
                    />
                    <TimePicker
                      defaultValue={
                        day.nightClosing ? moment(day.nightClosing, format) : ""
                      }
                      format={format}
                      disabled={day.disabled}
                      onChange={(time, timeString) =>
                        updateTime(time, timeString, i, "nightClosing")
                      }
                    />
                 
                  </Row>
                  <Row className="justify-content-between">

                  <h6   className="btn-ab btn-sm" >{day.day} </h6>
                    <p>Regular Timings: </p>
                    <TimePicker
                      defaultValue={
                        day.openingTime ? moment(day.openingTime, format) : ""
                      }
                      format={format}
                      disabled={day.disabled}
                      onChange={(time, timeString) =>
                        updateTime(time, timeString, i, "opening")
                      }
                    />
                    <TimePicker
                      defaultValue={
                        day.closingTime ? moment(day.closingTime, format) : ""
                      }
                      format={format}
                      disabled={day.disabled}
                      onChange={(time, timeString) =>
                        updateTime(time, timeString, i, "closing")
                      }
                    />
                  </Row>
                     {/* // {console.log("--- {allDays[i-1].day}", allDays[i-1]?allDays[i-1].day:"Sunday")} */}
                  {/* <p> Night timings will be applicable for {allDays[i-1]?allDays[i-1].day:"Sunday"} </p> */}
                </Col>
                <Col className=" col-2 d-flex flex-column justify-content-around">
                  <BootstrapSwitchButton
                    checked={day.status}
                    size="sm"
                    onstyle="btn-ab"
                    style="btn-ab"
                    onChange={(e) => onToggleSwitch(e, i)}
                  />
                  <Button
                    onClick={() => updateDayAvailiblity(i)}
                    className="btn-ab btn-sm"
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          );
      })
    );
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        // style={{width:"500px"}}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>Legg Til Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="profile"
            id="LeggTilRestaurant"
            className="mb-3"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              eventKey="OnlineUkeTilgjengelighet"
              title="Online Uke Tilgjengelighet"
            >
              <h4>Online Uke Tilgjengelighet</h4>
              <Card>
                <ListGroup className="list-group-flush">
                  {createAllDaysList()}
                </ListGroup>
              </Card>
            </Tab>
            <Tab eventKey="GenerallInformasjon" title="Generall Informasjon">
              <h4>Generall Informasjon</h4>
              <Card>
                {/* <ListGroup className="list-group-flush"> */}
                <InfoForm updateInfo={updateInfo} details={details} />
                {/* </ListGroup> */}
              </Card>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EditModel;
