import React, { useState, useEffect } from "react";
import { Table, Select, Input, Button } from "antd";
import EditForm from "./EditForm";
import UpdateImage from "./UpdateImage";
import appConfig from "../../helpers/appConfig";
import DeleteProduct from "./DeleteProduct";
import Ingredents from "./Ingredents";
import { sendNotification } from "../../helpers/commFuc";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useDispatch } from "react-redux";
import { switchExtraInformationAction } from "../../action/productsAction";
import Offer from "./Offer";
const Option = Select.Option;
const ProductList = ({
  products,
  updateProduct,
  categoryId,
  updateProductImage,
  successData,
  getProducts,
  deleteProduct,
  stockProduct,
  saveIngredent,
  isShowWeb,
  isLoading,
  isShowWebValues,
  setIsShowWebValues,

  offerBackgrounds
}) => {
  let [editingKey, setEditingKey] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  let [visibleEditPopover, setVisibleEditPopover] = useState();
  let [name, setName] = useState("");
  let [price, setPrice] = useState("");
  let [prodTax, setProdTax] = useState(0);
  let [itemNo, setItemNo] = useState("");
  let [unitArr, setUnitArr] = useState([]);
  let [availQtyArr, setAvailQtyArr] = useState([]);
  let [showToggle, setShowToggle] = useState(isShowWebValues);
  const dispatch = useDispatch();
  const [isShowExtraInfo, setisShowExtraInfo] = useState('');
  const [isofferSet,setIsofferSet]=useState(false)
  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      (successData.actionType === "updateProductImage" ||
        successData.actionType === "deleteProduct" ||
        "saveIngredent")
    ) {
      getProducts(categoryId);
    }
  }, [successData]);
  useEffect(()=>{
    console.log("offerSet")

    if(categoryId){
      getProducts(categoryId);
    }
    

  },[isofferSet])
  const edit = (key, record) => {
    setEditingKey(key);
    setName(record.name);
    setPrice(record.price);
    setItemNo(record.itemNo);
    setProdTax(record.prodTax?record.prodTax:0)
    setUnitArr(record.unitType)
    setAvailQtyArr(record.availQuantity)
  };
  const save = (id) => {
    if (validateForm()) {
      setEditingKey("");
      const data = {
        price,
        name,
        itemNo,
        id,
        categoryId,
        prodTax,
        unitType:unitArr,
        availQuantity:availQtyArr
      };
      updateProduct(data);
    }
  };

  const prodUnits=["kg","lt"]
  const availQtys=["0.5","1","2.5","5"]
  const selectUnits=(e)=>{
    console.log(e)
    setUnitArr(e)
  }
  const selectAvailQtys=(e)=>{
    console.log(e)
    setAvailQtyArr(e)
  }
  const validateForm = () => {
    if (!name.trim() || !price || !itemNo) {
      sendNotification("error", "All fields are required!");
      return false;
    }
    return true;
  };
  const cancel = () => {
    setEditingKey("");
  };

  const onChangeIsWeb = (e, record, index) => {
    isShowWebValues[record._id] = e;
    isShowWeb(e,record);
    setIsShowWebValues(isShowWebValues);
  };
 
// let branchType="rest"
let branchType=localStorage.getItem("branchType")
// branchType=""
console.log("-------branchType-------",branchType)
const commonColumns= [
    {
      title: "Produkt Nummer",
      dataIndex: "itemNo",
      width: "6%",
      editable: true,
      key: 1,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input
            type="number"
            value={itemNo}
            onChange={(e) => setItemNo(e.target.value)}
          />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Produkt Navn",
      dataIndex: "name",
      width: "10%",
      editable: true,
      key: 2,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Produkt bilde",
      width: "8%",
      editable: false,
      key: 3,
      render: (text, record) => {
        return (
          <img
            src={`${appConfig.s3url}/${record.thumbnail}`}
            style={{ maxWidth: 80, maxHeight: 80, borderRadius: "100%" }}
          />
        );
      }
    },
    {
      title: "Produkt Pris",
      width: "6%",
      editable: true,
      dataIndex: "price",
      key: 4,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Tilgjengelighet",
      width: "8%",
      editable: true,
      // dataIndex: "price",
      key:"Tilgjengelighet",
      render: (text, record, index) => {
        return (
          <>
            <BootstrapSwitchButton
              size="xs"
              style="btn-ab"
              onstyle="btn-ab"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={isShowWebValues[record._id]}
              onChange={(e) => {
                onChangeIsWeb(e,record, index);
              }}
            />
          </>
        );
      }
    },
    {
      title: "Spiciness",
      width: "6%",
      editable: true,
      // dataIndex: "price",
      key: 100,
      render: (text, record, index) => {
        let isShowinst;
        // console.log("record is",record);
      //  setisShowExtraInfo(record.isExtraInformation);
       isShowinst=record.isExtraInformation;
        // console.log("record.isExtraInformation",record.isExtraInformation)
       
        return (
          <>
            <BootstrapSwitchButton
              size="xs"
              style="btn-ab"
              onstyle="btn-ab"
              // checkedChildren="Yes"
              // unCheckedChildren="Yes"
              checked={isShowinst}
              onChange={(e) => {
                console.log("record", record.categoryId._id, record._id, e);
                const data={
                  categoryId:record.categoryId._id,
                  productId:record._id,
                  isExtraInformation:e
                };
                isShowinst=e;
                 dispatch(switchExtraInformationAction(data));
              }}
            />
          </>
        );
      }
      },
    {
      title: "Offer",
      width: "8%",
      // editable: true,
      dataIndex: "operation",
      key: 1089,
      render: (text, record, index) => {
        return (
          <>

            <Offer
              prodData={record}
              setIsofferSet={setIsofferSet}
              isofferSet={isofferSet}
            />
          </>
        );
      }
    },
    {
      title: "Handling",
      dataIndex: "operation",
      width: "12%",
      key: 5,
      render: (text, record) => {
        return (
          <>
            <Ingredents
              record={record}
            stockCats={stockProduct?stockProduct:[]}
              saveIngredent={saveIngredent}
            />
            <EditForm
              record={record}
              edit={edit}
              isEditing={isEditing}
              save={save}
              cancel={cancel}
              visibleEditPopover={visibleEditPopover}
              setVisibleEditPopover={setVisibleEditPopover}
            />
            <UpdateImage
              record={record}
              updateProductImage={updateProductImage}
            />
            <DeleteProduct record={record} deleteProduct={deleteProduct} />
          </>
        );
      }
    }
  ];
const vendorColumns= [
  {
    title: "Produkt Nummer",
    dataIndex: "itemNo",
    width: "6%",
    editable: true,
    key: 1,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <Input
          type="number"
          value={itemNo}
          onChange={(e) => setItemNo(e.target.value)}
        />
      ) : (
        <> {text}</>
      );
    }
  },
  {
    title: "Produkt Navn",
    dataIndex: "name",
    width: "10%",
    editable: true,
    key: 2,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      ) : (
        <> {text}</>
      );
    }
  },
  {
    title: "bilde",
    width: "10%",
    editable: false,
    key: 3,
    render: (text, record) => {
      return (
        <img
          src={`${appConfig.s3url}/${record.thumbnail}`}
          style={{ maxWidth: 120, maxHeight: 120, borderRadius: "100%" }}
        />
      );
    }
  },
  {
    title: "Produkt Pris",
    width: "10%",
    editable: true,
    dataIndex: "price",
    key: 4,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <Input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      ) : (
        <> {text}</>
      );
    }
  },
  {
    title: "Tax",
    width: "6%",
    editable: true,
    dataIndex: "prodTax",
    key: 5,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <Input
          type="number"
          value={prodTax}
          onChange={(e) => setProdTax(e.target.value)}
        />
      ) : (
        <> {text}</>
      );
    }
  },
  {
    title: "units",
    width: "10%",
    editable: true,
    dataIndex: "unitType",
    key: 7,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <>
         {/* {console.log("unitArr in edit",unitArr)} */}
         <Select
            // mode="tags"
            style={{ width: "100%" }}
            placeholder="Choose Type"
            value={unitArr}
            onChange={(e) => selectUnits(e)}
            required={true}
          >
          
            {prodUnits.length &&
              prodUnits.map((itm) => {
                return (
                  <Option value={itm} key={itm}>
                    {(itm)}
                  </Option>
                );
              })}
          </Select>

        </>
       
      ) : (
        
        <>
        {/* {console.log("------text",text.join(","))}  */}
        {text}</>
      );
    }
  },
  {
    title: "Available Quantity",
    width: "10%",
    editable: true,
    dataIndex: "availQuantity",
    key: 11,
    render: (text, record, index) => {
      return editingKey === record.name ? (
        <>
         {console.log("unitArr in edit",unitArr)}
         <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Choose Type"
            value={availQtyArr}
           
            onChange={(e) => selectAvailQtys(e)}
            required={true}
          >
          
            {availQtys.length &&
              availQtys.map((itm) => {
                console.log("taxes itm----->", itm);
                return (
                  <Option value={itm} key={itm}>
                    {(itm)}
                  </Option>
                );
              })}
          </Select>

        </>
       
      ) : (
        
        <>{console.log("------text",text.join(","))} {text.join(",")}</>
      );
    }
  },
  {
    title: "Tilgjengelighet",
    width: "8%",
    editable: true,
    // dataIndex: "price",
    key: 100,
    render: (text, record, index) => {
      return (
        <>
          <BootstrapSwitchButton
            size="xs"
            style="btn-ab"
            onstyle="btn-ab"
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={isShowWebValues[record._id]}
            onChange={(e) => {
              onChangeIsWeb(e,record, index);
            }}
          />
        </>
      );
    },
  },
  {
    title: "Offer",
    width: "8%",
    // editable: true,
    dataIndex: "operation",
    key: 1089,
    render: (text, record, index) => {
      return (
        <>

          <Offer
            prodData={record}
            setIsofferSet={setIsofferSet}
            isofferSet={isofferSet}
            offerBackgrounds = {offerBackgrounds}
          />
        </>
      );
    }
  },
  {
    title: "Handling",
    dataIndex: "operation",
    width: "20%",
    key: 6,
    render: (text, record) => {
      return (
        <>
        {/* {console.log("-----type",typeof(stockProduct))} */}
        {console.log("-----stockProduct in prodlist",stockProduct)}
          <Ingredents
            record={record}
            stockCats={stockProduct?stockProduct:[]}
            saveIngredent={saveIngredent}
          />
          <EditForm
            record={record}
            edit={edit}
            isEditing={isEditing}
            save={save}
            cancel={cancel}
            visibleEditPopover={visibleEditPopover}
            setVisibleEditPopover={setVisibleEditPopover}
          />
          <UpdateImage
            record={record}
            updateProductImage={updateProductImage}
          />
          <DeleteProduct record={record} deleteProduct={deleteProduct} />
        </>
      );
    }
  }
];



  // const restColumns= [
  //   {
  //     title: "Produkt Nummer",
  //     dataIndex: "itemNo",
  //     width: "6%",
  //     editable: true,
  //     key: 1,
  //     render: (text, record, index) => {
  //       return editingKey === record.name ? (
  //         <Input
  //           type="number"
  //           value={itemNo}
  //           onChange={(e) => setItemNo(e.target.value)}
  //         />
  //       ) : (
  //         <> {text}</>
  //       );
  //     }
  //   },
  //   {
  //     title: "Produkt Navn",
  //     dataIndex: "name",
  //     width: "20%",
  //     editable: true,
  //     key: 2,
  //     render: (text, record, index) => {
  //       return editingKey === record.name ? (
  //         <Input value={name} onChange={(e) => setName(e.target.value)} />
  //       ) : (
  //         <> {text}</>
  //       );
  //     }
  //   },
  //   {
  //     title: "Produkt bilde",
  //     width: "15%",
  //     editable: false,
  //     key: 3,
  //     render: (text, record) => {
  //       return (
  //         <img
  //           src={`${appConfig.s3url}/${record.thumbnail}`}
  //           style={{ maxWidth: 120, maxHeight: 120, borderRadius: "100%" }}
  //         />
  //       );
  //     }
  //   },
  //   {
  //     title: "Produkt Pris",
  //     width: "10%",
  //     editable: true,
  //     dataIndex: "price",
  //     key: 4,
  //     render: (text, record, index) => {
  //       return editingKey === record.name ? (
  //         <Input
  //           type="number"
  //           value={price}
  //           onChange={(e) => setPrice(e.target.value)}
  //         />
  //       ) : (
  //         <> {text}</>
  //       );
  //     }
  //   },
  //   {
  //     title: "Tilgjengelighet",
  //     width: "10%",
  //     editable: true,
  //     // dataIndex: "price",
  //     key: 100,
  //     render: (text, record, index) => {
  //       return (
  //         <>
  //           <BootstrapSwitchButton
  //             size="xs"
  //             style="btn-ab"
  //             onstyle="btn-ab"
  //             checkedChildren="Yes"
  //             unCheckedChildren="No"
  //             checked={isShowWebValues[record._id]}
  //             onChange={(e) => {
  //               onChangeIsWeb(e,record, index);
  //             }}
  //           />
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Spiciness",
  //     width: "10%",
  //     editable: true,
  //     // dataIndex: "price",
  //     key: 100,
  //     render: (text, record, index) => {
  //       let isShowinst;
  //       console.log("record is",record);
  //     //  setisShowExtraInfo(record.isExtraInformation);
  //      isShowinst=record.isExtraInformation;
  //       console.log("record.isExtraInformation",record.isExtraInformation)
       
  //       return (
  //         <>
  //           <BootstrapSwitchButton
  //             size="xs"
  //             style="btn-ab"
  //             onstyle="btn-ab"
  //             // checkedChildren="Yes"
  //             // unCheckedChildren="Yes"
  //             checked={isShowinst}
  //             onChange={(e) => {
  //               console.log("record",record.categoryId._id,record._id,e)
  //               const data={
  //                 categoryId:record.categoryId._id,
  //                 productId:record._id,
  //                 isExtraInformation:e
  //               }
  //               isShowinst=e;
  //                dispatch(switchExtraInformationAction(data));
  //             }}
  //           />
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Handling",
  //     dataIndex: "operation",
  //     width: "20%",
  //     key: 5,
  //     render: (text, record) => {
  //       return (
  //         <>
  //           <Ingredents
  //             record={record}
  //             stockCats={stockProduct}
  //             saveIngredent={saveIngredent}
  //           />
  //           <EditForm
  //             record={record}
  //             edit={edit}
  //             isEditing={isEditing}
  //             save={save}
  //             cancel={cancel}
  //             visibleEditPopover={visibleEditPopover}
  //             setVisibleEditPopover={setVisibleEditPopover}
  //           />
  //           <UpdateImage
  //             record={record}
  //             updateProductImage={updateProductImage}
  //           />
  //           <DeleteProduct record={record} deleteProduct={deleteProduct} />
  //         </>
  //       );
  //     }
  //   }
  // ];

  const restColumns = [
    {
      title: "Produkt Nummer",
      dataIndex: "itemNo",
      width: "6%",
      editable: true,
      key: 1,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input
            type="number"
            value={itemNo}
            onChange={(e) => setItemNo(e.target.value)}
          />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Produkt Navn",
      dataIndex: "name",
      width: "10%",
      editable: true,
      key: 2,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Produkt bilde",
      width: "8%",
      editable: false,
      key: 3,
      render: (text, record) => {
        return (
          <img
            src={`${appConfig.s3url}/${record.thumbnail}`}
            style={{ maxWidth: 80, maxHeight: 80, borderRadius: "100%" }}
          />
        );
      }
    },
    {
      title: "Produkt Pris",
      width: "6%",
      editable: true,
      dataIndex: "price",
      key: 4,
      render: (text, record, index) => {
        return editingKey === record.name ? (
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        ) : (
          <> {text}</>
        );
      }
    },
    {
      title: "Tilgjengelighet",
      width: "8%",
      editable: true,
      // dataIndex: "price",
      key:"Tilgjengelighet",
      render: (text, record, index) => {
        return (
          <>
            <BootstrapSwitchButton
              size="xs"
              style="btn-ab"
              onstyle="btn-ab"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={isShowWebValues[record._id]}
              onChange={(e) => {
                onChangeIsWeb(e,record, index);
              }}
            />
          </>
        );
      }
    },
    {
      title: "Spiciness",
      width: "6%",
      editable: true,
      // dataIndex: "price",
      key: 100,
      render: (text, record, index) => {
        let isShowinst;
        // console.log("record is",record);
      //  setisShowExtraInfo(record.isExtraInformation);
       isShowinst=record.isExtraInformation;
        // console.log("record.isExtraInformation",record.isExtraInformation)
       
        return (
          <>
            <BootstrapSwitchButton
              size="xs"
              style="btn-ab"
              onstyle="btn-ab"
              // checkedChildren="Yes"
              // unCheckedChildren="Yes"
              checked={isShowinst}
              onChange={(e) => {
                console.log("record", record.categoryId._id, record._id, e);
                const data={
                  categoryId:record.categoryId._id,
                  productId:record._id,
                  isExtraInformation:e
                };
                isShowinst=e;
                 dispatch(switchExtraInformationAction(data));
              }}
            />
          </>
        );
      }
      },
    {
      title: "Offer",
      width: "8%",
      // editable: true,
      dataIndex: "operation",
      key: 1089,
      render: (text, record, index) => {
        return (
          <>

            <Offer
              prodData={record}
              setIsofferSet={setIsofferSet}
              isofferSet={isofferSet}
              offerBackgrounds = {offerBackgrounds}
            />
          </>
        );
      }
    },
    {
      title: "Handling",
      dataIndex: "operation",
      width: "12%",
      key: 5,
      render: (text, record) => {
        return (
          <>
            <Ingredents
              record={record}
              stockCats={stockProduct}
              saveIngredent={saveIngredent}
            />
            <EditForm
              record={record}
              edit={edit}
              isEditing={isEditing}
              save={save}
              cancel={cancel}
              visibleEditPopover={visibleEditPopover}
              setVisibleEditPopover={setVisibleEditPopover}
            />
            <UpdateImage
              record={record}
              updateProductImage={updateProductImage}
            />
            <DeleteProduct record={record} deleteProduct={deleteProduct} />
          </>
        );
      }
    }
  ];
  const columns =branchType=="rest"?restColumns: branchType=="vendor"|| "retailer" ?vendorColumns:commonColumns
  const isEditing = (record) => {
    return record.name === editingKey ? editingKey : undefined;
  };
  const productColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === "price" ? "number" : "text",
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  return (
    <div className="categoryListTable">
      <Table
        rowKey={(record) => record._id}
        loading={isLoading}
        onChange={(pagination) => setCurrentPage(pagination.current)}
        bordered
        dataSource={products}
        columns={productColumns}
        rowClassName="editable-row"
        className="tableClass"
      />
    </div>
  );
};
export default ProductList;
