import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendNotification } from "../../helpers/commFuc";
import { Modal, Button, Input } from "antd";
import { Form } from "react-bootstrap";

import { saveCoupan } from "../../action/coupanAction";
const CoupanModal = (props) => {
  const [visible, setVisible] = useState(props.visible);
  const [coupanValue, setCoupanValue] = useState();
  const [phoneNumber, setphoneNumber] = useState();

  const [confirmLoading, setConfirmLoading] = useState(false);

  let dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const handleOk = () => {
    dispatch(saveCoupan(coupanValue, phoneNumber))
      .then((ewa) => {
        setCoupanValue("");
        setphoneNumber("");
        sendNotification("success", "coupan generated sucessfully");
      })
      .catch((err) => {
        sendNotification("failure", "something went wrong");
      });
    props.closeCoupanModal();
  };

  const handleCancel = () => {
    setCoupanValue("");
    setphoneNumber("");
    props.closeCoupanModal();
  };
  return (
    <React.Fragment>
      <div>
        <Modal
          title="ADD COUPAN"
          visible={visible}
          onOk={handleOk}
          centered
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Coupan Value</Form.Label>
              <Form.Control
                type="number"
                onChange={(event) => {
                  setCoupanValue(event.target.value);
                }}
                value={coupanValue}
                placeholder="Enter Coupans  Value"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Customer Phone No</Form.Label>
              <Form.Control
                type="number"
                onChange={(event) => {
                  setphoneNumber(event.target.value);
                }}
                value={phoneNumber}
                placeholder="Enter Customer PhoneNo"
              />
            </Form.Group>
          </Form>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default CoupanModal;
