import React from 'react'
import Home from "../Home";
import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Badge } from "react-bootstrap";

const RetailerDashboard = () => {
  return (
    <Home>
      <div className="report-container text-center"
        style={{ marginBottom: "0px" }}
      >
        <span className="item ">Rapporter</span>
      </div> 
       <Card>
        <Card.Body>
          <Row  justify="space-evenly" className="m-b-1">
            <Col span={6} className="text-left" style={{ marginTop: "15px", marginLeft: "40px" }}>
              <NavLink
                to="/retailerDailyBill"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                DAILY BILLS
              </NavLink>
              
                  
            </Col>
             <Col span={6} className="text-left" style={{ marginTop: "15px" }}>
              <NavLink
                to="/retailer-Arkiv"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                ARKIV
              </NavLink>
            </Col>
            <Col span={6} className="text-left" style={{ marginTop: "15px" }}> 
              <NavLink
                to="/retailer-ZRepport"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                Z-Repport
              </NavLink>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="report-container text-center order-type" >
        <span className="item ">Orders Type & Details </span>
      </div>
      <Card>
        <Card.Body>
          <Row className="m-b-1">
            <Col className=" text-left" style={{ marginTop: "15px", marginLeft: "40px" }}>
              <NavLink
                // to="/vendor/vendortest"
                // to="/vendor-orders"
                to="/retailerOrders"
                className="btn btn-link"
                style={{ width: "120px", padding: "30px" }}
              >
                RETAILER ORDERS
              </NavLink>
            </Col>
          </Row>


        </Card.Body>
      </Card>
    </Home>
  )
}

export default RetailerDashboard
