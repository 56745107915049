import React from "react";
import RedirectRoute from "./RedirectRoute";
import KitchenView from "./../Kitchen";
import CoupanList from "./../Coupans/CoupanList";
import DashBoard from "./../Dashboard";
import { Redirect } from "react-router";
const RedirectTo = (props) => {
  switch (props.history.location.pathname) {
    case "/kitchen":
      return (
        <>
          <RedirectRoute
            Component={KitchenView}
            history={props.history}
            permission={"kitchenpermission"}
            status={"kitchen"}
          />
        </>
      );
      break;
    case "/bar":
      return (
        <>
          <RedirectRoute
            Component={KitchenView}
            history={props.history}
            permission={"barpermission"}
            status={"bar"}
          />
        </>
      );
      break;
    case "/coupons":


      return (
        <>
          <RedirectRoute
            Component={CoupanList}
            history={props.history}
            permission={"couponPermission"}
            status={1}
          />
        </>
      );
    default:
      return (
        <>
          <Redirect to="/dashboard" />
        </>
      );
  }
};

export default RedirectTo;
