import { GET_DAILY_REPORT } from "../../action/types";
const initialState = {
  invoiceData: [],
  branch: {}
};
const dailyReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_REPORT:
      return {
        ...state,
        invoiceData: action.payload.data.invoiceData
          ? action.payload.data.invoiceData
          : [],
        branch: action.payload.data.branch ? action.payload.data.branch : []
      };
    default:
      return state;
  }
};
export default dailyReportReducer;
