import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetPassword } from "../../action";
const otpErrObj = {
  otp: {
    show: false,
    message: ""
  },
  password: {
    show: false,
    massage: ""
  }
};
const emailErrObj = {
  show: false,
  massage: ""
};
const ForgetEmail = ({ visibleModel, setVisibleModel }) => {
  let [email, setEmail] = useState("");
  let [otp, setOtp] = useState("");
  let [password, setPassword] = useState("");
  let [resetEmail, setResetEmail] = useState("");
  let [err, setErr] = useState(emailErrObj);
  let [otpErr, setOtpErr] = useState(otpErrObj);
  let [popUp, setPopUp] = useState("emailPopUp");
  const successData = useSelector(({ successMassage }) => successMassage.data);
  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "forgotPassword"
    ) {
      setResetEmail(successData.email);
      setPopUp("otpPopUp");
    }
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "resetPassword"
    ) {
      handleCancel();
    }
  }, [successData]);
  const dispatch = useDispatch();
  const handleCancel = () => {
    setVisibleModel(false);
    setPopUp("emailPopUp");
    setOtpErr(otpErrObj);
    setErr(emailErrObj);
    setEmail("");
    setPassword("");
    setResetEmail("");
    setResetEmail("");
    setOtp("");
    setEmail("");
  };
  const sendEmail = () => {
    let errorObj = { ...err };
    let isErr = 0;
    const pattern = new RegExp(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
    if (!email) {
      errorObj.show = true;
      errorObj.massage = "*Email is required";
      isErr++;
    }
    // if (email && !pattern.test(email)) {
    //   errorObj.show = true;
    //   errorObj.massage = "*Invalid Email";
    //   isErr++;
    // }
    if (isErr) {
      setErr(errorObj);
    } else {
      dispatch(forgotPassword({ email: email }));
    }
  };
  const handleEmail = () => {
    let errorObj = { ...err };
    errorObj.show = false;
    errorObj.massage = "";
    setErr(errorObj);
  };
  const sendPassword = () => {
    let errorObj = { ...otpErr };
    let isErr = 0;
    if (!otp) {
      errorObj.otp.show = true;
      errorObj.otp.massage = "*Otp is required";
      isErr++;
    }
    if (otp && isNaN(otp)) {
      errorObj.otp.show = true;
      errorObj.otp.massage = "*Otp Should be Number";
      isErr++;
    }
    if (!password) {
      errorObj.password.show = true;
      errorObj.password.massage = "*Password is required";
      isErr++;
    }
    if (password && password.length < 4) {
      errorObj.password.show = true;
      errorObj.password.massage =
        "*you have to enter atleast 4 length password!";
      isErr++;
    }
    if (isErr) {
      setOtpErr(errorObj);
    } else {
      const data = {
        otp,
        newPassword: password,
        resetEmail
      };
      dispatch(resetPassword(data));
    }
  };
  const EmailModal = () => {
    return (
      <Form>
        <Form.Item className={err.show ? "isError" : ""}>
          <Input
            name="email"
            type="email"
            required
            value={email}
            placeholder="Enter Your Email"
            onChange={(e) => setEmail(e.target.value)}
            onClick={() => handleEmail()}
          />
          <p className="text-danger login-error-msg">
            {err.massage ? err.massage : ""}
          </p>
        </Form.Item>
      </Form>
    );
  };
  const OtpPopUp = () => {
    return (
      <Form>
        <Form.Item className={otpErr.otp.show ? "isError" : ""}>
          <Input
            onChange={(e) => setOtp(e.target.value)}
            type="number"
            name="otp"
            value={otp}
            placeholder="Enter OTP Code"
            required
            autoFocus
          />
          <p className="text-danger login-error-msg">
            {otpErr.otp.massage ? otpErr.otp.massage : ""}
          </p>
        </Form.Item>
        <Form.Item className={otpErr.password.show ? "isError" : ""}>
          <Input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            name="password"
            value={password}
            placeholder="Enter Password"
            required
          />
          <p className="text-danger login-error-msg">
            {otpErr.password.massage ? otpErr.password.massage : ""}
          </p>
        </Form.Item>
      </Form>
    );
  };
  const handleChange = () => {
    if (popUp === "emailPopUp") {
      sendEmail();
    } else {
      sendPassword();
    }
  };

  return (
    <Modal
      title="Forgot Password"
      visible={visibleModel}
      width="400px"
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleChange}
          className="btn-ab"
        >
          {popUp === "emailPopUp" ? "Send" : "Update"}
        </Button>
      ]}
    >
      {popUp === "emailPopUp" ? EmailModal() : OtpPopUp()}
    </Modal>
  );
};

export default ForgetEmail;
