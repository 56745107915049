import { GET_LAGER_REPORT } from "../../action/types";
const initialState = {
  data: []
};
const getLagerReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAGER_REPORT:
      return {
        ...state,
        data: action.payload.data
      };
    default:
      return state;
  }
};

export default getLagerReport;
