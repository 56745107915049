import React, { Component } from "react";
import Home from "../Home/Home";
// import './detailedReports.css';
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { notification } from "antd";

import appConfig from "../../helpers/appConfig";

class detailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentWillMount() {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/invoice`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              items: result.data
            });
            console.table(this.state.items);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  render() {
    const { error, isLoaded, items } = this.state;
    if (!isLoaded) {
      return (
        <Home>
          <Loader />
        </Home>
      );
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      items.map((item) => {
        var date = new Date(item.created);
        item.created = date.toISOString().split`T`[0];
        return item;
      });
      return (
        <Home isAdmin={true}>
          <div className="report-container-flex">
            <button className="box shadow stock-bg">
              <p className="text-items">Detailed Reports</p>
            </button>
          </div>
          <div className="different-reports">
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100">
                  <div className="table">
                    {/* <div className="row headerR">
                      <div className="cell">Date</div>
                      <div className="cell">Order Type</div>
                      <div className="cell">Invoice Number</div>
                      <div className="cell">Total Amount</div>
                    </div> */}

                    {/* {items.map(item => {
                      return (
                        <div className="row">
                          <div className="cell" data-title="productName">
                            {item.created}
                          </div>
                          <div className="cell" data-title="sales">
                            {item.orderType}
                          </div>
                          <div className="cell" data-title="price">
                            {item.invoiceNumber}
                          </div>
                          <div className="cell" data-title="totalPrice">
                            {item.price}
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const details = connect(mapStateToProps)(detailsComponent);
export default details;
