import React, { Component } from "react";
import Home from "../Home/index";
import "./BillingOK.css";
import { connect } from "react-redux";
import { Col, Row, Input, notification, Button } from "antd";
import Loader from "../../components/Loader/Loader";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FloatingButtons from "../FloatingButtons/FloatingButtons";
import IconGridList from "../../components/IconGridList/IconGridList";
import BillingForm from "../../components/BillingForm/BillingForm";
import RightMenu from "../RightMenu/RightMenu";
import history from "../../history";
import appConfig from "../../helpers/appConfig";
const confirm = Modal.confirm;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class BillingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBill: false,
      test: "abc",
      isMobile: true,
      items: [],
      categoryData: [],
      productData: [],
      filteredProducts: [],
      table: [],
      searchQuery: "",
      modalIsOpen: false,
      category: true,
      customer: "",
      tableEmail: "",
      tablePhoneNo: 0,
      type: "",
      discount: 0,
      cash: 0,
      driveoutCharge: 0,
      creditCheck: false,
      createdBy: "",
      createdOn: "",
      fields: {},
      errors: {},

      customerModalIsOpen: false,

      firstName: "",
      lastName: "",
      phoneNo: 0,
      isDeleted: false,
      _id: null,
      branchId: null,
      line1: "",
      line2: "",
      city: "",
      state: "",
      pin: "",
      country: "",
      delete_tab: false,
      savevisible: false,
      allreadyDeleted: false,
      customerunpaidInvoice: [],
      saveItemsLoading: false,
      findcustomerLoader: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleOk = (type, discount) => {
    this.setState({ type, discount });
  };

  checkCustomerEmail = (email) => {
    let hdr = localStorage.token;
    let postData = {
      customer: email,
      type: "phoneNo"
    };
    fetch(`${appConfig.endpoint}/api/v1/employee/kunder/customerbyphone`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === 0) {
          this.openNotification(
            "error",
            "Invoice already generated or something went wrong."
          );
        } else {
          var dt = result.data;
          if (dt && Object.keys(dt).length !== 0) {
            this.setState({
              customerunpaidInvoice: dt.customerunpaidInvoice,
              findcustomerLoader: false
            });
            this.setState({
              firstName: dt.customer.firstName,
              lastName: dt.customer.lastName,
              phoneNo: dt.customer.phoneNo,
              line1: dt.customer.address.line1,
              line2: dt.customer.address.line2,
              city: dt.customer.address.city,
              state: dt.customer.address.state,
              pin: dt.customer.address.pin,
              country: dt.customer.address.country
            });
          } else {
            this.setState({
              findcustomerLoader: false
            });
          }
        }
      });
  };
  searchProducts = (searchQuery) => {
    const { category, categoryData, productData } = this.state;
    let data = category ? categoryData : productData;
    let searchedProducts = data.filter((item) => {
      searchQuery = searchQuery.toLowerCase();
      item.name = item.name.toLowerCase();
      return item.name.indexOf(searchQuery) > -1;
    });
    this.setState({
      searchQuery: searchQuery,
      filteredProducts: searchedProducts
    });
  };

  saveItems = () => {
    this.setState({ saveItemsLoading: true });
    const { type, discount } = this.state;
    let hdr = localStorage.token;
    let table = localStorage.table;
    let data = JSON.stringify({
      items: this.state.items,
      type,
      discount,
      id: table
    });
    fetch(`${appConfig.endpoint}/api/v1/employee/board/updatetable`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.setState({ saveItemsLoading: false });
          this.openNotification(
            "error",
            "Invoice already generated or something went wrong."
          );
        } else {
          this.setState({ saveItemsLoading: false });
          this.openNotification(result.status, result.message);
          // this.props.history.push('/tableview');
        }
      });
  };

  openNotification = (type, message) => {
    if (message.errors) {
      if (message.errors.length) {
        message = message.errors[0].msg;
      } else {
        message = "Something went wrog, please try again";
      }
    }
    // notification[type]({
    //   message: message
    // });
  };
  deletesimul() {
    this.setState({ allreadyDeleted: true });
    this.deleteTable();
  }
  deleteconfirmDialog = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              padding: 33,
              background: "#bfbfbf",
              boxShadow: "23px 14px 18px",
              borderRadius: 13
            }}
          >
            <h1>ER DU SIKKER?</h1>
            <p>Vil du slette bord?</p>
            <button className="ant-btn leftButton shadow" onClick={onClose}>
              <i
                style={{ color: "grey", fontSize: 20 }}
                className="fa fa-times"
                aria-hidden="true"
              ></i>{" "}
              NEI
            </button>
            <button
              className="ant-btn rightButton shadow"
              onClick={() => {
                this.deletesimul();
                onClose();
              }}
            >
              <i
                style={{ color: "grey", fontSize: 20 }}
                className="fa fa-check"
                aria-hidden="true"
              ></i>{" "}
              JA
            </button>
          </div>
        );
      }
    });
  };

  findCustomer = (cust) => {
    this.setState({ findcustomerLoader: true });
    let hdr = localStorage.token;
    let table = localStorage.table;
    this.setState({ customer: cust });
    const regexNo = /^[0-9]{8,10}$/;
    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let type;
    if (cust.match(regexEmail)) type = "email";
    else if (cust.match(regexNo)) type = "phoneNo";
    else
      return this.openNotification(
        "error",
        "Please enter a valid email or phone."
      );

    let data = JSON.stringify({
      customer: cust,
      type
    });
    fetch(
      `${appConfig.endpoint}/api/v1/employee/board/customerTable/${table}`,
      {
        method: "POST",
        body: data,
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === 0) {
            this.openNotification("error", result.data);
          } else {
            let data = JSON.parse(JSON.stringify(result.data));
            let address = { ...data.address };
            delete data.address;

            this.setState({
              tableEmail: result.data.email,
              tablePhoneNo: result.data.phoneNo,

              ...data,
              ...address
            });
            this.openNotification("success", "Customer added successfully.");
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
      );
  };

  componentDidMount() {
    if (localStorage.table === null) {
      history.push("/tableview");
    }
    var li = localStorage.getItem("eid");
    if (localStorage.eid !== null) {
      this.setState({ loggedid: li });
    }
    var hdr = localStorage.token;
    var tableId = localStorage.table;
    fetch(`${appConfig.endpoint}/api/v1/employee/board/table/${tableId}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            if (
              result.data &&
              result.data.errors &&
              result.data.errors[0] &&
              result.data.errors[0].msg
            ) {
              this.openNotification("error", result.data.errors[0].msg);
            } else {
              // this.setState({ stat: result.status });
              this.openNotification("error", "Select a Table first");
              history.push("/table");
            }
          } else {
            let type = "";
            if (
              result.data.orderType === "Take away" ||
              result.data.orderType === "Drive Out"
            ) {
              type = result.data.orderType;
            } else {
              type = result.data.orderType;
            }
            let customer = { ...result.data.customerId };
            let address = { ...customer.address };
            delete customer.address;
            this.setState({
              isLoaded: true,
              table: result.data,
              items: result.data.items,
              orderType: result.data.orderType,
              tableEmail: result.data.customerId
                ? result.data.customerId.email
                : "",
              tablePhoneNo: result.data.customerId
                ? result.data.customerId.phoneNo
                : "",
              modalIsOpen: result.data.customerId ? false : true,
              createdOn: result.data.created,
              createdBy: result.data.employeeId,
              tEmpid: result.data.tEmpid,
              type,
              driveoutCharge: result.data.driveoutCharge,
              ...customer,
              line1: address.line1 || "",
              line2: address.line2 || "",
              city: address.city || "",
              state: address.state || "",
              pin: address.pin || "",
              country: address.country || ""
            });
          }
        },
        (error) => {
          this.setState({
            error
          });
        }
      );
    let taxId = localStorage.getItem("taxID");
    console.log("tax id is", taxId);
    fetch(`${appConfig.endpoint}/api/v1/employee/menu/categorybyordertype`, {
      method: "POST",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      },
      // body: JSON.stringify({'taxId':5})
      body: JSON.stringify({ taxId: taxId })
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              categoryData: result.data || []
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }
  async componentWillUnmount() {
    if (this.state.items && this.state.items.length == 0) {
      if (
        this.state.allreadyDeleted == false &&
        this.state.loggedid === this.state.tEmpid
      ) {
        this.deleteTable();
        this.state.items = [];
        this.state.allreadyDeleted = false;
      }
    } else if (this.state.productData && this.state.productData.length !== 0) {
    }
  }

  cashGiven = (cash) => {
    console.log("cashGIve CALLED", cash);
    this.setState({ cash: Number(this.state.cash) + Number(cash) });
  };

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addToBill = (e, productName) => {
    var any = Object.assign({}, this.state);
    any.productName = productName;
    this.setState(any, function () {});
  };

  addProduct(productInfo) {
    var state = Object.assign({}, this.state);
    var product = Object.assign({}, productInfo);
    var index = state.items.findIndex((x) => x.id === product.id);

    if (index > -1) {
      state.items[index].quantity++;
      state.items[index].status = "IN-PROCESS";
    } else {
      product["quantity"] = 1;
      state.items.push(product);
    }

    this.setState(state);
  }

  handleClick = (category) => {
    if (category) {
      this.productPage(category.id);
    } else {
      console.log("beep bop");
    }
  };

  productPage = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/products/getProduct/${id}`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({
              isLoaded: true,
              productData: result.data,
              category: false,
              catId: id
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  toggleform = (e) => {
    let state = Object.assign(this.state);
    state.form = !state.form;
    this.setState(state);
  };
  Closeform = (e) => {
    this.setState({ form: false });
  };
  static propTypes = {
    isBilling: true
  };

  // try not to touch
  deleted = (id) => {
    let index = this.state.items.findIndex((x) => x.id === id);
    this.setState(this.state.items.splice(index, 1));
  };

  goToCat = () => {
    this.setState({ category: true });
  };

  handleQuantity = (e, id) => {
    const newItems = this.state.items.slice();
    newItems[id].quantity = e.target.value;
    this.setState({
      items: newItems
    });
  };

  creditCheck = () => {
    this.setState({ creditCheck: true });
  };

  deleteTable = () => {
    var hdr = localStorage.token;
    let tableId = localStorage.table;
    if (this.state.tEmpid === this.state.loggedid) {
      fetch(
        `${appConfig.endpoint}/api/v1/employee/board/deleteTable/${tableId}`,
        {
          method: "DELETE",
          headers: {
            "x-auth-token": hdr
          }
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status === "failure") {
              this.openNotification("error", "Could not delete table");
            } else {
              this.openNotification(result.status, result.message);
              // history.push("/table");
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    } else if (this.state.table.isOnline) {
      fetch(
        `${appConfig.endpoint}/api/v1/employee/board/deleteTable/${tableId}`,
        {
          method: "DELETE",
          headers: {
            "x-auth-token": hdr
          }
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            if (!result.status === 200) {
              this.openNotification("error", "Could not delete table");
            } else {
              this.openNotification("success", result.message);
              history.push("/table");
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    } else {
      this.openNotification("error", "You can't delete this table");
    }
  };

  //  Customer Model
  updateCustomerModalIsOpen = async (event) => {
    let customerModalIsOpen = this.state.customerModalIsOpen;

    console.log(
      "event = ",
      event,
      `customerModalIsOpen = ${customerModalIsOpen}`
    );

    if (this.state.firstName === "") {
      console.log("checking condition");
      await this.setState({ customerModalIsOpen: !customerModalIsOpen });
      return false;
    }

    if (event === "Closed") return false;
    else {
      this.creditCheck();
      return true;
    }
  };

  showConfirm = async () => {
    confirm({
      title: "Do you want to save this table?",
      onOk: () => {
        this.openNotification("success", "Table saved successfully");
        this.saveItems();
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        console.log("Cancel");
      }
    });
  };

  updateCustomerFunc = async () => {
    let table = localStorage.table;

    let {
      tableEmail,
      firstName,
      lastName,
      phoneNo,
      reference,
      line1,
      line2,
      city,
      state,
      pin,
      country
      // table
    } = this.state;
    let hdr = localStorage.token;

    let data = JSON.stringify({
      customer: tableEmail,
      type: "email",
      firstName,
      lastName,
      phoneNo,
      reference,
      // _id,
      line1,
      line2,
      city,
      state,
      pin,
      country
    });

    fetch(
      `${appConfig.endpoint}/api/v1/employee/board/customerTable/${table}`,
      {
        method: "POST",
        body: data,
        headers: {
          "x-auth-token": hdr,
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
            this.setState({ isLoaded: true });
            return;
          }

          this.openNotification("success", "Customer updated successfully.");

          let data = JSON.parse(JSON.stringify(result.data));
          let address = { ...data.address };
          delete data.address;
          this.creditCheck();
          this.setState({
            tableEmail: result.data.email,
            tablePhoneNo: result.data.phoneNo,
            isLoaded: true,
            customerModalIsOpen: false,
            ...data,
            ...address
          });

          return;
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  //  Customer Model

  render() {
    const {
      error,
      isLoaded,
      cash,
      table,
      tableEmail,
      tablePhoneNo,
      type,
      discount,
      createdBy
    } = this.state;
    if (!isLoaded) {
      return (
        <Home>
          <Loader />
        </Home>
      );
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <Home>
          <Row gutter={20} className="billing d-flex justify-content-between">
            <Col span={9} className="bill-container">
              <div className="table-number">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <span>
                    <span className="left-text small">Email: {tableEmail}</span>
                    <br />
                    <span className="left-text small">
                      Phone: {tablePhoneNo}
                    </span>
                  </span>
                  <span className="right-text">
                    {table.number} / Table
                    <i
                      style={{
                        padding: 5,
                        marginLeft: 10,
                        width: 30,
                        height: 30,
                        textAlign: "center",
                        lineHeight: "20px",
                        borderRadius: "50%",
                        background: "#444",
                        color: "#fefefe"
                      }}
                      className="fa fa-trash"
                      aria-hidden="true"
                      onClick={this.deleteconfirmDialog}
                    />
                  </span>
                </div>
              </div>

              <BillingForm
                orderType={this.state.orderType}
                loaded={this.state.isLoaded}
                clicked={this.saveItems}
                saveItemsLoading={this.state.saveItemsLoading}
                tax={table.tax}
                product={this.state.items ? this.state.items : []}
                delete={(id) => this.deleted(id)}
                changeQuantity={(e, id) => this.handleQuantity(e, id)}
                type={type}
                discount={discount}
                stat={this.state.stat}
                cash={cash}
                creditCheck={this.state.creditCheck}
                createdBy={this.state.createdBy}
                createdOn={this.state.createdOn}
                driveoutCharge={this.state.driveoutCharge}
              />
            </Col>
            <Col span={14}>
              <Row className="ant-input-search order-search ant-input-search-enter-button d-flex ant-input-affix-wrapper justify-content-between align-items-center">
              <Input
                  style={{width: '50%', border: '1px solid'}}
                    placeholder="Search"
                    className="ant-input"
                    type="text"
                    value={this.state.searchQuery}
                    onChange={(e) => this.searchProducts(e.target.value)}
                  />
                  {!this.state.category ? (
                    <div onClick={this.goToCat} className="back-button"    style={{width: 'auto'}}>
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ color: "#b59b56" }}
                        aria-hidden="true"
                      />
                      <span style={{ color: "#b59b56" }}>Back</span>
                    </div>
                  ) : null}
              </Row>
              <Row className="dashboard-flex billing">
                {this.state.category ? (
                  <IconGridList
                    isBilling={true}
                    type={this.state.type}
                    products={
                      this.state.searchQuery === ""
                        ? this.state.categoryData
                        : this.state.filteredProducts
                    }
                    handleClick={(categories) => this.handleClick(categories)}
                    category={this.state.category}
                  />
                ) : (
                  <IconGridList
                    isBilling={false}
                    type={this.state.type}
                    products={
                      this.state.searchQuery === ""
                        ? this.state.productData
                        : this.state.filteredProducts
                    }
                    handleClick={(productInfo) => this.addProduct(productInfo)}
                    category={this.state.category}
                  />
                )}
                {this.state.searchQuery &&
                this.state.filteredProducts.length === 0 ? (
                  <div>No products found</div>
                ) : (
                  ""
                )}
              </Row>
              {/* <Col className="bottom-bar">
                <FloatingButtons dispatch={this.props.dispatch} />
              </Col> */}
              <Row>
                <RightMenu
            // style={{ marginTop: "-10px" }}
            product={this.state.items}
            productData={this.state.productData}
            handleOk={(type, value) => this.handleOk(type, value)}
            cashGiven={(cash) => this.cashGiven(cash)}
            type={type}
            discount={discount}
            customerunpaidInvoice={this.state.customerunpaidInvoice}
            cash={cash}
            checkCustomerEmail={this.checkCustomerEmail}
            findcustomerLoader={this.state.findcustomerLoader}
            orderType={this.state.orderType}
            saveItems={this.saveItems}
            creditCheck={this.state.creditCheck}
            createdBy={this.state.createdBy}
            createdOn={this.state.createdOn}
            driveoutCharge={this.state.driveoutCharge}
            clickedCredit={this.creditCheck}
            updateCustomerModalIsOpen={this.updateCustomerModalIsOpen}
            findCustomer={this.findCustomer}
          />
              </Row>
            </Col>
          </Row>

          <Modal
            isOpen={false}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.toggleModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Customer"
          >
            <div className="button-container">
              <button onClick={this.toggleModal} className="close-button">
                X
              </button>
            </div>
            <form className="add-employee">
              <li>
                <center>
                  <h2>Customer Details</h2>
                </center>
              </li>
              <li>
                <input
                  onChange={this.handleChange}
                  name="customer"
                  type="text"
                  className="input2"
                  placeholder="E-mail or Phone No."
                />
              </li>
              <li>
                <button
                  onClick={(e) => this.findCustomer(e)}
                  type="submit"
                  id="submit-landing2"
                >
                  Add Customer
                </button>
              </li>
            </form>
          </Modal>

          <Modal
            isOpen={this.state.customerModalIsOpen}
            onRequestClose={() => this.updateCustomerModalIsOpen("Closed")}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Customer"
          >
            <div className="button-container">
              <button
                onClick={() => this.updateCustomerModalIsOpen("Closed")}
                className="close-button"
              >
                X
              </button>
            </div>
            <form className="add-employee add-customer">
              <li>
                <center>
                  <h2>Edit Customer</h2>
                </center>
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="firstName"
                  className="input2"
                  required={true}
                  placeholder="First Name"
                  value={this.state.firstName}
                />
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="lastName"
                  className="input2"
                  placeholder="Last Name"
                  value={this.state.lastName}
                />
              </li>
              <li>
                <input
                  type="email"
                  onChange={this.handleChange}
                  name="tableEmail"
                  className="input2"
                  placeholder="E-mail"
                  onBlur={() => this.checkCustomerEmail(this.state.tableEmail)}
                  value={this.state.tableEmail}
                />
              </li>
              <li>
                <input
                  type="number"
                  onChange={this.handleChange}
                  name="phoneNo"
                  className="input2"
                  min="0000000000"
                  max="9999999999"
                  required
                  maxLength="10"
                  pattern="[0-9]"
                  placeholder="Phone No."
                  value={this.state.phoneNo === 0 ? "" : this.state.phoneNo}
                />
              </li>
              <li>
                <textarea
                  onChange={this.handleChange}
                  name="reference"
                  placeholder="Skriv inn referanse"
                  className="input2"
                  value={this.state.reference}
                ></textarea>
              </li>
              <p>Address</p>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line1"
                  className="input2"
                  placeholder="Address Line 1"
                  value={this.state.line1}
                />
              </li>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line2"
                  className="input2"
                  placeholder="Address Line 2"
                  value={this.state.line2}
                />
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="city"
                  className="input2"
                  placeholder="City"
                  value={this.state.city}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="number"
                  onChange={this.handleChange}
                  name="pin"
                  className="input2"
                  placeholder="PIN"
                  value={this.state.pin}
                />
              </li>
              <li>
                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 2% 0 8%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="state"
                  className="input2"
                  placeholder="State"
                  value={this.state.state}
                />

                <input
                  style={{
                    maxWidth: "40%",
                    float: "left",
                    margin: "0 8% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="country"
                  className="input2"
                  placeholder="Country"
                  value={this.state.country}
                />
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => this.updateCustomerFunc()}
                  id="submit-landing2"
                >
                  Submit
                </button>
              </li>
            </form>
          </Modal>
          
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const BillingOk = connect(mapStateToProps)(BillingComponent);
export default BillingOk;
