import React, { Component, Fragment } from "react";
import ReactToPrint from "react-to-print";
import Home from "../Home/Home";
import "./taccountant.css";
import { Icon as RIcon } from "react-icons-kit";
import {bank} from 'react-icons-kit/fa/bank'
import {fileTextO} from 'react-icons-kit/fa/fileTextO'
import {dollar} from 'react-icons-kit/fa/dollar'
import { connect } from "react-redux";
import {
  DatePicker,
  notification,
  Table,
  Button,
  Select,
  Statistic,
  Card,
  Row,
  Col,
  Divider,
  Modal,
  Radio,
  Input
} from "antd";

import TableSum from "../TableSum/TableSum";
import TableSumPag from "../TableSum/TableSumPag";
import dragdropimg from "../../assets/3DragandDrop.png";
import { push } from "react-router-redux";
import PrintReport from "../PrintReport/PrintReport";
import moment from "moment";
import SingleInvoice from "../SingleInvoice/SingleInvoice";
import appConfig from "../../helpers/appConfig";
const { MonthPicker, WeekPicker } = DatePicker;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;

class taccountantComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      sendProps: false,
      currentPage: "buttons",
      reportItems: [],
      flatDiscountInvoices: [],
      items: [],
      tax: "",
      billData: "",
      paginationNo: 1,
      unitPriceTotal: 0,
      total15Tax: 0,
      total25Tax: 0,
      total15Quantity: 0,
      total25Quantity: 0,
      finalTotal: 0,
      totalSubtotal: 0,
      totalTax: 0,
      totalTaxPrice: 0,
      totalDiscount: 0,
      totalExtra: 0,
      orders: 0,
      dcount: 0,
      apiData: null,
      groupedInvoicesplu1: [],
      groupedInvoicesplu2: [],
      invoiceitems: [],
      dueItems: [],
      employeeTipData: [],
      plu2discountData: [],
      plu1discountData: [],
      unpaidtax15_total: 0,
      unpaidtax25_total: 0,
      discountInvoices: [],
      plu1discountPriceTotal: 0,
      plu2discountPriceTotal: 0,
      plu1FlatdiscountPriceTotal: 0,
      plu2FlatdiscountPriceTotal: 0,
      totalCash: 0,
      totalBank: 0,
      totalUnpaidAmount: 0,
      onlineorderTotalAmount: 0,
      tipTotal: 0,
      totalcashboxopen: 0,
      fileUploadState: "",
      plu1FlatDiscountData: [],
      plu2FlatDiscountData: [],
      showuploadpdfModal: false,
      pdfStatus: "",
      selectedFile: null,
      pdfName: "",
      pageTitle: "",
      filterDate: "",
      cashTipSum: 0,
      bankTipSum: 0,
      groupDate: "",
      printLoader: false
    };
  }

  componentDidMount = () => {
    let date = moment().date()._d;
    this.loadReports(date);
    this.redlistProducts();
    this.groupedInvoice(date);
    this.loadUnpaidInvoices(date);
    this.groupedDiscount(date);
    //this.getFlatGroupedDiscount(date);
    this.groupedCashBank(date);
    this.cahboxCounter(date);
    this.employeesTip(date);
    this.groupedUnpaidCashBankFlag(date);
    this.groupedOnlineorderTotal(date);
  };

  printGroupInvoice = () => {
    this.setState({ printLoader: true });
    const postData = this.state.groupDate;
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/prcat/printGroupReport`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ printLoader: false });
        window.open(result.report, "_blank");
      });
  };
  printbill = () => {
    window.print();
  };
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };

  handlePageChange = (page, title) => {
    this.setState({ currentPage: page, pageTitle: title });
  };
  handlePageChangerap = (page, title) => {
    let date = moment().date()._d;
    this.loadReports(date);
    this.setState({ currentPage: page, pageTitle: title, title: page });
  };

  pageChange = async (pagination) => {
    if (pagination) {
      await this.setState({ paginationNo: pagination.current });
      let nDate = await this.state.filterDate;
      nDate = (await (typeof nDate === "undefined")) ? Date.now() : nDate;
      // await this.onChange(this.state.title, nDate, "");
    }
  };

  //   ======================= reports function from reports =========================

  handleClick = (title, pageTitle) => {
    if (title === "BANK REPORTS" || title === "TFL REPORTS") {
      this.setState({ currentPage: "buttons" });
    } else {
      this.setState(
        {
          items: [],
          currentPage: title,
          title,
          pageTitle
        },
        this.sendDate(title)
      );
    }
    // const tDate = title == "YEARLY REPORTS"?  new Date().getFullYear():"";
    // this.setState({ paginationNo: 1,filterDate:tDate});
  };

  onFilterChange = async (title, date, dateString) => {
    await this.setState({ paginationNo: 1 });
    await this.onChange(title, date, dateString);
  };

  onChange = (title, date, dateString) => {
    this.setState({ filterDate: date });
    let startDate, endDate;
    switch (title) {
      case "GROUPED-INVOICE":
        return this.groupedInvoice(date);
      case "WEEKLY REPORTS":
        startDate = moment(date).startOf("week");
        endDate = moment(date).endOf("week");
        return this.getInvoices(title, startDate, endDate);
      case "MONTHLY REPORTS":
        startDate = moment(date).startOf("month");
        endDate = moment(date).endOf("month");
        return this.getInvoices(title, startDate, endDate);
      case "YEARLY REPORTS":
        var start = new Date("1/1/" + date);
        startDate = moment(start.valueOf());
        var end = new Date("12/31/" + date);
        endDate = moment(end.valueOf());
        return this.getInvoices(title, startDate, endDate);
      default:
        return "error";
    }
  };

  sendDate = (title) => {
    let startDate, endDate, date;
    this.setState({ filterDate: moment().date()._d });
    switch (title) {
      case "DAILY REPORTS":
        startDate = moment().date()._d;
        endDate = moment().date()._d;
        return this.getInvoices(title, startDate, endDate);
      case "WEEKLY REPORTS":
        // startDate = moment().startOf('week')._d;
        // endDate = moment().endOf('week')._d;
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        return this.getInvoices(title, startDate, endDate);
      case "MONTHLY REPORTS":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        return this.getInvoices(title, startDate, endDate);
      case "YEARLY REPORTS":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        return this.getInvoices(title, startDate, endDate);
      case "X-REPORTS":
        date = moment().date()._d;
        return this.onOk(date);
      case "ONLINE ORDERS":
        return this.getOnlineOrders();
      case "CASH REPORTS":
        return this.getCashReports();
      default:
        return 0;
    }
  };

  getInvoices = (title, startDate, endDate) => {
    let data;
    let pageNo = this.state.paginationNo;
    if (title === "DAILY REPORTS") {
      data = JSON.stringify({
        startDate: moment(startDate).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(endDate).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        }),
        pageNo: pageNo
      });
    } else if (title !== "X-REPORTS") {
      data = JSON.stringify({
        startDate: moment(startDate).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(endDate).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        }),
        pageNo: pageNo
      });
    } else {
      data = JSON.stringify({
        startDate: moment(startDate),
        endDate: moment(endDate),
        pageNo: pageNo
      });
    }

    this.setState({
      isLoaded: false,
      apiData: data
    });

    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/invoice`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            //! Magic, Do Not Touch
            let branchInfo = result.data.branch;
            let totalDocs = result.data.invoiceData.totalDocs;
            let pageLimit = result.data.invoiceData.limit;
            let curPageNo = result.data.invoiceData.page;
            let data = result.data.invoiceData.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(2),
              isLoaded: true,
              key: item._id
            }));
            let invoiceToLoad = data.reverse();
            this.setState({
              isLoaded: true,
              items: invoiceToLoad,
              branchInfo,
              sendProps: true,
              totalDocs,
              paginationNo: curPageNo,
              pageLimit
            });

            let totalSubtotal = 0;
            let totalTax = 0;
            let totalTaxPrice = 0;
            let totalDriveoutCharge = 0;
            result.data.invoiceData.forEach((current, index) => {
              current.taxPrice = parseFloat(current.taxPrice);
              current.tax = parseFloat(current.tax);
              current.driveoutCharge = parseFloat(current.driveoutCharge || 0);
              current.totalPrice = parseFloat(current.totalPrice || 0);

              totalSubtotal =
                totalSubtotal +
                (current.taxPrice -
                  (current.taxPrice -
                    (100 * current.taxPrice) / (100 + current.tax)));

              totalTax =
                totalTax +
                (current.taxPrice -
                  (100 * current.taxPrice) / (100 + current.tax));

              if (current.orderType === "Drive Out")
                totalDriveoutCharge =
                  totalDriveoutCharge + current.driveoutCharge;

              totalTaxPrice = totalTaxPrice + current.totalPrice;
            });

            totalSubtotal = parseFloat(totalSubtotal.toFixed(3));
            totalTax = parseFloat(totalTax.toFixed(3));
            totalDriveoutCharge = parseFloat(totalDriveoutCharge.toFixed(3));
            totalTaxPrice = parseFloat(totalTaxPrice.toFixed(3));

            this.setState({
              totalSubtotal,
              totalTax,
              totalDriveoutCharge,
              totalTaxPrice
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  //   ======================= END reports function from reports =========================

  printReportHandler = async () => {
    let { apiData, title } = this.state;

    if (!apiData) {
      apiData = JSON.stringify({
        startDate: moment().set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment().add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    }

    apiData = JSON.parse(apiData);
    if (apiData.pageNo) {
      delete apiData.pageNo;
    }
    apiData.title = this.state.pageTitle;
    let type = "P"; // Normal Reports
    if (
      title === "WEEKLY REPORTS" ||
      title === "MONTHLY REPORTS" ||
      title === "YEARLY REPORTS"
    )
      type = "N";
    // X Reports
    // else if (title === "CASH REPORTS") type = "C"; //  Cash Reports

    await this.setState({
      isLoaded: false
    });

    let result = await fetch(
      `${appConfig.endpoint}/api/v1/employee/reports/print`,
      {
        method: "POST",
        body: JSON.stringify({
          type,
          ...apiData
        }),
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "application/json"
        }
      }
    ).then((res) => res.json());

    await this.setState({
      isLoaded: true
    });

    if (result.success === -1) {
      // Not Logged In
      this.openNotification("error", "Please login again to continue");
      this.props.history.push("/");
      return;
    } // Not Logged In

    if (result.success !== 1) {
      this.openNotification("error", result.message);
      return;
    }

    this.openNotification("success", "Report Printed successfully");

    window.open(result.data.url, "_blank");
  };

  redlistProducts = () => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/stockredlist`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ redProducts: result.data });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  searchYear = async (e) => {
    this.setState({ filterDate: e.key });
    let startDate = moment(e.key);
    await this.setState({ paginationNo: 1 });
    let endDate = moment(startDate).endOf("year");
    return await this.getInvoices("YEARLY REPORTS", startDate, endDate);
  };
  handleSwitch = (title) => {
    switch (title) {
      case "GROUPED-INVOICE":
        return (
          <div>
            <span style={{ position: "absolute", marginLeft: "38%" }}>
              <DatePicker
                onChange={(date, dateString) => (
                  this.groupedInvoice(date), //done
                  this.loadUnpaidInvoices(date), // done
                  this.loadReports(date),
                  this.groupedDiscount(date),
                  this.groupedCashBank(date), //done
                  this.cahboxCounter(date), // done
                  this.employeesTip(date), // done
                  this.groupedUnpaidCashBankFlag(date), // done
                  this.groupedOnlineorderTotal(date) // done
                )}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>
            <span style={{ position: "absolute", marginLeft: "63%" }}>
              <MonthPicker
                onChange={(date, dateString) =>
                  this.employeesTip(date, "month")
                }
                placeholder="Select month"
              />
            </span>
            {/* <MonthPicker
              style={{ float: "left" }}
              onChange={(date, dateString) => (
                this.groupedInvoice(date),
                this.loadUnpaidInvoices(date),
                this.loadReports(date),
                this.groupedDiscount(date),
                this.groupedCashBank(date)
              )}
              placeholder="Select month"
            /> */}
          </div>
        );
      case "WEEKLY REPORTS":
        return (
          <WeekPicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            placeholder="Select week"
          />
        );
      case "MONTHLY REPORTS":
        return (
          <MonthPicker
            onChange={(date, dateString) =>
              this.onFilterChange(title, date, dateString)
            }
            placeholder="Select month"
          />
        );
      case "YEARLY REPORTS":
        const currentYear = new Date().getFullYear();
        const arr = [];
        for (let i = 2018; i <= currentYear; i++) {
          arr.push(i.toString());
        }
        return (
          <>
            <Select
              labelInValue
              defaultValue={{ key: currentYear }}
              style={{ width: 120 }}
              onChange={(e) => this.searchYear(e)}
            >
              {arr.map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </>
        );
      case "X-REPORTS":
        return (
          /* <DatePicker
            onChange={(date, dateString) => this.onOk(date, dateString)}
            format="YYYY-MM-DD"
            placeholder="Select day"
          /> */
          <span>Today's Reports</span>
        );
      // case 'X-REPORTS':
      //   return (
      //     <RangePicker
      //       showTime={{ format: 'HH:mm' }}
      //       format="YYYY-MM-DD HH:mm"
      //       placeholder={['Start Time', 'End Time']}
      //       onOk={date => this.onOk(date)}
      //     />
      //   );
      default:
        return "";
    }
  };

  // start of tip reports of employees

  employeesTip = (date, type) => {
    this.setState({ isLoaded: false, paginationNo: 1 });
    var hdr = localStorage.token;
    if (type == "month") {
      var postData = JSON.stringify({
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    } else {
      var postData = JSON.stringify({
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    }

    fetch(`${appConfig.endpoint}/employeestip`, {
      method: "POST",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.data) {
          let sum = 0,
            cashTipSum = 0,
            bankTipSum = 0;
          result.data.forEach((item) => {
            sum = Number(sum) + Number(item.total);
            cashTipSum = Number(cashTipSum) + Number(item.cash);
            bankTipSum = Number(bankTipSum) + Number(item.bank);
          });
          this.setState({
            employeeTipData: result.data,
            tipTotal: sum,
            cashTipSum: cashTipSum,
            bankTipSum: bankTipSum
          });
        } else {
          this.setState({
            employeeTipData: [],
            tipTotal: 0,
            cashTipSum: 0,
            bankTipSum: 0
          });
        }
      });
  };
  getFlatGroupedDiscount = (date, type) => {
    // var plu1DiscountData = [],
    //   plu2DiscountData = [];
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/groupedflatdiscount`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        let flatdis_sum = 0;
        let plu1_flat_dis_sum = 0;
        let plu1FlatDiscountData = [],
          plu2FlatDiscountData = [];
        if (result.status == 200) {
          result.data.forEach((dis) => {
            if (
              dis.orderType === "Take away" ||
              dis.orderType === "Drive Out"
            ) {
              flatdis_sum += dis.flatDiscount;
              plu2FlatDiscountData.push(dis);
            } else {
              plu1_flat_dis_sum += dis.flatDiscount;
              plu1FlatDiscountData.push(dis);
            }
          });

          this.setState({
            flatDiscountInvoices: result.data,
            plu1FlatDiscountData,
            plu2FlatDiscountData,
            plu2FlatdiscountPriceTotal: flatdis_sum,
            plu1FlatdiscountPriceTotal: plu1_flat_dis_sum
          });
        } else {
          this.setState({
            flatDiscountInvoices: [],
            plu1FlatDiscountData: [],
            plu2FlatDiscountData: [],
            plu2FlatdiscountPriceTotal: 0,
            plu1FlatdiscountPriceTotal: 0
          });
        }
      });
  };

  groupedDiscount = (date, type) => {
    // var plu1DiscountData = [],
    //   plu2DiscountData = [];
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/groupeddiscount`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        let dis_sum = 0,
          plu1_dis_sum = 0;
        let plu1DiscountData = [],
          plu2DiscountData = [];
        if (result.status == 200) {
          result.data.forEach((dis) => {
            if (
              dis.orderType === "Take away" ||
              dis.orderType === "Drive Out"
            ) {
              dis_sum += dis.discountPrice;
              plu2DiscountData.push(dis);
            } else {
              plu1_dis_sum += dis.discountPrice;
              plu1DiscountData.push(dis);
            }
          });
          this.setState({
            discountInvoices: result.data,
            plu1discountData: plu1DiscountData,
            plu2discountData: plu2DiscountData,
            plu2discountPriceTotal: dis_sum,
            plu1discountPriceTotal: plu1_dis_sum
          });
        } else {
          this.setState({
            discountInvoices: [],
            plu1discountData: [],
            plu2discountData: [],
            plu2discountPriceTotal: 0,
            plu1discountPriceTotal: 0
          });
        }
      });
  };

  groupedCashBank = (date, type) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/totalcashbank`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          totalCash: result.data ? Number(result.data[0].cash) : 0,
          totalBank: result.data ? result.data[0].bank : 0
        });
      });
  };

  groupedUnpaidCashBankFlag = (date, type) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/unpaidflagcashbank`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        // let dis_sum = 0;
        // result.data.forEach(dis => {
        //   dis_sum += dis.discountPrice;
        // });
        this.setState({
          totalUnpaidAmount: result.data ? Number(result.data[0].totalPrice) : 0
          // totalUnpaidBank: result.data ? result.data[0].bank : 0
        });
      });
  };

  groupedOnlineorderTotal = (date, type) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/groupedonlinetotal`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        // let dis_sum = 0;
        // result.data.forEach(dis => {
        //   dis_sum += dis.discountPrice;
        // });
        this.setState({
          onlineorderTotalAmount: result.data
            ? Number(result.data[0].onlineTotal)
            : 0
          // totalUnpaidBank: result.data ? result.data[0].bank : 0
        });
      });
  };

  cahboxCounter = (date, type) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postData = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postData = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/prcat/totalcashboxcounter`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          totalcashboxopen: result.data ? result.data[0].totalcashreport : 0
        });
        // let dis_sum = 0;
        // result.data.forEach(dis => {
        //   dis_sum += dis.discountPrice;
        // });
        // this.setState({
        //   totalCash: result.data ? result.data[0].cash : 0,
        //   totalBank: result.data ? result.data[0].bank : 0
        // });
      });
  };

  groupedInvoice = (date, type) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    if (type == "month") {
      var postdata = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
      let printData = {
        ...postdata,
        type: type
      };
      this.setState({ groupDate: printData });
    } else {
      var postdata = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
      let printData = {
        ...postdata,
        type: "day"
      };
      this.setState({ groupDate: printData });
    }

    fetch(`${appConfig.endpoint}/prcat/getgroupedreport`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          var dd2 = [];
          var dd1 = [];
          let plu1qty = 0,
            plu1Total = 0,
            plu1taxprice = 0,
            tmpplu1 = 0,
            plu1netprice = 0;
          let plu2qty = 0,
            plu2Total = 0,
            plu2taxprice = 0,
            tmpplu2 = 0,
            plu2netprice = 0;
          let plu1Data = result.plu1Data;
          let plu2Data = result.plu2Data;

          this.setState({ groupedInvoicesplu1: plu1Data });
          this.setState({ groupedInvoicesplu2: plu2Data });
          result.plu1Data.forEach((element) => {
            plu1qty += element.quantity;

            plu1Total += element.price;
            tmpplu1 = element.price - element.price / 1.25;
            plu1taxprice += tmpplu1;
          });
          // plu1taxprice = parseFloat(plu1Total) * parseFloat(0.25);
          plu1netprice = parseFloat(plu1Total) - parseFloat(plu1taxprice);
          result.plu2Data.forEach((element) => {
            plu2qty += element.quantity;

            plu2Total += element.price;
            tmpplu2 = element.price - element.price / 1.15;
            plu2taxprice += tmpplu2;
          });
          // plu2taxprice = plu2Total * 1.15;
          plu2netprice = parseFloat(plu2Total) - parseFloat(plu2taxprice);
          this.setState({ isLoaded: true });
          this.setState({
            plu1TotalQuantity: plu1qty,
            plu1Total: plu1Total,
            plu1taxprice: plu1taxprice,
            plu1netprice: plu1netprice,

            plu2TotalQuantity: plu2qty,
            plu2Total: plu2Total,
            plu2taxprice: plu2taxprice,
            plu2netprice: plu2netprice
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  loadUnpaidInvoices = (date, type) => {
    this.setState({ isLoaded: false });
    const hdr = localStorage.token;
    if (type == "month") {
      var postdata = {
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    } else {
      var postdata = {
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      };
    }
    fetch(`${appConfig.endpoint}/dtwiseunpaidinvoice`, {
      method: "POST",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postdata)
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            let unpaid;
            let data = result.data.invoice.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(3),
              isLoaded: true
              // unpaidtax15_total:parseFloat(item.taxPrice) * parseFloat(.15)
            }));
            var tax15totalunpaid = 0,
              tax25totalunpaid = 0,
              totunpaid = 0,
              nettotunpaid = 0,
              nett = 0;
            result.data.invoice.map((item) => {
              if (item.orderType == "Drive Out") {
                totunpaid +=
                  item.taxPrice + this.state.branchInfo.driveoutCharge;
              } else {
                totunpaid += item.taxPrice;
              }

              if (item.tax == "15") {
                let tax15 = (
                  parseFloat(item.taxPrice) -
                  parseFloat(item.taxPrice) / parseFloat(1.15)
                ).toFixed(3);
                tax15totalunpaid = (
                  parseFloat(tax15totalunpaid) + parseFloat(tax15)
                ).toFixed(3);
                nett = parseFloat(item.taxPrice) - parseFloat(tax15);
                nettotunpaid += nett;
              } else {
                let tax25 = (
                  parseFloat(item.taxPrice) -
                  parseFloat(item.taxPrice) / parseFloat(1.25)
                ).toFixed(3);
                tax25totalunpaid = (
                  parseFloat(tax25totalunpaid) + parseFloat(tax25)
                ).toFixed(3);
                nett = parseFloat(item.taxPrice) - parseFloat(tax25);
                nettotunpaid += nett;
              }
            });
            this.setState({
              tax15totalunpaid: tax15totalunpaid,
              tax25totalunpaid: tax25totalunpaid,
              totunpaid: totunpaid,
              nettotunpaid: nettotunpaid
            });
            let invoiceData = data.reverse();

            let dueData = result.data.invoice.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(3),
              isLoaded: true
            }));
            let dueInvoiceData = dueData.reverse();

            this.setState({
              invoiceitems: invoiceData,
              dueItems: dueInvoiceData,
              branchInfo: result.data.branch,
              isLoaded: true
            });
          }
        },
        (error) => {
          this.setState({
            error,
            isLoaded: true
          });
        }
      );
  };

  loadReports = (date, type) => {
    this.setState({ today: date });
    if (type == "month") {
      var data = JSON.stringify({
        startDate: moment(date).startOf("month").set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).endOf("month").add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    } else {
      var data = JSON.stringify({
        startDate: moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        }),
        endDate: moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })
      });
    }
    this.setState({
      isLoaded: false,
      apiData: data
    });

    // let startDate, endDate;
    // startDate = moment(date[0]);
    // endDate = moment(date[1]);
    // this.getInvoices('X-REPORTS', date[0], date[1]);x
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/report`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            // // Magic, Do Not Touch
            // let data = result.data.map(item => ({
            //   ...item,
            //   createdDate: new Date(item.created).toLocaleDateString(),
            //   createdTime: new Date(item.created).toLocaleTimeString(),
            //   taxPrice: 'Kr.' + item.taxPrice.toFixed(3)
            // }));
            // var invoiceToLoad = data.reverse();
            let unitPriceTotal = result.data.reportData.reduce(
              (acc, record, index) => {
                return (
                  acc +
                  (record.total -
                    (record.taxPrice15 && record.taxPrice25
                      ? record.taxPrice15 + record.taxPrice25
                      : record.taxPrice15
                      ? record.taxPrice15
                      : record.taxPrice25))
                  // current.total -
                  // (current.taxPrice15 ? current.taxPrice15 : current.taxPrice25)
                );
              },
              0
            );

            let finalTotal = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.total;
              },
              0
            );

            let total15Quantity = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.unitoftax15;
              },
              0
            );

            let total25Quantity = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.unitoftax25;
              },
              0
            );

            let total25Tax = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.taxPrice25;
              },
              0
            );

            let total15Tax = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.taxPrice15;
              },
              0
            );

            let totalDiscount = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + current.discountPrice;
              },
              0
            );
            let totalExtra = result.data.reportData.reduce(
              (acc, current, index) => {
                return acc + (current.extraPrice ? current.extraPrice : 0);
              },
              0
            );
            //  finalTotal = finalTotal - result.data.totalFlatDiscount;
            this.setState({
              isLoaded: true,
              sendProps: true,
              dcount: result.data.dcount ? result.data.dcount : "",
              tatalOrdersPrice: result.data.tatalOrdersPrice
                ? result.data.tatalOrdersPrice
                : 0,
              items: result.data.reportData,
              tax: result.data.tax,
              driveoutCharge:
                result.data.branch.driveoutCharge * result.data.dcount,
              // unitPriceTotal,
              branchInfo: result.data.branch,
              totalDiscount,
              totalExtra,
              finalTotal,
              total15Quantity,
              total25Quantity,
              total25Tax,
              total15Tax,
              unitPriceTotal
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  goBackButton = () => {
    this.setState({ currentPage: "buttons", paginationNo: 1, filterDate: "" });
  };
  showInvoice = (invoiceNumber) => {
    let index = this.state.items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    // this.handlePageChange("INVOICE");
    this.setState({
      currentPage: "INVOICE PAGE",
      billData: this.state.items[index]
    });
  };

  openpdfmodal = () => {
    // alert("here");
    this.setState({ showuploadpdfModal: true });
    // this.fileUploadButton
  };
  // handleOk = e => {
  //   this.uploadPdfuploadPdf
  // };

  handleCancel = (e) => {
    this.setState({
      showuploadpdfModal: false,
      pdfName: "",
      pdfStatus: ""
    });
  };

  pdfType = (e) => {
    let val = e.target.value;
    this.setState({ pdfStatus: val });
  };
  fileUploadButton = () => {
    document.getElementById("fileButton").click();
    document.getElementById("fileButton").onchange = () => {
      this.setState({
        fileUploadState: document.getElementById("fileButton").value
      });
    };
  };
  uploadPdf() {
    var brid = localStorage.bid;
    var hdr = localStorage.token;
    const postData = new FormData();
    postData.append("pdf", this.state.selectedFile);
    postData.append("branchId", brid);
    postData.append("status", this.state.pdfStatus);
    postData.append("fileName", this.state.pdfName);
    if (this.state.pdfStatus && this.state.selectedFile && this.state.pdfName) {
      fetch(`${appConfig.endpoint}/restreportuploadpdf`, {
        method: "POST",
        body: postData,
        headers: {
          "x-auth-token": hdr
        }
      })
        .then((res) => res.json())
        .then((result) => {
          this.openNotification("success", "Bill Uploaded");
          this.setState({ showuploadpdfModal: false });
        });
    } else {
      this.openNotification("error", "Please fill all fields");
    }
  }

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    });
  };

  render() {
    const { currentPage, isLoaded, title } = this.state;
    const dailyColumns = [
      {
        title: "FAKTURA NR.",
        dataIndex: "invoiceNumber",
        // key: "invoiceNumber",
        width: "5%",
        render: (text, record) => {
          return record.isPaid == false ? (
            <p style={{ color: "red" }}>{text}</p>
          ) : (
            text
          );
        }
      },
      {
        title: "Dato",
        dataIndex: "createdDate",
        key: "createdDate",
        width: "5%"
      },
      {
        title: "Tid",
        dataIndex: "createdTime",
        key: "createdTime",
        width: "12%"
      },
      {
        title: "Ordre Type",
        dataIndex: "orderType",
        key: "orderType",
        width: "10%"
      },

      {
        title: "Sub-Total",
        width: "15%",
        render: (text, record) => {
          return `Kr.${(
            record.taxPrice -
            (
              record.taxPrice -
              (100 * record.taxPrice) / (100 + record.tax)
            ).toFixed(3)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Mva",
        width: "11%",
        render: (text, record) => {
          return `Kr.${(
            record.taxPrice -
            (100 * record.taxPrice) / (100 + record.tax)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Utkjøring",
        width: "12%",
        render: (text, record) => {
          if (record.orderType == "Drive Out") {
            return `Kr. ${record.driveoutCharge || 0}`;
          } else {
            return `Kr. 0`;
          }
        }
      },
      {
        title: "Total",
        dataIndex: "totalPrice",
        key: "totalPrice",
        width: "15%",
        render: (text, record) => {
          return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
        }
      },
      {
        title: "Faktura",
        key: "_id",
        width: "9%",
        render: (text, record) => (
          <Button onClick={() => this.showInvoice(record.invoiceNumber)}>
            View
          </Button>
        )
      }
    ];

    const dailySummaryColumns = [
      {
        title: "emp",
        width: "10.3%",
        render: () => ``
      },
      {
        title: "Total",
        width: "13.8%",
        render: () => <strong>Total</strong>
      },
      {
        title: "emp",
        width: "12%"
      },
      {
        title: "emp",
        width: "9.4%"
      },
      // {
      //   title: "emp",
      //   width: "6%"
      // },
      {
        title: "Sub-Totall",
        width: "12.7%",
        render: () => `Kr.${this.state.totalSubtotal.toFixed(2)}`
      },
      {
        title: "Mva",
        width: "9.4%",
        render: () => `Kr.${this.state.totalTax.toFixed(2)}`
      },
      {
        title: "Utkjøring",
        width: "12%",
        render: () => {
          return `Kr.${this.state.totalDriveoutCharge}`;
          // return `Kr.${(
          //   this.state.totalTaxPrice -
          //   (this.state.totalSubtotal + this.state.totalTax)
          // ).toFixed()}`
        }
      },
      {
        title: "Total",
        width: "15%",
        render: () => `Kr.${this.state.totalTaxPrice.toFixed(2)}`
      },
      {
        title: "emp",
        width: "9%"
      }
    ];
    const redlistColumns = [
      {
        title: "Produkt Navn",
        width: "30%",
        key: "name",
        dataIndex: "name"
      },
      {
        title: "Min. Mengde",
        width: "30%",
        key: "newMinQty",
        dataIndex: "newMinQty"
      },
      {
        title: "Mengde",
        width: "30%",
        key: "newQty",
        dataIndex: "newQty"
      }
    ];
    const plu1Columns = [
      {
        title: "PRODUKT",
        width: "18%",
        key: "name",
        dataIndex: "name"
      },
      {
        title: "Antall",
        width: "15%",
        key: "quantity",
        dataIndex: "quantity"
      },
      {
        title: "Mva 25%",
        width: "20%",
        render: (text, record) => {
          return `Kr.${(
            parseFloat(record.price) -
            parseFloat(record.price) / parseFloat(1.25)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Nett Sum(Kr.)",
        width: "20%",
        // key: "total",
        // dataIndex: "total",
        render: (text, record) => {
          return `Kr.${(
            parseFloat(record.price) -
            (parseFloat(record.price) -
              parseFloat(record.price) / parseFloat(1.25))
          ).toFixed(2)}`;
        }
      },
      {
        title: "Total(Kr.) - Rabatt(PLU1)",
        width: "30%",
        render: (text, record) => {
          return `Kr.${record.price.toFixed(2)}`;
        }
      }
    ];
    const plu1SummaryColumns = [
      {
        title: "Navn",
        width: "18%",
        render: () => <strong>Total</strong>
      },
      {
        title: "Total Antall",
        width: "15%",
        render: () =>
          `${this.state.plu1TotalQuantity && this.state.plu1TotalQuantity}`
      },
      {
        title: "Total Mva",
        width: "20%",
        render: () =>
          `Kr.${(
            Number(this.state.plu1Total) -
            Number(this.state.plu1discountPriceTotal) -
            (Number(this.state.plu1Total) -
              Number(this.state.plu1discountPriceTotal)) /
              Number(1.25)
          ).toFixed(2)}`
      },
      {
        title: "Nett Total",
        width: "20%",

        render: () =>
          `Kr.${(
            (Number(this.state.plu1Total) -
              Number(this.state.plu1discountPriceTotal)) /
            Number(1.25)
          ).toFixed(2)}`
      },
      {
        title: "Total",
        width: "30%",
        render: () =>
          `Kr.${
            this.state.plu1Total &&
            (
              Number(this.state.plu1Total) -
              Number(this.state.plu1discountPriceTotal)
            ).toFixed(2)
          }`
      }
    ];
    const plu2Columns = [
      {
        title: "PRODUKT",
        width: "18%",
        key: "name",
        dataIndex: "name"
      },
      {
        title: "Antall",
        width: "15%",
        key: "quantity",
        dataIndex: "quantity"
      },
      {
        title: "Mva 15%",
        width: "20%",
        render: (text, record) => {
          return `Kr.${(
            parseFloat(record.price) -
            parseFloat(record.price) / parseFloat(1.15)
          ).toFixed(2)}`;
        }
      },
      {
        title: "Nett Antall",
        width: "20%",
        // key: "total",
        // dataIndex: "total",
        render: (text, record) => {
          return `Kr.${(
            parseFloat(record.price) -
            (parseFloat(record.price) -
              parseFloat(record.price) / parseFloat(1.15))
          ).toFixed(2)}`;
        }
      },
      {
        title: "Total(Kr.) - Rabatt(PLU2)",
        width: "30%",
        render: (text, record) => {
          return `Kr.${record.price.toFixed(2)}`;
        }
      }
    ];
    const plu2SummaryColumns = [
      {
        title: "Name",
        width: "18%",
        render: () => <strong>Totall</strong>
      },
      {
        title: "Total Antall",
        width: "15%",
        render: () =>
          `${this.state.plu2TotalQuantity && this.state.plu2TotalQuantity}`
      },
      {
        title: "Total Mva",
        width: "20%",
        render: () =>
          `Kr.${(
            Number(this.state.plu2Total) -
            Number(this.state.plu2discountPriceTotal) -
            (Number(this.state.plu2Total) -
              Number(this.state.plu2discountPriceTotal)) /
              Number(1.15)
          ).toFixed(2)}`
      },
      {
        title: "Nett Totall",
        width: "20%",
        render: () =>
          `Kr.${(
            (Number(this.state.plu2Total) -
              Number(this.state.plu2discountPriceTotal)) /
            Number(1.15)
          ).toFixed(2)}`
      },
      {
        title: "Total",
        width: "30%",
        render: () =>
          `Kr.${
            this.state.plu2Total &&
            (
              Number(this.state.plu2Total) -
              Number(this.state.plu2discountPriceTotal)
            ).toFixed(2)
          }`
      }
    ];

    var tipSummaryColumnsGrouped = [
      {
        title: "Total",
        width: "20%"
      },
      {
        title: "",
        width: "20%"
      },
      {
        title: "",
        width: "20%"
      },
      {
        title: "",
        width: "20%"
      },
      {
        title: "",
        width: "30%",
        render: () =>
          `Kr.${(
            Number(this.state.cashTipSum) + Number(this.state.bankTipSum)
          ).toFixed(2)}`
      }
    ];

    var employeesTipColumn = [
      {
        title: "Navn",
        width: "20%",
        key: "firstName",
        dataIndex: "firstName",
        render: (text, record) => {
          return `${record.firstName}`;
        }
      },
      {
        title: "Email",
        width: "20%",
        key: "email",
        dataIndex: "email"
      },
      {
        title: "Cash",
        width: "20%",
        key: "cash",
        dataIndex: "cash",
        render: (text, record) => `Kr.${record.cash.toFixed(2)}`
      },
      {
        title: "Bank",
        width: "20%",
        key: "bank",
        dataIndex: "bank",
        render: (text, record) => `Kr.${record.bank.toFixed(2)}`
      },
      {
        title: "Total",
        width: "30%",
        key: "total",
        dataIndex: "total",
        render: (text, record) => `Kr.${record.total.toFixed(2)}`
      }
    ];
    var tipSummaryColumns = [
      {
        title: "Name",
        width: "20%",
        render: () => <strong></strong>
      },
      {
        title: "email",
        width: "20%",
        render: () => <strong> </strong>
      },
      {
        title: "cash",
        width: "20%",
        render: () => <strong> </strong>
      },
      {
        title: "bank",
        width: "20%",
        render: () => <strong> </strong>
      },
      {
        title: "Total",
        width: "30%",
        render: () =>
          `Kr.${this.state.tipTotal && this.state.tipTotal.toFixed(2)}`
      }
    ];

    var groupeddiscountColumn = [
      {
        title: "Faktura Nr.",
        width: "20%",
        key: "invoiceNumber",
        dataIndex: "invoiceNumber"
      },
      {
        title: "Rabatt(%)",
        width: "20%",
        key: "discount",
        dataIndex: "discount"
      },
      {
        title: "Rabatt Pris",
        width: "20%",
        key: "discountPrice",
        render: (text, record) => {
          return `${record.discountPrice.toFixed(2)}`;
        }
      }
    ];

    var flatGroupeddiscountColumn = [
      {
        title: "Faktura Nr.",
        width: "70%",
        key: "invoiceNumber",
        dataIndex: "invoiceNumber"
      },

      {
        title: "Flat Rabatt Pris",
        width: "30%",
        key: "discountPrice",
        render: (text, record) => {
          return `${record.flatDiscount ? record.flatDiscount : 0}`;
        }
      }
    ];

    var plu1FlatgroupeddiscountSummary = [
      {
        title: "",
        width: "70%"
      },

      {
        title: "Rabatt Pris",
        width: "30%",
        key: "discountPriceTotal",
        render: () =>
          `${
            this.state.plu1FlatdiscountPriceTotal &&
            this.state.plu1FlatdiscountPriceTotal
          }`
      }
    ];

    var plu1groupeddiscountSummary = [
      {
        title: "",
        width: "20%"
      },
      {
        title: "",
        width: "20%"
      },
      {
        title: "Rabatt Pris",
        width: "20%",
        key: "discountPriceTotal",
        render: () =>
          `${
            this.state.plu1discountPriceTotal &&
            this.state.plu1discountPriceTotal.toFixed(2)
          }`
      }
    ];
    var plu2groupeddiscountSummary = [
      {
        title: "",
        width: "20%"
      },
      {
        title: "",
        width: "20%"
      },
      {
        title: "Rabatt Pris",
        width: "20%",
        key: "discountPriceTotal",
        render: () =>
          `${
            this.state.plu2discountPriceTotal &&
            this.state.plu2discountPriceTotal.toFixed(2)
          }`
      }
    ];
    var plu2FlatgroupeddiscountSummary = [
      {
        title: "",
        width: "70%"
      },

      {
        title: "Rabatt Pris",
        width: "30%",
        key: "discountPriceTotal",
        render: () =>
          `${
            this.state.plu2FlatdiscountPriceTotal &&
            this.state.plu2FlatdiscountPriceTotal
          }`
      }
    ];
    var dataSource = [
      {
        key: "1",
        dcount: this.state.dcount,
        driveoutTax: `Kr. ${(
          parseInt(this.state.tatalOrdersPrice) -
          (parseInt(this.state.tatalOrdersPrice) * 100) / 125
        ).toFixed(2)}`,
        driveoutCharge: `Kr. ${(this.state.tatalOrdersPrice * 100) / 125}`,
        totalDcharge: this.state.tatalOrdersPrice
      }
    ];
    const driveoutcolumns = [
      {
        title: "Total Ordre",
        dataIndex: "dcount",
        width: "10%",
        key: "dcount"
      },
      {
        title: "Utkjøring Mva",
        dataIndex: "driveoutTax",
        width: "10%",
        key: "driveoutTax"
      },
      {
        title: "Utkjøring Pris",
        dataIndex: "driveoutCharge",
        width: "15%",
        key: "driveoutCharge"
      },

      {
        title: "Total Utkjøring Pris",
        dataIndex: "totalDcharge",
        width: "13%",
        key: "totalDcharge"
      }
    ];

    const unpaiddataColumns = [
      {
        title: "Faktura Nr.",
        width: "45%",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber"
      },
      {
        title: "Total",
        width: "45%",
        dataIndex: "taxPrice",
        key: "taxPrice",
        render: (text, record) => {
          // return `Kr.${this.branchInfo.driveoutCharge + parseInt(text)}`;
          if (record.orderType === "Drive Out") {
            return `Kr.${(
              Number(this.state.branchInfo.driveoutCharge) + Number(text)
            ).toFixed(2)}`;
          } else {
            return `Kr.${parseFloat(text).toFixed(2)}`;
          }
        }
      }
    ];
    const unpaidSummaryColumns = [
      {
        title: "Navn",
        width: "45%",
        render: () => <strong>Total</strong>
      },
      {
        title: "Total",
        width: "45%",
        render: () =>
          `Kr.${this.state.totunpaid && this.state.totunpaid.toFixed(2)}`
      }
    ];

    const xColumns = [
      {
        title: "Sr. Nr.",
        editable: false,
        width: "8%",
        render: (text, record, index) =>
          //? for correct sr no., check antd pagination and table/pagination docs
          //? index + (currentpage - 1)*10
          {
            return index + (this.state.paginationNo - 1) * 10 + 1;
            // return index + 1;
          }
      },
      {
        title: "Navn",
        dataIndex: "productName",
        key: "productName",
        width: "15%"
      },
      {
        title: "Mva",
        width: "14%",
        children: [
          {
            title: "15%",
            width: "13%",
            dataIndex: "taxPrice15",
            render: (text) => {
              return `Kr.${text && text.toFixed(2)}`;
            }
          },
          {
            title: "25%",
            width: "13%",
            dataIndex: "taxPrice25",
            render: (text) => {
              return `Kr.${text && text.toFixed(2)}`;
            }
          }
        ]
      },
      {
        title: "Enhet Pris",
        dataIndex: "productPrice",
        key: "productprice",
        width: "13%",
        render: (text, record) => {
          return `Kr.${
            text
              ? (
                  record.total -
                  (record.taxPrice15 && record.taxPrice25
                    ? record.taxPrice15 + record.taxPrice25
                    : record.taxPrice15
                    ? record.taxPrice15
                    : record.taxPrice25)
                ).toFixed(2)
              : ""
          }`;
        }
      },
      {
        title: "Antall",
        width: "13%",
        children: [
          {
            title: "15%",
            dataIndex: "unitoftax15",
            key: "unitoftax15"
          },
          {
            title: "25%",
            dataIndex: "unitoftax25",
            key: "unitoftax25"
          }
        ]
      },
      {
        title: "Rabatt",
        key: "discountPrice",
        dataIndex: "discountPrice",
        width: "12%",
        render: (text) => {
          return `Kr.${text && text.toFixed(2)}`;
        }
      },
      {
        title: "Ekstra varepris",
        key: "extraPrice",
        dataIndex: "extraPrice",
        width: "12%",
        render: (text) => {
          if (text) {
            return `Kr.${text && text.toFixed(2)}`;
          }
          return `Kr.0`;
        }
      },
      {
        title: "Total",
        key: "total",
        dataIndex: "total",
        width: "13%",
        render: (text, record) => {
          let tamt = record.extraPrice
            ? record.extraPrice + Number(text)
            : text;
          return `Kr.${tamt ? tamt.toFixed(2) : ""}`;
        }
      }
    ];

    const summaryColumns = [
      {
        title: "Sr. Nr.",
        width: "7%"
      },
      {
        title: "Navn",
        width: "15%",
        render: () => <strong>Total</strong>
      },

      {
        title: "Mva",
        width: "14%",
        children: [
          {
            title: "Total 15% Mva",
            render: () => `Kr.${this.state.total15Tax.toFixed(2)}`
          },
          {
            title: "Total 25% Mva",
            render: () =>
              `Kr.${(
                Number(this.state.total25Tax) +
                Number(this.state.tatalOrdersPrice) -
                Number(this.state.tatalOrdersPrice * 100) / 125
              ).toFixed(2)}`
          }
        ]
      },

      {
        title: "Total Antall Pris",
        width: "12%",
        render: () =>
          `Kr.${(
            this.state.unitPriceTotal +
            parseInt(this.state.tatalOrdersPrice * 100) / 125
          ).toFixed(2)}`
      },
      {
        title: "Total Antall",
        width: "17%",
        children: [
          {
            title: "15%",
            dataIndex: "unitoftax15",
            key: "unitoftax15",
            render: () => this.state.total15Quantity
          },
          {
            title: "25%",
            dataIndex: "unitoftax25",
            key: "unitoftax25",
            render: () => this.state.total25Quantity
          }
        ]
      },
      {
        title: "Rabatt",
        width: "10%",
        render: () => `Kr.${this.state.totalDiscount.toFixed(2)}`
      },
      {
        title: "Ekstra varepris",
        width: "13%",
        render: () => `Kr.${this.state.totalExtra.toFixed(2)}`
      },
      {
        title: "Total",
        width: "14%",
        render: () =>
          `Kr.${(this.state.finalTotal + this.state.tatalOrdersPrice).toFixed(
            2
          )}`
      }
    ];

    const summaryData = [
      {
        key: "-1",
        // productName: 'Total',
        // productPrice: this.state.unitPriceTotal,
        // // total: this.state.finalTotal,
        // total: 'total',
        // Total: 'total',
        // unitoftax15: this.state.total15Quantity,
        // unitoftax25: this.state.total25Quantity
        render: () => "test"
      }
    ];

    if (currentPage === "buttons") {
      return (
        <Home isAdmin={true}>
          <div className="report-container-flex">
            <button className="box shadow stock-bg">
              <p className="text-items">ARKIV</p>
            </button>
          </div>
          <div className="different-reports-t">
            <div id="flex-stock">
              <button
                onClick={() => this.navigate("/restreports")}
                className="box-t shadow orange-bg"
              >
                <p className="text-items">ALL FAKTURA</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">VARSLER</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">PROGRESS KART</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">ANSATTE TIP RAPPORT</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">IK SYSTEM RAPPORT</p>
              </button>
              <button
                onClick={() =>
                  this.handleClick("WEEKLY REPORTS", "UKENTLIG RAPPORT")
                }
                className="box-t shadow orange-bg"
              >
                <p className="text-items">UKENTLIG RAPPORT</p>
              </button>
            </div>
            <div id="flex-stock">
              <button
                className="box-t shadow black-bg"
                onClick={() =>
                  this.handlePageChange("STOCK-RAP", "LAGER RAPPORT")
                }
              >
                <p className="text-items">LAGER RAPPORT</p>
              </button>
              <input
                type="file"
                name="file"
                onChange={this.onChangeHandler}
                hidden
              />
              <button
                onClick={() => this.openpdfmodal()}
                className="box-t shadow black-bg"
              >
                <p className="text-items">LEGG TIL FILER</p>
              </button>
              <button className="box-t shadow black-bg">
                <p className="text-items">KONTANT RAPPORT</p>
              </button>
              <button
                className="box-t shadow black-bg"
                onClick={() =>
                  this.handlePageChange("GROUPED-INVOICE", "")
                }
              >
                <p className="text-items">GRUPPERT RAPPORT</p>
              </button>
              <button
                className="box-t shadow black-bg"
                onClick={() =>
                  this.handlePageChange("TIP-REPORT", "TIP RAPPORT")
                }
              >
                <p className="text-items">TIP RAPPORT</p>
              </button>
              <button
                onClick={() =>
                  this.handleClick("MONTHLY REPORTS", "MÅNEDLIG RAPPORT")
                }
                className="box-t shadow orange-bg"
              >
                <p className="text-items">MÅNEDLIG RAPPORT</p>
              </button>
            </div>
            <div id="flex-stock">
              <button className="box-t shadow orange-bg">
                <p className="text-items">TILPASS</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">BANK RAPPORT</p>
              </button>
              <button className="box-t shadow orange-bg">
                <p className="text-items">WEB RAPPORT</p>
              </button>
              <button
                onClick={() => this.navigate("/tflreport")}
                className="box-t shadow orange-bg"
              >
                <p className="text-items">TFL RAPPORT</p>
              </button>
              <button
                className="box-t shadow orange-bg"
                onClick={() =>
                  this.handlePageChangerap("POS-RAP", "POS RAPPORT")
                }
              >
                <p className="text-items">POS RAPPORT</p>
              </button>

              <button
                onClick={() =>
                  this.handleClick("YEARLY REPORTS", "ÅRLIG RAPPORT")
                }
                className="box-t shadow orange-bg"
              >
                <p className="text-items">ÅRLIG RAPPORT</p>
              </button>
            </div>
          </div>
          <Modal
            title="Basic Modal"
            visible={this.state.showuploadpdfModal}
            onOk={() => this.uploadPdf()}
            onCancel={this.handleCancel}
          >
            <Input
              type="file"
              style={{ margin: "auto" }}
              name="file"
              onChange={this.onChangeHandler}
            ></Input>

            <Radio.Group onChange={this.pdfType}>
              <Radio.Button value="PAID">BETALT</Radio.Button>
              <Radio.Button value="UNPAID">UBETALT</Radio.Button>
            </Radio.Group>
            <Input
              placeholder="File Name"
              value={this.state.pdfName}
              onChange={(e) => this.setState({ pdfName: e.target.value })}
            />
          </Modal>
        </Home>
      );
    } else if (currentPage === "POS-RAP") {
      // setTimeout(() => {
      const dcharge = this.state.driveoutCharge;
      // }, 1000);

      return (
        <Home>
          <div className="report-container">
            <span className="item">POS RAPPORT</span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "61%" }}
            >
              <DatePicker
                onChange={(date, dateString) =>
                  this.loadReports(date, dateString)
                }
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>

            <span
              className="item"
              style={{ position: "absolute", marginLeft: "85%" }}
            >
              <button
                className="back-button-border"
                onClick={this.printReportHandler}
              >
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                ></i>
              </button>
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          <h4>
            <b>UTKJØRING :</b>
          </h4>
          <Table
            dataSource={dataSource}
            columns={driveoutcolumns}
            pagination={false}
          />
          <br />
          <h4>
            <b style={{ color: "grey" }}>
              BESTILLINGER
              {/* {this.state.reportItems} */}
            </b>
          </h4>
          <TableSum
            loading={isLoaded ? false : true}
            columns={xColumns}
            summaryColumns={summaryColumns}
            data={this.state.items}
            summaryData={summaryData}
            onChange={(pagination) => this.pageChange(pagination)}
            bordered
          />
          <div style={{ display: "none" }}>
            {this.state.sendProps ? (
              <PrintReport
                columns={xColumns}
                summaryColumns={summaryColumns}
                data={this.state.items}
                summaryData={summaryData}
                ref={(el) => (this.componentRef = el)}
                branch={this.state.branchInfo}
                total15Tax={this.state.total15Tax}
                total25Tax={this.state.total25Tax}
                total15Quantity={this.state.total15Quantity}
                total25Quantity={this.state.total25Quantity}
                totalDiscount={this.state.totalDiscount}
                finalTotal={this.state.finalTotal}
                type="x-reports"
              />
            ) : (
              ""
            )}
          </div>
        </Home>
      );
    } else if (currentPage == "STOCK-RAP") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">LAGER RAPPORT</span>

            <span
              className="item"
              style={{ position: "absolute", marginLeft: "85%" }}
            >
              <button className="back-button-border" onClick={this.printbill}>
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                ></i>
              </button>
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          <div id="printgroup">
            <Table
              dataSource={this.state.redProducts}
              columns={redlistColumns}
              pagination={true}
            />
            <div style={{ display: "none" }}>
              {this.state.sendProps ? (
                <PrintReport
                  columns={redlistColumns}
                  data={this.state.redProducts}
                  ref={(el) => (this.componentRef = el)}
                  type="stock-rap"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Home>
      );
    } else if (currentPage == "TIP-REPORT") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">TIP RAPPORT</span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "38%" }}
            >
              <DatePicker
                onChange={(date, dateString) => this.employeesTip(date)}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>
            <span style={{ position: "absolute", marginLeft: "63%" }}>
              <MonthPicker
                onChange={(date, dateString) =>
                  this.employeesTip(date, "month")
                }
                placeholder="Select month"
              />
            </span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "85%" }}
            >
              <button className="back-button-border" onClick={this.printbill}>
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                ></i>
              </button>
            </span>

            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          <div id="printgroup">
            <div className="printtiptitle" style={{ visibility: "hidden" }}>
              <h2 align="center">TIP RAPPORT</h2>
            </div>

            {/* <Table
                dataSource={this.state.employeeTipData}
                columns={employeesTipColumn}
                pagination={true}
              /> */}
            <TableSum
              // loading={isLoaded ? true : false}
              columns={employeesTipColumn}
              summaryColumns={tipSummaryColumns}
              data={this.state.employeeTipData}
              summaryData={summaryData}
              onChange={(pagination) => this.pageChange(pagination)}
            />
            <div style={{ display: "none" }}>
              {this.state.sendProps ? (
                <PrintReport
                  columns={redlistColumns}
                  // summaryColumns={summaryColumns}
                  data={this.state.redProducts}
                  // summaryData={summaryData}
                  ref={(el) => (this.componentRef = el)}
                  // branch={this.state.branchInfo}
                  // total15Tax={this.state.total15Tax}
                  // total25Tax={this.state.total25Tax}
                  // total15Quantity={this.state.total15Quantity}
                  // total25Quantity={this.state.total25Quantity}
                  // totalDiscount={this.state.totalDiscount}
                  // finalTotal={this.state.finalTotal}
                  type="stock-rap"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Home>
      );
    } else if (currentPage == "GROUPED-INVOICE") {
      const taxNo = localStorage.getItem("taxNo");
      return (
        <Home>
          <div className="report-container">
            <span className="item">GRUPPERT RAPPORT</span>
            <span style={{ position: "absolute", marginLeft: "20%" }}>
              {taxNo ? `Tax No.#${taxNo}` : ""}
            </span>
            <span style={{ position: "absolute", marginLeft: "40%" }}>
              <DatePicker
                onChange={(date, dateString) => (
                  this.groupedInvoice(date),
                  this.loadUnpaidInvoices(date),
                  this.loadReports(date),
                  this.groupedDiscount(date),
                  this.getFlatGroupedDiscount(date),
                  this.groupedCashBank(date),
                  this.cahboxCounter(date),
                  this.employeesTip(date),
                  this.groupedUnpaidCashBankFlag(date),
                  this.groupedOnlineorderTotal(date)
                )}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>
            <span style={{ position: "absolute", marginLeft: "63%" }}>
              <MonthPicker
                onChange={(date, dateString) => (
                  this.groupedInvoice(date, "month"),
                  this.loadUnpaidInvoices(date, "month"),
                  this.loadReports(date, "month"),
                  this.getFlatGroupedDiscount(date, "month"),
                  this.groupedDiscount(date, "month"),
                  this.groupedCashBank(date, "month"),
                  this.cahboxCounter(date, "month"),
                  this.employeesTip(date, "month"),
                  this.groupedUnpaidCashBankFlag(date, "month"),
                  this.groupedOnlineorderTotal(date, "month")
                )}
                placeholder="Select month"
              />
            </span>

            {/* <span
              className="item"
              style={{ position: "absolute", marginLeft: "61%" }}
            >
              {this.handleSwitch("GROUPED-INVOICE")}
            </span> */}

            <span
              className="item grp"
              style={{ position: "absolute", marginLeft: "85%" }}
            >
              <Button
                className="back-button-border"
                onClick={this.printGroupInvoice}
                style={{ height: "33px", position: "unset" }}
                loading={this.state.printLoader}
              >
                {!this.state.printLoader ? (
                  <i
                    className="fa fa-print"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  ></i>
                ) : (
                  ""
                )}
              </Button>
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>

          <div id="printgroup">
            {/* <h3 className="titleprint">GRUPPERT RAPPORT</h3> */}
            <div
              id="counters"
              style={{
                background: "#ECECEC",
                padding: "30px",
                height: "300px"
              }}
            >
              <Row gutter={18}>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="KONTANT"
                      value={(
                        Number(this.state.totalCash) +
                        Number(this.state.cashTipSum)
                      ).toFixed(2)}
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      prefix={<RIcon Icon={dollar} />}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="KONTANT BOX RAPPORT"
                      value={this.state.totalcashboxopen}
                      // precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      prefix={<RIcon Icon={fileTextO} />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <Statistic
                      title="BANK"
                      value={
                        Number(this.state.totalBank) +
                        Number(this.state.bankTipSum)
                      }
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      prefix={<RIcon Icon={bank} />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
              </Row>

              {/* unpaid invoices cash bank */}

              <Row gutter={18}>
                <Col span={8} style={{ marginTop: 20 }}>
                  <Card>
                    <Statistic
                      title="KREDIT"
                      value={Number(this.state.totalUnpaidAmount)}
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      prefix={<RIcon Icon={dollar} />}
                    />
                  </Card>
                </Col>
                <Col span={8} style={{ marginTop: 20 }}>
                  <Card>
                    <Statistic
                      title="ONLINE BESTILLING"
                      value={this.state.onlineorderTotalAmount}
                      // precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      prefix={<RIcon Icon={fileTextO} />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={8} style={{ marginTop: 20 }}>
                  <Card
                    style={{
                      background: "#f5a640",
                      color: "white",
                      border: "transparent",
                      boxShadow: "1px 3px 3px 2px goldenrod"
                    }}
                  >
                    <Statistic
                      // title="FINAL TOTALT"
                      value={
                        this.state.totalCash +
                        this.state.totalBank +
                        this.state.onlineorderTotalAmount +
                        this.state.totalUnpaidAmount
                      }
                      precision={2}
                      valueStyle={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 45
                      }}
                      prefix={
                        <div style={{ fontSize: 30 }}>
                          <RIcon Icon={dollar} /> TOTALL{" "}
                        </div>
                      }
                      // suffix="%"
                    />
                  </Card>
                </Col>
              </Row>

              {/* end of unpaid invoices cash bank */}
            </div>

            <div
              style={{
                // backgroundColor: "silver",
                padding: 10,
                marginTop: 20,
                marginBottom: 10
              }}
            >
              <h1 style={{ color: "black" }}>
                <b>PLU1</b>{" "}
                {this.state.plu1discountPriceTotal ? (
                  <p style={{ color: "red" }}>Etter Rabatt</p>
                ) : (
                  ""
                )}
              </h1>
              <TableSum
                loading={isLoaded ? false : true}
                columns={plu1Columns}
                summaryColumns={plu1SummaryColumns}
                data={this.state.groupedInvoicesplu1}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                pagination={false}
              />

              {this.state.plu1FlatdiscountPriceTotal != 0 && false ? (
                <div id="creditprint">
                  <h4 style={{ color: "red" }}>
                    <b>FLAT RABATT (PLU1)</b>
                  </h4>

                  <TableSum
                    loading={isLoaded ? false : true}
                    columns={flatGroupeddiscountColumn}
                    summaryColumns={plu1FlatgroupeddiscountSummary}
                    data={this.state.plu1FlatDiscountData}
                    summaryData={summaryData}
                    onChange={(pagination) => this.pageChange(pagination)}
                    rowKey={(record) => record.invoiceNumber}
                  />
                </div>
              ) : (
                ""
              )}
              {this.state.plu1discountPriceTotal != 0 ? (
                <div id="creditprint">
                  <h4 style={{ color: "red" }}>
                    <b>RABATT (PLU1)</b>
                  </h4>

                  <TableSum
                    loading={isLoaded ? false : true}
                    columns={groupeddiscountColumn}
                    summaryColumns={plu1groupeddiscountSummary}
                    data={this.state.plu1discountData}
                    summaryData={summaryData}
                    onChange={(pagination) => this.pageChange(pagination)}
                    rowKey={(record) => record.invoiceNumber}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div style={{ backgroundColor: "silver", padding: 10 }}>
              <h1 style={{ color: "white" }}>
                <b>PLU2</b>{" "}
                {this.state.plu2discountPriceTotal ? (
                  <p style={{ color: "red" }}>Etter Rabatt</p>
                ) : (
                  ""
                )}
              </h1>
              <TableSum
                loading={isLoaded ? false : true}
                columns={plu2Columns}
                summaryColumns={plu2SummaryColumns}
                data={this.state.groupedInvoicesplu2}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                pagination={false}
              />

              {this.state.plu2discountPriceTotal !== 0 ? (
                <div id="creditprint">
                  <h4 style={{ color: "red" }}>
                    <b>RABATT (PLU2)</b>
                  </h4>

                  <TableSum
                    loading={isLoaded ? false : true}
                    columns={groupeddiscountColumn}
                    summaryColumns={plu2groupeddiscountSummary}
                    data={this.state.plu2discountData}
                    summaryData={summaryData}
                    onChange={(pagination) => this.pageChange(pagination)}
                    rowKey={(record) => record.invoiceNumber}
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.plu2FlatdiscountPriceTotal !== 0 && false ? (
                <div id="creditprint">
                  <h4 style={{ color: "red" }}>
                    <b>FLAT RABATT (PLU2)</b>
                  </h4>

                  <TableSum
                    loading={isLoaded ? false : true}
                    columns={flatGroupeddiscountColumn}
                    summaryColumns={plu2FlatgroupeddiscountSummary}
                    data={this.state.plu2FlatDiscountData}
                    summaryData={summaryData}
                    onChange={(pagination) => this.pageChange(pagination)}
                    rowKey={(record) => record.invoiceNumber}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            <br />
            <br />
            <div style={{ marginTop: 40 }}>
              <h4>
                <b>UTKJØRING :</b>
              </h4>
              <Table
                dataSource={dataSource}
                columns={driveoutcolumns}
                pagination={false}
              />
            </div>
            <br />
            <h4>
              <b>ANSATTE TIP RAPPORT :</b>
            </h4>
            <TableSum
              // loading={isLoaded ? true : false}
              columns={employeesTipColumn}
              summaryColumns={tipSummaryColumns}
              data={this.state.employeeTipData}
              summaryData={summaryData}
              onChange={(pagination) => this.pageChange(pagination)}
            />
            <br />
            <div id="creditprint" style={{ marginBottom: 30 }}>
              <h4>
                <b>KREDIT/FAKTURA (UBETALT)</b>
              </h4>
              {/* <Table
                loading={isLoaded ? false : true}
                dataSource={this.state.dueItems}
                columns={unpaiddataColumns}
                rowKey={record => record.invoiceNumber}
                pagination={false}
              /> */}

              <TableSum
                loading={isLoaded ? false : true}
                columns={unpaiddataColumns}
                // summaryColumns={summaryColumns}
                summaryColumns={unpaidSummaryColumns}
                data={this.state.dueItems}
                summaryData={summaryData}
                onChange={(pagination) => this.pageChange(pagination)}
                pagination={false}
              />
            </div>
          </div>
        </Home>
      );
    } else if (currentPage == "INVOICE PAGE") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">HJEM / </span>
              FAKTURA NR. : {this.state.billData.invoiceNumber}
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                  {/* <span>Back</span> */}
                </div>
              </span>
            </span>
          </div>
          <SingleInvoice
            data={this.state.billData}
            branch={this.state.branchInfo}
          />
        </Home>
      );
    } else if (
      title === "WEEKLY REPORTS" ||
      title === "MONTHLY REPORTS" ||
      title === "YEARLY REPORTS"
    ) {
      return (
        <Home>
          <div className="report-container">
            <span className="item">GRUPPERT FAKTURA</span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "61%" }}
            >
              {this.handleSwitch(this.state.title)}
            </span>
            <span
              className="item"
              style={{ position: "absolute", marginLeft: "85%" }}
            >
              <button
                className="back-button-border"
                onClick={this.printReportHandler}
              >
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                ></i>
              </button>
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: 20, marginTop: 1 }}
                    aria-hidden="true"
                  />
                </div>
              </span>
            </span>
          </div>
          {/* <Fragment> */}
          <TableSum
            loading={isLoaded ? false : true}
            columns={dailyColumns}
            summaryColumns={dailySummaryColumns}
            data={this.state.items}
            summaryData={summaryData}
            onChange={(pagination) => this.pageChange(pagination)}
            rowKey={(record) => record.productName}
            totalDocs={this.state.totalDocs}
            currentPage={this.state.currentPage}
            pageLimit={this.state.pageLimit}
            bordered
          />
          <div style={{ display: "none" }}>
            {this.state.sendProps ? (
              <PrintReport
                columns={dailyColumns}
                summaryColumns={dailySummaryColumns}
                data={this.state.items}
                summaryData={summaryData}
                ref={(el) => (this.componentRef = el)}
                branch={this.state.branchInfo}
                totalTaxPrice={this.state.totalTaxPrice}
                totalSubtotal={this.state.totalSubtotal}
                totalTax={this.state.totalTax}
                type="daily-reports"
              />
            ) : (
              ""
            )}
          </div>
          {/* </Fragment> */}
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const taccountant = connect(mapStateToProps)(taccountantComponent);
export default taccountant;
