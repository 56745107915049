import React from "react";
import { Table, DatePicker } from "antd";
import { Card } from "react-bootstrap";
const { RangePicker } = DatePicker;
const StockHistory = (props) => {
  const { data, columns, loading ,handleStockHistory,setPaginationNo} = props;
  return (
    <>
      <Card>
        <Card.Body>
          <RangePicker
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            placeholder={["Fra Dato", "Til Dato"]}
            onOk={(date) => handleStockHistory(date)}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Table
          bordered
            loading={loading}
            dataSource={data}
            columns={columns}
            onChange={(e) => setPaginationNo(e.current)}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default StockHistory;
