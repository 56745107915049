import { GET_CAT_PRODUCT } from "../../action/types";

const categoryProductReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CAT_PRODUCT:
      return action.payload.data;
    default:
      return state;
  }
};
export default categoryProductReducer
