import React, { useState, useEffect } from "react";

import Home from "../Home";
import {Row, Table } from "antd";

import Tabs from "react-bootstrap/Tabs";
import { Card } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import ViewPage from "./ViewPage";
import { unpaidInvoicesTable } from "./../Table/TableColumns";

const UnpaidInvoices = (props) => {
  let [error, setError] = useState(false);
  let [isLoaded, setIsLoaded] = useState(props.isLoaded);
  let [items, setItems] = useState([]);
  let [dueItems, setDueItems] = useState([]);
  let [currentPage, setCurrentPage] = useState("unpaidInvoice");
  let [billData, setBillData] = useState(null);
  let [defaultActiveKey, setDefaultActiveKey] = useState("Kreditt Faktura");
  let [name, setName] = useState("Kreditt Faktura");

  let result = props.UnpaidInvoices;
  let invoiceData = [];
  let dueInvoiceData = [];
  const assignData = () => {
    let data = result.invoice.map((item) => ({
      ...item,
      createdDate: new Date(item.created).toLocaleDateString(),
      createdTime: new Date(item.created).toLocaleTimeString(),
      taxPrice: item.taxPrice.toFixed(2),
      isLoaded: true
    }));
    invoiceData = data;

    let dueData = result.dueDatedInvoice.map((item) => ({
      ...item,
      createdDate: new Date(item.created).toLocaleDateString(),
      createdTime: new Date(item.created).toLocaleTimeString(),
      taxPrice: item.taxPrice.toFixed(2),
      isLoaded: true
    }));
    dueInvoiceData = dueData;
    setItems(invoiceData);
    setDueItems(dueInvoiceData);
    setIsLoaded(true);
  };
  useEffect(() => {
    assignData();
  }, [props.count]);

  const showInvoice = (invoiceNumber) => {
    let index;

    index = items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );

    if (index > -1) {
      setCurrentPage("invoice");
      setBillData(items[index]);
    } else if (index < 0) {
      index = dueItems.findIndex(
        (invoice) => invoice.invoiceNumber === invoiceNumber
      );
      setCurrentPage("invoice");
      setBillData(dueItems[index]);
    }
  };

  const goBackPrevious = () => {
    setCurrentPage("unpaidInvoice");
    setDefaultActiveKey("Til Purring");
    // props.setCount(props.count + 1);
    props.changeCount();
  };

  const handleTitle = (e) => {
    const title = e === "tilPurring" ? "Til Purring" : "Kreditt Faktura";
    setName(title);
  };

  if (error) {
    return <div>error</div>;
  } else if (currentPage === "unpaidInvoice") {
    return (
      <Home>
        <Row className="report-container d-flex justify-content-center">
          <span className="item text-center">Ubetalt Faktura: {name}</span>
        </Row>
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="kreditt"
              id="faktura"
              className="mb-3"
              onSelect={(e) => handleTitle(e)}
            >
              <Tab eventKey="kreditt" title="Kreditt Faktura">
                <Table
                bordered
                  loading={isLoaded ? false : true}
                  dataSource={items}
                  columns={unpaidInvoicesTable(result.branch, showInvoice)}
                  rowKey={(record) => record.invoiceNumber}
                />
              </Tab>
              <Tab eventKey="tilPurring" title="Til Purring">
                <Table
                bordered
                  loading={isLoaded ? false : true}
                  dataSource={dueItems}
                  columns={unpaidInvoicesTable(result.branch, showInvoice)}
                  rowKey={(record) => record.invoiceNumber}
                />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Home>
    );
  } else if (currentPage === "invoice") {
    return (
      <>
        <ViewPage
          billData={billData}
          branch={result.branch}
          goBackPrevious={goBackPrevious}
        />
      </>
    );
  }
};

export default UnpaidInvoices;
