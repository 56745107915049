import React, { Component } from "react";
import Home from "../Home/Home";
import "./ik-accounts.css";
import { connect } from "react-redux";

class ikAccountsComponent extends Component {
  render() {
    return (
      <Home>
        <div className="report-container-flex">
          <button className="box shadow stock-bg">
            <p className="text-items">
              <u>IK SYSTEM</u>
            </p>
          </button>
        </div>
        <div className="different-reports">
          <div id="flex-stock">
            <button className="box shadow orange-bg">
              <p className="text-items">IK MAT</p>
            </button>
            <button className="box shadow orange-bg">
              <p className="text-items">IK ALCH.</p>
            </button>
            <button className="box shadow orange-bg">
              <p className="text-items">IK BRANN</p>
            </button>
          </div>
          <div id="flex-stock">
            <button className="box shadow black-bg">
              <p className="text-items">IK EL.</p>
            </button>
            <button className="box shadow black-bg">
              <p className="text-items">HMS</p>
            </button>
            <button className="box shadow black-bg">
              <p className="text-items small-text">FORSIKRING</p>
            </button>
          </div>
        </div>
      </Home>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const ikAccounts = connect(mapStateToProps)(ikAccountsComponent);
export default ikAccounts;
