import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getUnpaidInvoices } from "./../../action/unpaidInvoices";
import SingleInvoice from "./../Arkiv/Reports/SingleInvoice";
import Home from "../Home";

import { Button } from "react-bootstrap";
const ViewPage = (props) => {
  return (
    <Home>
      <div className="report-container">
        <span className="item">Ubetalt Faktura</span>
        <span className="item">
          <span id="less-visible">HJEM / </span>
          Ubetalt Faktura
        </span>
        <span className="item">
          <span id="less-visible">
            <Button onClick={props.goBackPrevious} className="btn back-btn">
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </Button>
          </span>
        </span>
      </div>
      <SingleInvoice data={props.billData} branch={props.branch} />
    </Home>
  );
};



export default ViewPage;
