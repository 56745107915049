import { PRODUCT_INGREDENT } from "../../action/types";

const productIngredentReducer = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_INGREDENT:
      return action.payload.data;
    default:
      return state;
  }
};
export default productIngredentReducer;
