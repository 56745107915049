import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Card, Col } from "react-bootstrap";
import { Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCoupanInvoices } from "../../action/coupanAction";
import Home from "../Home/index";
import SingleInvoice from "../../containers/SingleInvoice/SingleInvoice";

const CouponInvoicesList = (props) => {
  let dispatch = useDispatch();
  const [couponRecord, setcouponRecord] = useState([]);
  const [showView, setshowView] = useState(false);
  console.log(props.match.params.id);
  let coupanInvoies = useSelector(
    (state) => state.coupanReducer.data.invoiceData
  );
  const showInvoice = (record) => {
    {
        console.log("record is",record)
        setcouponRecord(record)
        setshowView(true)
   
    }
  };
  const columns = [
    {
      title: "FAKTURA NR.",
      dataIndex: "invoiceNumber",
      width: "5%",
      key: "invoiceNumber",
    },
    {
      title: "Dato",
      dataIndex: "createdDate",
      key: "createdDate",
      width: "5%",
      render: (text, record) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
    {
      title: "Tid",
      dataIndex: "createdTime",
      key: "createdTime",
      width: "12%",
      render: (text, record) => {
        return new Date(record.created).toLocaleTimeString();
      },
    },
    {
      title: "Ordre Type",
      dataIndex: "orderType",
      key: "orderType",
      width: "10%",
    },

    {
      title: "Sub-Total",
      width: "15%",
      render: (text, record) => {
        return `Kr.${(
          record.taxPrice -
          (
            record.taxPrice -
            (100 * record.taxPrice) / (100 + record.tax)
          ).toFixed(3)
        ).toFixed(2)}`;
      },
      key: "Sub-Total",
    },
    {
      title: "Mva",
      width: "11%",
      render: (text, record) => {
        return `Kr.${(
          record.taxPrice -
          (100 * record.taxPrice) / (100 + record.tax)
        ).toFixed(2)}`;
      },
      key: "tax",
    },
    {
      title: "Utkjøring",
      width: "12%",
      render: (text, record) => {
        if (record.orderType == "Drive Out") {
          var extra = record.extraDriveOut ? record.extraDriveOut : 0;
          return `Kr. ${(record.driveoutCharge + extra).toFixed(2) || 0}`;
        } else {
          return `Kr. 0`;
        }
      },
      key: "Drive-out-Charge",
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "15%",
      render: (text, record) => {
        return `Kr.${parseFloat(record.totalPrice.toFixed(2) || 0)}`;
      },
    },
    {
      title: "Action",
      width: "5%",
      render: (text, record) => (
        <Button onClick={() => showInvoice(record)}>
          <i className="fa fa-eye"></i>
        </Button>
      ),
      key: "action",
    },
  ];
  useEffect(() => {
    dispatch(getCoupanInvoices(props.match.params.id));
  }, []);
  useEffect(() => {
    console.log("coupanInvoies", coupanInvoies);
  }, [coupanInvoies]);

  const backButtonPressed=()=>{
    console.log('back button pressed')
    if(showView){
      setshowView(false);
    }else{
      props.history.push('/coupons')

    }

  }

  return (
    <Home>
      <Row>
        <Col>
        <>
            <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Hjem / </span>
              Coupon/ Coupon Invoices
            </span>
            <span id="less-visible">
              <Button onClick={backButtonPressed} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
          {(couponRecord.length==0 || !showView) ?( <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Table dataSource={coupanInvoies} columns={columns} />;
                </Col>
              </Row>
            </Card.Body>
          </Card>):(<SingleInvoice data={couponRecord}></SingleInvoice>)}
         
        </>
          
        </Col>
      </Row>
    </Home>
  );
};

export default CouponInvoicesList;
