import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
//import Home from "../Home/Home";
import { Button, Col, Row, notification, Card, Badge } from "antd";
import Home from "../../components/Home/index";
import KitchenHeading from "./kitchenheading";
import KitchenTable from "./kitchentable";
// import "./Tables.css";
import PropTypes from "prop-types";
import appConfig from "../../helpers/appConfig";
import Loader from "../../containers/Loader/Loader";
import moment from "moment";
import SingleKitchenInvoice from "../../containers/SingleKitchenBar/SingleKitchenBar";
import Countdown from "react-countdown";
import { socket } from "../../utils/socketNew";
import OnlineOrder from "./onlineorder";
import { getallTables } from "../../action/tableAction";
import {
  updateKitchenStatus,
  updateOnlineOrderKitchenStatus
} from "../../action/kitchenAction";
import { getOnlineOrders } from "../../action/ordersAction";
import { th } from "react-icons-kit/fa";

class KitchenViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      takeAway: [],
      items: [],
      currentPage: "KitchenView",
      pendingOnlineOrders: [],
      onlineorderData: {}
    };

    this.refer =
      this.props.history.location.pathname == "/kitchen" ? "KITCHEN" : "BAR";

    socket.on("tableSaved", (socketData) => {
      console.log("socket",socketData)
      // alert("got new order table");
      // console.log("table created in kitchen");
      this.getallTables();
      this.getOnlineOrders("active");
      // this.props.dispatch(getRestaurantInfo(restaurantId));
    });
  }

  componentDidMount() {
    this.getallTables();
    this.getOnlineOrders("active");
  }
  goBackButton = () => {
    this.setState({ currentPage: "KitchenView" });
  };

  showTable = (item) => {
    this.setState({
      currentPage: "Singlekitchenview",
      orderData: item
    });
  };

  showOnlineOrder = (item) => {
    this.setState({
      currentPage: "Singleorderkitchenview",
      onlineorderData: item
    });
  };

  getallTables() {
    this.props.getallTables();
  }

  getOnlineOrders = (filter) => {
    const startDate = moment(new Date()).set({
      hour: 4,
      minute: 0,
      second: 0
    });
    const endDate = moment(new Date()).add(1, "days").set({
      hour: 3,
      minute: 59,
      second: 59
    });
    this.props.getOnlineOrders({
      startDate: startDate,
      endDate: endDate,
      orderType: "active"
    });
  };

  componentWillMount() {
    // console.log("path name", this.props.location.pathname);
    // this.props.updateKitchenStatus();
  }

  // showOrder = id => {
  //   console.log("these are items id ", id);
  //   console.log("items", this.state.items);
  //   let index = this.state.items.findIndex(item => item._id === id);
  //   this.setState({
  //     currentPage: "Singlekitchenview",
  //     orderData: this.state.items[index]
  //   });
  // };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  backBtn = (rUrl) => {
    return (
      <Button
        className="back-btn"
        onClick={() =>
          this.setState({
            currentPage: "KitchenView"
          })
        }
      >
        <i className="fa fa-arrow-circle-left" aria-hidden="true" />
      </Button>
    );
  };
  onlineOrderFromProps = (orderId, pdId) => {
    this.props.updateOnlineOrderKitchenStatus(orderId, pdId).then((res) => {
      // console.log(this.props.updatedKitchenOrder, "updated order kitchens");
      this.setState({
        onlineorderData: this.props.updatedKitchenOrder
      });
    });
  };

  render() {
    let { activeTables, driveOut, noTable, takeAway } = this.props.allTables;
    // console.log(this.props.allTables);
    const Completionist = () => (
      <span style={{ color: "red", fontWeight: "bold" }}>
        Ordre Tid Fullført!
      </span>
    );
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          <span>
            {hours}:{minutes}:{seconds}
          </span>
        );
      }
    };
    //console.log(this.state);
    const { error, isLoaded } = this.state;

    // console.log(items);
    if (this.state.currentPage == "KitchenView") {
      if (isLoaded) {
        return (
          <Home>
            <div className="report-container text-center">
              <span className="item ">{this.refer}</span>
            </div>
            <Loader />
          </Home>
        );
      } else if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        // console.log(items);
        return (
          <Home>
            <div className="report-container text-center">
              <span className="item ">{this.refer}</span>
            </div>

            <div
              className={
                this.props.isBilling
                  ? "dashboard-billing"
                  : "dashboard-main-wrapper"
              }
            >
              <Row className="dashboard-flex">
                {/* {activeTable.length > 0 ? ( */}
                <KitchenHeading heading="AKTIV BORD" />
                <KitchenTable
                  activeTable={activeTables}
                  showTable={this.showTable}
                  refer={this.refer}
                />
                {/* {takeAway.length > 0 ? ( */}
                <KitchenHeading heading="TA-MED" />
                <KitchenTable
                  activeTable={takeAway}
                  showTable={this.showTable}
                  refer={this.refer}
                />
                {/* {driveOut.length > 0 ? ( */}
                <KitchenHeading heading="UTKJØRING" />
                <KitchenTable
                  activeTable={driveOut}
                  showTable={this.showTable}
                  refer={this.refer}
                />
                {/* {noTable.length > 0 ? ( */}
                <KitchenHeading heading="INGEN - BORD" />
                <KitchenTable
                  activeTable={noTable}
                  showTable={this.showTable}
                  refer={this.refer}
                />
              </Row>
              {/*  starting to show online orders */}
            </div>

            {this.state.pendingOnlineOrders.length > 0 ? (
              <KitchenHeading heading="  ONLINE ORDRE" />
            ) : (
              ""
            )}
            <div style={{ padding: "20px" }}>
              <Row gutter={16}>
                <OnlineOrder
                  pendingOnlineOrders={this.props.orders}
                  showOnlineOrder={this.showOnlineOrder}
                  refer={this.refer}
                ></OnlineOrder>
              </Row>
            </div>
          </Home>
        );
      } else {
        return <Home />;
      }
    } else if (this.state.currentPage === "Singlekitchenview") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span> Ordre Detaljer</span>
            </span>
            {this.backBtn("index")}
          </div>

          <SingleKitchenInvoice
            data={this.state.orderData}
            getallTables={() => this.getallTables()}
            crrurl={this.props.history.location.pathname}
            onlineorder={false}
            updatetableproduct={this.props.updateKitchenStatus}
          />
        </Home>
      );
    } else if (this.state.currentPage === "Singleorderkitchenview") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span> Online Ordre Detaljer</span>
            </span>
            {this.backBtn("index")}
          </div>
          <SingleKitchenInvoice
            data={this.state.onlineorderData}
            crrurl={this.props.history.location.pathname}
            onlineorder={true}
            getallTables={() => this.getOnlineOrders("active")}
            updateOnlineOrderKitchenStatus={this.onlineOrderFromProps}
          />
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    allTables: state.allTables,
    orders: state.onlineOrder.items,
    updatedKitchenOrder: state.onlineOrder.updatedKitchenOrder
  };
};
const KitchenView = connect(mapStateToProps, {
  getallTables,
  updateKitchenStatus,
  getOnlineOrders,
  updateOnlineOrderKitchenStatus
})(KitchenViewComponent);
export default KitchenView;
