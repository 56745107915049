const { LEG_TIL_FILER } = require("./../../action/types");
const initialState = {
  success: null,
  message: "",
  data: {},
};

const legTilFilerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEG_TIL_FILER: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default legTilFilerReducer;
