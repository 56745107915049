import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { leggTilFiler } from "./../../../action/arkiveActions";
import uploadIcon from "./../../../assets/uploadIcon.png";
import pdfImage from "./../../../assets/pdfImageGolden.png";
import { Modal, Radio, Button, Form } from "antd";
import { sendNotification } from "../../../helpers/commFuc";
import { Row, Col, Image, Card } from "react-bootstrap";
import { Icon as RIcon } from "react-icons-kit";
import { folderOpen, folderMinus } from "react-icons-kit/icomoon";

import "./input.css";

const FormItem = Form.Item;
const LeggTilFiler = (props) => {
  // let [isModalVisible, setIsModalVisible] = useState(true);
  let [selectedFile, setSelectedFile] = useState("");
  let [pdfName, setPdfName] = useState("");
  let [pdfStatus, setPdfStatus] = useState("");

  let [icon, setIcon] = useState(uploadIcon);

  let [stateData, setStateData] = useState(props.legTilFiler);

  useEffect(() => {
    setStateData(props.legTilFiler);
  }, [props.legTilFiler]);

  let errorsObj = {
    selectedFile: {
      show: false,
      message: "Required",
    },
    pdfStatus: {
      show: false,
      message: "Required",
    },
  };

  let [errors, setErrors] = useState(errorsObj);

  const inputRef = useRef(null);

  const inputPdf = useRef(null);

  const onChangeInput = (e) => {
    let pdfFile = e.target.files[0];
    let errorsObj = { ...errors };
    let pdfFileName = pdfFile.name.toLowerCase();
    let arr = pdfFileName.split(".");
    if (!(arr[arr.length - 1] === "pdf")) {
      errorsObj.selectedFile.show = true;
      errorsObj.selectedFile.message = "Only pdf files are allowed";
      setErrors(errorsObj);
      return false;
    }
    errorsObj.selectedFile.show = false;
    setErrors(errorsObj);
    setSelectedFile(pdfFile);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setIcon(pdfImage);
    });
    reader.readAsDataURL(pdfFile);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorsObj = { ...errors };
    let flag = false;
    if (!selectedFile) {
      errorsObj.selectedFile.show = true;
      errorsObj.selectedFile.message = "Please select a pdf file";
      flag = true;
    } else {
      errorsObj.selectedFile.show = false;
    }

    if (!pdfStatus) {
      errorsObj.pdfStatus.show = true;
      errorsObj.pdfStatus.message = "please select BETALT or UBETALT";
      flag = true;
    } else {
      errorsObj.pdfStatus.show = false;
    }

    flag ? setErrors(errorsObj) : uploadPdf();
  };

  const onChangePdfStatus = (e) => {
    setPdfStatus(e.target.value);
  };

  const handleCancel = () => {
    props.handleModalVisible(false);
    setPdfStatus("");
    setPdfName("");
  };

  const triggerFile = (e) => {
    inputRef.current.click();
  };
  const uploadPdf = async () => {
    let postData = new FormData();
    postData.append("pdf", selectedFile);
    postData.append("status", pdfStatus);
    postData.append("fileName", pdfName);

    if (pdfStatus && selectedFile) {
      await props.leggTilFiler(postData);
      props.handleModalVisible(false);
    } else {
      let msg = "Please fill and select all the required fields";
      sendNotification("error", msg)
    }
  };

  const ModalOpener = () => (
    <>
      <Modal
        title={null}
        visible={props.isModalVisible}
        centered
        onOk={() => uploadPdf()}
        onCancel={() => handleCancel()}
        width={450}
        footer={null}
      >
        <Row className="text-center legg"><Col>Legg Til Filer</Col></Row>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row className="text-center">
            <Col>
              <FormItem>
                <img
                  src={icon}
                  width="100"
                  height="100"
                  alt="icon"
                  style={{ color: "red", cursor: "pointer"  }}
                  onClick={triggerFile}
                />

                <input
                  ref={inputRef}
                  type="file"
                  name="file"
                  style={{ display: "none" }}
                  size="large"
                  display="none"
                  accept="image/*"
                  onChange={(e) => onChangeInput(e)}
                ></input>

                <p>{selectedFile === "" ? "" : selectedFile.name}</p>
                <p style={{ color: "red" }}></p>
                <p style={{ color: "red" }}>
                  {errors.selectedFile.show ? errors.selectedFile.message : ""}
                </p>
              </FormItem>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <FormItem>
                <input
                  ref={inputPdf}
                  type="text"
                  placeholder=" Enter the pdf name"
                  value={pdfName}
                  onChange={(e) => setPdfName(e.target.value)}
                ></input>
              </FormItem>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <FormItem>
              <Col>
                <Radio.Group onChange={(e) => onChangePdfStatus(e)}>
                  <Radio.Button value="PAID" className="btn btn-fak py-2">
                    {" "}
                    {/* <RIcon icon={folderOpen} size={40} /> */}
                    <p className="mb-0"> Betalt</p>
                  </Radio.Button>

                  <Radio.Button value="UNPAID" className="btn btn-fak py-2">
                    {/* <RIcon icon={folderMinus} size={40} /> */}
                    <p className="mb-0"> Ubetalt</p>
                  </Radio.Button>
                </Radio.Group>
                <p style={{ color: "red" }}>
                  {errors.pdfStatus.show ? errors.pdfStatus.message : ""}
                </p>
              </Col>
            </FormItem>
          </Row>
          <Row className="text-center">
            <Col>
              <Button
                key="submit"
                className="btn btn-ab"
                htmlType="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </Button>
            </Col>
            <Col>
              <Button
                key="back"
                className="btn btn-ab"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );

  return ModalOpener();
};

const mapStateToProps = ({ legTilFiler }) => {
  return { legTilFiler };
};

export default connect(mapStateToProps, { leggTilFiler })(LeggTilFiler);
