import React, { Component } from "react";
import Home from "../Home/index";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import {
  getCategories,
  getStockProducts,
  saveStock
} from "../../action/lagerAction";
import { getAnsatte } from "../../action/ansatteAction";
import productsUnits from "../../helpers/productUnits";
import { sendNotification } from "../../helpers/commFuc";
const FormItem = Form.Item;
const Option = Select.Option;

class Innut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      categories: [],
      products: [],
      quantity: "",
      stockType: "",
      productUnit: "",
      allUnits: [],
      units: [],
      subCatValue: "",
      unitValue: "",
      quantity: ""
    };
  }

  handleStockChange = (value) => {
    this.setState({ stockType: value });
  };

  handleCatChange = (id) => {
    this.props.getStockProducts(id).then(() => {
      let products =
        this.props.stockProduct.length &&
        this.props.stockProduct.map((product) => {
          return { name: product.name, id: product._id };
        });
      let allUnits =
        this.props.stockProduct.length &&
        this.props.stockProduct.map((product) => {
          const pUnit = productsUnits[product.base_unit]
            ? productsUnits[product.base_unit]
            : productsUnits["all"];
          return {
            id: product._id,
            unit: pUnit
          };
        });
      if (products.length)
        this.setState({
          products,
          allUnits,
          subCatValue: "",
          unitValue: "",
          quantity: ""
        });
    });
  };

  handleProdChange = async (id, name) => {
    let unitP = this.state.allUnits.find((e) => {
      return e.id == id;
    });
    this.setState({
      productId: id,
      units: unitP.unit,
      subCatValue: name,
      unitValue: "",
      quantity: ""
    });
  };

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
  };

  handleEmployeeChange = (id) => {
    this.setState({ employeeId: id });
  };
  handleProductChange = (id, value) => {
    this.setState({ productUnit: id, unitValue: value, quantity: "" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { productId, quantity, stockType, productUnit,employeeId } = this.state;
    let data = {
      quantity,
      type: stockType,
      productUnit
    };
    if (stockType === "out") {
      data = {
        ...data,
        id: employeeId
      };
      if (productId && quantity && stockType && productUnit && employeeId) {
        this.props.saveStock(data, productId);
      } else {
        sendNotification("error","All fields are required!")
      }
    }else{
      if (productId && quantity && stockType && productUnit) {
        this.props.saveStock(data, productId);
      } else {
        sendNotification("error","All fields are required!")
      }
    }
    
  };

  componentDidMount = () => {
    let categories = [];
    let employees = [];
    this.props.getCategories().then(() => {
      categories =
        this.props.stockCategories.length &&
        this.props.stockCategories.map((category) => {
          return { name: category.name, id: category._id };
        });
      this.setState({ categories });
    });
    this.props.getAnsatte().then(() => {
      employees =
        this.props.ansatte.length &&
        this.props.ansatte.map((employee) => {
          return {
            name: `${employee.firstName} ${employee.lastName}`,
            id: employee.id
          };
        });
      this.setState({ employees });
    });
  };

  render() {
    const { categories, products, employees, stockType } = this.state;
    return (
      <Home>
        <div className="report-container">
          <span className="item ">Lager: Inn/ut</span>
          <NavLink to="/lager" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
        </div>

        <Card>
          <Card.Body>
            <Col>
              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Row>
                  <Col>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Lager Inn / Lager ut
                      </label>
                      <Select
                        required
                        showSearch
                        onChange={this.handleStockChange}
                        placeholder="Lager Inn / Lager ut"
                        optionFilterProp="children"
                        value={this.state.stockType}
                      >
                        <Option value="in">Lager Inn</Option>
                        <Option value="out">Lager ut</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg kategori
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg kategori"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categories!=undefined && categories.length && categories.map((category) => {
                          return (
                            <Option
                              key={category.id}
                              value={category.name}
                              onClick={() => this.handleCatChange(category.id)}
                            >
                              {category.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg Produkt
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg Produkt"
                        optionFilterProp="children"
                        value={this.state.subCatValue}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {products.map((product) => {
                          return (
                            <Option
                              key={product.id}
                              value={product.name}
                              onClick={() =>
                                this.handleProdChange(product.id, product.name)
                              }
                            >
                              {product.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Velg Produktenhet
                      </label>
                      <Select
                        required
                        showSearch
                        placeholder="Velg Produktenhet"
                        optionFilterProp="children"
                        value={this.state.unitValue}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.units.map((product) => {
                          return (
                            <Option
                              key={product.key}
                              value={product.value}
                              onClick={() =>
                                this.handleProductChange(
                                  product.key,
                                  product.value
                                )
                              }
                            >
                              {product.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col style={{ paddingLeft: "10px" }}>
                    <FormItem>
                      <label style={{ color: "black", marginBottom: "0" }}>
                        Antall
                      </label>
                      <Input
                        type="Number"
                        min="1"
                        placeholder="Antall"
                        value={this.state.quantity}
                        onChange={(e) => this.handleQuantityChange(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col style={{ paddingLeft: "10px" }}>
                    {stockType === "out" ? (
                      <FormItem label="Assign Stock To:">
                        <Select
                          required
                          showSearch
                          placeholder="Opprett Ansatte"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {employees.map((employee) => {
                            return (
                              <Option
                                key={employee.id}
                                value={employee.name}
                                onClick={() =>
                                  this.handleEmployeeChange(employee.id)
                                }
                              >
                                {employee.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormItem className="text-right">
                      <Button type="submit" className="btn-ab">
                        Send
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Card.Body>
        </Card>
      </Home>
    );
  }
}
const mapStateToProps = ({ stockCategories, ansatte, stockProduct }) => {
  return { stockCategories, ansatte, stockProduct };
};
export default connect(mapStateToProps, {
  getCategories,
  getAnsatte,
  saveStock,
  getStockProducts
})(Innut);
