import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";

import axios from "axios";
import Home from "./../Home";
import AdminFunc from "./AdminFunc";

import "./AdminDashboard.css";
import "./style.css";

import appConfig from "../../helpers/appConfig";
const AdminDashboardFunc = () => {
  let [data, setData] = useState([]);
  useEffect(() => {
    let result = data;
    const fetchData = async () => {
      try {
        result = await axios.get(
          `${appConfig.endpoint}/api/v1/employee/adminIcons/list`
        );

        setData(result.data.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      <Home>
        <div className="report-container text-center">
          <span className="item ">A-Bord</span>
        </div>
        <AdminFunc data={data} />
      </Home>
    </>
  );
};

export default AdminDashboardFunc;
