import React, { useState, useEffect } from 'react'
import { DatePicker , Button } from 'antd';
import { Chart } from "react-google-charts";
import { POSTAPI } from '../../Vendor/AllApi';
import appConfig from '../../../helpers/appConfig';
import { Empty } from 'antd';
import { Card } from "react-bootstrap";
import moment from 'moment';
const bid = localStorage.getItem("bid");
const token = localStorage.getItem("token");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;


const YearIncomeGraph = () => {
    const [data, setData] = useState([]);
    const [sDate , setSDate ] = useState("");
    const [eDate , setEDate ] = useState("");
    const [currDate , setCurrDate ] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [time, setTime] = useState(null);
    const [time2, setTime2] = useState(null);
    const [graphDate, setGraphDate] = useState("");
  
  
  
    const [lineChartData, setLineChartData] = useState([]);
  
    useEffect(() => {
      //setLoading(true);
      // filterData(moment(new Date()), "d");
      console.log('----data array-----', lineChartData);
    }, [lineChartData]);


    useEffect(()=>{
      let date = new Date();
      let startDate = moment(date).startOf("year").subtract(2, 'year').add(1, "days")._d;
      let endDate =moment(date).endOf("year").add(1, "days")._d;
      let branchId = bid;
      let data = {};
      data.startDate = startDate;
      data.endDate = endDate;
      data.branchId = bid;
      data.type = "year";
  
      incomeByDaysData(data);
  
      console.log("intial data =====", startDate , endDate , branchId);
  
    },[]);
  
  
    const incomeByDaysData = async (postdata) => {
      const headers = {
        "x-auth-token": token,
      };
      try {
        let resData = await POSTAPI(
          `${appConfig.endpoint}/api/v1/employee/invoice/IncomeAmountDashboard`,
          postdata,
          {
            headers: headers,
          }
          
        );
        console.log("all data" , resData.data)
        if(resData.data.length > 0 ){
          // setData(resData.data);
          // console.log(Object.keys(resData.data).length);
  
          if(resData.data.length > 0){
            let d = resData.data;
  
            let dataArray = [["Date", "Total Amount"]];
            
            d.map((i)=>{
  
              let t = [];
              let thisDate = i._id.year.toString();
              let totalAmount = Math.floor(i.total);
  
              t = [thisDate, totalAmount];
              dataArray.push(t);
  
            })
  
            setLineChartData(dataArray);
            setGraphDate(resData.BeDate);
  
          }
  
        }else{
          console.log("no data")
          setLineChartData([]);
          setGraphDate("");
        }  
      } catch (error) {
        setData([]);
        setGraphDate("");
        console.log(error);
      }
    };

  
//   function filterData(date, dateString) {
//   //  let startDate = dateString[0]
//   //  let endDate = dateString[1]
//   //   console.log( startDate,endDate);
  
//  let startDate = moment(date[0])._d;
//  let endDate =  moment(date[1])._d;
//   console.log( startDate,endDate);
  
//     let payload = {};
//     payload.startDate = startDate;
//     payload.endDate = endDate;
//     payload.branchId = bid;
//     payload.type = "year";
  
  
//     incomeByDaysData(payload)
//   }


function onChange2(date, dateString) {
  console.log(date, dateString);
  setTime(null);
}

function onChange3(date, dateString) {
  console.log(date, dateString);
  setTime2(null);
}

  
function handleDone(){
  let payload = {};
      payload.startDate = sDate;
      payload.endDate = eDate;
      payload.branchId = bid;
      payload.type = "year";
    
    
      incomeByDaysData(payload)


}
  const options = {
   // title: "Year Wise Income",
    curveType: "function",
    legend: { position: "top right" },
    hAxis: {
      title: "Period",
    },
    vAxis: {
      title: "Sales (NOK)",
      minValue: 0
    },
    animation: {
      startup: true, // Enable animation when the chart is first rendered
      duration: 1000, // Animation duration in milliseconds
      easing: 'out', // Animation easing function
    },
      // colors: ['#957927'],
  //backgroundColor: 'red'
  
  };
  return (
    <>
    <Card className='mb-4'>
      <Card.Header className="h4">Sales performance comparison chart : Year Wise </Card.Header>
      </Card>
    <div className="d-flex m-3 justify-content-evenly">
      {/* <RangePicker  format="YYYY" onChange={filterData} />  */}
      <DatePicker
        value={time}
        open={isOpen}
        mode="year"
        placeholder="Start Year"
        format="YYYY"
        onOpenChange={status => {
          if (status) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
        onPanelChange={v => {
          setSDate(moment(v._d).startOf("year").add(1, "days"));
          console.log("start",v);
          setIsOpen(false);
          setTime(v);
        }}
        onChange={onChange2}
      />
        <DatePicker
        value={time2}
        open={isOpen2}
        mode="year"
        placeholder="End Year"
        format="YYYY"
        onOpenChange={status => {
          if (status) {
            setIsOpen2(true);
          } else {
            setIsOpen2(false);
          }
        }}
        onPanelChange={v => {
          setEDate(moment(v._d).endOf("year").add(1, "days"));
          console.log("end",v);
          setIsOpen2(false);
          setTime2(v);
        }}
        onChange={onChange3}
      />
       <Button
                key="back"
                className="btn btn-ab"
                style={{
                    height:"32px",
                    width:"120px"
                  }}
                onClick={() => handleDone()}
              >
                Done
      </Button>
   </div>
   <div>
   {
     lineChartData.length == 0 ?
     <div className="d-flex justify-content-center pt-5">
     <Empty /> 
     </div>
     :
   <>
  
     <Chart
     //chartType="LineChart"
     chartType="ColumnChart"
     width="100%"
     height="400px"
     data={lineChartData}
     options={options}
   />

<div className="d-flex justify-content-center">
              <span className="heading2"> {moment(graphDate.startDate).format('MMM DD, YYYY')} 04:00 am to {moment(graphDate.endDate).format('MMM DD, YYYY')} 03:59 am</span>

              </div>
   </>

   }

   </div>
  </>
  )
}

export default YearIncomeGraph
