import React, { useEffect, useState } from "react";
import { getDailyReport, getDailyReportPrint,viewDailyReport } from "../../../action/ReportAction";
import { connect } from "react-redux";
import "./Reports.css";
import List from "./List";
import ViewReport from "./ViewReport";
import { setDefaultDates } from "../../../helpers/commonDates";
import moment from "moment";

const DailyReport = props => {
  let [page, setPage] = useState("list");
  let [date, setDate] = useState(moment().date()._d);
  let [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    async function fetchData(tSDay, tEDay) {
      const data = {
        startDate: tSDay,
        endDate: tEDay
      };
      setIsLoaded(true);
      await props.getDailyReport(data).then(() => setIsLoaded(false));
    }
    const { tSDay, tEDay } = setDefaultDates(date);
    fetchData(tSDay, tEDay);
  }, [date]);
  const handleDateChange = date => {
    setDate(date);
  };
  const printReportHandler = async () => {
    const { tSDay, tEDay } = await setDefaultDates(date);
    const data = {
      startDate: tSDay,
      endDate: tEDay,
      title: "DAILY REPORTS",
      type: "N"
    };
    setIsLoaded(true);
    await props.getDailyReportPrint(data).then(() => setIsLoaded(false));
  };
  const goBackMainPage = () => {
    props.history.push("/rapporter");
  };
  const renderPage = () => {
    if (page === "list") {
      return (
        <List
          setPage={setPage}
          handleDateChange={handleDateChange}
          printReportHandler={printReportHandler}
          goBackMainPage={goBackMainPage}
          isLoaded={isLoaded}
          dailyReport={props.dailyReport}
          viewDailyReport={props.viewDailyReport}
        />
      );
    } else if (page === "view") {
      return <ViewReport setPage={setPage} />;
    } else {
      return <List />;
    }
  };

  return <>{renderPage()}</>;
};
const mapStateToProps = ({ dailyReport }) => {
  return {
    dailyReport
  };
};
export default connect(mapStateToProps, {
  getDailyReport,
  getDailyReportPrint,
  viewDailyReport
})(DailyReport);
