import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCategories,
  addCategory,
  getStockProduct,
  updateStockCategory,
  deleteStockCategory,
  addCatProduct,
  updateCatProduct,
  deleteProduct
} from "../../action/lagerAction";
import { NavLink } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import Home from "../Home";
import { Table, Input, InputNumber, Popover, Form, Select } from "antd";
import productsUnits from "../../helpers/productUnits";
const Option = Select.Option;
const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productFile: ""
    };
  }
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      category: true,
      visiblePopover: false,
      visibleEditPopover: false,
      editingKey: "",
      popoverId: "",
      deletingKey: "",
      items: [],
      products: [],
      PaginationNo: 1,
      eProductUnit: "",
      eProdLimit: ""
    };
    this.stockCatColumns = [
      {
        title: "Sr. Nr.",
        key: "index",
        width: "10%",
        render: (text, record, index) => {
          return index + (this.state.PaginationNo - 1) * 10 + 1;
        }
      },
      {
        title: "Produkt Kategori Navn",
        key: "name",
        dataIndex: "name",
        editable: true,
        render: (text, record, index) => (
          <a onClick={() => this.handleClick(record._id)}>{text}</a>
        )
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "10%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() => this.updateCategories(form, record._id)}
                        style={{ marginRight: 8 }}
                      >
                        Lagre
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          Avbryt
                        </a>
                      </span>
                    }
                    title="Er du sikker?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      Avbryt
                    </a>
                  </Popover>
                </span>
              ) : (
                <a
                  style={{ color: "#1890ff" }}
                  onClick={() => this.edit(record)}
                >
                  Edit
                </a>
              )}
            </div>
          );
        }
      },
      {
        title: "Slett",
        width: "10%", // width: "15%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        Avbryt
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        Slett
                      </a>
                    </span>
                  }
                  title="Er du sikker?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    Slett
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record._id)}
                  style={{ color: "#F44336" }}
                >
                  Slett
                </a>
              )}
            </>
          );
        }
      }
    ];
    this.stockProdColumns = [
      {
        title: "Sr. Nr.",
        key: "index",
        render: (text, record, index) => {
          return parseInt(record.minQuantity) > parseInt(record.quantity) ? (
            <div
              style={{
                padding: 1,
                margin: "auto",
                height: 24,
                width: 24,
                background: "red",
                borderRadius: "100%"
              }}
            >
              <p style={{ textAlign: "center", color: "#FFFFFF" }}>
                {index + (this.state.PaginationNo - 1) * 10 + 1}
              </p>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>
              {index + (this.state.PaginationNo - 1) * 10 + 1}
            </p>
          );
        }
      },
      {
        title: "Produkt Navn",
        key: "name",
        dataIndex: "name",
        editable: true,
        render: (text, record, index) => (
          <a
            onClick={() =>
              this.handleClick(
                this.state.products[index + (this.state.PaginationNo - 1) * 10]
                  ._id
              )
            }
          >
            {text}
          </a>
        )
      },
      {
        title: "Antall",
        key: "newQty",
        dataIndex: "newQty",
        render: (text, record) => {
          return record.quantity >= 0
            ? record.newQty
              ? record.newQty
              : record.quantity
            : 0;
        }
      },
      {
        title: "Min. Antall",
        key: "newMinQty",
        // editable: true,
        dataIndex: "newMinQty",
        width: "20%",
        render: (select, record) => {
          const editable = this.isEditing(record);
          // const units = productsUnits[record.base_unit]
          //   ? productsUnits[record.base_unit]
          //   : productsUnits["all"];
          const units = productsUnits[record.base_unit]
            ? productsUnits["all"]
            : productsUnits["all"];
          const qty = record.min_qty_unit ? record.min_qty_unit : "";
          return editable ? (
            <>
              <Input
                style={{ width: 80, marginRight: "2px" }}
                type="number"
                onChange={(e) => this.setState({ eProdLimit: e.target.value })}
                placeholder="Min. Antall"
                value={this.state.eProdLimit}
                required
              />
              <Select
                style={{ width: 80 }}
                showSearch
                defaultValue={this.state.eProductUnit}
              >
                {units.map((product) => (
                  <Option
                    value={product.key}
                    label={product.name}
                    key={product.value}
                    onClick={() => this.handleProductChange(product.key)}
                  >
                    {product.value}
                  </Option>
                ))}
              </Select>
            </>
          ) : record.newMinQty ? (
            `${record.newMinQty} ${qty}`
          ) : (
            record.minQuantity
          );
        }
      },
      {
        title: "Edit",
        dataIndex: "operation",
        width: "25%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        onClick={() => this.save(form, record._id, record)}
                        style={{ marginRight: 8 }}
                      >
                        Lagre
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.cancel(record.name)}
                        >
                          Avbryt
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visibleEditPopover}
                    onVisibleChange={this.handleEditVisibleChange}
                  >
                    <a
                      style={{ color: "#1890ff" }}
                      onClick={this.openEditPopover}
                    >
                      Avbryt
                    </a>
                  </Popover>
                </span>
              ) : (
                <a onClick={() => this.edit(record)}>Edit</a>
              )}
            </div>
          );
        }
      },
      {
        title: "Slett",
        width: "15%",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          return (
            <>
              {deletable ? (
                <Popover
                  content={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      <a style={{ color: "#1890ff" }} onClick={this.hideDelete}>
                        Avbryt
                      </a>
                      <a
                        style={{ color: "#F44336" }}
                        onClick={(e) => this.delete(e, record)}
                      >
                        Slett
                      </a>
                    </span>
                  }
                  title="Er du sikker?"
                  trigger="click"
                  visible={true}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <a
                    onClick={() => this.setState({ popoverId: record.id })}
                    style={{ color: "#F44336" }}
                  >
                    Slett
                  </a>
                </Popover>
              ) : (
                <a
                  onClick={() => this.deleteTemp(record._id)}
                  style={{ color: "#F44336" }}
                >
                  Slett
                </a>
              )}
            </>
          );
        }
      }
    ];
  }
  cancel = () => {
    this.setState({ editingKey: "" });
  };
  edit({ _id, min_qty_unit, newMinQty, minQuantity }) {
    let minQty = newMinQty ? newMinQty : minQuantity;
    this.setState({
      editingKey: _id,
      eProductUnit: min_qty_unit,
      eProdLimit: minQty
    });
  }
  openEditPopover = () => {
    this.setState({ visibleEditPopover: true });
  };
  delete(e, record) {
    e.preventDefault();
    const newData = this.state.category
      ? [...this.state.items]
      : [...this.state.products];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1);
      this.state.category
        ? this.setState({
            items: newData,
            editingId: item._id
          })
        : this.setState({
            products: newData,
            editingId: item._id
          });
      this.state.category
        ? this.deleteCategory(item._id)
        : this.deleteProduct(item._id);
    } else {
      newData.push(record);
      this.state.category
        ? this.setState({
            items: newData,
            editingKey: ""
          })
        : this.setState({
            products: newData,
            editingKey: ""
          });
    }
  }
  pageChange = async (pagination) => {
    if (pagination) {
      await this.setState({ PaginationNo: pagination.current });
    }
  };
  deleteTemp(key) {
    this.setState({ deletingKey: key });
  }
  edit({ _id, min_qty_unit, newMinQty, minQuantity }) {
    let minQty = newMinQty ? newMinQty : minQuantity;
    this.setState({
      editingKey: _id,
      eProductUnit: min_qty_unit,
      eProdLimit: minQty
    });
  }
  isEditing = (record) => {
    return record._id === this.state.editingKey;
  };
  isDeleting = (record) => {
    return record._id === this.state.deletingKey;
  };
  handleVisibleChange = () => {
    this.setState({ visiblePopover: true });
  };
  handleClick = (id) => {
    if (this.state.category) {
      this.setState({ isLoaded: true });
      this.productPage(id);
    } else {
      console.log("beep bop");
    }
  };
  productPage = (id) => {
    this.props.getStockProduct(id).then(() =>
      this.setState({
        isLoaded: false,
        catId: id,
        newItem: "",
        category: false,
        products: this.props.catProduct
      })
    );
  };
  handleProductChange = (id) => {
    this.setState({ productUnit: id });
    this.setState({ eProductUnit: id });
  };
  hideDelete = () => {
    this.setState({ deletingKey: "" });
  };
  save(form, key, record) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = this.state.category
        ? [...this.state.items]
        : [...this.state.products];
      const index = newData.findIndex((item) => key === item._id);
      if (index > -1) {
        const item = newData[index];
        const qu = this.state.eProductUnit;
        const nMq = this.state.eProdLimit;
        if (!qu || !nMq) {
          return;
        }
        item["min_qty_unit"] = qu;
        item["newMinQty"] = nMq;
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.state.category
          ? this.setState({
              items: newData,
              editingKey: ""
            })
          : this.setState({
              products: newData,
              editingKey: ""
            });

        this.state.category
          ? this.updateCategory(row.name, item._id)
          : this.updateProduct(
              row.name,
              item.newMinQty,
              item._id,
              item.min_qty_unit
            );
      } else {
        newData.push(row);
        this.state.category
          ? this.setState({
              items: newData,
              editingKey: ""
            })
          : this.setState({
              products: newData,
              editingKey: ""
            });
      }
    });
    this.cancel(record.name);
  }
  updateCategories = (form, key) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.items];
      const index = newData.findIndex((item) => key === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState({
          items: newData,
          editingKey: ""
        });
        this.props.updateStockCategory({ name: row.name, id: item._id });
      } else {
        newData.push(row);
        this.setState({ items: newData, editingKey: "" });
      }
    });
  };

  updateProduct = (name, minQuantity, id, min_qty_unit) => {
    const data = { name, minQuantity, min_qty_unit, id };
    this.setState({ isLoaded: true });
    this.props
      .updateCatProduct(data)
      .then(() => this.setState({ isLoaded: false }));
  };

  deleteCategory = (id) => {
    this.props
      .deleteStockCategory(id)
      .then(() => this.setState({ visiblePopover: false }));
  };

  deleteProduct = (id) => {
    this.props
      .deleteProduct(id)
      .then(() => this.setState({ visiblePopover: false }));
  };
  componentDidMount() {
    this.props
      .getCategories()
      .then(() => this.setState({ items: this.props.stockCategories }));
  }
  handleAddCategory = async (e) => {
    e.preventDefault();
    const { newItem } = this.state;
    this.setState({ isLoaded: true });
    await this.props
      .addCategory({ name: newItem })
      .then(() => this.setState({ newItem: "" }));
    await this.props
      .getCategories()
      .then(() =>
        this.setState({ items: this.props.stockCategories, isLoaded: false })
      );
  };
  handleAddProduct = () => {
    const { newItem, catId, prodLimit, productUnit } = this.state;
    if (newItem && prodLimit && productUnit) {
      const data = {
        name: newItem,
        prodLimit: prodLimit,
        productUnit: productUnit,
        catId
      };
      this.props.addCatProduct(data).then(() => this.productPage(catId));
    }
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const stockCatColumns = this.stockCatColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    const stockProdColumns = this.stockProdColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "quantity" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });
    const { isLoaded, category } = this.state;

    return (
      <Home>
        <div className="report-container">
          {category ? (
            <>
              <span className="item ">Lager: Produkt kategorier</span>
              <NavLink to="/lager" className="back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </NavLink>
            </>
          ) : (
            <>
              <span className="item ">Lager/ Produkt kategorier:Produkter</span>
              <Button
                onClick={() => this.setState({ category: true })}
                className="back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </>
          )}
        </div>
        <Card>
          <Card.Body>
            {category ? (
              <>
                <Form
                  onSubmit={this.handleAddCategory}
                  required
                  className="m-b-1 d-flex justify-content-between"
                >
                  <Input
                    style={{ marginRight: "10px" }}
                    type="text"
                    onChange={(e) => this.setState({ newItem: e.target.value })}
                    placeholder="Kategori Navn"
                    value={this.state.newItem}
                    required
                  />

                  <Button
                    className="btn-ab btn-sm"
                    onClick={this.handleAddCategory}
                  >
                    Legg Til Kategori
                  </Button>
                </Form>
                <Table
                  rowKey={(record) => record.name}
                  loading={isLoaded}
                  onChange={(pagination) => this.pageChange(pagination)}
                  components={components}
                  bordered
                  dataSource={
                    this.state.items && this.state.items.length
                      ? this.state.items
                      : []
                  }
                  columns={stockCatColumns}
                  rowClassName="editable-row"
                />
              </>
            ) : (
              <>
                <Form className="m-b-1">
                  <Input
                    style={{ width: 200, marginRight: "10px" }}
                    onChange={(e) => this.setState({ newItem: e.target.value })}
                    placeholder="Produkt Navn"
                    value={this.state.newItem}
                  />
                  <Input
                    style={{ width: 200, marginRight: "10px" }}
                    type="number"
                    onChange={(e) =>
                      this.setState({ prodLimit: e.target.value })
                    }
                    placeholder="Min. Antall"
                    value={this.state.prodLimit}
                    required
                  />
                  <Select
                    style={{ width: 200, marginRight: "10px" }}
                    showSearch
                    placeholder="Enhet Produkt"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productsUnits["all"].map((product) => {
                      return (
                        <Option
                          key={product.key}
                          value={product.key}
                          onClick={() => this.handleProductChange(product.key)}
                        >
                          {product.value}
                        </Option>
                      );
                    })}
                  </Select>
                  <Button
                    onClick={this.handleAddProduct}
                    className="btn-ab btn-sm"
                  >
                    Legg Til Produkt
                  </Button>
                </Form>
                <Table
                  rowKey={(record) => record.name}
                  loading={isLoaded}
                  onChange={(pagination) => this.pageChange(pagination)}
                  components={components}
                  bordered
                  dataSource={
                    this.state.products && this.state.products.length
                      ? this.state.products
                      : []
                  }
                  columns={stockProdColumns}
                  rowClassName="editable-row"
                />
              </>
            )}
          </Card.Body>
        </Card>
      </Home>
    );
  }
}
const mapStateToProps = ({ stockCategories, catProduct }) => {
  return { stockCategories, catProduct };
};
export default connect(mapStateToProps, {
  getCategories,
  addCategory,
  getStockProduct,
  updateStockCategory,
  deleteStockCategory,
  addCatProduct,
  updateCatProduct,
  deleteProduct
})(List);
