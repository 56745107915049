import React from "react";
import { NavLink } from "react-router-dom";
import TflBanner from "./../../../assets/tfllogo (1).png";
import "./tfl.css";
import { Progress } from "antd";
import { Card, Row, Col, Button, Image, Container } from "react-bootstrap";
import Home from "./../../Home";
const TflReport = (props) => {
  let tflamount;
  tflamount = Number(localStorage.getItem("tflamount"))
    ? Number(localStorage.getItem("tflamount"))
    : 0;

  /*---converting tfl point to progress bar --------*/
  const perValue = (tflamount / 10000) * 100;

  return (
    <>
      <Home>
        <div className="report-container">
          <span className="item ">
            <span>Arkiv : </span> TFL Rapport
            <NavLink to="/arkiv" className="btn back-btn">
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </NavLink>
          </span>
        </div>
        <div className="p-5">
          <Row className="justify-content-center py-3">
            <img src={TflBanner} height="350px" />
          </Row>

          <Row className="pt-5">
            <Progress percent={perValue} format={(percent) => `${tflamount} Points` } />
          </Row>
          <Row className="p-5 justify-content-center">
            <h2 style={{ fontSize: "30px", fontWeight:"700" }}>
              TFL POINTS: {`${tflamount}`}/ 10,000
            </h2>
          </Row>
        </div>
        {/* <Card className="mt-5">
          <Card.Body>
            <Row>
            <Col className="tfl_banner">
                <div className="tfl_logo">

                <div className="tfl_Points"><h2 style={{fontSize: "30px",color:"white"}}>TFL POINTS:{`${tflamount}`}</h2></div>
                </div>
              
                <Row className="text-center">
                  <Col>
                    {" "}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}
      </Home>
    </>
  );
};

export default TflReport;
