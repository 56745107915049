import React, { Component } from "react";
import Home from "../Home/Home";
import { connect } from "react-redux";
import { push } from "react-router-redux";

class invoiceComponent extends Component {
  render() {
    return (
      <Home isAdmin={true}>
        <div className="report-container">
          <span className="item">REPORTS</span>
          <span className="item">
            <span id="less-visible">Hjem / </span>
            Reports
          </span>
        </div>
        <div className="different-reports">
          <div id="flex-stock">
            <button
              onClick={() => {
                this.navigate("/unpaid");
              }}
              className="box shadow orange-bg"
            >
              <p className="text-items">INVOICE PENDING</p>
            </button>
          </div>
        </div>
      </Home>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const Invoice = connect(mapStateToProps)(invoiceComponent);
export default Invoice;
