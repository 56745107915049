import axios from "axios";
import {
  UPDATE_STATUS_kITCHEN,
  ERROR_MASSAGE,
  UPDATE_ORDER_STATUS_kITCHEN
} from "./types";
import appConfig from "../helpers/appConfig";
import Item from "antd/lib/list/Item";
import {isServerLogout} from "../utils/isServerLogout";
export const updateKitchenStatus = (tableId, pdId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${appConfig.endpoint}/api/v1/employee/kitchen/updatekitchenstatus`,
        {
          tableId: tableId,
          pdId: pdId
        }
      );
      //console.log(res.data, "table");

      dispatch({
        type: UPDATE_STATUS_kITCHEN,
        payload: {
          tableId: res.data.data._id,
          orderType: res.data.data.orderType,
          items: res.data.data.items
        }
      });
    } catch (error) {
      
      if (error.response) {

        if (error.response.status === 401) {
          isServerLogout(error.response)
        } else {
          dispatch({ type: ERROR_MASSAGE, payload: error.response });
        }
      }
    }
  };
};

export const updateOnlineOrderKitchenStatus = (orderId, pdId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${appConfig.endpoint}/api/v1/employee/kitchen/updateorderkitchenstatus`,
        {
          orderId: orderId,
          pdId: pdId
        }
      );
      //console.log(res.data, "table");

      dispatch({
        type: UPDATE_ORDER_STATUS_kITCHEN,
        payload: {
          orderId: res.data.data._id,
          items: res.data.data.items,
          order: res.data.data
        }
      });
    } catch (error) {
      
      if (error.response) {

        if (error.response.status === 401) {
          isServerLogout(error.response)
        } else {
          dispatch({ type: ERROR_MASSAGE, payload: error.response });
        }
      }
    }
  };
};
