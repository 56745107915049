import React, { Component } from "react";
import { connect } from "react-redux";
import Home from "../Home/Home";
import { Button, Col, Row, notification } from "antd";
import "./AdminDashboard.css";

import appConfig from "../../helpers/appConfig";

class AdminDashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

    this.fetchData = this.fetchData.bind();
    this.openNotification = this.openNotification.bind();
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      let result = await fetch(
        `${appConfig.endpoint}/api/v1/employee/adminIcons/list`,
        {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.token,
            "Content-Type": "application/json"
          }
        }
      ).then((result) => result.json());

      if (result.success === -1) {
        // Not Logged In
        this.openNotification("error", "Please login again to continue");
        this.props.history.push("/");
        return;
      } // Not Logged In

      if (result.success === 0) {
        this.openNotification("error", result.message);
        return;
      }

      this.setState({
        data: result.data.data
      });
    } catch (error) {
      this.openNotification("error", error.message);
    }
  };

  openNotification = (type, message) => {
    if (message.errors) {
      if (message.errors.length) {
        message = message.errors[0].msg;
      } else {
        message = "Something went wrog, please try again";
      }
    }

    notification[type]({
      message: message
    });
  };

  render() {
    return (
      <Home isAdmin={true}>
        <div
          className={
            this.props.isBilling
              ? "dashboard-billing"
              : "dashboard-main-wrapper"
          }
        >
          <Row className="m dashboard-flex">
            {this.state.data.map((adminIcon, index) => {
              return (
                <Col className="items gutter-row" key={index}>
                  <div className="button-style">
                    <Button
                      style={{
                        marginBottom: "3.5em"
                      }}
                      className="drinks button-size-icon"
                      shape="circle"
                    >
                      <div className="menu-options white">
                        <img
                          src={`${appConfig.s3url}/${adminIcon.path}`}
                          className="product-image invert-c"
                          title={adminIcon.name}
                        />
                      </div>
                     <div className="menu-options details">
                        <span className="menu-options price">
                          {adminIcon.name}
                        </span>
                      </div>
                    </Button>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Home>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const AdminDashboard = connect(mapStateToProps)(AdminDashboardComponent);
export default AdminDashboard;
