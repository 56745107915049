import axios from "axios";
import appConfig from "../helpers/appConfig";
import { GET_ANSATTE, ERROR_MASSAGE, SUCCESS_MESSAGE } from "./types";
import { isServerLogout } from "../utils/isServerLogout";
export const getAnsatte = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/ansatte`
    );
    dispatch({
      type: GET_ANSATTE,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const editAnsatte = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/ansatte`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "editAnsatte" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const deleteAnsatte = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/ansatte/delete`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "deleteAnsatte" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const addAnsatte = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/ansatte`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "addAnsatte" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const getEmpPermissions = (data) => {
  return {
      type: 'GET_EMP_PERMISSIONS',
      payload: data
  }
}

export const getSwitchPermissions = (data) => {
  return {
      type: 'GET_SWITCH_PERMISSIONS',
      payload: data
  }
}