import React, { useState, useEffect } from "react";
import moment from "moment";
import Countdown from "react-countdown";
import { Button, Card, Container } from "react-bootstrap";
import {
  Table,
  DatePicker,
  Row,
  Col,
  Radio,
  Modal,
  notification,
  Icon,
  TimePicker,
} from "antd";
import { Alert } from "antd";
import "./orders.css";
import appConfig from "../../../helpers/appConfig";
import { GETAllAPI, POSTAPI } from "../AllApi";
import history from "../../../history";
//import Modal from 'react-bootstrap/Modal';
import "./orders.css";

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const { Column } = Table;
const { Meta } = Card;


const SingleRetailerOrder = (props) => {
    let { setSingleOrder, statusChanged, setStatusChanged } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [onlineDate, setOnlineDate] = useState("");
    const [dataSource, setDataSource] = useState();
    const [confirmShow, setConfirmShow] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [cancel, setCancel] = useState("");
    const [onlineStatus, setOnlineStatus] = useState("all");
    const [inputDate, setInputDate] = useState();
    const [timeString, setTimeString] = useState();
    const [value, setValue] = useState("");
  
    const printbill = async () => {
      window.print();
    };
    const bid = localStorage.getItem("bid");
    const token = localStorage.getItem("token");
  
    const handleClose = () => {
      setConfirmShow(false);
    };
  
    const handleShow = () => {
      setConfirmShow(true);
    };
  
    const handleClosed = () => {
      setConfirmCancel(false);
    };
  
    const handleCancel = () => {
      setConfirmCancel(true);
    };
  
    const headers = {
      "x-auth-token": token,
    };
    //console.log(" address======", props.orderData.customerDetail.address.length > 1 )
    let IncompeleteOrder = async (data) => {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/vendor/orders/confirmRetailerOrder`,
        data,
        {
          headers: headers,
        }
      );
  
      if (resData.status == 200) {
        if (props.orderData.orderStatus == "PENDING" && !cancel) {
          notification.open({
            message: "Order confirmed successfully",
            icon: <Icon type="check-circle" style={{ color: "green" }} />,
            duration: 2,
          });
        } else if (props.orderData.orderStatus == "IN-PROCESS" && !cancel) {
          notification.open({
            message: "Invoice generated and order delivered",
            icon: <Icon type="check-circle" style={{ color: "green" }} />,
            duration: 2,
          });
        } else if (cancel) {
          notification.open({
            message: "Order cancelled successfully",
            icon: <Icon type="close-circle" style={{ color: "red" }} />,
            duration: 2,
          });
        }
        else {
          console.log("error");
        }
        history.push("/retailerOrders");
        setSingleOrder(false);
        setStatusChanged(!statusChanged);
      }
    };
    //console.log("value=====", value , requestedTime)
    // console.log("=====value from getvendor orders======")
  
    const changeOrderStatus = (orderStatus, reqDate , reqTime , tdTime) => {
      console.log("date yuttttttttttttt====== ", reqTime , reqDate , props.orderData.createdAt)
      setConfirmShow(false);
   
      // sending  payload
      let postData = {};
        postData = {   
          //branchId:bid,
          orderId: props.orderData._id,
          orderStatus: orderStatus,
          deliveryTime:  inputDate ? inputDate : reqDate,
          cancelReason: cancel,
          deliverySlot: value ? value : reqTime,
        };
     // }
      
      console.log("post data ----", postData);
      IncompeleteOrder(postData);
    };
  
    const handleReason = (val) => {
      setCancel(val);
    };
  
  
    const cancelReason = () => {
      // console.log("submit")
      if (!cancel) {
        notification.open({
          message: "Please select cancel reason",
          icon: <Icon type="close-circle" style={{ color: "red" }} />,
          duration: 2,
        });
      } else {
        changeOrderStatus("CANCEL");
      }
    };
  
  
    const selectDate = (date) => {
      let dd = moment(date).format();
      //let dd2 = moment(reqDate).format();
      
      
      // let m, d, y;
  
      // let dd = new Date(date);
      // m = dd.getMonth()
      // d = dd.getDate();
      // y = dd.getFullYear();
  
      // dd = d+"/"+m+"/"+y;
      // console.log("======date =====", dd, date._d)
  
      console.log("---moment date---", dd);
  
      setInputDate(dd);
      if (dd ) {
        setDisabled(false);
        console.log("---moment date---", dd);
      }
      else{
        console.log("---moment date---",);
      }
    };   
  
  useEffect(()=>{
    // onChange(); 
    // onChangetime();
  },[])
    const onChangetime = (time, timeString) => {
        setValue(timeString);
        setInputDate(props.orderData.createdAt);
      
    };
  
    const onChange = (e) => {
      console.log("TIME SLOT ====",e.target.value )
      // if(props.orderData.customerType == "tdUser" && deliverySlot
      //   == "Morning 9.00 AM to 12.00 PM"){
      //     notification.open({
      //       message: "Please enter time slot for delivery",
      //       icon: <Icon type="check-circle" style={{ color: "red" }} />,
      //       duration: 2,
      //     })}
      setValue(e.target.value);
    };
  
    let orderArr = [];
    orderArr.push(props.orderData);
    //console.log("array=====>",orderArr)
    useEffect(() => {
      console.log("itemmmm");
    }, [itemArr]);
    let itemArr = props.orderData.items;
    let totalQuantity = 0;
    let totalPrice = 0;
    itemArr.map((e) => {
      totalQuantity += e.quantity;
      totalPrice += e.totalPrice;
    });
    //console.log("total quantity====",totalQuantity)
  
    let tdServiceCharge = props.orderData.tdServiceCharge;
    const DriveOutColumns = (totalQuantity, totalPrice) => [
      {
        title: "Total Unit Price",
        render: () => {
          return `Kr.${totalPrice.toFixed(2)}`;
        },
      },
      {
        title: `Service Charge`,
        render: (record, text) => record.tdServiceCharge.toFixed(2),
      },
      {
        title: "Drive Out Charges",
        render: (record, text) => record.driveoutCharge.toFixed(2),
      },
      {
        title: "Total quantity",
        render: () => {
          return `${totalQuantity}`;
        },
      },
      {
        title: "Total",
        render: (text, record) => {
          return `Kr.${record.totalPrice.toFixed(2)}`;
        },
      },
    ];
  
    let CustomerName = props.orderData.customerDetail.name;
    let resPhone = props.orderData.customerDetail.phoneNo;
    let CustomerEmail = props.orderData.customerDetail.email;
    let Address = props.orderData.customerDetail.address ? props.orderData.customerDetail.address : "";
    let userEmail = props.orderData.customerDetail.userEmail;
    let customerPhone = props.orderData.customerDetail.customerPhone;
    let reqDate = props.orderData.requestedTime;
    let requestedTime = moment(props.orderData.requestedTime).format(
      "DD,MMMM,YYYY"
    );
    let deliveryTime = moment(props.orderData.deliveryTime).format("DD,MMMM,YYYY");
    let deliverySlot = props.orderData.deliverySlot;
    console.log("length=======", props.orderData.customerDetail.customerPhone
    );
  
  return (
    <Container fluid className="p-0">
    <Row className="justify-content-between bg-white p-0" id="displayrow">
      <Col className="p-2 ">
        <div className="d-flex justify-content-between">
          <div className="justify-content-between" id="tablePrint">
            <>
              <p id="tablePrint">
                <p className="orderType">
                  Order Type : {props.orderData.orderType}
                </p>
                <strong>OTP: {props.orderData.otp}</strong>
                <p>Name: {CustomerName ? CustomerName : ""}</p>
                <p> Phone No.:  +{props.orderData.customerDetail.countryCode ? props.orderData.customerDetail.countryCode : ""} {resPhone ? resPhone : ""}</p>                
                <p> Email: {CustomerEmail ? CustomerEmail : ""} </p>
              </p>  
              {
              props.orderData.orderType == "Drive Out" ?
              <p>
                <strong>Address: {props.orderData.address.locationAddress}</strong>
              </p>
              :""
              }
              {props.orderData.orderStatus == "IN-PROCESS" &&
                requestedTime.length ? (
                  <p>
                    Delivery Time : {deliveryTime} {deliverySlot.length > 0 ? <>at {deliverySlot}</>  : ""}
                  </p>
                ) : (
                  " "
                )} 
              {props.orderData.orderStatus == "CANCEL" ? (
                <span id="tablePrint">
                  <p>
                    Order Status:{" "}                
                    <b style={{ color: "red", fontWeight: "bold" }}>
                      CANCELLED
                    </b>
                  </p>
                  <p style={{ color: "red", fontSize: "16px" }}>
                    <b>Cancel Reason : </b>
                    {props.orderData.cancelReason}
                  </p>
                </span>
              ) : (
                ""
              )}
              {props.orderData.orderStatus == "PENDING" && (
                <>
                  <div className="d-flex">
                    <Button
                      onClick={() => handleShow()}
                      style={{
                        background: "transparent",
                        border: "1px solid black",
                        color: "black",
                        marginRight: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      Confirm
                    </Button>
                    <Modal
                      open={isModalOpen}
                      onCancel={handleCancel}
                      //title="Select Delivery Date and Timeslot"
                      className="model123"
                      visible={confirmShow}
                      footer={[
                        <Button
                          onClick={(e) => changeOrderStatus("IN-PROCESS" ,"8898" ,props.orderData.reqdeliverySlot ,props.orderData.createdAt)}
                         // disabled={disabled}
                        >
                          Submit
                        </Button>,
                        <Button
                          key="back"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>,
                      ]}
                      closable={true}
                    >
                {
                  props.orderData.customerType == "restaurant" && props.orderData.requestedTime.length > 0 ?
                  <>
                  {console.log("ggdgjdsfjhskfd", props.orderData.requestedTime.length)}
                  <p className="heading-Req">Requested delivery date and time slot</p>
                    <div className="d-flex reqTime">
                    <div className="mt-2">
                      
                      <p className="pl-2">Time slot: {props.orderData.reqdeliverySlot ? props.orderData.reqdeliverySlot : "" }</p>        
                    </div>
                    <div className="justify-content-end mycontent-right2"
                    style={{ float:"right" }}>
                    <Button
                    //className=""
                          onClick={(e) => changeOrderStatus("IN-PROCESS","77898" ,props.orderData.reqdeliverySlot)}
                          //disabled={disabled}
                        >
                          Submit
                        </Button>
                        <Button
                          key="back"
                          className="btn btn-secondary ml-2"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                        </div>
                        </div>
                  </> : ""
                }

                      {
                        props.orderData.customerType == "restaurant " ?
                        <p className="heading-Req">Change delivery date and time slot</p>
                        :   <p className="heading-Req">select delivery time slot</p>
                      }
                      <div className="d-flex">
                      <div className="timeslot ml-2">
                      {
                        props.orderData.customerType == "restaurant" ?
                        <DatePicker
                          //defaultValue={moment('2019-09-03')}
                            style={{ width: "200px" }}
                      onChange={(date, dateString) => {                                                                              
                              selectDate(date);
                            }}
                            format="YYYY-MM-DD"
                          placeholder="Change Date"
                          />
                          : ""
                          
                      } {/* <p className=" delivery pr-2"
                          >Delivery Date :
                          {moment(props.orderData.createdAt).format("DD,MMMM,YYYY")}</p> */}
                          </div>
                          {props.orderData.customerType == "tdUser" ? 
                            <div className="timeslot ml-2">
                              <TimePicker
                                style={{ width: "80%" }}
                                onChange={onChangetime}
                                
                                //defaultValue={moment("00:00", "HH:mm")}
                                format={"HH:mm"}
                              />
                            </div> : 
                              <Radio.Group onChange={onChange} value={value} 
                              //defaultValue={"Morning 9.00 AM to 12.00 PM"}
                              >
                                  <Radio className = "timeslott" value="Morning 9.00 AM to 12.00 PM">
                                    Morning 9.00 AM to 12.00 PM
                                  </Radio>
                                  <Radio className = "timeslott" value="Afternoon 12.00 PM to 3.00 PM">
                                    Afternoon 12.00 PM to 3.00 PM
                                  </Radio>
                                  {" "}
                                  <Radio className = "timeslott" value="Evening 3.00 PM to 6.00 PM">
                                    Evening 3.00 PM to 6.00 PM
                                  </Radio>
                              </Radio.Group>
                          }
                        </div>
                    </Modal>
                    <Button
                      onClick={handleCancel}
                      style={{
                        background: "transparent",
                        border: "1px solid black",
                        color: "black",
                        marginBottom: "20px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Modal
                      title="Select Reason for canceling order"
                      visible={confirmCancel}
                      footer={[
                        <Button
                          // disabled={cancel?false:true}
                          onClick={(e) => cancelReason()}
                        >
                          Submit
                        </Button>,
                        <Button
                          key="back"
                          className="btn btn-secondary"
                          onClick={() => handleClosed()}
                        >
                          Cancel
                        </Button>,
                      ]}
                      closable={false}
                    >
                      <div
                        style={{ textAlign: "center", alignSelf: "center" }}
                      >
                        <Radio.Group className="selected">
                          <Radio.Button
                            value="Item Not Available"
                            onClick={(e) => handleReason(e.target.value)}
                          >
                            Items Not present
                          </Radio.Button>
                          <Radio.Button
                            value="No One to deliver"
                            onClick={(e) => handleReason(e.target.value)}
                          >
                            No One to deliver
                          </Radio.Button>
                          <Radio.Button
                            value="Restaurant closed"
                            onClick={(e) => handleReason(e.target.value)}
                          >
                            Restaurant Closed
                          </Radio.Button>
                          <Radio.Button
                            value=" Not accepting orders"
                            onClick={(e) => handleReason(e.target.value)}
                          >
                            Not accepting orders
                          </Radio.Button>
                        </Radio.Group>
                        <p className="text-center text-danger"></p>
                      </div>
                    </Modal>
                  </div>
                </>
              )}

              {props.orderData.orderStatus == "IN-PROCESS" && (
                <div>
                  <Button
                    onClick={(e) => changeOrderStatus("DELIVERED")}
                    style={{
                      background: "orange",
                      border: "1px solid black",
                      color: "black",
                      marginRight: "15px",
                      marginBottom: "20px",
                    }}
                  >
                    Handover
                  </Button>

                  <Button
                    onClick={handleCancel}
                    style={{
                      background: "transparent",
                      border: "1px solid black",
                      color: "black",
                      marginBottom: "20px",
                    }}
                  >
                    Cancel
                  </Button>
                  <Modal
                    title="Select Reason for canceling order"
                    visible={confirmCancel}
                    footer={[
                      <Button
                        // disabled={cancel?false:true}
                        onClick={(e) => cancelReason()}
                      >
                        Submit
                      </Button>,
                      <Button
                        key="back"
                        className="btn btn-secondary"
                        onClick={() => handleClosed()}
                      >
                        Cancel
                      </Button>,
                    ]}
                    closable={false}
                  >
                    <div style={{ textAlign: "center", alignSelf: "center" }}>
                      <Radio.Group>
                        <Radio.Button
                          value="Item Not Available"
                          onClick={(e) => handleReason(e.target.value)}
                        >
                          Items Not present
                        </Radio.Button>
                        <Radio.Button
                          value="No One to deliver"
                          onClick={(e) => handleReason(e.target.value)}
                        >
                          No One to deliver
                        </Radio.Button>
                        <Radio.Button
                          value="Restaurant closed"
                          onClick={(e) => handleReason(e.target.value)}
                        >
                          Restaurant Closed
                        </Radio.Button>
                        <Radio.Button
                          value=" Not accepting orders"
                          onClick={(e) => handleReason(e.target.value)}
                        >
                          Not accepting orders
                        </Radio.Button>
                      </Radio.Group>
                      <p className="text-center text-danger"></p>
                    </div>
                  </Modal>
                </div>
              )}
            </>
          </div>
          <div
            className="justify-content-end mycontent-right"
            style={{ textAlign: "right" }}
            id="tablePrint"
          >
            {/* <Button
              onClick={() => printbill()}
              type="primary"
              size="large"
              className="btn-ab  text-color"
            >
              Print
            </Button> */}

            <>
              <h5 className="pt-2">Instruksjoner av kunden</h5>
              {props.orderData.items.map((e) => {
                return (
                  <p key={e._id}>
                    For {e.quantity} {e.name}
                    <br />
                  </p>
                );
              })}
            </>
          </div>
        </div>
        <div className="d-flex flex-column">
          <Table
            id="tablePrint"
            bordered
            dataSource={props.orderData.items}
            pagination={false}
            style={{ borderBlockStartColor: "red" }}
          >
            <Column title="Product Name" dataIndex="name" key="name" />
            <Column title="Item No." dataIndex="itemNo" key="itemNo" />
            <Column
              title="Unit Price"
              //dataIndex="price"
              // render={(text) => `Kr.${Number(text*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2)}`}
              render={(text, render) =>
                `Kr.${Number(render.price).toFixed(2)}`
              }
              key="price"
            />
            <Column
              title="Quantity"
              key="quantity"
              render={(text, render) =>
                `${render.quantity}pkt. X ${render.availQuantity}${render.unitType}`
              }
            />
            {/* <Column title="Discount" 
             render={(text, render) =>
              `Kr.${Number(render.discount).toFixed(2)}`
              }
             /> */}

            <Column
              title="Total Price"
              dataIndex="totalPrice"
              render={(text, record) =>
                `Kr.${Number(record.totalPrice).toFixed(2)}`
              }
              key="totalPrice"
            />
          </Table>
          <Table
            style={{ marginTop: "20px" }}
            pagination={false}
            id="tablePrint"
            //loading={loading}
            columns={DriveOutColumns(totalQuantity, totalPrice)}
            dataSource={orderArr}
            striped
            bordered
          />
        </div>
      </Col>
    </Row>
  </Container>
  )
}

export default SingleRetailerOrder
