/* eslint-disable*/
import { createStore as _createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import clientMiddleware from "./clientMiddleware";
import { routerMiddleware } from "react-router-redux";
import reducer from "./index";

export default function createStore(history, client, data) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(history);
  const middleware = [
    thunk.withExtraArgument(client),
    clientMiddleware(client),
    reduxRouterMiddleware
  ];
  let finalCreateStore;

  finalCreateStore = applyMiddleware(...middleware)(_createStore);

  const store = finalCreateStore(reducer, data);

  return store;
}
