import React, { Component } from "react";
import { Table, Button, notification } from "antd";
import Home from "../Home/Home";
import SingleInvoice from "../SingleInvoice/SingleInvoice";

import appConfig from "../../helpers/appConfig";

class UnpaidInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isLoaded: false,
      items: [],
      dueItems: [],
      currentPage: "unpaidInvoice"
    };
  }

  componentDidMount = () => {
    this.loadUnpaidInvoices();
  };

  loadUnpaidInvoices = () => {
    this.setState({ isLoaded: false });
    const hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/unpaidInvoice`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log(result);
            let data = result.data.invoice.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(2),
              isLoaded: true
            }));
            let invoiceData = data.reverse();

            let dueData = result.data.dueDatedInvoice.map((item) => ({
              ...item,
              createdDate: new Date(item.created).toLocaleDateString(),
              createdTime: new Date(item.created).toLocaleTimeString(),
              taxPrice: item.taxPrice.toFixed(2),
              isLoaded: true
            }));
            let dueInvoiceData = dueData.reverse();

            this.setState({
              items: invoiceData,
              dueItems: dueInvoiceData,
              branchInfo: result.data.branch,
              isLoaded: true
            });
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            error,
            isLoaded: true
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  showInvoice = (invoiceNumber) => {
    let index;

    index = this.state.items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );

    if (index > -1) {
      this.setState({
        currentPage: "invoice",
        billData: this.state.items[index]
      });
    } else if (index < 0) {
      index = this.state.dueItems.findIndex(
        (invoice) => invoice.invoiceNumber === invoiceNumber
      );
      this.setState({
        currentPage: "invoice",
        billData: this.state.dueItems[index]
      });
    }
    console.log(index);
  };

  goBackButton = () => {
    this.setState({ currentPage: "unpaidInvoice" });
    this.loadUnpaidInvoices();
  };

  render() {
    const { currentPage, error, isLoaded } = this.state;
    const dataColumns = [
      {
        title: "Faktura Nr.",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber"
      },
      {
        title: "Dato",
        dataIndex: "createdDate",
        key: "createdDate"
      },
      {
        title: "Tid",
        dataIndex: "createdTime",
        key: "createdTime"
      },
      {
        title: "Ordre Type",
        dataIndex: "orderType",
        key: "orderType"
      },
      {
        title: "Total",
        dataIndex: "taxPrice",
        key: "taxPrice",
        render: (text, record) => {
          if (record.orderType === "Drive Out") {
            return `Kr.${(
              parseFloat(this.state.branchInfo.driveoutCharge) +
              parseFloat(text)
            ).toFixed(2)}`;
          } else {
            return `Kr.${parseFloat(text).toFixed(2)}`;
          }
        }
      },
      {
        title: "Faktura",
        key: "_id",
        render: (text, record) => (
          <Button onClick={() => this.showInvoice(record.invoiceNumber)}>
            View
          </Button>
        )
      }
    ];
    if (error) {
      return <div>error</div>;
    } else if (currentPage === "unpaidInvoice") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">Kreditt Faktura</span>
            <span className="item">
              <span id="less-visible">Hjem / </span>
              Ubetalt Faktura
            </span>
          </div>
          <Table
            loading={isLoaded ? false : true}
            dataSource={this.state.items}
            columns={dataColumns}
            rowKey={(record) => record.invoiceNumber}
          />
          <div className="report-container">
            <span className="item">Til Purring</span>
            <span className="item">
              <span id="less-visible">HJEM / </span>
              Ubetalt Faktura
            </span>
          </div>
          <Table
            loading={isLoaded ? false : true}
            dataSource={this.state.dueItems}
            columns={dataColumns}
            rowKey={(record) => record.invoiceNumber}
          />
        </Home>
      );
    } else if (currentPage === "invoice") {
      return (
        <Home>
          <div className="report-container">
            <span className="item">Ubetalt Faktura</span>
            <span className="item">
              <span id="less-visible">HJEM / </span>
              Ubetalt Faktura
            </span>
            <span className="item">
              <span id="less-visible">
                <div onClick={this.goBackButton} className="back-button-border">
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  <span>Back</span>
                </div>
              </span>
            </span>
          </div>
          <SingleInvoice
            data={this.state.billData}
            branch={this.state.branchInfo}
          />
        </Home>
      );
    }
  }
}

export default UnpaidInvoice;
