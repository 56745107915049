import axios from "axios";
import history from "./../history";
import {
  GET_PRODUCTS,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE,
  PRODUCT_STOCKS,
  PRODUCT_INGREDENT
} from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
export const getProducts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/products/getProduct/${id}`
    );
    dispatch({
      type: GET_PRODUCTS,
      payload: { categoryId: id, ...res.data }
    });
    history.push("/products");
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getCatProducts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/products/getProduct/${id}`
    );
    dispatch({
      type: GET_PRODUCTS,
      payload: { categoryId: id, ...res.data }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const addProduct = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/products/add`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "addProduct" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateProduct = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/products/edit`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateProduct" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const updateProductImage = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/products/imageUpdate`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "updateProductImage" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const deleteProduct = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${appConfig.endpoint}/api/v1/employee/products/delete/${id}`
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "deleteProduct" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getProductStock = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/products/stocks`
    );
    dispatch({ type: PRODUCT_STOCKS, payload: res.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const getIngredent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/products/ingredent/${id}`
    );
    dispatch({ type: PRODUCT_INGREDENT, payload: res.data });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const saveIngredent = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/products/ingredent`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "saveIngredent" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const showWeb = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${appConfig.endpoint}/api/v1/employee/products/showUpdate`,
      data
    );

    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "showWeb" },
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};

export const switchExtraInformationAction =(data)=>async(dispatch)=>{
        try {
          const res = await axios.put(
            `${appConfig.endpoint}/api/v1/employee/products/updateExtraInformation`,
            data
          );
          console.log("res is",res);
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: { ...res.data, actionType: "" },
          });
          
        } catch (error) {
          if (error.response) {

            if (error.response.status === 401) {
              isServerLogout(error.response)
            } else {
              dispatch({ type: ERROR_MASSAGE, payload: error.response });
            }
          }
        }

}
