import axios from "axios";
import { GET_UNPAID_INVOICES, ERROR_MASSAGE } from "./types";
import appConfig from "../helpers/appConfig";
import { isServerLogout } from "../utils/isServerLogout";
export const getUnpaidInvoices = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${appConfig.endpoint}/unpaidinvoice`)
    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/employee/faktura/unpaidinvoices`
    );
    dispatch({
      type: GET_UNPAID_INVOICES,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
