import { VIEW_REPORT, GET_REPORT } from "../action/types";
const initialState = {
  billData: {},
  branch: {},
  reportName: ""
};
const viewReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_REPORT:
      return {
        ...state,
        billData: action.payload.ele,
        branch: action.payload.branch,
        reportName: action.payload.reportName
      };
    case GET_REPORT:
      return {
        ...state
      };
    default:
      return state;
  }
};
export default viewReportReducer;
