import React, { useState, useEffect } from "react";
import Home from "../../Home";
import { connect } from "react-redux";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { Icon as RIcon } from "react-icons-kit";
import { folderOpen, folderMinus } from "react-icons-kit/icomoon";
import { Card, Row, Col } from "react-bootstrap";
import Betalt from "./Betalt";
import {
  getFatura,
  markaspaid,
  downloadPdFile
} from "../../../action/arkiveActions";
const Faktura = (props) => {
  let [page, setPage] = useState("index");
  let [record, setRecord] = useState([]);
  useEffect(() => {
    setRecord(props.faktura);
    return () => {
      setRecord([]);
    };
  }, [props.faktura]);
  const callIndex = () => {
    return (
      <>
        <div className="report-container">
          <span className="item ">
            <span>Arkiv : </span> All Faktura
            <NavLink to="/arkiv" className="back-btn">
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </NavLink>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row className="m-b-1">
              <Col span={6} className="text-center">
                <Button
                  onClick={() => setPage("Betalt")}
                  className="btn btn-link btn1" 
                  style={{
                    height:"80px"
                  }}
                >
                  {/* <RIcon icon={folderOpen} size={40} /> */}
                  <p className="mb-0"> Betalt</p>
                </Button>
              </Col>
              <Col span={5} className="text-center">
                <Button
                  onClick={() => setPage("Ubetalt")}
                  className="btn btn-link btn1"
                  style={{
                    height:"80px"
                  }}
                >
                  {/* <RIcon icon={folderMinus} size={40} /> */}
                  <p className="mb-0"> Ubetalt</p>
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };
  const goBackPage = (rUrl) => {
    setPage(rUrl);
    setRecord([]);
  };
  const backBtn = (rUrl) => {
    return (
      <Button className="back-btn" onClick={() => goBackPage(rUrl)}>
        <i className="fa fa-arrow-circle-left" aria-hidden="true" />
      </Button>
    );
  };
  const callBetalt = () => {
    return (
      <>
        <div className="report-container">
          <span className="item">
            <span>Arkiv/ All Faktura:</span> Betalt
          </span>
          {backBtn("index")}
        </div>
        <Betalt
          getFatura={props.getFatura}
          faktura={record}
          type="PAID"
          downloadPdFile={props.downloadPdFile}
          successData={props.successData}
        />
      </>
    );
  };
  const callUbetalt = () => {
    return (
      <>
        <div className="report-container">
          <span className="item ">
            <span>Arkiv/ All Faktura:</span> Ubetalt
          </span>
          {backBtn("index")}
        </div>
        <Betalt
          getFatura={props.getFatura}
          faktura={record}
          type="UNPAID"
          markaspaid={props.markaspaid}
          downloadPdFile={props.downloadPdFile}
          successData={props.successData}
        />
      </>
    );
  };
  const renderPage = () => {
    if (page === "index") {
      return callIndex();
    } else if (page === "Betalt") {
      return callBetalt();
    } else if (page === "Ubetalt") {
      return callUbetalt();
    }
  };
  return <Home>{renderPage()}</Home>;
};
const mapStateToProps = ({ faktura, successMassage }) => {
  return {
    faktura,
    successData: successMassage.data
  };
};
export default connect(mapStateToProps, {
  getFatura,
  markaspaid,
  downloadPdFile
})(Faktura);
