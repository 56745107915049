import { PROFILE_DETAIL } from "../../action/types";
const initialState = {};
const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_DETAIL:
      return action.payload.data;
    default:
      return state;
  }
};
export default profileReducer;
