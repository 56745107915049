import React from "react";
import { Row, Card, Col } from "react-bootstrap";
import TableSum from "../../../containers/TableSum/TableSum";
import {
  ReportColumns,
  summaryColumns,
  summaryData,
  groupeDiscountColumn,
  discountSummary
} from "./TableColumns";
const ReportList = ({ report }) => {
  const getTotalData = (records, discount) => {
    let totalQuantity = 0;
    let total = 0;
    let discountPrice = 0;
    records.forEach((element) => {
      totalQuantity += element.quantity;
      total += element.price;
    });
    discount.forEach((element) => {
      discountPrice += element.discountPrice;
    });
    return { totalQuantity, total, discountPrice };
  };
  const renderReport = () => {
    return report.length ? (
      report.map((items) => {
        const { totalQuantity, total, discountPrice } = getTotalData(
          items.records,
          items.discount
        );
        return (
          <Row key={items.taxId} className="m-b-1">
            <Col>
              <Card>
                <Card.Header className="h4">{items.taxName=="PLU2"?"Tax15":"Tax25"}</Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title>
                        Rapportere
                        <span className="gr-sbH"> Etter Rabatt</span>
                      </Card.Title>
                      <TableSum
                        columns={ReportColumns(items.taxName,items.taxValue)}
                        summaryColumns={summaryColumns(
                          totalQuantity,
                          total,
                          discountPrice,
                          items.taxValue
                        )}
                        data={items.records}
                        summaryData={summaryData()}
                        rowKey={(record) => record.taxId}
                      />
                    </Col>
                  </Row>
                  {items.discount.length ? (
                    <Row className="m-b-1">
                      <Col>
                        <Card.Title>Rabatt</Card.Title>
                        <TableSum
                          columns={groupeDiscountColumn()}
                          summaryColumns={discountSummary(discountPrice)}
                          data={items.discount}
                          summaryData={summaryData()}
                          rowKey={(record) => record.invoiceNumber}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      })
    ) : (
      <Card>
        <Card.Body>
          <Card.Text className="text-center">No Data</Card.Text>
        </Card.Body>
      </Card>
    );
  };
  return (
    <Row>
      <Col>{renderReport()}</Col>
    </Row>
  );
};

export default ReportList;
