import React, { useState } from "react";
import { Popover, Button } from "antd";
import ImageModel from "./ImageModel";
const UpdateImage = ({ record, updateProductImage }) => {
  let [isModelVisible, setIsModelVisible] = useState(false);
  return (
    <>
      <Button
        title="Edit Image"
        type="link"
        style={{ color: "#1890ff", fontSize: "20px", padding: "0 7px" }}
        onClick={() => setIsModelVisible(true)}
      >
        <i title="Update Ican" className="fa fa-image"></i>
      </Button>

      <ImageModel
        record={record}
        updateProductImage={updateProductImage}
        setIsModelVisible={setIsModelVisible}
        isModelVisible={isModelVisible}
      />
    </>
  );
};

export default UpdateImage;
