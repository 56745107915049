import React from "react";
import Home from "./../Home";
// import "./Profile.css";
//To be configured
const Profile = (props) => {
  return (
    <Home>
      <div className="report-container">
        <span className="item">SETTINGS</span>
        <span className="item">
          <span id="less-visible">Hjem / </span>
          Settings
        </span>
      </div>
      <button className="btn-primary">See profile</button>
      <button className="btn-primary">Edit profile</button>
      <button className="btn-primary">Tip Report</button>
    </Home>
  );
};

export default Profile;
