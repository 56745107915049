import { GET_ORDER_COUNT_TREEDRIVE } from "../../action/types";
const initialState = {
  orders: 0
};

const treeDriveOrderCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_COUNT_TREEDRIVE:
      return {
        ...state,
        orders: action.payload
      };
    default:
      return state;
  }
};

export default treeDriveOrderCountReducer;
