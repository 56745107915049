import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Icon as RIcon } from "react-icons-kit";
import { checkCircle, edit } from "react-icons-kit/fa";
import { NavLink } from "react-router-dom";
import Layout from "../Home";
import { Card, Col, Row, ListGroup, Button } from "react-bootstrap";
import {
  getProfileDetails,
  updateInfo,
  changePassword
} from "../../action/profileAction";
import appConfig from "../../helpers/appConfig";
import { Avatar } from "antd";
import EditModel from "./EditModel";
import ChangePassword from "./ChangePassword";
const Profil = (props) => {
  useEffect(() => {
    props.getProfileDetails();
  }, []);

  const [show, setShow] = useState(false);
  const [showCP, setShowCP] = useState(false);
  const [loading, setLoading] = useState(false);
  const { successData } = props;
  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "updateInfo"
    ) {
      handleClose();
      setLoading(false);
      props.getProfileDetails();
    }
  }, [successData]);
  const { profileImage, email, phoneNo, address, personalInfo } = props.details;
  const regex = /(^(.)|png|jpeg|jpg|jfif)$/;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCPClose = () => setShowCP(false);
  const handleCPShow = () => setShowCP(true);
  const renderPage = () => {
    return (
      <>
        <div className="report-container text-center">
          <span className="item">Profil</span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              <Col className="p-0">
                <NavLink to="/profil/tips" style={{color:"#FFF"}} className="btn btn-ab">TIP RAPPORT</NavLink>
              </Col>
              {/* <Col className="text-center p-0">
                
              </Col> */}
              <Col className="text-right p-0">
              <Button onClick={handleCPShow} className="btn-ab">
                  Bytt Passord
                </Button>
                <Button className="btn-ab ml-2" onClick={handleShow}>
                  <RIcon style={{ color: "#fff" }} icon={edit} size={18} /> Edit Profil
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="profile-card">
          <Card.Body>
            <Row className="pb-3">
              <Col className=" text-center">
                <Avatar
                  // shape="circle"
                  size={100}
                  icon="user"
                  src={`${appConfig.s3url}/${profileImage}`}
                  style={{
                    border: '1px solid rgba(0,0,0,.125)',
                    borderRadius:'50px'
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={12} 
              className="p-4"
              style={{
                border: '1px solid rgba(0,0,0,.125)'
              }}>                
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <b>Email:</b> {email}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Phone:</b> {phoneNo}
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex">
                    <b>Addresse: </b>

                    <div className="pl-1 d-flex">
                    <p className="mb-0">{`${address ? address.line1 : ""} ${
                      address ? address.line2 : ""
                    }`}, &nbsp;</p>
                    <p className="mb-0">{`${address ? address.city : ""} ${
                      address ? address.pin : ""
                    }`}, &nbsp;</p>
                    <p className="mb-0">{`${address ? address.state : ""} ${
                      address ? address.country : ""
                    }`}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex">
                    <b>Dokumenter: </b>
                    <div className="d-flex">
                    {personalInfo && personalInfo.length > 0
                      ? personalInfo.map((item, index) => {
                          if (item && item.match(regex)) {
                            return (
                              <div key={index} className="p-2">
                                Dokumenter {index + 1}: 
                                <a
                                  href={`${appConfig.s3url}/${item}`}
                                  target="_blank"
                                >
                                  <img
                                  style={{ maxWidth: "50px" }}
                                  src={`${appConfig.s3url}/${item}`}
                                  key={item}
                                  target="_blank"
                                />
                                </a>
                               
                              </div>
                            );
                          } else if (item) {
                            return (
                              <div key={index} className="d-flex justify-content-center align-items-center">
                                <a
                                  href={`${appConfig.s3url}/${item}`}
                                  target="_blank"
                                >
                                  Dokumenter {index + 1} 
                                </a>
                              </div>
                            );
                          }
                        })
                      :   " Test"}
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <EditModel
          handleClose={handleClose}
          show={show}
          updateInfo={props.updateInfo}
          details={props.details}
          loading={loading}
          setLoading={setLoading}
        />
        <ChangePassword
          handleClose={handleCPClose}
          show={showCP}
          loading={loading}
          setLoading={setLoading}
          changePassword={props.changePassword}
        />
      </>
    );
  };
  return <Layout>{renderPage()}</Layout>;
};
const mapStateToProps = ({ profileDetails, successMassage }) => {
  return {
    details: profileDetails,
    successData: successMassage.data
  };
};
export default connect(mapStateToProps, {
  getProfileDetails,
  updateInfo,
  changePassword
})(Profil);
